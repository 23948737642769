import { AuthIcon } from "@aperture/assetkit";
import styled from "styled-components";
import { LabelText } from "../../Typography";
import { ModalText2, ModalTitle2 } from "../Typography";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 480px;
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 54px;
`;
const IconWrapper = styled.div`
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.global.primary};
  border-radius: ${({ theme }) => theme.radii.circle};
  > svg,
  path {
    fill: ${({ theme }) => theme.colors.global.textAlt.T1};
  }
`;
const TitleText = styled(ModalTitle2)`
  margin-top: 32px;
  text-align: center;
  letter-spacing: 0.0025em;
  color: ${({ theme }) => theme.colors.global.text.T1};
  font-family: "Dunbar Tall";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  text-transform: capitalize;
`;
const MainText = styled(ModalText2)`
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.global.text.T2};
  text-align: center;
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
const BoldText = styled(LabelText)`
  line-height: 18px;
  color: ${({ theme }) => theme.colors.global.text.T1};
  text-align: center;
`;

export const AuthContent: React.FC<{
  ifAuthorized: boolean | undefined;
}> = ({ ifAuthorized }) => {
  return (
    <ContentWrapper>
      <TitleWrapper>
        <IconWrapper>
          <AuthIcon width={40} height={40} />
        </IconWrapper>
      </TitleWrapper>
      <TitleText>
        {ifAuthorized ? "Revoke Operator Authorization" : "Authorize Operator"}
      </TitleText>
      <MainText>
        {ifAuthorized
          ? "After revoking the authorization, you will have to approve automation related features for each position. Do you want to proceed with the revoking process? Note you can always re-enable it if you want."
          : "For advanced features like recurring rebalance you must authorize Aperture to manage your Uniswap V3 & PancakeSwap V3 positions. Users can also opt to do this to save steps when setting up other automations like automated rebalance or prescheduled close. This however is not required for any features outside of recurring rebalance. You will always retain full possession of your LP NFTs and can revoke this authorization at any time."}
      </MainText>
      {!ifAuthorized && (
        <BoldText>
          You will be asked to assign authorization from your wallet.
        </BoldText>
      )}
    </ContentWrapper>
  );
};

import { ApertureLogo2, InfoIcon } from "@aperture/assetkit";
import { lightColors } from "@aperture/uikitv2";
import { Flex, FlexColumn } from "@ui/common";
import { formatTokenAmount } from "@ui/utils";
import bigDecimal from "js-big-decimal";
import styled from "styled-components";
import { Button } from "../Button";
import { Spinner } from "../Loader";

const Container = styled(FlexColumn)`
  box-sizing: border-box;
  width: 532px;
  height: fit-content;
  border-radius: 15px;
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
  padding: ${({ theme }) => theme.spacing.xl};
`;
const Text1 = styled.div<{ align: "left" | "right" }>`
  text-align: ${({ align }) => align};
  font-family: "Roboto Flex";
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.global.text.T2};
`;
const Text2 = styled.div`
  font-family: "Roboto Flex";
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const Text3 = styled(Text2)`
  color: ${({ theme }) => theme.colors.global.text.T2};
`;
const Points = styled.div`
  font-family: "Roboto Flex";
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const DividerHorizontal = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.global.line.Line1};
`;
const StyledButton = styled(Button)<{ isClaimed?: boolean }>`
  width: 120px;
  padding: 8px 24px;
  line-height: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.global.textAlt.T1};
  &:disabled {
    background: ${({ theme, isClaimed }) =>
      `${
        isClaimed
          ? theme.colors.global.primaryDisable
          : theme.colors.global.background.BG2
      } !important`};
    color: ${({ theme, isClaimed }) =>
      `${(isClaimed ? lightColors : theme.colors).global.text.T3} !important`};
  }
`;
const StyledInfoIcon = styled(InfoIcon)`
  fill: ${({ theme }) => theme.colors.grey.mediumGrey};
`;

export interface ItemBoxProps {
  allocated: number;
  availableToClaim: number;
  claimed: number;
  unvested?: number;
  disabled?: boolean;
  title: string;
  info?: string;
  isClaimed?: boolean;
}

export interface TokenClaimProps {
  totalTokens?: number;
  investor?: {
    allocated: number;
    availableToClaim: number;
    claimed: number;
    unvested: number;
  };
  employee?: {
    allocated: number;
    availableToClaim: number;
    claimed: number;
    unvested: number;
  };
  liquidityMining: {
    allocated: number;
    availableToClaim: number;
    claimed: number;
  };
  privateBeta: {
    allocated: number;
    availableToClaim: number;
    claimed: number;
  };
  onClick: () => void;
  disabled?: boolean;
  claiming?: boolean;
  isClaimed?: boolean;
}

const Item = ({
  label,
  points,
  align = "left",
}: {
  label: string;
  points: number;
  align?: "left" | "right";
}) => {
  return (
    <FlexColumn gap="lg">
      <Text1 align={align}>{label}</Text1>
      <Flex
        gap="md"
        justifyContent={align === "left" ? "flex-start" : "flex-end"}
      >
        <ApertureLogo2 width={28} height={28} />
        <Flex gap="sm" alignItems="baseline">
          <Points>{formatTokenAmount(points || 0)}</Points>
          <Text2>APTR</Text2>
        </Flex>
      </Flex>
    </FlexColumn>
  );
};
const ItemBox = ({
  title,
  info,
  allocated,
  availableToClaim,
  claimed,
  unvested,
}: ItemBoxProps) => {
  return (
    <Container gap="xl">
      <Flex gap="lg" justifyContent="space-between">
        <Item label={title} points={allocated} />
        <Item label="Tokens Claimed" points={claimed} align="right" />
      </Flex>
      {info && (
        <Flex gap="sm" alignItems="center" marginTop={-4}>
          <StyledInfoIcon />
          <Text3>{info}</Text3>
        </Flex>
      )}
      <DividerHorizontal />
      <Flex gap="lg" justifyContent="space-between" alignItems="center">
        <Item label="Available Tokens to Claim" points={availableToClaim} />
        {bigDecimal.compareTo(unvested, 0) !== 0 && (
          <Item
            label="Vested (Locked) Tokens"
            points={unvested ?? 0}
            align="right"
          />
        )}
      </Flex>
    </Container>
  );
};

export const TokenTable: React.FC<TokenClaimProps> = ({
  totalTokens = 0,
  investor,
  employee,
  privateBeta,
  liquidityMining,
  onClick,
  disabled = false,
  claiming = false,
  isClaimed = false,
}) => {
  return (
    <FlexColumn gap="lg">
      <Container>
        <Flex gap="lg" justifyContent="space-between" alignItems="center">
          <Item
            label={
              !employee && !investor
                ? "Liquidity Mining & Private Beta Tokens to Claim"
                : "Total Tokens to Claim"
            }
            points={totalTokens}
          />
          <StyledButton
            variant="contained"
            color="primary"
            size="lg"
            onClick={onClick}
            disabled={
              disabled || !onClick || totalTokens === 0 || isClaimed || claiming
            }
            isClaimed={isClaimed}
          >
            {claiming ? <Spinner /> : isClaimed ? "Claimed" : "Claim All"}
          </StyledButton>
        </Flex>
      </Container>
      {!!investor && (
        <ItemBox
          {...{
            ...investor,
            title: "Investor Token Allocation",
            disabled,
          }}
        />
      )}
      {!!employee && (
        <ItemBox
          {...{
            ...employee,
            title: "Employee Token Allocation",
            disabled,
          }}
        />
      )}
      <ItemBox
        {...{
          ...liquidityMining,
          title: "Liquidity Mining Total Token Rewards",
          info: "Vest over 13 months with a 30-day cliff",
          disabled,
        }}
      />
      <ItemBox
        {...{
          ...privateBeta,
          title: "Private Beta Token Allocation",
          info: "10% claimable at TGE with remaining 90% vesting block by block over 12 months",
          disabled,
        }}
      />
    </FlexColumn>
  );
};

import { CaretIcon } from "@aperture/assetkit";
import styled from "styled-components";
import { variant } from "styled-system";
import { ButtonText, CommonLgText } from "../Typography";

export const ButtonContainer = styled.div<{ size: string }>`
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.radii.round};
  border: 1px solid
    ${({ theme: { colors, isDropdownOpen } }) =>
      isDropdownOpen
        ? colors.global.background.BG4
        : colors.global.background.BG2};
  transition: all ${({ theme }) => theme.transitions.default};
  transition: all ${({ theme }) => theme.transitions.default};
  &:hover {
    opacity: 0.8;
  }
  cursor: pointer;
  ${variant({
    prop: "size",
    variants: {
      large: {
        minWidth: "160px",
        padding: "0 14.5px 0 12px",
        bg: "global.background.BG3",
      },
      small: {
        padding: "0 8px",
        bg: "global.background.BG3",
      },
    },
  })}
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const ArrowDownWrapper = styled.div`
  margin-left: auto;
  margin-bottom: -4px;
  > svg,
  path {
    fill: ${({ theme }) => theme.colors.global.text.T1};
  }
`;

export const SelectedTokenIconWrapper = styled.div`
  height: 24px;
  width: 24px;
`;

export const ArrowDown = styled(CaretIcon)`
  width: 20px;
  height: 20px;
  margin-left: auto;
`;

export const ContentContainer = styled.div`
  width: 282px;
  padding: 24px;
  box-sizing: border-box;
`;

export const Title = styled(CommonLgText)<{ showSearch: boolean }>`
  margin-bottom: ${({ showSearch }) => (showSearch ? "10px" : "0")};
  display: inline-block;
`;

export const TokenList = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  max-height: 240px;
  margin-top: 8px;
`;

export const TokenItem = styled.div`
  box-sizing: border-box;
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
  align-items: center;
  padding: 8px;
  border-radius: ${({ theme }) => theme.radii.md};
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.transparent.grey2};
  }
  transition: all ${({ theme }) => theme.transitions.default};
`;

export const TokenItemIcon = styled.div`
  height: 32px;
  width: 32px;
`;

export const Balance = styled(ButtonText)`
  margin-left: auto;
`;

import styled from "styled-components";
import { Button } from "../Button";
import { RangeTag, StakeTag, Tag } from "../Tag";
import { Headline3 } from "../Typography";

export const TitleBarContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xl};
`;

export const Tickers = styled(Headline3)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const Wrapper = styled.div`
  display: flex;
  margin-left: auto;
  gap: ${({ theme }) => theme.spacing["3xl"]};
`;

export const StyledStakeTag = styled(StakeTag)`
  height: 30px;
  box-sizing: border-box;
  > svg {
    height: 14px;
    width: 14px;
  }
  > span {
    font-size: 14px;
    line-height: 14px;
  }
`;

export const StyledRangeTag = styled(RangeTag)`
  height: 30px;
  box-sizing: border-box;
  > svg {
    height: 14px;
    width: 14px;
  }
  > span {
    font-size: 14px;
    line-height: 14px;
  }
`;

export const StyledButton = styled(Button)`
  height: 32px;
  box-sizing: border-box;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.global.text.T1};
  border: 0;
  background: transparent;
  :hover {
    border: 0;
    background: transparent;
  }
`;

export const DropdownContent = styled.div`
  padding: 8px 4px;
  width: 240px;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.radii.xl};
  color: ${({ theme }) => theme.colors.global.text.T1};
  background: ${({ theme }) => theme.colors.global.background.BG3};
  > p {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    padding: 0 24px;
  }
  > ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;

export const ListItem = styled.div<{ disabled: boolean }>`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.global.text.T1};
  height: 40px;
  line-height: 40px;
  border-radius: ${({ theme }) => theme.radii.md2};
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  padding: 0 24px;
  :hover {
    background: ${({ disabled, theme }) =>
      disabled ? "none" : theme.colors.global.background.BG4};
  }
`;

export const PercentageTag = styled(Tag)`
  min-width: 32px;
  border-radius: ${({ theme }) => theme.radii.md};
  padding: ${({ theme }) => theme.spacing.sm2}
    ${({ theme }) => theme.spacing.lg};
  background-color: ${({ theme }) => theme.colors.global.success};
  color: ${({ theme }) => theme.colors.grey.black};
`;

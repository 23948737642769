import { FeeAmount } from "@aperture_finance/uniswap-v3-sdk";
import { useEventCallback } from "@mui/material";
import bigDecimal from "js-big-decimal";
import React from "react";
import {
  Description,
  FeeTierContainer,
  FeeTierItem,
  Info,
  InfoSelect,
  Tips,
} from "./style";
import { FEE_TIERS, IFeeTierProps } from "./types";

export const FeeTierPercentage = 10000;

export const FeeTierComponent: React.FC<
  IFeeTierProps & { height?: number }
> = ({
  tiers,
  createdStatus,
  options,
  selected,
  onSelect,
  disabled = false,
  disableClick = false,
  disableNotCreated = false,
  height,
  tips,
}) => {
  const handleSelect = useEventCallback((value: FeeAmount | undefined) => {
    if (!!value && value !== selected && onSelect) {
      onSelect(value);
    }
  });

  const isSlipstream = options?.length < 4; // use this to roughly determine if it's slipstream

  if (tips) {
    return <Tips>{tips}</Tips>;
  }

  return (
    <FeeTierContainer>
      {options.map(({ key, label }, index) => {
        const notCreated = Boolean(
          createdStatus && !(createdStatus && createdStatus[key])
        );
        const _disabled = disabled || (notCreated && disableNotCreated);
        return (
          <FeeTierItem
            height={height}
            key={index}
            selected={key === selected}
            disabled={_disabled}
            notCreated={notCreated}
            onClick={() => {
              !_disabled && !disableClick && handleSelect(key);
            }}
          >
            <Info disabled={_disabled}>{label / 1e4}%</Info>
            {!isSlipstream && (
              <Description disabled={_disabled}>
                <span>{FEE_TIERS[key]?.description}</span>
              </Description>
            )}
            {!!tiers && (
              <InfoSelect>
                {!isNaN(tiers[key]) && !notCreated
                  ? `${bigDecimal.round(tiers[key] * 100)}% Select`
                  : `Not ${disableNotCreated ? "available" : "created"}`}
              </InfoSelect>
            )}
          </FeeTierItem>
        );
      })}
    </FeeTierContainer>
  );
};

export const FeeTier = React.memo(FeeTierComponent);

import { MoreIcon } from "@aperture/assetkit";
import React, { useRef } from "react";
import { useTheme } from "styled-components";
import { Dropdown, IDropdownRef } from "../Dropdown";
import { variants } from "../Tag";
import { TokenIcons } from "../TokenIcons";
import { Headline5 } from "../Typography";
import {
  DropdownContent,
  ListItem,
  PercentageTag,
  StyledButton,
  StyledRangeTag,
  StyledStakeTag,
  Tickers,
  TitleBarContainer,
  Wrapper,
} from "./style";
import { IAction, ITitleBarProps } from "./types";

export const TitleBar: React.FC<ITitleBarProps> = ({
  actions,
  isInRange,
  atStake,
  tokens,
  tag,
  closed = false,
}) => {
  const theme = useTheme();
  const dropdownRef = useRef<IDropdownRef>(null);
  const handleClick = (action: IAction) => {
    action.onClick(action.label);
    dropdownRef?.current?.onClose();
  };
  const dropdownBtn = (
    <StyledButton
      color="primary"
      size="md"
      startIcon={<MoreIcon fill={theme.colors.global.text.T1} />}
    ></StyledButton>
  );
  return (
    <TitleBarContainer>
      <TokenIcons
        leftIcon={tokens[0].Icon}
        rightIcon={tokens[1].Icon}
        size={32}
      />
      <Tickers
        lineHeight={"24px"}
      >{`${tokens[0].ticker}/${tokens[1].ticker}`}</Tickers>
      <PercentageTag variant={variants.RISE}>
        <Headline5 fontWeight={400} fontSize={14}>
          {tag}
        </Headline5>
      </PercentageTag>
      <Wrapper>
        {!closed && <StyledRangeTag closed={closed} inRange={isInRange} />}
        {atStake && <StyledStakeTag />}
        <Dropdown ref={dropdownRef} button={dropdownBtn}>
          <DropdownContent>
            <p>Quick Actions</p>
            <ul>
              {actions?.map((action) => (
                <ListItem
                  key={action.label}
                  disabled={action?.disabled ?? false}
                  onClick={() => {
                    !action?.disabled && handleClick(action);
                  }}
                >
                  {action.label}
                </ListItem>
              ))}
            </ul>
          </DropdownContent>
        </Dropdown>
      </Wrapper>
    </TitleBarContainer>
  );
};

import {
  PriceBasedRebalanceValue,
  RatioBasedRebalanceValue,
  RebalanceActionTab,
  TriggerType,
  TriggerValue,
} from "@ui/common/types/Strategy";
import { formatTokenAmount } from "@ui/utils";
import bigDecimal from "js-big-decimal";

export const getStrategyText = (
  triggerType: TriggerType,
  rebalanceType: RebalanceActionTab,
  tokens: [
    { ticker: string; decimals: number },
    { ticker: string; decimals: number }
  ],
  triggerValue: TriggerValue,
  rebalanceValue: PriceBasedRebalanceValue | RatioBasedRebalanceValue
) => {
  return {
    Trigger: getTriggerText(triggerType, tokens, triggerValue),
    "New Price Range": getPriceRangeText(rebalanceType, tokens, rebalanceValue),
  };
};

const getTriggerText = (
  triggerType: TriggerType,
  tokens: [
    { ticker: string; decimals: number },
    { ticker: string; decimals: number }
  ],
  triggerValue: TriggerValue
) => {
  const ticker0 = tokens[0].ticker;
  const ticker1 = tokens[1].ticker;
  const decimal0 = tokens[0].decimals;
  const decimal1 = tokens[1].decimals;
  switch (triggerType) {
    case TriggerType.PRICE:
      return [
        !!triggerValue.below &&
          `${
            triggerValue.baseToken ? ticker1 : ticker0
          } Price ${formatTokenAmount(
            triggerValue.below,
            triggerValue.baseToken ? decimal0 : decimal1
          )} ${triggerValue.baseToken ? ticker0 : ticker1} below spot price`,
        !!triggerValue.above &&
          `${
            triggerValue.baseToken ? ticker1 : ticker0
          } Price ${formatTokenAmount(
            triggerValue.above,
            triggerValue.baseToken ? decimal0 : decimal1
          )} ${triggerValue.baseToken ? ticker0 : ticker1} above spot price`,
      ];
    case TriggerType.PRICE_PERCENTAGE:
      return [
        !!triggerValue.below &&
          `${ticker0} ${triggerValue.below}% below spot price`,
        !!triggerValue.above &&
          `${ticker0} ${triggerValue.above}% above spot price`,
      ];
    case TriggerType.RATIO:
      return [
        !!triggerValue.below &&
          `${ticker0} composition ratio below ${bigDecimal.round(
            bigDecimal.multiply(triggerValue.below, 100),
            2
          )}%`,
        !!triggerValue.above &&
          `${ticker0} composition ratio above ${bigDecimal.round(
            bigDecimal.multiply(triggerValue.above, 100),
            2
          )}%`,
      ];
  }
};

const getPriceRangeText = (
  rebalanceType: RebalanceActionTab,
  tokens: [
    { ticker: string; decimals: number },
    { ticker: string; decimals: number }
  ],
  rebalanceValue: PriceBasedRebalanceValue | RatioBasedRebalanceValue
) => {
  const ticker0 = tokens[0].ticker;
  const ticker1 = tokens[1].ticker;
  const decimal0 = tokens[0].decimals;
  const decimal1 = tokens[1].decimals;
  switch (rebalanceType) {
    case RebalanceActionTab.TOKEN_TERMS:
      const { above: priceAbove, below: priceBelow } =
        rebalanceValue as PriceBasedRebalanceValue;
      return [
        `Lower price bound: ${formatTokenAmount(
          priceBelow,
          rebalanceValue.baseToken ? decimal0 : decimal1
        )} ${rebalanceValue.baseToken ? ticker0 : ticker1} below ${
          rebalanceValue.baseToken ? ticker1 : ticker0
        } future spot price`,
        `Upper price bound: ${formatTokenAmount(
          priceAbove,
          rebalanceValue.baseToken ? decimal0 : decimal1
        )} ${rebalanceValue.baseToken ? ticker0 : ticker1} above ${
          rebalanceValue.baseToken ? ticker1 : ticker0
        } future spot price`,
      ];
    case RebalanceActionTab.PERCENTAGES:
      const { above: percentageAbove, below: percentageBelow } =
        rebalanceValue as PriceBasedRebalanceValue;
      return [
        `Lower price bound: ${percentageBelow}% below ${ticker0} future spot price`,
        `Upper price bound: ${percentageAbove}% above ${ticker0} future spot price`,
      ];
    case RebalanceActionTab.RATIO:
      const { percentageToken0, percentageToken1, tickWidth } =
        rebalanceValue as RatioBasedRebalanceValue;
      return `${bigDecimal.round(
        bigDecimal.multiply(percentageToken0, 100),
        2
      )}% ${ticker0} & ${bigDecimal.round(
        bigDecimal.multiply(percentageToken1, 100),
        2
      )}% ${ticker1} with ${tickWidth} tick width based on future spot price`;
  }
};

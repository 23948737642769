import { CaretIcon, ExitIcon } from "@aperture/assetkit";
import styled from "styled-components";
import { BaseButton } from "../../Buttonb";
import { ButtonText, Subtitle2 } from "../../Typography";

export const StyledBox = styled(BaseButton)<{
  drawerOpen: Boolean;
}>`
  padding: 4px 6px;
  height: 40px;
  background: ${({ theme }) => theme.colors.global.primary};
  line-height: 16px;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.grey.black};
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};

  :hover {
    background: ${({ theme }) => theme.colors.global.primary};
    opacity: 0.8;
    > .icon {
      transform: rotate(180deg);
    }
  }
`;

export const WalletDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 0 8px;
`;

export const IconWrapper = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
`;

export const WalletAddress = styled.div.attrs({
  id: "auth-wallet-address-button",
})`
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-family: "Roboto Flex";
  align-items: center;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.radii.round};
  color: ${({ theme }) => theme.colors.transparent.black};
`;
export const ChevronDown = styled(CaretIcon)<{
  isShow?: boolean;
  colorAlt?: boolean;
}>`
  width: 24px;
  height: 24px;
  transition: transform ${({ theme }) => theme.transitions.default};
  transform: ${({ isShow }) => (isShow ? "rotate(180deg)" : "")};
  path {
    fill: ${({ theme, colorAlt }) =>
      colorAlt ? theme.colors.global.textAlt.T1 : theme.colors.global.text.T1};
  }
`;

export const ContentContainer = styled.div`
  width: 402px;
  padding: 24px 24px 16px 24px;
  border-radius: ${({ theme }) => theme.radii.md}
    ${({ theme }) => theme.radii.md} 0 0;
  color: ${({ theme }) => theme.colors.global.text.T1};
  background: ${({ theme }) => theme.colors.global.background.BG3};
`;

export const ConnectStatus = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
`;

export const StyledExitIcon = styled(ExitIcon)`
  height: 32px;
  width: 32px;
  border-radius: ${({ theme }) => theme.radii.circle};
  padding: 7px;
  box-sizing: border-box;
  cursor: pointer;
  fill: ${({ theme }) => theme.colors.global.text.T1};
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  margin: 16px 0 0 0;
  justify-content: space-between;
`;

export const UserAddress = styled.span`
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const NetType = styled.span`
  display: inline-flex;
  padding: 0px 8px;
  gap: 10px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.global.textAlt.T1};
  background: ${({ theme }) => theme.colors.global.primary};
`;

export const StyledButton = styled(BaseButton)`
  width: 234px;
  height: 32px;
  gap: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.global.textAlt.T1};
  background: ${({ theme }) => theme.colors.global.primary};
  &:hover,
  &:active {
    background: ${({ theme }) => theme.colors.global.primary};
    opacity: 0.8;
  }

  path,
  rect {
    fill: ${({ theme }) => theme.colors.grey.white};
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.global.primaryDisable};
    opacity: 1;
  }
`;

export const Amount = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  margin: 16px 0;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  > span {
    height: 32px;
    width: 32px;
    background: ${({ theme }) => theme.colors.global.background.BG4};
    border-radius: ${({ theme }) => theme.radii.circle};
    margin-right: 8px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
  }

  > svg,
  path {
    fill: ${({ theme }) => theme.colors.global.text.T1};
    stroke: ${({ theme }) => theme.colors.global.text.T1};
  }
`;

export const TabContentsContainer = styled.div`
  border-bottom-left-radius: ${({ theme }) => theme.radii.md};
  border-bottom-right-radius: ${({ theme }) => theme.radii.md};
  padding: 0 24px 24px 24px;
  color: ${({ theme }) => theme.colors.global.text.T1};
  background: ${({ theme }) => theme.colors.global.background.BG3};
`;

export const TabTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px 0 15px 0;
  span {
    font-weight: 500;
    font-size: 14px;
  }
`;

export const ActivitiesContent = styled.div`
  max-height: 120px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const Capitalized = styled.span`
  text-transform: capitalize;
`;

export const LoaderWrapper = styled.div`
  margin-top: 24px;
`;

export const TokensContent = styled.div`
  margin: 24px -12px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 204px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0;
  }
`;

export const TokenRow = styled.div`
  width: calc(100% - 24px);
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
  // &:hover {
  //   border-radius: ${({ theme }) => theme.radii.md};
  //   background: ${({ theme }) => theme.colors.transparent.white2};
  // }
  transition: all ${({ theme }) => theme.transitions.default};
`;

export const TokenLogo = styled.div`
  display: flex;
  height: 32px;
  width: 32px;
  > svg {
    display: flex;
    height: 32px;
    width: 32px;
  }
`;

export const TokenValue = styled(Subtitle2)`
  display: flex;
  align-items: flex-end;
`;

export const TokenItemText = styled.div`
  margin: 0 auto 0 0;
  display: flex;
  flex-direction: column;
`;

export const ButtonTextBox = styled(ButtonText)`
  color: ${({ theme }) => theme.colors.global.textAlt.T1};
  font-family: "Roboto Flex";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
`;

export const SpinnerWrapper = styled.div`
  padding: 6px 0;
`;

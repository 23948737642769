import { ErrorIcon } from "@aperture/assetkit";
import { E_AMM } from "@aperture/uikitv2";
import { IMenuItem, SupportedChains } from "@ui/components";
import { NavLinkProps } from "@ui/components/Nav/NavTabs/types";
import Big from "big.js";
import bigDecimal from "js-big-decimal";
import {
  AmmEnum,
  BasicInfo,
  ChainList,
  ConnectionType,
  NetworkInfo,
  SupportedChainId,
  WalletList,
} from "./types";

export function shortenAddress(
  address = "",
  startCharts: number,
  endCharts: number
) {
  if (address.length < startCharts + endCharts) {
    return address;
  }
  return `${address.slice(0, startCharts)}...${address.slice(-endCharts)}`;
}

export const getAmmEnum = (amm: BasicInfo | E_AMM): AmmEnum => {
  if (!amm) return "UNISWAP_V3";
  switch (typeof amm === "string" ? amm : amm.name) {
    case "PancakeSwap":
      return "PANCAKESWAP_V3";
    case "Aerodrome":
    case "Velodrome":
      return "SLIPSTREAM";
    default:
      return "UNISWAP_V3";
  }
};

export const getSupportedChainsByAmm = (
  targetAmm: BasicInfo
): SupportedChainId[] => {
  return SupportedChains.filter((id) => {
    const { amm } = getChainInfo(id);
    return amm.map((platform) => platform.name).includes(targetAmm.name);
  });
};

export const getChainInfo = (chainId: String): NetworkInfo => {
  const chain = ChainList.find((f) => f.id === chainId);
  return chain ?? ChainList[0];
};

export const getWalletIcon = (connectType: ConnectionType) => {
  const wallet = WalletList.filter((f) => f.id === connectType);
  return wallet.length ? wallet[0].Icon : ErrorIcon;
};

export function changeByPercentage(
  price: string,
  increase: boolean,
  percentage: number
) {
  const fraction = bigDecimal.multiply(
    bigDecimal.divide(price, 100, 6),
    percentage
  );
  const incrementFunc =
    (parseFloat(price) < 0 && increase) || (parseFloat(price) > 0 && !increase)
      ? bigDecimal.subtract
      : bigDecimal.add;
  return bigDecimal.round(incrementFunc(price, fraction), 6);
}

export const generateSelectorMap = (
  supportedAmm: BasicInfo[],
  link?: string
): Map<IMenuItem, IMenuItem[]> => {
  const list = new Map();

  supportedAmm.forEach((amm) => {
    const supportedChain: IMenuItem[] = [];
    ChainList.forEach((chain) => {
      if (chain.amm.filter((platform) => platform.name === amm.name).length > 0)
        supportedChain.push({
          Icon: chain.Icon,
          name: chain.name,
          id: chain.id,
          link: link,
        });
    });
    list.set(amm, supportedChain);
  });

  return list;
};

export const generateNavMap = (
  navLinks?: NavLinkProps[]
): Map<IMenuItem, IMenuItem[]> => {
  const list = new Map();

  if (navLinks)
    for (const navItem of navLinks) {
      list.set({ name: navItem.text, link: navItem.link }, []);
    }
  else {
    list.set({ name: "Limit Orders", link: "limit-orders" }, []);
    list.set(
      {
        name: "Dashboard",
        link: "claim",
      },
      []
    );
  }

  return list;
};

export const sqrtX96ToPrice = (
  sqrtPriceX96: string,
  token0Decimal: number,
  token1Decimal: number
) => {
  return sqrtPriceX96
    ? bigDecimal.divide(
        bigDecimal.multiply(
          bigDecimal.multiply(sqrtPriceX96, sqrtPriceX96),
          new Big(10).pow(token0Decimal - token1Decimal)
        ),
        0xffffffffffffffffffffffffffffffffffffffffffffffff, // 2^192
        18
      )
    : undefined;
};

import { ApertureLogo2, MetamaskIcon } from "@aperture/assetkit";
import { useEventCallback } from "@mui/material";
import { useUpdateEffect } from "ahooks";
import { Message } from "ai";
import { debounce } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { remark } from "remark";
import html from "remark-html";
import { useTheme } from "styled-components";
import {
  AvatarWrapper,
  ChatBubble,
  MessageListWrapper,
  MessageWrapper,
} from "../style";
import { BlinkCursor } from "./styles";

export interface IMessageBoxProps {
  isLoading: boolean;
  messages: Message[];
  userAvatar?: JSX.Element;
  botAvatar?: JSX.Element;
}

export const MessageListBox: React.FC<IMessageBoxProps> = ({
  isLoading,
  messages,
  userAvatar = <MetamaskIcon />,
  // botAvatar = <TransparentLogo width={32} height={32} />,
}) => {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const botAvatar = (
    <ApertureLogo2 width={32} height={32} fill={theme.colors.global.text.T1} />
  );
  const isThinking =
    isLoading &&
    messages.length > 0 &&
    "user" === messages[messages.length - 1].role;

  const debouncedScrollToBottom = useMemo(
    () =>
      debounce(() => {
        ref.current &&
          ref.current.scrollTo({
            top: ref.current.scrollHeight,
            behavior: "smooth",
          });
      }, 50),
    []
  );

  return (
    <MessageListWrapper ref={ref}>
      {messages.map((message, idx) => (
        <MessageBox
          key={`${message.role}-${idx}`}
          isUser={message.role === "user"}
          content={message.content}
          Avatar={message.role === "user" ? userAvatar : botAvatar}
          onDisplayUpdate={debouncedScrollToBottom}
        />
      ))}
      {isThinking && (
        <MessageBox
          key="thinking"
          isUser={false}
          isThinking={true}
          Avatar={botAvatar}
        />
      )}
    </MessageListWrapper>
  );
};

type MessageProps = {
  content?: string;
  isUser: boolean;
  Avatar: JSX.Element;
  isThinking?: boolean;
  onDisplayUpdate?: () => void;
};

const MessageBox = ({
  content = "",
  isUser,
  Avatar,
  isThinking = false,
  onDisplayUpdate,
}: MessageProps) => {
  const flexDirection = isUser ? "row-reverse" : "row";
  const displayContent = useDisplayContent(content);

  const onUpdate = useEventCallback(onDisplayUpdate ?? (() => {}));
  useEffect(() => {
    onUpdate();
  }, [onUpdate, displayContent]);

  return (
    <MessageWrapper flexDirection={flexDirection}>
      <AvatarWrapper>{Avatar}</AvatarWrapper>
      <ChatBubble isUser={isUser}>
        {isThinking ? (
          <p>
            Thinking... <BlinkCursor> ▋</BlinkCursor>
          </p>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: displayContent }} />
        )}
      </ChatBubble>
    </MessageWrapper>
  );
};

const useDisplayContent = (content: string) => {
  const queueRef = useRef<string[]>([]);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [currentContent, setCurrentContent] = useState<string>(content);

  useUpdateEffect(() => {
    queueRef.current.push(content);
  }, [content]);

  useEffect(() => {
    const updateCurrentContent = () => {
      const rest = queueRef.current.length;
      if (rest > 0) {
        const content = queueRef.current.shift();

        if (content) {
          setCurrentContent(content + (rest > 1 ? " ▋" : ""));
        }
      }
      const timeout = 50 + Math.random() * 100;
      timerRef.current = setTimeout(updateCurrentContent, timeout);
    };

    updateCurrentContent();
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return useParsedMarkdownContent(currentContent);
};

const useParsedMarkdownContent = (content: string) => {
  const [parsedContent, setParsedContent] = useState<string>("");

  useEffect(() => {
    // filter content like 【7:2†source】
    const _content = content.replace(/【[0-9:]*†source】/g, "");
    remark()
      .use(html)
      .process(_content)
      .then((res) => {
        setParsedContent(res.toString());
      });
  }, [content]);

  return parsedContent;
};

import { WarningIcon } from "@aperture/assetkit";
import bigDecimal from "js-big-decimal";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { IInputRef } from "..";
import { formatUSDAmount, trimNumber } from "../../../utils/numberFormat";
import {
  Container,
  Conversion,
  ErrorText,
  ErrorWrapper,
  IconWrapper,
  StyledInput,
  StyledSwitch,
  SwitchWrapper,
} from "./style";
import { IPercentageInputGroupProps } from "./types";

export const PercentageInputGroup: React.FC<IPercentageInputGroupProps> = ({
  amount,
  defaultUnit = "%",
  disabled,
  showError = true,
  inputHeight = 40,
  width,
  percentage,
  color,
  onChange,
  onError,
  ...props
}) => {
  const inputRef = useRef<IInputRef>(null);
  const [unit, setUnit] = useState(defaultUnit);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const [conversion, setConversion] = useState(
    unit === "%"
      ? `~$${bigDecimal.multiply(amount !== "-" ? amount : "0", percentage)}`
      : `~${percentage * 100}% of the position`
  );
  const convertValue = useCallback(() => {
    if (!amount || amount === "-") return "0";
    return trimNumber(bigDecimal.multiply(amount, percentage), 4);
  }, [amount, percentage]);
  const dollarValueRef = useRef(convertValue());

  const handleInputChange = (value: string) => {
    setConversion(
      unit === "%"
        ? `~${formatUSDAmount(
            bigDecimal.divide(
              bigDecimal.multiply(amount !== "-" ? amount : "0", value),
              100,
              8
            )
          )}`
        : `~${
            amount === "-" || bigDecimal.compareTo(amount, 0) === 0
              ? "0"
              : trimNumber(
                  bigDecimal.multiply(
                    bigDecimal.round(
                      bigDecimal.divide(value, amount, 18),
                      6,
                      0
                    ),
                    100
                  ),
                  4
                )
          }% of the position`
    );
  };

  const handleInputBlur = (value: string) => {
    if (unit === "%") {
      onChange(parseFloat(trimNumber(bigDecimal.divide(value, 100, 6))));
    } else {
      if (bigDecimal.compareTo(dollarValueRef.current, value) === 0) {
        return;
      }
      dollarValueRef.current = value;
      onChange(
        amount === "-" || bigDecimal.compareTo(amount, 0) === 0
          ? 0
          : parseFloat(
              trimNumber(
                bigDecimal.round(bigDecimal.divide(value, amount, 18), 6, 0)
              )
            )
      );
    }
  };

  useEffect(() => {
    dollarValueRef.current = convertValue();
    inputRef?.current?.setValue(
      unit === "%"
        ? trimNumber(bigDecimal.multiply(percentage, 100))
        : dollarValueRef.current
    );
  }, [unit, percentage, convertValue]);

  useEffect(() => {
    setErrorMsg(onError?.(percentage));
  }, [amount, percentage]);

  return (
    <Container width={width} {...props}>
      <StyledInput
        ref={inputRef}
        align="right"
        type="number"
        color={color}
        height={inputHeight}
        format={unit === "%" ? { suffix: "%" } : { prefix: "$" }}
        disabled={disabled}
        error={!disabled && !!errorMsg}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
      />
      <SwitchWrapper height={inputHeight}>
        <StyledSwitch
          leftValue={"%"}
          rightValue={"$"}
          disabled={disabled}
          selected={unit === "%" ? 0 : 1}
          sliderColor="primary"
          onSelect={(value) => {
            setUnit(value);
          }}
        />
      </SwitchWrapper>
      {!!errorMsg && showError ? (
        <ErrorWrapper>
          <IconWrapper>
            <WarningIcon height={15} width={15} />
          </IconWrapper>
          <ErrorText>{errorMsg}</ErrorText>
        </ErrorWrapper>
      ) : (
        <Conversion fontWeight={400} lineHeight={"16px"}>
          {conversion}
        </Conversion>
      )}
    </Container>
  );
};

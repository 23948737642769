import moment from "moment";
import React, { useEffect, useState } from "react";
import { countDownFormat } from "../../utils";
import { NumberCard } from "./NumberCard";
import {
  ColonItem,
  ContentLeft,
  ContentRight,
  CountDownContainer,
  S2,
  Title,
} from "./style";
import {
  DATE_STR_CAMPAIGN,
  IColonProps,
  ICountDownFormat,
  ICountdownProps,
  countDownVariants,
} from "./types";

const Colon: React.FC<IColonProps> = ({ showColon, variant }) => {
  return <ColonItem variant={variant}>{showColon && ":"}</ColonItem>;
};

export const CountDown: React.FC<ICountdownProps> = ({
  title,
  timestamp,
  isStart = false,
  isDaily = false,
  variant = countDownVariants.FIXED,
  isEnded = false,
}) => {
  const [countDownObj, setCountDownObj] = useState<ICountDownFormat>(
    countDownFormat(timestamp)
  );
  const [showColon, setShowColon] = useState(true);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountDownObj(countDownFormat(timestamp));
      setShowColon((prev) => !prev);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timestamp, isStart]);

  const formatDate = (date: Date): string => {
    const localTime = moment(date);
    const formattedMonth = localTime.format("MMM").toUpperCase();
    const formattedDate = localTime.format("Do");
    const formattedOffset = localTime.format("Z");

    return isDaily
      ? `UTC${formattedOffset}`
      : `${formattedMonth} ${formattedDate}`;
  };
  if (!timestamp) {
    return <CountDownContainer />;
  }
  const target = new Date(timestamp);

  return (
    <CountDownContainer>
      <ContentLeft>
        <Title variant={variant}>{`${title} ${
          isStart ? "Starts On" : isEnded ? "Has Ended On" : "Ends On"
        }`}</Title>
        <S2 variant={variant}>{formatDate(target)}</S2>
      </ContentLeft>
      <ContentRight>
        {!isDaily && (
          <>
            <NumberCard
              dateNum={countDownObj.days}
              dateText={DATE_STR_CAMPAIGN.DAYS}
              variant={variant}
            />
            <Colon showColon={showColon} variant={variant} />
          </>
        )}
        <NumberCard
          dateNum={countDownObj.hours}
          dateText={DATE_STR_CAMPAIGN.HOURS}
          variant={variant}
        />
        <Colon showColon={showColon} variant={variant} />
        <NumberCard
          dateNum={countDownObj.minutes}
          dateText={DATE_STR_CAMPAIGN.MINUTES}
          variant={variant}
        />
        {isDaily && (
          <>
            <Colon showColon={showColon} variant={variant} />
            <NumberCard
              dateNum={countDownObj.seconds}
              dateText={DATE_STR_CAMPAIGN.SECONDS}
              variant={variant}
            />
          </>
        )}
      </ContentRight>
    </CountDownContainer>
  );
};

import { ExchangeIcon } from "@aperture/assetkit";
import { ITokenTickerIconPair } from "@aperture/types";
import { Flex } from "@uiv2/common";
import { formatMarketPrice } from "@uiv2/utils/numberFormat";
import bigDecimal from "js-big-decimal";
import { useState } from "react";
import { Label, SwapButtonWrapper, Value } from "./style";

export const MarketPrice: React.FC<{
  tokens: ITokenTickerIconPair;
  marketPrice: string;
}> = ({ tokens, marketPrice }) => {
  const [swap, setSwap] = useState(false);
  if (!marketPrice) return null;
  return (
    <Flex gap="md">
      <Value>
        {formatMarketPrice(
          swap ? bigDecimal.divide(1, marketPrice, 18) : marketPrice
        )}
      </Value>
      <Label>
        {tokens[swap ? 0 : 1].ticker} per {tokens[swap ? 1 : 0].ticker}
      </Label>
      <SwapButtonWrapper
        onClick={(event) => {
          event.stopPropagation();
          setSwap(!swap);
        }}
      >
        <ExchangeIcon width={20} height={20} />
      </SwapButtonWrapper>
    </Flex>
  );
};

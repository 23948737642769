import { InRangeIcon } from "@aperture/assetkit";
import styled from "styled-components";
import { Flex, FlexColumn } from "../../common/styles/global";
import { EditBtn } from "../TriggerList/style";
import { Body1 } from "../Typography";

export const Container = styled(FlexColumn)<{ lite: Boolean }>`
  gap: ${({ theme }) => theme.spacing.lg};
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const Key = styled(Body1)`
  font-family: "Roboto Flex";
  line-height: 16px;
  color: ${({ theme }) => theme.colors.global.text.T3};
`;
export const Value = styled(Body1)`
  font-family: "Roboto Flex";
  line-height: 16px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
  cursor: pointer;
`;
export const StyledOutlineBtn = styled(EditBtn)`
  line-height: 20px;
  min-width: fit-content;
`;
export const ColumnsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ColumnsRight = styled(Flex)`
  justify-content: flex-end;
`;
export const CopyWrapper = styled.div`
  cursor: pointer;
  svg {
    display: block;
  }
`;
export const StyledInRangeIcon = styled(InRangeIcon)`
  fill: ${({ theme }) => theme.colors.global.text.T2};
`;

import { LinkIcon2 } from "@aperture/assetkit";
import React, { CSSProperties } from "react";
import styled, { useTheme } from "styled-components";
import { width } from "styled-system";
import { Button, ComingSoonButton } from "../Button";
import { Body1 } from "../Typography";
import { IToolCardProps } from "./types";

const Container = styled.div<{ comingSoon: boolean; subTitle: boolean }>`
  display: flex;
  padding: ${({ subTitle }) => (subTitle ? "16px" : "8px 16px")};
  align-items: center;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.radii.md};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line2};
  gap: ${({ comingSoon, theme }) =>
    comingSoon ? theme.spacing.lg : theme.spacing.lg};
`;
const ContentWrapper = styled.div<{
  comingSoon: boolean;
  width?: CSSProperties["width"];
}>`
  margin: auto;
  min-width: ${({ comingSoon, width }) =>
    width ? `${width}` : comingSoon ? "437px" : "537px"};
  ${width};
  align-self: baseline;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
`;
const Title = styled(Body1)`
  font-weight: 500;
  line-height: 16px;
  align-items: center;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const SubTitle = styled(Body1)`
  line-height: 16px;
  align-items: center;
  color: ${({ theme }) => theme.colors.global.text.T2};
`;
const StyledButton = styled(Button)`
  background: transparent;
  box-shadow: none;
  width: 24px;
  height: 24px;
`;

export const ToolCard: React.FC<IToolCardProps> = ({
  title = "",
  subTitle,
  nextClick = () => {},
  disabled = false,
  comingSoon = false,
  width,
}) => {
  const theme = useTheme();

  return (
    <Container comingSoon={comingSoon} subTitle={!!subTitle}>
      <ContentWrapper comingSoon={comingSoon} width={width}>
        <Title>{title}</Title>
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
      </ContentWrapper>
      {comingSoon ? (
        <ComingSoonButton>Coming Soon!</ComingSoonButton>
      ) : (
        <StyledButton
          variant="contained"
          color="default"
          size="sm"
          startIcon={<LinkIcon2 stroke={theme.colors.global.primary} />}
          disabled={disabled}
          onClick={nextClick}
        />
      )}
    </Container>
  );
};

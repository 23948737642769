import { Flex, FlexColumn } from "@uiv2/common";
import { LinearLoaderGroup } from "@uiv2/components";
import { H2, T3Regular } from "@uiv2/components/Typography";
import React, {
  CSSProperties,
  HTMLAttributes,
  Key,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import Pagination from "./Pagination";

const Container = styled(FlexColumn)`
  border-radius: 15px;
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
  padding: ${({ theme }) => theme.spacing.xl};
`;
const TableTitle = styled(H2)`
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const Subtitle = styled(T3Regular)`
  margin-top: ${({ theme }) => theme.spacing.lg};
  margin-bottom: ${({ theme }) => theme.spacing["3xl"]};
  color: ${({ theme }) => theme.colors.global.text.T3};
`;
const ColumnTitle = styled(T3Regular)`
  color: ${({ theme }) => theme.colors.global.text.T3};
`;
const ColumnTitleWrapper = styled.div`
  padding-block: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.global.line.Line2};
  color: ${({ theme }) => theme.colors.global.text.T3};
`;
const Tag = styled.div`
  height: fit-content;
  border-radius: ${({ theme }) => theme.radii.round};
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.xl}`};
  background-color: ${({ theme }) => theme.colors.global.background.BG3};
`;
const TagText = styled(T3Regular)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const LoaderWrapper = styled.div`
  margin-top: 12px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const Wrapper = styled(FlexColumn)<{ isLoading?: boolean; maxHeight?: number }>`
  overflow: ${({ isLoading }) => (isLoading ? "hidden" : "auto")};
  scrollbar-color: ${({ theme }) => theme.colors.global.background.BG3}
    transparent;
  max-height: ${({ maxHeight }) => `${maxHeight ?? 976}px`};
`;

export interface ITableProps<T>
  extends Omit<HTMLAttributes<HTMLDivElement>, "content"> {
  title: string;
  subtitle?: string;
  headers?: string[];
  tag?: string;
  content: T[];
  columnWidths: CSSProperties["width"][];
  rowComponent: (props: T) => JSX.Element;
  keyExtractor?: (item: T, index?: number) => Key;
  emptyState?: JSX.Element;
  loading?: boolean;
  additionalComponent?: React.ReactNode;
  maxHeight?: number;
}

const DEFAULT_PAGE_SIZE = 15;

export const Table = <T,>(props: ITableProps<T>) => {
  const {
    title,
    subtitle,
    headers,
    tag,
    content,
    columnWidths,
    rowComponent: Row,
    keyExtractor,
    emptyState,
    loading,
    additionalComponent,
    maxHeight,
    ...containerProps
  } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const currentContent = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * DEFAULT_PAGE_SIZE;
    const lastPageIndex = firstPageIndex + DEFAULT_PAGE_SIZE;
    return content.slice(firstPageIndex, lastPageIndex);
  }, [content, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [content.length]);

  return (
    <Container {...containerProps}>
      <FlexColumn gap="lg">
        <Flex alignItems="center" justifyContent="space-between">
          <TableTitle>{title}</TableTitle>
          {tag && (
            <Tag>
              <TagText>{tag}</TagText>
            </Tag>
          )}
        </Flex>
        {additionalComponent}
      </FlexColumn>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <Wrapper isLoading={loading} maxHeight={maxHeight}>
        {headers && (
          <Flex>
            {headers.map((header, index) => (
              <ColumnTitleWrapper
                key={index}
                style={{ minWidth: columnWidths[index] }}
              >
                <ColumnTitle>
                  {header !== "selector" ? header : " "}
                </ColumnTitle>
              </ColumnTitleWrapper>
            ))}
          </Flex>
        )}
        {loading ? (
          <LoaderWrapper>
            <LinearLoaderGroup size="100%" height="85px" />
          </LoaderWrapper>
        ) : content.length < 1 ? (
          <>{emptyState}</>
        ) : (
          currentContent.map((item, index) => {
            const key = keyExtractor ? keyExtractor(item, index) : index;
            return (
              <React.Fragment key={key}>
                {item && <Row {...item} />}
              </React.Fragment>
            );
          })
        )}
      </Wrapper>
      {!loading && content.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalCount={content.length}
          pageSize={DEFAULT_PAGE_SIZE}
          onPageChange={(page) => setCurrentPage(page)}
          siblingCount={2}
        />
      )}
    </Container>
  );
};

import bigDecimal from "js-big-decimal";
import React, { useState } from "react";
import styled from "styled-components";
import { formatFixed, formatTokenAmount, formatUSDAmount } from "../../utils";
import { FeeTierPercentage } from "../FeeTier";
import { FeeInfoCard } from "../InfoCard";
import { LinearLoader, RingSpinner } from "../Loader";
import { Popover } from "../Popover";
import { TokenIcon } from "../TokenIcon";
import { TokenIcons } from "../TokenIcons";
import {
  ApertureAPI,
  ArrowBtn,
  ArrowDown,
  Container,
  Content,
  Detail,
  DotLine,
  OrderRoutingText,
  OrderRoutingWrapper,
  PopoverText,
  RouteIcon,
  RouteSellTokenWrapper,
  RouteTag,
  RouteWrapper,
  S4,
  SplitBottom,
  SplitBottomDiv,
  StyledInfoCard,
  StyledPopover,
  Title,
  TitleWrapper,
  WarningContainer,
  WarningText,
} from "./style";
import { IExchangeRateProps } from "./types";

const InlineWrapper = styled.div`
  display: inline-block;
`;

export const ExchangeRate: React.FC<IExchangeRateProps> = ({
  isLoading = false,
  sellToken,
  buyToken,
  networkFee,
  priceImpact,
  minOutput,
  maxInput,
  expectedOutput,
  swapFeeInfo,
  priceRoutes,
  priceImpactWarning,
  amm,
  networkName,
}) => {
  const [expandable, setExpandable] = useState(true);
  const [isShow, setIsShow] = useState(false);
  const [overflow, setOverflow] = useState(false);
  const [swapTitle, setSwapTitle] = useState(false);

  const handleDropdown = () => {
    setIsShow(!isShow);
    setExpandable(isShow);
    setTimeout(
      function () {
        setOverflow(!isShow);
        setExpandable(true);
      },
      isShow ? 0 : 300
    );
  };

  return (
    <Container>
      <TitleWrapper>
        <Title
          isLoading={isLoading}
          onClick={() => !isLoading && setSwapTitle(!swapTitle)}
        >
          {isLoading && <RingSpinner width={16} height={16} />}
          {sellToken &&
          buyToken &&
          !!sellToken.exchangeRate &&
          !!buyToken.exchangeRate ? (
            <>
              <Detail>
                {swapTitle
                  ? `1 ${sellToken.ticker} = ${formatTokenAmount(
                      sellToken.exchangeRate
                    )} ${buyToken.ticker} `
                  : `1 ${buyToken.ticker} = ${formatTokenAmount(
                      buyToken.exchangeRate
                    )} ${sellToken.ticker} `}
              </Detail>
              <Detail>
                {`(${formatUSDAmount(
                  swapTitle ? sellToken.price : buyToken.price
                )})`}
              </Detail>
            </>
          ) : (
            <Detail>Fetching Price...</Detail>
          )}
        </Title>
        <ArrowBtn onClick={expandable ? handleDropdown : () => {}}>
          <ArrowDown isShow={isShow} />
        </ArrowBtn>
      </TitleWrapper>
      <Content isShow={isShow} useOverflow={overflow}>
        <StyledInfoCard
          title="Network fees"
          content={
            isLoading || !networkFee ? (
              <LinearLoader size="80px" height="14px" />
            ) : (
              <Popover
                content={`Network Fees are paid to the ${networkName} network to secure transactions.`}
                defaultPosition={{ right: 0 }}
                defaultSize={{ width: 258 }}
              >
                <PopoverText>
                  {`~${formatUSDAmount(networkFee.gasUSD ?? "")}${
                    networkFee.gasAmount
                      ? ` (${formatTokenAmount(networkFee.gasAmount)} ${
                          networkFee.symbol
                        })`
                      : ""
                  }`}
                </PopoverText>
              </Popover>
            )
          }
        />
        <StyledInfoCard
          title="Price impact"
          content={
            isLoading ? (
              <LinearLoader size="80px" height="14px" />
            ) : (
              `${formatFixed(priceImpact ?? "", 2)}%`
            )
          }
        />
        {maxInput ? (
          <StyledInfoCard
            title="Maximum input"
            content={
              isLoading ? (
                <LinearLoader size="80px" height="14px" />
              ) : (
                `${formatTokenAmount(maxInput ?? "")} ${sellToken?.ticker}`
              )
            }
          />
        ) : (
          <StyledInfoCard
            title="Minimum output"
            content={
              isLoading ? (
                <LinearLoader size="80px" height="14px" />
              ) : (
                `${formatTokenAmount(minOutput ?? "")} ${buyToken?.ticker}`
              )
            }
          />
        )}
        <StyledInfoCard
          title="Expected output"
          content={
            isLoading ? (
              <LinearLoader size="80px" height="14px" />
            ) : (
              `${formatTokenAmount(expectedOutput ?? "")} ${buyToken?.ticker}`
            )
          }
        />
        {swapFeeInfo && (
          <FeeInfoCard
            styles={{
              size: "md",
              titleColor: "light",
              titleFontWeight: 400,
            }}
            amm={amm}
            feeInfo={{
              fee: swapFeeInfo.apertureFee,
              amount: swapFeeInfo.amount,
              active: swapFeeInfo.apertureFeeActive,
            }}
            hint={`${swapFeeInfo.apertureFee * 100}% of the swapped amount.`}
            isLoading={isLoading}
          />
        )}
      </Content>
      <SplitBottomDiv>
        <SplitBottom />
      </SplitBottomDiv>
      <StyledInfoCard
        title="Order routing"
        content={
          <Popover
            content={
              <OrderRoutingWrapper>
                {isLoading ? (
                  <LinearLoader size="410px" height="54px" color="primary" />
                ) : (
                  <OrderRoutingText>
                    Best price route costs{" "}
                    {networkFee ? (
                      formatUSDAmount(networkFee.gasUSD)
                    ) : (
                      <InlineWrapper>
                        <LinearLoader
                          size="50px"
                          height="14px"
                          color="primary"
                        />
                      </InlineWrapper>
                    )}{" "}
                    in gas. This route optimizes your total output by
                    considering split routes, multiple hops, and the gas cost of
                    each step.
                  </OrderRoutingText>
                )}
                {isLoading ? (
                  <LinearLoader size="410px" height="26px" color="primary" />
                ) : (
                  Object.values(priceRoutes ?? []).map((route, routeIndex) => (
                    <RouteWrapper key={routeIndex}>
                      <RouteSellTokenWrapper>
                        <RouteIcon>{route.swaps[0].sellToken?.Icon}</RouteIcon>
                        <DotLine width={route.swaps.length < 3 ? 16 : 4} />
                        <RouteTag>{`${bigDecimal.multiply(
                          route.percentage,
                          100
                        )}%`}</RouteTag>
                      </RouteSellTokenWrapper>
                      {Object.values(route.swaps).map((swap, swapIndex) => (
                        <React.Fragment key={swapIndex}>
                          <DotLine />
                          <Popover
                            content={`${swap.sellToken?.ticker ?? "Unknown"}/${
                              swap.buyToken?.ticker ?? "Unknown"
                            } ${swap.feeTier / FeeTierPercentage}% Pool`}
                          >
                            <RouteTag>
                              <TokenIcons
                                leftIcon={
                                  swap.sellToken?.Icon ?? (
                                    <TokenIcon alt="Unknown" />
                                  )
                                }
                                rightIcon={
                                  swap.buyToken?.Icon ?? (
                                    <TokenIcon alt="Unknown" />
                                  )
                                }
                                size={18}
                              />
                              {`${swap.feeTier / FeeTierPercentage}%`}
                            </RouteTag>
                          </Popover>
                        </React.Fragment>
                      ))}
                      <DotLine />
                      <RouteIcon>
                        {route.swaps[route.swaps.length - 1].buyToken?.Icon}
                      </RouteIcon>
                    </RouteWrapper>
                  ))
                )}
              </OrderRoutingWrapper>
            }
            defaultPosition={{ right: 0, bottom: 38 }}
            defaultSize={{ width: 426 }}
          >
            <ApertureAPI>
              <S4>Aperture API</S4>
            </ApertureAPI>
          </Popover>
        }
      />
      {!isLoading && priceImpactWarning && (
        <StyledPopover content="A swap of this size may have a high price impact, given the current liquidity in the pool. There may be a large difference between the amount of your input token and what you will receive in the output token.">
          <WarningContainer>
            <WarningText>Price impact warning</WarningText>
            <WarningText>{`${priceImpactWarning}%`}</WarningText>
          </WarningContainer>
        </StyledPopover>
      )}
    </Container>
  );
};

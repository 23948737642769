import React from "react";
import styled from "styled-components";
import { NumericalInputProps } from "./types";

const StyledInput = styled.input<{
  error?: boolean;
  fontSize?: string;
  align?: string;
}>`
  color: black;
  width: 0;
  position: relative;
  font-weight: 400;
  outline: none;
  border: none;
  flex: 1 1 auto;
  background-color: transparent;
  font-size: ${({ fontSize }) => fontSize ?? "28px"};
  text-align: ${({ align }) => align && align};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px;
  -webkit-appearance: textfield;
  text-align: center;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  [type="number"] {
    -moz-appearance: textfield;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: gray;
  }
`;

function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

const positiveRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`); // match escaped "." characters via in a non-capturing group
const negativeRegex = RegExp(`^-?\\d*(?:\\\\[.])?\\d*$`); // negative number regex

export const NumericalInput: React.FC<NumericalInputProps> = ({
  value,
  onUserInput,
  placeholder,
  prependSymbol,
  appendSymbol,
  allowNegative = false,
  ...props
}) => {
  const inputRegex = allowNegative ? negativeRegex : positiveRegex;
  const enforcer = (nextUserInput: string) => {
    if (nextUserInput === "" || inputRegex.test(escapeRegExp(nextUserInput))) {
      onUserInput(nextUserInput);
    }
  };
  const onChange = (event: { target: { value: any } }) => {
    let formattedValue = event.target.value;
    const length = formattedValue.length;
    if (!!prependSymbol) {
      // handle when input cursor before prepend symbol
      if (formattedValue.charAt(1) === prependSymbol)
        formattedValue =
          prependSymbol +
          formattedValue.charAt(0) +
          formattedValue.substring(2, length);
      // cut off prepended symbol
      formattedValue = formattedValue.toString().includes(prependSymbol)
        ? formattedValue.toString().slice(1, formattedValue.toString().length)
        : formattedValue;
    }
    if (!!appendSymbol) {
      // handle when input cursor behind appended symbol
      if (formattedValue.charAt(length - 2) === appendSymbol)
        formattedValue =
          formattedValue.substring(0, length - 2) +
          formattedValue.charAt(length - 1) +
          appendSymbol;
      // handle when delete cursor behind appended symbol
      if (formattedValue.charAt(length - 1) !== appendSymbol && length > 1)
        formattedValue = formattedValue.substring(0, length - 1) + appendSymbol;
      // cut off appended symbol
      formattedValue = formattedValue.toString().includes(appendSymbol)
        ? formattedValue
            .toString()
            .slice(0, formattedValue.toString().length - 1)
        : formattedValue;
    }
    // replace commas with periods, because uniswap exclusively uses period as the decimal separator
    enforcer(formattedValue.replace(/,/g, "."));
  };

  return (
    <StyledInput
      {...props}
      value={(prependSymbol ?? "") + value + (appendSymbol ?? "")}
      onKeyDown={(event) => {
        if (event.key === "Backspace" && value.toString().length === 1) {
          enforcer("");
        }
      }}
      onChange={onChange}
      // universal input options
      inputMode="decimal"
      autoComplete="off"
      autoCorrect="off"
      // text-specific options
      type="text"
      pattern="^[0-9]*[.,]?[0-9]*$"
      placeholder={placeholder || "0"}
      minLength={1}
      maxLength={79}
      spellCheck="false"
    />
  );
};

import React from "react";
import { CardContainer, CardNumber, CardText } from "./style";
import { INumberCard } from "./types";

export const NumberCard: React.FC<INumberCard> = ({
  dateNum,
  dateText,
  variant,
}) => {
  return (
    <CardContainer variant={variant}>
      <CardNumber variant={variant}>
        {dateNum.toString().padStart(2, "0")}
      </CardNumber>
      <CardText variant={variant}>{dateText}</CardText>
    </CardContainer>
  );
};

import styled from "styled-components";
import { T1, T3Medium } from "../Typography";
import { ITokenPriceProps } from "./types";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 96px;
  padding: 16px;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.radii.md};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line2};
`;
const Title = styled(T3Medium)`
  justify-content: center;
  text-align: center;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.global.text.T2};
`;
const Amount = styled(T1)`
  justify-content: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const TokenPrice: React.FC<ITokenPriceProps> = ({
  title,
  subTitle,
  amount,
}) => {
  return (
    <Container>
      <Title lineHeight={"20px"}>{title}</Title>
      <Amount>{amount}</Amount>
      <Title lineHeight={"20px"}>{subTitle}</Title>
    </Container>
  );
};

import { Flex } from "@uiv2/common";
import styled from "styled-components";
import { T2 } from "../Typography";

export const StyledT2 = styled(T2)`
  color: ${({ theme }) => theme.colors.global.text.T2};
`;
export const NotFoundWrapper = styled(Flex)`
  min-height: calc(100vh - 100px);
`;

import { Flex } from "@uiv2/common";
import { timestampFormat } from "@uiv2/utils";
import { formatTokenAmount, formatUSDAmount } from "@uiv2/utils/numberFormat";
import bigDecimal from "js-big-decimal";
import React, { Fragment, useState } from "react";
import { useTheme } from "styled-components";
import {
  ArrowBtn,
  ArrowDown,
  Content,
  Item,
  Line,
  Row,
  StyledT2,
  StyledT3,
  Text,
  Title,
  TitleText,
  Wrapper,
} from "./style";
import { IActivityLogs } from "./types";

export const ActivityLogs: React.FC<IActivityLogs> = ({
  tokens,
  logs,
  setLogsShown,
}: IActivityLogs) => {
  const theme = useTheme();
  const [expandable, setExpandable] = useState(true);
  const [isShow, setIsShow] = useState(false);
  const [overflow, setOverflow] = useState(false);

  return (
    <div>
      <Title
        onClick={() => {
          if (!expandable) return;
          setIsShow(!isShow);
          setLogsShown?.(!isShow);
          setExpandable(isShow);
          setTimeout(
            function () {
              setOverflow(!isShow);
              setExpandable(true);
            },
            isShow ? 0 : 300
          );
        }}
      >
        <TitleText>Activity Logs</TitleText>
        <ArrowBtn>
          <ArrowDown isShow={isShow} />
        </ArrowBtn>
      </Title>
      <Content isShow={isShow} useOverflow={overflow} length={logs.length}>
        <Wrapper gap="lg">
          <Row>
            <StyledT3>Type / Date</StyledT3>
            <StyledT3>
              {tokens[0].ticker} / {tokens[1].ticker} Amount
            </StyledT3>
            <StyledT3>Current Value / TX</StyledT3>
          </Row>
          {logs.map((log, idx) => {
            const showLine = idx < logs.length - 1;
            const { id, type, timestamp, valueUSD, txHash, explorerURL } = log;
            const { date, time } = timestampFormat(timestamp, false, true);
            return (
              <Fragment key={`${id}-${txHash}`}>
                <Row>
                  <Item>
                    <Text type={type}>{type}</Text>
                    <StyledT2>
                      {date}, {time.toUpperCase()}
                    </StyledT2>
                  </Item>
                  <Item>
                    <Flex gap="sm">
                      <Text>
                        {formatTokenAmount(
                          bigDecimal.divide(
                            log.token0Amount,
                            Math.pow(10, tokens[0].decimals),
                            tokens[0].decimals
                          )
                        )}
                      </Text>
                      <StyledT2>{tokens[0].ticker}</StyledT2>
                    </Flex>
                    <Flex gap="sm">
                      <Text>
                        {formatTokenAmount(
                          bigDecimal.divide(
                            log.token1Amount,
                            Math.pow(10, tokens[1].decimals),
                            tokens[1].decimals
                          )
                        )}
                      </Text>
                      <StyledT2>{tokens[1].ticker}</StyledT2>
                    </Flex>
                  </Item>
                  <Item>
                    <Text>{formatUSDAmount(valueUSD)}</Text>
                    <a href={explorerURL} target="_blank" rel="noreferrer">
                      <StyledT2 color={theme.colors.global.warning} underlined>
                        {txHash.slice(0, 6)}
                      </StyledT2>
                    </a>
                  </Item>
                </Row>
                {showLine && (
                  <Row>
                    {[...Array(3)].map((_, idx) => (
                      <Line key={idx} />
                    ))}
                  </Row>
                )}
              </Fragment>
            );
          })}
        </Wrapper>
      </Content>
    </div>
  );
};

import { MinusIcon, PlusIcon } from "@aperture/assetkit";
import { Flex } from "@uiv2/common";
import { Input } from "@uiv2/components/Input";
import styled from "styled-components";

const IconButton = styled(Flex)`
  cursor: pointer;
  align-items: center;
  svg {
    fill: ${({ theme }) => theme.colors.global.text.T1};
  }
`;
const StyledDaysInput = styled(Input)`
  width: 292px;
  font-family: "Roboto Flex";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
interface ActiveDaysInputProps {
  value: string;
  onChange: (value: string) => void;
}

export const ActiveDaysInput: React.FC<ActiveDaysInputProps> = ({
  value,
  onChange,
}) => {
  const handleDecrement = () => {
    const _value = Math.max(0, parseInt(value) - 1);
    onChange(_value.toString());
  };

  const handleIncrement = () => {
    const _value = parseInt(value) + 1;
    onChange(_value.toString());
  };

  return (
    <Flex gap="md">
      <IconButton onClick={handleDecrement}>
        <MinusIcon />
      </IconButton>
      <StyledDaysInput
        type="integer"
        align="left"
        defaultValue={value}
        onChange={onChange}
      />
      <IconButton onClick={handleIncrement}>
        <PlusIcon />
      </IconButton>
    </Flex>
  );
};

export default ActiveDaysInput;

import {
  CheckMarkIcon,
  ClosedPositionIcon,
  OutOfRangeIcon,
} from "@aperture/assetkit";
import styled from "styled-components";
import { T3Regular } from "../Typography";
import { RangeProps } from "./types";

const StyledRangeTag = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
`;

const Text = styled(T3Regular)<{ inRange: Boolean }>`
  color: ${({ theme, inRange }) =>
    inRange ? theme.colors.global.text.T1 : theme.colors.global.warning};
`;

const ClosedText = styled(T3Regular)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const RangeTag: React.FC<RangeProps> = ({ closed, inRange }) => {
  return (
    <StyledRangeTag>
      {closed ? (
        <ClosedPositionIcon height={16} width={16} />
      ) : inRange ? (
        <CheckMarkIcon height={16} width={16} />
      ) : (
        <OutOfRangeIcon height={16} width={16} />
      )}
      {closed ? (
        <ClosedText>Closed Position</ClosedText>
      ) : (
        <Text inRange={inRange}>{inRange ? "In Range" : "Out of Range"}</Text>
      )}
    </StyledRangeTag>
  );
};

import { ITokenTickerIconPair, SupportedChainId } from "@aperture/types";
import { Flex, FlexColumn } from "@uiv2/common";
import { useState } from "react";
import { ClosedPositionCard, IClosedPosition } from "../PositionCard";
import { ArrowBtn, ArrowDown, Content, Title, TitleText } from "./style";

export type IClosedPositions = {
  tokens: ITokenTickerIconPair;
  supportedChainId: SupportedChainId;
  isCurrentChain: boolean;
  list: IClosedPosition[];
  onToggleShow?: (show: boolean) => void;
};

export const ClosedPositions = ({
  tokens,
  supportedChainId,
  isCurrentChain,
  list,
  onToggleShow,
}: IClosedPositions) => {
  const [expandable, setExpandable] = useState(true);
  const [isShow, setIsShow] = useState(false);
  const [overflow, setOverflow] = useState(false);

  if (list.length < 1) {
    return (
      <div>
        <Title justifyContent="space-between" clickable={false}>
          <TitleText>Rebalance History</TitleText>
          <Flex gap="sm" alignItems="center">
            <TitleText>0</TitleText>
          </Flex>
        </Title>
      </div>
    );
  }

  return (
    <div>
      <Title
        clickable={true}
        onClick={() => {
          if (!expandable) return;
          setIsShow(!isShow);
          setExpandable(isShow);
          onToggleShow?.(!isShow);
          setTimeout(
            function () {
              setOverflow(!isShow);
              setExpandable(true);
            },
            isShow ? 0 : 300
          );
        }}
        justifyContent="space-between"
      >
        <TitleText>Rebalance History</TitleText>
        <Flex gap="sm" alignItems="center">
          <TitleText>{list.length}</TitleText>
          <ArrowBtn>
            <ArrowDown isShow={isShow} />
          </ArrowBtn>
        </Flex>
      </Title>
      <Content isShow={isShow} useOverflow={overflow} length={list.length}>
        {list.map((pos) => (
          <ClosedPositionCard
            key={pos.positionId}
            {...{ ...pos, tokens, supportedChainId, isCurrentChain }}
          />
        ))}
      </Content>
    </div>
  );
};

export const ExplorerClosedPositions = ({
  tokens,
  supportedChainId,
  isCurrentChain,
  list,
}: IClosedPositions) => {
  return (
    <FlexColumn gap="lg">
      <TitleText>Rebalance History ({list.length})</TitleText>
      {list.map((pos) => (
        <ClosedPositionCard
          key={pos.positionId}
          {...{ ...pos, tokens, supportedChainId, isCurrentChain }}
        />
      ))}
    </FlexColumn>
  );
};

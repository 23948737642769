import { FlexColumn } from "@ui/common";
import styled from "styled-components";
import { variant } from "styled-system";
import { Body3, Headline4, Subtitle2, Subtitle3 } from "../Typography";
import { CountDownVariant, countDownVariants } from "./types";

export const CountDownContainer = styled.div`
  display: flex;
  height: 66px;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.lg};
`;

export const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing.sm};
`;

export const Title = styled(Subtitle3)<{ variant: CountDownVariant }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  font-family: "Roboto Flex";
  margin-bottom: 4px;
  white-space: nowrap;

  ${({ theme }) =>
    variant({
      variants: {
        [countDownVariants.FIXED]: {
          color: "#161616",
        },
        [countDownVariants.PRIMARY]: {
          color: theme.colors.global.text.T1,
        },
        [countDownVariants.SECONDARY]: {
          color: theme.colors.global.text.T2,
        },
      },
    })}
`;

export const S2 = styled(Subtitle2)<{ variant: CountDownVariant }>`
  font-family: Dunbar Tall;
  font-size: 20px;
  line-height: 20px;
  ${({ theme }) =>
    variant({
      variants: {
        [countDownVariants.FIXED]: {
          color: "black",
        },
        [countDownVariants.PRIMARY]: {
          color: theme.colors.global.primary,
        },
        [countDownVariants.SECONDARY]: {
          color: theme.colors.global.primary,
        },
      },
    })}
`;

export const ContentRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const CardContainer = styled(FlexColumn)<{ variant: CountDownVariant }>`
  align-items: center;
  width: 38px;
  height: 50px;
  border-radius: ${({ theme }) => theme.radii["md2"]};
  padding: 8px 4px;
  gap: 4px;
  box-sizing: border-box;
  ${({ theme }) =>
    variant({
      variants: {
        [countDownVariants.FIXED]: {
          background: "#242424",
        },
        [countDownVariants.PRIMARY]: {
          background: theme.colors.global.background.BG2,
        },
        [countDownVariants.SECONDARY]: {
          background: theme.colors.global.background.BG2,
        },
      },
    })}
`;

export const CardNumber = styled(Headline4)<{ variant: CountDownVariant }>`
  font-weight: 500;
  font-size: border-box;
  margin: 0;
  color: ${({ theme }) => theme.colors.global.text.T1};
  ${({ theme }) =>
    variant({
      variants: {
        [countDownVariants.FIXED]: {
          color: "white",
        },
        [countDownVariants.PRIMARY]: {
          color: theme.colors.global.text.T1,
        },
        [countDownVariants.SECONDARY]: {
          color: theme.colors.global.text.T1,
        },
      },
    })}
`;

export const CardText = styled(Body3)<{ variant: CountDownVariant }>`
  font-weight: 500;
  line-height: 12px;
  ${({ theme }) =>
    variant({
      variants: {
        [countDownVariants.FIXED]: {
          color: "rgba(255, 255, 255, 0.50)",
        },
        [countDownVariants.PRIMARY]: {
          color: theme.colors.global.text.T3,
        },
        [countDownVariants.SECONDARY]: {
          color: theme.colors.global.text.T3,
        },
      },
    })}
`;

export const ColonItem = styled(Headline4)<{ variant: CountDownVariant }>`
  height: 50px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  ${({ theme }) =>
    variant({
      variants: {
        [countDownVariants.FIXED]: {
          color: "black",
        },
        [countDownVariants.PRIMARY]: {
          color: theme.colors.global.text.T1,
        },
        [countDownVariants.SECONDARY]: {
          color: theme.colors.global.text.T1,
        },
      },
    })}
`;

import { Flex, FlexColumn } from "@uiv2/common";
import { Button } from "@uiv2/components/Button";
import { H1, H2, T3Regular } from "@uiv2/components/Typography";
import styled from "styled-components";
import { IValuePecentProps, ValuePercentBox } from "../ValuePercentBox";

const Container = styled(FlexColumn)`
  width: 400px;
  box-sizing: border-box;
  border-radius: 15px;
  padding: ${({ theme }) => theme.spacing.xl};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
`;
const StyledH2 = styled(H2)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const Subtitle = styled(H2)`
  color: ${({ theme }) => theme.colors.global.text.T4};
`;
const Amount = styled(H1)`
  color: ${({ theme }) => theme.colors.global.primary};
`;
const StyledT3 = styled(T3Regular)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

type IEstimatedFeesProps = {
  amount: string;
  monthly: IValuePecentProps;
  yearly: IValuePecentProps;
  onClick: () => void;
};

export const PureEstimatedFees: React.FC<IEstimatedFeesProps> = ({
  amount,
  monthly,
  yearly,
  onClick,
}) => {
  return (
    <Container gap="3xl">
      <Flex gap="sm">
        <StyledH2>Estimated Fees</StyledH2>
        <Subtitle>(24H)</Subtitle>
      </Flex>
      <Amount>${amount}</Amount>
      <FlexColumn gap="lg">
        <Flex alignItems="center" justifyContent="space-between">
          <StyledT3>Monthly</StyledT3>
          <ValuePercentBox {...monthly} />
        </Flex>
        <Flex alignItems="center" justifyContent="space-between">
          <StyledT3>Yearly (APR)</StyledT3>
          <ValuePercentBox {...yearly} />
        </Flex>
      </FlexColumn>
      <Button size="xl" variant="outlined" width="100%" onClick={onClick}>
        Calculate Impermanent Loss
      </Button>
    </Container>
  );
};

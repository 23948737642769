import styled from "styled-components";
import { compose, layout, space, typography } from "styled-system";
import {
  buttonSizeVariants,
  buttonVariants,
  disabledButtonVariants,
  iconSizeVariants,
} from "./themes";
import { ThemedProps } from "./types";

export const StyledButtonWrapper = styled.div<ThemedProps>`
  ${({ width }) => width}
`;

export const StyledButton = styled.button<ThemedProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (!!props.disabled ? "auto" : "pointer")};
  font-weight: 500;
  gap: ${({ theme, iconGap }) => (iconGap ? `${iconGap}px` : theme.spacing.sm)};
  border: none;
  transition: all ${({ theme }) => theme.transitions.fast} ease-in-out;
  width: ${(props) => props.width};

  &:hover {
    transition-duration: ${({ theme }) => theme.transitions.default};
  }
  // Button Variants
  ${(props) => buttonVariants(props)}
  // Size Variants
  ${() => buttonSizeVariants()}
  ${(props) => (!!props.disabled ? disabledButtonVariants(props) : "")};
  ${compose(layout, space, typography)}
`;

export const IconWrapper = styled.div<ThemedProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  // Size Variants
  ${() => iconSizeVariants()}
`;

import { CrossIcon } from "@aperture/assetkit";
import { Flex, FlexColumn } from "@ui/common";
import styled, { DefaultTheme } from "styled-components";
import { ISnackbarProps, IStatus } from "./types";

export const SnackbarContainer = styled(Flex).attrs({
  className: "snack-bar",
})<{
  isOpen: boolean;
  top: number;
  status: IStatus;
}>`
  position: fixed;
  top: ${({ top }) => 94 + top}px;
  left: 50%;
  transform: translateX(-50%);
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  transition: all ${({ theme }) => theme.transitions.default};
  z-index: ${({ theme }) => theme.zIndices.snackbar};
  overflow: hidden;
  clip-path: inset(0);
  box-sizing: border-box;
  width: 360px;
  padding: 12px 16px;
  gap: ${({ theme }) => theme.spacing.xl};
  align-items: center;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.radii.md2};
  background-color: ${({ theme }) => theme.colors.global.background.BG2};

  > svg {
    flex-shrink: 0;
  }
  ::before {
    content: "";
    position: absolute;
    left: -49px;
    top: -32px;
    width: 120px;
    height: 120px;
    background: ${({ status, theme }) => getStatusColor(status, theme.colors)};
    opacity: 0.3;
    border-radius: 50%;
    filter: blur(30px);
    z-index: -1;
  }
`;

export const TextSection = styled(FlexColumn)`
  gap: ${({ theme }) => theme.spacing.sm};
  white-space: pre-line;
`;

export const Info = styled.div`
  color: ${({ theme }) => theme.colors.global.text.T1};
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
`;

export const Url = styled.div<{ state: ISnackbarProps["status"] }>`
  color: ${({ theme, state }) => getStatusColor(state, theme.colors)};
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
`;

export const CloseBtn = styled(CrossIcon)`
  width: 15px;
  height: 15px;
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.colors.global.text.T1};
  }
`;

export const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
`;
export const Box = styled.div<{ status: ISnackbarProps["status"] }>`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 5px;
  background: ${({ status, theme }) =>
    `${getStatusColor(status, theme.colors)}1A`};
`;

const getStatusColor = (status: IStatus, theme: DefaultTheme["colors"]) => {
  return status === "completed"
    ? theme.global.success
    : status === "failed"
    ? theme.global.error
    : status === "warning"
    ? theme.global.warning
    : theme.global.primary;
};

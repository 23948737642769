import { ApertureSupportedChainId } from "@aperture_finance/uniswap-v3-automation-sdk";
import { Token } from "@uniswap/sdk-core";
import { getAddress } from "viem";
import tokenEthereumMainnet from "./data/commonTokens-1.json";
import tokenOptimism from "./data/commonTokens-10.json";
import tokenPolygon from "./data/commonTokens-137.json";
import tokenMantaMainnet from "./data/commonTokens-169.json";
import tokenArbitrumMainnet from "./data/commonTokens-42161.json";
import tokenAvalanche from "./data/commonTokens-43114.json";
import tokenScroll from "./data/commonTokens-534352.json";
import tokenBNB from "./data/commonTokens-56.json";
import tokenBase from "./data/commonTokens-8453.json";
import { BaseToken } from "./tokenConfig";

export function getCommonTokenMap(
  chainId: ApertureSupportedChainId
): Map<string, Token> {
  const tokenMap = new Map();
  let tokensJson: BaseToken[] = [];
  switch (chainId) {
    case ApertureSupportedChainId.ETHEREUM_MAINNET_CHAIN_ID:
      tokensJson = tokenEthereumMainnet;
      break;
    case ApertureSupportedChainId.ARBITRUM_MAINNET_CHAIN_ID:
      tokensJson = tokenArbitrumMainnet;
      break;
    case ApertureSupportedChainId.MANTA_PACIFIC_MAINNET_CHAIN_ID:
      tokensJson = tokenMantaMainnet;
      break;
    case ApertureSupportedChainId.OPTIMISM_MAINNET_CHAIN_ID:
      tokensJson = tokenOptimism;
      break;
    case ApertureSupportedChainId.POLYGON_MAINNET_CHAIN_ID:
      tokensJson = tokenPolygon;
      break;
    case ApertureSupportedChainId.BASE_MAINNET_CHAIN_ID:
      tokensJson = tokenBase;
      break;
    case ApertureSupportedChainId.BNB_MAINNET_CHAIN_ID:
      tokensJson = tokenBNB;
      break;
    case ApertureSupportedChainId.AVALANCHE_MAINNET_CHAIN_ID:
      tokensJson = tokenAvalanche;
      break;
    case ApertureSupportedChainId.SCROLL_MAINNET_CHAIN_ID:
      tokensJson = tokenScroll;
      break;
  }

  for (const token of tokensJson) {
    const tokenAddress = getAddress(token.id);
    tokenMap.set(
      tokenAddress,
      new Token(
        chainId,
        tokenAddress,
        Number(token.decimals),
        token.symbol,
        token.name
      )
    );
  }
  return tokenMap;
}

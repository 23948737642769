import { PositionBreakdown } from "./PositionBreakdownChart";
import { ChartPrice, ChartTick, ChartVolumn } from "./types";

export const calculatorToken0PriceMockData: ChartPrice[] = [
  { timestamp: 1721567608920, value: 3502.846024763745 },
  { timestamp: 1721571208311, value: 3492.9623880073286 },
  { timestamp: 1721574802969, value: 3506.225393776344 },
  { timestamp: 1721578424794, value: 3501.458461695287 },
  { timestamp: 1721582020945, value: 3511.281815245828 },
  { timestamp: 1721585624743, value: 3445.5888093742687 },
  { timestamp: 1721589208556, value: 3473.6367041627054 },
  { timestamp: 1721592804095, value: 3487.09046781595 },
  { timestamp: 1721596423894, value: 3497.5859625056114 },
  { timestamp: 1721600023199, value: 3533.9957698105054 },
  { timestamp: 1721603624101, value: 3542.4053361829815 },
  { timestamp: 1721607220286, value: 3535.2227777563803 },
  { timestamp: 1721610803844, value: 3540.0634935173844 },
  { timestamp: 1721614418301, value: 3526.5868774567684 },
  { timestamp: 1721618003416, value: 3528.8771548143686 },
  { timestamp: 1721621612805, value: 3515.170904993431 },
  { timestamp: 1721625425799, value: 3510.20445585671 },
  { timestamp: 1721628844609, value: 3496.523411585734 },
  { timestamp: 1721632659333, value: 3471.739986594082 },
  { timestamp: 1721636063938, value: 3483.0263220748147 },
  { timestamp: 1721639659218, value: 3484.016149326808 },
  { timestamp: 1721643234693, value: 3489.962512732427 },
  { timestamp: 1721646827114, value: 3488.196159018585 },
  { timestamp: 1721650422018, value: 3495.12356272167 },
  { timestamp: 1721654023563, value: 3506.263833938832 },
  { timestamp: 1721657647489, value: 3474.2411558507583 },
  { timestamp: 1721661296706, value: 3445.639718359632 },
  { timestamp: 1721664877106, value: 3458.9245639523 },
  { timestamp: 1721668467103, value: 3459.246050365343 },
  { timestamp: 1721672077753, value: 3477.567763397696 },
  { timestamp: 1721675684563, value: 3477.4046873959305 },
  { timestamp: 1721679283462, value: 3487.5967653366583 },
  { timestamp: 1721682872762, value: 3473.96063969859 },
  { timestamp: 1721686441431, value: 3449.442104563003 },
  { timestamp: 1721690055360, value: 3442.266932508103 },
  { timestamp: 1721693700567, value: 3446.042215575894 },
  { timestamp: 1721697257803, value: 3470.6843364678034 },
  { timestamp: 1721700868868, value: 3484.5273316871903 },
  { timestamp: 1721704413143, value: 3481.583087307021 },
  { timestamp: 1721708073058, value: 3478.4056170349477 },
  { timestamp: 1721711660440, value: 3447.677579519731 },
  { timestamp: 1721715250071, value: 3456.1064163763353 },
  { timestamp: 1721718994059, value: 3442.048464618539 },
  { timestamp: 1721722445918, value: 3456.8696030017063 },
  { timestamp: 1721726058555, value: 3526.072316931404 },
  { timestamp: 1721730132234, value: 3524.5445638071324 },
  { timestamp: 1721732940529, value: 3496.0831455565667 },
  { timestamp: 1721736338229, value: 3514.8058929000085 },
  { timestamp: 1721740146838, value: 3511.422698845935 },
  { timestamp: 1721743723702, value: 3449.3296986991336 },
  { timestamp: 1721747367834, value: 3473.1860174845074 },
  { timestamp: 1721753897528, value: 3432.144504777952 },
  { timestamp: 1721757801260, value: 3413.925762139329 },
  { timestamp: 1721761730723, value: 3450.526797890767 },
  { timestamp: 1721763597278, value: 3481.779273408468 },
  { timestamp: 1721765088283, value: 3460.5001803257837 },
  { timestamp: 1721768905337, value: 3481.1364352581513 },
  { timestamp: 1721772555497, value: 3472.670067772582 },
  { timestamp: 1721775883657, value: 3473.7486759156673 },
  { timestamp: 1721779751030, value: 3483.1597835832436 },
  { timestamp: 1721783163181, value: 3466.240582539831 },
  { timestamp: 1721786694930, value: 3443.781701007814 },
  { timestamp: 1721790293680, value: 3453.504565574193 },
  { timestamp: 1721793889505, value: 3449.532933281403 },
  { timestamp: 1721799968368, value: 3436.7323695924642 },
  { timestamp: 1721803420418, value: 3441.4547148491747 },
  { timestamp: 1721806885377, value: 3446.9406817530244 },
  { timestamp: 1721810764033, value: 3459.1919791226055 },
  { timestamp: 1721814231970, value: 3454.4833703823197 },
  { timestamp: 1721817886444, value: 3464.976064309031 },
  { timestamp: 1721821391287, value: 3461.7825730219765 },
  { timestamp: 1721825058866, value: 3458.889068983156 },
  { timestamp: 1721828634023, value: 3473.4278621726485 },
  { timestamp: 1721832216739, value: 3457.972686709791 },
  { timestamp: 1721835973647, value: 3420.488987450619 },
  { timestamp: 1721839479473, value: 3420.125553525491 },
  { timestamp: 1721843131329, value: 3428.818121371154 },
  { timestamp: 1721846699393, value: 3393.72571746773 },
  { timestamp: 1721850242729, value: 3397.870884168957 },
  { timestamp: 1721853895529, value: 3372.6309159255175 },
  { timestamp: 1721857547527, value: 3331.3087316707515 },
  { timestamp: 1721861025998, value: 3327.3540513776084 },
  { timestamp: 1721864610854, value: 3328.951473752899 },
  { timestamp: 1721868284052, value: 3333.1576322245237 },
  { timestamp: 1721871886012, value: 3216.981790312819 },
  { timestamp: 1721875594680, value: 3175.4313026006057 },
  { timestamp: 1721879171423, value: 3170.103093789424 },
  { timestamp: 1721882767940, value: 3169.8136317621675 },
  { timestamp: 1721886280780, value: 3189.990962958667 },
  { timestamp: 1721889828261, value: 3179.94890412293 },
  { timestamp: 1721893437004, value: 3182.444537803212 },
  { timestamp: 1721897092541, value: 3168.7982845740507 },
  { timestamp: 1721900752458, value: 3180.013245069748 },
  { timestamp: 1721904357097, value: 3162.2052693940236 },
  { timestamp: 1721907873739, value: 3175.130208377723 },
  { timestamp: 1721911490782, value: 3159.304415489112 },
  { timestamp: 1721915105269, value: 3145.817467350744 },
  { timestamp: 1721918828704, value: 3173.5130140328342 },
  { timestamp: 1721922401203, value: 3145.797957309618 },
  { timestamp: 1721925950580, value: 3167.309267688337 },
  { timestamp: 1721929559027, value: 3168.607422582712 },
  { timestamp: 1721933160426, value: 3140.6097762509244 },
  { timestamp: 1721936757003, value: 3116.796149013086 },
  { timestamp: 1721940364473, value: 3121.32033450172 },
  { timestamp: 1721943953344, value: 3168.3825548701966 },
  { timestamp: 1721947510448, value: 3170.0640766109027 },
  { timestamp: 1721951147565, value: 3175.9593431766675 },
  { timestamp: 1721954749928, value: 3182.7551316906115 },
  { timestamp: 1721958456380, value: 3183.069343273851 },
  { timestamp: 1721962004915, value: 3229.8842565889304 },
  { timestamp: 1721965618036, value: 3238.8343450475772 },
  { timestamp: 1721969363167, value: 3251.6701870122993 },
  { timestamp: 1721972654074, value: 3257.250829670893 },
  { timestamp: 1721976335374, value: 3268.177851812769 },
  { timestamp: 1721980066881, value: 3259.525483353884 },
  { timestamp: 1721983568046, value: 3255.3850453013843 },
  { timestamp: 1721987237195, value: 3256.44249882076 },
  { timestamp: 1721990779572, value: 3245.6418072864703 },
  { timestamp: 1721994281056, value: 3245.187442066893 },
  { timestamp: 1721997949777, value: 3243.670601969455 },
  { timestamp: 1722001608236, value: 3246.8001406903636 },
  { timestamp: 1722005209879, value: 3230.4487947531015 },
  { timestamp: 1722008799024, value: 3247.6954447844378 },
  { timestamp: 1722012363103, value: 3257.8818034738015 },
  { timestamp: 1722016092825, value: 3244.5352157756693 },
  { timestamp: 1722019596564, value: 3250.4966918487808 },
  { timestamp: 1722023145898, value: 3267.470082499042 },
  { timestamp: 1722026832361, value: 3256.5231346292694 },
  { timestamp: 1722030472730, value: 3271.351060680842 },
  { timestamp: 1722033983058, value: 3279.5134399367466 },
  { timestamp: 1722037544466, value: 3281.25325073928 },
  { timestamp: 1722041145204, value: 3263.344010660022 },
  { timestamp: 1722044675325, value: 3258.918078848479 },
  { timestamp: 1722048438752, value: 3253.896758788703 },
  { timestamp: 1722051922030, value: 3251.528771358874 },
  { timestamp: 1722055521406, value: 3249.9768175915506 },
  { timestamp: 1722059141656, value: 3258.5707495593488 },
  { timestamp: 1722062746498, value: 3258.3889589537516 },
  { timestamp: 1722066387497, value: 3272.261460233559 },
  { timestamp: 1722069942560, value: 3270.390711871185 },
  { timestamp: 1722073585639, value: 3277.368891367005 },
  { timestamp: 1722077184240, value: 3276.4507923165984 },
  { timestamp: 1722080784585, value: 3277.5107637986102 },
  { timestamp: 1722084356103, value: 3288.17524478005 },
  { timestamp: 1722087895949, value: 3311.525122973818 },
  { timestamp: 1722091540379, value: 3308.903455446554 },
  { timestamp: 1722095214325, value: 3286.4120832948265 },
  { timestamp: 1722098757329, value: 3288.3937577736765 },
  { timestamp: 1722102378196, value: 3269.286950791112 },
  { timestamp: 1722105942004, value: 3271.0227203056247 },
  { timestamp: 1722109528907, value: 3241.593591675882 },
  { timestamp: 1722113173561, value: 3209.7838437881724 },
  { timestamp: 1722116768214, value: 3286.360534235911 },
  { timestamp: 1722120343198, value: 3282.3135018471403 },
  { timestamp: 1722123995158, value: 3256.2625741970032 },
  { timestamp: 1722127580463, value: 3250.742879354037 },
  { timestamp: 1722131232328, value: 3251.8529208792074 },
  { timestamp: 1722134815365, value: 3249.858256835508 },
  { timestamp: 1722138386759, value: 3247.9990992920675 },
  { timestamp: 1722141891237, value: 3231.7498685324945 },
  { timestamp: 1722145542265, value: 3220.829215494278 },
  { timestamp: 1722149190923, value: 3223.7554227136247 },
  { timestamp: 1722152738243, value: 3219.6215992878247 },
  { timestamp: 1722156326002, value: 3215.291140482861 },
  { timestamp: 1722159997699, value: 3235.534773698161 },
  { timestamp: 1722163569314, value: 3250.1413474150077 },
  { timestamp: 1722167099974, value: 3257.9066798530703 },
  { timestamp: 1722170731781, value: 3272.493156984202 },
  { timestamp: 1722174338280, value: 3269.8561461741747 },
  { timestamp: 1722177964662, value: 3266.7690394297633 },
  { timestamp: 1722181515931, value: 3259.922808632485 },
  { timestamp: 1722185120892, value: 3272.2290304293733 },
  { timestamp: 1722188727682, value: 3269.204395234113 },
  { timestamp: 1722192323046, value: 3279.4609184966876 },
  { timestamp: 1722195898099, value: 3269.4927000878747 },
  { timestamp: 1722199546119, value: 3264.649529435254 },
  { timestamp: 1722203157144, value: 3263.8629271882255 },
  { timestamp: 1722206757032, value: 3259.619502049852 },
  { timestamp: 1722210280058, value: 3271.629568241926 },
  { timestamp: 1722213899381, value: 3294.6001436545275 },
  { timestamp: 1722217610287, value: 3289.417710625509 },
  { timestamp: 1722221198193, value: 3317.1843599535996 },
  { timestamp: 1722224760493, value: 3346.9011224113005 },
  { timestamp: 1722228350439, value: 3349.3232933782897 },
  { timestamp: 1722231954780, value: 3355.8763443494245 },
  { timestamp: 1722235557847, value: 3380.399110318956 },
  { timestamp: 1722239242173, value: 3369.485937079473 },
  { timestamp: 1722242764271, value: 3369.9596828300378 },
  { timestamp: 1722245621883, value: 3383.5887822133577 },
  { timestamp: 1722247491422, value: 3393.7160037398558 },
  { timestamp: 1722253844083, value: 3380.0602932989696 },
  { timestamp: 1722256625861, value: 3381.2386444192184 },
  { timestamp: 1722260497324, value: 3358.2900269312627 },
  { timestamp: 1722264088829, value: 3329.325831087333 },
  { timestamp: 1722267724192, value: 3294.047052052196 },
  { timestamp: 1722271312110, value: 3281.4723032099337 },
  { timestamp: 1722274951954, value: 3307.158092071647 },
  { timestamp: 1722278555938, value: 3304.6929767286224 },
  { timestamp: 1722282165758, value: 3309.0359281447436 },
  { timestamp: 1722285662495, value: 3327.6626057870294 },
  { timestamp: 1722289306988, value: 3322.129482701257 },
  { timestamp: 1722292837975, value: 3341.8394133673432 },
  { timestamp: 1722296290631, value: 3331.671100699606 },
  { timestamp: 1722300133847, value: 3317.5549789296692 },
  { timestamp: 1722303659189, value: 3323.1498752618018 },
  { timestamp: 1722307255496, value: 3297.687862855957 },
  { timestamp: 1722311117689, value: 3319.6002420142036 },
  { timestamp: 1722314294905, value: 3316.639820841904 },
  { timestamp: 1722317928446, value: 3296.5516090016918 },
  { timestamp: 1722321844352, value: 3323.432334537394 },
  { timestamp: 1722328708449, value: 3347.0799328037288 },
  { timestamp: 1722332364739, value: 3335.847881400604 },
  { timestamp: 1722336047987, value: 3333.0576695229 },
  { timestamp: 1722339566893, value: 3337.1589416410643 },
  { timestamp: 1722343270901, value: 3336.0582841666283 },
  { timestamp: 1722346908519, value: 3356.6416663715477 },
  { timestamp: 1722350734290, value: 3320.8026419470943 },
  { timestamp: 1722354168556, value: 3317.9281854525384 },
  { timestamp: 1722357752965, value: 3313.4630122293747 },
  { timestamp: 1722361413089, value: 3295.978590587147 },
  { timestamp: 1722365023918, value: 3290.5525198463924 },
  { timestamp: 1722368495457, value: 3270.3202111763594 },
  { timestamp: 1722372178305, value: 3267.9579645340496 },
  { timestamp: 1722375652526, value: 3273.725115194748 },
  { timestamp: 1722379183338, value: 3277.105089385942 },
  { timestamp: 1722382830547, value: 3278.3710497665743 },
  { timestamp: 1722386495175, value: 3276.251207029346 },
  { timestamp: 1722390037319, value: 3270.10204467041 },
  { timestamp: 1722393712113, value: 3293.964872152307 },
  { timestamp: 1722397223676, value: 3290.5106837277017 },
  { timestamp: 1722400771221, value: 3276.346178381506 },
  { timestamp: 1722404443267, value: 3280.3851862259758 },
  { timestamp: 1722407952240, value: 3317.5555920772736 },
  { timestamp: 1722411548989, value: 3321.015507967156 },
  { timestamp: 1722415283890, value: 3319.7957627352916 },
  { timestamp: 1722418860336, value: 3318.7110883644705 },
  { timestamp: 1722422376068, value: 3311.911065965165 },
  { timestamp: 1722425997119, value: 3315.7841019589764 },
  { timestamp: 1722429646469, value: 3318.009704377274 },
  { timestamp: 1722433187724, value: 3334.882385160655 },
  { timestamp: 1722436835385, value: 3313.0559339879005 },
  { timestamp: 1722440482904, value: 3305.4134512571786 },
  { timestamp: 1722444056623, value: 3295.431606065405 },
  { timestamp: 1722447710316, value: 3307.0314427541575 },
  { timestamp: 1722451376412, value: 3301.267498456554 },
  { timestamp: 1722454972275, value: 3276.456190484048 },
  { timestamp: 1722458556831, value: 3229.236897811947 },
  { timestamp: 1722462135228, value: 3223.7180788340675 },
  { timestamp: 1722465724438, value: 3227.766450900211 },
  { timestamp: 1722469394265, value: 3228.335108111083 },
  { timestamp: 1722473026691, value: 3236.259128939106 },
  { timestamp: 1722476516367, value: 3212.737397082388 },
  { timestamp: 1722480165405, value: 3192.657462906995 },
  { timestamp: 1722483701578, value: 3188.0940806208314 },
  { timestamp: 1722487288991, value: 3166.6909175267656 },
  { timestamp: 1722490860623, value: 3170.0132295664794 },
  { timestamp: 1722494458246, value: 3165.8275225983666 },
  { timestamp: 1722497960394, value: 3172.0162517585286 },
  { timestamp: 1722501442509, value: 3178.847431801097 },
  { timestamp: 1722505147113, value: 3182.7733530997016 },
  { timestamp: 1722508743544, value: 3185.4111755741865 },
  { timestamp: 1722512329034, value: 3186.893581482332 },
  { timestamp: 1722515464814, value: 3196.8359544725827 },
  { timestamp: 1722518587629, value: 3182.5317046623554 },
  { timestamp: 1722522066086, value: 3178.5993915388362 },
  { timestamp: 1722525947161, value: 3128.722364301879 },
  { timestamp: 1722529732480, value: 3113.5443460938677 },
  { timestamp: 1722533202516, value: 3092.4947734060456 },
  { timestamp: 1722537099130, value: 3105.7800031884876 },
  { timestamp: 1722540025833, value: 3101.9479982992393 },
  { timestamp: 1722543773300, value: 3128.714536591301 },
  { timestamp: 1722547784852, value: 3163.991072506407 },
  { timestamp: 1722551100188, value: 3194.4548156571395 },
  { timestamp: 1722554398440, value: 3208.7793451982334 },
  { timestamp: 1722558026118, value: 3208.5100378927887 },
  { timestamp: 1722561894165, value: 3179.413927833903 },
  { timestamp: 1722565932787, value: 3148.348153433744 },
  { timestamp: 1722569221096, value: 3156.840710665201 },
  { timestamp: 1722572392268, value: 3166.5757379533197 },
  { timestamp: 1722577039208, value: 3160.6528137464406 },
  { timestamp: 1722579588425, value: 3142.2629469747167 },
  { timestamp: 1722582896027, value: 3142.6689989207484 },
  { timestamp: 1722586549228, value: 3144.821174160886 },
  { timestamp: 1722590132934, value: 3152.8745076575706 },
  { timestamp: 1722593991458, value: 3149.5455057137247 },
  { timestamp: 1722597445715, value: 3154.5167280352607 },
  { timestamp: 1722601031567, value: 3153.5614764630923 },
  { timestamp: 1722605234430, value: 3147.18360932365 },
  { timestamp: 1722609000086, value: 3124.766051236137 },
  { timestamp: 1722612611073, value: 3006.6209595022133 },
  { timestamp: 1722616295762, value: 3029.4154162094137 },
  { timestamp: 1722620269872, value: 3030.7455148466943 },
  { timestamp: 1722623828671, value: 3020.653782885235 },
  { timestamp: 1722627143097, value: 3013.7187616606084 },
  { timestamp: 1722631046384, value: 2998.9136472839104 },
  { timestamp: 1722634195623, value: 2989.4628327515998 },
  { timestamp: 1722637897513, value: 2995.9991755414057 },
  { timestamp: 1722641721081, value: 2991.2297198532992 },
  { timestamp: 1722645228812, value: 2988.2591779859727 },
  { timestamp: 1722649059632, value: 2946.0290922493537 },
  { timestamp: 1722652610679, value: 2958.3820425783865 },
  { timestamp: 1722655791599, value: 2973.266167997274 },
  { timestamp: 1722659538352, value: 2974.0783304729966 },
  { timestamp: 1722663006166, value: 2961.2736331216165 },
  { timestamp: 1722666298536, value: 2977.9765056801843 },
  { timestamp: 1722670193906, value: 2986.093618305953 },
  { timestamp: 1722673465828, value: 2976.9930231337466 },
  { timestamp: 1722677095509, value: 2976.7518233052624 },
  { timestamp: 1722680738589, value: 2988.0433416007286 },
  { timestamp: 1722685004330, value: 2991.02234130179 },
  { timestamp: 1722688163946, value: 3004.250892831391 },
  { timestamp: 1722692264341, value: 3005.076924602663 },
  { timestamp: 1722695837309, value: 3010.3956020950113 },
  { timestamp: 1722699434320, value: 2978.137571996377 },
  { timestamp: 1722703115244, value: 2946.55912171865 },
  { timestamp: 1722706679787, value: 2918.3454219452533 },
  { timestamp: 1722710301282, value: 2898.81670631893 },
  { timestamp: 1722713910253, value: 2895.9354418707944 },
  { timestamp: 1722717507857, value: 2912.804727098744 },
  { timestamp: 1722721064311, value: 2900.133085602844 },
  { timestamp: 1722724562656, value: 2907.4087571978944 },
  { timestamp: 1722728024034, value: 2909.807198710065 },
  { timestamp: 1722731874016, value: 2904.1160193290575 },
  { timestamp: 1722735513321, value: 2888.285998770812 },
  { timestamp: 1722739100601, value: 2927.9887797118013 },
  { timestamp: 1722742735963, value: 2908.839521978082 },
  { timestamp: 1722746292555, value: 2914.8662069648535 },
  { timestamp: 1722748832082, value: 2905.021773610613 },
  { timestamp: 1722753004042, value: 2915.468953077611 },
  { timestamp: 1722757112457, value: 2899.7148083370885 },
  { timestamp: 1722760550902, value: 2895.7601512411184 },
  { timestamp: 1722763552557, value: 2907.902295220241 },
  { timestamp: 1722767687467, value: 2909.2891861018993 },
  { timestamp: 1722771501785, value: 2914.124550093112 },
  { timestamp: 1722775067995, value: 2916.7681960924874 },
  { timestamp: 1722778681160, value: 2913.864643218925 },
  { timestamp: 1722781885975, value: 2886.035734718655 },
  { timestamp: 1722785950444, value: 2839.4973509032307 },
  { timestamp: 1722789572893, value: 2828.6670079003525 },
  { timestamp: 1722793093107, value: 2719.1549063767147 },
  { timestamp: 1722796753688, value: 2696.956882664358 },
  { timestamp: 1722800429707, value: 2744.195867138562 },
  { timestamp: 1722803967647, value: 2750.638833014264 },
  { timestamp: 1722807577134, value: 2734.0027979810075 },
  { timestamp: 1722811192729, value: 2740.0400557801813 },
  { timestamp: 1722814768451, value: 2704.2587464708067 },
  { timestamp: 1722818459411, value: 2646.754193189667 },
  { timestamp: 1722821999889, value: 2309.046710808783 },
  { timestamp: 1722825671451, value: 2297.8025944292485 },
  { timestamp: 1722829266398, value: 2310.065309896124 },
  { timestamp: 1722832856083, value: 2359.720090786258 },
  { timestamp: 1722836352686, value: 2359.8890392334356 },
  { timestamp: 1722840079376, value: 2222.9527623372387 },
  { timestamp: 1722843663682, value: 2340.4946727901847 },
  { timestamp: 1722847320568, value: 2330.538971328287 },
  { timestamp: 1722850381627, value: 2324.2699035148007 },
  { timestamp: 1722854407784, value: 2254.5209573592506 },
  { timestamp: 1722858072905, value: 2236.2239323122963 },
  { timestamp: 1722861632730, value: 2241.8464836077906 },
  { timestamp: 1722865345279, value: 2269.5150066302444 },
  { timestamp: 1722868960230, value: 2388.2372045258753 },
  { timestamp: 1722872575418, value: 2447.4312204959665 },
  { timestamp: 1722876254553, value: 2479.6029976801888 },
  { timestamp: 1722879696333, value: 2460.9908153745896 },
  { timestamp: 1722883409699, value: 2410.0113222956315 },
  { timestamp: 1722887009261, value: 2400.283580722798 },
  { timestamp: 1722890610918, value: 2418.3340945367836 },
  { timestamp: 1722894125260, value: 2442.702589936524 },
  { timestamp: 1722897771202, value: 2452.0818478904844 },
  { timestamp: 1722901372489, value: 2445.3714454293204 },
  { timestamp: 1722904971457, value: 2518.6213356953344 },
  { timestamp: 1722908563828, value: 2506.7491189063808 },
  { timestamp: 1722912231270, value: 2521.697376771011 },
  { timestamp: 1722915851991, value: 2500.1578773979645 },
  { timestamp: 1722919412398, value: 2496.9066732527567 },
  { timestamp: 1722922957659, value: 2513.782624772209 },
  { timestamp: 1722926551539, value: 2528.1742095794452 },
  { timestamp: 1722930145079, value: 2512.783403744424 },
  { timestamp: 1722933704624, value: 2464.5560147822384 },
  { timestamp: 1722937526568, value: 2454.1847372501 },
  { timestamp: 1722941016673, value: 2460.7199923769845 },
  { timestamp: 1722944540524, value: 2458.21135131305 },
  { timestamp: 1722948159223, value: 2447.7326081071487 },
  { timestamp: 1722951867438, value: 2444.484322579486 },
  { timestamp: 1722955465888, value: 2540.276050109212 },
  { timestamp: 1722959094102, value: 2513.675211925417 },
  { timestamp: 1722962699933, value: 2528.87674594995 },
  { timestamp: 1722966260190, value: 2519.9439810953213 },
  { timestamp: 1722969868511, value: 2508.662531416402 },
  { timestamp: 1722973487215, value: 2508.2697163806097 },
  { timestamp: 1722977073700, value: 2499.7670672770096 },
  { timestamp: 1722980681642, value: 2491.156728286981 },
  { timestamp: 1722984123101, value: 2467.0656117197377 },
  { timestamp: 1722987705789, value: 2458.9188738984926 },
  { timestamp: 1722991273884, value: 2464.4166289710656 },
  { timestamp: 1722994977230, value: 2467.45956794221 },
  { timestamp: 1722998665250, value: 2503.356592008898 },
  { timestamp: 1723001963952, value: 2499.5394650856765 },
  { timestamp: 1723003615569, value: 2504.9352531676 },
  { timestamp: 1723007650307, value: 2527.52160583594 },
  { timestamp: 1723014527098, value: 2503.737929659999 },
  { timestamp: 1723017891298, value: 2511.6299732220214 },
  { timestamp: 1723023967815, value: 2533.3129956502517 },
  { timestamp: 1723028243276, value: 2533.945492206571 },
  { timestamp: 1723029713520, value: 2498.346792859814 },
  { timestamp: 1723034524344, value: 2468.6715552596224 },
  { timestamp: 1723038378192, value: 2465.5984590284484 },
  { timestamp: 1723045400785, value: 2393.3238981532872 },
  { timestamp: 1723049993427, value: 2393.018180813834 },
  { timestamp: 1723050330567, value: 2396.0534842291013 },
  { timestamp: 1723050419754, value: 2396.0534842291013 },
  { timestamp: 1723053736815, value: 2380.4155368145225 },
  { timestamp: 1723057252238, value: 2346.687363172791 },
  { timestamp: 1723060954448, value: 2353.3925056156318 },
  { timestamp: 1723064407528, value: 2324.4526334412512 },
  { timestamp: 1723068373892, value: 2361.814360600954 },
  { timestamp: 1723071682082, value: 2358.0889270058537 },
  { timestamp: 1723075952938, value: 2350.7345057652688 },
  { timestamp: 1723079205502, value: 2359.322619597207 },
  { timestamp: 1723083246008, value: 2452.399060387877 },
  { timestamp: 1723086278994, value: 2458.2433615687823 },
  { timestamp: 1723089721247, value: 2434.3750774537884 },
  { timestamp: 1723093364129, value: 2424.970575193036 },
  { timestamp: 1723097161719, value: 2418.3299378107063 },
  { timestamp: 1723100681481, value: 2437.6806801177877 },
  { timestamp: 1723104036411, value: 2418.2282078990183 },
  { timestamp: 1723108514446, value: 2418.1426867104838 },
  { timestamp: 1723111893231, value: 2429.8496967614856 },
  { timestamp: 1723115755269, value: 2443.2530618418446 },
  { timestamp: 1723119221158, value: 2446.6692811072357 },
  { timestamp: 1723122791496, value: 2476.962196186335 },
  { timestamp: 1723126141304, value: 2447.2908631064342 },
  { timestamp: 1723130298769, value: 2489.168266755571 },
  { timestamp: 1723133935261, value: 2568.770407252441 },
  { timestamp: 1723137539816, value: 2598.064987636454 },
  { timestamp: 1723140297451, value: 2577.529246280722 },
  { timestamp: 1723144430018, value: 2587.0322720690383 },
  { timestamp: 1723147494086, value: 2572.378676404268 },
  { timestamp: 1723151424461, value: 2574.8892860175006 },
  { timestamp: 1723154473494, value: 2615.4191516993374 },
  { timestamp: 1723158198446, value: 2682.377607757535 },
  { timestamp: 1723161757198, value: 2680.560983178949 },
  { timestamp: 1723166092584, value: 2658.687928353093 },
  { timestamp: 1723169143183, value: 2663.8491802953463 },
  { timestamp: 1723172431597, value: 2675.9975389580122 },
  { timestamp: 1723176363463, value: 2690.273460210742 },
  { timestamp: 1723179969329, value: 2659.5223990336244 },
  { timestamp: 1723183551162, value: 2679.8170465032663 },
  { timestamp: 1723187715757, value: 2693.0657678102243 },
  { timestamp: 1723191268347, value: 2667.419879039293 },
  { timestamp: 1723194587298, value: 2674.1074741875536 },
  { timestamp: 1723197933328, value: 2660.749142728504 },
  { timestamp: 1723202345714, value: 2633.7069312824515 },
  { timestamp: 1723205063314, value: 2638.3985990893398 },
  { timestamp: 1723209661216, value: 2613.488006035407 },
  { timestamp: 1723212840472, value: 2631.4473782326913 },
  { timestamp: 1723216554708, value: 2587.3688898655046 },
  { timestamp: 1723220234998, value: 2571.659767971635 },
  { timestamp: 1723223863683, value: 2600.8224505235466 },
  { timestamp: 1723227750362, value: 2585.7192226787233 },
  { timestamp: 1723230412600, value: 2588.4174604919567 },
  { timestamp: 1723234576044, value: 2592.353448297954 },
  { timestamp: 1723237575529, value: 2589.3044707403706 },
  { timestamp: 1723241924166, value: 2592.1623245552855 },
  { timestamp: 1723245390486, value: 2589.5375533250863 },
  { timestamp: 1723248568080, value: 2593.336234872227 },
  { timestamp: 1723252638058, value: 2606.6253934863576 },
  { timestamp: 1723255794433, value: 2600.8854666381826 },
  { timestamp: 1723259111750, value: 2606.3511438276564 },
  { timestamp: 1723263334055, value: 2601.6565021880356 },
  { timestamp: 1723267092313, value: 2600.920519815376 },
  { timestamp: 1723270888913, value: 2610.711687150483 },
  { timestamp: 1723273462948, value: 2622.9046504954963 },
  { timestamp: 1723277967800, value: 2630.3048157160983 },
  { timestamp: 1723281580949, value: 2632.8038176220534 },
  { timestamp: 1723284872653, value: 2636.047424007501 },
  { timestamp: 1723287806050, value: 2630.562632060222 },
  { timestamp: 1723292431722, value: 2632.2550967069415 },
  { timestamp: 1723295553065, value: 2625.3716261699474 },
  { timestamp: 1723298604815, value: 2611.690243885294 },
  { timestamp: 1723302177460, value: 2613.2087425813934 },
  { timestamp: 1723306892672, value: 2599.848753191469 },
  { timestamp: 1723310364955, value: 2607.721635325173 },
  { timestamp: 1723312807366, value: 2609.309079604553 },
  { timestamp: 1723316859832, value: 2598.1359740970993 },
  { timestamp: 1723321279125, value: 2602.841748339105 },
  { timestamp: 1723324536747, value: 2610.5142766138615 },
  { timestamp: 1723327442083, value: 2612.9544162253105 },
  { timestamp: 1723330860364, value: 2607.720314322331 },
  { timestamp: 1723335767031, value: 2617.6190488111542 },
  { timestamp: 1723338385642, value: 2622.8763694719437 },
  { timestamp: 1723341966807, value: 2627.8353075408377 },
  { timestamp: 1723345282796, value: 2626.74999780007 },
  { timestamp: 1723348880705, value: 2647.3761486382896 },
  { timestamp: 1723352455126, value: 2647.221652636746 },
  { timestamp: 1723356046522, value: 2660.287384351505 },
  { timestamp: 1723359633125, value: 2660.0827282280884 },
  { timestamp: 1723363471744, value: 2679.5992727423322 },
  { timestamp: 1723367172092, value: 2689.808994447381 },
  { timestamp: 1723370741141, value: 2687.256529762198 },
  { timestamp: 1723374055753, value: 2681.893562193612 },
  { timestamp: 1723378667211, value: 2642.1163684214985 },
  { timestamp: 1723382032569, value: 2627.803164561613 },
  { timestamp: 1723385801921, value: 2642.48606517042 },
  { timestamp: 1723389380184, value: 2608.1174824069494 },
  { timestamp: 1723393314265, value: 2610.5212381918986 },
  { timestamp: 1723396274232, value: 2632.4378205600196 },
  { timestamp: 1723400272602, value: 2637.667594592645 },
  { timestamp: 1723403988549, value: 2630.330369180601 },
  { timestamp: 1723406494945, value: 2605.0362315850953 },
  { timestamp: 1723410072321, value: 2555.6561256004493 },
  { timestamp: 1723414270091, value: 2574.5530526001826 },
  { timestamp: 1723417443648, value: 2568.72596386083 },
  { timestamp: 1723420865915, value: 2558.704714920683 },
  { timestamp: 1723425099943, value: 2536.4997465643687 },
  { timestamp: 1723428610943, value: 2550.1650246967206 },
  { timestamp: 1723432145309, value: 2552.3096435320363 },
  { timestamp: 1723436022627, value: 2546.950086160531 },
  { timestamp: 1723439486966, value: 2542.8523002961774 },
  { timestamp: 1723442537446, value: 2552.0811310721338 },
  { timestamp: 1723446041508, value: 2544.290167978759 },
  { timestamp: 1723450795941, value: 2583.426689218474 },
  { timestamp: 1723454473599, value: 2575.5733977429995 },
  { timestamp: 1723458116192, value: 2597.6082969500308 },
  { timestamp: 1723461785607, value: 2669.5867440623624 },
  { timestamp: 1723464304434, value: 2671.1731904136486 },
  { timestamp: 1723468424916, value: 2685.4366887445203 },
  { timestamp: 1723471529259, value: 2615.626392707661 },
  { timestamp: 1723474940127, value: 2701.398209534773 },
  { timestamp: 1723479773212, value: 2671.2168224940256 },
  { timestamp: 1723482031683, value: 2672.692964374694 },
  { timestamp: 1723485804723, value: 2660.7514611459233 },
  { timestamp: 1723490679006, value: 2661.044073284815 },
  { timestamp: 1723493385485, value: 2663.8020607378166 },
  { timestamp: 1723496507874, value: 2682.0870126993773 },
  { timestamp: 1723500814377, value: 2692.2591440468177 },
  { timestamp: 1723505079076, value: 2734.8966499442763 },
  { timestamp: 1723508253326, value: 2711.120956044805 },
  { timestamp: 1723511501422, value: 2711.278221425959 },
  { timestamp: 1723514617406, value: 2728.332685092784 },
  { timestamp: 1723518987696, value: 2664.1108854796853 },
  { timestamp: 1723522495377, value: 2651.4694744885824 },
  { timestamp: 1723526250761, value: 2659.358010657546 },
  { timestamp: 1723529541791, value: 2655.204142290287 },
  { timestamp: 1723533132056, value: 2660.560779163158 },
  { timestamp: 1723536790679, value: 2648.598525390817 },
  { timestamp: 1723540302298, value: 2637.6267265354722 },
  { timestamp: 1723543684959, value: 2638.8597749700825 },
  { timestamp: 1723547238897, value: 2634.0933574821015 },
  { timestamp: 1723550441762, value: 2640.9238569403747 },
  { timestamp: 1723554942038, value: 2646.731566913234 },
  { timestamp: 1723557978146, value: 2668.767707441988 },
  { timestamp: 1723562652094, value: 2648.624796262024 },
  { timestamp: 1723565761515, value: 2660.307063445724 },
  { timestamp: 1723569168595, value: 2709.105682263468 },
  { timestamp: 1723573392880, value: 2700.698850640632 },
  { timestamp: 1723577074090, value: 2686.542232990131 },
  { timestamp: 1723580211540, value: 2708.8165712254186 },
  { timestamp: 1723582981472, value: 2699.260072432377 },
  { timestamp: 1723586492809, value: 2728.153641116996 },
  { timestamp: 1723591308369, value: 2711.832456860672 },
  { timestamp: 1723593789215, value: 2704.449361112694 },
  { timestamp: 1723598263481, value: 2707.3614460170324 },
  { timestamp: 1723600909816, value: 2695.717097710589 },
  { timestamp: 1723605238811, value: 2709.153044391005 },
  { timestamp: 1723609265275, value: 2715.8340555302143 },
  { timestamp: 1723612991851, value: 2738.6122509638803 },
  { timestamp: 1723616543885, value: 2732.5112652055554 },
  { timestamp: 1723620104811, value: 2727.444828837276 },
  { timestamp: 1723622736971, value: 2727.994157184096 },
  { timestamp: 1723626247328, value: 2726.108749987498 },
  { timestamp: 1723630644155, value: 2733.880993643589 },
  { timestamp: 1723633873509, value: 2727.0453179812675 },
  { timestamp: 1723637523970, value: 2754.1718960013104 },
  { timestamp: 1723641045537, value: 2733.5225964680967 },
  { timestamp: 1723645274008, value: 2646.2938332462545 },
  { timestamp: 1723647961744, value: 2661.2032745368074 },
  { timestamp: 1723652495792, value: 2664.6534930216176 },
  { timestamp: 1723655493985, value: 2662.2790602530827 },
  { timestamp: 1723659870657, value: 2662.4572139744905 },
  { timestamp: 1723662178982, value: 2657.4273979876593 },
  { timestamp: 1723665802864, value: 2667.85006935309 },
  { timestamp: 1723669256110, value: 2673.347781052251 },
  { timestamp: 1723672980237, value: 2665.0920573297317 },
  { timestamp: 1723676578075, value: 2670.0345198719533 },
  { timestamp: 1723680731985, value: 2666.13486728978 },
  { timestamp: 1723683827654, value: 2668.70393224499 },
  { timestamp: 1723687684369, value: 2640.6170301294396 },
  { timestamp: 1723692267590, value: 2653.355005334898 },
  { timestamp: 1723694654964, value: 2650.919003319133 },
  { timestamp: 1723698263881, value: 2654.454570071106 },
  { timestamp: 1723701894337, value: 2645.167152462143 },
  { timestamp: 1723705443371, value: 2622.523518608622 },
  { timestamp: 1723709076698, value: 2615.972967856657 },
  { timestamp: 1723712622570, value: 2620.2085131582335 },
  { timestamp: 1723716970805, value: 2624.198814792827 },
  { timestamp: 1723720558150, value: 2623.3450318021773 },
  { timestamp: 1723724204423, value: 2636.2802710616506 },
  { timestamp: 1723727655458, value: 2648.2798124479928 },
  { timestamp: 1723731781094, value: 2651.5804490846817 },
  { timestamp: 1723735184750, value: 2652.084163817979 },
  { timestamp: 1723739126703, value: 2668.4838003987293 },
  { timestamp: 1723741519616, value: 2657.6322873523914 },
  { timestamp: 1723745118917, value: 2631.0112140483866 },
  { timestamp: 1723749731852, value: 2538.806240826164 },
  { timestamp: 1723753359694, value: 2539.079330941673 },
  { timestamp: 1723756063399, value: 2545.04778590399 },
  { timestamp: 1723760356910, value: 2569.14064357838 },
  { timestamp: 1723763088207, value: 2579.374087724198 },
  { timestamp: 1723767811925, value: 2576.0266210246673 },
  { timestamp: 1723770914676, value: 2569.3239038863753 },
  { timestamp: 1723774308393, value: 2566.151147259585 },
  { timestamp: 1723777317459, value: 2594.2283673190973 },
  { timestamp: 1723782075465, value: 2580.584000958953 },
  { timestamp: 1723784599213, value: 2597.1407223477163 },
  { timestamp: 1723788634608, value: 2597.8926121264526 },
  { timestamp: 1723792726746, value: 2622.585731863468 },
  { timestamp: 1723795462991, value: 2616.9785873367464 },
  { timestamp: 1723800225192, value: 2623.0008326910465 },
  { timestamp: 1723803675576, value: 2613.373706440344 },
  { timestamp: 1723807105898, value: 2614.1948685236753 },
  { timestamp: 1723811010274, value: 2594.1735529863386 },
  { timestamp: 1723813968306, value: 2579.809040322701 },
  { timestamp: 1723817695351, value: 2586.6921194748998 },
  { timestamp: 1723821290275, value: 2587.012992384228 },
  { timestamp: 1723824216089, value: 2570.6692954358673 },
  { timestamp: 1723828173447, value: 2589.2700448154424 },
  { timestamp: 1723831629074, value: 2620.2287253954178 },
  { timestamp: 1723836217931, value: 2620.30632369861 },
  { timestamp: 1723838769668, value: 2622.916234251069 },
  { timestamp: 1723843186682, value: 2604.5651566772112 },
  { timestamp: 1723845644353, value: 2600.2757111657625 },
  { timestamp: 1723849251007, value: 2597.7256296458568 },
  { timestamp: 1723852976321, value: 2591.834992320734 },
  { timestamp: 1723857774195, value: 2604.746757849194 },
  { timestamp: 1723861314176, value: 2600.64514595735 },
  { timestamp: 1723864282468, value: 2598.1818759141333 },
  { timestamp: 1723867756759, value: 2597.524849322721 },
  { timestamp: 1723871577042, value: 2600.670455188845 },
  { timestamp: 1723875165721, value: 2599.495222586961 },
  { timestamp: 1723878770994, value: 2596.614259409954 },
  { timestamp: 1723882346372, value: 2599.327830313222 },
  { timestamp: 1723885524904, value: 2601.658282180156 },
  { timestamp: 1723889033898, value: 2602.2971842213456 },
  { timestamp: 1723893220419, value: 2599.9854002140487 },
  { timestamp: 1723896641210, value: 2598.7879972538517 },
  { timestamp: 1723900077002, value: 2602.708997810046 },
  { timestamp: 1723904623599, value: 2604.869980024552 },
  { timestamp: 1723908354646, value: 2615.533082251437 },
  { timestamp: 1723911029031, value: 2610.537570001233 },
  { timestamp: 1723914163122, value: 2620.690078949853 },
  { timestamp: 1723917971794, value: 2616.169462141411 },
  { timestamp: 1723922030796, value: 2615.0672289353683 },
  { timestamp: 1723925023550, value: 2614.5752149016093 },
  { timestamp: 1723929050222, value: 2617.1178021785136 },
  { timestamp: 1723932343077, value: 2607.4062373551637 },
  { timestamp: 1723936360227, value: 2611.140943075699 },
  { timestamp: 1723939668280, value: 2613.80930624316 },
  { timestamp: 1723943308875, value: 2616.929341544344 },
  { timestamp: 1723946840649, value: 2608.329696747996 },
  { timestamp: 1723950120758, value: 2632.858057373436 },
  { timestamp: 1723954287233, value: 2608.4496953707844 },
  { timestamp: 1723957980880, value: 2608.0660852860806 },
  { timestamp: 1723961756192, value: 2604.4008709708414 },
  { timestamp: 1723965068233, value: 2606.8744158059308 },
  { timestamp: 1723968958103, value: 2608.120091053025 },
  { timestamp: 1723971925612, value: 2617.086076256027 },
  { timestamp: 1723975660479, value: 2648.4765882001566 },
  { timestamp: 1723979730628, value: 2650.0060023951855 },
  { timestamp: 1723982699637, value: 2650.920173996522 },
  { timestamp: 1723986754372, value: 2677.9453612806205 },
  { timestamp: 1723990711324, value: 2664.093773869774 },
  { timestamp: 1723994281344, value: 2663.510810803318 },
  { timestamp: 1723997371909, value: 2674.3853985767787 },
  { timestamp: 1724000494776, value: 2668.890410878294 },
  { timestamp: 1724004883308, value: 2653.3002069789873 },
  { timestamp: 1724007766382, value: 2660.718420875832 },
  { timestamp: 1724012369167, value: 2668.7996688103426 },
  { timestamp: 1724015004310, value: 2668.9561948550513 },
  { timestamp: 1724018576153, value: 2645.3625322356784 },
  { timestamp: 1724022866986, value: 2644.699315568237 },
  { timestamp: 1724026017509, value: 2613.6531087763074 },
  { timestamp: 1724029757351, value: 2616.2195417879143 },
  { timestamp: 1724032891907, value: 2631.3451873209956 },
  { timestamp: 1724037101874, value: 2641.359942557777 },
  { timestamp: 1724040678452, value: 2645.2067675447365 },
  { timestamp: 1724044397900, value: 2633.443546288493 },
  { timestamp: 1724048057056, value: 2620.552272647714 },
  { timestamp: 1724051514058, value: 2628.974986090578 },
  { timestamp: 1724055213921, value: 2618.359194858258 },
  { timestamp: 1724058631477, value: 2615.0156146326854 },
  { timestamp: 1724062139145, value: 2576.0519845618496 },
  { timestamp: 1724066419309, value: 2579.8979824245994 },
  { timestamp: 1724069220932, value: 2589.233719780614 },
  { timestamp: 1724073621994, value: 2592.4376954367644 },
  { timestamp: 1724076457046, value: 2573.609758086497 },
  { timestamp: 1724080890707, value: 2581.1911532476197 },
  { timestamp: 1724084075117, value: 2581.0421534415127 },
  { timestamp: 1724087135173, value: 2586.158182930824 },
  { timestamp: 1724090423905, value: 2608.285364813862 },
  { timestamp: 1724094337737, value: 2607.9148255966084 },
  { timestamp: 1724098300203, value: 2609.384772486645 },
  { timestamp: 1724101373958, value: 2616.4172249858557 },
  { timestamp: 1724105648568, value: 2619.7421031363792 },
  { timestamp: 1724109268897, value: 2619.0739129658346 },
  { timestamp: 1724113284734, value: 2629.9214709869375 },
  { timestamp: 1724116998644, value: 2661.3857497562835 },
  { timestamp: 1724119978246, value: 2649.612627592351 },
  { timestamp: 1724123571158, value: 2652.976583452757 },
  { timestamp: 1724127489098, value: 2670.0067477738435 },
  { timestamp: 1724130049199, value: 2666.4547112610658 },
  { timestamp: 1724133859599, value: 2682.993734487709 },
  { timestamp: 1724138534047, value: 2674.4094413065077 },
  { timestamp: 1724141493449, value: 2670.485279188686 },
  { timestamp: 1724144691470, value: 2662.571581557205 },
  { timestamp: 1724148881841, value: 2659.2747187445384 },
  { timestamp: 1724152070629, value: 2658.446673002101 },
  { timestamp: 1724156050331, value: 2648.794744966516 },
  { timestamp: 1724156489000, value: 2650.77343047746 },
];

export const calculatorToken1PriceMockData: ChartPrice[] = [
  { timestamp: 1721570692585, value: 1.0004717664170109 },
  { timestamp: 1721574226821, value: 0.9999257048549992 },
  { timestamp: 1721577792925, value: 1.0005518185581708 },
  { timestamp: 1721581712241, value: 1.000235602130579 },
  { timestamp: 1721585215776, value: 1.000180547747226 },
  { timestamp: 1721589043125, value: 0.9995033343105079 },
  { timestamp: 1721592627962, value: 1.0013270037675 },
  { timestamp: 1721596272203, value: 0.999909483691631 },
  { timestamp: 1721599282206, value: 1.000409936408258 },
  { timestamp: 1721603018450, value: 0.9999453381735575 },
  { timestamp: 1721606638370, value: 1.0005735041213142 },
  { timestamp: 1721610190737, value: 0.9980118877722214 },
  { timestamp: 1721614059491, value: 1.0000032385148787 },
  { timestamp: 1721617782706, value: 0.999545519213163 },
  { timestamp: 1721621158035, value: 1.0002037755720412 },
  { timestamp: 1721625267490, value: 0.9998307944327391 },
  { timestamp: 1721628277338, value: 0.9996840359541652 },
  { timestamp: 1721632414062, value: 0.9998269053047505 },
  { timestamp: 1721635374097, value: 0.9994143020853653 },
  { timestamp: 1721638859069, value: 0.9998467573780246 },
  { timestamp: 1721642711623, value: 1.0000423871822326 },
  { timestamp: 1721646169655, value: 1.0002635297463864 },
  { timestamp: 1721650201238, value: 1.0000090964275024 },
  { timestamp: 1721653330041, value: 1.0001917789326695 },
  { timestamp: 1721656863347, value: 0.9992298305878745 },
  { timestamp: 1721660690886, value: 0.9993516545618948 },
  { timestamp: 1721664498320, value: 0.9999387884211578 },
  { timestamp: 1721668236785, value: 1.0002615488530073 },
  { timestamp: 1721671808436, value: 1.000592623376256 },
  { timestamp: 1721675272852, value: 1.0002316932531472 },
  { timestamp: 1721679011504, value: 0.99819063900477 },
  { timestamp: 1721682555935, value: 0.9999624763004786 },
  { timestamp: 1721685906953, value: 1.000290805293543 },
  { timestamp: 1721689579437, value: 1.000594749667491 },
  { timestamp: 1721693226834, value: 1.0003327883197108 },
  { timestamp: 1721697031650, value: 0.999995451184534 },
  { timestamp: 1721700588144, value: 1.0006445216146345 },
  { timestamp: 1721704149578, value: 1.000603924660812 },
  { timestamp: 1721707454872, value: 0.9995763302318159 },
  { timestamp: 1721710982617, value: 0.9992968920700102 },
  { timestamp: 1721715137206, value: 1.0003297535336644 },
  { timestamp: 1721718382358, value: 1.0002926598800461 },
  { timestamp: 1721721875135, value: 0.9998829178318303 },
  { timestamp: 1721725818245, value: 0.9998712877818818 },
  { timestamp: 1721729015752, value: 0.9994295869793484 },
  { timestamp: 1721732438634, value: 1.0000660972396507 },
  { timestamp: 1721736274979, value: 1.000195497065145 },
  { timestamp: 1721739893928, value: 1.000112851047124 },
  { timestamp: 1721743497125, value: 1.0008141306417269 },
  { timestamp: 1721747077867, value: 1.0001734020209732 },
  { timestamp: 1721752259032, value: 0.9998556127559267 },
  { timestamp: 1721757276342, value: 1.0001689382793992 },
  { timestamp: 1721759377167, value: 1.0009388876025318 },
  { timestamp: 1721762350037, value: 1.0010177161154272 },
  { timestamp: 1721765050632, value: 0.9997182481761538 },
  { timestamp: 1721768440448, value: 1.0001520776595485 },
  { timestamp: 1721772528320, value: 0.9998235243082052 },
  { timestamp: 1721775866922, value: 1.0003857892763484 },
  { timestamp: 1721779219852, value: 1.0001527910056072 },
  { timestamp: 1721783073820, value: 0.9998974754208851 },
  { timestamp: 1721786659243, value: 0.9998845861519742 },
  { timestamp: 1721790006492, value: 0.9996325142622934 },
  { timestamp: 1721793780158, value: 1.0000311361966485 },
  { timestamp: 1721797306056, value: 1.000380522594006 },
  { timestamp: 1721802282878, value: 1.0012133040329292 },
  { timestamp: 1721805171147, value: 0.9999765483585272 },
  { timestamp: 1721809288029, value: 0.9998853501640961 },
  { timestamp: 1721812182068, value: 0.9994522907343019 },
  { timestamp: 1721817388201, value: 0.9998295621406185 },
  { timestamp: 1721820243322, value: 1.00014732853725 },
  { timestamp: 1721823657274, value: 1.000113501531848 },
  { timestamp: 1721827786020, value: 1.0003811636270585 },
  { timestamp: 1721830229110, value: 1.0007226734986034 },
  { timestamp: 1721834549658, value: 0.9990401192275357 },
  { timestamp: 1721838964287, value: 1.0000362501798523 },
  { timestamp: 1721841272531, value: 1.0001178914453874 },
  { timestamp: 1721846125908, value: 1.0003525329162006 },
  { timestamp: 1721849328641, value: 0.9999418770060504 },
  { timestamp: 1721852500200, value: 1.0004643000117817 },
  { timestamp: 1721856884773, value: 0.9994949094687798 },
  { timestamp: 1721860480222, value: 0.999929874203935 },
  { timestamp: 1721862892236, value: 0.9996024214653754 },
  { timestamp: 1721867911823, value: 0.9997286925241587 },
  { timestamp: 1721871465590, value: 0.999504050283702 },
  { timestamp: 1721872855079, value: 0.9997818661885195 },
  { timestamp: 1721878498007, value: 1.0004088833808955 },
  { timestamp: 1721881921837, value: 0.9996911435711341 },
  { timestamp: 1721885308193, value: 0.9983784743394721 },
  { timestamp: 1721888572681, value: 0.9998411169234627 },
  { timestamp: 1721892355454, value: 1.000618782408457 },
  { timestamp: 1721895226262, value: 0.9993501993723304 },
  { timestamp: 1721898941553, value: 0.9994894545422727 },
  { timestamp: 1721903444593, value: 0.9993766624179331 },
  { timestamp: 1721907009117, value: 0.9997070791522613 },
  { timestamp: 1721910449833, value: 0.999688452099566 },
  { timestamp: 1721913243383, value: 0.9998423973481462 },
  { timestamp: 1721918237085, value: 1.0003812159245118 },
  { timestamp: 1721921926792, value: 0.9986081800706134 },
  { timestamp: 1721924485649, value: 1.0001438134632326 },
  { timestamp: 1721927823604, value: 1.000299882572535 },
  { timestamp: 1721931084087, value: 0.9998235128451092 },
  { timestamp: 1721936240275, value: 1.0004759630935909 },
  { timestamp: 1721938410610, value: 0.9993649366761486 },
  { timestamp: 1721941338448, value: 1.0001836904479542 },
  { timestamp: 1721947083865, value: 1.0001925314886977 },
  { timestamp: 1721950016581, value: 0.999476833945169 },
  { timestamp: 1721953630563, value: 1.0002475273831268 },
  { timestamp: 1721957853091, value: 0.9994848791042454 },
  { timestamp: 1721961140416, value: 1.0014393685834644 },
  { timestamp: 1721964587034, value: 0.9989643193885079 },
  { timestamp: 1721968070593, value: 0.9998042541344845 },
  { timestamp: 1721970356236, value: 0.999552300036078 },
  { timestamp: 1721975679019, value: 0.9995040605179677 },
  { timestamp: 1721979661686, value: 0.9994558371609333 },
  { timestamp: 1721982061520, value: 1.0000482538401088 },
  { timestamp: 1721984491239, value: 1.0002810125907824 },
  { timestamp: 1721989020268, value: 0.9996512669937964 },
  { timestamp: 1721993684757, value: 0.9994885343102922 },
  { timestamp: 1721997070268, value: 0.9998865588127923 },
  { timestamp: 1722001030462, value: 1.0002528324440694 },
  { timestamp: 1722002959342, value: 1.0000874105020634 },
  { timestamp: 1722008009559, value: 0.9996734755646424 },
  { timestamp: 1722011020461, value: 1.0006642221011517 },
  { timestamp: 1722014970073, value: 1.0000468888101284 },
  { timestamp: 1722018896507, value: 1.0001405849944744 },
  { timestamp: 1722021790793, value: 1.0000985765123727 },
  { timestamp: 1722026263734, value: 1.0006135245646721 },
  { timestamp: 1722029785502, value: 1.000295995661701 },
  { timestamp: 1722032506716, value: 1.0003239624817395 },
  { timestamp: 1722035392031, value: 0.9997193115602891 },
  { timestamp: 1722038588125, value: 1.0001481078385845 },
  { timestamp: 1722042082371, value: 1.000090392619962 },
  { timestamp: 1722046751860, value: 1.000271892032105 },
  { timestamp: 1722051449234, value: 1.000254521516903 },
  { timestamp: 1722054882981, value: 0.9998398850309727 },
  { timestamp: 1722058083362, value: 1.0007749970275188 },
  { timestamp: 1722062235851, value: 1.0000853900681947 },
  { timestamp: 1722064670387, value: 1.0000474028890203 },
  { timestamp: 1722069180778, value: 0.9993611120489895 },
  { timestamp: 1722071477642, value: 0.9999397567335754 },
  { timestamp: 1722075896296, value: 1.0000510589036755 },
  { timestamp: 1722078195124, value: 1.0000949754210176 },
  { timestamp: 1722082782644, value: 1.000274244411955 },
  { timestamp: 1722087201989, value: 1.0004006680863802 },
  { timestamp: 1722090514065, value: 0.9998720763186673 },
  { timestamp: 1722094633984, value: 0.9999441943763759 },
  { timestamp: 1722096891126, value: 1.000653498634862 },
  { timestamp: 1722099877129, value: 0.9999582985563727 },
  { timestamp: 1722104817583, value: 1.0007260941063405 },
  { timestamp: 1722108263230, value: 1.000411583821432 },
  { timestamp: 1722112065418, value: 0.9955874201856442 },
  { timestamp: 1722114259196, value: 1.0041191657143405 },
  { timestamp: 1722119598384, value: 0.9990657660911422 },
  { timestamp: 1722123148306, value: 0.9998264810085267 },
  { timestamp: 1722126768495, value: 1.0024331886260822 },
  { timestamp: 1722129817656, value: 0.9985170329747299 },
  { timestamp: 1722133029984, value: 0.9997632026533805 },
  { timestamp: 1722136226455, value: 0.9999964804088454 },
  { timestamp: 1722139434065, value: 0.9998490054959733 },
  { timestamp: 1722143514139, value: 0.9989062610661125 },
  { timestamp: 1722147441792, value: 0.9999993845639487 },
  { timestamp: 1722151251925, value: 0.999897042708874 },
  { timestamp: 1722154135193, value: 0.9999795720755147 },
  { timestamp: 1722158848766, value: 1.000116960389182 },
  { timestamp: 1722162417923, value: 0.9998587924939768 },
  { timestamp: 1722165201056, value: 0.9999679343964287 },
  { timestamp: 1722168541632, value: 0.9997950469685722 },
  { timestamp: 1722172144664, value: 1.000151414351088 },
  { timestamp: 1722176390646, value: 1.0000050513681793 },
  { timestamp: 1722180094537, value: 1.0000241216246435 },
  { timestamp: 1722182929714, value: 0.9995778314163457 },
  { timestamp: 1722186466117, value: 1.0003856689352635 },
  { timestamp: 1722190129251, value: 0.9999411260927652 },
  { timestamp: 1722193590330, value: 0.9997679560528232 },
  { timestamp: 1722198402947, value: 0.9999216610467337 },
  { timestamp: 1722202247518, value: 0.9999836156806777 },
  { timestamp: 1722205367525, value: 0.998795484342442 },
  { timestamp: 1722208939808, value: 1.0001622463620805 },
  { timestamp: 1722211361178, value: 1.0001652775682102 },
  { timestamp: 1722215307879, value: 0.9999075908140737 },
  { timestamp: 1722219569167, value: 1.0004448870303988 },
  { timestamp: 1722223499760, value: 0.9991806986983706 },
  { timestamp: 1722227201483, value: 1.0000125684980194 },
  { timestamp: 1722230891919, value: 0.9998226936010021 },
  { timestamp: 1722233487781, value: 1.0002052353240662 },
  { timestamp: 1722237946882, value: 0.9997688284966143 },
  { timestamp: 1722241900986, value: 0.9999251795895602 },
  { timestamp: 1722244576519, value: 1.0000732270870214 },
  { timestamp: 1722247471855, value: 1.0002359795449025 },
  { timestamp: 1722251300623, value: 0.9988032517992139 },
  { timestamp: 1722255296430, value: 0.9987356337784563 },
  { timestamp: 1722258369652, value: 1.0001068560958875 },
  { timestamp: 1722263747120, value: 0.9992801681555682 },
  { timestamp: 1722267117120, value: 1.0008161744268944 },
  { timestamp: 1722269902190, value: 0.9987519755617313 },
  { timestamp: 1722273703497, value: 0.9996585486187816 },
  { timestamp: 1722277299617, value: 0.9998363775424958 },
  { timestamp: 1722280228587, value: 0.9995879561372529 },
  { timestamp: 1722283404849, value: 0.9997137792276912 },
  { timestamp: 1722288233210, value: 0.9997267117755904 },
  { timestamp: 1722290897209, value: 1.0005138087664094 },
  { timestamp: 1722294702118, value: 0.9998461935598575 },
  { timestamp: 1722298266015, value: 0.9995765900027236 },
  { timestamp: 1722302246586, value: 0.9997432777255867 },
  { timestamp: 1722305989590, value: 0.99962548182366 },
  { timestamp: 1722308582074, value: 0.9998421450277768 },
  { timestamp: 1722312417828, value: 0.9994667811621013 },
  { timestamp: 1722316558903, value: 0.999190675027148 },
  { timestamp: 1722321529554, value: 0.9999168032701977 },
  { timestamp: 1722326494700, value: 0.9997342214390813 },
  { timestamp: 1722330415715, value: 0.9998948598486018 },
  { timestamp: 1722335048044, value: 0.9999705292522982 },
  { timestamp: 1722338532204, value: 1.0001017259224008 },
  { timestamp: 1722342029522, value: 0.9999055807498094 },
  { timestamp: 1722346605135, value: 0.9997451660396497 },
  { timestamp: 1722349767903, value: 0.9997871414534094 },
  { timestamp: 1722352573801, value: 1.0000373412634191 },
  { timestamp: 1722357238167, value: 0.9998047265056373 },
  { timestamp: 1722359143011, value: 0.9988117941969852 },
  { timestamp: 1722362843964, value: 0.9995142986112738 },
  { timestamp: 1722367928377, value: 1.0001887559941147 },
  { timestamp: 1722371545383, value: 0.9996519991012303 },
  { timestamp: 1722373422534, value: 0.9993698552819732 },
  { timestamp: 1722377504015, value: 0.9992398078149458 },
  { timestamp: 1722381937602, value: 0.9994352067296397 },
  { timestamp: 1722385340164, value: 0.9997137842714234 },
  { timestamp: 1722389059487, value: 0.9994723963206192 },
  { timestamp: 1722392024089, value: 0.99988513747806 },
  { timestamp: 1722396114627, value: 0.9996513667594525 },
  { timestamp: 1722400480717, value: 0.999635223337863 },
  { timestamp: 1722403984277, value: 1.0009074382068677 },
  { timestamp: 1722406593112, value: 1.0000779049594737 },
  { timestamp: 1722410710368, value: 0.9998712000334694 },
  { timestamp: 1722413741245, value: 0.9986910772624757 },
  { timestamp: 1722417935737, value: 0.9999138602713192 },
  { timestamp: 1722421158026, value: 0.9996079993831033 },
  { timestamp: 1722424584570, value: 0.9995052190014319 },
  { timestamp: 1722428269037, value: 0.9993867894671458 },
  { timestamp: 1722431393065, value: 1.000333887282731 },
  { timestamp: 1722434849005, value: 1.0004421253813325 },
  { timestamp: 1722438384569, value: 1.0004536121983267 },
  { timestamp: 1722442585163, value: 1.0007581448080431 },
  { timestamp: 1722446327986, value: 0.9997336238012943 },
  { timestamp: 1722450445441, value: 0.9993571212765106 },
  { timestamp: 1722454114148, value: 0.99865499572399 },
  { timestamp: 1722458220929, value: 0.9994339849507616 },
  { timestamp: 1722460029171, value: 1.0006753155079962 },
  { timestamp: 1722464002982, value: 0.9988975022101503 },
  { timestamp: 1722467239734, value: 0.9995864914092384 },
  { timestamp: 1722472130755, value: 0.9993271046311695 },
  { timestamp: 1722474173436, value: 0.9998341114517232 },
  { timestamp: 1722478865235, value: 1.0003537835467151 },
  { timestamp: 1722483226093, value: 1.0004691944952455 },
  { timestamp: 1722486024202, value: 0.9996479472320501 },
  { timestamp: 1722490267327, value: 0.9995734588999403 },
  { timestamp: 1722492352009, value: 0.9993849974869957 },
  { timestamp: 1722496080665, value: 0.9993838691783362 },
  { timestamp: 1722499696047, value: 1.0005803086338958 },
  { timestamp: 1722503762754, value: 0.9997542967433998 },
  { timestamp: 1722508145478, value: 0.999339183810803 },
  { timestamp: 1722511370948, value: 0.9999434981558712 },
  { timestamp: 1722514121610, value: 0.9993887344244385 },
  { timestamp: 1722517344788, value: 0.9995982047009133 },
  { timestamp: 1722521699870, value: 0.9992304920178554 },
  { timestamp: 1722525088917, value: 0.9978590268002097 },
  { timestamp: 1722528819052, value: 1.0014354077625642 },
  { timestamp: 1722532794163, value: 0.9990660911179725 },
  { timestamp: 1722536767158, value: 0.9989104249894396 },
  { timestamp: 1722539545390, value: 0.9991687832867779 },
  { timestamp: 1722543598938, value: 0.9992971810339509 },
  { timestamp: 1722547497522, value: 0.9994040044584168 },
  { timestamp: 1722550435106, value: 0.9999225943435722 },
  { timestamp: 1722554192900, value: 0.9981715477431808 },
  { timestamp: 1722556932226, value: 0.9990545360116161 },
  { timestamp: 1722561516778, value: 0.9984319296667169 },
  { timestamp: 1722565373271, value: 0.9990915907190661 },
  { timestamp: 1722568798357, value: 0.9990890702480649 },
  { timestamp: 1722571739711, value: 0.9992642275836797 },
  { timestamp: 1722575696317, value: 0.9989674015785139 },
  { timestamp: 1722578787084, value: 0.9988798429695893 },
  { timestamp: 1722582886927, value: 0.9984196072762038 },
  { timestamp: 1722586452447, value: 0.9999661319870252 },
  { timestamp: 1722589783635, value: 0.9990950352669421 },
  { timestamp: 1722593476494, value: 0.9990041293532376 },
  { timestamp: 1722597284622, value: 0.9984551821871068 },
  { timestamp: 1722600273690, value: 0.9990393696122959 },
  { timestamp: 1722603999456, value: 0.9986853658174273 },
  { timestamp: 1722607845134, value: 0.9992501647938865 },
  { timestamp: 1722612183458, value: 0.999055386908176 },
  { timestamp: 1722614606809, value: 0.9997398003545719 },
  { timestamp: 1722618992139, value: 0.999284011256219 },
  { timestamp: 1722621731234, value: 0.9989507271317233 },
  { timestamp: 1722625500418, value: 0.9991522336316171 },
  { timestamp: 1722630758418, value: 0.9955584809675007 },
  { timestamp: 1722633898285, value: 0.9989130163775957 },
  { timestamp: 1722636593602, value: 0.998956130430667 },
  { timestamp: 1722640002683, value: 0.997831709911573 },
  { timestamp: 1722643718139, value: 0.9980744928349193 },
  { timestamp: 1722648538683, value: 0.9987135908708391 },
  { timestamp: 1722651821238, value: 0.999541035645104 },
  { timestamp: 1722655422459, value: 0.9997859348971063 },
  { timestamp: 1722658485688, value: 0.9989145402859168 },
  { timestamp: 1722662177248, value: 0.9992842221283526 },
  { timestamp: 1722665284270, value: 0.9992916636866954 },
  { timestamp: 1722668953926, value: 0.999094238322198 },
  { timestamp: 1722672762197, value: 0.9989008367617908 },
  { timestamp: 1722675961555, value: 0.9989907798663614 },
  { timestamp: 1722680568420, value: 0.9988284423327227 },
  { timestamp: 1722682902764, value: 0.999300219292648 },
  { timestamp: 1722686918159, value: 0.9992464514075239 },
  { timestamp: 1722691564814, value: 0.9992495002797186 },
  { timestamp: 1722694939799, value: 1.000422449313048 },
  { timestamp: 1722698288072, value: 0.9993556275050544 },
  { timestamp: 1722700851813, value: 0.9989783691966669 },
  { timestamp: 1722706195616, value: 0.9994568400608824 },
  { timestamp: 1722710076923, value: 0.998713805359401 },
  { timestamp: 1722712689061, value: 0.9993954118499699 },
  { timestamp: 1722716055021, value: 0.9986189783158594 },
  { timestamp: 1722719875441, value: 0.9993193657205222 },
  { timestamp: 1722722875191, value: 0.9994853531859438 },
  { timestamp: 1722726268466, value: 0.9995333489377736 },
  { timestamp: 1722731107695, value: 0.9997532803209581 },
  { timestamp: 1722734009032, value: 0.9987495912406337 },
  { timestamp: 1722737324243, value: 0.9994806363817762 },
  { timestamp: 1722740621363, value: 0.9995056424673872 },
  { timestamp: 1722745122098, value: 0.9995872757996085 },
  { timestamp: 1722747757394, value: 0.9991667987639303 },
  { timestamp: 1722751252815, value: 0.9993689878004985 },
  { timestamp: 1722755534354, value: 0.9993347368279459 },
  { timestamp: 1722759292395, value: 0.9989196319866802 },
  { timestamp: 1722762409164, value: 0.99911856682077 },
  { timestamp: 1722766797187, value: 0.9991937240809806 },
  { timestamp: 1722770601103, value: 0.9998664887990304 },
  { timestamp: 1722773779932, value: 0.9989290211327059 },
  { timestamp: 1722776551609, value: 0.9998173052250909 },
  { timestamp: 1722780682559, value: 0.999538469762584 },
  { timestamp: 1722784907801, value: 0.9993115978874427 },
  { timestamp: 1722789274012, value: 0.9989885174519068 },
  { timestamp: 1722792137422, value: 0.9996724590269923 },
  { timestamp: 1722795144408, value: 1.000247613651877 },
  { timestamp: 1722799989150, value: 1.0003399446041707 },
  { timestamp: 1722801913960, value: 0.9997166894345347 },
  { timestamp: 1722805433933, value: 0.9998096602286722 },
  { timestamp: 1722809851494, value: 1.0001596830958632 },
  { timestamp: 1722812975765, value: 0.9989869229589133 },
  { timestamp: 1722818040952, value: 0.9989069829390304 },
  { timestamp: 1722819635319, value: 0.9993466599065376 },
  { timestamp: 1722825103174, value: 1.001349516965064 },
  { timestamp: 1722827398604, value: 0.9995468327509741 },
  { timestamp: 1722831417808, value: 1.0036054343408383 },
  { timestamp: 1722835199231, value: 1.0003645916779298 },
  { timestamp: 1722839071254, value: 0.9953616459609796 },
  { timestamp: 1722842985213, value: 1.0051909578109484 },
  { timestamp: 1722844878126, value: 0.9988451287867782 },
  { timestamp: 1722849140054, value: 0.998932890558148 },
  { timestamp: 1722854095057, value: 0.9962411859532481 },
  { timestamp: 1722856759648, value: 1.0024676241205124 },
  { timestamp: 1722859972564, value: 0.998959437218791 },
  { timestamp: 1722864419996, value: 0.9983594930994243 },
  { timestamp: 1722867866116, value: 1.0049443919970416 },
  { timestamp: 1722871647145, value: 1.001259146652132 },
  { timestamp: 1722875054835, value: 0.9992804745415154 },
  { timestamp: 1722878233332, value: 1.0024976274843764 },
  { timestamp: 1722882073467, value: 0.9997847574112068 },
  { timestamp: 1722885157227, value: 0.9978127876414743 },
  { timestamp: 1722890240248, value: 0.9972609635752665 },
  { timestamp: 1722893499230, value: 0.999702922530726 },
  { timestamp: 1722897078359, value: 1.0011752458276988 },
  { timestamp: 1722900891088, value: 1.0005024822973996 },
  { timestamp: 1722904396806, value: 1.001408054755975 },
  { timestamp: 1722907243300, value: 1.0009071364965902 },
  { timestamp: 1722911343568, value: 0.9997651044926732 },
  { timestamp: 1722915094473, value: 1.0008343306844532 },
  { timestamp: 1722918890260, value: 1.0009370747970447 },
  { timestamp: 1722921590003, value: 0.9995189664954527 },
  { timestamp: 1722925771325, value: 1.002930273370263 },
  { timestamp: 1722927948738, value: 1.0009240775672457 },
  { timestamp: 1722932939388, value: 0.9992982901156585 },
  { timestamp: 1722935025310, value: 1.0012858854678304 },
  { timestamp: 1722940368251, value: 1.0018017316877503 },
  { timestamp: 1722943705679, value: 1.0003752623362974 },
  { timestamp: 1722947505729, value: 1.0004034542546716 },
  { timestamp: 1722949588144, value: 1.0005884792140627 },
  { timestamp: 1722953858613, value: 1.0014460234346296 },
  { timestamp: 1722958535105, value: 0.9977882930300358 },
  { timestamp: 1722960352906, value: 1.0018412816613504 },
  { timestamp: 1722963721656, value: 1.0010086925889405 },
  { timestamp: 1722968803055, value: 0.999459648456116 },
  { timestamp: 1722973056117, value: 1.0011408488785245 },
  { timestamp: 1722975343922, value: 0.9996995755112673 },
  { timestamp: 1722980270978, value: 1.0014088903196112 },
  { timestamp: 1722983030644, value: 1.000915913114622 },
  { timestamp: 1722985951933, value: 1.0024067866172266 },
  { timestamp: 1722990713022, value: 1.000976761702557 },
  { timestamp: 1722994316691, value: 1.0010410599322406 },
  { timestamp: 1722997403700, value: 1.000944813110958 },
  { timestamp: 1723000136524, value: 1.0013524974784844 },
  { timestamp: 1723003496456, value: 0.9999759803087335 },
  { timestamp: 1723007512247, value: 1.0005113772161343 },
  { timestamp: 1723011081077, value: 1.0015013639637471 },
  { timestamp: 1723014300029, value: 0.9993435466286553 },
  { timestamp: 1723017860893, value: 1.0006129920547928 },
  { timestamp: 1723023551748, value: 1.0007178079273977 },
  { timestamp: 1723025329544, value: 0.9992774902251241 },
  { timestamp: 1723029631393, value: 1.0006987259834677 },
  { timestamp: 1723034509540, value: 0.9988898085921372 },
  { timestamp: 1723039520562, value: 1.0004099680231355 },
  { timestamp: 1723045563639, value: 0.999145491875929 },
  { timestamp: 1723049991992, value: 1.0033260343726034 },
  { timestamp: 1723050331575, value: 1.0010653459496779 },
  { timestamp: 1723050420473, value: 1.0010653459496779 },
  { timestamp: 1723053738518, value: 0.9994585626938473 },
  { timestamp: 1723057593193, value: 1.0025856587654531 },
  { timestamp: 1723060968027, value: 0.999463612484467 },
  { timestamp: 1723064460331, value: 1.0012387257002997 },
  { timestamp: 1723068516763, value: 1.0000041454724318 },
  { timestamp: 1723072196383, value: 1.0011500506470443 },
  { timestamp: 1723075784811, value: 1.0007632081287399 },
  { timestamp: 1723079068106, value: 1.00149073724987 },
  { timestamp: 1723082920392, value: 1.0028976507061218 },
  { timestamp: 1723086361289, value: 1.002665171741375 },
  { timestamp: 1723089734731, value: 0.9991144536053918 },
  { timestamp: 1723093745438, value: 1.0008208287708695 },
  { timestamp: 1723097622982, value: 1.0001617472873776 },
  { timestamp: 1723101339669, value: 1.0006284487718757 },
  { timestamp: 1723104823353, value: 1.0000043892248518 },
  { timestamp: 1723107983124, value: 0.9995910259155592 },
  { timestamp: 1723112005546, value: 0.9999772350399859 },
  { timestamp: 1723115617990, value: 0.9999000047138548 },
  { timestamp: 1723119314078, value: 0.9989108506269226 },
  { timestamp: 1723122645461, value: 1.000293741041206 },
  { timestamp: 1723125838534, value: 1.0007989780207598 },
  { timestamp: 1723129260674, value: 1.002928044620169 },
  { timestamp: 1723132853489, value: 1.0004489581939526 },
  { timestamp: 1723136994772, value: 0.9981347720394814 },
  { timestamp: 1723140073367, value: 0.9990348069457552 },
  { timestamp: 1723143689240, value: 0.999812641883455 },
  { timestamp: 1723148298308, value: 1.0005315240044945 },
  { timestamp: 1723150955518, value: 0.9997068184827574 },
  { timestamp: 1723154561979, value: 1.006519994475649 },
  { timestamp: 1723158298945, value: 1.002314772169451 },
  { timestamp: 1723162509663, value: 0.9979277051559026 },
  { timestamp: 1723166209401, value: 1.0012045903039293 },
  { timestamp: 1723169641942, value: 1.0005241639066655 },
  { timestamp: 1723172438811, value: 0.9994459482281048 },
  { timestamp: 1723176973706, value: 0.9996727711800066 },
  { timestamp: 1723180684397, value: 1.0008603361896833 },
  { timestamp: 1723183998834, value: 0.9999355412828034 },
  { timestamp: 1723187378949, value: 1.0007064906907466 },
  { timestamp: 1723191543735, value: 1.0000426729517076 },
  { timestamp: 1723195004664, value: 0.9989825835106925 },
  { timestamp: 1723198636183, value: 1.0006522704479233 },
  { timestamp: 1723201359196, value: 1.0009739206457833 },
  { timestamp: 1723205524476, value: 1.0003842092158555 },
  { timestamp: 1723208818038, value: 1.0018286556169045 },
  { timestamp: 1723212677574, value: 1.0084393547120907 },
  { timestamp: 1723215953670, value: 1.0004234204888531 },
  { timestamp: 1723220085639, value: 0.9995196896015062 },
  { timestamp: 1723223901228, value: 1.0018534491022062 },
  { timestamp: 1723227642483, value: 0.9989756613855877 },
  { timestamp: 1723230733999, value: 1.0010805085982168 },
  { timestamp: 1723234466264, value: 1.0012649125161968 },
  { timestamp: 1723237977652, value: 0.9994428756226987 },
  { timestamp: 1723241763556, value: 0.9988434227264141 },
  { timestamp: 1723245019758, value: 0.9995266917706056 },
  { timestamp: 1723248833580, value: 1.0004003047132637 },
  { timestamp: 1723252452240, value: 1.0009449363084013 },
  { timestamp: 1723255579052, value: 1.0012617540795576 },
  { timestamp: 1723259349932, value: 0.9993461590880357 },
  { timestamp: 1723262908353, value: 0.9996688760667148 },
  { timestamp: 1723266592128, value: 1.000397082047019 },
  { timestamp: 1723270253105, value: 1.0001492363589075 },
  { timestamp: 1723273565754, value: 1.0008859686439149 },
  { timestamp: 1723277563062, value: 0.9998417066111125 },
  { timestamp: 1723280676526, value: 1.0008311437326396 },
  { timestamp: 1723284618597, value: 0.9999761026734327 },
  { timestamp: 1723288479773, value: 1.000336959592173 },
  { timestamp: 1723291252071, value: 1.0003303618402788 },
  { timestamp: 1723295641332, value: 0.9997661391175793 },
  { timestamp: 1723298860466, value: 1.000331671054789 },
  { timestamp: 1723302514904, value: 0.9999575417798596 },
  { timestamp: 1723306713366, value: 1.0015722871460642 },
  { timestamp: 1723309691732, value: 0.9998775106609491 },
  { timestamp: 1723313248868, value: 1.0007639813972802 },
  { timestamp: 1723317436474, value: 1.0005371857655736 },
  { timestamp: 1723320030853, value: 0.9998532838227808 },
  { timestamp: 1723324514247, value: 1.0008480617213922 },
  { timestamp: 1723327672768, value: 0.9997877418670351 },
  { timestamp: 1723330839184, value: 1.0001200791573783 },
  { timestamp: 1723334983882, value: 1.0009697574193521 },
  { timestamp: 1723338302484, value: 1.0002525107049178 },
  { timestamp: 1723341732663, value: 1.0000217117098051 },
  { timestamp: 1723345817503, value: 1.0005928132775608 },
  { timestamp: 1723349216998, value: 0.9999222843982274 },
  { timestamp: 1723352685505, value: 1.0000325088732964 },
  { timestamp: 1723356608093, value: 0.9999297956905305 },
  { timestamp: 1723360252104, value: 1.0004667163368743 },
  { timestamp: 1723363390624, value: 1.0002780378701897 },
  { timestamp: 1723367156240, value: 1.0004919346278132 },
  { timestamp: 1723371102687, value: 1.0015166786548302 },
  { timestamp: 1723374111547, value: 1.0002101917320327 },
  { timestamp: 1723377690309, value: 1.0006390256595423 },
  { timestamp: 1723382258506, value: 1.0007304431356965 },
  { timestamp: 1723385654959, value: 1.0001091530705355 },
  { timestamp: 1723388728430, value: 1.000136552026002 },
  { timestamp: 1723393043875, value: 1.0000533424291402 },
  { timestamp: 1723395621352, value: 0.9995337661077075 },
  { timestamp: 1723399712773, value: 0.9998393196091637 },
  { timestamp: 1723404076959, value: 0.999449973337989 },
  { timestamp: 1723406884886, value: 0.9966586320879744 },
  { timestamp: 1723410620985, value: 0.9994270014454752 },
  { timestamp: 1723414649411, value: 0.9994463439867781 },
  { timestamp: 1723418620706, value: 1.0005751318421945 },
  { timestamp: 1723420870225, value: 1.0006695520459041 },
  { timestamp: 1723425235349, value: 0.9995123086821672 },
  { timestamp: 1723429285172, value: 1.0015785102043897 },
  { timestamp: 1723432756274, value: 0.9994578950246256 },
  { timestamp: 1723435960320, value: 1.0003392627334955 },
  { timestamp: 1723439482249, value: 1.000119587866493 },
  { timestamp: 1723442984717, value: 1.0000651824214422 },
  { timestamp: 1723446258734, value: 1.0006936554378305 },
  { timestamp: 1723450774586, value: 0.9994915589228806 },
  { timestamp: 1723453398088, value: 0.9994077168029292 },
  { timestamp: 1723457600825, value: 1.0008992754824162 },
  { timestamp: 1723461105504, value: 1.0018652813450504 },
  { timestamp: 1723464576168, value: 0.9994814286526046 },
  { timestamp: 1723468055463, value: 0.999610517153727 },
  { timestamp: 1723472459400, value: 1.0064144912429285 },
  { timestamp: 1723476097164, value: 1.0015510668769787 },
  { timestamp: 1723479528189, value: 0.9991440336036741 },
  { timestamp: 1723483444380, value: 1.0005268226992323 },
  { timestamp: 1723486950958, value: 0.9991210635035033 },
  { timestamp: 1723489821088, value: 1.0005540420898493 },
  { timestamp: 1723493023201, value: 0.9998944239843323 },
  { timestamp: 1723496512531, value: 0.9995524615495949 },
  { timestamp: 1723501171284, value: 1.0002215605912326 },
  { timestamp: 1723504221130, value: 1.0004256061804546 },
  { timestamp: 1723507959861, value: 1.0012493873663337 },
  { timestamp: 1723511028653, value: 0.9998168658355263 },
  { timestamp: 1723514912521, value: 0.9994312531082937 },
  { timestamp: 1723518327003, value: 1.0005899203042967 },
  { timestamp: 1723522857142, value: 1.000519272889767 },
  { timestamp: 1723526605946, value: 1.000463847497489 },
  { timestamp: 1723529977125, value: 1.0001285977033123 },
  { timestamp: 1723533446247, value: 1.0003659052861467 },
  { timestamp: 1723537054085, value: 1.0002175027905746 },
  { timestamp: 1723540887036, value: 0.9993605343269996 },
  { timestamp: 1723543433430, value: 1.0005444800242411 },
  { timestamp: 1723547366360, value: 1.0002880362021136 },
  { timestamp: 1723550446947, value: 0.9999257665558302 },
  { timestamp: 1723554569311, value: 1.0000131611007652 },
  { timestamp: 1723558694805, value: 1.0013164124065397 },
  { timestamp: 1723562591359, value: 0.9997021241736862 },
  { timestamp: 1723565358515, value: 1.0009687930993232 },
  { timestamp: 1723568879383, value: 1.0003897113475668 },
  { timestamp: 1723572040260, value: 0.9993658588508044 },
  { timestamp: 1723576453083, value: 0.9997427753906383 },
  { timestamp: 1723580371473, value: 0.9991695668484132 },
  { timestamp: 1723582988868, value: 0.9993421278648065 },
  { timestamp: 1723586589340, value: 1.001631514246213 },
  { timestamp: 1723590961843, value: 1.0000445899080486 },
  { timestamp: 1723594570232, value: 1.0006970489559315 },
  { timestamp: 1723597940094, value: 1.0000516228500822 },
  { timestamp: 1723601812961, value: 1.000463193268502 },
  { timestamp: 1723605573494, value: 1.0009655411227876 },
  { timestamp: 1723609009684, value: 1.000434098184872 },
  { timestamp: 1723612623438, value: 1.001850983331879 },
  { timestamp: 1723616089125, value: 1.0004758783001244 },
  { timestamp: 1723619186098, value: 1.0006234568396468 },
  { timestamp: 1723622535096, value: 1.0005495361249177 },
  { timestamp: 1723626032164, value: 1.000223743102422 },
  { timestamp: 1723630239268, value: 1.0010580790856796 },
  { timestamp: 1723634513006, value: 0.9999684514747745 },
  { timestamp: 1723637068535, value: 1.0004791523155658 },
  { timestamp: 1723640732552, value: 1.000936458256426 },
  { timestamp: 1723645100253, value: 0.9983527760840241 },
  { timestamp: 1723648472109, value: 0.9985434869513448 },
  { timestamp: 1723652401196, value: 1.0003665316313402 },
  { timestamp: 1723655949326, value: 1.0010881426850309 },
  { timestamp: 1723659397726, value: 0.9998784665403895 },
  { timestamp: 1723662442224, value: 1.0006001738296726 },
  { timestamp: 1723666152155, value: 1.000342170964659 },
  { timestamp: 1723670212310, value: 0.9999285195133852 },
  { timestamp: 1723673327006, value: 0.9991444379278129 },
  { timestamp: 1723676489607, value: 1.0003358691357105 },
  { timestamp: 1723680440128, value: 0.9995672506900928 },
  { timestamp: 1723683798315, value: 1.0003727646206375 },
  { timestamp: 1723688079546, value: 1.0004871352681468 },
  { timestamp: 1723692125602, value: 1.000032698162929 },
  { timestamp: 1723694772468, value: 0.9999715349108094 },
  { timestamp: 1723698376240, value: 1.0004570531235444 },
  { timestamp: 1723702115877, value: 1.0005717039319664 },
  { timestamp: 1723705597924, value: 1.0004481377891183 },
  { timestamp: 1723709525770, value: 1.0001046468963966 },
  { timestamp: 1723712922413, value: 1.0009624812398326 },
  { timestamp: 1723716134906, value: 0.9999642583835243 },
  { timestamp: 1723720767512, value: 1.0003948706718189 },
  { timestamp: 1723724532615, value: 1.0001892663850653 },
  { timestamp: 1723727498292, value: 1.0004973795037924 },
  { timestamp: 1723731719585, value: 0.999790797830302 },
  { timestamp: 1723735480682, value: 1.0014035106091854 },
  { timestamp: 1723737769670, value: 0.9997932027769935 },
  { timestamp: 1723741466906, value: 1.0000205050218585 },
  { timestamp: 1723745302732, value: 0.9953173628006523 },
  { timestamp: 1723749333013, value: 0.9996975130627551 },
  { timestamp: 1723752764351, value: 0.9984051322093317 },
  { timestamp: 1723755670175, value: 1.002727965276283 },
  { timestamp: 1723759997533, value: 1.0005301542930525 },
  { timestamp: 1723763813995, value: 1.0006271891196648 },
  { timestamp: 1723766908211, value: 0.9996685953520892 },
  { timestamp: 1723770910977, value: 0.9996573256165662 },
  { timestamp: 1723773933706, value: 1.001926742875309 },
  { timestamp: 1723777818213, value: 0.9998518989215877 },
  { timestamp: 1723781009036, value: 1.0004184494086168 },
  { timestamp: 1723784953668, value: 0.9994835805601485 },
  { timestamp: 1723789099663, value: 1.0002194408632563 },
  { timestamp: 1723791670978, value: 0.999426811833054 },
  { timestamp: 1723796178200, value: 1.0007805315032412 },
  { timestamp: 1723799123144, value: 0.9999478142040912 },
  { timestamp: 1723802443587, value: 1.0003519726264782 },
  { timestamp: 1723806068564, value: 1.000206797320933 },
  { timestamp: 1723810721712, value: 0.9995175245730973 },
  { timestamp: 1723813925618, value: 1.0000657250716984 },
  { timestamp: 1723817008562, value: 1.0022826309110278 },
  { timestamp: 1723821776551, value: 1.0000876192508625 },
  { timestamp: 1723825375488, value: 1.0010112836229672 },
  { timestamp: 1723828587722, value: 1.0012169447378032 },
  { timestamp: 1723832521183, value: 1.0009600120677835 },
  { timestamp: 1723835038069, value: 1.0008773842691694 },
  { timestamp: 1723838979004, value: 1.0003990297412482 },
  { timestamp: 1723843013439, value: 0.9996929591547882 },
  { timestamp: 1723846737827, value: 0.9999804123834739 },
  { timestamp: 1723850007260, value: 0.9998014342587799 },
  { timestamp: 1723853282173, value: 1.0005532281564526 },
  { timestamp: 1723857580396, value: 1.0002793873647993 },
  { timestamp: 1723860413477, value: 1.0011489382381369 },
  { timestamp: 1723864720500, value: 0.9999041184777098 },
  { timestamp: 1723867586124, value: 1.000762126485984 },
  { timestamp: 1723871803161, value: 1.0003027068546186 },
  { timestamp: 1723875454876, value: 1.0003434985912738 },
  { timestamp: 1723878146733, value: 1.000123752655561 },
  { timestamp: 1723881974004, value: 1.0001827422566023 },
  { timestamp: 1723886488049, value: 1.000241523087301 },
  { timestamp: 1723888852979, value: 1.0003562829567707 },
  { timestamp: 1723893651172, value: 1.0002573796403318 },
  { timestamp: 1723896770291, value: 1.000031389469561 },
  { timestamp: 1723900225119, value: 1.000030300999141 },
  { timestamp: 1723904597644, value: 0.99986809596993 },
  { timestamp: 1723906930024, value: 1.0012716554877472 },
  { timestamp: 1723911591851, value: 1.0003507608780309 },
  { timestamp: 1723915224925, value: 0.999770109130346 },
  { timestamp: 1723917877931, value: 0.9994329603019061 },
  { timestamp: 1723921557030, value: 1.0004008600119352 },
  { timestamp: 1723925742998, value: 1.0000529211682196 },
  { timestamp: 1723928484165, value: 1.000199520961543 },
  { timestamp: 1723932327893, value: 1.0003360815622524 },
  { timestamp: 1723935783757, value: 1.0000706908599537 },
  { timestamp: 1723939390331, value: 1.0005105350024412 },
  { timestamp: 1723943319126, value: 0.9996187614095609 },
  { timestamp: 1723947069103, value: 1.0002287397164344 },
  { timestamp: 1723950409515, value: 0.9997456903961331 },
  { timestamp: 1723954572472, value: 1.000242992579386 },
  { timestamp: 1723957954659, value: 1.0007123272229883 },
  { timestamp: 1723961532407, value: 1.0002742178406854 },
  { timestamp: 1723964459621, value: 0.9998924733192657 },
  { timestamp: 1723968935081, value: 1.0003578805708433 },
  { timestamp: 1723971767764, value: 1.0003901299760327 },
  { timestamp: 1723975833982, value: 1.0004150778740089 },
  { timestamp: 1723979166201, value: 1.000278813367796 },
  { timestamp: 1723983152706, value: 0.9996570275066043 },
  { timestamp: 1723986328947, value: 0.9998261418601073 },
  { timestamp: 1723990448620, value: 1.000334608458996 },
  { timestamp: 1723993236064, value: 1.0002622804931047 },
  { timestamp: 1723997665516, value: 0.9996206389117374 },
  { timestamp: 1724001492540, value: 1.0001718911301807 },
  { timestamp: 1724004642107, value: 1.0003411804059954 },
  { timestamp: 1724008560119, value: 1.0002283250505952 },
  { timestamp: 1724012331585, value: 1.0001311577069327 },
  { timestamp: 1724015166079, value: 1.0002118006830352 },
  { timestamp: 1724018563132, value: 1.0010646351261954 },
  { timestamp: 1724022046925, value: 1.0003847781234652 },
  { timestamp: 1724025859752, value: 0.9995979062182182 },
  { timestamp: 1724029712362, value: 0.9998607440181514 },
  { timestamp: 1724033533158, value: 1.0002899041526092 },
  { timestamp: 1724037188704, value: 1.0001335074753688 },
  { timestamp: 1724040376436, value: 1.000116684151659 },
  { timestamp: 1724044299801, value: 0.9994480198775235 },
  { timestamp: 1724048345592, value: 1.0001196180935932 },
  { timestamp: 1724051801223, value: 0.9999569471667579 },
  { timestamp: 1724054760951, value: 0.9998115823958208 },
  { timestamp: 1724058188970, value: 1.0003728898764956 },
  { timestamp: 1724062376491, value: 1.0000975734154263 },
  { timestamp: 1724065934032, value: 1.0011508404067424 },
  { timestamp: 1724069619662, value: 1.0004584930827378 },
  { timestamp: 1724073681889, value: 1.000767724067299 },
  { timestamp: 1724076163695, value: 0.9999163713665548 },
  { timestamp: 1724080869612, value: 1.00000563846371 },
  { timestamp: 1724084159373, value: 0.9993622218970385 },
  { timestamp: 1724088088576, value: 1.0004718629578104 },
  { timestamp: 1724091368160, value: 0.9995887697558521 },
  { timestamp: 1724094670224, value: 0.999933986378045 },
  { timestamp: 1724098763357, value: 0.9999949806243295 },
  { timestamp: 1724102524007, value: 1.0004372780786455 },
  { timestamp: 1724105783319, value: 1.000365100390457 },
  { timestamp: 1724109051950, value: 0.999903049680145 },
  { timestamp: 1724113183008, value: 1.0004061666486155 },
  { timestamp: 1724116793316, value: 1.00012867256642 },
  { timestamp: 1724119275646, value: 1.0005008997380191 },
  { timestamp: 1724124189782, value: 1.00029139766175 },
  { timestamp: 1724127433409, value: 1.0023862764010614 },
  { timestamp: 1724130045777, value: 1.0006388890384474 },
  { timestamp: 1724134253027, value: 1.0001073892750123 },
  { timestamp: 1724137592910, value: 1.0002585573973763 },
  { timestamp: 1724141770999, value: 0.9999120266495102 },
  { timestamp: 1724145631853, value: 1.001103346518615 },
  { timestamp: 1724149733490, value: 1.000471150127325 },
  { timestamp: 1724152786531, value: 1.000398304460769 },
  { timestamp: 1724155825661, value: 1.0010654918541844 },
  { timestamp: 1724157573000, value: 0.9999304913254204 },
];

export const calculatorPoolDayDatasMock: ChartVolumn[] = [
  { volumeUSD: "8231279.788458260241734457638918996" },
  { volumeUSD: "7242605.731183271673600267931426658" },
  { volumeUSD: "6822166.812416037785719009491302655" },
  { volumeUSD: "6418014.287721556335077528452828999" },
  { volumeUSD: "1231954.698435633280896504893076877" },
  { volumeUSD: "12320037.66350407823500568602277009" },
  { volumeUSD: "14191362.77517023596245954805247035" },
  { volumeUSD: "20993786.09330038325741947843720173" },
  { volumeUSD: "14357028.86094149772950668473150998" },
  { volumeUSD: "25452083.62241482294497444592768258" },
  { volumeUSD: "12849137.90129199817048913375561977" },
  { volumeUSD: "4753696.885354499261895662730314404" },
  { volumeUSD: "11792043.91037822770549411474709104" },
  { volumeUSD: "15715605.3112136073082400434250735" },
  { volumeUSD: "26654255.89554277054751176219753955" },
  { volumeUSD: "22983428.94318579656965761741432456" },
  { volumeUSD: "239256994.7177684281203164580854432" },
  { volumeUSD: "61162351.05730362944602671891127626" },
  { volumeUSD: "37256739.26778832340133424064437711" },
  { volumeUSD: "55635918.79007201509589341730413638" },
  { volumeUSD: "44841378.82910582800633668038871396" },
  { volumeUSD: "29331953.38501902066052215925466246" },
  { volumeUSD: "27396968.89158709730250596296779436" },
  { volumeUSD: "37686866.48261098955973235205346485" },
  { volumeUSD: "10439187.30686218549264080845179986" },
  { volumeUSD: "29131474.12910585377742313909971609" },
  { volumeUSD: "22039914.88725175448951674214737665" },
  { volumeUSD: "76549235.95018450761179745118986313" },
  { volumeUSD: "25928256.88175079483231829713846838" },
  { volumeUSD: "54705831.60275324841743888861337048" },
  { volumeUSD: "24309688.60901225103356664038618512" },
  { volumeUSD: "26929975.79022157375025581418695238" },
  { volumeUSD: "7351847.918974911228263141275635336" },
  { volumeUSD: "31673374.97305208283190442813945739" },
  { volumeUSD: "25883949.58267956960336205743347151" },
  { volumeUSD: "27165261.77061377237197416215124963" },
  { volumeUSD: "42184021.83900812229123834751482108" },
  { volumeUSD: "34716219.3907268855465156799584895" },
  { volumeUSD: "12523593.51815410467238477596129011" },
  { volumeUSD: "9038338.155243556308740276365371884" },
  { volumeUSD: "26483979.38284757176801343101680191" },
  { volumeUSD: "32608095.1538748905154944134560271" },
  { volumeUSD: "31203130.24363337441044955425516449" },
  { volumeUSD: "36720870.72584796656046705496482698" },
  { volumeUSD: "37685498.59726285145274103506826796" },
  { volumeUSD: "11579135.16284456600417056161500028" },
  { volumeUSD: "9244364.662721567241105664879068066" },
  { volumeUSD: "64640178.48058153284933463686015464" },
  { volumeUSD: "67955994.5448398619471832476125566" },
  { volumeUSD: "43215921.01149162669120200441512686" },
  { volumeUSD: "7021984.650513502871861620950076085" },
  { volumeUSD: "16598621.84234098430830515589308017" },
  { volumeUSD: "9438976.087476442843608063902713387" },
  { volumeUSD: "2882022.096730934532972586422565411" },
  { volumeUSD: "21970042.6770329623035321247311531" },
  { volumeUSD: "11453880.97242991187705115318036674" },
  { volumeUSD: "18036720.77243060777293814329009069" },
  { volumeUSD: "17349858.29894821238177870027929705" },
  { volumeUSD: "62693367.23755678584299715374558434" },
  { volumeUSD: "8337028.117225781548084519644627985" },
  { volumeUSD: "4304595.448662966459891235047130728" },
  { volumeUSD: "33094925.71488005602830953556793882" },
  { volumeUSD: "27464552.27672872932704308482359185" },
  { volumeUSD: "26631883.04019244489807385172331253" },
  { volumeUSD: "43959000.62805594038454454266675137" },
  { volumeUSD: "35079230.29398614354069077336888288" },
  { volumeUSD: "10774555.74770876618127060948033097" },
  { volumeUSD: "14957786.61648824436600607553515523" },
  { volumeUSD: "33202698.67356663352909732327004155" },
  { volumeUSD: "26569506.71267276290258192720775141" },
  { volumeUSD: "40569828.29297492234760407369354331" },
  { volumeUSD: "40891663.13394442769692236134367728" },
  { volumeUSD: "10534375.89462304399022503271935042" },
  { volumeUSD: "3598987.820950347059763944271501568" },
  { volumeUSD: "3902254.024557249329995682903418451" },
  { volumeUSD: "23120529.41337705917835683257222497" },
  { volumeUSD: "7333735.682554869197889338542662686" },
  { volumeUSD: "10035976.4696256337558311577052468" },
  { volumeUSD: "13827552.45077884726824577028405424" },
  { volumeUSD: "11309738.7471095608038994135419507" },
  { volumeUSD: "12990462.97211984340158532954945055" },
  { volumeUSD: "4220577.544796309883102198849041188" },
  { volumeUSD: "20442006.76850633378318289951905992" },
  { volumeUSD: "22813086.13767356058573007517055186" },
  { volumeUSD: "15168440.76776728336662767853627355" },
  { volumeUSD: "12847410.7462188372443508357720189" },
  { volumeUSD: "9897184.274753148949255951160889436" },
  { volumeUSD: "11785598.55846600235413099037541777" },
  { volumeUSD: "10105606.43678906590645894391719407" },
  { volumeUSD: "39593710.85206543669265915357611791" },
];

export const calculatorPoolTicksMockData: ChartTick[] = [
  { liquidityNet: 647463540406806, tickIdx: -887220 },
  { liquidityNet: 0, tickIdx: -414840 },
  { liquidityNet: 1044180640578291, tickIdx: -414480 },
  { liquidityNet: 0, tickIdx: -414240 },
  { liquidityNet: 0, tickIdx: -413880 },
  { liquidityNet: 0, tickIdx: -391440 },
  { liquidityNet: 36488597426, tickIdx: -368460 },
  { liquidityNet: 7291342873418, tickIdx: -361500 },
  { liquidityNet: 0, tickIdx: -360600 },
  { liquidityNet: 24199791669961254, tickIdx: -358140 },
  { liquidityNet: 0, tickIdx: -357780 },
  { liquidityNet: 0, tickIdx: -357720 },
  { liquidityNet: 0, tickIdx: -357600 },
  { liquidityNet: 55365840142507116, tickIdx: -357360 },
  { liquidityNet: 0, tickIdx: -357180 },
  { liquidityNet: 0, tickIdx: -357120 },
  { liquidityNet: 0, tickIdx: -356880 },
  { liquidityNet: -55365840142507116, tickIdx: -356400 },
  { liquidityNet: -24199791669961254, tickIdx: -356220 },
  { liquidityNet: 0, tickIdx: -355680 },
  { liquidityNet: 0, tickIdx: -354540 },
  { liquidityNet: 0, tickIdx: -354420 },
  { liquidityNet: 0, tickIdx: -353760 },
  { liquidityNet: 0, tickIdx: -352320 },
  { liquidityNet: 0, tickIdx: -351780 },
  { liquidityNet: 0, tickIdx: -351660 },
  { liquidityNet: 0, tickIdx: -350160 },
  { liquidityNet: 0, tickIdx: -349680 },
  { liquidityNet: 0, tickIdx: -349440 },
  { liquidityNet: 1030111628826338, tickIdx: -348960 },
  { liquidityNet: 0, tickIdx: -348780 },
  { liquidityNet: 0, tickIdx: -348420 },
  { liquidityNet: 0, tickIdx: -347640 },
  { liquidityNet: 0, tickIdx: -347220 },
  { liquidityNet: 169948537884090, tickIdx: -345420 },
  { liquidityNet: 41616435971610862, tickIdx: -345240 },
  { liquidityNet: -169948537884090, tickIdx: -343560 },
  { liquidityNet: -41616435971610862, tickIdx: -343200 },
  { liquidityNet: -1030111628826338, tickIdx: -339000 },
  { liquidityNet: -7291342873418, tickIdx: -338460 },
  { liquidityNet: 0, tickIdx: -328200 },
  { liquidityNet: 0, tickIdx: -327960 },
  { liquidityNet: 17069717179, tickIdx: -323400 },
  { liquidityNet: 383344368069245, tickIdx: -322380 },
  { liquidityNet: -383344368069245, tickIdx: -320580 },
  { liquidityNet: 17148019954, tickIdx: -300420 },
  { liquidityNet: 5508389560097, tickIdx: -299340 },
  { liquidityNet: 0, tickIdx: -295440 },
  { liquidityNet: 1803579396721, tickIdx: -292440 },
  { liquidityNet: 0, tickIdx: -281460 },
  { liquidityNet: 0, tickIdx: -278580 },
  { liquidityNet: 0, tickIdx: -278460 },
  { liquidityNet: 594609443512, tickIdx: -277380 },
  { liquidityNet: 996550885, tickIdx: -276420 },
  { liquidityNet: 142322427413, tickIdx: -276360 },
  { liquidityNet: 6701311416302, tickIdx: -276300 },
  { liquidityNet: 0, tickIdx: -274500 },
  { liquidityNet: 0, tickIdx: -274080 },
  { liquidityNet: 0, tickIdx: -271980 },
  { liquidityNet: 0, tickIdx: -271200 },
  { liquidityNet: 0, tickIdx: -265320 },
  { liquidityNet: 7166286345721, tickIdx: -260220 },
  { liquidityNet: 17496133052, tickIdx: -259860 },
  { liquidityNet: 0, tickIdx: -258420 },
  { liquidityNet: 11421034244605, tickIdx: -253320 },
  { liquidityNet: 0, tickIdx: -253260 },
  { liquidityNet: 0, tickIdx: -252840 },
  { liquidityNet: 0, tickIdx: -251700 },
  { liquidityNet: 0, tickIdx: -251400 },
  { liquidityNet: 0, tickIdx: -249000 },
  { liquidityNet: 0, tickIdx: -244080 },
  { liquidityNet: 0, tickIdx: -241680 },
  { liquidityNet: 342909760817, tickIdx: -237180 },
  { liquidityNet: 0, tickIdx: -235380 },
  { liquidityNet: 0, tickIdx: -235260 },
  { liquidityNet: 0, tickIdx: -234540 },
  { liquidityNet: 0, tickIdx: -233820 },
  { liquidityNet: 0, tickIdx: -232860 },
  { liquidityNet: 0, tickIdx: -231540 },
  { liquidityNet: 0, tickIdx: -230340 },
  { liquidityNet: 15873798707626, tickIdx: -230280 },
  { liquidityNet: 73382200827, tickIdx: -230220 },
  { liquidityNet: 0, tickIdx: -229980 },
  { liquidityNet: 0, tickIdx: -229500 },
  { liquidityNet: 0, tickIdx: -229080 },
  { liquidityNet: 0, tickIdx: -228780 },
  { liquidityNet: 0, tickIdx: -228060 },
  { liquidityNet: 0, tickIdx: -227880 },
  { liquidityNet: 0, tickIdx: -226860 },
  { liquidityNet: 0, tickIdx: -226260 },
  { liquidityNet: 0, tickIdx: -226200 },
  { liquidityNet: 0, tickIdx: -226140 },
  { liquidityNet: 0, tickIdx: -225840 },
  { liquidityNet: 560879285034695, tickIdx: -225660 },
  { liquidityNet: 0, tickIdx: -224880 },
  { liquidityNet: 0, tickIdx: -224580 },
  { liquidityNet: 0, tickIdx: -224160 },
  { liquidityNet: 2150345848697, tickIdx: -223980 },
  { liquidityNet: 0, tickIdx: -223320 },
  { liquidityNet: 0, tickIdx: -223140 },
  { liquidityNet: 0, tickIdx: -222480 },
  { liquidityNet: 0, tickIdx: -222240 },
  { liquidityNet: 9635129523749, tickIdx: -222180 },
  { liquidityNet: 0, tickIdx: -221160 },
  { liquidityNet: 0, tickIdx: -221100 },
  { liquidityNet: 0, tickIdx: -220920 },
  { liquidityNet: 0, tickIdx: -220440 },
  { liquidityNet: 30012473174, tickIdx: -220200 },
  { liquidityNet: 14760697187118, tickIdx: -219600 },
  { liquidityNet: 0, tickIdx: -219480 },
  { liquidityNet: 212544738572991, tickIdx: -219300 },
  { liquidityNet: 0, tickIdx: -219240 },
  { liquidityNet: 0, tickIdx: -219120 },
  { liquidityNet: 0, tickIdx: -218760 },
  { liquidityNet: 0, tickIdx: -218640 },
  { liquidityNet: 0, tickIdx: -218520 },
  { liquidityNet: 0, tickIdx: -218220 },
  { liquidityNet: 0, tickIdx: -218160 },
  { liquidityNet: 0, tickIdx: -218100 },
  { liquidityNet: 0, tickIdx: -218040 },
  { liquidityNet: 0, tickIdx: -217860 },
  { liquidityNet: 0, tickIdx: -217800 },
  { liquidityNet: 0, tickIdx: -217740 },
  { liquidityNet: 0, tickIdx: -217620 },
  { liquidityNet: 0, tickIdx: -217500 },
  { liquidityNet: 3821406503805, tickIdx: -217320 },
  { liquidityNet: 0, tickIdx: -217260 },
  { liquidityNet: 0, tickIdx: -217200 },
  { liquidityNet: 15594316072341, tickIdx: -217140 },
  { liquidityNet: 0, tickIdx: -216780 },
  { liquidityNet: 0, tickIdx: -216720 },
  { liquidityNet: 0, tickIdx: -216540 },
  { liquidityNet: 284872582171864, tickIdx: -216420 },
  { liquidityNet: 8389917034588, tickIdx: -216360 },
  { liquidityNet: 63316182385296, tickIdx: -216300 },
  { liquidityNet: 0, tickIdx: -216000 },
  { liquidityNet: 0, tickIdx: -215940 },
  { liquidityNet: 22170868763629, tickIdx: -215820 },
  { liquidityNet: 0, tickIdx: -215760 },
  { liquidityNet: 0, tickIdx: -215700 },
  { liquidityNet: 0, tickIdx: -215640 },
  { liquidityNet: 12401813790374, tickIdx: -215580 },
  { liquidityNet: 0, tickIdx: -215520 },
  { liquidityNet: 0, tickIdx: -215460 },
  { liquidityNet: 17362920264, tickIdx: -215400 },
  { liquidityNet: 0, tickIdx: -215220 },
  { liquidityNet: 0, tickIdx: -215160 },
  { liquidityNet: 0, tickIdx: -215040 },
  { liquidityNet: 0, tickIdx: -214980 },
  { liquidityNet: 0, tickIdx: -214800 },
  { liquidityNet: 0, tickIdx: -214560 },
  { liquidityNet: 229652981859200, tickIdx: -214440 },
  { liquidityNet: 0, tickIdx: -214380 },
  { liquidityNet: 0, tickIdx: -214320 },
  { liquidityNet: 8387262720444, tickIdx: -214260 },
  { liquidityNet: 44161220884803, tickIdx: -214200 },
  { liquidityNet: 0, tickIdx: -214140 },
  { liquidityNet: 0, tickIdx: -214020 },
  { liquidityNet: 24011432361167, tickIdx: -213960 },
  { liquidityNet: 0, tickIdx: -213900 },
  { liquidityNet: 1054486262167, tickIdx: -213780 },
  { liquidityNet: 0, tickIdx: -213660 },
  { liquidityNet: 212887968807951, tickIdx: -213600 },
  { liquidityNet: 104834545602, tickIdx: -213540 },
  { liquidityNet: 14818281157161, tickIdx: -213480 },
  { liquidityNet: 0, tickIdx: -213420 },
  { liquidityNet: 10391121491, tickIdx: -213360 },
  { liquidityNet: 11987607319714, tickIdx: -213300 },
  { liquidityNet: 10366623293, tickIdx: -213240 },
  { liquidityNet: 206462075621, tickIdx: -213180 },
  { liquidityNet: 217322842296, tickIdx: -213120 },
  { liquidityNet: 124605522853, tickIdx: -213060 },
  { liquidityNet: 0, tickIdx: -213000 },
  { liquidityNet: 903141848271, tickIdx: -212940 },
  { liquidityNet: 0, tickIdx: -212880 },
  { liquidityNet: 14023796647, tickIdx: -212700 },
  { liquidityNet: 12484774257952, tickIdx: -212640 },
  { liquidityNet: 7451136121504, tickIdx: -212580 },
  { liquidityNet: 13983627174376, tickIdx: -212520 },
  { liquidityNet: 3887795852863, tickIdx: -212460 },
  { liquidityNet: 10409773799483, tickIdx: -212400 },
  { liquidityNet: 2386726814462591, tickIdx: -212340 },
  { liquidityNet: 139336762959152, tickIdx: -212280 },
  { liquidityNet: 209456676146178, tickIdx: -212220 },
  { liquidityNet: 3126706829537, tickIdx: -212160 },
  { liquidityNet: 5398444956419, tickIdx: -212100 },
  { liquidityNet: 14157890126182, tickIdx: -212040 },
  { liquidityNet: 13061079462676, tickIdx: -211980 },
  { liquidityNet: 1722258059820314, tickIdx: -211920 },
  { liquidityNet: 218119457328, tickIdx: -211860 },
  { liquidityNet: 45204256455507, tickIdx: -211800 },
  { liquidityNet: 1486875887062, tickIdx: -211740 },
  { liquidityNet: 7972980024861, tickIdx: -211680 },
  { liquidityNet: 1331017194748, tickIdx: -211620 },
  { liquidityNet: 75539327355548, tickIdx: -211560 },
  { liquidityNet: 12302531458136, tickIdx: -211500 },
  { liquidityNet: 17357320271444, tickIdx: -211440 },
  { liquidityNet: 51278725117061, tickIdx: -211380 },
  { liquidityNet: 4370344192827, tickIdx: -211320 },
  { liquidityNet: 1147857766957, tickIdx: -211260 },
  { liquidityNet: 11370940716952, tickIdx: -211200 },
  { liquidityNet: 0, tickIdx: -211140 },
  { liquidityNet: 3629724608399, tickIdx: -211080 },
  { liquidityNet: 4087223106043, tickIdx: -211020 },
  { liquidityNet: 0, tickIdx: -210960 },
  { liquidityNet: 71676727823, tickIdx: -210900 },
  { liquidityNet: 136579643960, tickIdx: -210840 },
  { liquidityNet: 3397655472035038, tickIdx: -210780 },
  { liquidityNet: 91251623748, tickIdx: -210720 },
  { liquidityNet: 0, tickIdx: -210660 },
  { liquidityNet: 154740238096, tickIdx: -210600 },
  { liquidityNet: 0, tickIdx: -210540 },
  { liquidityNet: 2744272421471, tickIdx: -210480 },
  { liquidityNet: 495837612117, tickIdx: -210420 },
  { liquidityNet: 890472146917, tickIdx: -210360 },
  { liquidityNet: 3868076657661, tickIdx: -210300 },
  { liquidityNet: 18357415114845, tickIdx: -210240 },
  { liquidityNet: 2640671960539, tickIdx: -210180 },
  { liquidityNet: 13344287029680, tickIdx: -210120 },
  { liquidityNet: 21605232052893, tickIdx: -210060 },
  { liquidityNet: 280509577121, tickIdx: -210000 },
  { liquidityNet: 13704539358947, tickIdx: -209940 },
  { liquidityNet: 82710374111208, tickIdx: -209880 },
  { liquidityNet: 29469750543789, tickIdx: -209820 },
  { liquidityNet: 4950570438391, tickIdx: -209760 },
  { liquidityNet: 96334402756810, tickIdx: -209700 },
  { liquidityNet: 71773993205259, tickIdx: -209640 },
  { liquidityNet: 15656597965152, tickIdx: -209580 },
  { liquidityNet: 2926962867394668, tickIdx: -209520 },
  { liquidityNet: 706195776919608, tickIdx: -209460 },
  { liquidityNet: 1505087363651, tickIdx: -209400 },
  { liquidityNet: 91315313125899, tickIdx: -209340 },
  { liquidityNet: 98837252717082, tickIdx: -209280 },
  { liquidityNet: 15329299937226, tickIdx: -209220 },
  { liquidityNet: 969568116330, tickIdx: -209160 },
  { liquidityNet: 80215307758107, tickIdx: -209100 },
  { liquidityNet: 21847490606146, tickIdx: -209040 },
  { liquidityNet: 6076577313481, tickIdx: -208980 },
  { liquidityNet: 2682593474820, tickIdx: -208920 },
  { liquidityNet: -579981132515026, tickIdx: -208860 },
  { liquidityNet: 9267978252982, tickIdx: -208800 },
  { liquidityNet: 34812750275505, tickIdx: -208740 },
  { liquidityNet: 1298847478664, tickIdx: -208680 },
  { liquidityNet: 1231893599147129, tickIdx: -208620 },
  { liquidityNet: 13352726980648, tickIdx: -208560 },
  { liquidityNet: 2172498505266, tickIdx: -208500 },
  { liquidityNet: 2989151769390, tickIdx: -208440 },
  { liquidityNet: 11113895346670, tickIdx: -208380 },
  { liquidityNet: 470710671101329, tickIdx: -208320 },
  { liquidityNet: 283571260563207, tickIdx: -208260 },
  { liquidityNet: 220790808483364, tickIdx: -208200 },
  { liquidityNet: 7774969781183, tickIdx: -208140 },
  { liquidityNet: 4710291605255, tickIdx: -208080 },
  { liquidityNet: 14350366648616, tickIdx: -208020 },
  { liquidityNet: 65290776764543, tickIdx: -207960 },
  { liquidityNet: 98888532022946, tickIdx: -207900 },
  { liquidityNet: 188065212165242, tickIdx: -207840 },
  { liquidityNet: 896857516791913, tickIdx: -207780 },
  { liquidityNet: 5502173106951, tickIdx: -207720 },
  { liquidityNet: 2692504026941, tickIdx: -207660 },
  { liquidityNet: 1398676183819, tickIdx: -207600 },
  { liquidityNet: 1088003644819673, tickIdx: -207540 },
  { liquidityNet: 120991288761770, tickIdx: -207480 },
  { liquidityNet: 161357599522, tickIdx: -207420 },
  { liquidityNet: 71595408818259, tickIdx: -207360 },
  { liquidityNet: 113811178775950, tickIdx: -207300 },
  { liquidityNet: 17907395028397044, tickIdx: -207240 },
  { liquidityNet: 1733120854100, tickIdx: -207180 },
  { liquidityNet: 78707929667486150, tickIdx: -207120 },
  { liquidityNet: 1028293905601955, tickIdx: -207060 },
  { liquidityNet: 174866682313271, tickIdx: -207000 },
  { liquidityNet: 19880748021669, tickIdx: -206940 },
  { liquidityNet: 1039719174955288, tickIdx: -206880 },
  { liquidityNet: 424555507674793, tickIdx: -206820 },
  { liquidityNet: 282100067567440, tickIdx: -206760 },
  { liquidityNet: 20778544609949, tickIdx: -206700 },
  { liquidityNet: 97628981717144, tickIdx: -206640 },
  { liquidityNet: 38907999071184, tickIdx: -206580 },
  { liquidityNet: 99091581046458, tickIdx: -206520 },
  { liquidityNet: 183425864967841, tickIdx: -206460 },
  { liquidityNet: 61215846647772, tickIdx: -206400 },
  { liquidityNet: -73507016305617, tickIdx: -206340 },
  { liquidityNet: 107232739656951, tickIdx: -206280 },
  { liquidityNet: 168325100759839, tickIdx: -206220 },
  { liquidityNet: 110398227164587, tickIdx: -206160 },
  { liquidityNet: 233973495706384, tickIdx: -206100 },
  { liquidityNet: -183047976045651, tickIdx: -206040 },
  { liquidityNet: 175427869279543, tickIdx: -205980 },
  { liquidityNet: 37841110775970, tickIdx: -205920 },
  { liquidityNet: 75713950432959, tickIdx: -205860 },
  { liquidityNet: 62423356823118, tickIdx: -205800 },
  { liquidityNet: 29102662248753, tickIdx: -205740 },
  { liquidityNet: 324143953007403, tickIdx: -205680 },
  { liquidityNet: -5010618266469, tickIdx: -205620 },
  { liquidityNet: -13306648020604, tickIdx: -205560 },
  { liquidityNet: 14027384027237, tickIdx: -205500 },
  { liquidityNet: 585944615277772, tickIdx: -205440 },
  { liquidityNet: 1111627328744063, tickIdx: -205380 },
  { liquidityNet: 3420656838037300, tickIdx: -205320 },
  { liquidityNet: 6744496674603602, tickIdx: -205260 },
  { liquidityNet: 20798888327126, tickIdx: -205200 },
  { liquidityNet: 606701913678583, tickIdx: -205140 },
  { liquidityNet: -22365165808418, tickIdx: -205080 },
  { liquidityNet: -5083831349301723, tickIdx: -205020 },
  { liquidityNet: 19374346309295, tickIdx: -204960 },
  { liquidityNet: 68799508440607, tickIdx: -204900 },
  { liquidityNet: 410609914752980, tickIdx: -204840 },
  { liquidityNet: -160056296833661, tickIdx: -204780 },
  { liquidityNet: -31232034260020, tickIdx: -204720 },
  { liquidityNet: -10274152035794, tickIdx: -204660 },
  { liquidityNet: 43694692904743823, tickIdx: -204600 },
  { liquidityNet: 352534124545330, tickIdx: -204540 },
  { liquidityNet: -26012690576057, tickIdx: -204480 },
  { liquidityNet: 19331484131687, tickIdx: -204420 },
  { liquidityNet: 432788420908078, tickIdx: -204360 },
  { liquidityNet: 3637865623854392, tickIdx: -204300 },
  { liquidityNet: -61161882654824, tickIdx: -204240 },
  { liquidityNet: -9180690815824, tickIdx: -204180 },
  { liquidityNet: 3956636956772, tickIdx: -204120 },
  { liquidityNet: 6731940392611, tickIdx: -204060 },
  { liquidityNet: 23613517770072, tickIdx: -204000 },
  { liquidityNet: -17848831865192, tickIdx: -203940 },
  { liquidityNet: 370879762763378, tickIdx: -203880 },
  { liquidityNet: 3321367942396872, tickIdx: -203820 },
  { liquidityNet: 48692053381430, tickIdx: -203760 },
  { liquidityNet: 37882879798832, tickIdx: -203700 },
  { liquidityNet: 2364633606837457, tickIdx: -203640 },
  { liquidityNet: 107798997174373, tickIdx: -203580 },
  { liquidityNet: 84754233787889952, tickIdx: -203520 },
  { liquidityNet: 52704882741752625, tickIdx: -203460 },
  { liquidityNet: 425443069544454, tickIdx: -203400 },
  { liquidityNet: 1752854779378531, tickIdx: -203340 },
  { liquidityNet: 856950758456302, tickIdx: -203280 },
  { liquidityNet: 125104341346864450, tickIdx: -203220 },
  { liquidityNet: 12176863212459374, tickIdx: -203160 },
  { liquidityNet: 45819014022997, tickIdx: -203100 },
  { liquidityNet: 78129792519177, tickIdx: -203040 },
  { liquidityNet: 33321050847138, tickIdx: -202980 },
  { liquidityNet: 9745402620769049, tickIdx: -202920 },
  { liquidityNet: 672511373195818, tickIdx: -202860 },
  { liquidityNet: -1956270680882491, tickIdx: -202800 },
  { liquidityNet: 1458726309624536, tickIdx: -202740 },
  { liquidityNet: 174243933510574, tickIdx: -202680 },
  { liquidityNet: -945154746962096, tickIdx: -202620 },
  { liquidityNet: 313601962411678, tickIdx: -202560 },
  { liquidityNet: 595869215585144, tickIdx: -202500 },
  { liquidityNet: 373347815630286, tickIdx: -202440 },
  { liquidityNet: 51900129991928, tickIdx: -202380 },
  { liquidityNet: 1866321909170790, tickIdx: -202320 },
  { liquidityNet: 48688369528440, tickIdx: -202260 },
  { liquidityNet: 122217347830303, tickIdx: -202200 },
  { liquidityNet: 80659435739610, tickIdx: -202140 },
  { liquidityNet: 5929834739508774, tickIdx: -202080 },
  { liquidityNet: -115679679020179, tickIdx: -202020 },
  { liquidityNet: 8977337127650719, tickIdx: -201960 },
  { liquidityNet: 2426526272549540, tickIdx: -201900 },
  { liquidityNet: 2325427221591340, tickIdx: -201840 },
  { liquidityNet: 7674308490175, tickIdx: -201780 },
  { liquidityNet: 43790110053740649, tickIdx: -201720 },
  { liquidityNet: 501233734628519, tickIdx: -201660 },
  { liquidityNet: -631186477533680, tickIdx: -201600 },
  { liquidityNet: 868876237967533, tickIdx: -201540 },
  { liquidityNet: -1898344437934180, tickIdx: -201480 },
  { liquidityNet: 342478723536782, tickIdx: -201420 },
  { liquidityNet: 80202019033861921, tickIdx: -201360 },
  { liquidityNet: 7923164057180332, tickIdx: -201300 },
  { liquidityNet: -217448051915831, tickIdx: -201240 },
  { liquidityNet: -265567483222410, tickIdx: -201180 },
  { liquidityNet: 1250512863228460, tickIdx: -201120 },
  { liquidityNet: -638999287947852, tickIdx: -201060 },
  { liquidityNet: 2556677859036479, tickIdx: -201000 },
  { liquidityNet: 730706021927519, tickIdx: -200940 },
  { liquidityNet: 30870897019366506, tickIdx: -200880 },
  { liquidityNet: 52333242293876138, tickIdx: -200820 },
  { liquidityNet: 1541692056263626, tickIdx: -200760 },
  { liquidityNet: -3411579078754109, tickIdx: -200700 },
  { liquidityNet: 2913013453943536, tickIdx: -200640 },
  { liquidityNet: -355289415382637, tickIdx: -200580 },
  { liquidityNet: 735619182662849, tickIdx: -200520 },
  { liquidityNet: 13204876964135545, tickIdx: -200460 },
  { liquidityNet: 450257769350076, tickIdx: -200400 },
  { liquidityNet: 256741625688473556, tickIdx: -200340 },
  { liquidityNet: 699984282081899, tickIdx: -200280 },
  { liquidityNet: 720455094946807, tickIdx: -200220 },
  { liquidityNet: -907280901562685, tickIdx: -200160 },
  { liquidityNet: 4057452266060048, tickIdx: -200100 },
  { liquidityNet: 95596060121050, tickIdx: -200040 },
  { liquidityNet: 14504722484046, tickIdx: -199980 },
  { liquidityNet: 514383877639946, tickIdx: -199920 },
  { liquidityNet: 1985837324953481, tickIdx: -199860 },
  { liquidityNet: 971751669908149357, tickIdx: -199800 },
  { liquidityNet: 712864634950456, tickIdx: -199740 },
  { liquidityNet: 13274646753968287, tickIdx: -199680 },
  { liquidityNet: -235101342068272, tickIdx: -199620 },
  { liquidityNet: -831304985729355, tickIdx: -199560 },
  { liquidityNet: 8778813975948313, tickIdx: -199500 },
  { liquidityNet: -24361266929402, tickIdx: -199440 },
  { liquidityNet: 92406878673804644, tickIdx: -199380 },
  { liquidityNet: -1761816230778925, tickIdx: -199320 },
  { liquidityNet: -457692206318542, tickIdx: -199260 },
  { liquidityNet: 2422979350724834, tickIdx: -199200 },
  { liquidityNet: 526069501236374, tickIdx: -199140 },
  { liquidityNet: 557283311714184, tickIdx: -199080 },
  { liquidityNet: 946716478363262, tickIdx: -199020 },
  { liquidityNet: -719500514527245, tickIdx: -198960 },
  { liquidityNet: -944728385833784225, tickIdx: -198900 },
  { liquidityNet: 6638382574719611, tickIdx: -198840 },
  { liquidityNet: 36204191243934256, tickIdx: -198780 },
  { liquidityNet: 18473313161017819, tickIdx: -198720 },
  { liquidityNet: -111262293977308, tickIdx: -198660 },
  { liquidityNet: 2200507328158728, tickIdx: -198600 },
  { liquidityNet: 1031188309840187, tickIdx: -198540 },
  { liquidityNet: 5617265073869400, tickIdx: -198480 },
  { liquidityNet: 4185278896070442, tickIdx: -198420 },
  { liquidityNet: 16287686783002373, tickIdx: -198360 },
  { liquidityNet: 591186310360064991, tickIdx: -198300 },
  { liquidityNet: 368915358894885, tickIdx: -198240 },
  { liquidityNet: 8513879257589151, tickIdx: -198180 },
  { liquidityNet: 7436965347487736, tickIdx: -198120 },
  { liquidityNet: 337819179107646602, tickIdx: -198060 },
  { liquidityNet: 124806660922828263, tickIdx: -198000 },
  { liquidityNet: 33311264175004573, tickIdx: -197940 },
  { liquidityNet: 2552214501811809, tickIdx: -197880 },
  { liquidityNet: 16146172668614054, tickIdx: -197820 },
  { liquidityNet: 163414147044255, tickIdx: -197760 },
  { liquidityNet: 57186846289204224, tickIdx: -197700 },
  { liquidityNet: 11726252319290225, tickIdx: -197640 },
  { liquidityNet: -4613002448029475, tickIdx: -197580 },
  { liquidityNet: -111504446319278430, tickIdx: -197520 },
  { liquidityNet: 113694012136526747, tickIdx: -197460 },
  { liquidityNet: 35774093032888982, tickIdx: -197400 },
  { liquidityNet: 49049610961407868, tickIdx: -197340 },
  { liquidityNet: 40609418090892, tickIdx: -197280 },
  { liquidityNet: 4198422686618399344, tickIdx: -197220 },
  { liquidityNet: 175770543735268, tickIdx: -197160 },
  { liquidityNet: 9776935878869900, tickIdx: -197100 },
  { liquidityNet: -832457141926125, tickIdx: -197040 },
  { liquidityNet: 60540526733504010, tickIdx: -196980 },
  { liquidityNet: -248998369909228116, tickIdx: -196920 },
  { liquidityNet: -584810775811363497, tickIdx: -196860 },
  { liquidityNet: 1192661382919898, tickIdx: -196800 },
  { liquidityNet: -3337990917558125, tickIdx: -196740 },
  { liquidityNet: -43701224684398442, tickIdx: -196680 },
  { liquidityNet: -16041361250424016, tickIdx: -196620 },
  { liquidityNet: 3385395327800234, tickIdx: -196560 },
  { liquidityNet: 2367005464831423, tickIdx: -196500 },
  { liquidityNet: -4026432595447023514, tickIdx: -196440 },
  { liquidityNet: -390544732083550, tickIdx: -196380 },
  { liquidityNet: -3884905371910120, tickIdx: -196320 },
  { liquidityNet: 1563959287601301516, tickIdx: -196260 },
  { liquidityNet: 5766882965924232431, tickIdx: -196200 },
  { liquidityNet: -65097256391992299, tickIdx: -196140 },
  { liquidityNet: -79958747924900, tickIdx: -196080 },
  { liquidityNet: 1458390806715112, tickIdx: -196020 },
  { liquidityNet: 2071790356741843, tickIdx: -195960 },
  { liquidityNet: -746221428045050, tickIdx: -195900 },
  { liquidityNet: 961408483503510, tickIdx: -195840 },
  { liquidityNet: 45976359202718, tickIdx: -195780 },
  { liquidityNet: -11670689591544380, tickIdx: -195720 },
  { liquidityNet: 38752897210086281, tickIdx: -195660 },
  { liquidityNet: -1465461124923560838, tickIdx: -195600 },
  { liquidityNet: 727263278319830, tickIdx: -195540 },
  { liquidityNet: -2689063457255525, tickIdx: -195480 },
  { liquidityNet: 317939883789164764, tickIdx: -195420 },
  { liquidityNet: 1861389505849980, tickIdx: -195360 },
  { liquidityNet: 14948310480199677, tickIdx: -195300 },
  { liquidityNet: -18654832928030897, tickIdx: -195240 },
  { liquidityNet: 91795849656870982, tickIdx: -195180 },
  { liquidityNet: 10804738028187917, tickIdx: -195120 },
  { liquidityNet: -58731196375091541, tickIdx: -195060 },
  { liquidityNet: 9265975661960948, tickIdx: -195000 },
  { liquidityNet: 3509516907947202, tickIdx: -194940 },
  { liquidityNet: 9399286639734359, tickIdx: -194880 },
  { liquidityNet: -10324474126672505, tickIdx: -194820 },
  { liquidityNet: 56415705740948932, tickIdx: -194760 },
  { liquidityNet: -5209994092493425, tickIdx: -194700 },
  { liquidityNet: -49388196193052599, tickIdx: -194640 },
  { liquidityNet: -1090150343905834, tickIdx: -194580 },
  { liquidityNet: 265134123094427103, tickIdx: -194520 },
  { liquidityNet: -571613883746448, tickIdx: -194460 },
  { liquidityNet: -7265804575847945, tickIdx: -194400 },
  { liquidityNet: -550596568390449507, tickIdx: -194340 },
  { liquidityNet: 24381299675038, tickIdx: -194280 },
  { liquidityNet: -2920118862081182, tickIdx: -194220 },
  { liquidityNet: 3181890149081005, tickIdx: -194160 },
  { liquidityNet: 4533575209800364, tickIdx: -194100 },
  { liquidityNet: -1330655977787113, tickIdx: -194040 },
  { liquidityNet: -11916359366974295, tickIdx: -193980 },
  { liquidityNet: -5870064340384338, tickIdx: -193920 },
  { liquidityNet: -5846039470234854909, tickIdx: -193860 },
  { liquidityNet: -3224660735820208, tickIdx: -193800 },
  { liquidityNet: -1708133958167536, tickIdx: -193740 },
  { liquidityNet: -332273762083426858, tickIdx: -193680 },
  { liquidityNet: -5486545216577111, tickIdx: -193620 },
  { liquidityNet: -263058478740531042, tickIdx: -193560 },
  { liquidityNet: -11636082030282616, tickIdx: -193500 },
  { liquidityNet: -65839715524266475, tickIdx: -193440 },
  { liquidityNet: -212916844757603608, tickIdx: -193380 },
  { liquidityNet: -14296799043976804, tickIdx: -193320 },
  { liquidityNet: -81118809111578646, tickIdx: -193260 },
  { liquidityNet: -25818935563194303, tickIdx: -193200 },
  { liquidityNet: -5501170416030528, tickIdx: -193140 },
  { liquidityNet: -20056205485257294, tickIdx: -193080 },
  { liquidityNet: -2546069229297851, tickIdx: -193020 },
  { liquidityNet: -806452976590058, tickIdx: -192960 },
  { liquidityNet: -103632848658564166, tickIdx: -192900 },
  { liquidityNet: -271124707033332, tickIdx: -192840 },
  { liquidityNet: -2742384319762737, tickIdx: -192780 },
  { liquidityNet: -725908764365218, tickIdx: -192720 },
  { liquidityNet: -725713278357427, tickIdx: -192660 },
  { liquidityNet: -225082788787765, tickIdx: -192600 },
  { liquidityNet: -23337015348884921, tickIdx: -192540 },
  { liquidityNet: -34429487703388510, tickIdx: -192480 },
  { liquidityNet: -9584871267690066, tickIdx: -192420 },
  { liquidityNet: -30793676751233294, tickIdx: -192360 },
  { liquidityNet: -38245796064296, tickIdx: -192300 },
  { liquidityNet: -546035452838010, tickIdx: -192240 },
  { liquidityNet: -56055501206650090, tickIdx: -192180 },
  { liquidityNet: -593432096602613, tickIdx: -192120 },
  { liquidityNet: -457565790603841, tickIdx: -192060 },
  { liquidityNet: -204961694592736, tickIdx: -192000 },
  { liquidityNet: -10598083947746902, tickIdx: -191940 },
  { liquidityNet: -398345251994150, tickIdx: -191880 },
  { liquidityNet: -2222801144873, tickIdx: -191820 },
  { liquidityNet: -880768596659627, tickIdx: -191760 },
  { liquidityNet: -2367417987808113, tickIdx: -191700 },
  { liquidityNet: -92900047388546, tickIdx: -191640 },
  { liquidityNet: -23091036208396601, tickIdx: -191580 },
  { liquidityNet: -192571618233165, tickIdx: -191520 },
  { liquidityNet: -8814425673753767, tickIdx: -191460 },
  { liquidityNet: -1897756212965582, tickIdx: -191400 },
  { liquidityNet: -199989513027233, tickIdx: -191340 },
  { liquidityNet: -360658413951047, tickIdx: -191280 },
  { liquidityNet: -2823868974161305, tickIdx: -191220 },
  { liquidityNet: -5196462793787840, tickIdx: -191160 },
  { liquidityNet: -3297764147007471, tickIdx: -191100 },
  { liquidityNet: -761907692527237, tickIdx: -191040 },
  { liquidityNet: -11190840094486023, tickIdx: -190980 },
  { liquidityNet: -565582179798006, tickIdx: -190920 },
  { liquidityNet: -9155004661795, tickIdx: -190860 },
  { liquidityNet: -707097074637136, tickIdx: -190800 },
  { liquidityNet: -75599976109334080, tickIdx: -190740 },
  { liquidityNet: -60661775728504, tickIdx: -190680 },
  { liquidityNet: -3378081065107659, tickIdx: -190620 },
  { liquidityNet: -152456820094175, tickIdx: -190560 },
  { liquidityNet: -200010638715978, tickIdx: -190500 },
  { liquidityNet: -264773545784116, tickIdx: -190440 },
  { liquidityNet: -644215669771740, tickIdx: -190380 },
  { liquidityNet: -1021581656436, tickIdx: -190320 },
  { liquidityNet: -1785537477247, tickIdx: -190260 },
  { liquidityNet: -5924899593865122, tickIdx: -190200 },
  { liquidityNet: -10335208984433, tickIdx: -190140 },
  { liquidityNet: -1704079962229701, tickIdx: -190080 },
  { liquidityNet: -54483065795781, tickIdx: -190020 },
  { liquidityNet: -19393133837001, tickIdx: -189960 },
  { liquidityNet: -43634152752102, tickIdx: -189900 },
  { liquidityNet: -20644891359357, tickIdx: -189840 },
  { liquidityNet: -47603802479665, tickIdx: -189780 },
  { liquidityNet: -687526952144831, tickIdx: -189720 },
  { liquidityNet: -85107974556502, tickIdx: -189660 },
  { liquidityNet: -77960341339705494, tickIdx: -189600 },
  { liquidityNet: -56732244688384887, tickIdx: -189540 },
  { liquidityNet: -5809100610673, tickIdx: -189480 },
  { liquidityNet: -914553467225849, tickIdx: -189420 },
  { liquidityNet: -125512152768419600, tickIdx: -189360 },
  { liquidityNet: -8347046539602589, tickIdx: -189300 },
  { liquidityNet: -28254213787081, tickIdx: -189240 },
  { liquidityNet: -29954595047800, tickIdx: -189180 },
  { liquidityNet: -25524423773918, tickIdx: -189120 },
  { liquidityNet: -248527324420536, tickIdx: -189060 },
  { liquidityNet: -131069733949635, tickIdx: -189000 },
  { liquidityNet: -433529851704895, tickIdx: -188940 },
  { liquidityNet: -93000585318964, tickIdx: -188880 },
  { liquidityNet: -1744025067013470, tickIdx: -188820 },
  { liquidityNet: -137712242591991, tickIdx: -188760 },
  { liquidityNet: -18414973565658, tickIdx: -188700 },
  { liquidityNet: -176435807396630, tickIdx: -188640 },
  { liquidityNet: -6807164764470476, tickIdx: -188580 },
  { liquidityNet: -35502003154080, tickIdx: -188520 },
  { liquidityNet: -861407051645244, tickIdx: -188460 },
  { liquidityNet: -112214340039195, tickIdx: -188400 },
  { liquidityNet: -209113282172103, tickIdx: -188340 },
  { liquidityNet: -60040782042619, tickIdx: -188280 },
  { liquidityNet: -689658216681055, tickIdx: -188220 },
  { liquidityNet: -6811658581045, tickIdx: -188160 },
  { liquidityNet: -18937259020063, tickIdx: -188100 },
  { liquidityNet: -107976014235828, tickIdx: -188040 },
  { liquidityNet: -1724623205956157, tickIdx: -187980 },
  { liquidityNet: -1269318938232, tickIdx: -187920 },
  { liquidityNet: -701312211346779, tickIdx: -187860 },
  { liquidityNet: -2945578767028505, tickIdx: -187800 },
  { liquidityNet: -803976988238992, tickIdx: -187740 },
  { liquidityNet: -414325662564716, tickIdx: -187680 },
  { liquidityNet: -2251380820483, tickIdx: -187620 },
  { liquidityNet: -264288121738259, tickIdx: -187560 },
  { liquidityNet: -24011432361167, tickIdx: -187500 },
  { liquidityNet: -5263069041949042, tickIdx: -187440 },
  { liquidityNet: -1649501787009758, tickIdx: -187380 },
  { liquidityNet: -3499676058326, tickIdx: -187320 },
  { liquidityNet: -415981747228417, tickIdx: -187260 },
  { liquidityNet: -48783747154743, tickIdx: -187200 },
  { liquidityNet: -12701682608397, tickIdx: -187140 },
  { liquidityNet: -471601609541668, tickIdx: -187080 },
  { liquidityNet: -575233446734789, tickIdx: -187020 },
  { liquidityNet: -57836585547874338, tickIdx: -186960 },
  { liquidityNet: -1057003493864, tickIdx: -186900 },
  { liquidityNet: -105838329503180, tickIdx: -186840 },
  { liquidityNet: -4966400301165, tickIdx: -186780 },
  { liquidityNet: -10385613490116, tickIdx: -186720 },
  { liquidityNet: -449690233944788, tickIdx: -186660 },
  { liquidityNet: -118875237011984, tickIdx: -186600 },
  { liquidityNet: -27127546230227, tickIdx: -186540 },
  { liquidityNet: 773729463989, tickIdx: -186480 },
  { liquidityNet: -9693381380986925, tickIdx: -186420 },
  { liquidityNet: -51897396682069, tickIdx: -186360 },
  { liquidityNet: -10723488439, tickIdx: -186300 },
  { liquidityNet: -26205597105420, tickIdx: -186240 },
  { liquidityNet: -224984961580867, tickIdx: -186180 },
  { liquidityNet: -4380112439744, tickIdx: -186120 },
  { liquidityNet: 1362644551482, tickIdx: -186060 },
  { liquidityNet: 0, tickIdx: -186000 },
  { liquidityNet: -219370164646571, tickIdx: -185940 },
  { liquidityNet: 0, tickIdx: -185880 },
  { liquidityNet: -521252140303, tickIdx: -185820 },
  { liquidityNet: 0, tickIdx: -185760 },
  { liquidityNet: -19432092757533, tickIdx: -185700 },
  { liquidityNet: -8203874384108, tickIdx: -185640 },
  { liquidityNet: 0, tickIdx: -185580 },
  { liquidityNet: 0, tickIdx: -185520 },
  { liquidityNet: 0, tickIdx: -185460 },
  { liquidityNet: 0, tickIdx: -185400 },
  { liquidityNet: 0, tickIdx: -185340 },
  { liquidityNet: -114495774337573, tickIdx: -185280 },
  { liquidityNet: -87090365456988, tickIdx: -185220 },
  { liquidityNet: 0, tickIdx: -185160 },
  { liquidityNet: 0, tickIdx: -185100 },
  { liquidityNet: -8771797658309, tickIdx: -185040 },
  { liquidityNet: -22132326417264, tickIdx: -184980 },
  { liquidityNet: 0, tickIdx: -184920 },
  { liquidityNet: -5048490297264, tickIdx: -184800 },
  { liquidityNet: 0, tickIdx: -184740 },
  { liquidityNet: -125345424331208, tickIdx: -184680 },
  { liquidityNet: 0, tickIdx: -184620 },
  { liquidityNet: 0, tickIdx: -184500 },
  { liquidityNet: 0, tickIdx: -184440 },
  { liquidityNet: 4944799044914, tickIdx: -184320 },
  { liquidityNet: 5554715835757, tickIdx: -184260 },
  { liquidityNet: -3846909716193813, tickIdx: -184200 },
  { liquidityNet: -4513860737321, tickIdx: -184140 },
  { liquidityNet: 0, tickIdx: -184080 },
  { liquidityNet: -80929223546, tickIdx: -184020 },
  { liquidityNet: -43007766552636101, tickIdx: -183900 },
  { liquidityNet: 0, tickIdx: -183840 },
  { liquidityNet: 0, tickIdx: -183780 },
  { liquidityNet: 0, tickIdx: -183720 },
  { liquidityNet: 0, tickIdx: -183660 },
  { liquidityNet: 0, tickIdx: -183480 },
  { liquidityNet: 0, tickIdx: -183420 },
  { liquidityNet: 0, tickIdx: -183360 },
  { liquidityNet: 0, tickIdx: -183300 },
  { liquidityNet: 0, tickIdx: -183240 },
  { liquidityNet: -8378785459768, tickIdx: -183180 },
  { liquidityNet: 0, tickIdx: -183060 },
  { liquidityNet: 0, tickIdx: -182940 },
  { liquidityNet: 0, tickIdx: -182760 },
  { liquidityNet: 0, tickIdx: -182700 },
  { liquidityNet: 0, tickIdx: -182580 },
  { liquidityNet: -3369044876190728, tickIdx: -182400 },
  { liquidityNet: -971304305555, tickIdx: -182340 },
  { liquidityNet: 0, tickIdx: -182280 },
  { liquidityNet: -2215783929045, tickIdx: -182220 },
  { liquidityNet: 0, tickIdx: -182160 },
  { liquidityNet: -335814617560660, tickIdx: -181980 },
  { liquidityNet: 0, tickIdx: -181920 },
  { liquidityNet: 0, tickIdx: -181800 },
  { liquidityNet: -2096814000413, tickIdx: -181680 },
  { liquidityNet: 0, tickIdx: -181620 },
  { liquidityNet: 0, tickIdx: -181560 },
  { liquidityNet: -1280684114742, tickIdx: -181500 },
  { liquidityNet: -773729463989, tickIdx: -181440 },
  { liquidityNet: -4097154198364, tickIdx: -181380 },
  { liquidityNet: -1167186634519, tickIdx: -181320 },
  { liquidityNet: -113529433229, tickIdx: -181200 },
  { liquidityNet: 0, tickIdx: -180900 },
  { liquidityNet: 0, tickIdx: -180840 },
  { liquidityNet: 0, tickIdx: -180780 },
  { liquidityNet: -61155370139661, tickIdx: -180180 },
  { liquidityNet: -303984595577, tickIdx: -180120 },
  { liquidityNet: 0, tickIdx: -180000 },
  { liquidityNet: 0, tickIdx: -179820 },
  { liquidityNet: 0, tickIdx: -179760 },
  { liquidityNet: 0, tickIdx: -179700 },
  { liquidityNet: -369952148577597, tickIdx: -179580 },
  { liquidityNet: -14760697187118, tickIdx: -179520 },
  { liquidityNet: 0, tickIdx: -179460 },
  { liquidityNet: 0, tickIdx: -179100 },
  { liquidityNet: -586785501118, tickIdx: -179040 },
  { liquidityNet: 0, tickIdx: -178920 },
  { liquidityNet: 0, tickIdx: -178860 },
  { liquidityNet: 0, tickIdx: -178800 },
  { liquidityNet: 0, tickIdx: -178620 },
  { liquidityNet: -177280162046764, tickIdx: -178320 },
  { liquidityNet: 0, tickIdx: -178080 },
  { liquidityNet: -16327268781367, tickIdx: -177900 },
  { liquidityNet: 0, tickIdx: -177780 },
  { liquidityNet: 0, tickIdx: -177540 },
  { liquidityNet: 0, tickIdx: -177480 },
  { liquidityNet: -270133945598659, tickIdx: -177300 },
  { liquidityNet: 0, tickIdx: -177240 },
  { liquidityNet: 0, tickIdx: -177120 },
  { liquidityNet: -1137689426337, tickIdx: -176880 },
  { liquidityNet: 0, tickIdx: -176820 },
  { liquidityNet: 0, tickIdx: -176340 },
  { liquidityNet: 0, tickIdx: -175980 },
  { liquidityNet: 0, tickIdx: -175440 },
  { liquidityNet: 0, tickIdx: -175380 },
  { liquidityNet: -102585541689, tickIdx: -175080 },
  { liquidityNet: -390319542161059, tickIdx: -175020 },
  { liquidityNet: -35264576526227, tickIdx: -174900 },
  { liquidityNet: 0, tickIdx: -174660 },
  { liquidityNet: 0, tickIdx: -174360 },
  { liquidityNet: 0, tickIdx: -174060 },
  { liquidityNet: -7083531680537, tickIdx: -174000 },
  { liquidityNet: 0, tickIdx: -173940 },
  { liquidityNet: 0, tickIdx: -173220 },
  { liquidityNet: 0, tickIdx: -171060 },
  { liquidityNet: 0, tickIdx: -170880 },
  { liquidityNet: 0, tickIdx: -170640 },
  { liquidityNet: 0, tickIdx: -170340 },
  { liquidityNet: 0, tickIdx: -169620 },
  { liquidityNet: 0, tickIdx: -168360 },
  { liquidityNet: 0, tickIdx: -168120 },
  { liquidityNet: 0, tickIdx: -163680 },
  { liquidityNet: 0, tickIdx: -163440 },
  { liquidityNet: -6665589160077, tickIdx: -161160 },
  { liquidityNet: 0, tickIdx: -157140 },
  { liquidityNet: 0, tickIdx: -154260 },
  { liquidityNet: 0, tickIdx: -154140 },
  { liquidityNet: -2786887277176, tickIdx: -153360 },
  { liquidityNet: 0, tickIdx: -148800 },
  { liquidityNet: 0, tickIdx: -146820 },
  { liquidityNet: 0, tickIdx: -139500 },
  { liquidityNet: 0, tickIdx: -138240 },
  { liquidityNet: -784699599920, tickIdx: -138180 },
  { liquidityNet: 0, tickIdx: -131220 },
  { liquidityNet: 0, tickIdx: -116160 },
  { liquidityNet: -17388473740, tickIdx: -115140 },
  { liquidityNet: -17389547040, tickIdx: -115080 },
  { liquidityNet: 0, tickIdx: -92100 },
  { liquidityNet: 0, tickIdx: -86340 },
  { liquidityNet: -17496133052, tickIdx: -70140 },
  { liquidityNet: 0, tickIdx: -69060 },
  { liquidityNet: 0, tickIdx: -46080 },
  { liquidityNet: 216558840797888217, tickIdx: -19980 },
  { liquidityNet: -216558840797888217, tickIdx: 18000 },
  { liquidityNet: 0, tickIdx: 19980 },
  { liquidityNet: 0, tickIdx: 23040 },
  { liquidityNet: 0, tickIdx: 46080 },
  { liquidityNet: 1006622141407037933, tickIdx: 60000 },
  { liquidityNet: -968399120497163, tickIdx: 70140 },
  { liquidityNet: 47365088727841353, tickIdx: 74160 },
  { liquidityNet: -47365088727841353, tickIdx: 75960 },
  { liquidityNet: 0, tickIdx: 78480 },
  { liquidityNet: 0, tickIdx: 78540 },
  { liquidityNet: 9915356752940611347, tickIdx: 81660 },
  { liquidityNet: 59667982119728326, tickIdx: 81780 },
  { liquidityNet: -9975024735060339673, tickIdx: 81900 },
  { liquidityNet: 257741879600571692937, tickIdx: 82440 },
  { liquidityNet: -257741879600571692937, tickIdx: 82560 },
  { liquidityNet: -1006622141407037933, tickIdx: 180000 },
  { liquidityNet: 0, tickIdx: 199980 },
  { liquidityNet: 0, tickIdx: 206400 },
  { liquidityNet: 0, tickIdx: 299340 },
  { liquidityNet: -760947720023800, tickIdx: 887220 },
];

export const calculatorPoolTicksMockData2: ChartTick[] = [
  {
    liquidityNet: 2740975705281840,
    price0:
      "0.000000000000000000000000000000000000002939544628336670031698378204689082",
    price1: "340188745685363564484273285553137100000",
    tickIdx: -887270,
  },
  {
    liquidityNet: 4624654983,
    price0: "0.0000000000000000009996040641431475715491984630912174",
    price1: "1000396092684148719.852500763708141",
    tickIdx: -414490,
  },
  {
    liquidityNet: -4624654983,
    price0: "0.000000000000000009999037550537377159417827388360015",
    price1: "100009625421024357.6469826329652432",
    tickIdx: -391460,
  },
  {
    liquidityNet: 41222225304484,
    price0: "0.0000000000000001000203535814544673266492206788803",
    price1: "9997965056038530.017892097451939471",
    tickIdx: -368430,
  },
  {
    liquidityNet: -41111100781360,
    price0: "0.0000000000000001099877451004872071147611876345954",
    price1: "9091922005368672.213211238731903023",
    tickIdx: -367480,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000000000002000043374177967852512527664416284",
    price1: "4999891566906678.512384162621452769",
    tickIdx: -361500,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000000000002499667487822056247942629569274471",
    price1: "4000532090255305.865051897659711526",
    tickIdx: -359270,
  },
  {
    liquidityNet: 0,
    price0: "0.000000000000000274876843204366463376631618301132",
    price1: "3637992885623021.580526069925559403",
    tickIdx: -358320,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000000000002751518437751412660958954090153103",
    price1: "3634356892833387.260447256866539584",
    tickIdx: -358310,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000000000004545454943274806680870971584725041",
    price1: "2199999807455010.418084021478539831",
    tickIdx: -353290,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000000000005496537347452304221830097094098172",
    price1: "1819327217822888.490325089547451255",
    tickIdx: -351390,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000000000006062446437118861663272460850508226",
    price1: "1649499109595834.23899980857747034",
    tickIdx: -350410,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000000000007691390003797142298848930042990078",
    price1: "1300155107862574.390958009628599019",
    tickIdx: -348030,
  },
  {
    liquidityNet: 6749174559973830,
    price0: "0.000000000000000999503453102888211545532751604649",
    price1: "1000496793578421.658791083874875686",
    tickIdx: -345410,
  },
  {
    liquidityNet: -6749174559973830,
    price0: "0.000000000000001200207166198756266754426755210709",
    price1: "833189492749952.7751711531351077108",
    tickIdx: -343580,
  },
  {
    liquidityNet: -111124523124,
    price0: "0.000000000000009998031138493210137053118534561236",
    price1: "100019692492246.8943962883003260085",
    tickIdx: -322380,
  },
  {
    liquidityNet: 3853895956755,
    price0: "0.0000000000001000102864436927124368463061303972",
    price1: "9998971461430.769538036964671734875",
    tickIdx: -299350,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000000008997916193473110002330990783738443",
    price1: "1111368430754.420617344283647997502",
    tickIdx: -277380,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000000009984039987888020481944736817729886",
    price1: "1001598552502.928789671242569028076",
    tickIdx: -276340,
  },
  {
    liquidityNet: 18003865715,
    price0: "0.00000000000100040270489264179120266356026516",
    price1: "999597457213.30790736395788572891",
    tickIdx: -276320,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000000110009646800500499833346544357093",
    price1: "909011190458.1175334767602242898289",
    tickIdx: -275370,
  },
  {
    liquidityNet: 0,
    price0: "0.000000000001135867043471926577295403728302999",
    price1: "880384729662.873974086296814884897",
    tickIdx: -275050,
  },
  {
    liquidityNet: 0,
    price0: "0.000000000001137003421791895967526826509955852",
    price1: "879504828951.1907040543379174984953",
    tickIdx: -275040,
  },
  {
    liquidityNet: 0,
    price0: "0.000000000002000441639911616624027781043352729",
    price1: "499889614397.4397232272651920233859",
    tickIdx: -269390,
  },
  {
    liquidityNet: 22212901948448,
    price0: "0.000000000002999206421440883029325756532360634",
    price1: "333421532059.6634935328783454056151",
    tickIdx: -265340,
  },
  {
    liquidityNet: -22212901948448,
    price0: "0.000000000007998857194260551022902571373058918",
    price1: "125017858890.8342584650604035984906",
    tickIdx: -255530,
  },
  {
    liquidityNet: 719503892580,
    price0: "0.000000000009997024827745312633149241281014369",
    price1: "100029760576.8311208127395629746463",
    tickIdx: -253300,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000001999041450292571429659015820732027",
    price1: "50023975233.41220065683650172089672",
    tickIdx: -246370,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000005000920241872446159600269461629393",
    price1: "19996319709.86163297679996808833634",
    tickIdx: -237200,
  },
  {
    liquidityNet: 32003584930518,
    price0: "0.00000000009990027507700642644216311779355243",
    price1: "10009982447.28722741739832220867951",
    tickIdx: -230280,
  },
  {
    liquidityNet: 302641161311,
    price0: "0.0000000001000002203191973486882842416434346",
    price1: "9999977968.128805572948034378952146",
    tickIdx: -230270,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000001712535986559416051674951355554526",
    price1: "5839293351.19583616673981544063698",
    tickIdx: -224890,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000001999640782142086429931781867343458",
    price1: "5000898205.970596286559125210864159",
    tickIdx: -223340,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000003438173796479657828183773750432039",
    price1: "2908520799.687028162666733097326057",
    tickIdx: -217920,
  },
  {
    liquidityNet: 75673176463425,
    price0: "0.0000000003539335534477957609910268253713488",
    price1: "2825389088.597663253971475459553221",
    tickIdx: -217630,
  },
  {
    liquidityNet: -75673176463425,
    price0: "0.0000000003589232198884408431144677765018624",
    price1: "2786111192.000384415276637927768959",
    tickIdx: -217490,
  },
  {
    liquidityNet: 1024210077534050,
    price0: "0.0000000003799750693624588116541537222938348",
    price1: "2631751608.540660438433776984065484",
    tickIdx: -216920,
  },
  {
    liquidityNet: 20572362790301271,
    price0: "0.0000000003998554448022749729745460379996199",
    price1: "2500903796.607024497996230456387577",
    tickIdx: -216410,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000004022616686150351533827407436459721",
    price1: "2485944045.931458282546659292624211",
    tickIdx: -216350,
  },
  {
    liquidityNet: 0,
    price0: "0.000000000430135142433942461560970595230316",
    price1: "2324850730.264556136515761437296271",
    tickIdx: -215680,
  },
  {
    liquidityNet: -20572362790301271,
    price0: "0.00000000044993233023031100335379251731472",
    price1: "2222556444.183774914173421242987028",
    tickIdx: -215230,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000004697003998562350398994445422346248",
    price1: "2129016710.026387038142525280689382",
    tickIdx: -214800,
  },
  {
    liquidityNet: 0,
    price0: "0.000000000475370473560455436784692937538129",
    price1: "2103622449.476396825447381890995328",
    tickIdx: -214680,
  },
  {
    liquidityNet: 6374462628,
    price0: "0.0000000005002419565849645209829118175296625",
    price1: "1999032641.777525810476810266302715",
    tickIdx: -214170,
  },
  {
    liquidityNet: 24018939819093,
    price0: "0.0000000005108575808538848840887379712690043",
    price1: "1957492728.8512124027215865343735",
    tickIdx: -213960,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000005354398157969803168739270819649326",
    price1: "1867623532.089298973021398456622223",
    tickIdx: -213490,
  },
  {
    liquidityNet: 0,
    price0: "0.000000000541361869256100967408672529712607",
    price1: "1847193267.183973050165817994806769",
    tickIdx: -213380,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000005435315447093119075226330063459431",
    price1: "1839819619.917025525751434554219887",
    tickIdx: -213340,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000005457099158092604628690433386321243",
    price1: "1832475406.859796754927024485789226",
    tickIdx: -213300,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000005489938530704597253828798963335799",
    price1: "1821514019.523378201473809656458735",
    tickIdx: -213240,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000005500928844910405438665508465525767",
    price1: "1817874813.86025303086952214914895",
    tickIdx: -213220,
  },
  {
    liquidityNet: 412935343371,
    price0: "0.0000000005589647124269882231292090216602149",
    price1: "1789021699.881671248019549649079341",
    tickIdx: -213060,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000005606440403316976173356117979996262",
    price1: "1783662944.866698754271775211592658",
    tickIdx: -213030,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000005696860359643794352394682075016283",
    price1: "1755352838.00167897996998135753146",
    tickIdx: -212870,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000005702559784274342878036627692009769",
    price1: "1753598450.221686050147617820580927",
    tickIdx: -212860,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000005708264910894947086608998605108401",
    price1: "1751845815.864945674166951484585049",
    tickIdx: -212850,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000005713975745210163613678345401633089",
    price1: "1750094933.178998596635264371229119",
    tickIdx: -212840,
  },
  {
    liquidityNet: 1,
    price0: "0.0000000005976964373044014405828673196233444",
    price1: "1673090113.28556566130395241366555",
    tickIdx: -212390,
  },
  {
    liquidityNet: 2889276436260,
    price0: "0.00000000060009189099653710417012050071656",
    price1: "1666411452.984907288592914635349466",
    tickIdx: -212350,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000006006922530009082200776404693608595",
    price1: "1664745957.691730118412320458792526",
    tickIdx: -212340,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000006049116384268684318528418336692071",
    price1: "1653134005.820415839466511282453748",
    tickIdx: -212270,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000006152825204904853147172673434028597",
    price1: "1625269639.064066226980155350777779",
    tickIdx: -212100,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000006270840579085413466160200970526632",
    price1: "1594682542.776183153961235990709235",
    tickIdx: -211910,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000006302271722830960102188972864281316",
    price1: "1586729427.068884344929326660193574",
    tickIdx: -211860,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000006500691848245591589692093372552965",
    price1: "1538297804.825005296373674435797993",
    tickIdx: -211550,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000006605534177910722754131120253588598",
    price1: "1513882107.133827508158127414896372",
    tickIdx: -211390,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000006658587729971026605196089292961956",
    price1: "1501819966.265355915466326973875175",
    tickIdx: -211310,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000006698657341535605407556986923747631",
    price1: "1492836473.063659088339805250278814",
    tickIdx: -211250,
  },
  {
    liquidityNet: 218907027699,
    price0: "0.0000000006861361624711829591126755649064463",
    price1: "1457436664.463810434930310744022019",
    tickIdx: -211010,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000006902651475691611851142634049364114",
    price1: "1448718660.534436008940014091277086",
    tickIdx: -210950,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000006985978086939327572477081771070493",
    price1: "1431438787.17506330278135225417133",
    tickIdx: -210830,
  },
  {
    liquidityNet: 22149675407889,
    price0: "0.0000000006999963324438972221215252451727395",
    price1: "1428578913.419017442439843002819559",
    tickIdx: -210810,
  },
  {
    liquidityNet: 0,
    price0: "0.000000000702099369269179492364647652724377",
    price1: "1424299812.490798150110093769188796",
    tickIdx: -210780,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000007077384083590976415811353770671103",
    price1: "1412951435.430098166819246388406547",
    tickIdx: -210700,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000007307511266827536771958971594811942",
    price1: "1368454954.752519050604613854092325",
    tickIdx: -210380,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000007403124270772724368222322510778551",
    price1: "1350781053.274986908196450196273611",
    tickIdx: -210250,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000007440230725670791587188850006824282",
    price1: "1344044340.654291519095372729941876",
    tickIdx: -210200,
  },
  {
    liquidityNet: 1342622820654007,
    price0: "0.000000000749998829523496841993681188130996",
    price1: "1333335414.183697507288371303166092",
    tickIdx: -210120,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000007560225818614004332120018610504111",
    price1: "1322711813.102068536203213913934501",
    tickIdx: -210040,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000007598119708828050724024242955196831",
    price1: "1316115089.418934691428452007524925",
    tickIdx: -209990,
  },
  {
    liquidityNet: 1753479987759865,
    price0: "0.0000000007620947150644467775410487464709539",
    price1: "1312172857.563294724794432017217265",
    tickIdx: -209960,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000007643843174178447984288048605186705",
    price1: "1308242434.091380898688791282947218",
    tickIdx: -209930,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000007720661215169882441098275371683053",
    price1: "1295225851.945371737244130177600485",
    tickIdx: -209830,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000007736117215661826671720153288975679",
    price1: "1292638118.22226863925281849754425",
    tickIdx: -209810,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000007798251251515917555168047139130753",
    price1: "1282338780.512628408958754865997186",
    tickIdx: -209730,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000007868748750876774550768310605131923",
    price1: "1270850082.598678854720793178275557",
    tickIdx: -209640,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000007908189041212216309947471025359587",
    price1: "1264512007.475625281766611464964089",
    tickIdx: -209590,
  },
  {
    liquidityNet: 286745851543126,
    price0: "0.0000000008003654181851453241298233230334723",
    price1: "1249429294.768297957924547542994084",
    tickIdx: -209470,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000008181676741199058691176877972863895",
    price1: "1222243351.371330088360964987597891",
    tickIdx: -209250,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000008198055649198341862923129997412152",
    price1: "1219801429.498452324067284450982875",
    tickIdx: -209230,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000008206257394956521201773147557594481",
    price1: "1218582298.69147097279022412402397",
    tickIdx: -209220,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000008297019967254027133883498374452776",
    price1: "1205252010.898750352020704918035839",
    tickIdx: -209110,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000008305320721876083079729620502278583",
    price1: "1204047421.511388304979317657317325",
    tickIdx: -209100,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000008321947152901297649254318246863689",
    price1: "1201641853.314783345593851406877915",
    tickIdx: -209080,
  },
  {
    liquidityNet: 2892954073961,
    price0: "0.0000000008330272845929226192698935908795406",
    price1: "1200440872.100212570991500242795532",
    tickIdx: -209070,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000008405579909742731907744984257542275",
    price1: "1189685911.903497473851043493243055",
    tickIdx: -208980,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000008422407049750480786851058324426203",
    price1: "1187309036.589042133338156079130355",
    tickIdx: -208960,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000008498547022220749688779333140286858",
    price1: "1176671726.808532340025167184191298",
    tickIdx: -208870,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000008601138776474149364920197303899245",
    price1: "1162636746.119248585089295345189869",
    tickIdx: -208750,
  },
  {
    liquidityNet: 0,
    price0: "0.000000000862697964270145659111438662920539",
    price1: "1159154236.379836309433266203529808",
    tickIdx: -208720,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000008644250003087118539242060311987173",
    price1: "1156838360.346901449040309270444214",
    tickIdx: -208700,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000008696268802558927422992211157357885",
    price1: "1149918456.644008304698305454217081",
    tickIdx: -208640,
  },
  {
    liquidityNet: 0,
    price0: "0.000000000874860063734294059133469647657306",
    price1: "1143039945.990393846915162920507524",
    tickIdx: -208580,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000008792450982561701010872941099926867",
    price1: "1137339294.792005400390165361025337",
    tickIdx: -208530,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000008801247391202483646186129847151473",
    price1: "1136202580.783692182734240251150315",
    tickIdx: -208520,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000008818866618392619493415898939745507",
    price1: "1133932559.89879809437035533713683",
    tickIdx: -208500,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000008889696953479880039729900651928741",
    price1: "1124897738.621505068587711704043105",
    tickIdx: -208420,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000008898590651863939326173160379094275",
    price1: "1123773459.329242710523668137473271",
    tickIdx: -208410,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000008907493247949614375325709432783286",
    price1: "1122650303.698166265154222446960669",
    tickIdx: -208400,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000008925325168841544072847942609041604",
    price1: "1120407358.928519860949281965719482",
    tickIdx: -208380,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000008952140005773845929330420114391241",
    price1: "1117051341.193314409828029427696552",
    tickIdx: -208350,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000008970061305061182898198169320276366",
    price1: "1114819582.599473895899810773351117",
    tickIdx: -208330,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000008997010545186087602796881920812646",
    price1: "1111480302.237788173560556408884979",
    tickIdx: -208300,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009006011605465849249457235535557213",
    price1: "1110369433.005270398273513951302068",
    tickIdx: -208290,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009024040750370049372564822470919928",
    price1: "1108151024.206083002094762657942389",
    tickIdx: -208270,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009187935920864742768964902515916794",
    price1: "1088383733.42277598695568943150982",
    tickIdx: -208090,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009197127992459517181181748841894671",
    price1: "1087295948.061039969795459351675662",
    tickIdx: -208080,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009243226477715435917355775681008893",
    price1: "1081873307.346636485308281825472058",
    tickIdx: -208030,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009252473864754447633596050513352005",
    price1: "1080792028.831674094144565646365963",
    tickIdx: -208020,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009298849758444831520876021656807473",
    price1: "1075401824.931993634073580231159814",
    tickIdx: -207970,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009336117780464405740907932321549284",
    price1: "1071109023.594877015390892934625501",
    tickIdx: -207930,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009354807765296816501388354454199891",
    price1: "1068969053.228076926231569715059147",
    tickIdx: -207910,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009401696584088297247222769287371413",
    price1: "1063637813.724417434252959006796638",
    tickIdx: -207860,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009420517851202474295341155241503707",
    price1: "1061512770.099316586909199111780469",
    tickIdx: -207840,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009467736026661468054766462151905727",
    price1: "1056218717.10825675905747662249577",
    tickIdx: -207790,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009486689498211049304409126623999116",
    price1: "1054108496.107704181457623002844079",
    tickIdx: -207770,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009496180457858136532858960937425595",
    price1: "1053054967.139440815029264477765048",
    tickIdx: -207760,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009505680912736941804218220155362801",
    price1: "1052002491.120936480489265863634574",
    tickIdx: -207750,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009591613862632588707117991157605266",
    price1: "1042577416.398966927062243949121553",
    tickIdx: -207660,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009601209793872654592069934568924852",
    price1: "1041535412.17085447137730842279479",
    tickIdx: -207650,
  },
  {
    liquidityNet: 0,
    price0: "0.000000000961081532536328131422402586159381",
    price1: "1040494449.374096969133945346097366",
    tickIdx: -207640,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009649333646067024205764480144906756",
    price1: "1036340991.699038620654013006203338",
    tickIdx: -207600,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009678323660809338028437137342286754",
    price1: "1033236782.573539411832730593591236",
    tickIdx: -207570,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009688006340877396839153093025204454",
    price1: "1032204113.843958051451119489666987",
    tickIdx: -207560,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009697698707983891864275080359756592",
    price1: "1031172477.215365584465504381817591",
    tickIdx: -207550,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009707400771820221869961866426569508",
    price1: "1030141871.656228450537937648506219",
    tickIdx: -207540,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009756056881827600175359951546839095",
    price1: "1025004273.870808142452016048267114",
    tickIdx: -207490,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009785367530956860248812808868972196",
    price1: "1021934022.239239485898991752373231",
    tickIdx: -207460,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009795157303077655660742888190149534",
    price1: "1020912650.055960040930761909499378",
    tickIdx: -207450,
  },
  {
    liquidityNet: 0,
    price0: "0.000000000980495686937714430070721522703303",
    price1: "1019892298.68333380347645869766436",
    tickIdx: -207440,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009814766239653913417852922466058962",
    price1: "1018872967.101111341682456147426771",
    tickIdx: -207430,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009824585423716353259116587907284051",
    price1: "1017854654.290062912214176343414072",
    tickIdx: -207420,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009854101956848850588132001521743602",
    price1: "1014805818.30693831764636334277191",
    tickIdx: -207390,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009883707168097582592155194254789685",
    price1: "1011766114.669785534450966932499526",
    tickIdx: -207360,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009903493372749613503253745634625983",
    price1: "1009744705.592062442372805585843684",
    tickIdx: -207340,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009923319187427303177821114588049118",
    price1: "1007727335.090646914141084291515844",
    tickIdx: -207320,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009943184691426007156762340436954848",
    price1: "1005713995.096861129040924077452257",
    tickIdx: -207300,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009953132351743935300530877735908213",
    price1: "1004708834.023276380685482369478699",
    tickIdx: -207290,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009983035085361905289564096433025704",
    price1: "1001699374.438037417539320188788863",
    tickIdx: -207260,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000009993022614011229486850607040341747",
    price1: "1000698225.777953060122255486461814",
    tickIdx: -207250,
  },
  {
    liquidityNet: 210563820384037,
    price0: "0.000000001000302013468478961373148414637455",
    price1: "999698077.7161172051593386186226227",
    tickIdx: -207240,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001001302765737910751629525643759315",
    price1: "998698929.2524817131246998644760349",
    tickIdx: -207230,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001002304519210070606211116444267365",
    price1: "997700779.3879979428254577409889238",
    tickIdx: -207220,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001004311033770190170432878001187859",
    price1: "995707471.4652825016320059295358866",
    tickIdx: -207200,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001005315796864463974304094290899828",
    price1: "994712311.4139420544685162368618253",
    tickIdx: -207190,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001006321565174096037076263589173143",
    price1: "993718145.9755337835922134460851036",
    tickIdx: -207180,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001007328339704754184566458401390581",
    price1: "992724974.1559915751957571669500412",
    tickIdx: -207170,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001010354710694661241418778804312598",
    price1: "989751410.4847970239052417298983517",
    tickIdx: -207140,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001012377340942294927491783964419261",
    price1: "987773984.6184482801498586968541223",
    tickIdx: -207120,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001013390173974547189840631809744121",
    price1: "986786753.6922817014698120249569349",
    tickIdx: -207110,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001014404020295728130201263060623573",
    price1: "985800509.4542814002133319752854327",
    tickIdx: -207100,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001015418880919582778885547668330262",
    price1: "984815250.9183016715935794384748377",
    tickIdx: -207090,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000101643475686087036754260178926749",
    price1: "983830977.0991824143652460768811463",
    tickIdx: -207080,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000101948848675215742482189445761879",
    price1: "980884054.1061498322940927769802682",
    tickIdx: -207050,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001020508434131088650955363299645438",
    price1: "979903709.3225490625183315121553534",
    tickIdx: -207040,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001021529401916497543940954556862954",
    price1: "978924344.3447578683092843708855169",
    tickIdx: -207030,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001023574402791232787971726569878044",
    price1: "976968549.8905144078714792267073112",
    tickIdx: -207010,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001025623497555551456604781988515082",
    price1: "975016662.921021272877773102159208",
    tickIdx: -206990,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001026649582706777268444443691111074",
    price1: "974042182.3028308865004148363571466",
    tickIdx: -206980,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001027676694405015775916081606491521",
    price1: "973068675.6295086665848633314794798",
    tickIdx: -206970,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001029734001551594677348880642020577",
    price1: "971124580.2248039496418766024393632",
    tickIdx: -206950,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001032827687082699711192876538246301",
    price1: "968215717.4006208063616374492538318",
    tickIdx: -206920,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001034895306007394696203027810792714",
    price1: "966281317.7286308449144085535714006",
    tickIdx: -206900,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001035930667140498966357311160371111",
    price1: "965315567.6531141446902608110438457",
    tickIdx: -206890,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001040082480312733840314696732214042",
    price1: "961462209.9002362108632424009938066",
    tickIdx: -206850,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001041123030954994456876080478998609",
    price1: "960501276.2830984589085886103540346",
    tickIdx: -206840,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001044250933162858282097397121819037",
    price1: "957624234.0250252497038933444251043",
    tickIdx: -206810,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001047388232696428162441251435940234",
    price1: "954755809.5297381248303876508092837",
    tickIdx: -206780,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001049485000393993349279988587644259",
    price1: "952848301.4283997449839460788928477",
    tickIdx: -206760,
  },
  {
    liquidityNet: 20409464402966,
    price0: "0.00000000105053495778859776180348583343794",
    price1: "951895976.9839786141268709819948165",
    tickIdx: -206750,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001051585965613203623250596141384713",
    price1: "950944604.3404329032746666315627755",
    tickIdx: -206740,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001052638024918713754644556991378078",
    price1: "949994182.5464850181606512794297756",
    tickIdx: -206730,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001055800522245474928688179236659252",
    price1: "947148612.7637079931513056119075879",
    tickIdx: -206700,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001056856798004673651221848518831002",
    price1: "946201984.8743763125581715541116306",
    tickIdx: -206690,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001060031970019495415171902638084986",
    price1: "943367774.0696902316868025602707802",
    tickIdx: -206660,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001067477851705180129006709817831729",
    price1: "936787586.1804611898456880364956895",
    tickIdx: -206590,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001069614836833950838613174703392606",
    price1: "934915976.8202074911177957526616595",
    tickIdx: -206570,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001070684933125837609756966888398958",
    price1: "933981574.8415598476203553976931476",
    tickIdx: -206560,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001073901649754590786956207064646276",
    price1: "931183968.5026288253763519292495591",
    tickIdx: -206530,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001074976034788978519920670832460692",
    price1: "930253296.4805149609423555919836803",
    tickIdx: -206520,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001078205643406446993147447424380604",
    price1: "927466857.658649709423502067317926",
    tickIdx: -206490,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001080364104513659350707411859218261",
    price1: "925613870.1962554102308612474161668",
    tickIdx: -206470,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001084693998455607107830381556432291",
    price1: "921918994.134571724096931233177375",
    tickIdx: -206430,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001086865448608192248394199711692405",
    price1: "920077090.7572509798621856459513832",
    tickIdx: -206410,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001090130777234372096380885157968178",
    price1: "917321133.2836312747620242363679165",
    tickIdx: -206380,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000109231311128059500934552191853068",
    price1: "915488415.9795812607091304413109915",
    tickIdx: -206360,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000109340591606387619530448837883945",
    price1: "914573430.8808884558008522481100792",
    tickIdx: -206350,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001094499814143833974451595775919404",
    price1: "913659360.2642537707350309173155635",
    tickIdx: -206340,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001095594806614257138103062362099",
    price1: "912746203.2156979114031042828516731",
    tickIdx: -206330,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001097788079107127284595381194272629",
    price1: "910922626.1714719645646258553460907",
    tickIdx: -206310,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001098886361322627635896427675233385",
    price1: "910012204.3524070218875763179932504",
    tickIdx: -206300,
  },
  {
    liquidityNet: 163028861529042671,
    price0: "0.000000001099985742314702301456487757645028",
    price1: "909102692.454629371390741671243843",
    tickIdx: -206290,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001102187805026758963909119061731383",
    price1: "907286394.786160745179613356144911",
    tickIdx: -206270,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001103290488948583670239423318837267",
    price1: "906379607.1993535627877817452017251",
    tickIdx: -206260,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001104394276050670311326936046975496",
    price1: "905473725.9015994432863261238279112",
    tickIdx: -206250,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001105499167436695712629086093278351",
    price1: "904568749.9871075932727305598269054",
    tickIdx: -206240,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001106605164211440873215795588617949",
    price1: "903664678.5509925121521544941868031",
    tickIdx: -206230,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000110771226748079207044010309053589",
    price1: "902761510.6892730873423370102212554",
    tickIdx: -206220,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001108820478351741965713954583679921",
    price1: "901859245.4988716903827987601288224",
    tickIdx: -206210,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001109929797932390711390268003063306",
    price1: "900957882.0776132739474377484781905",
    tickIdx: -206200,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000111104022733194705875237805162559",
    price1: "900057419.5242244697596160754302672",
    tickIdx: -206190,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001112151767660729467111969190843087",
    price1: "899157856.9383326874088356449089678",
    tickIdx: -206180,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000111549306534228659385191531646888",
    price1: "896464559.9954063356281075404046718",
    tickIdx: -206150,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001116609060513390880481457836020645",
    price1: "895568588.2937608028402795947154005",
    tickIdx: -206140,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001118844401465108136922535174634868",
    price1: "893779330.4328257176509010269111092",
    tickIdx: -206120,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001119963749480838731086085931094927",
    price1: "892886042.4844570646253092710527029",
    tickIdx: -206110,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001122205806188225892669224984132842",
    price1: "891102144.0859231336662383791708824",
    tickIdx: -206090,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001123328517121715169156257278412874",
    price1: "890211531.8520376820592887337470046",
    tickIdx: -206080,
  },
  {
    liquidityNet: 5612278721122,
    price0: "0.000000001125577309761280067143094171028387",
    price1: "888432976.8624126410157078553255607",
    tickIdx: -206060,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001127830604261394823597088756793619",
    price1: "886657975.2505387723708493450298259",
    tickIdx: -206040,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001128958942524791495844783313063384",
    price1: "885771804.742173245138459227781696",
    tickIdx: -206030,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001130088409634339207582782856740145",
    price1: "884886519.9171171980006472899998257",
    tickIdx: -206020,
  },
  {
    liquidityNet: 117250311670238,
    price0: "0.000000001131219006719392226103792202288592",
    price1: "884002119.8901742525105135495817425",
    tickIdx: -206010,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001133483595339081696098141688300165",
    price1: "882235970.6942648170379548022682856",
    tickIdx: -205990,
  },
  {
    liquidityNet: -117250311670238,
    price0: "0.00000000113461758913808051783381170381144",
    price1: "881354219.7593255827277830471978483",
    tickIdx: -205980,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001135752717441311651013294710499054",
    price1: "880473350.0905521956134856182435631",
    tickIdx: -205970,
  },
  {
    liquidityNet: 95943675128053,
    price0: "0.000000001136888981383789991016909996510751",
    price1: "879593360.8071629924235190047462653",
    tickIdx: -205960,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001138026382101665959013285716045021",
    price1: "878714251.0292566073134054478681745",
    tickIdx: -205950,
  },
  {
    liquidityNet: 20510761916597,
    price0: "0.000000001139164920732226637996270089899641",
    price1: "877836019.8778110920522758572169293",
    tickIdx: -205940,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001140304598413896909958390870179087",
    price1: "876958666.4746830370887424806476786",
    tickIdx: -205930,
  },
  {
    liquidityNet: -7866675670146,
    price0: "0.000000001141445416286240594202000129008129",
    price1: "876082189.9426066934952224809280069",
    tickIdx: -205920,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001142587375489961586789242568670519",
    price1: "875206589.4051930957898344513136634",
    tickIdx: -205910,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001143730477166905001131986689301496",
    price1: "874331863.9869291856349907795682724",
    tickIdx: -205900,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001144874722460058309722859290111755",
    price1: "873458012.8131769364118096465684959",
    tickIdx: -205890,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001147166648472663153406353044420982",
    price1: "871712929.7050252264486442894524199",
    tickIdx: -205870,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001148314331483811720465616807573063",
    price1: "870841696.0257170044781076248931643",
    tickIdx: -205860,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000114946316269456653717436847553931",
    price1: "869971333.101101176243696890230614",
    tickIdx: -205850,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001150613143253644037413137713887417",
    price1: "869101840.0609017729287017201365292",
    tickIdx: -205840,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001151764274310909888556600062094779",
    price1: "868233216.035712623224844116283772",
    tickIdx: -205830,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001152916557017380141224364083944142",
    price1: "867365460.1569964840129673513383646",
    tickIdx: -205820,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001154069992525222380182026830918102",
    price1: "866498571.557084171912566249614568",
    tickIdx: -205810,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001155224581987756876393648404664271",
    price1: "865632549.3691736956992904806879738",
    tickIdx: -205800,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001156380326559457740226797555906939",
    price1: "864767392.7273293895895533701479409",
    tickIdx: -205790,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001158695285654031136552993560020749",
    price1: "863039672.6224230575206260268976378",
    tickIdx: -205770,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001159854502491631481803195933231109",
    price1: "862177107.4318135388817107483620733",
    tickIdx: -205760,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001161014879067856134685790972323222",
    price1: "861315404.3321734776119863381854081",
    tickIdx: -205750,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001162176416542965741082341855350909",
    price1: "860454562.4618858646888941275018117",
    tickIdx: -205740,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001164502978836687473761097196628944",
    price1: "858735458.9672048036683106468444694",
    tickIdx: -205720,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000116566800598162945270197161987616",
    price1: "857877195.6238796122522285523689571",
    tickIdx: -205710,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001166834198678118416571576635269511",
    price1: "857019790.0720416637855878930101021",
    tickIdx: -205700,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000116800155809223055044164934944272",
    price1: "856163241.4543710676906105787212609",
    tickIdx: -205690,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001169170085391208640443235521165092",
    price1: "855307548.9144047819427055744820772",
    tickIdx: -205680,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001170339781743463240892859363311224",
    price1: "854452711.5965357566929939720621555",
    tickIdx: -205670,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001171510648318573842586344861488748",
    price1: "853598728.6460120787467407174435916",
    tickIdx: -205660,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001172682686287290042261456786424088",
    price1: "852745599.2089361168968375578071244",
    tickIdx: -205650,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000117385589682153271323053074590914",
    price1: "851893322.4322636681114826269524627",
    tickIdx: -205640,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000117503028109439517718426279198392",
    price1: "851041897.4638031045752029421346469",
    tickIdx: -205630,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001176205840280144377167830270072791",
    price1: "850191323.452214521582366938555947",
    tickIdx: -205620,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001177382575554222051730516769005967",
    price1: "849341599.5470088862823350201576898",
    tickIdx: -205610,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001178560488093245910250015204244894",
    price1: "848492724.8985471872753969569093074",
    tickIdx: -205600,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001179739579075010809432584241190834",
    price1: "847644698.6580395850586458094937794",
    tickIdx: -205590,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000118091984967848993099023444119188",
    price1: "846797519.9775445633209389121402277",
    tickIdx: -205580,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001182101301083835960496121689775746",
    price1: "845951188.009968081086097293356658",
    tickIdx: -205570,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000118328393447238226741932664472523",
    price1: "845105701.9090627257034957624695476",
    tickIdx: -205560,
  },
  {
    liquidityNet: 5279563534382,
    price0: "0.000000001184467751026644086340200120881462",
    price1: "844261060.8294268666851967371829668",
    tickIdx: -205550,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000118565275193031969934745550900804",
    price1: "843417263.9265038103887817338290844",
    tickIdx: -205540,
  },
  {
    liquidityNet: -5279563534382,
    price0: "0.000000001186838938368291619618190507678086",
    price1: "842574310.3565809555450352875951092",
    tickIdx: -205530,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001188026311526627776182021629957441",
    price1: "841732199.2767889496296369147796977",
    tickIdx: -205520,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001189214872592582699870516130651647",
    price1: "840890929.8451008460780175730556135",
    tickIdx: -205510,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001190404622754598710453107185063383",
    price1: "840050501.2203312623425379187956656",
    tickIdx: -205500,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001191595563202307104960679336571731",
    price1: "839210912.562135538791146502756602",
    tickIdx: -205490,
  },
  {
    liquidityNet: 8567685840843,
    price0: "0.000000001192787695126529347198012417896259",
    price1: "838372163.0310088984466768868115008",
    tickIdx: -205480,
  },
  {
    liquidityNet: -8567685840843,
    price0: "0.000000001193981019719278258446273339648616",
    price1: "837534251.7882856075659435049761319",
    tickIdx: -205470,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001195175538173759209356746329703279",
    price1: "836697177.9961381370577969316896195",
    tickIdx: -205460,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001196371251684371313036993398038571",
    price1: "835860940.8175763247393000591853003",
    tickIdx: -205450,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001197568161446708619330637994010115",
    price1: "835025539.4164465384291875238248749",
    tickIdx: -205440,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001198766268657561310291966016522871",
    price1: "834190972.9574308398777715584685214",
    tickIdx: -205430,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001199965574514916896856539532265861",
    price1: "833357240.606046149532458284316516",
    tickIdx: -205420,
  },
  {
    liquidityNet: 135190535987989,
    price0: "0.0000000012011660802179614167090197530669",
    price1: "832524341.5286434121380392911848619",
    tickIdx: -205410,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001202367786967080633349397020514331",
    price1: "831692274.8924067631709241898692957",
    tickIdx: -205400,
  },
  {
    liquidityNet: -135190535987988,
    price0: "0.000000001203570695963861236358826744279994",
    price1: "830861039.8653526961064806541097284",
    tickIdx: -205390,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001204774808411092042866271440063804",
    price1: "830030635.6163292305186493026914019",
    tickIdx: -205380,
  },
  {
    liquidityNet: 63420656769532,
    price0: "0.000000001205980125512765200217150213766427",
    price1: "829201061.3150150810110016044107476",
    tickIdx: -205370,
  },
  {
    liquidityNet: 37376441120218,
    price0: "0.000000001207186648474077389845198240383882",
    price1: "828372316.1319188269784098199938955",
    tickIdx: -205360,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001208394378501431032348739989207722",
    price1: "827544399.2383780831984988255848143",
    tickIdx: -205350,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001209603316802435493772581151207821",
    price1: "826717309.8065586712520504921190643",
    tickIdx: -205340,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001210813464585908293096725429973076",
    price1: "825891047.0094537917715321237688688",
    tickIdx: -205330,
  },
  {
    liquidityNet: -37376441120218,
    price0: "0.000000001212024823061876310933123564289613",
    price1: "825065610.0208831975169212866865363",
    tickIdx: -205320,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001213237393441576999431663158347626",
    price1: "824240998.0154923672780001864870053",
    tickIdx: -205310,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001214451176937459593396609104687974",
    price1: "823417210.1687516806022935792972627",
    tickIdx: -205300,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001215666174763186322614705595329329",
    price1: "822594245.6569555933478250267614229",
    tickIdx: -205290,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001216882388133633625396151928057187",
    price1: "821772103.6572218140598671301262066",
    tickIdx: -205280,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001218099818264893363329665527608684",
    price1: "820950783.3474904811708622024432067",
    tickIdx: -205270,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001219318466374274037252846815453083",
    price1: "820130283.9065233410226906610125206",
    tickIdx: -205260,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001220538333680302004439061777048206",
    price1: "819310604.513902926710465244457907",
    tickIdx: -205250,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001221759421402722697002059291849278",
    price1: "818491744.3500317377470299802673492",
    tickIdx: -205240,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001222981730762501841519541508959711",
    price1: "817673702.5961314205473436492556902",
    tickIdx: -205230,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001224205262981826679876906770144663",
    price1: "816856478.4342419497319283132085583",
    tickIdx: -205220,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001225430019284107191332385801978804",
    price1: "816040071.0472208102485642909500699",
    tickIdx: -205210,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001226656000893977315804793120171009",
    price1: "815224479.6187421803114137862414579",
    tickIdx: -205200,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001227883209037296178385116811601726",
    price1: "814409703.3332961151567561882647901",
    tickIdx: -205190,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001229111644941149315073171083324888",
    price1: "813595741.3761877316145188819760141",
    tickIdx: -205180,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001230341309833849899740537192726625",
    price1: "812782592.9335363934947882213255658",
    tickIdx: -205170,
  },
  {
    liquidityNet: 28482271368219,
    price0: "0.000000001231572204944939972321019599198863",
    price1: "811970257.1922748977884861332435392",
    tickIdx: -205160,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001232804331505191668229845405078531",
    price1: "811158733.3401486616813986343706737",
    tickIdx: -205150,
  },
  {
    liquidityNet: -28482271368219,
    price0: "0.000000001234037690746608449012836382223562",
    price1: "810348020.5657149103807433557871079",
    tickIdx: -205140,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001235272283902426334226784110446645",
    price1: "809538118.0583418657534639834486347",
    tickIdx: -205130,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001236508112207115134552259985107731",
    price1: "808729025.0082079357754403336860467",
    tickIdx: -205120,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001237745176896379686140093083478076",
    price1: "807920740.6063009047908035939577567",
    tickIdx: -205110,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001238983479207161086192750113033186",
    price1: "807113264.0444171245805470690701207",
    tickIdx: -205100,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001240223020377637929781852899610749",
    price1: "806306594.5151607062396235822945331",
    tickIdx: -205090,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001241463801647227547903070109383674",
    price1: "805500731.2119427128617214892162483",
    tickIdx: -205080,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001242705824256587246769621135848965",
    price1: "804695673.3289803530309120697478062",
    tickIdx: -205070,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001243949089447615548345631321521585",
    price1: "803891420.0612961751193618705306946",
    tickIdx: -205060,
  },
  {
    liquidityNet: 3162737997976704,
    price0: "0.000000001245193598463453432120578923749916",
    price1: "803087970.604717262390304375933314",
    tickIdx: -205050,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001246439352548485578126075475037189",
    price1: "802285324.1558744289054661910321718",
    tickIdx: -205040,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001247686352948341611196222430462512",
    price1: "801483479.9122014162361437243373885",
    tickIdx: -205030,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001248934600909897346472788238247187",
    price1: "800682437.0719340909771271615937981",
    tickIdx: -205020,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001250184097681276036156451214208062",
    price1: "799882194.8341096430626693247560033",
    tickIdx: -205010,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000125143484451184961750535484678095",
    price1: "799082752.3985657848836978122005191",
    tickIdx: -205000,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001252686842652239962082223406485013",
    price1: "798284108.9659399512054696174013375",
    tickIdx: -204990,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001253940093354320126251286982134509",
    price1: "797486263.7376684998848682236577937",
    tickIdx: -204980,
  },
  {
    liquidityNet: -63420656769532,
    price0: "0.000000001255194597871215602926266315788927",
    price1: "796689215.9159859133865439720261583",
    tickIdx: -204970,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001256450357457305574570669059367288",
    price1: "795892964.7039240010970992983698789",
    tickIdx: -204960,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001257707373368224167451650328038724",
    price1: "795097509.3053111024365212334085157",
    tickIdx: -204950,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001260225179193365975318353898467768",
    price1: "793508982.7677235780917881927659017",
    tickIdx: -204930,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001261485971625143467716360237327356",
    price1: "792715910.0403811205629538299797958",
    tickIdx: -204920,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001262748025416860653478267989753377",
    price1: "791923629.949750424764485343947685",
    tickIdx: -204910,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001264011341830445235659987571744212",
    price1: "791132141.7036305548027023931836008",
    tickIdx: -204900,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001265275922129087413039409512902723",
    price1: "790341444.5106123401835311544044654",
    tickIdx: -204890,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001266541767577241143180401037443137",
    price1: "789551537.58007758448240155400294",
    tickIdx: -204880,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001267808879440625406760435172171612",
    price1: "788762420.1221982748050395447683315",
    tickIdx: -204870,
  },
  {
    liquidityNet: 1701458405486823,
    price0: "0.000000001269077258986225473163116582753741",
    price1: "787974091.3479357920383639676286559",
    tickIdx: -204860,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001270346907482294167336869604352641",
    price1: "787186550.4690401218906983282125796",
    tickIdx: -204850,
  },
  {
    liquidityNet: -1701458405486823,
    price0: "0.000000001271617826198353137921055198756941",
    price1: "786399796.6980490667205086072660366",
    tickIdx: -204840,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001272890016405194126640784837420298",
    price1: "785613829.2482874581528790124055628",
    tickIdx: -204830,
  },
  {
    liquidityNet: 11072955256412,
    price0: "0.000000001274163479374880238971700578404236",
    price1: "784828647.3338663704829383663496353",
    tickIdx: -204820,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001275438216380747216075991875055425",
    price1: "784044250.1696823348654506136413126",
    tickIdx: -204810,
  },
  {
    liquidityNet: -11072955256412,
    price0: "0.000000001276714228697404708010920925358236",
    price1: "783260636.9714165542897837139610525",
    tickIdx: -204800,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001277991517600737548211129643284821",
    price1: "782477806.9555341193394719754284999",
    tickIdx: -204790,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001279270084367907029246002607119322",
    price1: "781695759.339283224735587665807097",
    tickIdx: -204780,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001280549930277352179853361614661408",
    price1: "780914493.3406943866631385232563323",
    tickIdx: -204770,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001281831056608791043250768751418438",
    price1: "780134008.1785796608797085712241099",
    tickIdx: -204760,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001283113464643221956725716155376386",
    price1: "779354303.0725318616055604242368932",
    tickIdx: -204750,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001284397155662924832505981940698643",
    price1: "778575377.2429237811944180527286616",
    tickIdx: -204740,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001285682130951462439911433022740019",
    price1: "777797229.9109074105841497556522144",
    tickIdx: -204730,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001286968391793681688788556868082179",
    price1: "777019860.2984131605265718694386229",
    tickIdx: -204720,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001288255939475714914229005475897497",
    price1: "776243267.6281490835955945209135423",
    tickIdx: -204710,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001289544775284981162573436180832281",
    price1: "775467451.1236000969729315100433644",
    tickIdx: -204700,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001290834900510187478701935152768701",
    price1: "774692410.0090272060105971858706542",
    tickIdx: -204690,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001292126316441330194612310755278953",
    price1: "773918143.5094667285694139557076775",
    tickIdx: -204680,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001293419024369696219287545212326337",
    price1: "773144650.8507295201327548435899544",
    tickIdx: -204670,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001294713025587864329853694321797482",
    price1: "772371931.2594001996947462891493479",
    tickIdx: -204660,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001296008321389706464029526244769025",
    price1: "771599983.9628363764221571524490896",
    tickIdx: -204650,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001297304913070389013869191691022127",
    price1: "770828808.1891678770892006639320206",
    tickIdx: -204640,
  },
  {
    liquidityNet: 478787210607703,
    price0: "0.000000001298602801926374120799219114220372",
    price1: "770058403.1672959742844768314690819",
    tickIdx: -204630,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001299901989255420971951129824362323",
    price1: "769288768.1268926153892835885583836",
    tickIdx: -204620,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001301202476356587097790969220610466",
    price1: "768519902.2983996523265257390168678",
    tickIdx: -204610,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001302504264530229671047051644384845",
    price1: "767751804.9130280720794515240273773",
    tickIdx: -204600,
  },
  {
    liquidityNet: -169283546025066,
    price0: "0.000000001303807355078006806937217650693576",
    price1: "766984475.2027572279794474071546682",
    tickIdx: -204590,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001305111749302878864696903795055042",
    price1: "766217912.4003340717621224419252771",
    tickIdx: -204580,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001307724454002268221139081539518647",
    price1: "764687084.4538520196474502436445804",
    tickIdx: -204560,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000130903276708922919037046662972853",
    price1: "763922817.7791181184878955590709436",
    tickIdx: -204550,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001310342389078175034751826625380625",
    price1: "763159314.9508803641645258007555733",
    tickIdx: -204540,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001311653321278596902147233744277342",
    price1: "762396575.205712208111756127459785",
    tickIdx: -204530,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000131296556500129602099680725562938",
    price1: "761634597.7809501085958648426806548",
    tickIdx: -204520,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001315593992263289195032043278796566",
    price1: "760112926.8458003716372389914267234",
    tickIdx: -204500,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001316910178430747912568218470001363",
    price1: "759353231.8138938254103422462446193",
    tickIdx: -204490,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001318227681376815834161674355970496",
    price1: "758594296.0593539967851019250003905",
    tickIdx: -204480,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001319546502418864277431698817013835",
    price1: "757836118.8233209546088635090955096",
    tickIdx: -204470,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001320866642875582524290404176233145",
    price1: "757078699.3476932104540579420471465",
    tickIdx: -204460,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001322188104066979139500262139131582",
    price1: "756322036.8751269605924532396370164",
    tickIdx: -204450,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000132351088731438329055078977730813",
    price1: "755566130.6490353287270151009999375",
    tickIdx: -204440,
  },
  {
    liquidityNet: 13815908924400,
    price0: "0.000000001324834993940446068855706301057343",
    price1: "754810979.9135876094806193281695025",
    tickIdx: -204430,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001326160425269141812271881686033634",
    price1: "754056583.9137085126408596183744456",
    tickIdx: -204420,
  },
  {
    liquidityNet: -13815908924400,
    price0: "0.000000001327487182625769428941399540799175",
    price1: "753302941.8950774081601950493991126",
    tickIdx: -204410,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001328815267336953722458057925056477",
    price1: "752550053.1041275719106823335856922",
    tickIdx: -204400,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001330144680730646718359633152672384",
    price1: "751797916.7880454321925386705652311",
    tickIdx: -204390,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001331475424136128991947232939230735",
    price1: "751046532.194769816995781782559741",
    tickIdx: -204380,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001332807498884010997433066580807705",
    price1: "750295898.5729912020141944690996581",
    tickIdx: -204370,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001334140906306234398417961178948151",
    price1: "749546015.1721509594108617702502643",
    tickIdx: -204360,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001335475647736073399699954256434478",
    price1: "748796881.2424406073345295789382072",
    tickIdx: -204350,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001336811724508136080415294439382955",
    price1: "748048496.0348010601860342937156864",
    tickIdx: -204340,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001338149137958365728513183213477351",
    price1: "747300858.8009218796340538532959801",
    tickIdx: -204330,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000013394878894240421765655920957576",
    price1: "746553968.793240526379431243440498",
    tickIdx: -204320,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001340827980243783138913490898323203",
    price1: "745807825.2649416126673223152752478",
    tickIdx: -204310,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000134216941175754555015082409658869",
    price1: "745062427.4699561555464205018641785",
    tickIdx: -204300,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001343512185306626904947573652342895",
    price1: "744317774.6629608308745117668691357",
    tickIdx: -204290,
  },
  {
    liquidityNet: 403139175070980,
    price0: "0.000000001344856302233666599213247980816459",
    price1: "743573866.0993772280696138653818389",
    tickIdx: -204280,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000134620176388264727260213809125423",
    price1: "742830701.035371105605954742523094",
    tickIdx: -204270,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000134754857159889615236168327212232",
    price1: "742088278.7278516472540456401691966",
    tickIdx: -204260,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001348896726729086398525290035054959",
    price1: "741346598.4344707190641052261858362",
    tickIdx: -204250,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001350246230621238450450949375965212",
    price1: "740605659.4136221270920918038265722",
    tickIdx: -204240,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001351597084624721374706998757407484",
    price1: "739865460.9244408758676014014868454",
    tickIdx: -204230,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001352949290090254214306376563289883",
    price1: "739126002.2268024276028902847962403",
    tickIdx: -204220,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001354302848369907339290718125392252",
    price1: "738387282.581321962142281174082098",
    tickIdx: -204210,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000135565776081710379866564377085238",
    price1: "737649301.2493536376512131905473214",
    tickIdx: -204200,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001357014028786620673688590690839955",
    price1: "736912057.4929898520441962940750332",
    tickIdx: -204190,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001358371653634590432510541783046492",
    price1: "736175550.5750605051509317144034275",
    tickIdx: -204180,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001359730636718502286173005974381191",
    price1: "735439779.7591322616198606155063957",
    tickIdx: -204170,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001361090979397203545961605885378747",
    price1: "734704744.3095078145584039703700726",
    tickIdx: -204160,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001362452683030900982117630054296963",
    price1: "733970443.4912251499091573599730443",
    tickIdx: -204150,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001363815748981162183908908296710876",
    price1: "733236876.5700568115613051461593748",
    tickIdx: -204140,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000136518017861091692106137013559747",
    price1: "732504042.8125091671965192032395079",
    tickIdx: -204130,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000136654597328445850655264759745213",
    price1: "731771941.4858216748686081275653051",
    tickIdx: -204120,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001367913134367445160769085031886348",
    price1: "731040571.8579661503161835780026131",
    tickIdx: -204110,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001370651561231219288463200444871619",
    price1: "729580024.7742956649175137835132012",
    tickIdx: -204090,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001372022829750160036285205411556299",
    price1: "728850845.8580795400340969078008309",
    tickIdx: -204080,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001373395470154855139400736575254819",
    price1: "728122395.7198915945965643143024885",
    tickIdx: -204070,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000137476948381780786540965092516788",
    price1: "727394673.631354468061895657019627",
    tickIdx: -204060,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001376144872112894602970604945560626",
    price1: "726667678.8648187768002562665551642",
    tickIdx: -204050,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001377521636415366235540182695083543",
    price1: "725941410.69336238651831116380388",
    tickIdx: -204040,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001378899778101849516486381361657857",
    price1: "725215868.3907896854097157524666964",
    tickIdx: -204030,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001380279298550348445577829269026819",
    price1: "724491051.2316308580320564134972609",
    tickIdx: -204020,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001381660199140245646850112686669261",
    price1: "723766958.4911411599095149509665779",
    tickIdx: -204010,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001383042481252303747850589171743019",
    price1: "723043589.4453001928605315644827889",
    tickIdx: -204000,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001384426146268666760263066550074665",
    price1: "722320943.3708111810497417482292737",
    tickIdx: -203990,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001385811195572861461913728022939714",
    price1: "721599019.5451002477634632408858409",
    tickIdx: -203980,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001387197630549798780159685267485651",
    price1: "720877817.2463156929080098741755141",
    tickIdx: -203970,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001388585452585775176661542781139957",
    price1: "720157335.7533272712301098905341151",
    tickIdx: -203960,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000138997466306847403354135810421829",
    price1: "719437574.3457254712587070224321663",
    tickIdx: -203950,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001392757254931715585886979579825044",
    price1: "718000208.9086430381569636517113594",
    tickIdx: -203930,
  },
  {
    liquidityNet: -12644086246451,
    price0: "0.000000001394150639094572142749080423044594",
    price1: "717282603.4419405715567417614491787",
    tickIdx: -203920,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001395545417268781664817615782581683",
    price1: "716565715.1861796226450100070296963",
    tickIdx: -203910,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001396941590848982977477266543286571",
    price1: "715849543.4245435581867737204394624",
    tickIdx: -203900,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001398339161231210172692955643977044",
    price1: "715134087.4409321674887733703554778",
    tickIdx: -203890,
  },
  {
    liquidityNet: 226977189079067,
    price0: "0.000000001399738129812894004904465741895049",
    price1: "714419346.5199609463708176632135197",
    tickIdx: -203880,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001401138497992863288317579814942408",
    price1: "713705319.9469603818527516528837289",
    tickIdx: -203870,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001402540267171346295593141853236318",
    price1: "712992007.0079752375563446173867201",
    tickIdx: -203860,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001403943438749972157935436189305786",
    price1: "712279406.9897638398213831759318106",
    tickIdx: -203850,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001405348014131772266581285415428966",
    price1: "711567519.1797973645352558336928593",
    tickIdx: -203840,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001406753994721181675691268237190027",
    price1: "710856342.86625912467531585515734",
    tickIdx: -203830,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001408161381924040506644460014314015",
    price1: "710145877.3380438585633100795914045",
    tickIdx: -203820,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001409570177147595353738100143220666",
    price1: "709436121.8847570188311620041584733",
    tickIdx: -203810,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000141098038180050069129359184052457",
    price1: "708727075.7967140620973981715121113",
    tickIdx: -203800,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000141239199729282028217024129290101",
    price1: "708018738.3649397393535076092562355",
    tickIdx: -203790,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001413805025036028587688144546335526",
    price1: "707311108.8811673870595247785278274",
    tickIdx: -203780,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001415219466443012178961631916782172",
    price1: "706604186.6378382189481271981099908",
    tickIdx: -203770,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001416635322928071149644681114672084",
    price1: "705897970.9281006185365396189270598",
    tickIdx: -203760,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001419471286796691702921174798289125",
    price1: "704487656.285525263824841896043391",
    tickIdx: -203740,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000142089139701593382002535377122456",
    price1: "703783555.94251376798420329094613",
    tickIdx: -203730,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001422312927984615220957792254143107",
    price1: "703080159.3127449467294631815159144",
    tickIdx: -203720,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001423735881124124852769768262142842",
    price1: "702377465.6928924449028947151257464",
    tickIdx: -203710,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001425160257857273691255233787808285",
    price1: "701675474.380332847027114953628867",
    tickIdx: -203700,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001426586059608296163619641082706487",
    price1: "700974184.67314497475186675714987",
    tickIdx: -203690,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001428013287802851572572078136886572",
    price1: "700273595.870109185002967636233553",
    tickIdx: -203680,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001429441943868025521842137306240721",
    price1: "699573707.2707066688327237914205466",
    tickIdx: -203670,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001430872029232331343122942462175347",
    price1: "698874518.1751187509711082607138877",
    tickIdx: -203660,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001432303545325711524441761464057312",
    price1: "698176027.88422619007700279609477",
    tickIdx: -203650,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001433736493579539139959632182342651",
    price1: "697478235.6996084796888037902395322",
    tickIdx: -203640,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001435170875426619281201431729166144",
    price1: "696781140.9235431498736932738840744",
    tickIdx: -203630,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001436606692301190489717819983470282",
    price1: "696084742.8590050695748767028772113",
    tickIdx: -203620,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001438043945638926191180489929483477",
    price1: "695389040.8096657496560899518614889",
    tickIdx: -203610,
  },
  {
    liquidityNet: 35858890972279,
    price0: "0.000000001439482636876936130912158760520955",
    price1: "694694034.0798926466426786287195582",
    tickIdx: -203600,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000144092276745376781085273513467919",
    price1: "693999721.9747484671585535204269201",
    tickIdx: -203590,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001442364338809407927963099405027417",
    price1: "693306103.7999904730583266767584648",
    tickIdx: -203580,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001443807352385283814067935084369115",
    price1: "692613178.8620697872539333334074062",
    tickIdx: -203570,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001445251809624264877139051243553774",
    price1: "691920946.4681307002350455704916869",
    tickIdx: -203560,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001446697711970664044020636982666334",
    price1: "691229405.9260099772825842961453218",
    tickIdx: -203550,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001448145060870239204597890556209653",
    price1: "690538556.5442361663746368379212509",
    tickIdx: -203540,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001449593857770194657410467176625835",
    price1: "689848397.6320289067840881170686894",
    tickIdx: -203530,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001451044104119182556712190965176553",
    price1: "689158928.499298238367274072392452",
    tickIdx: -203520,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001452495801367304360978477965322205",
    price1: "688470148.4566439115429666913549175",
    tickIdx: -203510,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001453948950966112282862918581306107",
    price1: "687782056.8153546979610006963439636",
    tickIdx: -203500,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001455403554368610740604469253664612",
    price1: "687094652.8874077018598526236029401",
    tickIdx: -203490,
  },
  {
    liquidityNet: 1464394318474518,
    price0: "0.000000001456859613029257810886704633848344",
    price1: "686407935.9854676721124837212023112",
    tickIdx: -203480,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001458317128403966683150582972055147",
    price1: "685721905.4228863149597587806276635",
    tickIdx: -203470,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001459776101950107115362178885743379",
    price1: "685036560.513701607430753704066001",
    tickIdx: -203460,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001461236535126506891236839131116154",
    price1: "684351900.5726371114492652962923768",
    tickIdx: -203450,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001462698429393453278921218456144686",
    price1: "683667924.915101288625837456192557",
    tickIdx: -203440,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001464161786212694491134654071433253",
    price1: "682984632.8571868157346186284053508",
    tickIdx: -203430,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001465626607047441146771338734421186",
    price1: "682302023.7156699008743660603310505",
    tickIdx: -203420,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000146709289336236773396475390306989",
    price1: "681620096.8080096003129130938309099",
    tickIdx: -203410,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001468560646623614074615825877296948",
    price1: "680938851.4523471360144164043373118",
    tickIdx: -203400,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001470029868298786790386269309996042",
    price1: "680258286.9675052138487007828060371",
    tickIdx: -203390,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001471500559856960770158583934522484",
    price1: "679578402.6729873424820197379714127",
    tickIdx: -203380,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001472972722768680638964171822030761",
    price1: "678899197.8889771529485508777128916",
    tickIdx: -203370,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001474446358505962228381043950024422",
    price1: "678220671.9363377189019457090083531",
    tickIdx: -203360,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001475921468542294048402586332921077",
    price1: "677542824.1366108775462541759359118",
    tickIdx: -203350,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001477398054352638760778857436347871",
    price1: "676865653.8120165512455449344928823",
    tickIdx: -203340,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000147887611741343465383189006926701",
    price1: "676189160.2854520698115430416284604",
    tickIdx: -203330,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001480355659202597118746472421888056",
    price1: "675513342.8804914934686074138363847",
    tickIdx: -203320,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001483319184885077711298066824407918",
    price1: "674163733.7330578915423679931846664",
    tickIdx: -203300,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001484803171741625442921702748089821",
    price1: "673489940.6411105546249706200658626",
    tickIdx: -203290,
  },
  {
    liquidityNet: 153631444170281,
    price0: "0.000000001486288643253001917313691212162322",
    price1: "672816820.9718171507909636457758756",
    tickIdx: -203280,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001487775600904530236079495757102216",
    price1: "672144374.0521252604620792517066453",
    tickIdx: -203270,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001489264046183019492499851077106489",
    price1: "671472599.2096551464488205415517775",
    tickIdx: -203260,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001490753980576766258191312895328651",
    price1: "670801495.7726990816379001421660514",
    tickIdx: -203250,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001492245405575556071254137564668253",
    price1: "670131063.0702206773516217417759973",
    tickIdx: -203240,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001493738322670664925908979393316007",
    price1: "669461300.4318542123785329920250417",
    tickIdx: -203230,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000149523273335486076362389518292594",
    price1: "668792207.187903962674678871540996",
    tickIdx: -203220,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001496728639122404965733146957444321",
    price1: "668123782.6693435317347852792471403",
    tickIdx: -203210,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001498226041469053847549295352274248",
    price1: "667456026.2078151816327032955014465",
    tickIdx: -203200,
  },
  {
    liquidityNet: 281876761252848,
    price0: "0.000000001499724941892060153970077626596255",
    price1: "666788937.1356291647304452183422922",
    tickIdx: -203190,
  },
  {
    liquidityNet: 7190253138683922,
    price0: "0.000000001501225341890174556581565756300589",
    price1: "666122514.7857630560551441506439881",
    tickIdx: -203180,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001502727242963647152259101561117373",
    price1: "665456758.4918610863432695818422744",
    tickIdx: -203170,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001504230646614228963267507317158299",
    price1: "664791667.5882334757514320750792576",
    tickIdx: -203160,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000150573555434517343886207180520917",
    price1: "664127241.4098557682331108371397766",
    tickIdx: -203150,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000150724196766123795839181324573811",
    price1: "663463479.2923681665806386144075383",
    tickIdx: -203140,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001508749888068685335906522073711063",
    price1: "662800380.5720748681317790232602217",
    tickIdx: -203130,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001510259317075285326269088009935804",
    price1: "662137944.5859434011402320878487874",
    tickIdx: -203120,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001511770256190316132774617390789582",
    price1: "661476170.6716039618094044220681084",
    tickIdx: -203110,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000151328270692456591627784822482526",
    price1: "660815058.1673487519887811557244063",
    tickIdx: -203100,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001514796670790334305830371952897804",
    price1: "660154606.4121313175322373674405438",
    tickIdx: -203090,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001516312149301433910829172398108897",
    price1: "659494814.7455658873176274487135726",
    tickIdx: -203080,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001517829143973191834677993903033609",
    price1: "658835682.5079267129269914847508161",
    tickIdx: -203070,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001519347656322451189963052164371097",
    price1: "658177209.0401474089867183982617876",
    tickIdx: -203060,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001520867687867572615144602789352758",
    price1: "657519393.6838202941670062622740478",
    tickIdx: -203050,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001522389240128435792765884113947468",
    price1: "656862235.7811957328399608472724395",
    tickIdx: -203040,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001523912314626440969180952340126263",
    price1: "656205734.6751814773956741265335392",
    tickIdx: -203030,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001525436912884510475802928568189346",
    price1: "655549889.709342011215625121441408",
    tickIdx: -203020,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001526963036427090251874178820418335",
    price1: "654894700.2278978923027461258273748",
    tickIdx: -203010,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001528490686780151368759949674097575",
    price1: "654240165.5757250975674980049763726",
    tickIdx: -203000,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001530019865471191555766983645251745",
    price1: "653586285.0983543677692989208858802",
    tickIdx: -202990,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001531550574029236727488639989274424",
    price1: "652933058.1419705531126514906514586",
    tickIdx: -202980,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001533082813984842512678048110975127",
    price1: "652280484.0534119594973140394859189",
    tickIdx: -202970,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001534616586870095784650822304452308",
    price1: "651628562.1801696954218622638578933",
    tickIdx: -202960,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001536151894218616193218868072608347",
    price1: "650977291.8703870195399882735607122",
    tickIdx: -202950,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001537688737565557698156811807061122",
    price1: "650326672.4728586888688846341946641",
    tickIdx: -202940,
  },
  {
    liquidityNet: -20409464402966,
    price0: "0.000000001539227118447610104202587141677026",
    price1: "649676703.3370303076490616835655629",
    tickIdx: -202930,
  },
  {
    liquidityNet: 6281450907694,
    price0: "0.000000001540767038403000597593712826953685",
    price1: "649027383.8129976768549470468707379",
    tickIdx: -202920,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000154230849897149528414079850801873",
    price1: "648378713.2515061443556169262607402",
    tickIdx: -202910,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001543851501694400728839816326085288",
    price1: "647730691.0039499557250093904318869",
    tickIdx: -202900,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001545396048114565497024677801816962",
    price1: "647083316.42237160570097053932187",
    tickIdx: -202890,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001546942139776381697061656999206446",
    price1: "646436588.8594611902924850677487137",
    tickIdx: -202880,
  },
  {
    liquidityNet: 3936266090360098,
    price0: "0.000000001548489778225786524587202510264175",
    price1: "645790507.6685557595344433999530001",
    tickIdx: -202870,
  },
  {
    liquidityNet: -3936266090360098,
    price0: "0.000000001550038965010263808290682344048033",
    price1: "645145072.2036386708892982144751605",
    tickIdx: -202860,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001551589701678845557243607348343674",
    price1: "644500281.8193389432949638256244001",
    tickIdx: -202850,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001553141989782113509776880338629078",
    price1: "643856135.8709306118583125339740976",
    tickIdx: -202840,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001554695830872200683907619656827973",
    price1: "643212633.7143320831936227038510103",
    tickIdx: -202830,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001556251226502792929317107431776401",
    price1: "642569774.7061054914053339706728906",
    tickIdx: -202820,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001557808178229130480881414364296409",
    price1: "641927558.203456054714465625231891",
    tickIdx: -202810,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001559366687608009513756254412292274",
    price1: "641285983.5642314327280548656199954",
    tickIdx: -202800,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001560926756197783700017624305359274",
    price1: "640645050.1469210843509722504483457",
    tickIdx: -202790,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001562488385558365766859784374024426",
    price1: "640004757.3106556263394723293253499",
    tickIdx: -202780,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000156405157725122905635213873592431",
    price1: "639365104.4152061924958380682295307",
    tickIdx: -202770,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001565616332839409086756574439968748",
    price1: "638726090.8209837935034783284428072",
    tickIdx: -202760,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001567182653887505115406820729842134",
    price1: "638087715.8890386774018382980989738",
    tickIdx: -202750,
  },
  {
    liquidityNet: 6373661281386,
    price0: "0.000000001568750541961681703151391150058273",
    price1: "637449978.98105969070048341515118",
    tickIdx: -202740,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001570319998629670280361672781211244",
    price1: "636812879.4593736401317179596718283",
    tickIdx: -202730,
  },
  {
    liquidityNet: -6373661281386,
    price0: "0.000000001571891025460770714506728456055523",
    price1: "636176416.6869446550411001318692039",
    tickIdx: -202720,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001573463624025852879296379374605085",
    price1: "635540590.0273735504152160700378894",
    tickIdx: -202710,
  },
  {
    liquidityNet: 902736185609084,
    price0: "0.000000001575037795897358225394137104564905",
    price1: "634905398.8448971905460758998553",
    tickIdx: -202700,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000157661354264930135270155552310084",
    price1: "634270842.5043878533314955429950924",
    tickIdx: -202690,
  },
  {
    liquidityNet: 130109624307177,
    price0: "0.000000001578190865857271584215574827216788",
    price1: "633636920.3713525952108286489504361",
    tickIdx: -202680,
  },
  {
    liquidityNet: -902736185609084,
    price0: "0.000000001579769767098434541460431312842969",
    price1: "633003631.8119326167354136492467672",
    tickIdx: -202670,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001581350247951533721495708197147619",
    price1: "632370976.1929026287731015678753584",
    tickIdx: -202660,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001582932309996892075502104334567941",
    price1: "631738952.8816702193462308557964571",
    tickIdx: -202650,
  },
  {
    liquidityNet: -130109624307177,
    price0: "0.000000001584515954816413588946499254616466",
    price1: "631107561.2462752211024161507444613",
    tickIdx: -202640,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001586101183993584863327894528657473",
    price1: "630476800.6553890794175184963183262",
    tickIdx: -202630,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001587687999113476699505813053566546",
    price1: "629846670.4783142211301651864586744",
    tickIdx: -202620,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000158927640176274568261273942248612",
    price1: "629217170.0849834239071880328996298",
    tickIdx: -202610,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001590866393529635768552186136772731",
    price1: "628588298.8459591862393494840387731",
    tickIdx: -202600,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001592457976003979872083971998699103",
    price1: "627960056.1324330980667266538934963",
    tickIdx: -202590,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001594051150777201456498300611527795",
    price1: "627332441.3162252120331239494070487",
    tickIdx: -202580,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001595645919442316124880228502214522",
    price1: "626705453.7697834153688856133333072",
    tickIdx: -202570,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001597242283593933212966113972230001",
    price1: "626079092.8661828024014801282664416",
    tickIdx: -202560,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001598840244828257383593639373810817",
    price1: "625453357.9791250476932290550907945",
    tickIdx: -202550,
  },
  {
    liquidityNet: 2835336969687083,
    price0: "0.000000001600439804743090222747001102364064",
    price1: "624828248.4829377798055535062080612",
    tickIdx: -202540,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001602040964937831837198863190758823",
    price1: "624203763.7525739556891120803539079",
    tickIdx: -202530,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001603643727013482453750671987841648",
    price1: "623579903.1636112356992047116450842",
    tickIdx: -202520,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001605248092572644020072931001714589",
    price1: "622956666.0922513592358175107015475",
    tickIdx: -202510,
  },
  {
    liquidityNet: 30344669473029,
    price0: "0.00000000160685406321952180714703658811463",
    price1: "622334051.9153195210076843002666864",
    tickIdx: -202500,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001608461640559926013310276765634247",
    price1: "621712060.0102637479197411717030894",
    tickIdx: -202490,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001610070826201273369905597042525733",
    price1: "621090689.7551542765833510120720465",
    tickIdx: -202480,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001611681621752588748537738744438654",
    price1: "620469940.528682931448675574212713",
    tickIdx: -202470,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001613294028824506769937356938651772",
    price1: "619849811.710162503558573284322264",
    tickIdx: -202460,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001614908049029273414434726658179762",
    price1: "619230302.6795261299234016030019933",
    tickIdx: -202450,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001616523683980747634044647738562497",
    price1: "618611412.8173266735161033765768505",
    tickIdx: -202440,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001618140935294402966164160191182346",
    price1: "617993141.5047361038869572357179133",
    tickIdx: -202430,
  },
  {
    liquidityNet: -6687938987506,
    price0: "0.000000001619759804587329148884683649604329",
    price1: "617375488.1235448783973727179994372",
    tickIdx: -202420,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001621380293478233737920196039696743",
    price1: "616758452.0561613240721114100050018",
    tickIdx: -202410,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001623002403587443725153068240167651",
    price1: "616142032.6856110200693160229615048",
    tickIdx: -202400,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001624626136536907158799173117646994",
    price1: "615526229.395536180767729933625969",
    tickIdx: -202390,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001626251493950194765193888939556703",
    price1: "614911041.5701950394704903392789192",
    tickIdx: -202380,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001627878477452501572200618788743588",
    price1: "614296468.5944612327248787921901188",
    tickIdx: -202370,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000162950708867064853424344922620372",
    price1: "613682509.853823185257413494818278",
    tickIdx: -202360,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001631137329233084158965573072203983",
    price1: "613069164.7343834955236683522866397",
    tickIdx: -202350,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001632769200769886135515102801708169",
    price1: "612456432.6228583218722043933416908",
    tickIdx: -202340,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001634402704912762964459902677243036",
    price1: "611844312.9065767693219997850532476",
    tickIdx: -202330,
  },
  {
    liquidityNet: 2897309020690023,
    price0: "0.000000001636037843295055589333069371195688",
    price1: "611232804.9734802769527652799514889",
    tickIdx: -202320,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001637674617551739029810692460019267",
    price1: "610621908.2121220059075325471206845",
    tickIdx: -202310,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001639313029319424016523527804940703",
    price1: "610011622.0116662280069034509810986",
    tickIdx: -202300,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001640953080236358627504218467513925",
    price1: "609401945.7618877149743489530903787",
    tickIdx: -202290,
  },
  {
    liquidityNet: -2897309020690023,
    price0: "0.000000001642594771942429926271699443746082",
    price1: "608792878.8531711282719469232843126",
    tickIdx: -202280,
  },
  {
    liquidityNet: 2976477899922395,
    price0: "0.000000001644238106079165601554424137544542",
    price1: "608184420.6765104095459487568547676",
    tickIdx: -202270,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001645883084289735608654052132890483",
    price1: "607576570.6235081716815653042304933",
    tickIdx: -202260,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001647529708218953812451239464442274",
    price1: "606969328.0863750904663632287849234",
    tickIdx: -202250,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001649177979513279632055174228210273",
    price1: "606362692.4579292968616635169447392",
    tickIdx: -202240,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000165082789982081968709850201752582",
    price1: "605756663.1315957698813344727143531",
    tickIdx: -202230,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001652479470791329445679287314752608",
    price1: "605151239.5014057300773721360652856",
    tickIdx: -202220,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000165413269407621487395165861606004",
    price1: "604546420.9619960336316616713662004",
    tickIdx: -202210,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001655787571328534087366786715097184",
    price1: "603942206.9086085670533138781497768",
    tickIdx: -202200,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001657444104202999003565847221574232",
    price1: "603338596.737089642480971582027214",
    tickIdx: -202190,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001659102294355976996926620042577548",
    price1: "602735589.8438893935894812684706025",
    tickIdx: -202180,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001660762143445492554765380207916125",
    price1: "602133185.6260611721003259264882695",
    tickIdx: -202170,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001662423653131228935195736075923289",
    price1: "601531383.4812609448952156729190727",
    tickIdx: -202160,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000166408682507452982664607261291926",
    price1: "600930182.8077466917322333311691663",
    tickIdx: -202150,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001665751660938401009037259097979615",
    price1: "600329583.0043778035639327407094886",
    tickIdx: -202140,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001667418162387512016622282264753187",
    price1: "599729583.4706144814567881755448373",
    tickIdx: -202130,
  },
  {
    liquidityNet: -30344669473029,
    price0: "0.000000001669086331088197802489467553832382",
    price1: "599130183.60651713611139385115641",
    tickIdx: -202120,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001670756168708460404730952812600776",
    price1: "598531382.8127457879828131001097639",
    tickIdx: -202110,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001672427676917970614278080444568931",
    price1: "597933180.4905594680004773966098602",
    tickIdx: -202100,
  },
  {
    liquidityNet: 116649049881701,
    price0: "0.000000001674100857388069644405375676961293",
    price1: "597335576.0418156188870360097748002",
    tickIdx: -202090,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001675775711791770801904780283736437",
    price1: "596738568.8689694970755576642906582",
    tickIdx: -202080,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001677452241803761159931812771311516",
    price1: "596142158.3750735752244861854020363",
    tickIdx: -202070,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000167913044910040323252532770602119",
    price1: "595546343.9637769453297527028872359",
    tickIdx: -202060,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001680810335359736650802548535773249",
    price1: "594951125.039324722433447585763836",
    tickIdx: -202050,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001682491902261479840831049933469278",
    price1: "594356501.0065574489284558759705975",
    tickIdx: -202040,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001684175151487031703179367366540665",
    price1: "593762471.2709104994584605851755765",
    tickIdx: -202030,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001685860084719473294147913275409803",
    price1: "593169035.2384134864127188141687032",
    tickIdx: -202020,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001687546703643569508681880923825057",
    price1: "592576192.3156896660150162490104871",
    tickIdx: -202010,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001689235009945770764967818665837685",
    price1: "591983941.9099553450062061822275258",
    tickIdx: -202000,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001690925005314214690715559057691137",
    price1: "591392283.4290192879197398008707071",
    tickIdx: -201990,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001692616691438727811127188928079574",
    price1: "590801216.281282124949595076184033",
    tickIdx: -201980,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001694310070010827238554748207104926",
    price1: "590210739.8757357604100121819713871",
    tickIdx: -201970,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001696005142723722363848347002821773",
    price1: "589620853.6219627817864439604959959",
    tickIdx: -201960,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001697701911272316549396392104508766",
    price1: "589031556.9301358693771305459032928",
    tickIdx: -201950,
  },
  {
    liquidityNet: 4595266845518518,
    price0: "0.000000001699400377353208823859615783745618",
    price1: "588442849.2110172065247078457230606",
    tickIdx: -201940,
  },
  {
    liquidityNet: 25342363186198,
    price0: "0.000000001701100542664695578600601458007821",
    price1: "587854729.8759578904372601709816224",
    tickIdx: -201930,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001702802408906772265810502476818673",
    price1: "587267198.3368973435982278948401237",
    tickIdx: -201920,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000170450597778113509833465198752178",
    price1: "586680254.0063627257645816084711356",
    tickIdx: -201910,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001706211250991182751198763536458553",
    price1: "586093896.2974683465526748310935361",
    tickIdx: -201900,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001707918230242018064837423761756021",
    price1: "585508124.623915078611187918705476",
    tickIdx: -201890,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001709626917240449750026580236052346",
    price1: "584922938.3999897713805764030877619",
    tickIdx: -201880,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001711337313694994094521729221312291",
    price1: "584338337.0405646654384375790958443",
    tickIdx: -201870,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001713049421315876671403509803414424",
    price1: "583754319.9610968074302097441182897",
    tickIdx: -201860,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001714763241815034049132412581427621",
    price1: "583170886.577627465584619078853803",
    tickIdx: -201850,
  },
  {
    liquidityNet: 398655482,
    price0: "0.000000001716478776906115503314312795438229",
    price1: "582588036.3067815458132897432480811",
    tickIdx: -201840,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001718196028304484730178539487442791",
    price1: "582005768.565767008393933345536628",
    tickIdx: -201830,
  },
  {
    liquidityNet: -398655482,
    price0: "0.000000001719914997727221561770194002186133",
    price1: "581424082.7723742852365345258607404",
    tickIdx: -201820,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001721635686893123682858432848902716",
    price1: "580842978.3449756977319499788617333",
    tickIdx: -201810,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001723358097522708349562431660712075",
    price1: "580262454.7025248751823388220137294",
    tickIdx: -201800,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001725082231338214109696748705928644",
    price1: "579682511.2645561738128427982285525",
    tickIdx: -201790,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001726808090063602524837808124774048",
    price1: "579103147.4511840963639353824580292",
    tickIdx: -201780,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001728535675424559894113224785927703",
    price1: "578524362.6831027122638594426298944",
    tickIdx: -201770,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001730264989148498979715694380021083",
    price1: "577946156.3815850783805736852840888",
    tickIdx: -201760,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000173199603296456073414317409157393",
    price1: "577368527.9684826603526286957271338",
    tickIdx: -201750,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001733728808603616029167080916988791",
    price1: "576791476.8662247544983939613940196",
    tickIdx: -201740,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000017354633177982673865302364240653",
    price1: "576215002.4978179103030578454002406",
    tickIdx: -201730,
  },
  {
    liquidityNet: 16685285763763,
    price0: "0.000000001737199562282850710376288478069214",
    price1: "575639104.2868453534828230549818432",
    tickIdx: -201720,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001738937543793437021412342190695211",
    price1: "575063781.6574664096257207266591807",
    tickIdx: -201710,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001740677264067834192806534081298513",
    price1: "574489034.034415928408466826521073",
    tickIdx: -201700,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001742418724845588687822285174540289",
    price1: "573914860.8430037083887851400108355",
    tickIdx: -201690,
  },
  {
    liquidityNet: -16685285763763,
    price0: "0.000000001744161927867987299190970495097186",
    price1: "573341261.5091139223726217010047438",
    tickIdx: -201680,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001745906874878058890224744158328114",
    price1: "572768235.459204543355676084807488",
    tickIdx: -201670,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001747653567620576137671260995773078",
    price1: "572195782.1203067710386755639486776",
    tickIdx: -201660,
  },
  {
    liquidityNet: 18808106518626,
    price0: "0.000000001749402007842057276312037396081423",
    price1: "571623900.9200244589158186993499785",
    tickIdx: -201650,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001751152197290767845306195785431822",
    price1: "571052591.286533541935815512544652",
    tickIdx: -201640,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001752904137716722436281338916715633",
    price1: "570481852.6485814647349519571706264",
    tickIdx: -201630,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001754657830871686443173301883710483",
    price1: "569911684.4354866104416069799253936",
    tickIdx: -201620,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001756413278509177813816531525173961",
    price1: "569342086.0771377300516510325665086",
    tickIdx: -201610,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001758170482384468803286844634239761",
    price1: "568773057.0039933723741554673658968",
    tickIdx: -201600,
  },
  {
    liquidityNet: 3989973904317,
    price0: "0.000000001759929444254587728998318140702375",
    price1: "568204596.6470813145468428186800702",
    tickIdx: -201590,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001761690165878320727556066187733148",
    price1: "567636704.4379979931207085429823201",
    tickIdx: -201580,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001763452649016213513366660780281949",
    price1: "567069379.8089079357132453588175562",
    tickIdx: -201570,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000176521689543057313900795443988674",
    price1: "566502622.1925431932297018966862384",
    tickIdx: -201560,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000176698290688546975736006505983948",
    price1: "565936431.0222027726518079368414253",
    tickIdx: -201550,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001768750685146738385499284915643129",
    price1: "565370805.731752070393399080392837",
    tickIdx: -201540,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000177052023198198067035667754844246",
    price1: "564805745.755622306222374265954647",
    tickIdx: -201530,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001772291549160566656143128003623025",
    price1: "564241250.5288099577484201103499632",
    tickIdx: -201520,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001774064638453636553542613673049451",
    price1: "563677319.4868761954759366175952712",
    tickIdx: -201510,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001775839501634102510675464757458205",
    price1: "563113952.0659463184215993655330054",
    tickIdx: -201500,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001777616140476650385833385135332739",
    price1: "562551147.7027091902959938440604878",
    tickIdx: -201490,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001779394556757741521988006196172344",
    price1: "561988905.8344166762487581829192591",
    tickIdx: -201480,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000178117475225561452307474796942179",
    price1: "561427225.8988830801766710704609288",
    tickIdx: -201470,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001782956728750287032053763655458773",
    price1: "560866107.3344845825941222276946156",
    tickIdx: -201460,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001784740488023557510749745441942045",
    price1: "560305549.5801586790654033642474152",
    tickIdx: -201450,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000178652603185900702147237126750667",
    price1: "559745552.0754036191982581046336888",
    tickIdx: -201440,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001788313362042001010419173975255887",
    price1: "559186114.2602778461981299344318608",
    tickIdx: -201430,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001790102480359691092862616080743388",
    price1: "558627235.5753994369825477766094139",
    tickIdx: -201420,
  },
  {
    liquidityNet: -6281450907694,
    price0: "0.000000001791893388601016840123155163167164",
    price1: "558068915.4619455428550893683184412",
    tickIdx: -201410,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001793686088556707568330086674308272",
    price1: "557511153.3616518307383631680060071",
    tickIdx: -201400,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001795480582019284128971952747346669",
    price1: "556953948.7168119249654500816462513",
    tickIdx: -201390,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001797276870783060701238307377073489",
    price1: "556397300.9702768496292468553051902",
    tickIdx: -201380,
  },
  {
    liquidityNet: 1452031505933821,
    price0: "0.000000001799074956644146586154630134196514",
    price1: "555841209.565454471489153539095104",
    tickIdx: -201370,
  },
  {
    liquidityNet: 21740514210709689,
    price0: "0.000000001800874841400448002512182369404989",
    price1: "555285673.9463089434345479848637841",
    tickIdx: -201360,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001802676526851669884594601657623106",
    price1: "554730693.5573601485044908966953153",
    tickIdx: -201350,
  },
  {
    liquidityNet: 32620925914152,
    price0: "0.000000001804480014799317681703032029440181",
    price1: "554176267.8436831444631055094740621",
    tickIdx: -201340,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001806285307046699159481589335061674",
    price1: "553622396.2509076089300765263826283",
    tickIdx: -201330,
  },
  {
    liquidityNet: -32620925914152,
    price0: "0.000000001808092405398926203044962886280474",
    price1: "553069078.2252172850657135012683598",
    tickIdx: -201320,
  },
  {
    liquidityNet: 6149881737316,
    price0: "0.000000001809901311662916621909956323924064",
    price1: "552516313.213349427810024406322008",
    tickIdx: -201310,
  },
  {
    liquidityNet: -116649049881701,
    price0: "0.000000001811712027647395956732772461992218",
    price1: "551964100.6625942506752456794669973",
    tickIdx: -201300,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001813524555162899287853848665263446",
    price1: "551412440.020794373091275599258927",
    tickIdx: -201290,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001815338896021773045652051124518316",
    price1: "550861330.7363442683034583879430342",
    tickIdx: -201280,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001817155052038176822710038202705962",
    price1: "550310772.2581897118221669956128757",
    tickIdx: -201270,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001818973025028085187792604836368152",
    price1: "549760764.0358272304236330701570537",
    tickIdx: -201260,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001820792816809289501639821789435278",
    price1: "549211305.5193035517014731688729055",
    tickIdx: -201250,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001822614429201399734576785371122104",
    price1: "548662396.1592150541683608182673181",
    tickIdx: -201240,
  },
  {
    liquidityNet: 78413,
    price0: "0.000000001824437864025846285941795046080137",
    price1: "548114035.4067072179072945786556978",
    tickIdx: -201230,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001826263123105881805334778183209661",
    price1: "547566222.7134740757719128197112315",
    tickIdx: -201220,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001828090208266583015687783009599792",
    price1: "547018957.531757665135306462108426",
    tickIdx: -201210,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000182991912133485253815936265795104",
    price1: "546472239.3143474801867814888480857",
    tickIdx: -201200,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001831749864139420718854675019543773",
    price1: "545926067.5145799247760235777459089",
    tickIdx: -201190,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001833582438510847457373124940349365",
    price1: "545380441.5863377658041177539143142",
    tickIdx: -201180,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001835416846281524037185377125240555",
    price1: "544835360.9840495871608765078675014",
    tickIdx: -201170,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001837253089285674957841569944445516",
    price1: "544290825.1626892442079303711336248",
    tickIdx: -201160,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001839091169359359769012562167408055",
    price1: "543746833.5777753188070354869658985",
    tickIdx: -201150,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001840931088340474906366046482066169",
    price1: "543203385.685370574893053258906973",
    tickIdx: -201140,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001842772848068755529279365492244661",
    price1: "542660480.9420814145910577045785811",
    tickIdx: -201130,
  },
  {
    liquidityNet: 11630659308364,
    price0: "0.000000001844616450385777360390867722376477",
    price1: "542118118.8050573348770266861418002",
    tickIdx: -201120,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001846461897134958526991642997123769",
    price1: "541576298.7319903847815737324028395",
    tickIdx: -201110,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001848309190161561404259478403665173",
    price1: "541035020.1811146231361777105256064",
    tickIdx: -201100,
  },
  {
    liquidityNet: 24671349178891,
    price0: "0.000000001850158331312694460336877886452357",
    price1: "540494282.6112055768613681477559447",
    tickIdx: -201090,
  },
  {
    liquidityNet: -11630659308364,
    price0: "0.000000001852009322437314103254990368118249",
    price1: "539954085.4815796997963245454639567",
    tickIdx: -201080,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001853862165386226529705293135943489",
    price1: "539414428.2520938320693485691707092",
    tickIdx: -201070,
  },
  {
    liquidityNet: -412935343371,
    price0: "0.000000001855716862012089575660879080858304",
    price1: "538875310.3831446600086685390444708",
    tickIdx: -201060,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001857573414169414568849198225376069",
    price1: "538336731.3356681765930361856299371",
    tickIdx: -201050,
  },
  {
    liquidityNet: 107215028818840,
    price0: "0.000000001859431823714568183078105828124114",
    price1: "537798690.5711391424415761753122485",
    tickIdx: -201040,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001861292092505774294417071205758788",
    price1: "537261187.5515705473423494492164843",
    tickIdx: -201030,
  },
  {
    liquidityNet: -105097682099082,
    price0: "0.000000001863154222403115839235403268027141",
    price1: "536724221.7395130723190919579033197",
    tickIdx: -201020,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001865018215268536674099350618568757",
    price1: "536187792.5980545522355909123431602",
    tickIdx: -201010,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001866884072965843437529935932740159",
    price1: "535651899.5908194389371612092348612",
    tickIdx: -201000,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001868751797360707413623386184292547",
    price1: "535116542.1819682649286852257816645",
    tickIdx: -200990,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001870621390320666397536022155143405",
    price1: "534581719.8361971075886797155467416",
    tickIdx: -200980,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001872492853715126562835472526755535",
    price1: "534047432.0187370539188540729842787",
    tickIdx: -200970,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001874366189415364330720079717775159",
    price1: "533513678.1953536658286247696799188",
    tickIdx: -200960,
  },
  {
    liquidityNet: 131885859355103,
    price0: "0.000000001876241399294528241108366500585895",
    price1: "532980457.8323464459540513002370822",
    tickIdx: -200950,
  },
  {
    liquidityNet: 526741596530864,
    price0: "0.000000001878118485227640825600434299309278",
    price1: "532447770.3965483040106595101138275",
    tickIdx: -200940,
  },
  {
    liquidityNet: -131885859355103,
    price0: "0.000000001879997449091600482313165943527231",
    price1: "531915615.3553250236796187115489365",
    tickIdx: -200930,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001881878292765183352591107525619099",
    price1: "531383992.1765747300267395270164212",
    tickIdx: -200920,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000188376101812904519959490588509757",
    price1: "530852900.3287273574537599324151434",
    tickIdx: -200910,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001885645627065723288769180120695877",
    price1: "530322339.280744118181387504435252",
    tickIdx: -200900,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001887532121459638270191707410204895",
    price1: "529792308.5021169712635664082462314",
    tickIdx: -200890,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001889420503197096062805805299185144",
    price1: "529262807.4628680921324381928230074",
    tickIdx: -200880,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001891310774166289740537794502686979",
    price1: "528733835.6335493426734659918673603",
    tickIdx: -200870,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001893202936257301420301428149004485",
    price1: "528205392.4852417418301922583923165",
    tickIdx: -200860,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001895096991362104151891175281266455",
    price1: "527677477.4895549367381006906178259",
    tickIdx: -200850,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001896992941374563809766248321333433",
    price1: "527150090.1186266743870535358773467",
    tickIdx: -200840,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001898890788190440986727266091024825",
    price1: "526623229.8451222738117759877575399",
    tickIdx: -200830,
  },
  {
    liquidityNet: -22611825854883,
    price0: "0.000000001900790533707392889487445878146559",
    price1: "526096896.142234098809859919687611",
    tickIdx: -200820,
  },
  {
    liquidityNet: 736096804771,
    price0: "0.000000001902692179824975236140219929129574",
    price1: "525571088.4836810311867597256614596",
    tickIdx: -200810,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000190459572844464415552517364632432",
    price1: "525045806.3437079445272535657152721",
    tickIdx: -200800,
  },
  {
    liquidityNet: -736096804771,
    price0: "0.000000001906501181469758088494204666128554",
    price1: "524521049.1970851784928438401959839",
    tickIdx: -200790,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001908408540805579691079803894156716",
    price1: "523996816.5191080136445712427427282",
    tickIdx: -200780,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001910317808359277739567361475591106",
    price1: "523473107.7855961467907172672644745",
    tickIdx: -200770,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001912228986039929037473402582689784",
    price1: "522949922.4728931668588705690330724",
    tickIdx: -200760,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001914142075758520324431659807165529",
    price1: "522427260.0578660312918331043223793",
    tickIdx: -200750,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001916057079427950186988890852796167",
    price1: "521905120.0179045429668424968116011",
    tickIdx: -200740,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001917973998963030971312352133181102",
    price1: "521383501.8309208276375876022349127",
    tickIdx: -200730,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001919892836280490697810840791023794",
    price1: "520862404.9753488118984947655003916",
    tickIdx: -200720,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001921813593298974977671219568697162",
    price1: "520341828.9301437016707627867198176",
    tickIdx: -200710,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000192373627193904893131234087514038",
    price1: "519821773.1747814612096251342874681",
    tickIdx: -200700,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001925660874123199108758288311343164",
    price1: "519302237.1892582926323184643212133",
    tickIdx: -200690,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001927587401775835411932855835799351",
    price1: "518783220.4540901159662370264334995",
    tickIdx: -200680,
  },
  {
    liquidityNet: 139783996937993,
    price0: "0.000000001929515856823293018877186672357264",
    price1: "518264722.4503120497167530559337254",
    tickIdx: -200670,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001931446241193834309892495985861973",
    price1: "517746742.6594778919541837721775805",
    tickIdx: -200660,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001933378556817650795609803275875973",
    price1: "517229280.5636596019193861218736607",
    tickIdx: -200650,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001935312805626865046988602365582031",
    price1: "516712335.6454467821474609247335987",
    tickIdx: -200640,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001937248989555532627246398791716803",
    price1: "516195907.3879461611090485969095924",
    tickIdx: -200630,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001939187110539644025721046332058325",
    price1: "515679995.2747810763686991452030782",
    tickIdx: -200620,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001941127170517126593667816339596532",
    price1: "515164598.790090958259799642050907",
    tickIdx: -200610,
  },
  {
    liquidityNet: -25342363186198,
    price0: "0.000000001943069171427846481993135487055454",
    price1: "514649717.4185308140755429078012526",
    tickIdx: -200600,
  },
  {
    liquidityNet: 438887534207860,
    price0: "0.000000001945013115213610580926929461910654",
    price1: "514135350.6452707127754216427811401",
    tickIdx: -200590,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000194695900381816846163551209045778",
    price1: "513621497.9559952702067327671314132",
    tickIdx: -200580,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001948906839187214319776961309839605",
    price1: "513108158.8369031348405772413437294",
    tickIdx: -200570,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001950856623268388921000925349231753",
    price1: "512595332.7747064740218411548782385",
    tickIdx: -200560,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001952808358011281548394804425623228",
    price1: "512083019.256630460732644384170523",
    tickIdx: -200550,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001954762045367431951878255205808926",
    price1: "511571217.7704127608687436347526506",
    tickIdx: -200540,
  },
  {
    liquidityNet: -526741596530864,
    price0: "0.000000001956717687290332299547967234339437",
    price1: "511059927.8043030210283771951163181",
    tickIdx: -200530,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001958675285735429130974662477250539",
    price1: "510549148.8470623568130392423365903",
    tickIdx: -200520,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001960634842660125312454271083422885",
    price1: "510038880.387962841639672051353135",
    tickIdx: -200510,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001962596360023781994215238419403344",
    price1: "509529121.9167869960637649711726718",
    tickIdx: -200500,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001964559839787720569583920389455318",
    price1: "509019872.9238272776128495421120677",
    tickIdx: -200490,
  },
  {
    liquidityNet: 4294967296,
    price0: "0.000000001966525283915224636110026010497994",
    price1: "508511132.8998855711298806385466712",
    tickIdx: -200480,
  },
  {
    liquidityNet: -4294967296,
    price0: "0.000000001968492694371541958654068171445935",
    price1: "508002901.3362726796259940314635508",
    tickIdx: -200470,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001970462073123886434438785468272581",
    price1: "507495177.7248078156421312744448395",
    tickIdx: -200460,
  },
  {
    liquidityNet: 425373803235919,
    price0: "0.000000001972433422141440060066499969896073",
    price1: "506987961.5578180931190233255228695",
    tickIdx: -200450,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001974406743395354900504377735725364",
    price1: "506481252.3281380197750248256567234",
    tickIdx: -200440,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001976382038858755060039560873410683",
    price1: "505975049.5291089899912914623797567",
    tickIdx: -200430,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001978359310506738655206141895017192",
    price1: "505469352.654578778203793354460034",
    tickIdx: -200420,
  },
  {
    liquidityNet: -414129428239826,
    price0: "0.000000001980338560316379789685953101485948",
    price1: "504964161.1989010328016579002010106",
    tickIdx: -200410,
  },
  {
    liquidityNet: 6843410092335733,
    price0: "0.000000001982319790266730531185145698864125",
    price1: "504459474.6569347705313360382886689",
    tickIdx: -200400,
  },
  {
    liquidityNet: -18808106518627,
    price0: "0.000000001984303002338822890288535325378773",
    price1: "503955292.5240438714060863758641946",
    tickIdx: -200390,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001986288198515670801293692645997222",
    price1: "503451614.2960965741202721437686607",
    tickIdx: -200380,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001988275380782272105026759650664511",
    price1: "502948439.4694649719679664436685773",
    tickIdx: -200370,
  },
  {
    liquidityNet: 19731179740774089,
    price0: "0.000000001990264551125610533641974273935925",
    price1: "502445767.5410245092653617560290791",
    tickIdx: -200360,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001992255711534657697406887937232872",
    price1: "501943598.0081534782764801816554432",
    tickIdx: -200350,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001994248864000375073475262600444873",
    price1: "501441930.368732516641681392774079",
    tickIdx: -200340,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001996244010515715996649635897081332",
    price1: "500940764.1211441053084657723716192",
    tickIdx: -200330,
  },
  {
    liquidityNet: 0,
    price0: "0.000000001998241153075627652135544916646092",
    price1: "500440098.764272066964070722755731",
    tickIdx: -200320,
  },
  {
    liquidityNet: 14540580338339209,
    price0: "0.000000002000240293677053070289401189367432",
    price1: "499939933.7975010649693586260443131",
    tickIdx: -200310,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002002241434318933123362011421868167",
    price1: "499440268.7207161027934954405313119",
    tickIdx: -200300,
  },
  {
    liquidityNet: 188770088006,
    price0: "0.000000002004244577002208524239740527806924",
    price1: "498941103.0343020239489194176179866",
    tickIdx: -200290,
  },
  {
    liquidityNet: -153631444170281,
    price0: "0.000000002006249723729821827185315494964319",
    price1: "498442436.239143012426099924238605",
    tickIdx: -200280,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002008256876506719430580270629688899",
    price1: "497944267.836622093627586855449705",
    tickIdx: -200270,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002010266037339853581671036721059032",
    price1: "497446597.3286206358008516210927825",
    tickIdx: -200260,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002012277208238184383320678670560725",
    price1: "496949424.217517851969421189181996",
    tickIdx: -200250,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002014290391212681802768288138529405",
    price1: "496452748.0061903023618071669117555",
    tickIdx: -200240,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002016305588276327682398039766058153",
    price1: "495956568.1980113973377323979243679",
    tickIdx: -200230,
  },
  {
    liquidityNet: 26020327136165,
    price0: "0.00000000201832280144411775251992154053766",
    price1: "495460884.2968509008111580517257425",
    tickIdx: -200220,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002020342032733063646164151884466346",
    price1: "494965695.80707443416961467788802",
    tickIdx: -200210,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002022363284162194915891298060654636",
    price1: "494471002.2335429806893411939323631",
    tickIdx: -200200,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002024386557752561052620112502447282",
    price1: "493976803.0816123904457362715435553",
    tickIdx: -200190,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002026411855527233506475105695104005",
    price1: "493483097.8571328857186270810309586",
    tickIdx: -200180,
  },
  {
    liquidityNet: 7395200162,
    price0: "0.000000002028439179511307709655876254013464",
    price1: "492989886.0664485668918608487183054",
    tickIdx: -200170,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002030468531731905101330220866970776",
    price1: "492497167.2163969188467251762182317",
    tickIdx: -200160,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002032499914218175154553048791326491",
    price1: "492004940.8143083178487035643268732",
    tickIdx: -200150,
  },
  {
    liquidityNet: -7395200162,
    price0: "0.000000002034533329001297405213127622417086",
    price1: "491513206.3680055389270730775597713",
    tickIdx: -200140,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002036568778114483483009689077315693",
    price1: "491021963.3858032637468515781432373",
    tickIdx: -200130,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002038606263592979144460925567599045",
    price1: "490531211.3765075889726024505756944",
    tickIdx: -200120,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002040645787474066307946410366514349",
    price1: "490040949.8494155351236052296818814",
    tickIdx: -200110,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002042687351797065090785476209650249",
    price1: "489551178.3143145559199010363995998",
    tickIdx: -200100,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002044730958603335848353589203971006",
    price1: "489061896.2814820481187222163644641",
    tickIdx: -200090,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000204677660993628121523875695786477",
    price1: "488573103.2616848618408160666933162",
    tickIdx: -200080,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002048824307841348148440011884687192",
    price1: "488084798.7661788113861730262121138",
    tickIdx: -200070,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002050874054366029972610012674152803",
    price1: "487596982.3067081865386701937296671",
    tickIdx: -200060,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002052925851559868427343808969840509",
    price1: "487109653.3955052643591415278250847",
    tickIdx: -200050,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002054979701474455716515816337038348",
    price1: "486622811.5452898214663865699946686",
    tickIdx: -200040,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002057035606163436559667050653158303",
    price1: "486136456.2692686468056300208937769",
    tickIdx: -200030,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002059093567682510245444673103006562",
    price1: "485650587.0811350549039449868113221",
    tickIdx: -200020,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002061153588089432687095899013300168",
    price1: "485165203.4950683996121532004295951",
    tickIdx: -200010,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002063215669444018480018325814979601",
    price1: "484680305.025733588332716006350475",
    tickIdx: -200000,
  },
  {
    liquidityNet: -218907027699,
    price0: "0.000000002065279813808142961368737478080503",
    price1: "484195891.188280596733130387811297",
    tickIdx: -199990,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002067346023245744271732444822198593",
    price1: "483711961.4983439839443447964701815",
    tickIdx: -199980,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002069414299822825418855223165911801",
    price1: "483228515.4720424082437100321119811",
    tickIdx: -199970,
  },
  {
    liquidityNet: -1931085281446,
    price0: "0.000000002071484645607456343439910840914946",
    price1: "482745552.6259781432219809036126362",
    tickIdx: -199960,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002073557062669775987009734161076857",
    price1: "482263072.4772365944338848865021553",
    tickIdx: -199950,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002075631553081994361840426503149816",
    price1: "481781074.5433858165317744759851198",
    tickIdx: -199940,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002077708118918394622963211224448628",
    price1: "481299558.3424760308818804173130419",
    tickIdx: -199930,
  },
  {
    liquidityNet: 183941712298,
    price0: "0.000000002079786762255335142240720213473554",
    price1: "480818523.3930391436626834779555727",
    tickIdx: -199920,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002081867485171251584517921942179893",
    price1: "480337969.2140882644449229080879227",
    tickIdx: -199910,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002083950289746658985850134963399159",
    price1: "479857895.3251172252527602175004385",
    tickIdx: -199900,
  },
  {
    liquidityNet: 13025318215,
    price0: "0.000000002086035178064153833810204873794779",
    price1: "479378301.2461001001056173781435094",
    tickIdx: -199890,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002088122152208416149876924841690924",
    price1: "478899186.4974907250402090421473951",
    tickIdx: -199880,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000209021121426621157390678188014875",
    price1: "478420550.6002222186122888453025987",
    tickIdx: -199870,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002092302366326393450691113128781935",
    price1: "477942393.0757065028776303456525644",
    tickIdx: -199860,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002094395610479904918600758493005033",
    price1: "477464713.4458338248517636260372456",
    tickIdx: -199850,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002096490948819781000320298076695993",
    price1: "476987511.232972278447989068133911",
    tickIdx: -199840,
  },
  {
    liquidityNet: -13025318215,
    price0: "0.0000000020985883834411506956739649336302",
    price1: "476510785.9599673268931902837709519",
    tickIdx: -199830,
  },
  {
    liquidityNet: 680173176856137,
    price0: "0.000000002100687916441239076545325754509763",
    price1: "476034537.1501413256209686670418692",
    tickIdx: -199820,
  },
  {
    liquidityNet: 86088666806590,
    price0: "0.000000002102789549919369383892824199970485",
    price1: "475558764.3272930456416225080205603",
    tickIdx: -199810,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002104893285976965126863283685602261",
    price1: "475083467.0156971973884940856759357",
    tickIdx: -199800,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002106999126717552184005468522768454",
    price1: "474608644.7401039550402086339042905",
    tickIdx: -199790,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002109107074246760906585804418858358",
    price1: "474134297.0257384813183295504421821",
    tickIdx: -199780,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002111217130672328224008361442556203",
    price1: "473660423.398300452759954693791307",
    tickIdx: -199770,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002113329298104099751341204663762381",
    price1: "473187023.3839635854647790881805123",
    tickIdx: -199760,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000021154435786540318989512197839598",
    price1: "472714096.5093751613161498310090795",
    tickIdx: -199750,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002117559974436193984249523181082639",
    price1: "472241642.3016555546756394711602648",
    tickIdx: -199740,
  },
  {
    liquidityNet: 80232712836197,
    price0: "0.000000002119678487566770345549567903318278",
    price1: "471769660.2883977595506646000452482",
    tickIdx: -199730,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002121799120164062458040059258758104",
    price1: "471298149.9976669172346768702355877",
    tickIdx: -199720,
  },
  {
    liquidityNet: 1902630323697,
    price0: "0.00000000212392187434849105187479576241117",
    price1: "470827110.9579998444194541290674969",
    tickIdx: -199710,
  },
  {
    liquidityNet: 1627910756383,
    price0: "0.000000002126046752242598232381553318808569",
    price1: "470356542.6984045617790198266542044",
    tickIdx: -199700,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002128173755971049602392132637257874",
    price1: "469886444.7483598230247193293238198",
    tickIdx: -199690,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002130302887660636386695691997758366",
    price1: "469416816.6378146444309822406099509",
    tickIdx: -199680,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002132434149440277558617489608660933",
    price1: "468947657.8971878348313003025613112",
    tickIdx: -199670,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002134567543441021968725161922353799",
    price1: "468478968.0573675260839509203051534",
    tickIdx: -199660,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000213670307179605047566466640257865",
    price1: "468010746.6497107040069968224980543",
    tickIdx: -199650,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002138840736640678079128019366433382",
    price1: "467542993.2060427397820928395268357",
    tickIdx: -199640,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002140980540112356054954961655699809",
    price1: "467075707.258656921826631250082678",
    tickIdx: -199630,
  },
  {
    liquidityNet: 85727317647215,
    price0: "0.000000002143122484350674092370687025849294",
    price1: "466608888.3403139881337576150232669",
    tickIdx: -199620,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002145266571497362433361770276928575",
    price1: "466142535.9842416590797894852615554",
    tickIdx: -199610,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002147412803696294014192434288514173",
    price1: "465676649.7241341706985708377758999",
    tickIdx: -199600,
  },
  {
    liquidityNet: 17370520582437,
    price0: "0.000000002149561183093486609063297261048864",
    price1: "465211229.0941518084222955607254034",
    tickIdx: -199590,
  },
  {
    liquidityNet: 41132315658245,
    price0: "0.000000002151711711837104975914743608139804",
    price1: "464746273.6289204412883337750767547",
    tickIdx: -199580,
  },
  {
    liquidityNet: 147933039927065514,
    price0: "0.000000002153864392077463004377064088807329",
    price1: "464281782.8635310566115952461051189",
    tickIdx: -199570,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002156019225967025865869512915228202",
    price1: "463817756.3335392951219646036222206",
    tickIdx: -199560,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002158176215660412165850431720219326",
    price1: "463354193.5749649865663435548101104",
    tickIdx: -199550,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002160335363314396098220592419559966",
    price1: "462891094.1242916857748357380996647",
    tickIdx: -199540,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002162496671087909601881913157254219",
    price1: "462428457.5184662091906103306291573",
    tickIdx: -199530,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002164660141142044519453703676993258",
    price1: "461966283.2948981718629809854506575",
    tickIdx: -199520,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002166825775640054758148598620390746",
    price1: "461504570.9914595249032371378210644",
    tickIdx: -199510,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002168993576747358452810339412036976",
    price1: "461043320.1464840934027651826207201",
    tickIdx: -199500,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002171163546631540131115567553049858",
    price1: "460582530.2987671148129974871862402",
    tickIdx: -199490,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002173335687462352880941794308596141",
    price1: "460122200.98756477778672766562588",
    tickIdx: -199480,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002175510001411720519903713940816194",
    price1: "459662331.7525937614803310020059332",
    tickIdx: -199470,
  },
  {
    liquidityNet: 100668693635726,
    price0: "0.000000002177686490653739767060029806712579",
    price1: "459202922.1340307753164293706557599",
    tickIdx: -199460,
  },
  {
    liquidityNet: 253432446722088,
    price0: "0.000000002179865157364682416792964810858697",
    price1: "458743971.6725120992065404622375301",
    tickIdx: -199450,
  },
  {
    liquidityNet: 31199147170082,
    price0: "0.000000002182046003722997514862629875251037",
    price1: "458285479.9091331242332515841651249",
    tickIdx: -199440,
  },
  {
    liquidityNet: -253432446722088,
    price0: "0.00000000218422903190931353663842626326933",
    price1: "457827446.3854478937914587634352962",
    tickIdx: -199430,
  },
  {
    liquidityNet: -131867840805808,
    price0: "0.000000002186414244106440567509659771525201",
    price1: "457369870.6434686451882123389536351",
    tickIdx: -199420,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002188601642499372485477546982374147",
    price1: "456912752.2256653517007106889985529",
    tickIdx: -199410,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002190791229275289145930795951039712",
    price1: "456456090.6749652650919841975688574",
    tickIdx: -199400,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002192983006623558568606945884655047",
    price1: "455999885.5347524585838120210050129",
    tickIdx: -199390,
  },
  {
    liquidityNet: -1454291230079381,
    price0: "0.000000002195176976735739126741652556067665",
    price1: "455544136.3488673702864146734612893",
    tickIdx: -199380,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002197373141805581738408108382980332",
    price1: "455088842.6616063470844659065361925",
    tickIdx: -199370,
  },
  {
    liquidityNet: -403139175070980,
    price0: "0.000000002199571504029032060048788292916876",
    price1: "454634004.0177211889789678146422752",
    tickIdx: -199360,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002201772065604232682201714686608494",
    price1: "454179619.9624186938845335535140996",
    tickIdx: -199350,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002203974828731525327423437006695943",
    price1: "453725690.0413602028816225146152486",
    tickIdx: -199340,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002206179795613453050410923615138148",
    price1: "453272213.8006611459232732531122803",
    tickIdx: -199330,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002208386968454762440324565881410413",
    price1: "452819190.7868905879958799215358627",
    tickIdx: -199320,
  },
  {
    liquidityNet: 1027188904527667,
    price0: "0.000000002210596349462405825314496584467679",
    price1: "452366620.5470707757335584152474747",
    tickIdx: -199310,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002212807940845543479252426934542481",
    price1: "451914502.6286766844856488893744535",
    tickIdx: -199300,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002215021744815545830671208726145537",
    price1: "451462836.579635565836901759967263",
    tickIdx: -199290,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002217237763585995673914330341141444",
    price1: "451011621.9483264955798947547711232",
    tickIdx: -199280,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002219455999372690382497557530485024",
    price1: "450560858.2835799221392290311900027",
    tickIdx: -199270,
  },
  {
    liquidityNet: -294036802555629,
    price0: "0.000000002221676454393644124684932115127657",
    price1: "450110545.134677215447052830754909",
    tickIdx: -199260,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000222389913086909008128134396073957",
    price1: "449660682.0513502162694615906908614",
    tickIdx: -199250,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000222612403102148266564389379724594",
    price1: "449211268.5837807859833238840083332",
    tickIdx: -199240,
  },
  {
    liquidityNet: 324035942790599,
    price0: "0.000000002228351157075499745914266672743782",
    price1: "448762304.2826003568030830099257882",
    tickIdx: -199230,
  },
  {
    liquidityNet: -176916379377741,
    price0: "0.000000002230580511258044869474338052155366",
    price1: "448313788.6988894824570845063606226",
    tickIdx: -199220,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002232812095798249489627236793984431",
    price1: "447865721.3841773893129803057068372",
    tickIdx: -199210,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000223504591292747519450609146377598",
    price1: "447418101.8904415279517607041495442",
    tickIdx: -199200,
  },
  {
    liquidityNet: 1044453137783621,
    price0: "0.00000000223728196487931593821268867034124",
    price1: "446970929.7701071251899657633494023",
    tickIdx: -199190,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000022395202538896002741882743404986",
    price1: "446524204.5760467365496282114647292",
    tickIdx: -199180,
  },
  {
    liquidityNet: -324035942790599,
    price0: "0.000000002241760782196393590818731080001263",
    price1: "446077925.8615797991755003581658116",
    tickIdx: -199170,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002244003552040000349276367002475217",
    price1: "445632093.1804721851991179855352559",
    tickIdx: -199160,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002246248565662966323600553644579125",
    price1: "445186706.0869357555492546235405603",
    tickIdx: -199150,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002248495825310080843019452824223164",
    price1: "444741764.1356279142083200651108713",
    tickIdx: -199140,
  },
  {
    liquidityNet: 34998399612856013,
    price0: "0.000000002250745333228379036515074539548876",
    price1: "444297266.8816511629142574217495767",
    tickIdx: -199130,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002252997091667144079633910249478993",
    price1: "443853213.880552656307493466068421",
    tickIdx: -199120,
  },
  {
    liquidityNet: -85727317647215,
    price0: "0.000000002255251102877909443545388121997259",
    price1: "443409604.6883237575224974526376466",
    tickIdx: -199110,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002257507369114461146350399083915636",
    price1: "442966438.8613995942235040531107284",
    tickIdx: -199100,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002259765892632840006642144755732321",
    price1: "442523715.956658615083956485702002",
    tickIdx: -199090,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002262026675691343899321560607280808",
    price1: "442081435.5314221467092263627713577",
    tickIdx: -199080,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002264289720550530013669569924220256",
    price1: "441639597.1434539510021672235025952",
    tickIdx: -199070,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002266555029473217113678426432022724",
    price1: "441198200.3509597829710591614514823",
    tickIdx: -199060,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002268822604724487800644405682975815",
    price1: "440757244.7125869489795023990864537",
    tickIdx: -199050,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002271092448571690778024107572842085",
    price1: "440316729.7874238654378181033496825",
    tickIdx: -199040,
  },
  {
    liquidityNet: 1208198346129589,
    price0: "0.000000002273364563284443118556634617201557",
    price1: "439876655.1349996179355151777293898",
    tickIdx: -199030,
  },
  {
    liquidityNet: 68719476736,
    price0: "0.000000002275638951134632533653912883153106",
    price1: "439437020.3152835208143822073561547",
    tickIdx: -199020,
  },
  {
    liquidityNet: -68719476736,
    price0: "0.000000002277915614396419645061424739966438",
    price1: "438997824.8886846771817641742171429",
    tickIdx: -199010,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002280194555346240258791624862461468",
    price1: "438559068.41605153936358399972294",
    tickIdx: -199000,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002282475776262807641332313193348057",
    price1: "438120750.4586714697966694115625813",
    tickIdx: -198990,
  },
  {
    liquidityNet: 22883332025832,
    price0: "0.000000002284759279427114798132240845488714",
    price1: "437682870.5782703023599460710438086",
    tickIdx: -198980,
  },
  {
    liquidityNet: 857723784316693,
    price0: "0.000000002287045067122436754366227202052363",
    price1: "437245428.3370119041440583359379824",
    tickIdx: -198970,
  },
  {
    liquidityNet: 3133288463956254,
    price0: "0.000000002289333141634332837982068751810651",
    price1: "436808423.2974977376589794722329343",
    tickIdx: -198960,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002291623505250648965031522478392135",
    price1: "436371855.0227664234791735661427083",
    tickIdx: -198950,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002293916160261519927287648906155943",
    price1: "435935723.0762933033258718252311511",
    tickIdx: -198940,
  },
  {
    liquidityNet: 68719476736,
    price0: "0.000000002296211108959371682150802191477809",
    price1: "435500027.0219900035860263945770123",
    tickIdx: -198930,
  },
  {
    liquidityNet: -68719476736,
    price0: "0.000000002298508353638923644845556936659759",
    price1: "435064766.4242039992675052505421061",
    tickIdx: -198920,
  },
  {
    liquidityNet: 2147441042882743,
    price0: "0.000000002300807896597190982910863694382536",
    price1: "434629940.8477181783900921709015875",
    tickIdx: -198910,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002303109740133486912985727423619463",
    price1: "434195549.8577504068118562158569244",
    tickIdx: -198900,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002305413886549424999892705453224052",
    price1: "433761593.0199530934904555897781625",
    tickIdx: -198890,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002307720338148921458021523806993583",
    price1: "433328069.9004127561789411884130109",
    tickIdx: -198880,
  },
  {
    liquidityNet: -151066259671545032,
    price0: "0.000000002310029097238197455015113043899466",
    price1: "432894980.0656495875556255707565487",
    tickIdx: -198870,
  },
  {
    liquidityNet: -68719476736,
    price0: "0.00000000231234016612578141776036706936467",
    price1: "432462323.0826170217875835287974111",
    tickIdx: -198860,
  },
  {
    liquidityNet: 68719476736,
    price0: "0.000000002314653547122511340685930677961211",
    price1: "432030098.5187013015273508619445926",
    tickIdx: -198850,
  },
  {
    liquidityNet: -68719476736,
    price0: "0.000000002316969242541537096369323894698952",
    price1: "431598305.9417210453423883960939229",
    tickIdx: -198840,
  },
  {
    liquidityNet: 184721299399067,
    price0: "0.000000002319287254698322748455713491183027",
    price1: "431166944.919926815576878720015285",
    tickIdx: -198830,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002321607585910648866890644364333435",
    price1: "430736015.0220006866454235440311662",
    tickIdx: -198820,
  },
  {
    liquidityNet: 33355293857535,
    price0: "0.000000002323930238498614845469045779089026",
    price1: "430305515.8170558137582100178146075",
    tickIdx: -198810,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002326255214784641221702829792561558",
    price1: "429875446.8746360020772147755604636",
    tickIdx: -198800,
  },
  {
    liquidityNet: -33355293857535,
    price0: "0.000000002328582517093471999009401495466031",
    price1: "429445807.7647152763030149077785565",
    tickIdx: -198790,
  },
  {
    liquidityNet: 4745881456118217,
    price0: "0.000000002330912147752176971223403027333454",
    price1: "429016598.0576974506917754895212055",
    tickIdx: -198780,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002333244109090154049434015645013863",
    price1: "428587817.324415699501983724991198",
    tickIdx: -198770,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002335578403439131591150146449303113",
    price1: "428159465.1361321278705001981799459",
    tickIdx: -198760,
  },
  {
    liquidityNet: 16947962120472,
    price0: "0.000000002337915033133170731795828702179075",
    price1: "427731541.0645373431174981484597856",
    tickIdx: -198750,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002340254000508667718538166997113619",
    price1: "427304044.6817500264798621188995587",
    tickIdx: -198740,
  },
  {
    liquidityNet: -184721299399067,
    price0: "0.0000000023425953079043562464501608772386",
    price1: "426876975.5603165052726177534891748",
    tickIdx: -198730,
  },
  {
    liquidityNet: 48429365059,
    price0: "0.000000002344938957661309797010742830789263",
    price1: "426450333.2732103254779649474472372",
    tickIdx: -198720,
  },
  {
    liquidityNet: 48475428631,
    price0: "0.000000002347284952122943978944368930229286",
    price1: "426024117.39383182476148698234646",
    tickIdx: -198710,
  },
  {
    liquidityNet: -48429365059,
    price0: "0.000000002349633293635018871402502720780657",
    price1: "425598327.4960077059151087049248955",
    tickIdx: -198700,
  },
  {
    liquidityNet: -48475428631,
    price0: "0.000000002351983984545641369489335305740829",
    price1: "425172963.1539906107263772351574063",
    tickIdx: -198690,
  },
  {
    liquidityNet: 1743806519664,
    price0: "0.000000002354337027205267532134086919971573",
    price1: "424748023.942458694273639115441755",
    tickIdx: -198680,
  },
  {
    liquidityNet: -68719476736,
    price0: "0.000000002356692423966704932312237629291014",
    price1: "424323509.436515199646688238607572",
    tickIdx: -198670,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002359050177185115009618037142194785",
    price1: "423899419.211688033092459317884732",
    tickIdx: -198660,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002361410289218015425190646071376422",
    price1: "423475752.8439293395853420869707511",
    tickIdx: -198650,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002363772762425282418996263335913459",
    price1: "423052509.9096150788216918429151167",
    tickIdx: -198640,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002366137599169153169468596750736382",
    price1: "422629689.9855446016381123686924346",
    tickIdx: -198630,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002368504801814228155510036208105254",
    price1: "422207292.6489402268530876960663075",
    tickIdx: -198620,
  },
  {
    liquidityNet: 20039416659547378,
    price0: "0.000000002370874372727473520855891216285482",
    price1: "421785317.4774468185315395926524147",
    tickIdx: -198610,
  },
  {
    liquidityNet: 638351414611625,
    price0: "0.000000002373246314278223440804056923442526",
    price1: "421363764.049131363671888079972726",
    tickIdx: -198600,
  },
  {
    liquidityNet: 48754961902045,
    price0: "0.000000002375620628838182491312475119967473",
    price1: "420942631.9424825503151927117536074",
    tickIdx: -198590,
  },
  {
    liquidityNet: -92098300723880,
    price0: "0.000000002377997318781428020466759080003851",
    price1: "420521920.7364103460759527637591617",
    tickIdx: -198580,
  },
  {
    liquidityNet: 3649682130895,
    price0: "0.000000002380376386484412522320353472873063",
    price1: "420101630.0102455770941449080679391",
    tickIdx: -198570,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000238275783432596601310960294739388",
    price1: "419681759.3437395074080773658965472",
    tickIdx: -198560,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002385141664687298409846105366762837",
    price1: "419262308.3170634187476399538481265",
    tickIdx: -198550,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002387527879952001911288728048709504",
    price1: "418843276.5108081907475298588175515",
    tickIdx: -198540,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002389916482506053381297667745065863",
    price1: "418424663.505983881580033396718987",
    tickIdx: -198530,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002392307474737816734572937476694795",
    price1: "418006468.8840193090069444297154957",
    tickIdx: -198520,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002394700859038045324779665723911292",
    price1: "417588692.2267616318502005357251774",
    tickIdx: -198510,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000239709663779988433506259585910392",
    price1: "417171333.1164759318808184426542387",
    tickIdx: -198500,
  },
  {
    liquidityNet: 831991567517358,
    price0: "0.000000002399494813418873170952176097225594",
    price1: "416754391.135844796125710658064866",
    tickIdx: -198490,
  },
  {
    liquidityNet: -224614755070704,
    price0: "0.00000000240189538829294785566463263117431",
    price1: "416337865.8679678995919656428252211",
    tickIdx: -198480,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002404298364822443427798421012828489",
    price1: "415921756.8963615884081742947107126",
    tickIdx: -198470,
  },
  {
    liquidityNet: 0,
    price0: "0.0000000024067037454100963414294532366404",
    price1: "415506063.8049584633823859249303445",
    tickIdx: -198460,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002409111532461046868607500381227209",
    price1: "415090786.1781069639762773271398074",
    tickIdx: -198450,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002411521728382841504256173065334825",
    price1: "414675923.60057095269511895467449",
    tickIdx: -198440,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002413934335585435373478884377887436",
    price1: "414261475.6575292998931226374911535",
    tickIdx: -198430,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000241634935648119464127320234757767",
    price1: "413847441.9345754689937556856470566",
    tickIdx: -198420,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002418766793484898924656001425601306",
    price1: "413433822.0177171021246066410702378",
    tickIdx: -198410,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002421186649013743707201824865698553",
    price1: "413020615.4933756061663883538849043",
    tickIdx: -198400,
  },
  {
    liquidityNet: -35858890972279,
    price0: "0.000000002423608925487342755996872298633768",
    price1: "412607821.9483857392156644736518152",
    tickIdx: -198390,
  },
  {
    liquidityNet: 58732421224623374,
    price0: "0.000000002426033625327730541011029213629292",
    price1: "412195440.9699951974608858595656372",
    tickIdx: -198380,
  },
  {
    liquidityNet: -22883332025832,
    price0: "0.000000002428460750959364656890357477069447",
    price1: "411783472.1458642024713238269198691",
    tickIdx: -198370,
  },
  {
    liquidityNet: 1091245972680,
    price0: "0.000000002430890304809128247172468439009941",
    price1: "411371915.0640650888984875600055206",
    tickIdx: -198360,
  },
  {
    liquidityNet: 3188800447419384,
    price0: "0.000000002433322289306332430927202600668427",
    price1: "410960769.3130818925896134340526882",
    tickIdx: -198350,
  },
  {
    liquidityNet: 9498336245448,
    price0: "0.000000002435756706882718731825042241136255",
    price1: "410550034.4818099391128144008549062",
    tickIdx: -198340,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002438193559972461509635685829041847",
    price1: "410139710.1595554326934780043350909",
    tickIdx: -198330,
  },
  {
    liquidityNet: 2963566552749641,
    price0: "0.000000002440632851012170394159215474815114",
    price1: "409729795.9360350455615020035194359",
    tickIdx: -198320,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002443074582440892721592291111552349",
    price1: "409320291.401375507708956991182183",
    tickIdx: -198310,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002445518756700115973331807527264487",
    price1: "408911196.1461131970577658068101753",
    tickIdx: -198300,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002447965376233770217218452808510919",
    price1: "408502509.7611937300369899525119434",
    tickIdx: -198290,
  },
  {
    liquidityNet: 1596859513065802,
    price0: "0.000000002450414443488230551222609195078765",
    price1: "408094231.8379715525693136300621375",
    tickIdx: -198280,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000245286596091231954957503978746582",
    price1: "407686361.9682095314663164264288768",
    tickIdx: -198270,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002455319930957309711344806993467068",
    price1: "407278899.7440785462321260838793795",
    tickIdx: -198260,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002457776356076925911466871047151864",
    price1: "406871844.7581570812750431990985289",
    tickIdx: -198250,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002460235238727347854221819382954204",
    price1: "406465196.6034308185267301036862017",
    tickIdx: -198240,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002462696581367212529170180099484418",
    price1: "406058954.8732922304685565859226386",
    tickIdx: -198230,
  },
  {
    liquidityNet: 136224718151027,
    price0: "0.000000002465160386457616669543775202009411",
    price1: "405653119.1615401735646955208073135",
    tickIdx: -198220,
  },
  {
    liquidityNet: 114766405232771,
    price0: "0.00000000246762665646211921309657176934296",
    price1: "405247689.0623794821015618820860148",
    tickIdx: -198210,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002470095393846743765417491650139691",
    price1: "404842664.1704205624331890162836502",
    tickIdx: -198200,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002472566601079981065707642755299003",
    price1: "404438044.0806789876321364646569812",
    tickIdx: -198190,
  },
  {
    liquidityNet: 0,
    price0: "0.00000000247504028063279145502443747736053",
    price1: "404033828.3885750925455240244725327",
    tickIdx: -198180,
  },
  {
    liquidityNet: -136224718151027,
    price0: "0.000000002477516434978607346995066234413408",
    price1: "403630016.6899335692557871461006881",
    tickIdx: -198170,
  },
  {
    liquidityNet: 80480548192599,
    price0: "0.000000002479995066593335701001796605150071",
    price1: "403226608.580983062945749167097873",
    tickIdx: -198160,
  },
  {
    liquidityNet: 1065521008638171,
    price0: "0.000000002482476177955360497841570993273876",
    price1: "402823603.6583557681676062887251845",
    tickIdx: -198150,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002484959771545545217862378233521251",
    price1: "402421001.5190870255154216042242005",
    tickIdx: -198140,
  },
  {
    liquidityNet: 0,
    price0: "0.000000002487445849847235321578877028085471",
    price1: "402018801.7606149187007248916394544",
    tickIdx: -198130,
  },
];

export const calculatorPositionBreakDownMockData: PositionBreakdown[] = [
  { price: 0, token0Amount: 1035.194601, token1Amount: 0 },
  {
    price: 0.0000029999999999999997,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  {
    price: 0.000005999999999999999,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  { price: 0.000009, token0Amount: 1035.194601, token1Amount: 0 },
  {
    price: 0.000011999999999999999,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  {
    price: 0.000014999999999999999,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  { price: 0.000018, token0Amount: 1035.194601, token1Amount: 0 },
  { price: 0.000021, token0Amount: 1035.194601, token1Amount: 0 },
  {
    price: 0.000023999999999999997,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  {
    price: 0.000026999999999999996,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  {
    price: 0.000029999999999999997,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  {
    price: 0.000032999999999999996,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  { price: 0.000036, token0Amount: 1035.194601, token1Amount: 0 },
  { price: 0.000039, token0Amount: 1035.194601, token1Amount: 0 },
  { price: 0.000042, token0Amount: 1035.194601, token1Amount: 0 },
  {
    price: 0.000044999999999999996,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  {
    price: 0.000047999999999999994,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  {
    price: 0.00005099999999999999,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  {
    price: 0.00005399999999999999,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  {
    price: 0.000056999999999999996,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  {
    price: 0.000059999999999999995,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  {
    price: 0.00006299999999999999,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  {
    price: 0.00006599999999999999,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  { price: 0.000069, token0Amount: 1035.194601, token1Amount: 0 },
  { price: 0.000072, token0Amount: 1035.194601, token1Amount: 0 },
  { price: 0.000075, token0Amount: 1035.194601, token1Amount: 0 },
  { price: 0.000078, token0Amount: 1035.194601, token1Amount: 0 },
  {
    price: 0.00008099999999999999,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  { price: 0.000084, token0Amount: 1035.194601, token1Amount: 0 },
  { price: 0.000087, token0Amount: 1035.194601, token1Amount: 0 },
  {
    price: 0.00008999999999999999,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  { price: 0.000093, token0Amount: 1035.194601, token1Amount: 0 },
  {
    price: 0.00009599999999999999,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  { price: 0.000099, token0Amount: 1035.194601, token1Amount: 0 },
  {
    price: 0.00010199999999999999,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  {
    price: 0.00010499999999999999,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  {
    price: 0.00010799999999999998,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  {
    price: 0.00011099999999999999,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  {
    price: 0.00011399999999999999,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  {
    price: 0.00011699999999999998,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  {
    price: 0.00011999999999999999,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  {
    price: 0.00012299999999999998,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  {
    price: 0.00012599999999999997,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  { price: 0.000129, token0Amount: 1035.194601, token1Amount: 0 },
  {
    price: 0.00013199999999999998,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  {
    price: 0.00013499999999999997,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  { price: 0.000138, token0Amount: 1035.194601, token1Amount: 0 },
  {
    price: 0.00014099999999999998,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  { price: 0.000144, token0Amount: 1035.194601, token1Amount: 0 },
  { price: 0.000147, token0Amount: 1035.194601, token1Amount: 0 },
  { price: 0.00015, token0Amount: 1035.194601, token1Amount: 0 },
  { price: 0.000153, token0Amount: 1035.194601, token1Amount: 0 },
  { price: 0.000156, token0Amount: 1035.194601, token1Amount: 0 },
  {
    price: 0.00015899999999999996,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  {
    price: 0.00016199999999999998,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  {
    price: 0.00016499999999999997,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  { price: 0.000168, token0Amount: 1035.194601, token1Amount: 0 },
  {
    price: 0.00017099999999999998,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  { price: 0.000174, token0Amount: 1035.194601, token1Amount: 0 },
  {
    price: 0.00017699999999999997,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  {
    price: 0.00017999999999999998,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  {
    price: 0.00018299999999999998,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  { price: 0.000186, token0Amount: 1035.194601, token1Amount: 0 },
  {
    price: 0.00018899999999999996,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  {
    price: 0.00019199999999999998,
    token0Amount: 1035.194601,
    token1Amount: 0,
  },
  { price: 0.000195, token0Amount: 1035.194601, token1Amount: 0 },
  { price: 0.000198, token0Amount: 1035.194601, token1Amount: 0 },
  {
    price: 0.000201,
    token0Amount: 1026.77128,
    token1Amount: 0.001689053477274831,
  },
  {
    price: 0.00020399999999999997,
    token0Amount: 1000.74341,
    token1Amount: 0.00695955239366857,
  },
  {
    price: 0.000207,
    token0Amount: 975.283435,
    token1Amount: 0.012191438036291502,
  },
  {
    price: 0.00020999999999999998,
    token0Amount: 950.370997,
    token1Amount: 0.017385546848855498,
  },
  {
    price: 0.000213,
    token0Amount: 925.986749,
    token1Amount: 0.02254268550570478,
  },
  {
    price: 0.00021599999999999996,
    token0Amount: 902.112288,
    token1Amount: 0.027663632374337,
  },
  {
    price: 0.00021899999999999998,
    token0Amount: 878.730092,
    token1Amount: 0.03274913888682067,
  },
  {
    price: 0.00022199999999999998,
    token0Amount: 855.823476,
    token1Amount: 0.03779993082695056,
  },
  {
    price: 0.000225,
    token0Amount: 833.376534,
    token1Amount: 0.04281670953938357,
  },
  {
    price: 0.00022799999999999999,
    token0Amount: 811.374093,
    token1Amount: 0.04780015306645854,
  },
  {
    price: 0.00023099999999999998,
    token0Amount: 789.801675,
    token1Amount: 0.052750917217916755,
  },
  {
    price: 0.00023399999999999997,
    token0Amount: 768.645453,
    token1Amount: 0.05766963657830129,
  },
  {
    price: 0.000237,
    token0Amount: 747.892213,
    token1Amount: 0.0625569254564162,
  },
  {
    price: 0.00023999999999999998,
    token0Amount: 727.529322,
    token1Amount: 0.06741337878086695,
  },
  {
    price: 0.000243,
    token0Amount: 707.544696,
    token1Amount: 0.07223957294537835,
  },
  {
    price: 0.00024599999999999996,
    token0Amount: 687.926766,
    token1Amount: 0.07703606660728966,
  },
  {
    price: 0.000249,
    token0Amount: 668.664453,
    token1Amount: 0.08180340144235858,
  },
  {
    price: 0.00025199999999999995,
    token0Amount: 649.747142,
    token1Amount: 0.08654210285876075,
  },
  {
    price: 0.00025499999999999996,
    token0Amount: 631.164656,
    token1Amount: 0.09125268067294917,
  },
  {
    price: 0.000258,
    token0Amount: 612.907232,
    token1Amount: 0.09593562974983409,
  },
  {
    price: 0.000261,
    token0Amount: 594.965503,
    token1Amount: 0.10059143060955918,
  },
  {
    price: 0.00026399999999999997,
    token0Amount: 577.330475,
    token1Amount: 0.10522055000297961,
  },
  {
    price: 0.000267,
    token0Amount: 559.993507,
    token1Amount: 0.10982344145779316,
  },
  {
    price: 0.00026999999999999995,
    token0Amount: 542.946298,
    token1Amount: 0.11440054579713274,
  },
  {
    price: 0.00027299999999999997,
    token0Amount: 526.180864,
    token1Amount: 0.11895229163229887,
  },
  {
    price: 0.000276,
    token0Amount: 509.689527,
    token1Amount: 0.12347909583119106,
  },
  {
    price: 0.00027899999999999995,
    token0Amount: 493.464901,
    token1Amount: 0.12798136396388685,
  },
  {
    price: 0.00028199999999999997,
    token0Amount: 477.499871,
    token1Amount: 0.13245949072671684,
  },
  {
    price: 0.000285,
    token0Amount: 461.787589,
    token1Amount: 0.13691386034609018,
  },
  {
    price: 0.000288,
    token0Amount: 446.321455,
    token1Amount: 0.1413448469632406,
  },
  {
    price: 0.00029099999999999997,
    token0Amount: 431.095109,
    token1Amount: 0.1457528150009826,
  },
  {
    price: 0.000294,
    token0Amount: 416.102418,
    token1Amount: 0.1501381195134959,
  },
  {
    price: 0.00029699999999999996,
    token0Amount: 401.337466,
    token1Amount: 0.15450110652008786,
  },
  {
    price: 0.0003,
    token0Amount: 386.794546,
    token1Amount: 0.15884211332382223,
  },
  {
    price: 0.000303,
    token0Amount: 372.468148,
    token1Amount: 0.1631614688158441,
  },
  {
    price: 0.000306,
    token0Amount: 358.352953,
    token1Amount: 0.16745949376617864,
  },
  {
    price: 0.000309,
    token0Amount: 344.44382,
    token1Amount: 0.17173650110173086,
  },
  {
    price: 0.000312,
    token0Amount: 330.735786,
    token1Amount: 0.17599279617216826,
  },
  {
    price: 0.000315,
    token0Amount: 317.224049,
    token1Amount: 0.180228677004326,
  },
  {
    price: 0.0003179999999999999,
    token0Amount: 303.903971,
    token1Amount: 0.18444443454573398,
  },
  {
    price: 0.00032099999999999994,
    token0Amount: 290.771061,
    token1Amount: 0.18864035289782943,
  },
  {
    price: 0.00032399999999999996,
    token0Amount: 277.820978,
    token1Amount: 0.19281670953938357,
  },
  {
    price: 0.000327,
    token0Amount: 265.049518,
    token1Amount: 0.1969737755406398,
  },
  {
    price: 0.00032999999999999994,
    token0Amount: 252.452613,
    token1Amount: 0.20111181576863107,
  },
  {
    price: 0.00033299999999999996,
    token0Amount: 240.026322,
    token1Amount: 0.20523108908411652,
  },
  {
    price: 0.000336,
    token0Amount: 227.766828,
    token1Amount: 0.20933184853055156,
  },
  {
    price: 0.000339,
    token0Amount: 215.670433,
    token1Amount: 0.21341434151548203,
  },
  {
    price: 0.00034199999999999996,
    token0Amount: 203.733552,
    token1Amount: 0.21747880998473004,
  },
  {
    price: 0.000345,
    token0Amount: 191.952711,
    token1Amount: 0.22152549058971907,
  },
  {
    price: 0.000348,
    token0Amount: 180.324537,
    token1Amount: 0.22555461484826508,
  },
  {
    price: 0.00035099999999999997,
    token0Amount: 168.845764,
    token1Amount: 0.2295664092991433,
  },
  {
    price: 0.00035399999999999993,
    token0Amount: 157.513218,
    token1Amount: 0.23356109565072253,
  },
  {
    price: 0.00035699999999999995,
    token0Amount: 146.32382,
    token1Amount: 0.23753889092394279,
  },
  {
    price: 0.00035999999999999997,
    token0Amount: 135.274584,
    token1Amount: 0.24150000758989737,
  },
  {
    price: 0.000363,
    token0Amount: 124.362606,
    token1Amount: 0.2454446537022661,
  },
  {
    price: 0.00036599999999999995,
    token0Amount: 113.585068,
    token1Amount: 0.24937303302483313,
  },
  {
    price: 0.00036899999999999997,
    token0Amount: 102.939232,
    token1Amount: 0.2532853451543109,
  },
  {
    price: 0.000372,
    token0Amount: 92.422437,
    token1Amount: 0.25718178563867905,
  },
  {
    price: 0.000375,
    token0Amount: 82.032098,
    token1Amount: 0.2610625460912378,
  },
  {
    price: 0.0003779999999999999,
    token0Amount: 71.7657,
    token1Amount: 0.2649278143005626,
  },
  {
    price: 0.00038099999999999994,
    token0Amount: 61.620798,
    token1Amount: 0.2687777743365403,
  },
  {
    price: 0.00038399999999999996,
    token0Amount: 51.595016,
    token1Amount: 0.2726126066526548,
  },
  {
    price: 0.000387,
    token0Amount: 41.686039,
    token1Amount: 0.2764324881846837,
  },
  {
    price: 0.00039,
    token0Amount: 31.891618,
    token1Amount: 0.2802375924459585,
  },
  {
    price: 0.00039299999999999996,
    token0Amount: 22.209562,
    token1Amount: 0.284028089619334,
  },
  {
    price: 0.000396,
    token0Amount: 12.637738,
    token1Amount: 0.2878041466460035,
  },
  {
    price: 0.000399,
    token0Amount: 3.174072,
    token1Amount: 0.2915659273112925,
  },
  { price: 0.000402, token0Amount: 0, token1Amount: 0.2928339901634213 },
  {
    price: 0.0004049999999999999,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  {
    price: 0.00040799999999999994,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  {
    price: 0.00041099999999999996,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  { price: 0.000414, token0Amount: 0, token1Amount: 0.2928339901634213 },
  {
    price: 0.00041699999999999994,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  {
    price: 0.00041999999999999996,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  { price: 0.000423, token0Amount: 0, token1Amount: 0.2928339901634213 },
  { price: 0.000426, token0Amount: 0, token1Amount: 0.2928339901634213 },
  {
    price: 0.0004289999999999999,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  {
    price: 0.00043199999999999993,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  {
    price: 0.00043499999999999995,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  {
    price: 0.00043799999999999997,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  {
    price: 0.00044099999999999993,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  {
    price: 0.00044399999999999995,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  {
    price: 0.00044699999999999997,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  { price: 0.00045, token0Amount: 0, token1Amount: 0.2928339901634213 },
  {
    price: 0.00045299999999999995,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  {
    price: 0.00045599999999999997,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  { price: 0.000459, token0Amount: 0, token1Amount: 0.2928339901634213 },
  {
    price: 0.00046199999999999995,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  {
    price: 0.0004649999999999999,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  {
    price: 0.00046799999999999994,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  {
    price: 0.00047099999999999996,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  { price: 0.000474, token0Amount: 0, token1Amount: 0.2928339901634213 },
  {
    price: 0.00047699999999999994,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  {
    price: 0.00047999999999999996,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  { price: 0.000483, token0Amount: 0, token1Amount: 0.2928339901634213 },
  { price: 0.000486, token0Amount: 0, token1Amount: 0.2928339901634213 },
  { price: 0.000489, token0Amount: 0, token1Amount: 0.2928339901634213 },
  {
    price: 0.0004919999999999999,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  { price: 0.000495, token0Amount: 0, token1Amount: 0.2928339901634213 },
  { price: 0.000498, token0Amount: 0, token1Amount: 0.2928339901634213 },
  { price: 0.000501, token0Amount: 0, token1Amount: 0.2928339901634213 },
  {
    price: 0.0005039999999999999,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  { price: 0.000507, token0Amount: 0, token1Amount: 0.2928339901634213 },
  {
    price: 0.0005099999999999999,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  { price: 0.000513, token0Amount: 0, token1Amount: 0.2928339901634213 },
  { price: 0.000516, token0Amount: 0, token1Amount: 0.2928339901634213 },
  {
    price: 0.0005189999999999999,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  { price: 0.000522, token0Amount: 0, token1Amount: 0.2928339901634213 },
  { price: 0.000525, token0Amount: 0, token1Amount: 0.2928339901634213 },
  {
    price: 0.0005279999999999999,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  {
    price: 0.0005309999999999999,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  { price: 0.000534, token0Amount: 0, token1Amount: 0.2928339901634213 },
  {
    price: 0.0005369999999999999,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  {
    price: 0.0005399999999999999,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  { price: 0.000543, token0Amount: 0, token1Amount: 0.2928339901634213 },
  {
    price: 0.0005459999999999999,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  { price: 0.000549, token0Amount: 0, token1Amount: 0.2928339901634213 },
  { price: 0.000552, token0Amount: 0, token1Amount: 0.2928339901634213 },
  {
    price: 0.0005549999999999999,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  {
    price: 0.0005579999999999999,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  { price: 0.000561, token0Amount: 0, token1Amount: 0.2928339901634213 },
  {
    price: 0.0005639999999999999,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  {
    price: 0.0005669999999999999,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  { price: 0.00057, token0Amount: 0, token1Amount: 0.2928339901634213 },
  {
    price: 0.0005729999999999999,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  { price: 0.000576, token0Amount: 0, token1Amount: 0.2928339901634213 },
  { price: 0.000579, token0Amount: 0, token1Amount: 0.2928339901634213 },
  {
    price: 0.0005819999999999999,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  { price: 0.000585, token0Amount: 0, token1Amount: 0.2928339901634213 },
  { price: 0.000588, token0Amount: 0, token1Amount: 0.2928339901634213 },
  {
    price: 0.0005909999999999999,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  {
    price: 0.0005939999999999999,
    token0Amount: 0,
    token1Amount: 0.2928339901634213,
  },
  { price: 0.000597, token0Amount: 0, token1Amount: 0.2928339901634213 },
  { price: 0.0006, token0Amount: 0, token1Amount: 0.2928339901634213 },
];

import { LimitedOrderDetails } from "@/config/order/orderConfig";
import { ITokenInfo } from "@/config/position/positionConfig";
import { getTokensFromInfo, tokenToTokenInfo } from "@/helper/tokenHelper";
import { TMutateWarningTokens } from "@/hooks/globalState/useWarningTokens";
import { getAutomanAPI, getTokenMap } from "@/utils/helper";
import {
  ActionTypeEnum,
  ApertureSupportedChainId,
  AutomanClient,
  ClientTypeEnum,
  CreateTriggerPayload,
  CreateTriggerRequest,
  DeleteTriggerPayload,
  DeleteTriggerRequest,
  getTokenValueProportionFromPriceRatio,
  ListTriggerRequest,
  ListTriggerResponse,
  parsePrice,
  PermitInfo,
  PriceCondition,
  TimeCondition,
  TriggerItem,
  UpdateTriggerPayload,
  UpdateTriggerRequest,
  viem,
} from "@aperture_finance/uniswap-v3-automation-sdk";
import { ITriggerSetupForm } from "@ui/components";
import { DEFAULT_TIME_BUFFER } from "@ui/components/Strategy/utils";
import { AmmEnum } from "@ui/utils";
import { trimNumber } from "@uiv2/utils/numberFormat";
import { Token } from "@uniswap/sdk-core";
import Big from "big.js";
import bigDecimal from "js-big-decimal";
import moment from "moment";
import { Address, formatUnits, PublicClient } from "viem";
import { getOrderStatus } from "./limitOrderHelper";
import { checkPositionPermission } from "./positionHelper";

export enum TriggerStatus {
  CREATED = "CREATED",
  STARTED = "STARTED",
  COMPLETED = "COMPLETED",
  INVALID = "INVALID",
  DELETED = "DELETED",
}

export const getTriggers = async (
  amm: AmmEnum,
  walletAddress: Address,
  chainId: ApertureSupportedChainId,
  isLimitOrder: boolean
): Promise<ListTriggerResponse> => {
  const autoClient = new AutomanClient(getAutomanAPI());
  const request: ListTriggerRequest = {
    ownerAddr: walletAddress,
    chainId,
    amm,
    isLimitOrder,
    clientType: ClientTypeEnum.enum.FRONTEND,
  };
  return autoClient.listTrigger(request);
};

export const getTriggerType = (
  trigger: TriggerItem | CreateTriggerPayload | UpdateTriggerPayload | undefined
) => {
  if (!trigger) return "Price";
  else if (trigger.condition.type === "Time") {
    return "Time";
  } else {
    const frontendType = (trigger.condition as PriceCondition)?.frontendType;
    const singleToken = (trigger.condition as PriceCondition)?.singleToken;
    return (frontendType === "RELATIVE_PRICE" && singleToken === undefined) ||
      (frontendType === undefined && singleToken !== undefined)
      ? "Price"
      : "Ratio";
  }
};

export async function createTrigger(
  payload: CreateTriggerPayload,
  signature: string,
  permitInfo?: PermitInfo
) {
  const autoClient = new AutomanClient(getAutomanAPI());
  const request: CreateTriggerRequest = {
    payloadSignature: signature,
    payload: { ...payload, clientType: ClientTypeEnum.enum.FRONTEND },
    permitInfo,
  };
  return autoClient.createTrigger(request);
}

export async function deleteTrigger(
  payload: DeleteTriggerPayload,
  payloadSignature: string
) {
  const autoClient = new AutomanClient(getAutomanAPI());
  const request: DeleteTriggerRequest = {
    payload: { ...payload, clientType: ClientTypeEnum.enum.FRONTEND },
    payloadSignature,
  };
  return autoClient.deleteTrigger(request);
}

export async function updateTrigger(
  payload: UpdateTriggerPayload,
  payloadSignature: string
) {
  const autoClient = new AutomanClient(getAutomanAPI());
  const request: UpdateTriggerRequest = {
    payload: { ...payload, clientType: ClientTypeEnum.enum.FRONTEND },
    payloadSignature,
  };
  return autoClient.updateTrigger(request);
}

export function filterTriggers(
  triggerObj: ListTriggerResponse,
  actionTypes: ActionTypeEnum | ActionTypeEnum[],
  positionId?: string
): TriggerItem[] {
  if (!triggerObj?.triggers) return [];
  if (!Array.isArray(actionTypes)) {
    actionTypes = [actionTypes];
  }
  return triggerObj.triggers.filter(
    (trigger) =>
      actionTypes.includes(trigger.action.type) &&
      trigger.status !== TriggerStatus.DELETED &&
      (!positionId || trigger.nftId === positionId)
  );
}

export const triggerToLimitOrder = async (
  triggers: TriggerItem[],
  chainId: number,
  ammEnum: AmmEnum,
  mutateWarningTokens: TMutateWarningTokens,
  client: PublicClient
): Promise<LimitedOrderDetails[]> => {
  let tokenMap = getTokenMap(chainId);

  // In case the user change browser and the warning tokens in local storage are gone
  const missingTokens: Promise<Token>[] = [];
  const missingAddresses: string[] = [];
  for (const trigger of triggers) {
    const sellTokenAddress = trigger.limitOrderInfo!.inputTokenAmountAtCreation
      .address as Address;
    const buyTokenAddress = trigger.limitOrderInfo!.outputTokenAmountAtClosure
      .address as Address;
    if (
      !tokenMap.get(sellTokenAddress) &&
      !missingAddresses.includes(sellTokenAddress)
    ) {
      missingTokens.push(
        viem.getToken(sellTokenAddress, chainId, client, undefined, true)
      );
      missingAddresses.push(sellTokenAddress);
    } else if (
      !tokenMap.get(buyTokenAddress) &&
      !missingAddresses.includes(buyTokenAddress)
    ) {
      missingTokens.push(
        viem.getToken(buyTokenAddress, chainId, client, undefined, true)
      );
      missingAddresses.push(buyTokenAddress);
    }
  }
  if (missingAddresses.length > 0) {
    mutateWarningTokens(chainId, await Promise.all(missingTokens));
    tokenMap = getTokenMap(chainId);
  }

  const limitOrders = triggers.map(async (trigger: TriggerItem) => {
    const positionInfo = await viem.getPosition(
      chainId,
      ammEnum,
      BigInt(trigger.nftId),
      client
    );

    const sellToken = tokenMap.get(
      trigger.limitOrderInfo!.inputTokenAmountAtCreation.address
    );
    const buyToken = tokenMap.get(
      trigger.limitOrderInfo!.outputTokenAmountAtClosure.address
    );
    const sellAmount = formatUnits(
      BigInt(trigger.limitOrderInfo!.inputTokenAmountAtCreation.rawAmount),
      sellToken.decimals
    );
    const buyAmount = formatUnits(
      BigInt(trigger.limitOrderInfo!.outputTokenAmountAtClosure.rawAmount),
      buyToken.decimals
    );
    const sellTokenIsToken0 =
      sellToken.address === positionInfo.pool.token0.address;

    let approvalStatus = true;
    if (
      trigger.status === TriggerStatus.CREATED ||
      trigger.status === TriggerStatus.STARTED
    ) {
      try {
        approvalStatus = !!(await checkPositionPermission(
          ammEnum,
          chainId,
          trigger.nftId
        ));
      } catch (error) {
        console.log(
          "Failed to checkPositionPermission on position",
          trigger.nftId,
          error
        );
      }
    }

    return {
      orderId: trigger.taskId,
      nftId: trigger.nftId,
      chainId: chainId,
      tokenSell: tokenToTokenInfo(sellToken),
      tokenBuy: tokenToTokenInfo(buyToken),
      sellAmount: sellAmount,
      buyAmount: buyAmount,
      buyRatio:
        sellAmount === "0" ? "0" : bigDecimal.divide(buyAmount, sellAmount, 10),
      feeTier: trigger.limitOrderInfo.feeTier,
      amountMinimum: sellTokenIsToken0
        ? positionInfo.token0PriceLower.toFixed(18)
        : positionInfo.token0PriceUpper.invert().toFixed(18),
      amountMaximum: sellTokenIsToken0
        ? positionInfo.token0PriceUpper.toFixed(18)
        : positionInfo.token0PriceLower.invert().toFixed(18),
      status: getOrderStatus(trigger.status as TriggerStatus, approvalStatus),
      timestamp: trigger.expiration,
      feeRevenue: {
        earnedFeeInputToken: trigger.limitOrderInfo?.earnedFeeInputToken,
        earnedFeeOutputToken: trigger.limitOrderInfo?.earnedFeeOutputToken,
      },
      notification: trigger?.lastFailedMessage,
    };
  });
  return Promise.all(limitOrders);
};

export function getCreateTriggerFailure(err) {
  return err?.response?.data ?? err;
}

export const getTag = (
  triggerObj: ListTriggerResponse,
  actionType: ActionTypeEnum | ActionTypeEnum[],
  positionId: string
) => {
  if (triggerObj?.triggers.length) {
    const now = moment().valueOf();
    const triggerList = filterTriggers(triggerObj, actionType).filter(
      (trigger) => trigger.nftId === positionId
    );
    const index = triggerList.findIndex(
      (trigger) => moment(trigger.expiration * 1000).valueOf() < now
    );
    if (triggerList.length === 0) return undefined;
    else if (index === -1) return true;
    else return false;
  } else return undefined;
};

export const getTriggerCondition = (
  trigger: ITriggerSetupForm,
  tokenA: ITokenInfo,
  tokenB: ITokenInfo,
  tickLower: number,
  tickUpper: number,
  networkId: ApertureSupportedChainId,
  isRebalance: boolean
): PriceCondition | TimeCondition => {
  switch (trigger.type) {
    case "Time": {
      return {
        type: "Time",
        timeAfterEpochSec: moment(trigger.Time.dateTime).unix(),
      } as TimeCondition;
    }
    case "Ratio": {
      return {
        ...viem.generatePriceConditionFromTokenValueProportion(
          tickLower,
          tickUpper,
          trigger.Ratio.token.address === tokenA.address
            ? trigger.Ratio.aboveOrBelow === "Above"
            : trigger.Ratio.aboveOrBelow === "Below",
          trigger.Ratio.token.address === tokenA.address
            ? trigger.Ratio.percentage
            : 1 - trigger.Ratio.percentage
        ),
        frontendType: "POSITION_VALUE_RATIO",
        durationSec:
          trigger.Ratio.timeBufferChecked && trigger.Ratio.timeBuffer
            ? parseFloat(bigDecimal.multiply(trigger.Ratio.timeBuffer, 3600))
            : undefined,
      } as PriceCondition;
    }
    default: {
      const { token0, token1 } = getTokensFromInfo(tokenA, tokenB, networkId);
      let relativePrice = new Big(0);
      let invertPrice = new Big(0);
      if (bigDecimal.compareTo(trigger.Price.amount, 0) !== 0) {
        const price = parsePrice(
          trigger.Price.token.address === tokenA.address ? token0 : token1,
          trigger.Price.token.address === tokenA.address ? token1 : token0,
          bigDecimal.multiply(trigger.Price.amount, "1.0")
        );
        relativePrice = new Big(
          bigDecimal.divide(
            price.numerator.toString(),
            price.denominator.toString(),
            18
          )
        );
        invertPrice = new Big(
          bigDecimal.divide(
            price.invert().numerator.toString(),
            price.invert().denominator.toString(),
            18
          )
        );
      }
      const isLte =
        (trigger.Price.aboveOrBelow === "Above" &&
          trigger.Price.token.ticker === tokenB.ticker) ||
        (trigger.Price.aboveOrBelow === "Below" &&
          trigger.Price.token.ticker === tokenA.ticker);
      return {
        type: "Price",
        frontendType: "RELATIVE_PRICE",
        [isLte ? "lte" : "gte"]: (trigger.Price.token.ticker === tokenA.ticker
          ? relativePrice
          : invertPrice
        ).toString(),
        durationSec:
          isRebalance &&
          trigger.Price.timeBufferChecked &&
          trigger.Price.timeBuffer
            ? parseFloat(bigDecimal.multiply(trigger.Price.timeBuffer, 3600))
            : undefined,
      } as PriceCondition;
    }
  }
};

const getEditRatio = (
  trigger: TriggerItem | undefined,
  tickLower: number,
  tickUpper: number
) => {
  const price = new Big(
    (trigger.condition as PriceCondition).lte ||
      (trigger.condition as PriceCondition).gte ||
      "0"
  );
  try {
    const ratio = getTokenValueProportionFromPriceRatio(
      tickLower,
      tickUpper,
      price
    ).toFixed(4);
    return parseFloat(ratio);
  } catch (e) {
    console.log(e);
    return 0;
  }
};

export const getInitializedPriceTrigger = (
  trigger: TriggerItem | undefined,
  tokenA: ITokenInfo,
  tokenB: ITokenInfo
) => {
  const condition = trigger?.condition as PriceCondition;
  return {
    token:
      getTriggerType(trigger) === "Price" && condition?.singleToken === 1
        ? tokenB
        : tokenA,
    aboveOrBelow:
      getTriggerType(trigger) === "Price" && condition?.lte !== undefined
        ? "Below"
        : "Above",
    amount:
      getTriggerType(trigger) === "Price" &&
      condition?.singleToken === undefined
        ? condition?.gte || condition?.lte || "0"
        : "0",
  };
};

export const getInitializedRatioTrigger = (
  trigger: TriggerItem | undefined,
  tokenA: ITokenInfo,
  tickLower: number,
  tickUpper: number
) => {
  const condition = trigger?.condition as PriceCondition;
  return {
    token: tokenA,
    aboveOrBelow:
      getTriggerType(trigger) === "Ratio" && condition?.lte !== undefined
        ? "Above"
        : "Below",
    percentage:
      getTriggerType(trigger) === "Ratio"
        ? getEditRatio(trigger, tickLower, tickUpper)
        : 0.5,
  };
};

export const getInitializedTimeTrigger = (trigger: TriggerItem | undefined) => {
  return {
    dateTime: moment(
      trigger?.condition?.type === "Time"
        ? trigger.condition.timeAfterEpochSec * 1000
        : new Date().setDate(new Date().getDate() + 7)
    ).format("MMMM DD, yyyy hh:mm a"),
  };
};

export const getInitializedTriggerTimeBuffer = (
  trigger: TriggerItem | undefined
) => {
  return {
    timeBuffer:
      trigger?.condition?.type === "Price" && trigger?.condition?.durationSec
        ? parseFloat(
            trimNumber(
              bigDecimal.divide(trigger?.condition?.durationSec, 3600, 8)
            )
          )
        : DEFAULT_TIME_BUFFER,
    timeBufferChecked: !!(
      trigger?.condition?.type === "Price" && trigger?.condition?.durationSec
    ),
  };
};

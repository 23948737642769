import { useLocalStorageState } from "ahooks";
import { Message } from "ai/react";

type ChatRecord = {
  threadId: string;
  messages: Message[];
  date: number;
};

type BotHistory = Record<string, ChatRecord>;

export const useHistoryChatStorage = (address: string) => {
  const storageKey = `botHistory-${address}`;
  const [chats, setChats] = useLocalStorageState<BotHistory>(storageKey, {
    defaultValue: {},
  });
  return {
    chats,
    setChats,
  };
};

import { IPositionDetails } from "@/config/position/positionConfig";
import { StrategyPage } from "@/views/Strategy/Detail/types";
import { PositionPage } from "@/views/types";
import { ReactNode, createContext, useContext, useState } from "react";

interface PageContextType {
  position: IPositionDetails;
  setPosition: (position: IPositionDetails) => void;
  positionPage: PositionPage;
  setPositionPage: (page: PositionPage) => void;
  strategyPage: StrategyPage;
  setStrategyPage: (page: StrategyPage) => void;
}

const PageContext = createContext<PageContextType>({
  position: null,
  setPosition: () => {},
  positionPage: "details",
  setPositionPage: () => {},
  strategyPage: "details",
  setStrategyPage: () => {},
});

export const usePage = () => {
  const context = useContext(PageContext);
  if (!context) {
    throw new Error("usePage must be used within a PageContext.Provider");
  }
  return context;
};

interface PageProviderProps {
  children: ReactNode;
}

export const PageProvider: React.FC<PageProviderProps> = ({ children }) => {
  const [positionPage, setPositionPage] = useState<PositionPage>("details");
  const [strategyPage, setStrategyPage] = useState<StrategyPage>("details");
  const [position, setPosition] = useState<IPositionDetails>(null);

  return (
    <PageContext.Provider
      value={{
        position,
        setPosition,
        positionPage,
        setPositionPage,
        strategyPage,
        setStrategyPage,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export interface IColonProps {
  showColon: boolean;
  variant: CountDownVariant;
}
export interface ICountdownProps {
  timestamp: number;
  title: string;
  isStart: boolean;
  isDaily: boolean;
  variant?: CountDownVariant;
  isEnded?: boolean;
}
export interface ICountDownFormat {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}
export interface INumberCard {
  dateNum: number;
  dateText: DATE_STR_CAMPAIGN;
  variant: CountDownVariant;
}
export enum DATE_STR_CAMPAIGN {
  DAYS = "D",
  HOURS = "H",
  MINUTES = "M",
  SECONDS = "S",
}
export const countDownVariants = {
  FIXED: "fixed",
  PRIMARY: "primary",
  SECONDARY: "secondary",
} as const;
export type CountDownVariant =
  (typeof countDownVariants)[keyof typeof countDownVariants];

import { CopyFileIcon } from "@aperture/assetkit";
import { Flex, FlexColumn } from "@uiv2/common";
import { useCopyText } from "@uiv2/utils/hooks";
import { formatFixed } from "@uiv2/utils/numberFormat";
import { useTheme } from "styled-components";
import { PositionInfoTitle } from "../PositionInfoTitle";
import { StakeTag } from "../Tag";
import { Fees } from "./Fees";
import { Status } from "./Status";
import { SwapRate } from "./SwapRate";
import {
  APR,
  APRContainer,
  APRText,
  AnalyticsInfo,
  ChainInfo,
  ClosedPosContainer,
  ClosedPosID,
  CopyWrapper,
  CurrentChainDot,
  Dot,
  IconWrapper,
  MoreIconWrapper,
  PositionInfo,
  StyledInRangeIcon,
  StyledMoreIcon,
  StyledPercentage,
  StyledRangeTag,
  StyledSubtitle2,
  StyledT4,
  T1,
  T3,
  Wrapper,
} from "./style";
import { PositionCardProps } from "./types";

export const LgPositionCard: React.FC<
  Omit<PositionCardProps, "id" | "chainId" | "supportedChainId"> & {
    Icon: any;
    name: string;
    isExplorerCard?: boolean;
  }
> = ({
  positionId,
  tokens,
  feeTier,
  liquidity,
  fees,
  priceRange,
  tokenPercentages,
  totalAPR,
  feeAPR,
  positionHistory = [],
  autoCompound = undefined,
  inRange,
  rebalanced,
  recurringRebalanced,
  prescheduledPosition,
  limitOrder,
  closed,
  closedInfo,
  atStake,
  sm = false,
  isCurrentChain,
  Icon,
  name,
  isExplorerCard,
}) => {
  const { hasCopied, handleCopyAddress } = useCopyText(positionId);
  const theme = useTheme();
  return (
    <Wrapper gap="lg">
      <PositionInfo sm={sm}>
        <Flex gap="lg" alignItems="center">
          <Flex gap="md" alignItems="end">
            <T3>Position ID</T3>
            <T1>#{positionId}</T1>
            <CopyWrapper onClick={handleCopyAddress}>
              {hasCopied ? (
                <StyledInRangeIcon width={16} height={16} />
              ) : (
                <CopyFileIcon
                  width={16}
                  height={16}
                  fill={theme.colors.global.text.T1}
                />
              )}
            </CopyWrapper>
          </Flex>
          {!isExplorerCard && (
            <Status
              {...{
                rebalanced,
                recurringRebalanced,
                prescheduledPosition,
                limitOrder,
                position: { left: 0, bottom: -40 },
              }}
            />
          )}
        </Flex>
        <Flex gap={15} alignItems="center">
          <StyledRangeTag closed={Boolean(closed)} inRange={inRange} />
          {atStake && <StakeTag />}
          <ChainInfo gap="sm" alignItems="center">
            {isCurrentChain && <CurrentChainDot />}
            <Icon width={16} height={16} />
            <T1>{name}</T1>
          </ChainInfo>
        </Flex>
      </PositionInfo>
      <PositionInfo sm={sm}>
        <PositionInfoTitle
          padding="0 8px"
          fontSize="12px"
          lineHeight="20px"
          size={20}
          {...{
            tokens,
            feeTier,
            autoCompound,
            tagVariant: "rise",
            wordOnly: true,
          }}
        />
        {closed ? (
          <Fees
            {...{
              liquidity: closedInfo?.exitedValueUsd ?? "-",
              fees: closedInfo?.exitedCollectedIncomeUsd ?? "-",
              closed: true,
            }}
          />
        ) : (
          <Fees {...{ liquidity, fees }} />
        )}
      </PositionInfo>
      <FlexColumn gap="md">
        {closed && closedInfo ? (
          <StyledSubtitle2>Closed at {closedInfo.dateTime}</StyledSubtitle2>
        ) : (
          <PositionInfo sm={sm}>
            <SwapRate {...{ sm, tokens, priceRange }} />
            <Flex gap="sm2" alignItems="center">
              {tokenPercentages.map((percentage, index) => (
                <Flex gap="sm2" alignItems="center" key={index}>
                  <IconWrapper>{tokens[index].Icon}</IconWrapper>
                  <StyledSubtitle2>{tokens[index].ticker}</StyledSubtitle2>
                  <StyledPercentage>{percentage}%</StyledPercentage>
                  {index === 0 && (
                    <StyledPercentage padding={"0 1px 1px"}>|</StyledPercentage>
                  )}
                </Flex>
              ))}
            </Flex>
          </PositionInfo>
        )}
        {(totalAPR || feeAPR || !isExplorerCard) && (
          <AnalyticsInfo sm={sm}>
            <APRContainer gap="md" sm={sm}>
              {totalAPR && (
                <APR sm={sm} ta="left">
                  <T3>Total APR</T3>
                  <APRText isPositive={Number(totalAPR) >= 0}>
                    {formatFixed(totalAPR, 2)}%
                  </APRText>
                </APR>
              )}
              {feeAPR && (
                <APR sm={sm} ta="right">
                  <T3>Fee APR</T3>
                  <APRText isPositive={Number(feeAPR) >= 0}>
                    {formatFixed(feeAPR, 2)}%
                  </APRText>
                </APR>
              )}
            </APRContainer>
            {!isExplorerCard && (
              <ClosedPosContainer
                sm={sm}
                gap="md"
                alignItems="center"
                justifyContent="space-between"
              >
                <T3>Rebalance History ({positionHistory.length})</T3>
                {positionHistory.length > 0 && (
                  <Flex gap={6}>
                    {positionHistory
                      .slice(0, 2)
                      .map(({ positionId, isActive }) => (
                        <ClosedPosID key={positionId} alignItems="center">
                          <Dot isActive={isActive} />
                          <StyledT4>#{positionId}</StyledT4>
                        </ClosedPosID>
                      ))}
                    {positionHistory.length > 2 && (
                      <MoreIconWrapper>
                        <StyledMoreIcon width={16} />
                      </MoreIconWrapper>
                    )}
                  </Flex>
                )}
              </ClosedPosContainer>
            )}
          </AnalyticsInfo>
        )}
      </FlexColumn>
    </Wrapper>
  );
};

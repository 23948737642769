import { Button, Spinner } from "@aperture/uikit";
import { useCallback } from "react";
import styled from "styled-components";
import { useAOStore } from "../GlobalStore";

const ButtonBox = styled(Button)`
  background: ${({ theme }) => theme.colors.global.primary};
  color: ${({ theme }) => theme.colors.global.textAlt.T1};
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  :hover {
    opacity: 0.8;
  }
`;

const AuthorizationBtn: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => {
  const { ifAuthorized, safeToggleAOStatus, isAOStatusLoading } = useAOStore();

  const handleClick = useCallback(() => {
    safeToggleAOStatus().then(() => {
      onClick();
    });
  }, [onClick, safeToggleAOStatus]);

  return (
    <ButtonBox
      width="240px"
      variant="contained"
      size="lg"
      onClick={handleClick}
      disabled={isAOStatusLoading || ifAuthorized === undefined}
    >
      {isAOStatusLoading || ifAuthorized === undefined ? (
        <Spinner />
      ) : ifAuthorized ? (
        "Revoke Authorization"
      ) : (
        "Authorize Operator"
      )}
    </ButtonBox>
  );
};

export default AuthorizationBtn;

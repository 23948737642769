import { useEffect, useState } from "react";
import {
  TabBar,
  TabItem,
  TabSlider,
  Wrapper,
  getTabWidths,
  pillTypeTabs,
  tabTextStyles,
} from "./styles";
import { Edge, TabProps } from "./types";

// TODO:
// - styles cleanup

export const Tab: React.FC<TabProps> = ({
  size = "lg",
  type = "pill",
  tabs = ["", ""],
  selectedIdx,
  onSelect,
  ...props
}) => {
  const [fontLoading, setFontLoading] = useState(true);
  useEffect(() => {
    document.fonts.ready.then(() => setFontLoading(false));
  }, []);

  const isSwitchTab = type === "switch";
  const isPillType = pillTypeTabs.includes(type);

  const widths = getTabWidths(size, type, tabs);
  const suffix = !isPillType ? "%" : "px";
  const sliderPositon = widths
    .slice(0, selectedIdx)
    .reduce((acc, w) => acc + w, 0);

  const edge: Edge | undefined =
    selectedIdx === 0
      ? "left"
      : selectedIdx === tabs.length - 1
      ? "right"
      : undefined;

  const toggleSwitch = () => {
    return selectedIdx === 0 ? 1 : 0;
  };
  const isOn = isSwitchTab && selectedIdx === 1;

  return (
    <Wrapper type={type} size={size} isOn={isOn} {...props}>
      <TabBar type={type}>
        {!fontLoading && (
          <TabSlider
            type={type}
            size={size}
            isOn={isOn}
            width={`${widths[selectedIdx]}${suffix}`}
            height={isSwitchTab ? "16px" : undefined}
            edge={edge}
            style={{
              left: `${sliderPositon}${suffix}`,
            }}
          />
        )}
        {tabs.map((item, index) => (
          <TabItem
            key={`${index}-${item}`}
            type={type}
            size={size}
            width={isSwitchTab ? "16px" : `${widths[index]}${suffix}`}
            onClick={() => onSelect(isSwitchTab ? toggleSwitch() : index)}
            isSelected={index === selectedIdx}
          >
            {!isSwitchTab && <span style={tabTextStyles[size]}>{item}</span>}
          </TabItem>
        ))}
      </TabBar>
    </Wrapper>
  );
};

import { useEffect } from "react";
import styled from "styled-components";
import { SwitchTabProps } from "../BaseTab";
import { SwitchStateTab } from "./SwitchStateTab";

const TokenTabWrapper = styled.div`
  width: 140px;
  height: 36px;
`;

export const TokenTab: React.FC<SwitchTabProps> = ({
  leftValue,
  rightValue,
  selected = 0,
  onSelect,
  width,
  height,
  sliderHeight,
  marginLeft,
  ...props
}) => {
  useEffect(() => {
    onSelect(selected ? rightValue : leftValue);
  }, [leftValue, rightValue]);

  return (
    <TokenTabWrapper {...props}>
      <SwitchStateTab
        width={width}
        height={height}
        sliderHeight={sliderHeight}
        menu={[leftValue, rightValue]}
        selected={selected}
        onSelect={(value) => onSelect(value ? rightValue : leftValue)}
        {...props}
      />
    </TokenTabWrapper>
  );
};

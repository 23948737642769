import { getExplorerURL } from "@/config/chain";
import { IPositionDetails } from "@/config/position/positionConfig";
import {
  ApertureSupportedChainId,
  viem,
} from "@aperture_finance/uniswap-v3-automation-sdk";
import { ActivityType, IActivityLog } from "@uiv2/components/ActivityLog/types";
import { sqrtX96ToPrice } from "@uiv2/utils";
import Big from "big.js";
import bigDecimal from "js-big-decimal";
import { xirr } from "node-irr";

export type AnalyticPosition = {
  positionId: string;
  timeAPR: string;
  getMoneyAPR: () => string;
  totalAPR: string;
  feeAPR: string;
  pnl: string;
  divergenceLoss: string;
  gasCost: string;
  activityLogs: IActivityLog[];
  headPositionId: string;
  rebalancePositions: {
    id: string;
    closedTimestamp?: bigint;
  }[];

  totalFees: string;
  avgLiquidityUSD: string;

  // for closed position
  closedTimestamp?: number; // undefined if not closed
  closedLiquidityUSD?: string;
  closedAccruedFeesUSD?: string;
  closedMarketPrice?: string; // sqrtX96Price
  closedTotalAPR?: string;
  closedFeeAPR?: string;
};

// Constants
const DAYS_PER_YEAR = 365;
const SECONDS_PER_DAY = 86400;
const ACTIVITY_LOG_SPLIT = ",";

function calculateAvgLiquidityUSD(
  activityLogs: IActivityLog[],
  endTimestamp: number
): number {
  const liquidityLogs = activityLogs.filter(
    (log) => log.type === "Deposit" || log.type === "Withdraw"
  );

  if (liquidityLogs.length === 0) return 0;

  const weightedSum = liquidityLogs.reduce((acc, log) => {
    const liquidityChange =
      log.type === "Deposit" ? log.valueUSD : -log.valueUSD;
    const duration = endTimestamp - log.timestamp;
    return acc + liquidityChange * duration;
  }, 0);
  const totalDuration = endTimestamp - liquidityLogs[0].timestamp;
  return totalDuration > 0 ? weightedSum / totalDuration : 0;
}

function calculateFeesAndLosses(
  data: viem.AnalyticPositionSubgraphData,
  latestPosition: IPositionDetails,
  token0Price: string,
  token1Price: string,
  nativeTokenDecimals: number,
  nativeTokenPrice: string
) {
  const isClosed = data.closedTimestamp !== null;
  const totalFees = bigDecimal.add(
    bigDecimal.multiply(
      bigDecimal.add(
        parseSubgraphAmount(
          data.collectedToken0Amount,
          latestPosition.tokenA.decimals
        ),
        isClosed ? 0 : latestPosition.tokenA.collectableAmount
      ),
      token0Price
    ),
    bigDecimal.multiply(
      bigDecimal.add(
        parseSubgraphAmount(
          data.collectedToken1Amount,
          latestPosition.tokenB.decimals
        ),
        isClosed ? 0 : latestPosition.tokenB.collectableAmount
      ),
      token1Price
    )
  );

  const investedToken0Amount = parseSubgraphAmount(
    data.investedToken0Amount - data.withdrawnToken0Amount,
    latestPosition.tokenA.decimals
  );
  const investedToken1Amount = parseSubgraphAmount(
    data.investedToken1Amount - data.withdrawnToken1Amount,
    latestPosition.tokenB.decimals
  );
  const divergenceLoss = bigDecimal.add(
    bigDecimal.multiply(
      bigDecimal.subtract(
        isClosed ? 0 : latestPosition.tokenA.amount,
        investedToken0Amount
      ),
      token0Price
    ),
    bigDecimal.multiply(
      bigDecimal.subtract(
        isClosed ? 0 : latestPosition.tokenB.amount,
        investedToken1Amount
      ),
      token1Price
    )
  );

  const gasCostUSD = bigDecimal.multiply(
    parseSubgraphAmount(data.gasCost, nativeTokenDecimals),
    nativeTokenPrice
  );

  const currentLiquidityUSD = isClosed
    ? "0"
    : bigDecimal.add(
        bigDecimal.multiply(
          bigDecimal.add(
            latestPosition.tokenA.amount,
            latestPosition.tokenA.collectableAmount
          ),
          token0Price
        ),
        bigDecimal.multiply(
          bigDecimal.add(
            latestPosition.tokenB.amount,
            latestPosition.tokenB.collectableAmount
          ),
          token1Price
        )
      );

  return { totalFees, divergenceLoss, gasCostUSD, currentLiquidityUSD };
}

function calculateMoneyAPR(
  positionId: string,
  currentLiquidityUSD: string,
  gasCostUSD: string,
  activityLogs: IActivityLog[],
  endTimestamp: number
) {
  const data = activityLogs
    .filter(
      (log) =>
        log.type === "Deposit" ||
        log.type === "Withdraw" ||
        log.type === "CollectFees"
    )
    .map((log) => ({
      amount: log.valueUSD * (log.type === "Deposit" ? -1 : 1),
      date: new Date(log.timestamp * 1000),
    }));

  data.push({
    amount: -Number(gasCostUSD),
    date: new Date(endTimestamp * 1000),
  });

  data.push({
    amount: Number(currentLiquidityUSD),
    date: new Date(endTimestamp * 1000),
  });

  const res = xirr(data);

  return (((res.rate + 1) ** (DAYS_PER_YEAR / res.days) - 1) * 100).toString();
}

function calculateAPRs(
  pnl: string,
  totalFees: string,
  avgLiquidityUSD: number,
  startTimestamp: number,
  endTimestamp: number
) {
  const positionAgeInDays = Math.ceil(
    (endTimestamp - startTimestamp) / SECONDS_PER_DAY
  );

  if (avgLiquidityUSD === 0 || positionAgeInDays === 0) {
    return { totalAPR: "0", feeAPR: "0" };
  }

  return {
    totalAPR: bigDecimal.divide(
      bigDecimal.multiply(
        bigDecimal.divide(pnl, avgLiquidityUSD, 6),
        DAYS_PER_YEAR * 100
      ),
      positionAgeInDays,
      4
    ),
    feeAPR: bigDecimal.divide(
      bigDecimal.multiply(
        bigDecimal.divide(totalFees, avgLiquidityUSD, 6),
        DAYS_PER_YEAR * 100
      ),
      positionAgeInDays,
      4
    ),
  };
}

function parseSubgraphAmount(amount: bigint, decimals: number): String {
  return bigDecimal.divide(amount, new Big(10).pow(decimals), decimals);
}

function timeConverter(timestamp: number) {
  const date = new Date(timestamp * 1000);
  return date.toISOString().split("T")[0].replace(/-/g, "");
}

export function parseAnalyticPositionData(
  data: viem.AnalyticPositionSubgraphData,
  latestPosition: IPositionDetails,
  nativeTokenDecimals: number,
  nativeTokenPrice: string = "3000",
  token0Price: string = "1",
  token1Price: string = "1"
): AnalyticPosition {
  const currentTimestamp = Date.now() / 1000;
  const isClosed = data.closedTimestamp !== null;
  const endTimestamp = isClosed
    ? Number(data.closedTimestamp)
    : currentTimestamp;

  token0Price = isClosed ? data.closedToken0USDPrice?.toString() : token0Price;
  token1Price = isClosed ? data.closedToken1USDPrice?.toString() : token1Price;
  nativeTokenPrice = isClosed
    ? data.closedNativeUSDPrice?.toString()
    : nativeTokenPrice;

  // Parse activity logs and calculate liquidity metrics
  const activityLogs = data.activityLogs.map((log, index) =>
    parseAnalyticActivityLog(latestPosition.chainId, log, index)
  );
  const avgLiquidityUSD = calculateAvgLiquidityUSD(activityLogs, endTimestamp);

  // Calculate fees and losses
  const { totalFees, divergenceLoss, gasCostUSD, currentLiquidityUSD } =
    calculateFeesAndLosses(
      data,
      latestPosition,
      token0Price,
      token1Price,
      nativeTokenDecimals,
      nativeTokenPrice
    );

  // Calculate PnL
  const pnl = bigDecimal.subtract(
    bigDecimal.add(totalFees, divergenceLoss), // divergenceLoss is negative
    gasCostUSD
  );

  // Calculate money weighed APR
  const getMoneyAPR = () =>
    calculateMoneyAPR(
      latestPosition.positionId,
      currentLiquidityUSD,
      gasCostUSD,
      activityLogs,
      endTimestamp
    );

  // Calculate APRs
  const { totalAPR, feeAPR } = calculateAPRs(
    pnl,
    totalFees,
    avgLiquidityUSD,
    Number(data.createdTimestamp),
    Number(endTimestamp)
  );

  let closedLiquidityUSD: number,
    closedAccruedFeesUSD: string,
    closedMarketPrice: string;
  if (isClosed) {
    const closedLog = activityLogs.findLast((log) => log.type === "Withdraw");
    closedLiquidityUSD = closedLog?.valueUSD;
    closedMarketPrice = sqrtX96ToPrice(
      data.closedMarketPrice.toString(),
      latestPosition.tokenA.decimals,
      latestPosition.tokenB.decimals
    );
    closedAccruedFeesUSD = bigDecimal.add(
      bigDecimal.multiply(
        parseSubgraphAmount(
          data.collectedToken0Amount,
          latestPosition.tokenA.decimals
        ),
        token0Price
      ),
      bigDecimal.multiply(
        parseSubgraphAmount(
          data.collectedToken1Amount,
          latestPosition.tokenB.decimals
        ),
        token1Price
      )
    );
  }

  return {
    positionId: data.id,
    timeAPR: "-1", // TODO Analytics: TBD
    getMoneyAPR,
    totalAPR,
    feeAPR,
    pnl,
    divergenceLoss,
    totalFees,
    avgLiquidityUSD: avgLiquidityUSD.toString(),
    gasCost: gasCostUSD,
    activityLogs: activityLogs.filter((log) => log.type !== "Transfer"),
    headPositionId: data.headPosition?.id,
    rebalancePositions: data.headPosition?.rebalancePositions,

    closedTimestamp: isClosed ? Number(data.closedTimestamp) : undefined,
    closedLiquidityUSD: closedLiquidityUSD?.toString(),
    closedAccruedFeesUSD,
    closedMarketPrice,
  };
}

export function parseAnalyticActivityLog(
  networkId: ApertureSupportedChainId,
  activityLog: string,
  index: number
): IActivityLog {
  const [logType, timestamp, token0Amount, token1Amount, txHash, valueUSD] =
    activityLog.split(ACTIVITY_LOG_SPLIT);

  return {
    id: index,
    type: logType as ActivityType,
    timestamp: Number(timestamp),
    token0Amount,
    token1Amount,
    valueUSD: Number(valueUSD),
    txHash,
    explorerURL: `${getExplorerURL(networkId)}/tx/${txHash}`,
  };
}

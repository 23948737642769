import bn from "bignumber.js";
import { ChartPoint } from "./CorrelationChart";
import { ChartPrice } from "./types";

bn.config({ EXPONENTIAL_AT: 999999, DECIMAL_PLACES: 40 });
const Q96 = new bn(2).pow(96);

export const findMin = (data: number[]): number => {
  return data.reduce(
    (min, val) => (min > val ? val : min),
    Number.MAX_SAFE_INTEGER
  );
};

export const findMax = (data: number[]): number => {
  return data.reduce((max, val) => (max > val ? max : val), 0);
};

export const averageArray = (data: number[]): number => {
  return data.reduce((result, val) => result + val, 0) / data.length;
};

export const divideArray = (data0: number[], data1: number[]): number[] => {
  const result: number[] = [];
  data0.forEach((d, i) => {
    result[i] = d / data1[i];
    if (isNaN(result[i])) result[i] = result[i - 1];
  });
  return result;
};

// TODO: there is a chance that coingecko cannot fetch historical price for last 30 days (maybe only 10 to 20 days)
// Changing to coingecko pro API or using cached price in the future may resolve the problem
export const processPriceChartData = (
  token0PriceChart: ChartPrice[] | null,
  token1PriceChart: ChartPrice[] | null
): ChartPoint[] => {
  if (token0PriceChart === null || token1PriceChart === null) {
    return [];
  }

  const points: ChartPoint[] = [];
  const latest = Math.max(
    token0PriceChart.at(-1)?.timestamp ?? 0,
    token1PriceChart.at(-1)?.timestamp ?? 0
  );
  const length0 = token0PriceChart.length;
  const length1 = token1PriceChart.length;
  // 720 hours for 30 days, 1hr = 3600000ms
  const length = 720;
  const duration = 3600000;
  for (let i = 1; i <= length; i++) {
    const token0Value =
      token0PriceChart[length0 - i < 0 ? 0 : length0 - i].value;
    const token1Value =
      token1PriceChart[length1 - i < 0 ? 0 : length1 - i].value;
    points.push({
      x: Math.round(latest - i * duration),
      y: token0Value ? token1Value / token0Value : 0,
    });
  }
  return points.reverse();
};

export const getTickFromPrice = (
  price: number,
  token0Decimal: number,
  token1Decimal: number
): number => {
  const token0 = expandDecimals(price, token0Decimal);
  const token1 = expandDecimals(1, token1Decimal);
  const sqrtPrice = encodeSqrtPriceX96(token1).div(encodeSqrtPriceX96(token0));

  return Math.log(sqrtPrice.toNumber()) / Math.log(Math.sqrt(1.0001));
};

export const getPriceFromTick = (
  tick: number,
  token0Decimal: number,
  token1Decimal: number
): number => {
  const sqrtPrice = new bn(Math.pow(Math.sqrt(1.0001), tick)).multipliedBy(
    new bn(2).pow(96)
  );
  const token0 = expandDecimals(1, token0Decimal);
  const token1 = expandDecimals(1, token1Decimal);
  const L2 = mulDiv(
    encodeSqrtPriceX96(token0),
    encodeSqrtPriceX96(token1),
    Q96
  );
  const price = mulDiv(L2, Q96, sqrtPrice)
    .div(new bn(2).pow(96))
    .div(new bn(10).pow(token0Decimal))
    .pow(2);

  return price.toNumber();
};

// private helper functions
const encodeSqrtPriceX96 = (price: number | string | bn): bn => {
  return new bn(price).sqrt().multipliedBy(Q96).integerValue(3);
};

const expandDecimals = (n: number | string | bn, exp: number): bn => {
  return new bn(n).multipliedBy(new bn(10).pow(exp));
};

const mulDiv = (a: bn, b: bn, multiplier: bn) => {
  return a.multipliedBy(b).div(multiplier);
};

import { LinkIcon2 } from "@aperture/assetkit";
import React, { CSSProperties } from "react";
import styled, { useTheme } from "styled-components";
import { Headline5 } from "../Typography";
import { TextLinkProps } from "./types";

const Wrapper = styled.div<{
  wBorder: boolean;
  disabled: boolean;
  hover?: TextLinkProps["hover"];
}>`
  display: inline;
  width: fit-content;
  border: ${({ wBorder, theme }) =>
    wBorder ? `1px solid ${theme.colors.transparent.transparent}` : "none"};
  border-radius: ${({ wBorder, theme }) =>
    wBorder ? theme.radii.round : undefined};
  :hover,
  :active {
    opacity: ${({ disabled }) => (disabled ? "1" : "0.8")};
    border-color: ${({ hover, theme }) =>
      hover && hover.type === "border"
        ? hover.color
        : theme.colors.transparent.transparent};
  }
`;

const LinkWrapper = styled.div<{
  wBorder: boolean;
  sm: boolean;
  disabled: boolean;
  hover?: TextLinkProps["hover"];
}>`
  display: inline;
  width: fit-content;
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  border: ${({ wBorder, theme }) =>
    wBorder ? `1px solid ${theme.colors.global.text.T4}` : "none"};
  border-radius: ${({ wBorder, theme }) =>
    wBorder ? theme.radii.round : undefined};
  padding: ${({ wBorder, sm, theme }) =>
    wBorder
      ? sm
        ? `${theme.spacing.md} ${theme.spacing.xl}`
        : `${theme.spacing.lg} ${theme.spacing["3xl"]}`
      : undefined};
  :hover,
  :active {
    background-color: ${({ hover, theme }) =>
      hover && hover.type === "background"
        ? hover.color
        : theme.colors.transparent.transparent};
    border-color: ${({ hover, theme }) =>
      hover ? hover.color : theme.colors.global.text.T4};
  }
`;

const Text = styled(Headline5)<{
  underlined: boolean;
  wBorder: boolean;
  textColor: CSSProperties["color"];
}>`
  display: inline;
  font-family: Roboto Flex;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  margin-right: ${({ theme, wBorder }) =>
    wBorder ? theme.spacing.sm : theme.spacing.md};
  text-decoration: ${({ underlined }) => (underlined ? "underline" : "none")};
  color: ${({ textColor, theme }) => textColor ?? theme.colors.global.text.T1};
`;

const IconWrapper = styled.span<{ hasLink: boolean; disabled: boolean }>`
  display: inline-flex;
  align-items: center;
  min-height: 24px;
  vertical-align: ${({ hasLink }) => (hasLink ? "top" : "middle")};
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
`;

export const TextLink: React.FC<TextLinkProps> = ({
  link,
  text,
  showIcon = true,
  disabled = false,
  onLinkClick = () => {},
  sm = false,
  withBorder = false,
  textColor,
  iconColor = undefined,
  hover = undefined,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Wrapper hover={hover} wBorder={withBorder} disabled={disabled}>
      <LinkWrapper
        sm={sm}
        hover={hover}
        disabled={disabled}
        wBorder={withBorder}
        {...props}
        onClick={(event) => {
          event.stopPropagation();
          onLinkClick();
          link && window.open(link, "_blank");
        }}
      >
        <Text underlined={!!link} wBorder={withBorder} textColor={textColor}>
          {text}
        </Text>
        {showIcon && (
          <IconWrapper hasLink={!!link} disabled={disabled}>
            <LinkIcon2
              width={16}
              height={16}
              stroke={iconColor ?? theme.colors.global.primary}
            />
          </IconWrapper>
        )}
      </LinkWrapper>
    </Wrapper>
  );
};

import { useState } from "react";
import { useEventCallback } from "./useEventCallback";
import { useOnUpdateExist } from "./useOnUpdateExist";

export const useOnFirstLoad = <T>(v: T, callback: () => void) => {
  const cb = useEventCallback(callback);
  const [first, setFirst] = useState(true);

  useOnUpdateExist(first && v, () => {
    setFirst(false);
    cb();
  });
};

import { Flex, FlexColumn } from "@uiv2/common";
import { H2, T3Regular } from "@uiv2/components/Typography";
import styled from "styled-components";

export const Container = styled(FlexColumn)`
  width: 368px;
  padding: 16px;
  border-radius: ${({ theme }) => theme.radii.xl};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
`;
export const Heading = styled(H2)`
  margin-bottom: ${({ theme }) => theme.spacing["3xl"]};
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const StatContainer = styled(FlexColumn)`
  margin-bottom: ${({ theme }) => theme.spacing.md};
  gap: ${({ theme }) => theme.spacing.md};
`;
export const StatItem = styled(Flex)`
  justify-content: space-between;
`;
export const StatTitle = styled(T3Regular)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const StatValue = styled(T3Regular)`
  color: ${({ theme }) => theme.colors.global.text.T2};
`;

import { ExchangeIcon } from "@aperture/assetkit";
import { ITokenTickerIconPair } from "@aperture/types";
import { Flex, IPriceRange } from "@ui/common";
import { T1Regular, T3Regular } from "@ui/components/Typography";
import { useState } from "react";
import { MinMaxWrapper, SwapButtonWrapper } from "./style";

export const SwapRate: React.FC<{
  sm?: boolean;
  tokens: ITokenTickerIconPair;
  priceRange: IPriceRange;
}> = ({ sm = false, tokens, priceRange }) => {
  const [swap, setSwap] = useState(false);
  return (
    <MinMaxWrapper sm={sm}>
      <Flex gap="md" alignItems={"center"}>
        <Flex gap="xs" alignItems={"center"}>
          <T3Regular>Min</T3Regular>
          <T1Regular>{swap ? priceRange.swapMin : priceRange.min} </T1Regular>
        </Flex>
        <T1Regular>
          {tokens[swap ? 0 : 1].ticker} per {tokens[swap ? 1 : 0].ticker}
        </T1Regular>
      </Flex>
      <SwapButtonWrapper
        onClick={(event) => {
          event.stopPropagation();
          setSwap(!swap);
        }}
      >
        <ExchangeIcon width={16} height={16} />
      </SwapButtonWrapper>
      <Flex gap="md" alignItems={"center"}>
        <Flex gap="xs" alignItems={"center"}>
          <T3Regular>Max</T3Regular>
          <T1Regular>{swap ? priceRange.swapMax : priceRange.max} </T1Regular>
        </Flex>
        <T1Regular>
          {tokens[swap ? 0 : 1].ticker} per {tokens[swap ? 1 : 0].ticker}
        </T1Regular>
      </Flex>
    </MinMaxWrapper>
  );
};

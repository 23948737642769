import { CopyFileIcon, LinkIcon2 } from "@aperture/assetkit";
import { darkColors } from "@aperture/uikitv2";
import { useCopyText } from "@ui/utils/hooks";
import { useTheme } from "styled-components";
import { Flex, FlexColumn } from "../../common/styles/global";
import { formatTokenAmount, formatUSDAmount } from "../../utils";
import { Notification } from "../Notification";
import { SwapRate } from "../PastPositionCard/SwapRate";
import { PositionInfoTitle } from "../PositionInfoTitle";
import { variants } from "../Tag";
import {
  Container,
  CopyWrapper,
  Key,
  StyledInRangeIcon,
  StyledOutlineBtn,
  Value,
  Wrapper,
} from "./style";
import { CurrentPositionInfoCardProps } from "./types";

export const CurrentPositionInfoCard: React.FC<
  CurrentPositionInfoCardProps
> = ({
  id,
  tokens,
  feeTier,
  priceRange,
  lite = false,
  liquidity = undefined,
  notification = false,
  goToDetail = undefined,
  addLiquidity = undefined,
  liquidityDetails = undefined,
}) => {
  const theme = useTheme();
  const enableCopyPositionID = false;
  const { hasCopied, handleCopyAddress } = useCopyText(id);
  const data: Record<string, string | JSX.Element | undefined> = {
    "Position ID": `${id}`,
    "Liquidity Token Amount":
      lite && liquidityDetails
        ? `${tokens[0].ticker} ${formatTokenAmount(
            liquidityDetails.amounts[0]
          )} (${liquidityDetails.percentages[0]}%) ${
            tokens[1].ticker
          } ${formatTokenAmount(liquidityDetails.amounts[1])} (${
            liquidityDetails.percentages[1]
          }%)`
        : undefined,
    "Position Liquidity":
      !lite && liquidity ? formatUSDAmount(liquidity) : undefined,
    "Current Position Range": (
      <SwapRate
        {...{
          tokens,
          priceRange,
          iconColor: lite ? darkColors.grey.mediumGrey : undefined,
        }}
      />
    ),
  };
  const CopyIcon = enableCopyPositionID ? (
    <CopyWrapper onClick={handleCopyAddress}>
      {hasCopied ? (
        <StyledInRangeIcon width={16} height={16} />
      ) : (
        <CopyFileIcon
          fill={theme.colors.global.text.T1}
          width={16}
          height={16}
        />
      )}
    </CopyWrapper>
  ) : undefined;

  return (
    <Container lite={lite}>
      <FlexColumn gap="xl">
        <Flex justifyContent={"space-between"}>
          <PositionInfoTitle
            titleSize="lg"
            forceLeft={true}
            {...{ tokens, feeTier, tagVariant: variants.DARK_GRAY }}
          />
          <Wrapper onClick={goToDetail}>
            <Value>Go to Position Detail</Value>
            <LinkIcon2
              stroke={theme.colors.global.primary}
              width={14}
              height={14}
            />
          </Wrapper>
        </Flex>
        <FlexColumn gap="md">
          {Object.entries(data).map(
            ([label, value]) =>
              value && (
                <Flex gap="md" key={label} justifyContent={"space-between"}>
                  <Key>{label}</Key>
                  {typeof value === "string" ? (
                    <Flex gap="sm" alignItems="center">
                      <Value>{value}</Value>
                      {label === "Position ID" && CopyIcon}
                    </Flex>
                  ) : (
                    value
                  )}
                </Flex>
              )
          )}
        </FlexColumn>
      </FlexColumn>
      {!lite && notification && (
        <Notification
          variant="warning"
          text="Your position value is too low. Recurring Rebalance is paused. Please add liquidity to resume the strategy."
          button={
            <StyledOutlineBtn onClick={addLiquidity}>
              Add Liquidity
            </StyledOutlineBtn>
          }
        />
      )}
    </Container>
  );
};

import React from "react";
import styled from "styled-components";
import { compose, flexbox, grid, layout, space } from "styled-system";
import { WhiteCardUIProps } from "./types";

const StyledWhiteCard = styled.div<WhiteCardUIProps>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  width: 100%;
  padding: 16px;
  gap: ${({ theme }) => theme.spacing.xl};
  border-radius: ${({ theme }) => theme.radii.md};
  background-color: ${({ theme }) => theme.colors.global.background.BG2};
  ${compose(flexbox, grid, layout, space)}
`;

export const WhiteCard: React.FC<React.PropsWithChildren<WhiteCardUIProps>> = ({
  children,
  ...props
}) => {
  return <StyledWhiteCard {...props}>{children}</StyledWhiteCard>;
};

import { Spacing } from "@uiv2/theme";
import styled from "styled-components";
import {
  FlexboxProps,
  alignItems,
  compose,
  flexDirection,
  flexWrap,
  justifyContent,
} from "styled-system";

export const DrawerOpenCardWidth = "395px";

export type IFlexProps = FlexboxProps & {
  gap?: keyof Spacing | number;
  marginTop?: keyof Spacing | number;
  marginBottom?: keyof Spacing | number;
  marginLeft?: keyof Spacing | number;
  marginRight?: keyof Spacing | number;
  height?: string;
  width?: string;
};

export const Flex = styled.div<IFlexProps>`
  display: flex;
  ${(props) =>
    props.gap &&
    props.theme &&
    `gap: ${
      typeof props.gap === "number"
        ? `${props.gap}px`
        : props.theme.spacing[props.gap]
    }`};
  ${(props) =>
    props.marginTop &&
    props.theme &&
    `margin-top: ${
      typeof props.marginTop === "number"
        ? `${props.marginTop}px`
        : props.theme.spacing[props.marginTop]
    }`};
  ${(props) =>
    props.marginBottom &&
    props.theme &&
    `margin-bottom: ${
      typeof props.marginBottom === "number"
        ? `${props.marginBottom}px`
        : props.theme.spacing[props.marginBottom]
    }`};
  ${(props) =>
    props.marginLeft &&
    props.theme &&
    `margin-left: ${
      typeof props.marginLeft === "number"
        ? `${props.marginLeft}px`
        : props.theme.spacing[props.marginLeft]
    }`};
  ${(props) =>
    props.marginRight &&
    props.theme &&
    `margin-right: ${
      typeof props.marginRight === "number"
        ? `${props.marginRight}px`
        : props.theme.spacing[props.marginRight]
    }`};
  ${(props) => props.height && `height: ${props.height}`};
  ${(props) => props.width && `width: ${props.width}`};
  ${compose(flexDirection, alignItems, justifyContent, flexWrap)}
`;

export const FlexColumn = styled(Flex)`
  flex-direction: column;
`;

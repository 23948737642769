import { IPositionDetails, ITokenInfo } from "@/config/position/positionConfig";
import { getTokenIcon } from "@/config/token/tokenIconConfig";
import { calculateTotalValue } from "@/helper/positionHelper";
import { getTokenPercentages, tokenToTokenInfo } from "@/helper/tokenHelper";
import {
  ApertureSupportedChainId,
  fractionToBig,
  getTokenValueProportionFromPriceRatio,
  viem,
} from "@aperture_finance/uniswap-v3-automation-sdk";
import { tickToPrice } from "@aperture_finance/uniswap-v3-sdk";
import bigDecimal from "js-big-decimal";

import {
  IPositionMap,
  IRawTokenMap,
} from "@/components/GlobalStore/allChain/types";
import { getNetworkId } from "@/utils/networkHelper";
import { SupportedChains } from "@aperture/uikit";
import { keyBy } from "lodash";

export const getActivePositions = (
  positionMap: IPositionMap,
  tokenMap: IRawTokenMap,
  chainId: ApertureSupportedChainId,
  tokenPrice?: any
): { [positionId: string]: IPositionDetails } => {
  if (!positionMap || !tokenMap) {
    return {};
  }

  const ret = Object.entries(positionMap)
    .map(([positionId, details]) => {
      const token0 = tokenMap?.[details.pool.token0.address];
      const token1 = tokenMap?.[details.pool.token1.address];

      if (!token0 || !token1) {
        return null;
      }
      const minPrice = tickToPrice(token0, token1, details.tickLower).toFixed(
        18
      );
      const maxPrice = tickToPrice(token0, token1, details.tickUpper).toFixed(
        18
      );
      const tokenAInfo = tokenToTokenInfo(token0);
      const tokenBInfo = tokenToTokenInfo(token1);

      const tokenA: ITokenInfo = {
        ...tokenAInfo,
        amount: details.position.amount0.toExact(),
        collectableAmount: details.tokensOwed0.toExact(),
        address: details.token0.address,
        Icon: getTokenIcon(tokenAInfo.address, tokenAInfo.ticker, chainId),
        ratio: 0.5,
        price: fractionToBig(details.pool.token0Price).toString(),
        getTokenValueProportion: () =>
          getTokenValueProportionFromPriceRatio(
            details.tickLower,
            details.tickUpper,
            fractionToBig(details.pool.token0Price)
          ),
      };
      const tokenB: ITokenInfo = {
        ...tokenBInfo,
        amount: details.position.amount1.toExact(),
        collectableAmount: details.tokensOwed1.toExact(),
        address: details.token1.address,
        Icon: getTokenIcon(tokenBInfo.address, tokenBInfo.ticker, chainId),
        ratio: 0.5,
        price: fractionToBig(details.pool.token1Price).toString(),
        getTokenValueProportion: () =>
          getTokenValueProportionFromPriceRatio(
            details.tickLower,
            details.tickUpper,
            fractionToBig(details.pool.token1Price)
          ),
      };
      const tokenPercentages = getTokenPercentages(
        tokenA.amount,
        tokenB.amount,
        tokenPrice?.[tokenA.address]?.price || "0",
        tokenPrice?.[tokenB.address]?.price || "0"
      );
      return {
        positionIdBN: BigInt(positionId),
        positionId: positionId,
        chainId: details.chainId,
        tokenA,
        tokenB,
        liquidity: calculateTotalValue(
          tokenA.amount,
          tokenB.amount,
          tokenPrice?.[tokenA.address]?.price,
          tokenPrice?.[tokenB.address]?.price
        ),
        feeAmount: calculateTotalValue(
          tokenA.collectableAmount,
          tokenB.collectableAmount,
          tokenPrice?.[tokenA.address]?.price,
          tokenPrice?.[tokenB.address]?.price
        ),
        feeTier: details.fee,
        tickSpacing: details.tickSpacing,
        tickLower: details.tickLower,
        tickUpper: details.tickUpper,
        minPrice: minPrice,
        maxPrice: maxPrice,
        inRange: viem.isPositionInRange(details.position),
        closed: bigDecimal.compareTo(details.liquidity, 0) === 0,
        positionDataRaw: details.position,
        tokenPercentages,
      } as IPositionDetails;
    })
    .filter((p) => p);

  return keyBy(ret, "positionId");
};

export const sortActivePositions = (activePositions: IPositionDetails[]) => {
  const chainIdIndexMap = new Map(
    SupportedChains.map((chain, index) => [getNetworkId(chain), index])
  );
  return activePositions.sort((a, b) => {
    if (a.closed && b.closed)
      if (chainIdIndexMap.get(a.chainId) === chainIdIndexMap.get(b.chainId))
        return bigDecimal.compareTo(b.positionId, a.positionId);
      else
        return chainIdIndexMap.get(a.chainId) - chainIdIndexMap.get(b.chainId);
    if (a.closed || b.closed) return a.closed ? 1 : -1;
    if (a.inRange && !b.inRange) return -1;
    if (!a.inRange && b.inRange) return 1;
    if (chainIdIndexMap.get(a.chainId) === chainIdIndexMap.get(b.chainId))
      return bigDecimal.compareTo(b.positionId, a.positionId);
    return chainIdIndexMap.get(a.chainId) - chainIdIndexMap.get(b.chainId);
  });
};

import { useNetwork } from "@/components/NetworkContext/NetworkContext";
import { getNativeIcon, getTokenIcon } from "@/config/token/tokenIconConfig";
import { isUserPortfolioBasedOnTokenMap } from "@/utils/networkHelper";
import {
  IPortfolioToken,
  PortfolioBalances,
  SupportedChainId,
} from "@aperture/uikit";
import {
  ApertureSupportedChainId,
  TokenBalance,
  TokenStandard,
} from "@aperture_finance/uniswap-v3-automation-sdk";
import axios from "axios";
import bigDecimal from "js-big-decimal";
import useSWR from "swr/immutable";
import { Address } from "viem";
import { useAccount } from "wagmi";
import { ITokenMap } from "./useFetchTokenMap";
export function useFetchUserPortfolio(
  tokenMap: ITokenMap,
  walletBalance: string
) {
  const { address: walletAddress, isConnected } = useAccount();
  const { networkId, network, isChainSupported } = useNetwork();
  const fetchFromTokenMap = isUserPortfolioBasedOnTokenMap(networkId);
  const showFetch =
    isConnected &&
    isChainSupported &&
    walletAddress &&
    ((fetchFromTokenMap && !!tokenMap) || !fetchFromTokenMap);

  return useSWR(
    showFetch
      ? `user-portfolio-${walletAddress}-${networkId}-${walletBalance}`
      : null,
    () =>
      getUserPortfolio(
        networkId,
        network,
        walletAddress,
        fetchFromTokenMap,
        tokenMap
      )
  );
}

async function getUserPortfolio(
  chainId: ApertureSupportedChainId,
  chain: SupportedChainId,
  walletAddress: Address,
  fetchFromTokenMap: boolean,
  tokenMap?: ITokenMap
): Promise<PortfolioBalances> {
  if (fetchFromTokenMap) {
    return parseTokenMap(chainId, tokenMap);
  }

  const portfolio = (
    await axios.post("https://uniswap-api.aperture.finance/v1/graphql", {
      operationName: "PortfolioBalances",
      variables: {
        ownerAddress: walletAddress ?? "",
        chains: [chain],
      },
      query: `
        query PortfolioBalances($ownerAddress: String!, $chains: [Chain!]!) {
          portfolios(ownerAddresses: [$ownerAddress], chains: $chains) {
            id
            tokensTotalDenominatedValue {
              id
              value
            }
            tokensTotalDenominatedValueChange(duration: DAY) {
              absolute {
                id
                value
              }
              percentage {
                id
                value
              }
            }
            tokenBalances {
              id
              quantity
              denominatedValue {
                id
                currency
                value
              }
              tokenProjectMarket {
                id
                pricePercentChange(duration: DAY) {
                  id
                  value
                }
                tokenProject {
                  id
                  logoUrl
                  isSpam
                }
              }
              token {
                id
                chain
                address
                name
                symbol
                standard
                decimals
              }
            }
          }
        }
      `,
    })
  ).data.data?.portfolios?.[0];
  const totalBalance: string = bigDecimal.multiply(
    portfolio?.tokensTotalDenominatedValue?.value ?? 0,
    "1.0"
  );
  const tokenBalances: Array<TokenBalance> | undefined =
    portfolio?.tokenBalances;
  const chainTokens = tokenBalances?.reduce(
    (acc: Array<IPortfolioToken>, tokenBalance) => {
      const token = parseTokenBalance(chainId, tokenBalance);
      if (token) {
        acc.push(token);
      }
      return acc;
    },
    []
  );

  return { totalBalance, tokenBalances: chainTokens ?? [] };
}

function parseTokenBalance(
  chainId: ApertureSupportedChainId,
  tokenBalance: TokenBalance
): IPortfolioToken | undefined {
  if (tokenBalance.token)
    try {
      const native = tokenBalance.token.standard === TokenStandard.Native;
      const defaultFields = {
        id: tokenBalance.token.id,
        chainId,
        address: tokenBalance.token.address,
        name: tokenBalance.token.name,
        symbol: tokenBalance.token.symbol,
        decimals: tokenBalance.token.decimals,
        native,
        logo: native
          ? getNativeIcon(chainId)
          : getTokenIcon(
              tokenBalance.token.address,
              tokenBalance.token.symbol,
              chainId,
              tokenBalance.tokenProjectMarket?.tokenProject?.logoUrl
            ),
        denominatedValue: tokenBalance.denominatedValue?.value,
        quantity: tokenBalance.quantity,
      };
      return {
        ...defaultFields,
      };
    } catch (err) {
      console.log("Failed to parse TokenBalance", err);
      return undefined;
    }

  return undefined;
}

function parseTokenMap(
  chainId: ApertureSupportedChainId,
  tokenMap: ITokenMap
): PortfolioBalances {
  const chainTokens: Array<IPortfolioToken> = [];
  let totalBalance = "0";
  if (tokenMap)
    Object.values(tokenMap).forEach((token) => {
      if (bigDecimal.compareTo(token.amount, 0)) {
        const denominatedValue = bigDecimal.multiply(
          token.amount ?? 0,
          token.price ?? 0
        );
        chainTokens.push({
          id: `${token.address}-${token.native}`,
          chainId,
          address: token.address,
          name: token.name,
          symbol: token.ticker,
          decimals: token.decimals,
          native: token.native,
          logo: token.Icon,
          price: token.price,
          denominatedValue,
          quantity: token.amount,
        });
        totalBalance = bigDecimal.add(totalBalance, denominatedValue);
      }
    });

  chainTokens.sort((a, b) =>
    bigDecimal.compareTo(b.denominatedValue, a.denominatedValue)
  );

  return { totalBalance, tokenBalances: chainTokens };
}

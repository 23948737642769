import { EvmChainMap, getExplorerURL } from "@/config/chain";
import { useEventCallback } from "@/hooks/useEventCallback";
import { useFetchTokenMap } from "@/hooks/useFetchTokenMap";
import { useFetchUserPortfolio } from "@/hooks/useFetchUserPortfolio";
import { useUserIPRestriction } from "@/hooks/useUserIPRestriction";
import {
  DEFAULT_AMM,
  DEFAULT_NETWORK,
  getNetworkId,
  getSwitchNetworkError,
} from "@/utils/networkHelper";
import { formatTokenAmount } from "@/utils/numberFormat";
import {
  AmmInfo,
  AuthStatus,
  BasicInfo,
  ConnectionType,
  SupportedChainId,
  TopBar,
} from "@aperture/uikit";
import { E_ModalKey, Modal, useModal } from "@aperture/uikitv2";
import { getLogger, viem } from "@aperture_finance/uniswap-v3-automation-sdk";
import { debounce } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useAccount, useBalance, useSwitchChain } from "wagmi";
import { useAOStore, useConnectStore } from "../GlobalStore";
import { AuthModal } from "../Modal/AuthModal";
import { WrongNetworkModal } from "../Modal/WrongNetworkModal";
import { useNetwork } from "../NetworkContext/NetworkContext";
import { useSnackbarV2 } from "../SnackbarContext/SnackbarContextV2";
import {
  ConnectModal,
  IPRestrictModal,
  useToggleIPRestrictModal,
} from "./hooks";

const Container = styled.div<{ open: Boolean }>`
  width: ${({ open }) => (open ? "calc(100% - 648px)" : "100%")};
  height: 40px;
`;

const TopBarLayout: React.FC<React.PropsWithChildren<{ open: Boolean }>> = ({
  open,
  children,
}) => {
  const router = useRouter();
  const { isConnected, address, chain, connector } = useAccount();
  const { state, setModalState } = useModal();
  const { isOpen: isWrongNetworkModalOpen } = state.WRONG_NETWORK_MODAL;
  const { isOpen: isAuthModalOpen } = state.AUTH_MODAL;
  const { data: walletBalance } = useBalance({
    address,
    query: { refetchInterval: 30_000 },
  });

  const { amm, ammEnum, network, networkId, setNetwork, isChainSupported } =
    useNetwork();
  const { ifAuthorized, isAOStatusLoading } = useAOStore();
  const { connectType, setConnectType, disconnect } = useConnectStore();
  const [authKeepOpen, setAuthKeepOpen] = useState(false);
  const [activities, setActivities] = useState<
    { [hash: string]: viem.Activity } | undefined
  >(undefined);
  const { data: tokenMap } = useFetchTokenMap();
  const {
    switchChain,
    isPending: isSwitchChainLoading,
    isError: isSwitchChainError,
    error: switchChainTxnErrorMsg,
  } = useSwitchChain();
  const { data: portfolioBalances, mutate: portfolioMutate } =
    useFetchUserPortfolio(tokenMap, walletBalance?.formatted);
  const { addSnackbar } = useSnackbarV2();
  const { data: userIpInfo } = useUserIPRestriction();
  const { toggleIPRestrictionModal } = useToggleIPRestrictModal();

  useEffect(() => {
    const getActivities = async () => {
      if (!address) return;
      const activities = await viem.getWalletActivities(address);
      setActivities(activities);
    };
    getActivities();
  }, [address]);

  useEffect(() => {
    debounce(() => {
      if (tokenMap) portfolioMutate();
    }, 500);
  }, [tokenMap]);

  useEffect(() => {
    if (isSwitchChainError) {
      getLogger().warn("TopBarLayout.SwitchNetworkError", {
        error: (switchChainTxnErrorMsg as Error).message,
      });
      addSnackbar({
        info: getSwitchNetworkError(connectType),
        status: "failed",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSwitchChainError]);

  const isWrongNetworkLoading =
    !isSwitchChainError && isConnected && chain && isChainSupported
      ? false
      : isSwitchChainLoading;

  const handleOpenWrongNetworkModal = () => {
    if (chain?.id !== networkId) {
      switchChain?.({ chainId: networkId });
      return;
    }
    setModalState(E_ModalKey.WRONG_NETWORK_MODAL, true);
  };

  const handleOnWrongNetworkModalClose = () => {
    setModalState(E_ModalKey.WRONG_NETWORK_MODAL, false);
    if (ammEnum === "SLIPSTREAM") {
      if (
        router.pathname.startsWith("/limit-orders") ||
        router.pathname.startsWith("/swap")
      ) {
        if (network === SupportedChainId.BASE) {
          setNetwork(AmmInfo.Uniswap, SupportedChainId.BASE);
        } else if (network === SupportedChainId.OPTIMISM) {
          setNetwork(AmmInfo.Uniswap, SupportedChainId.OPTIMISM);
        }
        return;
      }
    } else if (
      router.pathname.startsWith("/limit-orders") ||
      router.pathname.startsWith("/recurring-rebalance")
    ) {
      if (network === SupportedChainId.MANTA) {
        setNetwork(DEFAULT_AMM, DEFAULT_NETWORK);
        switchChain?.({ chainId: getNetworkId(DEFAULT_NETWORK) });
        return;
      }
    }
  };

  const toggleAuthModal = (open: boolean) => {
    setAuthKeepOpen(open);
    setModalState(E_ModalKey.AUTH_MODAL, open);
  };

  const timerRef = useRef(null);
  const toggleWrongNetworkModal = useEventCallback((open: boolean) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (open && isConnected) {
      // open modal after 500ms to avoid flickering
      timerRef.current = setTimeout(async () => {
        try {
          const connectorChainId = await connector?.getChainId?.();
          if (connectorChainId) handleOpenWrongNetworkModal();
        } catch (e) {
          disconnect();
          addSnackbar({
            info: "Error connecting, please retry and confirm in wallet!",
            status: "failed",
          });
        }
      }, 500);
    } else {
      setModalState(E_ModalKey.WRONG_NETWORK_MODAL, false);
    }
  });

  useEffect(() => {
    if (isConnected) {
      toggleWrongNetworkModal(!isChainSupported);
    } else setConnectType(ConnectionType.NONE);
  }, [isConnected, chain, isChainSupported, toggleWrongNetworkModal]);

  useEffect(() => {
    if (
      router.pathname.startsWith("/stake") ||
      router.pathname.startsWith("/claim")
    )
      toggleIPRestrictionModal(userIpInfo);
  }, [userIpInfo, router.pathname, toggleIPRestrictionModal]);

  return (
    <Container open={open}>
      <TopBar
        currentAmm={amm}
        currentChainId={network}
        supportedAmmList={AmmInfo}
        chainOnSelect={(amm: BasicInfo, chain: SupportedChainId) =>
          setNetwork(amm, chain)
        }
        walletConnected={isConnected}
        connectType={connectType}
        authUser={{
          address: address ? address : "",
          balance: formatTokenAmount(walletBalance?.formatted ?? "0.0"),
          ticker: walletBalance?.symbol ?? "",
        }}
        authStatus={
          isAOStatusLoading
            ? AuthStatus.LOADING
            : ifAuthorized
            ? AuthStatus.AUTHORIZED
            : AuthStatus.UNAUTHORIZED
        }
        authKeepOpen={authKeepOpen}
        onAuthClick={() => toggleAuthModal(true)}
        portfolioBalances={portfolioBalances}
        activities={activities}
        onDisconnect={() => disconnect()}
        url={
          chain && EvmChainMap[chain.id] ? EvmChainMap[chain.id].exploreURL : ""
        }
        wrongNetwork={isConnected && !isChainSupported}
        wrongNetworkLoading={isWrongNetworkLoading}
        openConnectWalletModal={() =>
          setModalState(E_ModalKey.CONNECT_MODAL, true)
        }
        openWrongNetworkModal={handleOpenWrongNetworkModal}
        explorerURL={getExplorerURL(networkId)}
      />
      {children}
      <ConnectModal />
      <IPRestrictModal />
      <Modal
        isModalOpen={isWrongNetworkModalOpen}
        onClose={handleOnWrongNetworkModalClose}
      >
        <WrongNetworkModal onclose={handleOnWrongNetworkModalClose} />
      </Modal>
      <Modal
        isModalOpen={isAuthModalOpen}
        onClose={() => toggleAuthModal(false)}
      >
        <AuthModal onclose={() => toggleAuthModal(false)} />
      </Modal>
    </Container>
  );
};

export default TopBarLayout;

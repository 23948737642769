import styled from "styled-components";
import { T2 } from "../Typography";

export const StyledTitle = styled(T2)`
  display: flex;
  align-items: start;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.global.text.T3};
`;

export const StyledValue = styled(T2)`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const PureBox = styled.div`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.global.text.T3};
`;

import React from "react";
import styled from "styled-components";
import { ButtonText1 } from "../../Typography";
import { StyledBar, StyledItem } from "../SharedTab";
import { StateTabProps } from "./types";

const TabBar = styled(StyledBar)<{ width: string; height: string }>`
  position: relative;
  background: ${({ theme }) => theme.colors.global.background.BG3};
  border-radius: ${({ theme }) => theme.radii.round};
  padding: 0;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

const TabSlider = styled.div<{
  selectedIndex: number;
  selectedLength: number;
  length: number;
  initLeft: number;
  // sliderWidth: string[];
  sliderHeight: string;
}>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  --switch-width: ${({ selectedLength }) =>
    selectedLength === 3
      ? "59px"
      : `calc(59px + ${(selectedLength - 3) * 6}px)`};
  left: ${({ initLeft, selectedIndex }) =>
    selectedIndex === 0
      ? `${initLeft}px`
      : `calc(100% - ${initLeft}px - var(--switch-width))`};
  transition: left 0.3s;
  background: ${({ theme }) => theme.colors.global.text.T1};
  border-radius: ${({ theme }) => theme.radii.round};
  width: var(--switch-width);
  height: ${({ sliderHeight }) => sliderHeight};
  z-index: ${({ theme }) => theme.zIndices.tab};
`;

const TabItems = styled(StyledItem)<{ isSelected: boolean }>`
  height: calc(100% - 8px);
  padding: 4px 16px;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.colors.global.background.BG1
      : theme.colors.global.text.T1};
  transition: color 0.3s;
  border-radius: ${({ theme }) => theme.radii.round};
`;
const StyledText = styled(ButtonText1)`
  line-height: 20px;
`;

export const SwitchStateTab: React.FC<StateTabProps> = ({
  menu,
  selected = 0,
  equalDivision = false,
  onSelect,
  marginLeft = 4,
  width,
  height,
  sliderHeight = "29px",
  marginTop,
  ...props
}) => {
  const menuTextLength = menu.join("").length;
  return (
    <TabBar
      width={width || "auto"}
      height={height || "29px"}
      {...props}
      onClick={() => {
        if (menu.length === 2) onSelect(1 - selected);
      }}
    >
      <TabSlider
        initLeft={marginLeft}
        sliderHeight={sliderHeight}
        selectedIndex={selected}
        length={menu.length}
        selectedLength={menu[selected].length}
      />
      {menu.map((item, index) => (
        <TabItems
          key={`${index}-${item}`}
          itemWidth={
            100 *
            (equalDivision
              ? 1 / menu.length
              : menu[index].length / menuTextLength)
          }
          onClick={() => {
            if (menu.length > 2) onSelect(index);
          }}
          isSelected={index === selected}
        >
          <StyledText>{item}</StyledText>
        </TabItems>
      ))}
    </TabBar>
  );
};

import { ExchangeIcon } from "@aperture/assetkit";
import { IPriceRange, ITokenTickerIconPair } from "@aperture/types";
import { Flex } from "@uiv2/common";
import { useState } from "react";
import { MinMaxWrapper, SwapButtonWrapper, T1, T3 } from "./style";

export const SwapRate: React.FC<{
  sm?: boolean;
  tokens: ITokenTickerIconPair;
  priceRange: IPriceRange;
}> = ({ sm = false, tokens, priceRange }) => {
  const [swap, setSwap] = useState(false);
  return (
    <MinMaxWrapper sm={sm}>
      <Flex gap="md" alignItems={"center"}>
        <Flex gap="xs" alignItems={"center"}>
          <T3>Min</T3>
          <T1>{swap ? priceRange.swapMin : priceRange.min} </T1>
        </Flex>
        <T1>
          {tokens[swap ? 0 : 1].ticker} per {tokens[swap ? 1 : 0].ticker}
        </T1>
      </Flex>
      <SwapButtonWrapper
        onClick={(event) => {
          event.stopPropagation();
          setSwap(!swap);
        }}
      >
        <ExchangeIcon width={16} height={16} />
      </SwapButtonWrapper>
      <Flex gap="md" alignItems={"center"}>
        <Flex gap="xs" alignItems={"center"}>
          <T3>Max</T3>
          <T1>{swap ? priceRange.swapMax : priceRange.max} </T1>
        </Flex>
        <T1>
          {tokens[swap ? 0 : 1].ticker} per {tokens[swap ? 1 : 0].ticker}
        </T1>
      </Flex>
    </MinMaxWrapper>
  );
};

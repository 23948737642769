import { InRangeIcon } from "@aperture/assetkit";
import { Flex, FlexColumn } from "@ui/common";
import { RangeTag } from "@ui/components/Tag";
import styled from "styled-components";
import { Subtitle2 } from "../../Typography";

export const Container = styled.div<{ selected: Boolean }>`
  padding: 16px;
  display: flex;
  box-sizing: border-box;
  gap: ${({ theme }) => theme.spacing.xl};
  align-items: center;
  border-radius: ${({ theme }) => theme.radii.xl};
  background-color: ${({ theme, selected }) =>
    selected
      ? theme.colors.global.background.BG2
      : theme.colors.global.background.BG1};
  border: 1.5px solid
    ${({ theme, selected }) =>
      selected
        ? theme.colors.transparent.transparent
        : theme.colors.global.line.Line1};
  transition: all ${({ theme }) => theme.transitions.default} ease;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.global.background.BG2};
  }
`;
export const PositionInfo = styled.div<{ sm: boolean }>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: ${({ sm }) => (sm ? "column" : "row")};
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.lg};
`;
export const MinMaxWrapper = styled.div<{ sm: boolean }>`
  display: flex;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing.lg};
  flex-direction: ${({ sm }) => (sm ? "column" : "row")};
`;
export const SwapButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  svg {
    fill: ${({ theme }) => theme.colors.global.primary};
  }
`;
export const ChainInfo = styled(Flex)<{ selected: Boolean; isManta: Boolean }>`
  transition: all ${({ theme }) => theme.transitions.default} ease;
`;
export const CurrentChainDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: ${({ theme }) => theme.radii.circle};
  background-color: ${({ theme }) => theme.colors.global.success};
`;
export const Wrapper = styled(FlexColumn)`
  width: 100%;
  border-radius: 15px;
`;
export const IconWrapper = styled.div`
  height: 16px;
  width: 16px;
`;
export const StyledPercentage = styled(Subtitle2)`
  line-height: 16px;
  color: ${({ theme }) => theme.colors.global.text.T2};
`;
export const StyledSubtitle2 = styled(Subtitle2)`
  line-height: 16px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const StyledRangeTag = styled(RangeTag)`
  height: fit-content;
`;
export const CopyWrapper = styled.div`
  cursor: pointer;
  svg {
    display: block;
  }
`;
export const StyledInRangeIcon = styled(InRangeIcon)`
  fill: ${({ theme }) => theme.colors.global.text.T2};
`;

import { Flex } from "@ui/common";
import { T1Regular, T3Regular } from "@ui/components/Typography";
import { formatUSDAmount } from "../../../utils/numberFormat";

export const Fees: React.FC<{
  liquidity: string;
  fees: string;
  closed?: boolean;
}> = ({ liquidity = "", fees = "", closed = false }) => {
  return (
    <Flex gap="xl" alignItems={"center"}>
      <Flex gap="md" alignItems={"center"}>
        <T3Regular>Liquidity</T3Regular>
        <T1Regular>{formatUSDAmount(liquidity)}</T1Regular>
      </Flex>
      <Flex gap="md" alignItems={"center"}>
        <T3Regular>{closed ? "Accrued Fees" : "Unclaimed Fees"}</T3Regular>
        <T1Regular>{formatUSDAmount(fees)}</T1Regular>
      </Flex>
    </Flex>
  );
};

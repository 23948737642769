import { ExchangeIcon, InRangeIcon } from "@aperture/assetkit";
import { CSSProperties } from "react";
import styled from "styled-components";
import { Flex, FlexColumn } from "../../common/styles/global";
import { Body1 } from "../Typography";

export const SwapButton = styled.button<{
  color?: CSSProperties["color"];
}>`
  width: 20px;
  height: 20px;
  padding: 0;
  cursor: pointer;
  border: none;
  background: none;
`;

export const SwapButtonIcon = styled(ExchangeIcon)<{
  color?: CSSProperties["color"];
}>`
  transform: rotate(0);
  transition: transform ${({ theme }) => theme.transitions.default} ease;
  fill: ${({ theme }) => theme.colors.global.primary};
`;

export const SwapBox = styled(Flex)`
  align-items: center;
  height: 16px;
  line-height: 16px;
  gap: ${({ theme }) => theme.spacing.md};
`;

export const Container = styled(FlexColumn)`
  width: 416px;
  padding: 16px;
  gap: ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.radii.md};
  background-color: ${({ theme }) => theme.colors.global.background.BG3};
`;

export const Label = styled(Body1)`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.global.text.T3};
`;

export const Value = styled(Body1)`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const PositionInfoItem = styled(FlexColumn)`
  width: 100%;
  min-width: 154px;
  gap: ${({ theme }) => theme.spacing.md};
`;

export const CopyWrapper = styled.div`
  cursor: pointer;
  svg {
    display: block;
  }
`;
export const StyledInRangeIcon = styled(InRangeIcon)`
  fill: ${({ theme }) => theme.colors.global.text.T1};
`;

import { useTheme } from "styled-components";
import {
  PopularQuestionsContainer,
  QuestionLink,
  QuestionListContainer,
  Text,
} from "./styles";

type PopularQuestionsProps = {
  isDrawer: boolean;
  questions: string[];
  onQuestionClick: (question: string) => void;
};

export const PopularQuestions: React.FC<PopularQuestionsProps> = ({
  isDrawer,
  questions,
  onQuestionClick,
}) => {
  const theme = useTheme();

  return (
    !!questions?.length && (
      <PopularQuestionsContainer>
        <Text>Popular questions</Text>
        <QuestionListContainer gap={6} isDrawer={isDrawer}>
          {questions.map((question, idx) => (
            <QuestionLink
              key={`${question}-${idx}`}
              {...{
                text: question,
                sm: true,
                withBorder: true,
                onLinkClick: () => onQuestionClick(question),
                hover: {
                  type: "background",
                  color: isDrawer
                    ? theme.colors.global.background.BG3
                    : theme.colors.global.background.BG2,
                },
                textColor: theme.colors.global.text.T3,
              }}
            />
          ))}
        </QuestionListContainer>
      </PopularQuestionsContainer>
    )
  );
};

import { ChartColor, ChartProps, ChartVolumn } from "../types";

export interface CorrelationChartProps extends ChartProps {
  poolDayDatas: ChartVolumn[] | null;
}
export interface ChartPoint {
  x: number;
  y: number;
}
export interface D3CorrelationChartProps {
  width: number;
  height: number;
  data: ChartPoint[];
  volumes: number[];
  mostActivePrice: number;
  minRange: number;
  maxRange: number;
  minPercentage: string;
  maxPercentage: string;
  colors: ChartColor;
}
export const VOLUME_DAYS = 30;

import { CheckMarkIcon } from "@aperture/assetkit";
import { Flex, FlexColumn } from "@ui/common";
import { FeeInfoCard, Popover, SwapRoute } from "@ui/components";
import {
  Tag as IconTag,
  IconWrapper,
  StyledViewIcon,
} from "@ui/components/SwapPath/style";
import { formatTokenAmount, formatUSDAmount } from "@ui/utils";
import bigDecimal from "js-big-decimal";
import { useState } from "react";
import styled from "styled-components";
import { formatPrice } from "../../../utils/numberFormat";
import { FEE_TIERS, FeeTierPercentage } from "../../FeeTier";
import { SwitchMenuTab } from "../../Tabs";
import { TokenIcons } from "../../TokenIcons";
import { TokenPrice } from "../../TokenPriceGroup";
import { Body1, Headline3, Headline5 } from "../../Typography";
import { PreviewLPProps } from "../types";

const ContentWrapper = styled(FlexColumn)`
  width: 520px;
`;
const TokenInfoContainer = styled.div`
  padding-bottom: 12px;
  width: 100%;
  border-bottom: 2px solid ${({ theme }) => theme.colors.global.line.Line1};
`;
const PriceRangBar = styled(FlexColumn)`
  align-items: flex-start;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.lg};
`;
const MinMax = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.xl};
`;
const TitleB1 = styled(Body1)`
  color: ${({ theme }) => theme.colors.global.text.T2};
  font-family: "Roboto Flex";
  font-weight: 400;
  line-height: 16px;
`;
const SubtitleB1 = styled(TitleB1)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const H3 = styled(Headline3)`
  color: ${({ theme }) => theme.colors.global.text.T1};
  font-family: "Roboto Flex";
  line-height: 28px;
`;
const TopTitle = styled(Headline5)`
  color: ${({ theme }) => theme.colors.global.text.T1};
  font-family: "Dunbar Tall";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  text-transform: capitalize;
`;
const HR = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.global.line.Line2};
  height: 0;
  margin: 16px 0;
`;
const CardBox = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 16px;
  align-items: center;
`;
const TagCardTitle5 = styled.div`
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 8px 12px;
  color: ${({ theme }) => theme.colors.grey.black};
  background-color: ${({ theme }) => theme.colors.global.success};
  text-align: center;
  font-family: "Roboto Flex";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
`;
const TokenType = styled.div`
  display: flex;
  gap: 10px;
  color: ${({ theme }) => theme.colors.global.text.T1};
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;
const TokenIconWrapper = styled.div`
  width: 20px;
  height: 20px;

  svg,
  img {
    height: 20px !important;
    width: 20px !important;
  }
`;
const TokenInfoBox = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.global.text.T1};
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
`;
const FlexColumnBox = styled(FlexColumn)`
  margin-top: 16px;
  gap: 12px;
`;
const FeeBox = styled(FlexColumn)`
  padding: 16px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line2};
`;
const FeeBoxTitle = styled(Headline5)`
  color: ${({ theme }) => theme.colors.global.text.T1};
  font-family: "Roboto Flex";
  line-height: 20px;
`;
const FeeBoxCheck = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${({ theme }) => theme.colors.global.success};
  font-family: "Roboto Flex";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
`;

export const PreviewLPContent: React.FC<PreviewLPProps> = ({
  amm,
  baseToken,
  tokenInfo,
  total,
  feeTier,
  priceRange,
  slippage,
  zapTokenInfo = undefined,
  amounts = [],
  estimatedGas = undefined,
  apertureFee = undefined,
  swapInfo = undefined,
}) => {
  const zapIn = !!zapTokenInfo;
  const baseSelected = baseToken.ticker === tokenInfo[0].ticker ? 0 : 1;
  const [selected, setSelected] = useState(baseSelected);
  const subTitle = [
    `${tokenInfo[1].ticker} per ${tokenInfo[0].ticker}`,
    `${tokenInfo[0].ticker} per ${tokenInfo[1].ticker}`,
  ];
  return (
    <ContentWrapper>
      <TopTitle>Preview Liquidity Position</TopTitle>
      <CardBox>
        <TokenIcons
          leftIcon={tokenInfo[0].Icon}
          rightIcon={tokenInfo[1].Icon}
          size={32}
        />
        <H3 fontWeight={500}>
          {tokenInfo[0].ticker}/{tokenInfo[1].ticker}
        </H3>
        <TagCardTitle5>{feeTier / FeeTierPercentage}%</TagCardTitle5>
      </CardBox>
      <HR />
      <FlexColumn gap={12}>
        <TitleB1>{zapIn ? "Token Deposited" : "Net Deposit"}</TitleB1>
        <FlexColumn gap="md">
          <H3 letterSpacing={"0.0025em"}>
            {bigDecimal.compareTo(total, 0) ? formatUSDAmount(total) : "$-"}
          </H3>
          {zapIn && (
            <TokenInfoContainer>
              <FlexColumn gap={12}>
                {(Array.isArray(zapTokenInfo)
                  ? zapTokenInfo
                  : [zapTokenInfo]
                ).map((tokenItem, index) => {
                  return (
                    <TokenInfoBox key={`zapIn-${tokenItem.ticker}-${index}`}>
                      <TokenType>
                        <TokenIconWrapper>{tokenItem.Icon}</TokenIconWrapper>
                        {tokenItem.ticker}
                      </TokenType>
                      {formatTokenAmount(tokenItem.amount ?? "-")}
                    </TokenInfoBox>
                  );
                })}
              </FlexColumn>
            </TokenInfoContainer>
          )}
        </FlexColumn>
        {zapIn && <TitleB1>Min. Amounts Of Liquidity To Add</TitleB1>}
        <FlexColumn gap={8}>
          {tokenInfo.map((tokenItem, index) => {
            return (
              <TokenInfoBox key={`${tokenItem.ticker}-${index}`}>
                <TokenType>
                  <TokenIconWrapper>{tokenItem.Icon}</TokenIconWrapper>
                  {tokenItem.ticker}
                </TokenType>
                {formatTokenAmount(
                  (zapIn ? amounts[index] : tokenItem.amount) ?? "-"
                )}
              </TokenInfoBox>
            );
          })}
        </FlexColumn>
      </FlexColumn>
      <HR />
      <FlexColumn gap={12}>
        <PriceRangBar>
          <TitleB1>Price Range</TitleB1>
          <SwitchMenuTab
            menu={[tokenInfo[0].ticker, tokenInfo[1].ticker]}
            defaultSelected={selected}
            onSelect={(value: string) => {
              setSelected(value === tokenInfo[0].ticker ? 0 : 1);
            }}
            width={140}
          />
        </PriceRangBar>
        <MinMax>
          <TokenPrice
            title="Min"
            subTitle={subTitle[selected]}
            amount={formatPrice(
              baseSelected === selected ? priceRange.min : priceRange.swapMin
            )}
          />
          <TokenPrice
            title="Max"
            subTitle={subTitle[selected]}
            amount={formatPrice(
              baseSelected === selected ? priceRange.max : priceRange.swapMax
            )}
          />
        </MinMax>
      </FlexColumn>
      <FlexColumnBox>
        <TitleB1>Fee Tier</TitleB1>
        <FeeBox gap={8}>
          <FeeBoxTitle>{feeTier / FeeTierPercentage}%</FeeBoxTitle>
          {FEE_TIERS[feeTier]?.description && (
            <FeeBoxCheck>
              <CheckMarkIcon />
              {FEE_TIERS[feeTier]?.description}
            </FeeBoxCheck>
          )}
        </FeeBox>
        {zapIn && (
          <FlexColumn gap={8}>
            <Flex justifyContent="space-between">
              <SubtitleB1>Est. Total Gas Fee</SubtitleB1>
              <SubtitleB1>{estimatedGas ?? "-"}</SubtitleB1>
            </Flex>
            <Flex justifyContent="space-between">
              <SubtitleB1>Slippage Setting</SubtitleB1>
              <SubtitleB1>{slippage}%</SubtitleB1>
            </Flex>
            {apertureFee && (
              <FeeInfoCard
                styles={{ size: "md" }}
                amm={amm}
                feeInfo={{
                  fee: apertureFee,
                  amount: `${total}`,
                }}
                hint={`${apertureFee * 100}% of the position size.`}
              />
            )}
            <Flex alignItems="center" justifyContent="space-between">
              <SubtitleB1>Order Routing</SubtitleB1>
              {swapInfo && (
                <Flex gap={2} alignItems="center">
                  <IconTag gap="sm">
                    <IconWrapper>{swapInfo?.api?.Icon}</IconWrapper>
                    <SubtitleB1>{swapInfo?.api?.name}</SubtitleB1>
                  </IconTag>
                  <Popover
                    defaultPosition={{ bottom: 30, left: -380 }}
                    content={
                      <SwapRoute
                        api={swapInfo?.api}
                        priceImpact={swapInfo?.priceImpact}
                        fromToken={swapInfo?.fromToken}
                        routes={swapInfo?.swapRoutes}
                        gasFeeUSD={swapInfo?.gasFeeUSD}
                        loading={false}
                        maxWidth={400}
                        dark={true}
                      />
                    }
                  >
                    <StyledViewIcon />
                  </Popover>
                </Flex>
              )}
            </Flex>
          </FlexColumn>
        )}
      </FlexColumnBox>
    </ContentWrapper>
  );
};

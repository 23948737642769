import { ButtonText1 } from "@ui/components/Typography";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { LayoutProps } from "styled-system";
import { StyledBar, StyledItem, StyledSlider } from "../SharedTab";
import { MenuTabProps } from "./types";

const TabBar = styled(StyledBar)<{
  height?: LayoutProps["height"];
}>`
  background: ${({ theme }) => theme.colors.global.background.BG3};
  border-radius: ${({ theme }) => theme.radii.round};
  padding: 6px;
  height: ${({ height }) => height ?? "29px"};
`;
const TabSlider = styled(StyledSlider)<{ height?: string }>`
  background: ${({ theme }) => theme.colors.global.text.T1};
  border-radius: ${({ theme }) => theme.radii.round};
  height: ${({ height }) => height ?? "29px"};
`;

const TabItems = styled(StyledItem)<{ isSelected: boolean }>`
  height: calc(100% - 8px);
  padding: 4px 16px;
  transition: color 0.3s linear;
  z-index: ${({ theme }) => theme.zIndices.tab};
  color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.colors.global.background.BG1
      : theme.colors.global.text.T1};
  border-radius: ${({ theme }) => theme.radii.round};
`;

const StyledText = styled(ButtonText1)`
  line-height: 20px;
`;

export const SwitchMenuTab: React.FC<MenuTabProps> = ({
  menu,
  defaultSelected = 0,
  onSelect,
  ...props
}) => {
  const menuLength = menu.length ?? 1;
  const [selected, setSelected] = useState(
    defaultSelected < 0 || defaultSelected > menuLength ? 0 : defaultSelected
  );

  useEffect(() => {
    onSelect(menu[selected]);
  }, [onSelect, menu, selected]);

  return (
    <TabBar {...props}>
      <TabSlider
        selectedIndex={selected}
        length={menuLength}
        containerPadding="8px"
        height={typeof props.height === "string" ? props.height : undefined}
      />
      {menu.map((item, index) => (
        <TabItems
          key={`${index}-${item}`}
          itemWidth={100 / menuLength}
          onClick={() => {
            setSelected(index);
          }}
          isSelected={index === selected}
        >
          <StyledText>{item}</StyledText>
        </TabItems>
      ))}
    </TabBar>
  );
};

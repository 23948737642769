import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { SupportedChainId, getChainInfo } from "../../../utils";
import { Dropdown, IDropdownRef } from "../../Dropdown";
import {
  AmmBox,
  AmmContainer,
  ButtonTextBox,
  ChainItem,
  ChevronDown,
  ContentContainer,
  ContentContainerBox,
  IconWithAmm,
  SelectedMark,
  StyledBox,
  Title,
  TitleClose,
  TitleText,
} from "./style";
import { NetworkProps } from "./types";

export const NetworkSelector: React.FC<NetworkProps> = ({
  currentAmm,
  supportedAmmList,
  currentChainId,
  supportedChainIdList,
  chainOnSelect,
}) => {
  const selectedChain = getChainInfo(currentChainId);
  const NetworkIcon = selectedChain.Icon;
  const AmmIcon = currentAmm ? currentAmm.Icon : selectedChain.amm[0].Icon;
  const ammName = currentAmm ? currentAmm.name : selectedChain.amm[0].name;
  const dropDownRef = useRef<IDropdownRef>(null);
  const [ammSelection, setAmmSelection] = useState(currentAmm);

  useEffect(() => {
    if (currentAmm) setAmmSelection(currentAmm);
  }, [currentAmm]);

  const handleOnSelect = (id: SupportedChainId) => {
    if (ammName === ammSelection?.name && currentChainId === id) return;
    dropDownRef?.current?.onClose();
    chainOnSelect && chainOnSelect(ammSelection, id);
  };

  const handleClose = () => {
    dropDownRef?.current?.onClose();
  };

  const handleBackgroundClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const dropdownBtn = (
    <StyledBox>
      <IconWithAmm>
        <NetworkIcon width={28} height={28} />
        <AmmIcon
          width={16}
          height={16}
          style={{ position: "absolute", right: "0", bottom: "0" }}
        />
      </IconWithAmm>
      <ChevronDown />
    </StyledBox>
  );

  return (
    <Dropdown ref={dropDownRef} button={dropdownBtn}>
      <ContentContainerBox onClick={handleBackgroundClick}>
        <ContentContainer>
          <Title>
            <TitleText>Switch Network</TitleText>
            <TitleClose onClick={handleClose} />
          </Title>
          <AmmContainer gap="lg">
            {Object.entries(supportedAmmList).map(([p, { name, Icon }]) => {
              return (
                <AmmBox
                  key={"amm:" + p}
                  gap="md"
                  selected={ammSelection?.name === name}
                  onClick={() => setAmmSelection({ name, Icon })}
                >
                  <Icon width={20} height={20} />
                  <ButtonTextBox>{p}</ButtonTextBox>
                </AmmBox>
              );
            })}
          </AmmContainer>
          {supportedChainIdList.map((id) => {
            const { Icon, name, amm } = getChainInfo(id);
            return (
              amm
                .map((platform) => platform.name)
                .includes(ammSelection?.name) && (
                <ChainItem
                  selected={
                    ammName === ammSelection?.name && currentChainId === id
                  }
                  key={"chain:" + id}
                  onClick={() => {
                    handleOnSelect(id);
                  }}
                >
                  <SelectedMark
                    selected={
                      ammName === ammSelection?.name && currentChainId === id
                    }
                  />
                  <Icon width={20} height={20} />
                  <ButtonTextBox>{name}</ButtonTextBox>
                </ChainItem>
              )
            );
          })}
        </ContentContainer>
      </ContentContainerBox>
    </Dropdown>
  );
};

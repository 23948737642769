import { CaretIcon } from "@aperture/assetkit";
import { Flex, FlexColumn } from "@uiv2/common";
import { CSSProperties } from "react";
import styled, { DefaultTheme } from "styled-components";
import { T2, T3Regular } from "../Typography";
import { ActivityType } from "./types";

const getTextColor = (theme: DefaultTheme, type?: ActivityType) => {
  const colors: Partial<Record<ActivityType, string>> = {
    Deposit: theme.colors.global.success,
    Withdraw: theme.colors.global.error,
  };

  return !type || !colors[type] ? theme.colors.global.text.T1 : colors[type];
};

export const Title = styled(Flex)`
  cursor: pointer;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const TitleText = styled(T2)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const StyledT2 = styled(T2)<{
  color?: CSSProperties["color"];
  underlined?: boolean;
}>`
  font-weight: 400;
  color: ${({ theme, color }) => color ?? theme.colors.global.text.T3};
  text-decoration: ${({ underlined }) => underlined && "underline"};
`;
export const StyledT3 = styled(T3Regular)`
  color: ${({ theme }) => theme.colors.global.text.T3};
`;
export const Text = styled(T2)<{ type?: ActivityType }>`
  font-weight: 400;
  color: ${({ theme, type }) => getTextColor(theme, type)};
`;

export const ArrowBtn = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 8px;
  margin: -12px -14px -12px auto;
  border-radius: ${({ theme }) => theme.radii.md};
`;

export const ArrowDown = styled(CaretIcon)<{ isShow: boolean }>`
  width: 24px;
  height: 24px;
  transition: transform ${({ theme }) => theme.transitions.default};
  transform: ${({ isShow }) => (isShow ? "rotate(180deg)" : "")};
  path {
    fill: ${({ theme }) => theme.colors.global.text.T1};
  }
`;

export const Content = styled.div<{
  isShow: boolean;
  useOverflow: boolean;
  length: number;
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
  overflow: ${({ useOverflow }) => (useOverflow ? "initial" : "hidden")};
  padding-top: ${({ isShow }) => (isShow ? "12px" : "0")};
  height: ${({ isShow, length }) =>
    isShow ? `calc(62px + ${44 * length}px + ${24 * (length - 1)}px)` : "0"};
  transition: height ${({ theme }) => theme.transitions.default},
    padding-top ${({ theme }) => theme.transitions.default};
`;

export const Wrapper = styled(FlexColumn)`
  padding: 16px;
  border-radius: ${({ theme }) => theme.radii.lg};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
`;
export const Row = styled(Flex)`
  justify-content: space-between;
  gap: 12.5px;
  > div {
    width: 172px;
    :last-child {
      text-align: right;
    }
  }
`;
export const Item = styled(FlexColumn)`
  gap: ${({ theme }) => theme.spacing.sm};
`;
export const Line = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.global.line.Line1};
`;

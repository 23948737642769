import { useNetwork } from "@/components/NetworkContext/NetworkContext";
import { SLIPSTREAM_NETWORKS } from "@/utils/helper";
import { viem } from "@aperture_finance/uniswap-v3-automation-sdk";
import useSWR from "swr/immutable";

export function useFetchSlipstreamPools() {
  const { publicClient, networkId, ammEnum } = useNetwork();
  const showFetch =
    ammEnum === "SLIPSTREAM" && SLIPSTREAM_NETWORKS.includes(networkId);

  return useSWR(showFetch ? `slipstream-pools-${networkId}` : null, () =>
    viem.getSlipStreamPools(publicClient, networkId)
  );
}

export function getSlipStreamPool(
  pools: viem.SlipStreamPool[],
  token0: string,
  token1: string,
  fee: number
) {
  return pools.find(
    (pool) =>
      ((pool.token0 === token0 && pool.token1 === token1) ||
        (pool.token0 === token1 && pool.token1 === token0)) &&
      pool.fee === fee
  );
}

import { ITokenTickerIconPair } from "@aperture/types";
import { FeeAmount } from "@aperture_finance/uniswap-v3-sdk";
import { CSSProperties } from "react";
import { FeeTierPercentage } from "../FeeTier";
import { AutoCompoundTag, Variant, variants } from "../Tag";
import { TokenIcons } from "../TokenIcons";
import {
  PercentageTag,
  TitleContainer,
  TokenText,
  TokenWrapper,
} from "./style";

export const PositionInfoTitle: React.FC<{
  tokens: ITokenTickerIconPair;
  feeTier?: FeeAmount;
  titleSize?: "regular" | "sm" | "lg" | "lg2";
  fontSize?: string;
  lineHeight?: string;
  padding?: string;
  size?: number;
  autoCompound?: Boolean;
  tagVariant?: Variant;
  wordOnly?: boolean;
  iconGap?: number;
  tagGap?: number;
  color?: CSSProperties["color"];
}> = ({
  tokens,
  feeTier,
  titleSize = "regular",
  fontSize = "14px",
  lineHeight = "16px",
  padding = "6px 12px",
  size = 32,
  color,
  autoCompound,
  tagVariant,
  wordOnly,
  iconGap,
  tagGap,
}) => {
  return (
    <TitleContainer gap={tagGap ?? "sm2"}>
      <TokenWrapper gap={iconGap ?? "sm2"}>
        <TokenIcons
          overlap={8}
          leftIcon={tokens[0]?.Icon}
          rightIcon={tokens[1]?.Icon}
          size={size}
        />
        <TokenText size={titleSize}>
          {tokens[0]?.ticker}/{tokens[1]?.ticker}
        </TokenText>
      </TokenWrapper>
      <TokenWrapper gap="sm">
        {feeTier && (
          <PercentageTag
            fontSize={fontSize}
            lineHeight={lineHeight}
            padding={padding}
            color={color}
            variant={tagVariant ?? variants.GRAY}
          >
            {feeTier / FeeTierPercentage}%
          </PercentageTag>
        )}
        {autoCompound && <AutoCompoundTag autoCompound wordOnly={wordOnly} />}
      </TokenWrapper>
    </TitleContainer>
  );
};

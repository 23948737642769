import { CopyFileIcon } from "@aperture/assetkit";
import { Flex, FlexColumn } from "@uiv2/common";
import { getChainInfo, timestampFormat } from "@uiv2/utils";
import { useCopyText } from "@uiv2/utils/hooks";
import { formatFixed, formatUSDAmount } from "@uiv2/utils/numberFormat";
import { useTheme } from "styled-components";
import { PositionInfoTitle } from "../PositionInfoTitle";
import { MarketPrice } from "./MarketPrice";
import { PriceRange } from "./PriceRange";
import {
  APR,
  APRTextLg,
  CardContainer,
  ChainInfo,
  CopyWrapper,
  CurrentChainDot,
  Label,
  Line,
  Link,
  Row,
  Section,
  StyledButton,
  StyledInRangeIcon,
  Text,
  Timestamp,
  Value,
} from "./style";
import { IClosedPositionCardProps } from "./types";

export const ClosedPositionCard: React.FC<IClosedPositionCardProps> = ({
  positionId,
  tokens,
  supportedChainId,
  isCurrentChain,
  closedTimestamp,
  closedLiquidityUSD,
  closedAccruedFeesUSD,
  closedPriceRange,
  closedMarketPrice,
  closedTotalAPR,
  closedFeeAPR,
  onClick,
}) => {
  const { hasCopied, handleCopyAddress } = useCopyText(positionId);
  const theme = useTheme();
  const { name, Icon } = getChainInfo(supportedChainId);
  const { time, date } = timestampFormat(closedTimestamp, true, true);

  return (
    <CardContainer gap="xl">
      <Section>
        <Row>
          <Label>Position ID</Label>
          <Flex gap="md">
            <Link underline={!!onClick} onClick={onClick}>
              <Text>#{positionId}</Text>
            </Link>
            <CopyWrapper onClick={handleCopyAddress}>
              {hasCopied ? (
                <StyledInRangeIcon width={20} height={20} />
              ) : (
                <CopyFileIcon
                  width={20}
                  height={20}
                  fill={theme.colors.global.text.T1}
                />
              )}
            </CopyWrapper>
          </Flex>
        </Row>
        <Row>
          <PositionInfoTitle size={20} tokens={tokens} />
          <ChainInfo gap="md" alignItems="center">
            {isCurrentChain && <CurrentChainDot size={8} />}
            <Icon width={20} height={20} />
            <Value>{name}</Value>
          </ChainInfo>
        </Row>
        <FlexColumn gap="md">
          {closedLiquidityUSD && (
            <Row>
              <Label>Liquidity</Label>
              <Text>{formatUSDAmount(closedLiquidityUSD)}</Text>
            </Row>
          )}
          {closedAccruedFeesUSD && (
            <Row>
              <Label>Accrued Fees</Label>
              <Text>{formatUSDAmount(closedAccruedFeesUSD)}</Text>
            </Row>
          )}
        </FlexColumn>
      </Section>
      <Line />
      <Section>
        <Value>Price Range</Value>
        {closedMarketPrice && (
          <Row>
            <Label>Market Price</Label>
            <MarketPrice {...{ tokens, marketPrice: closedMarketPrice }} />
          </Row>
        )}
        <PriceRange tokens={tokens} priceRange={closedPriceRange} />
        {onClick && (
          <StyledButton variant="default" width="100%" onClick={onClick}>
            View Details
          </StyledButton>
        )}
      </Section>
      <Line />
      <Row>
        <APR ta="left" sm>
          <Label>Total APR</Label>
          <APRTextLg isPositive={Number(closedTotalAPR) >= 0}>
            {formatFixed(closedTotalAPR, 2)}%
          </APRTextLg>
        </APR>
        <APR ta="right" sm>
          <Label>Fee APR</Label>
          <APRTextLg isPositive={Number(closedFeeAPR) >= 0}>
            {formatFixed(closedFeeAPR, 2)}%
          </APRTextLg>
        </APR>
      </Row>
      {closedTimestamp && (
        <>
          <Line />
          <Timestamp>
            Closed at {time.toUpperCase()}, {date}
          </Timestamp>
        </>
      )}
    </CardContainer>
  );
};

import { ViewIcon2 } from "@aperture/assetkit";
import { Flex, FlexColumn } from "@ui/common";
import { Checkbox, Popover, SwapRoute, formatFixed } from "@ui/index";
import styled from "styled-components";
import { Headline5 } from "../../Typography";
import { OrderRoutingProps } from "../types";

const TopTitle = styled(Headline5)`
  font-family: "Dunbar Tall";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const CardBox = styled(Flex)<{ selected?: boolean }>`
  cursor: pointer;
  padding: 12px 16px;
  border-radius: 16px;
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.colors.global.line.Line2 : "transparent"};
`;
const Title = styled.div`
  font-family: "Roboto Flex";
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const Subtitle = styled.div`
  font-family: "Roboto Flex";
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.global.text.T2};
`;
const Text1 = styled.div`
  font-family: "Roboto Flex";
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const Text2 = styled(Text1)`
  line-height: 20px;
`;
const Text3 = styled(Text2)`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.global.success};
`;
const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
  > svg {
    width: 16px;
    height: 16px;
  }
`;
const CheckboxWrapper = styled.div`
  margin-left: auto;
`;
const StyledViewIcon = styled(ViewIcon2)`
  width: 16px;
  height: 16px;
  fill: ${({ theme }) => theme.colors.global.text.T2};
  &:hover {
    fill: ${({ theme }) => theme.colors.global.primary};
  }
`;

export const OrderRoutingContent: React.FC<OrderRoutingProps> = ({
  swapInfo,
}) => {
  return (
    <FlexColumn>
      <TopTitle>Order Routing</TopTitle>
      <FlexColumn gap="xl">
        <Title>Select Swap API</Title>
        <FlexColumn gap="sm">
          {swapInfo.map(
            ({
              selected,
              suggested,
              onClick,
              api,
              loading,
              errorMsg,
              showSwapRoutes,
              priceImpact,
              fromToken,
              swapData,
              swapRoutes,
              maxSwapRouteWidth,
              gasFeeUSD,
            }) => (
              <CardBox
                gap="md"
                selected={!!selected}
                onClick={onClick}
                alignItems="center"
                justifyContent="space-between"
                key={api?.name}
              >
                <Flex gap="md" alignItems="center">
                  <FlexColumn gap="md" style={{ minWidth: "150px" }}>
                    <Flex gap="sm" alignItems="center">
                      <IconWrapper>{api?.Icon}</IconWrapper>
                      <Text1>{api?.name}</Text1>
                      {!loading && !errorMsg && showSwapRoutes && (
                        <Popover
                          defaultPosition={{ bottom: 28, left: -80 }}
                          content={
                            <SwapRoute
                              noConnectingLine={false}
                              {...{
                                dark: true,
                                loading: false,
                                priceImpact,
                                api,
                                fromToken,
                                routes: swapRoutes,
                                maxWidth: maxSwapRouteWidth,
                                gasFeeUSD,
                              }}
                            />
                          }
                        >
                          <StyledViewIcon />
                        </Popover>
                      )}
                    </Flex>
                    <Flex gap="sm" alignItems="center">
                      <Subtitle>Est. Price Impact</Subtitle>
                      <Text2>{formatFixed(priceImpact ?? "", 2)}%</Text2>
                    </Flex>
                  </FlexColumn>
                  <Flex gap="lg">
                    {swapData?.map((data, index) => (
                      <FlexColumn
                        gap="md"
                        key={`${data.to.ticker}-${index}`}
                        style={{ minWidth: "148px" }}
                      >
                        <Text2>
                          Swap {fromToken?.ticker} to {data.to.ticker}
                        </Text2>
                        <FlexColumn gap={2}>
                          <Subtitle>Amount In</Subtitle>
                          <Text2>
                            {data.amountIn} {fromToken?.ticker}
                          </Text2>
                        </FlexColumn>
                        <FlexColumn gap={2}>
                          <Subtitle>Expected Amount Out</Subtitle>
                          <Text2>
                            {data.amountOut} {data.to.ticker}
                          </Text2>
                        </FlexColumn>
                        <FlexColumn gap={2}>
                          <Subtitle>Min. Out After Slippage</Subtitle>
                          <Text2>
                            {data.minAmountOut} {data.to.ticker}
                          </Text2>
                        </FlexColumn>
                      </FlexColumn>
                    ))}
                  </Flex>
                </Flex>
                <Flex gap="sm" alignItems="center" style={{ minWidth: "74px" }}>
                  {suggested && <Text3>Suggested</Text3>}
                  <CheckboxWrapper>
                    <Checkbox checked={!!selected} size={16} />
                  </CheckboxWrapper>
                </Flex>
              </CardBox>
            )
          )}
        </FlexColumn>
      </FlexColumn>
    </FlexColumn>
  );
};

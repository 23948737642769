import { InfoIcon } from "@aperture/assetkit";
import { Flex, FlexColumn } from "@ui/common";
import { InfoPopover } from "@ui/components/Popover";
import { Body1, Body2, Subtitle0 } from "@ui/components/Typography";
import bigDecimal from "js-big-decimal";
import styled from "styled-components";
import {
  formatTokenAmount,
  MIN_FIXED_VALUE,
} from "../../../utils/numberFormat";
import { PreviewStakeProps } from "../types";

const ContentWrapper = styled(FlexColumn)`
  width: 444px;
  gap: ${({ theme }) => theme.spacing.xl};
`;
const TitleText = styled(Subtitle0)`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-family: "Dunbar Tall";
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const DetailsWrapper = styled(FlexColumn)`
  gap: ${({ theme }) => theme.spacing.lg};
`;
const InfoWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
`;
const InfoTitle = styled(Body1)`
  line-height: 16px;
  font-family: "Roboto Flex";
  color: ${({ theme }) => theme.colors.global.text.T2};
`;
const InfoValueWrapper = styled(Flex)`
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
`;
const InfoValue = styled(Subtitle0)<{ highlight?: boolean }>`
  font-size: 20px;
  font-family: "Roboto Flex";
  color: ${({ theme, highlight }) =>
    highlight ? theme.colors.global.success : theme.colors.global.text.T1};
`;
const InfoUnit = styled(Body2)`
  line-height: 14px;
  font-family: "Roboto Flex";
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const HR = styled.hr`
  width: 100%;
  margin: 0;
  border-bottom: none;
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line2};
`;
const InfoIconWrapper = styled.div`
  height: 14px;
  width: 14px;
  svg {
    fill: ${({ theme }) => theme.colors.grey.mediumGrey};
  }
`;
const InfoText = styled(Body2)`
  line-height: 14px;
  font-family: "Roboto Flex";
  color: ${({ theme }) => theme.colors.global.text.T2};
`;

export const PreviewStakeContent: React.FC<PreviewStakeProps> = ({
  token,
  days,
  APR,
  share,
}) => {
  return (
    <ContentWrapper>
      <TitleText>Preview Stake</TitleText>
      <DetailsWrapper>
        <InfoWrapper>
          <InfoTitle>Stake Token</InfoTitle>
          <InfoValueWrapper>
            <Flex gap="sm">
              <token.Icon width={20} height={20} />
              <InfoValue>{formatTokenAmount(token.amount)}</InfoValue>
            </Flex>
            <InfoUnit>{token.ticker}</InfoUnit>
          </InfoValueWrapper>
        </InfoWrapper>
        <InfoWrapper>
          <InfoTitle>Stake Period</InfoTitle>
          <InfoValueWrapper>
            <InfoValue>{days}</InfoValue>
            <InfoUnit>Days</InfoUnit>
          </InfoValueWrapper>
        </InfoWrapper>
        <InfoWrapper>
          <Flex gap="sm">
            <InfoTitle>Staking APR</InfoTitle>
            <InfoPopover
              content="USDC APR is calculated based on the current price of APTR token."
              iconSize={14}
            />
          </Flex>
          <Flex gap="lg">
            <InfoValueWrapper>
              <InfoValue highlight>{`${APR[0].percent}%`}</InfoValue>
              <InfoUnit>{APR[0].unit}</InfoUnit>
            </InfoValueWrapper>
            <InfoValueWrapper>
              <InfoValue highlight>{`${APR[1].percent}%`}</InfoValue>
              <InfoUnit>{APR[1].unit}</InfoUnit>
            </InfoValueWrapper>
          </Flex>
        </InfoWrapper>
        <InfoWrapper>
          <InfoTitle>Staking Share</InfoTitle>
          <InfoValueWrapper>
            <InfoValue>{`${
              bigDecimal.compareTo(share, MIN_FIXED_VALUE) >= 0
                ? formatTokenAmount(share)
                : `<${MIN_FIXED_VALUE}`
            }%`}</InfoValue>
          </InfoValueWrapper>
        </InfoWrapper>
        <HR />
        <Flex gap="sm">
          <InfoIconWrapper>
            <InfoIcon width={14} height={14} />
          </InfoIconWrapper>
          <InfoText>
            You will only be able to redeem your staked tokens in full after the
            stake period ends.
          </InfoText>
        </Flex>
      </DetailsWrapper>
    </ContentWrapper>
  );
};

import React from "react";
import styled from "styled-components";
import { compose, layout, space, typography } from "styled-system";
import { UITypographyProps } from "./types";

const StyledHeadline1 = styled.div<UITypographyProps>`
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  ${compose(layout, space, typography)}
`;
export const Headline1: React.FC<
  React.PropsWithChildren<UITypographyProps>
> = ({ children, ...props }) => (
  <StyledHeadline1 {...props}>{children}</StyledHeadline1>
);

const StyledHeadline2 = styled.div<UITypographyProps>`
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  ${compose(layout, space, typography)}
`;
export const Headline2: React.FC<
  React.PropsWithChildren<UITypographyProps>
> = ({ children, ...props }) => (
  <StyledHeadline2 {...props}>{children}</StyledHeadline2>
);

const StyledHeadline3 = styled.div<UITypographyProps>`
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  ${compose(layout, space, typography)}
`;
export const Headline3: React.FC<
  React.PropsWithChildren<UITypographyProps>
> = ({ children, ...props }) => (
  <StyledHeadline3 {...props}>{children}</StyledHeadline3>
);

const StyledHeadline4 = styled.div<UITypographyProps>`
  font-size: 18px;
  line-height: 18px;
  ${compose(layout, space, typography)}
`;
export const Headline4: React.FC<
  React.PropsWithChildren<UITypographyProps>
> = ({ children, ...props }) => (
  <StyledHeadline4 {...props}>{children}</StyledHeadline4>
);

const StyledHeadline5 = styled.div<UITypographyProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.0025em;
  ${compose(layout, space, typography)}
`;
export const Headline5: React.FC<
  React.PropsWithChildren<UITypographyProps>
> = ({ children, ...props }) => (
  <StyledHeadline5 {...props}>{children}</StyledHeadline5>
);

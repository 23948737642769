import { IToken } from "@aperture/types";
import { TriggerItem } from "@aperture_finance/uniswap-v3-automation-sdk";
import { trimNumber } from "@ui/utils/numberFormat";
import bigDecimal from "js-big-decimal";
export const convertRelativePriceToHumanValue = (
  value: string,
  tokens: IToken[]
) => {
  const decimalsDiff = tokens[0].decimals - tokens[1].decimals;
  return bigDecimal.multiply(value, Math.pow(10, decimalsDiff));
};

export const getType = (trigger: TriggerItem) => {
  if (trigger.condition.type === "Time") {
    return "Time";
  } else if (trigger.condition.type === "Price") {
    const frontendType = trigger.condition.frontendType;
    const singleToken = trigger.condition.singleToken;
    return (frontendType === "RELATIVE_PRICE" && singleToken === undefined) ||
      (frontendType === undefined && singleToken !== undefined)
      ? "Price"
      : "Ratio";
  } else if (trigger.condition.type === "RecurringPrice") {
    return "Price in Token Terms";
  } else if (trigger.condition.type === "RecurringRatio") {
    return "Ratio";
  } else if (trigger.condition.type === "RecurringPercentage") {
    return "Price in Percentage";
  }
};

export const getTriggerNotification = () => {
  return "Triggers will wait until at least the specified duration before triggering. Max TWAP duration is 24 hrs. However, if the pool price oracle doesn't have enough data to cover specified duration, then only the available data will be used to compute the TWAP.";
};

export const getTriggerPopoverText = (type: string, token?: string) => {
  switch (type) {
    case "Price":
      return "The price is retrieved from the underlying liquidity pool.";
    case "Ratio":
      return `The ratio is between the value of ${token} and the total LP value, using the liquidity pool price.`;
    default:
      return "";
  }
};

export const formatDuration = (durationSec?: number) =>
  durationSec
    ? `for ${trimNumber(bigDecimal.divide(durationSec, "3600", 2))} ${
        bigDecimal.compareTo(durationSec, 3600) > 0 ? "hrs" : "hr"
      }`
    : "";

export const isStrategyTrigger = (trigger: TriggerItem) => {
  return ["RecurringPrice", "RecurringRatio", "RecurringPercentage"].includes(
    trigger.condition.type
  );
};

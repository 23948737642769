import { RecurringAction } from "@aperture_finance/uniswap-v3-automation-sdk";
import { useEventCallback } from "@mui/material";
import { TriggerType } from "@ui/common/types";
import { useDebounce } from "ahooks";
import bigDecimal from "js-big-decimal";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  E_TriggerTypeTab,
  E_WidthUnit,
  IDualTriggerState,
  IEditTriggerSetupProps,
  IEditTriggerState,
  IPureEditTriggerProps,
} from "../type";
import { getDefaultCacheForm } from "../utils";
import { PureEditTriggerSetup } from "./PureEditTrigger";

const defaultTokenSpotPrice: [string, string] = ["0", "0"];

export const EditTrigger: React.FC<
  IEditTriggerSetupProps & {
    triggerIdx: IPureEditTriggerProps["triggerIdx"];
  }
> = ({
  triggerIdx,
  initForm,
  tokens,
  tokenSpotPrice = defaultTokenSpotPrice, // may fetch in delay
  defaultAction,
  tickSpacing,
  tokenRatio,
  onSave,
  onDiscard,
  isAddTrigger,
  ...props
}) => {
  const getTokenRatio = useCallback(
    (tokenIdx: number) => {
      return tokenIdx === 0 ? tokenRatio : 10000 - tokenRatio;
    },
    [tokenRatio]
  );
  const { form, updateForm } = useForm(
    initForm,
    getDefaultCacheForm(
      getTokenRatio(initForm.baseToken),
      tokenSpotPrice[initForm.baseToken],
      tokens[1 - initForm.baseToken].decimals,
      true
    )
  );

  const triggerTab: E_TriggerTypeTab =
    initForm.triggerType === TriggerType.RATIO
      ? E_TriggerTypeTab.Ratio
      : E_TriggerTypeTab.Price;
  const [aboveTriggerAction, setAboveTriggerAction] = useState<
    RecurringAction | undefined
  >(form.aboveAction);
  const [belowTriggerAction, setBelowTriggerAction] = useState<
    RecurringAction | undefined
  >(form.belowAction);

  const widthUnit: E_WidthUnit =
    initForm.triggerType === TriggerType.PRICE_PERCENTAGE
      ? E_WidthUnit.Percentage
      : E_WidthUnit.TokenTerm;

  const handleInputAboveChange = useEventCallback((value: string) => {
    updateForm({
      above: value,
    });
  });

  const handleInputBelowChange = useEventCallback((value: string) => {
    updateForm({
      below: value,
    });
  });

  const handleSave = useEventCallback(() => {
    onSave &&
      onSave({
        ...form,
        aboveAction: aboveTriggerAction,
        belowAction: belowTriggerAction,
      });
  });

  const { invalidInput, errorMsg } = useMemo(() => {
    return validateForm(form, tokenSpotPrice, getTokenRatio(form.baseToken));
  }, [form, tokenSpotPrice, getTokenRatio]);

  return (
    <PureEditTriggerSetup
      triggerIdx={triggerIdx}
      triggerType={triggerTab}
      widthUnit={widthUnit}
      tokens={tokens}
      selectedToken={tokens[form.baseToken]}
      aboveChecked={form.aboveChecked}
      above={form.above}
      onAboveInput={handleInputAboveChange}
      belowChecked={form.belowChecked}
      below={form.below}
      onBelowInput={handleInputBelowChange}
      tickSpacing={tickSpacing}
      defaultAction={defaultAction}
      tokenMarketPrice={{
        tokenA: tokenSpotPrice[0],
        tokenB: tokenSpotPrice[1],
      }}
      aboveTriggerAction={aboveTriggerAction}
      setAboveTriggerAction={setAboveTriggerAction}
      belowTriggerAction={belowTriggerAction}
      setBelowTriggerAction={setBelowTriggerAction}
      onSave={handleSave}
      onDiscard={() => onDiscard?.()}
      isAddTrigger={isAddTrigger}
      invalidInput={invalidInput}
      errorMsg={useDebounce(errorMsg, { wait: 500 })}
      {...props}
    />
  );
};

function useForm(
  initForm: IEditTriggerState,
  defaultForms: ReturnType<typeof getDefaultCacheForm>
) {
  const [form, setForm] = useState<IDualTriggerState>(initForm);

  const inputCache = useRef<{
    [key: string]: IDualTriggerState;
  }>({
    ...defaultForms,
  });

  const updateForm = useEventCallback((_form: Partial<IDualTriggerState>) => {
    // cache form
    const currentTriggerType = form.triggerType;
    inputCache.current[currentTriggerType] = {
      ...inputCache.current[currentTriggerType],
      ..._form,
    };

    setForm((prevState) => ({
      ...prevState,
      ..._form,
    }));
  });

  useEffect(() => {
    inputCache.current[initForm.triggerType] = initForm;
    setForm(initForm);
  }, [initForm]);

  return {
    form,
    updateForm,
  };
}

function validateForm(
  form: IDualTriggerState,
  tokenSpotPrice: [string, string],
  tokenRatio: number
): Pick<IPureEditTriggerProps, "invalidInput" | "errorMsg"> {
  const { above, aboveChecked, belowChecked, below, triggerType } = form;
  const spotPrice = tokenSpotPrice[form.baseToken];

  if (aboveChecked && !above) {
    return {
      invalidInput: "above",
      errorMsg: "",
    };
  }

  if (belowChecked && !below) {
    return {
      invalidInput: "below",
      errorMsg: "",
    };
  }

  if (triggerType === TriggerType.RATIO) {
    if (aboveChecked) {
      if (bigDecimal.compareTo(above, tokenRatio / 100) < 0) {
        return {
          invalidInput: "above",
          errorMsg:
            "Upper threshold should be greater than the current token ratio.",
        };
      }
    }

    if (belowChecked) {
      if (bigDecimal.compareTo(tokenRatio / 100, below) < 0) {
        return {
          invalidInput: "below",
          errorMsg:
            "Lower threshold should be less than the current token ratio.",
        };
      }
    }
  } else if (belowChecked) {
    if (triggerType === TriggerType.PRICE_PERCENTAGE) {
      if (parseFloat(below) > 100) {
        return {
          invalidInput: "below",
          errorMsg:
            "The price percentage can only go down to 100% and the absolute token terms cannot exceed the value of the spot price at trigger creation.",
        };
      }
    } else {
      if (bigDecimal.compareTo(below, spotPrice) > 0) {
        return {
          invalidInput: "below",
          errorMsg:
            "The price percentage can only go down to 100% and the absolute token terms cannot exceed the value of the spot price at trigger creation.",
        };
      }
    }
  }

  return {
    invalidInput: "",
    errorMsg: "",
  };
}

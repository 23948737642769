import { WarningIcon } from "@aperture/assetkit";
import { IToken } from "@aperture/types";
import { Flex, FlexColumn } from "@uiv2/common";
import { BasicInfo, NetworkInfo } from "@uiv2/utils";
import { DefaultTheme, useTheme } from "styled-components";
import { DropdownSelector } from "./DropdownSelector";
import { DropdownTokenSelector } from "./DropdownTokenSelector";
import {
  Balance,
  DropdownItem,
  IconWrapper,
  StyledT2,
  StyledT3,
} from "./style";
import { IDropdownTokenSelectorProps, ISelectorProps } from "./types";

interface IOptionProps<T> {
  Icon: any;
  name: string;
  isSelected?: boolean;
  inModal?: boolean;
}
const Option = <T,>({ Icon, name, isSelected, ...props }: IOptionProps<T>) => {
  return (
    <DropdownItem {...props} selected={isSelected}>
      <IconWrapper>
        <Icon />
      </IconWrapper>
      <StyledT2>{name}</StyledT2>
      <StyledT2>{props.inModal}</StyledT2>
    </DropdownItem>
  );
};

interface IDropdownBtnProps<T extends { Icon: any; name: string }> {
  placeholder: string;
  selected?: T;
}
const DropdownBtn = <T extends { Icon: any; name: string }>({
  selected,
  placeholder,
}: IDropdownBtnProps<T>) => {
  if (!selected) return <StyledT2>{placeholder}</StyledT2>;
  const { Icon, name } = selected;
  return (
    <Flex gap={10}>
      <IconWrapper>
        <Icon />
      </IconWrapper>
      <StyledT2>{name}</StyledT2>
    </Flex>
  );
};

const common = (theme: DefaultTheme, inModal?: boolean) => ({
  style: { width: 400 },
  contentContainerStyles: { width: 400 },
  dropdownContainerStyles: {
    backgroundColor: inModal
      ? theme.colors.global.background.BG3
      : theme.colors.global.background.BG2,
  },
});

export const DexDropdownSelector = (
  props: Omit<ISelectorProps<BasicInfo>, "optionComponent" | "buttonComponent">
) => {
  const theme = useTheme();
  return (
    <FlexColumn gap="lg">
      <StyledT3>Select Exchange</StyledT3>
      <DropdownSelector
        {...props}
        optionComponent={Option}
        buttonComponent={
          <DropdownBtn
            selected={props.selectedOption}
            placeholder="Select Exchange"
          />
        }
        {...common(theme, props.inModal)}
      />
    </FlexColumn>
  );
};
export const NetworkDropdownSelector = (
  props: Omit<
    ISelectorProps<NetworkInfo>,
    "optionComponent" | "buttonComponent"
  >
) => {
  const theme = useTheme();
  return (
    <FlexColumn gap="lg">
      <StyledT3>Select Network</StyledT3>
      <DropdownSelector
        {...props}
        optionComponent={Option}
        buttonComponent={
          <DropdownBtn
            selected={props.selectedOption}
            placeholder="Select Network"
          />
        }
        {...common(theme, props.inModal)}
      />
    </FlexColumn>
  );
};

const TokenOption = (
  props: IToken & { isSelected?: boolean; onClick: () => void }
) => {
  const { Icon, ticker, formatAmount, isSelected, warning, onClick } = props;
  return (
    <DropdownItem {...props} selected={isSelected} onClick={onClick}>
      <IconWrapper>{Icon}</IconWrapper>
      <StyledT2>{ticker}</StyledT2>
      {warning && <WarningIcon height={16} width={16} />}
      <Balance>{formatAmount}</Balance>
    </DropdownItem>
  );
};
export const TokenDropdownSelector = (
  props: Omit<
    IDropdownTokenSelectorProps,
    "optionComponent" | "buttonComponent"
  >
) => {
  const theme = useTheme();
  const { selectedToken, tokens } = props;
  const dropdownBtn = selectedToken ? (
    <Flex alignItems="center" gap={10}>
      <IconWrapper>{selectedToken.Icon}</IconWrapper>
      <StyledT2>{selectedToken.ticker}</StyledT2>
    </Flex>
  ) : (
    <StyledT2>Select A Token</StyledT2>
  );

  const searchTokens = (query: string) => {
    return tokens?.filter(
      (token) =>
        token.ticker?.toLowerCase().indexOf(query) > -1 ||
        token.address.toLowerCase().indexOf(query) > -1
    );
  };

  return (
    <DropdownTokenSelector
      {...props}
      optionComponent={TokenOption}
      buttonComponent={dropdownBtn}
      searchOptions={searchTokens}
      searchPlaceholder="Search Name or Address"
      keyExtractor={(token) => `${token.address}-${token.native}`}
      contentContainerStyles={{ width: 350 }}
      dropdownContainerStyles={{
        ...props.dropdownContainerStyles,
        ...common(theme, props.inModal).dropdownContainerStyles,
      }}
    />
  );
};

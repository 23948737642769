import { LargeIcons } from "@aperture/assetkit";
import dynamic from "next/dynamic";
import styled from "styled-components";
import { Steps } from "../../Steps";
import { StepsProps } from "../types";

const PencilIcon = dynamic(
  () => LargeIcons.Modal().then((mod) => mod.PencilIcon),
  { loading: () => <div style={{ width: 24, height: 24 }} /> }
);
const PlusIcon2 = dynamic(
  () => LargeIcons.Modal().then((mod) => mod.PlusIcon2),
  { loading: () => <div style={{ width: 24, height: 24 }} /> }
);

const ContentWrapper = styled.div.attrs({ id: "steps-modal-show" })`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 450px;
  padding-top: 38px;
  gap: ${({ theme }) => theme.spacing["3xl"]};
`;
const TextWrapper = styled.div`
  font-family: "Roboto Flex";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.global.text.T1};
  text-align: center;
  white-space: pre-wrap;
`;

export const StepsContent: React.FC<StepsProps> = ({
  amm,
  step,
  approveOnChain,
}) => {
  const title = [
    `Creating Position On ${amm === "UNISWAP_V3" ? "Uniswap" : "PancakeSwap"}.`,
    `${
      approveOnChain ? "Approve On-Chain" : "Sign An Off-Chain Message"
    } To Allow Aperture To\nAutomate Your Position Management.`,
    "Sign An Off-Chain Message To Create This Limit Order.",
  ];
  return (
    <ContentWrapper>
      <Steps
        activeIndex={step}
        stepsList={[
          "Create Position",
          `${approveOnChain ? "Approve On-Chain" : "Sign Permit"}`,
          "Sign Message",
        ]}
      />
      {step === 0 && <PlusIcon2 width={120} height={120} />}
      {step === 1 && (
        <PencilIcon width={120} height={120} style={{ margin: "6px" }} />
      )}
      {step === 2 && <PencilIcon width={120} height={120} />}
      <TextWrapper>{title[step]}</TextWrapper>
    </ContentWrapper>
  );
};

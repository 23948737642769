import { ViewIcon } from "@aperture/assetkit";
import { CSSProperties } from "react";
import styled from "styled-components";
import { Button } from "../Button";
import { Popover } from "../Popover";
import { Tag } from "../Tag";
import { Body2, Subtitle2 } from "../Typography";

export const ViewButton = styled(Button)<{ t: "filled" | "text" }>`
  height: 44px;
  gap: ${({ theme }) => theme.spacing.md2};
  padding: 12px 16px;
  border-radius: ${({ theme }) => theme.radii.round};
  background-color: ${({ theme, t }) =>
    t === "text"
      ? theme.colors.transparent.transparent
      : theme.colors.global.background.BG3};
`;
export const StyledViewIcon = styled(ViewIcon)<{
  disabled?: Boolean;
  t: "filled" | "text";
}>`
  width: 20px;
  height: 20px;
  fill: ${({ theme, disabled, t }) =>
    disabled
      ? theme.colors.grey.black
      : t === "text"
      ? theme.colors.global.primary
      : theme.colors.global.text.T1};
`;
export const Text = styled(Body2)<{
  disabled?: Boolean;
  t: "filled" | "text";
}>`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme, disabled, t }) =>
    disabled
      ? theme.colors.grey.black
      : t === "text"
      ? theme.colors.global.primary
      : theme.colors.global.text.T1};
`;
export const AxisContainer = styled.div<{ axisWidth: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.lg};
  width: ${({ axisWidth }) => `${axisWidth}px`};
  height: 120px;
  padding: 20px;
  border-radius: ${({ theme }) => theme.radii.xl};
  background-color: ${({ theme }) => theme.colors.global.background.BG3};
`;
export const Axis = styled.div`
  box-sizing: border-box;
  position: relative;
  height: 20px;
  padding: 1px 2px;
  border-radius: ${({ theme }) => theme.radii.round};
`;
export const AxisInner = styled.div`
  position: relative;
  height: 20px;
  width: 100%;
  border-radius: ${({ theme }) => theme.radii.round};
  background-color: ${({ theme }) => theme.colors.global.line.Line2};
`;
export const SpotWrapper = styled.div<{ left: number }>`
  position: absolute;
  left: ${({ left }) => `calc(${left}% - 10px)`};
  background-color: ${({ theme }) => theme.colors.global.text.T3};
`;
export const Spot = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: ${({ theme }) => theme.radii.circle};
  background-color: ${({ theme }) => theme.colors.global.text.T3};
  z-index: 1;
`;
export const SpotLabel = styled(Subtitle2)<{
  spot: number;
  isPriceTrigger: Boolean;
}>`
  position: absolute;
  right: ${({ spot, isPriceTrigger }) =>
    isPriceTrigger && spot === 0
      ? "-40.5px"
      : spot <= 0 || !isPriceTrigger
      ? "-20px"
      : undefined};
  width: max-content;
  margin-top: ${({ theme, spot, isPriceTrigger }) =>
    isPriceTrigger && spot === 0 ? "52px" : "52px"};
  color: ${({ theme }) => theme.colors.global.text.T2};
`;
export const MarkerWrapper = styled.div<{ left: number }>`
  position: absolute;
  left: ${({ left }) => `calc(${left}% - 1px)`};
`;
export const Marker = styled.div<{
  spotPrice?: Boolean;
}>`
  position: absolute;
  width: 2px;
  height: 40px;
  top: ${({ spotPrice }) => (spotPrice ? "-10px" : "-10px")};
  background-color: ${({ theme, spotPrice }) =>
    spotPrice ? theme.colors.global.text.T3 : theme.colors.global.primary};
`;
export const MarkerLabel = styled(Tag)<{
  left: CSSProperties["left"];
  right: CSSProperties["right"];
}>`
  position: absolute;
  margin-top: -55px;
  padding: 8px 12px;
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  background-color: ${({ theme }) => theme.colors.global.background.BG4};
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const OverlayLine = styled.div<{
  width: number;
  left?: number;
  below: boolean;
  isPriceTrigger: boolean;
}>`
  position: absolute;
  height: calc(100% + 2px);
  top: -1px;
  left: ${({ left, below }) => (below && !left ? `-2px` : `${left}%`)};
  width: ${({ width, below }) =>
    below ? `calc(${width}% + 2px)` : `calc(${width}% + 2px)`};
  background: ${({ theme, isPriceTrigger }) => theme.colors.global.primary};
  border-radius: ${({ theme, below }) =>
    below
      ? `${theme.radii.md} 0 0 ${theme.radii.md}`
      : `0 ${theme.radii.md} ${theme.radii.md} 0`};
`;

export const StyledPopover = styled(Popover)``;

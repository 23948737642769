// @ts-nocheck
import { formatPrice, formatUSDAmount } from "@uiv2/utils/numberFormat";
import * as d3 from "d3";
import { findMax, findMin } from "../utils";
import { ChartPoint, D3CorrelationChartProps, VOLUME_DAYS } from "./types";

export class D3CorrelationChart {
  containerEl;
  props;
  svg;
  x;
  y;
  xAxis;
  xAxisHeight;
  mostActivePrice;
  minPrice;
  maxPrice;
  minPercentage;
  maxPercentage;
  volumes;
  colors;

  constructor(containerEl: any, props: D3CorrelationChartProps) {
    this.containerEl = containerEl;
    this.xAxisHeight = 20;
    this.props = props;
    this.volumes = props.volumes;
    this.colors = props.colors;

    const xData = props.data.map((d) => d.x);
    const yData = props.data.map((d) => d.y);
    const data = props.data as any;

    this.svg = d3
      .select(containerEl)
      .append("svg")
      .attr("viewBox", `0 0 ${props.width} ${props.height}`);

    this.createLinearGradient("graph-gradient", this.colors.graph);

    // add x axis
    const x = d3
      .scaleTime()
      .domain([findMin(xData), findMax(xData)])
      .range([0, props.width]);
    const dom = [new Date(findMin(xData)), new Date(findMax(xData))];
    const duration = d3.timeDay.filter(
      (d) => d3.timeDay.count(dom[0], d) % 3 === 0
    ); // 3 days
    this.xAxis = this.svg
      .append("g")
      .attr(
        "transform",
        "translate(0," + (props.height - this.xAxisHeight) + ")"
      )
      .attr("color", this.colors.axis)
      .call(d3.axisBottom(x).ticks(duration, "%b %d"));
    this.x = x;

    // add y axis
    const min = findMin(yData);
    const max = findMax(yData);
    const margin = max - min;
    const y = d3
      .scaleLinear()
      .domain([min - margin, max + margin / 1.5])
      .range([props.height, 0]);
    this.y = y;

    // render chart
    this.svg
      .append("path")
      .datum(data)
      .attr("fill", "transparent")
      .attr("stroke", this.colors.graph)
      .attr("stroke-width", 1.5)
      .attr(
        "d",
        d3
          .line()
          .x(function (d: any) {
            return x(d.x);
          })
          .y(function (d: any) {
            return y(d.y);
          })
      );
    // render gradient below chart
    this.svg
      .append("path")
      .datum(data)
      .attr("fill", "url(#graph-gradient)")
      .attr(
        "d",
        d3
          .area()
          .x(function (d: any) {
            return x(d.x);
          })
          .y0(props.height)
          .y1(function (d: any) {
            return y(d.y);
          })
      );

    // Add total volume for 30 days
    if (this.volumes && this.volumes.length > 0) {
      const maxVolume = Math.max(...this.volumes);
      const heightUnit = 50;
      const revertUnit = 158;
      for (let index = 0; index < VOLUME_DAYS; index++) {
        const dayVolume = ((this.volumes[index] ?? 0) * heightUnit) / maxVolume;
        this.svg
          .append("rect")
          .style("fill", this.colors.graph)
          .attr("x", "1")
          .attr(
            "transform",
            `translate(${(props.width / VOLUME_DAYS) * index},${
              revertUnit - dayVolume
            })`
          )
          .attr("opacity", "0.6")
          .attr("width", props.width / VOLUME_DAYS)
          .attr("height", dayVolume);
      }
    }

    this.mostActivePrice = this.renderMostActivePriceAssumption(
      props.mostActivePrice
    );
    this.minPercentage = props.minPercentage;
    this.maxPercentage = props.maxPercentage;
    const { minPriceSVG, maxPriceSVG } = this.renderMinMaxPriceRange(
      props.minRange,
      props.maxRange
    );
    this.minPrice = minPriceSVG;
    this.maxPrice = maxPriceSVG;
    this.handleMouseMove();
  }

  destroy() {
    this.svg.remove();
  }

  handleMouseMove() {
    const bisect = d3.bisector(function (d: ChartPoint) {
      return d.x;
    }).left;
    const focus = this.svg
      .append("g")
      .append("circle")
      .style("fill", "rgba(255,255,255,0.15)")
      .attr("stroke", this.colors.axis)
      .attr("r", 5)
      .attr("opacity", 0);
    const focusTextDate = this.svg
      .append("g")
      .append("text")
      .style("opacity", 0)
      .attr("fill", this.colors.axis)
      .attr("font-size", "0.6rem")
      .attr("alignment-baseline", "middle");
    const focusTextPrice = this.svg
      .append("g")
      .append("text")
      .style("opacity", 0)
      .attr("fill", this.colors.axis)
      .attr("font-size", "0.6rem")
      .attr("alignment-baseline", "middle");
    const focusTextVolume = this.svg
      .append("g")
      .append("text")
      .style("opacity", 0)
      .attr("fill", this.colors.axis)
      .attr("font-size", "0.6rem")
      .attr("alignment-baseline", "middle");
    const verticalLine = this.svg
      .append("g")
      .append("line")
      .style("stroke-width", 1)
      .style("stroke", this.colors.axis);

    const onMouseMove = (e: any) => {
      let coords = d3.pointer(e);
      const x0 = this.x.invert(coords[0]);
      const xV = Math.floor((e.offsetX * VOLUME_DAYS) / (this.props.width + 1));
      const i = bisect(this.props.data, x0, 1);
      const selectedData = this.props.data[i];
      verticalLine
        .attr("x1", this.x(selectedData.x))
        .attr("y1", 0)
        .attr("x2", this.x(selectedData.x))
        .attr("y2", this.props.height - this.xAxisHeight);
      focus
        .attr("cx", this.x(selectedData.x))
        .attr("cy", this.y(selectedData.y));

      const self = this;
      if (this.x(selectedData.x) > this.props.width * 0.5) {
        focusTextDate
          .html(`Date: ${x0.toLocaleDateString()}`)
          .attr("x", function (d: any) {
            return self.x(selectedData.x) - (this.getComputedTextLength() + 5);
          })
          .attr("text-anchor", "right")
          .attr("y", 5);
        focusTextPrice
          .html(`Price: ${formatPrice(selectedData.y)}`)
          .attr("x", function (d: any) {
            return self.x(selectedData.x) - (this.getComputedTextLength() + 5);
          })
          .attr("text-anchor", "right")
          .attr("y", 15);
        focusTextVolume
          .html(`Volume: ${formatUSDAmount(this.volumes[xV])}`)
          .attr("x", function (d: any) {
            return self.x(selectedData.x) - (this.getComputedTextLength() + 5);
          })
          .attr("text-anchor", "right")
          .attr("y", 25);
      } else {
        focusTextDate
          .html(`Date: ${x0.toLocaleDateString()}`)
          .attr("x", this.x(selectedData.x) + 5)
          .attr("text-anchor", "left")
          .attr("y", 5);
        focusTextPrice
          .html(`Price: ${formatPrice(selectedData.y)}`)
          .attr("x", this.x(selectedData.x) + 5)
          .attr("text-anchor", "left")
          .attr("y", 15);
        focusTextVolume
          .html(`Volume: ${formatUSDAmount(this.volumes[xV])}`)
          .attr("x", this.x(selectedData.x) + 5)
          .attr("text-anchor", "left")
          .attr("y", 25);
      }
    };

    this.svg
      .append("rect")
      .style("fill", "none")
      .style("pointer-events", "all")
      .attr("width", this.props.width)
      .attr("height", this.props.height)
      .on("mouseover", () => {
        focus.style("opacity", 1);
        focusTextDate.style("opacity", 1);
        focusTextPrice.style("opacity", 1);
        focusTextVolume.style("opacity", 1);
        verticalLine.style("opacity", 1);
      })
      .on("mouseout", () => {
        focus.style("opacity", 0);
        focusTextDate.style("opacity", 0);
        focusTextPrice.style("opacity", 0);
        focusTextVolume.style("opacity", 0);
        verticalLine.style("opacity", 0);
      })
      .on("mousemove", onMouseMove);
  }

  renderMostActivePriceAssumption(price: number) {
    return this.svg
      .append("g")
      .append("line")
      .style("stroke-width", 1.25)
      .style("stroke-dasharray", "10, 3")
      .style("stroke", this.colors.current)
      .attr("x1", 0)
      .attr("y1", this.y(price))
      .attr("x2", this.props.width)
      .attr("y2", this.y(price));
  }
  updateMostActivePriceAssumption(price: number) {
    this.mostActivePrice
      .attr("x1", 0)
      .attr("y1", this.y(price))
      .attr("x2", this.props.width)
      .attr("y2", this.y(price));
  }

  renderMinMaxPriceRange(minPrice: number, maxPrice: number) {
    const minPriceSVG = this.svg
      .append("g")
      .append("line")
      .style("stroke-width", 1.25)
      .style("stroke-dasharray", "10, 3")
      .style("stroke", this.colors.minMax)
      .attr("x1", 0)
      .attr("y1", this.y(minPrice))
      .attr("x2", this.props.width)
      .attr("y2", this.y(minPrice));
    // Add percentage tag for min price
    const minPriceTag = this.svg.append("g");
    minPriceTag
      .append("rect")
      .style("fill", this.colors.tagBg ?? "black")
      .attr("x", 304)
      .attr("y", this.y(minPrice) + 2)
      .attr("width", 50)
      .attr("height", 22)
      .attr("alignment-baseline", "middle")
      .attr("rx", 8)
      .attr("ry", 8);
    minPriceTag
      .append("text")
      .html(`${this.minPercentage}%`)
      .style("opacity", 1)
      .style("fill", this.colors.tagTxt ?? "white")
      .attr("x", 329)
      .attr("y", this.y(minPrice) + 14.5)
      .attr("font-size", "0.8rem")
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle");

    const maxPriceSVG = this.svg
      .append("g")
      .append("line")
      .style("stroke-width", 1.25)
      .style("stroke-dasharray", "10, 3")
      .style("stroke", this.colors.minMax)
      .attr("x1", 0)
      .attr("y1", this.y(maxPrice))
      .attr("x2", this.props.width)
      .attr("y2", this.y(maxPrice));
    // Add percentage tag for min price
    const maxPriceTag = this.svg.append("g");
    maxPriceTag
      .append("rect")
      .style("fill", this.colors.tagBg ?? "black")
      .attr("x", 304)
      .attr("y", this.y(maxPrice) - 24)
      .attr("width", 50)
      .attr("height", 22)
      .attr("alignment-baseline", "middle")
      .attr("rx", 8)
      .attr("ry", 8);
    maxPriceTag
      .append("text")
      .html(`${this.maxPercentage}%`)
      .style("opacity", 1)
      .style("fill", this.colors.tagTxt ?? "white")
      .attr("x", 329)
      .attr("y", this.y(maxPrice) - 11.5)
      .attr("font-size", "0.8rem")
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle");

    return { minPriceSVG, maxPriceSVG };
  }
  updateMinMaxPriceRange(
    minPrice: number,
    maxPrice: number,
    isFullRange: boolean
  ) {
    this.minPrice.attr("opacity", !isFullRange ? 1 : 0.4);
    this.maxPrice.attr("opacity", !isFullRange ? 1 : 0.4);

    this.minPrice
      .attr("x1", 0)
      .attr("y1", this.y(minPrice))
      .attr("x2", this.props.width)
      .attr("y2", this.y(minPrice));

    this.maxPrice
      .attr("x1", 0)
      .attr("y1", this.y(maxPrice))
      .attr("x2", this.props.width)
      .attr("y2", this.y(maxPrice));
  }

  createLinearGradient(id: string, color: string) {
    const lg = this.svg
      .append("defs")
      .append("linearGradient")
      .attr("id", id)
      .attr("x1", "0%")
      .attr("x2", "0%")
      .attr("y1", "0%")
      .attr("y2", "100%");
    lg.append("stop")
      .attr("offset", "0%")
      .style("stop-color", color)
      .style("stop-opacity", 1);
    lg.append("stop")
      .attr("offset", "10%")
      .style("stop-color", color)
      .style("stop-opacity", 0.5);
    lg.append("stop")
      .attr("offset", "25%")
      .style("stop-color", color)
      .style("stop-opacity", 0.3);
    lg.append("stop")
      .attr("offset", "50%")
      .style("stop-color", color)
      .style("stop-opacity", 0.15);
    lg.append("stop")
      .attr("offset", "100%")
      .style("stop-color", color)
      .style("stop-opacity", 0);
  }
}

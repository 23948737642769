import { Flex } from "@ui/common";
import { useDrawer } from "@ui/components/Drawer";
import { DRAWER_WIDTH } from "@ui/components/Drawer/style";
import { useResize } from "@ui/contexts";
import { PropsWithChildren, useEffect, useRef } from "react";
import styled from "styled-components";
import { NavLogo } from "../NavLogo";
import { NavTabs } from "../NavTabs";
import { NavBarProps } from "./types";

const Container = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  transition: width 0.3s;
  height: calc(100vh - 64px);
  display: grid;
  grid-template-rows: 64px calc(100vh - 64px);
`;

const Children = styled.div<{ isMantaNetwork?: boolean; drawerOpen: Boolean }>`
  padding: ${({ drawerOpen }) => (drawerOpen ? "0 40px 0 180px" : "0")};
  padding-bottom: 37px;
  box-sizing: border-box;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
`;
const SideBarWrapper = styled.div<{ drawerOpen?: boolean }>`
  z-index: ${({ theme }) => theme.zIndices.ribbon};
  height: 64px;
  display: flex;
  flex-direction: rows;
  align-items: center;
  gap: ${({ theme }) => theme.spacing["6xl"]};
  width: 100%;
  overflow: visible;
  background: ${({ theme }) => theme.colors.transparent.transparent};
  box-sizing: border-box;
  padding: ${({ drawerOpen }) => (drawerOpen ? "0 40px 0 20px" : "0 20px")};
`;
const LogoWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 132px;
  height: 32px;
  flex-shrink: 0;
  > svg,
  path {
    fill: ${({ theme }) => theme.colors.global.text.T1};
  }
`;
const NavBarTabs = styled.div`
  z-index: ${({ theme }) => theme.zIndices.snackbar};
`;
const StatusWrapper = styled(Flex)`
  margin: 0 0 0 auto;
  height: 40px;
`;

export const NavBar: React.FC<PropsWithChildren<NavBarProps>> = ({
  pathname = "",
  bgImages,
  networkInfo,
  navLinks,
  moreMenuItem,
  onSelect,
  isMantaNetwork,
  FooterBtn,
  ThemeBtn,
  children,
  ...props
}) => {
  const { width, updateWidth } = useResize();
  const { drawerOpen: drawerOpen1, intentDrawerOpen } = useDrawer();
  const drawerOpen = intentDrawerOpen || drawerOpen1;
  const childRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (drawerOpen) {
      updateWidth(`calc(100% - ${DRAWER_WIDTH})`);
    } else {
      updateWidth("100vw");
    }
  }, [drawerOpen]);

  return (
    <Container width={width}>
      <SideBarWrapper {...props} drawerOpen={drawerOpen}>
        <LogoWrapper>
          <NavLogo />
        </LogoWrapper>
        {drawerOpen ? null : (
          <NavBarTabs>
            <NavTabs
              networkInfo={networkInfo}
              moreMenuItem={moreMenuItem}
              navLinks={navLinks}
              bgImages={bgImages}
              onSelect={onSelect}
            />
          </NavBarTabs>
        )}
        <StatusWrapper gap="lg">
          {FooterBtn ? <FooterBtn /> : null}
          {ThemeBtn}
        </StatusWrapper>
      </SideBarWrapper>
      <Children
        drawerOpen={drawerOpen}
        ref={childRef}
        isMantaNetwork={isMantaNetwork}
      >
        {children}
      </Children>
    </Container>
  );
};

import { Flex, FlexColumn } from "@ui/common";
import styled from "styled-components";
import { Body1 } from "../../Typography";
import { Input } from "../Input";

export const StakeContainer = styled(FlexColumn)`
  gap: ${({ theme }) => theme.spacing.md};
  width: 100%;
`;

export const StakeWrapper = styled.div<{ isError: boolean }>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 16px;
  border-radius: ${({ theme }) => theme.radii.md};
  gap: ${({ theme }) => theme.spacing.md};
  border: ${({ theme, isError }) =>
    `1px solid ${
      isError ? theme.colors.global.error : theme.colors.global.line.Line1
    }`};
  :focus-within {
    border-color: ${({ theme, isError }) =>
      isError ? theme.colors.global.error : theme.colors.global.line.Line2};
  }
`;
export const Text = styled(Body1)<{ color?: string }>`
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: ${({ theme, color }) => color ?? theme.colors.global.text.T1};
`;
export const InputButton = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.md};
`;

export const StyledInput = styled(Input)`
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 24px !important;
  line-height: 28px !important;
  padding: 0;
  color: ${({ theme }) => theme.colors.global.text.T1};
  :focus {
    border-color: ${({ theme }) => theme.colors.transparent.transparent};
  }
  border: none !important;
  border-radius: 0;
`;

export const ValueContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
`;

export const USDValue = styled.div`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.global.text.T3};
`;

export const BalanceWrapper = styled(Flex)`
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`;

export const BalanceName = styled.span`
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.global.text.T3};
`;
export const BalanceValue = styled.span`
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const Max = styled.span<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  color: ${({ theme }) => theme.colors.global.primary};
`;

export const TokenInfoWrapper = styled(Flex)`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

import { IToken } from "@aperture/types";
import { RecurringAction } from "@aperture_finance/uniswap-v3-automation-sdk";
import { useEventCallback } from "@mui/material";
import { TriggerType } from "@ui/common/types";
import { useDebounce } from "ahooks";
import bigDecimal from "js-big-decimal";
import _ from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  E_TriggerTypeTab,
  E_WidthUnit,
  IDualTriggerSetupProps,
  IDualTriggerState,
  IPureTriggerSetupProps,
} from "../type";
import {
  emptyDualTriggerForm,
  getDefaultCacheForm,
  getTokenDefaultPrice,
  offsetTickToPercentage,
  percentageToOffsetTick,
} from "../utils";
import { PureDualTriggerSetup } from "./PureDualTriggerSetup";

const defaultTokenSpotPrice: [string, string] = ["0", "0"];

export const DualTriggerSetup: React.FC<IDualTriggerSetupProps> = ({
  initForm,
  tokens,
  tokenSpotPrice = defaultTokenSpotPrice, // may fetch in delay
  tokenMarketPrice,
  defaultAction,
  tickSpacing,
  tokenRatio,
  onSave,
  bg,
  ...props
}) => {
  const defaultForm: IDualTriggerState = useMemo(() => {
    return (
      initForm || {
        ...emptyDualTriggerForm,
        ...getTokenDefaultPrice(tokenSpotPrice[0], tokens[1].decimals),
      }
    );
  }, [initForm, tokenSpotPrice, tokens]);

  const getTokenRatio = useCallback(
    (tokenIdx: number) => {
      return tokenIdx === 0 ? tokenRatio : 10000 - tokenRatio;
    },
    [tokenRatio]
  );

  // cache price when switching token under token terms
  const priceCache = useRef<{
    above: string;
    below: string;
  }>();

  const { form, updateForm } = useForm(
    defaultForm,
    getDefaultCacheForm(
      getTokenRatio(defaultForm.baseToken),
      tokenSpotPrice[defaultForm.baseToken],
      tokens[1 - defaultForm.baseToken].decimals,
      true
    )
  );
  const selectedToken = tokens[form.baseToken];

  const [triggerTab, setTriggerTab] = useState<E_TriggerTypeTab>(
    defaultForm.triggerType === TriggerType.RATIO
      ? E_TriggerTypeTab.Ratio
      : E_TriggerTypeTab.Price
  );
  const [aboveTriggerAction, setAboveTriggerAction] = useState<
    RecurringAction | undefined
  >(form.aboveAction);
  const [belowTriggerAction, setBelowTriggerAction] = useState<
    RecurringAction | undefined
  >(form.belowAction);

  const [widthUnit, setWidthUnit] = useState<E_WidthUnit>(
    defaultForm.triggerType === TriggerType.PRICE_PERCENTAGE
      ? E_WidthUnit.Percentage
      : E_WidthUnit.TokenTerm
  );

  const convertWidthUnit = (widthUnit: E_WidthUnit) => {
    return widthUnit === E_WidthUnit.Percentage
      ? TriggerType.PRICE_PERCENTAGE
      : TriggerType.PRICE;
  };

  const handleWidthUnitChange = useCallback(
    (widthUnit: E_WidthUnit) => {
      setWidthUnit(widthUnit);
      updateForm({
        triggerType: convertWidthUnit(widthUnit),
      });
    },
    [updateForm]
  );

  const handleTabChange = useCallback(
    (tab: E_TriggerTypeTab) => {
      setTriggerTab(tab);
      updateForm({
        triggerType:
          tab === E_TriggerTypeTab.Ratio
            ? TriggerType.RATIO
            : convertWidthUnit(widthUnit),
      });
    },
    [updateForm, widthUnit]
  );

  const handleTokenChange = useEventCallback((token: IToken) => {
    if (token === selectedToken || errorMsg) {
      return;
    }
    const baseToken = tokens[0] === token ? 0 : 1;
    updateForm({
      baseToken,
      aboveChecked: form.belowChecked,
      belowChecked: form.aboveChecked,
      ...(form.triggerType === TriggerType.RATIO && {
        above: `${Math.round(10000 - 100 * parseFloat(form.below)) / 100}`,
        below: `${Math.round(10000 - 100 * parseFloat(form.above)) / 100}`,
      }),
      ...(form.triggerType === TriggerType.PRICE_PERCENTAGE && {
        above: offsetTickToPercentage(
          -percentageToOffsetTick(-Number(form.below), -100, 0)
        ),
        below: offsetTickToPercentage(
          -percentageToOffsetTick(Number(form.above), 0)
        ).replace("-", ""),
      }),
      ...(form.triggerType === TriggerType.PRICE &&
        (priceCache.current ||
          getTokenDefaultPrice(
            tokenSpotPrice[baseToken],
            tokens[1 - baseToken].decimals
          ))),
    });
    const belowAction = belowTriggerAction;
    const aboveAction = aboveTriggerAction;
    setAboveTriggerAction(belowAction);
    setBelowTriggerAction(aboveAction);

    if (form.triggerType === TriggerType.PRICE) {
      priceCache.current = {
        above: form.above,
        below: form.below,
      };
    }
  });

  const handleInputAboveChange = useEventCallback((value: string) => {
    updateForm({
      above: value,
    });
  });

  const handleInputBelowChange = useEventCallback((value: string) => {
    updateForm({
      below: value,
    });
  });

  const handleAboveChecked = useCallback(
    (checked: boolean) => {
      updateForm({
        aboveChecked: checked,
      });
      !checked && setAboveTriggerAction(undefined);
    },
    [updateForm]
  );

  const handleBelowChecked = useCallback(
    (checked: boolean) => {
      updateForm({
        belowChecked: checked,
      });
      !checked && setBelowTriggerAction(undefined);
    },
    [updateForm]
  );

  const handleTimeBufferChecked = useCallback(
    (checked: boolean) => {
      updateForm({
        timeBufferChecked: checked,
      });
    },
    [updateForm]
  );

  const handleTimeBufferChange = useCallback(
    (value: string) => {
      updateForm({
        timeBuffer: value,
      });
    },
    [updateForm]
  );

  const handleSave = useEventCallback(() => {
    onSave &&
      onSave({
        ...form,
        aboveAction: aboveTriggerAction,
        belowAction: belowTriggerAction,
      });
  });

  const { invalidInput, errorMsg } = useMemo(() => {
    return validateForm(form, tokenSpotPrice, getTokenRatio(form.baseToken));
  }, [form, tokenSpotPrice, getTokenRatio]);

  return (
    <PureDualTriggerSetup
      key={form.baseToken}
      bg={bg}
      triggerType={triggerTab}
      onSelectTriggerType={handleTabChange}
      widthUnit={widthUnit}
      onSelectWidthUnit={handleWidthUnitChange}
      tokens={tokens}
      selectedToken={tokens[form.baseToken]}
      onSelectToken={handleTokenChange}
      aboveChecked={form.aboveChecked}
      above={form.above}
      onAboveInput={handleInputAboveChange}
      onAboveChecked={handleAboveChecked}
      belowChecked={form.belowChecked}
      below={form.below}
      onBelowInput={handleInputBelowChange}
      onBelowChecked={handleBelowChecked}
      timeBuffer={form.timeBuffer}
      timeBufferChecked={form.timeBufferChecked}
      onTimeBufferChecked={handleTimeBufferChecked}
      onTimeBufferInput={handleTimeBufferChange}
      tickSpacing={tickSpacing}
      defaultAction={defaultAction}
      tokenMarketPrice={tokenMarketPrice}
      aboveTriggerAction={aboveTriggerAction}
      setAboveTriggerAction={setAboveTriggerAction}
      belowTriggerAction={belowTriggerAction}
      setBelowTriggerAction={setBelowTriggerAction}
      onSave={handleSave}
      invalidInput={invalidInput}
      errorMsg={useDebounce(errorMsg, { wait: 500 })}
      {...props}
    />
  );
};

function useForm(
  initForm: IDualTriggerState,
  defaultForms: ReturnType<typeof getDefaultCacheForm>
) {
  const [form, setForm] = useState<IDualTriggerState>(initForm);

  const inputCache = useRef({
    ...defaultForms,
    ...{
      [initForm.triggerType]: {
        ...initForm,
      },
    },
  });

  const updateForm = useEventCallback((_form: Partial<IDualTriggerState>) => {
    // cache form
    Object.assign(
      inputCache.current[form.triggerType],
      _.omit(_form, ["triggerType"])
    );

    setForm((prevState) => ({
      ...prevState,
      ..._form,
    }));
  });

  useEffect(() => {
    updateForm({
      ...inputCache.current[form.triggerType],
    });
  }, [form.triggerType, updateForm]);

  useEffect(() => {
    setForm(initForm);
    inputCache.current[initForm.triggerType] = initForm;
  }, [initForm]);

  return {
    form,
    updateForm,
  };
}

function validateForm(
  form: IDualTriggerState,
  tokenSpotPrice: [string, string],
  tokenRatio: number
): Pick<IPureTriggerSetupProps, "invalidInput" | "errorMsg"> {
  const { above, aboveChecked, belowChecked, below, triggerType } = form;
  const spotPrice = tokenSpotPrice[form.baseToken];

  if (aboveChecked && !above) {
    return {
      invalidInput: "above",
      errorMsg: "",
    };
  }

  if (belowChecked && !below) {
    return {
      invalidInput: "below",
      errorMsg: "",
    };
  }

  if (triggerType === TriggerType.RATIO) {
    if (aboveChecked) {
      if (bigDecimal.compareTo(above, 100) > 0) {
        return {
          invalidInput: "above",
          errorMsg: "Upper threshold cannot be greater than 100%.",
        };
      }
    }
    if (belowChecked) {
      if (bigDecimal.compareTo(below, 100) > 0) {
        return {
          invalidInput: "below",
          errorMsg: "Lower threshold cannot be greater than 100%.",
        };
      }
    }
    if (belowChecked && aboveChecked) {
      if (bigDecimal.compareTo(below, above) >= 0) {
        return {
          invalidInput: "above",
          errorMsg: "Upper threshold must be greater than the lower threshold.",
        };
      }
    }
    // if (aboveChecked) {
    //   if (bigDecimal.compareTo(above, tokenRatio / 100) < 0) {
    //     return {
    //       invalidInput: "above",
    //       errorMsg:
    //         "Upper threshold should be greater than the current token ratio.",
    //     };
    //   }
    // }
    // if (belowChecked) {
    //   if (bigDecimal.compareTo(tokenRatio / 100, below) < 0) {
    //     return {
    //       invalidInput: "below",
    //       errorMsg:
    //         "Lower threshold should be less than the current token ratio.",
    //     };
    //   }
    // }
  } else if (belowChecked) {
    if (triggerType === TriggerType.PRICE_PERCENTAGE) {
      if (parseFloat(below) > 100) {
        return {
          invalidInput: "below",
          errorMsg:
            "The price percentage can only go down to 100% and the absolute token terms cannot exceed the value of the spot price at trigger creation.",
        };
      }
    } else {
      if (bigDecimal.compareTo(below, spotPrice) > 0) {
        return {
          invalidInput: "below",
          errorMsg:
            "The price percentage can only go down to 100% and the absolute token terms cannot exceed the value of the spot price at trigger creation.",
        };
      }
    }
  }

  return {
    invalidInput: "",
    errorMsg: "",
  };
}

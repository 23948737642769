import { ApertureSupportedChainId } from "@aperture_finance/uniswap-v3-automation-sdk";
import { useLocalStorageState } from "ahooks";
import { PropsWithChildren, createContext, useContext } from "react";
import { useAccount } from "wagmi";
import { useNetwork } from "../NetworkContext/NetworkContext";
import { useAllChainState } from "./allChain/useAllChainState";
import { useAOStatus } from "./useAOStatus";
import { useHistoryChatStorage } from "./useChatHistoryStore";
import { useConnectStatus } from "./useConnectStatus";
import { useMenu } from "./useMenu";
import { useSolverRouting } from "./useSolverRouting";

const Context = createContext<IGlobalStoreContext>({} as IGlobalStoreContext);

interface IGlobalStoreContext {
  aoStatus: ReturnType<typeof useAOStatus>;
  historyChatStorage: ReturnType<typeof useHistoryChatStorage>;
  slippageSettings: {
    globalSlippage: number;
    setGlobalSlippage: (
      value?:
        | Record<ApertureSupportedChainId, number>
        | {
            (previousState?: Record<ApertureSupportedChainId, number>): Record<
              ApertureSupportedChainId,
              number
            >;
          }
    ) => void;
  };
  connectStatus: ReturnType<typeof useConnectStatus>;
  menu: ReturnType<typeof useMenu>;
  solverRouting: ReturnType<typeof useSolverRouting>;
  allChainState: ReturnType<typeof useAllChainState>;
}

export const GlobalStoreProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const aoStatus = useAOStatus();
  const { address } = useAccount();
  const historyChatStorage = useHistoryChatStorage(address);
  const { networkId } = useNetwork();
  const [globalSlippage, setGlobalSlippage] = useLocalStorageState<
    Record<ApertureSupportedChainId, number>
  >(`global-slippage-${address}`);
  const connectStatus = useConnectStatus();
  const menu = useMenu();
  const solverRouting = useSolverRouting();
  const allChainState = useAllChainState();

  return (
    <Context.Provider
      value={{
        aoStatus,
        historyChatStorage,
        slippageSettings: {
          globalSlippage: globalSlippage?.[networkId],
          setGlobalSlippage,
        },
        connectStatus,
        menu,
        solverRouting,
        allChainState,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useAOStore = () => {
  return useContext(Context).aoStatus;
};
export const useGlobalSlippage = () => {
  return useContext(Context).slippageSettings;
};

export const useConnectStore = () => {
  return useContext(Context).connectStatus;
};

export const useHistoryChatStore = () => {
  return useContext(Context).historyChatStorage;
};

export const useMenuStore = () => {
  return useContext(Context).menu;
};

export const useSolverRoutingStore = () => {
  return useContext(Context).solverRouting;
};

export const useAllChainStateStore = () => {
  return useContext(Context).allChainState;
};

import { useAllChainStateStore } from "@/components/GlobalStore";
import { useNetwork } from "@/components/NetworkContext/NetworkContext";
import { useEffect } from "react";

export const useCurrentChainToken = (networkId?: number) => {
  const { networkId: currentNetworkId } = useNetwork();

  const {
    state: { rawAllTokenMap, rawAllTokenPriceMap },
    updateTokenPriceMap,
  } = useAllChainStateStore();

  const _network = networkId ?? currentNetworkId;
  const currentTokenMap = rawAllTokenMap?.[_network];
  const currentTokenPrice = rawAllTokenPriceMap?.[_network];

  // for swap page use to init token price map
  useEffect(() => {
    if (!_network || !currentTokenMap) return;

    updateTokenPriceMap({
      chainId: _network,
      tokenMap: currentTokenMap,
      isInitialization: true,
    });
  }, [_network, currentTokenMap, updateTokenPriceMap]);

  return {
    currentTokenMap,
    currentTokenPrice,
  };
};

import { CaretIcon } from "@aperture/assetkit";
import styled from "styled-components";

export const ArrowDown = styled(CaretIcon)`
  position: absolute;
  top: 11px;
  right: 12px;
  height: 20px;
  width: 20px;
  transform: rotate(180deg);
  pointer-events: none;
`;

export const Container = styled.div<{ type: string }>`
  position: relative;
  width: ${({ type }) => (type === "date" ? "220px" : "180px")};
  .react-datepicker-wrapper input {
    height: 40px;
    line-height: 43px;
    width: 100%;
    color: ${({ theme }) => theme.colors.global.text.T1};
    background: ${({ theme }) => theme.colors.global.background.BG3};
    border: 1px solid ${({ theme }) => theme.colors.grey.grey};
    border-radius: ${({ theme }) => theme.radii.round};
    box-sizing: border-box;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    padding: 0 31px 0 12px;
    text-align: left;
    :focus-visible {
      border: 1px solid ${({ theme }) => theme.colors.global.line.Line2} !important;
      outline: 0 !important;
    }
  }
  .react-datepicker-popper {
    z-index: ${({ theme }) => theme.zIndices.dropdown};
    transform: translate(0px, 40px) !important;
    inset: ${({ type }) =>
      type === "date"
        ? "0px 0px auto auto !important"
        : "0px auto auto 0px !important"};
  }
  .react-datepicker__navigation-icon::before {
    border-width: 2px 2px 0 0;
    height: 7px;
    width: 7px;
  }
  .react-datepicker__navigation--previous {
    top: 18px;
    left: 20px;
  }
  .react-datepicker__navigation--next {
    top: 18px;
    right: 20px;
  }
  .react-datepicker {
    width: ${({ type }) => (type === "date" ? "387px" : "180px")};
    border: 1px solid ${({ theme }) => theme.colors.grey.grey};
    background: ${({ theme }) => theme.colors.global.background.BG3};
    padding: 24px 24px 15px 24px;
    box-sizing: border-box;
  }
  .react-datepicker__time {
    background: ${({ theme }) => theme.colors.global.background.BG3};
  }
  .react-datepicker__month-container {
    width: 100%;
    box-sizing: border-box;
  }
  .react-datepicker__header {
    padding: 0;
    border: 0;
    background: ${({ theme }) => theme.colors.global.background.BG3};
    margin-bottom: 18px;
  }
  .react-datepicker__header--time--only {
    display: none;
  }
  .react-datepicker__current-month {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 24px;
    color: ${({ theme }) => theme.colors.global.text.T1};
  }
  .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
    .react-datepicker__day-name {
      color: ${({ theme }) => theme.colors.global.text.T2};
      font-size: 14px;
      font-weight: 500;
      height: unset;
      line-height: 1;
      margin: 0;
    }
  }
  .react-datepicker__month {
    margin: 0;
  }
  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    :last-child {
      margin: 0;
    }
  }
  .react-datepicker__day {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    height: 32px;
    line-height: 32px;
    width: 32px;
    color: ${({ theme }) => theme.colors.global.text.T2};
    :hover {
      color: ${({ theme }) => theme.colors.global.textAlt.T1};
      background: ${({ theme }) => theme.colors.global.primaryHover};
      border-radius: ${({ theme }) => theme.radii.circle};
    }
    &.react-datepicker__day--selected {
      margin: 0;
      border-radius: ${({ theme }) => theme.radii.circle};
      background: ${({ theme }) => theme.colors.global.primary};
      color: ${({ theme }) => theme.colors.global.textAlt.T1};
    }
    &.react-datepicker__day--outside-month {
      color: ${({ theme }) => theme.colors.global.text.T4};
    }
    &.react-datepicker__day--keyboard-selected {
      color: ${({ theme }) => theme.colors.global.text.T1};
      background: ${({ theme }) => theme.colors.global.primaryHover};
      border-radius: ${({ theme }) => theme.radii.circle};
    }
  }
  .react-datepicker__time-list {
    scrollbar-width: none;
  }
  .react-datepicker--time-only {
    padding: 8px 4px 8px 8px;
  }
  .react-datepicker__time-container {
    width: 100%;
    .react-datepicker__header {
      margin-bottom: 10px;
    }
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 100%;
    margin: 0;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    width: 130px;
    height: unset;
    padding: 8px 24px 8px 8px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    text-align: left;
    color: ${({ theme }) => theme.colors.global.text.T2};
    :hover {
      color: ${({ theme }) => theme.colors.global.textAlt.T1};
      background: ${({ theme }) => theme.colors.global.primaryHover};
      border-radius: ${({ theme }) => theme.radii.md};
    }
    &.react-datepicker__time-list-item--selected {
      color: ${({ theme }) => theme.colors.global.textAlt.T1};
      background: ${({ theme }) => theme.colors.global.primary};
      border-radius: ${({ theme }) => theme.radii.md};
    }
  }
  > svg,
  path {
    transform: none;
    fill: ${({ theme }) => theme.colors.global.text.T1};
  }
`;

import { useTheme } from "@/Providers";
import TopBarLayout from "@/components/layout/TopBarLayout";
import { CloudFrontURL } from "@/config/url";
import { useIsMounted } from "@/hooks/useIsMounted";
import { CommonQueryKey } from "@/utils/browserHistory";
import { getNetworkId, isManta } from "@/utils/networkHelper";
import { IntentGptDrawer } from "@/views/IntentGPT/Drawer";
import {
  DiscordIcon,
  LinkIcon,
  MediumIcon,
  TelegramIcon,
  ThemeIcon,
  TwitterIcon,
} from "@aperture/assetkit";
import {
  Flex,
  FlexColumn,
  NavBar,
  useDrawer,
  useResize,
} from "@aperture/uikit";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useMenuStore } from "../GlobalStore";
import { useNetwork } from "../NetworkContext/NetworkContext";

const Container = styled(FlexColumn)<{ height: string }>`
  height: ${({ height }) => height};
  transition: height 0.3s;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.global.background.BG1};
  justify-content: space-between;
`;
const Wrapper = styled(Flex)<{ drawerOpen?: boolean }>`
  justify-content: ${({ drawerOpen }) => (drawerOpen ? "flex-end" : "center")};
`;
const FooterBar = styled.div`
  z-index: 100;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(20px);
  > svg,
  path {
    fill: ${({ theme }) => theme.colors.global.text.T1};
  }
`;
const FooterItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 72px;
  height: 12px;
  padding: 0 20px;
  cursor: pointer;
  > svg {
    width: 12px;
    height: 12px;
    fill: ${({ theme }) => theme.colors.global.text.T1};
    :hover {
      opacity: 0.8;
    }
  }
  > path {
    fill: ${({ theme }) => theme.colors.global.text.T1};
  }
`;
const FooterLinkWrapper = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;
const FooterLink = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.global.text.T3};
  font-family: "Roboto Flex";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  height: 14px;
  line-height: 14px;
  text-transform: uppercase;
  padding: 0 20px;
  cursor: pointer;
  position: relative;
  border-right: 2px solid ${({ theme }) => theme.colors.global.line.Line2};
  transition: color 0.3s;
  :hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.global.text.T1};
  }
  &:last-child {
    border: none;
  }
  > svg {
    width: 14px;
    height: 14px;
  }
  > svg,
  path {
    stroke: ${({ theme }) => theme.colors.global.text.T3};
  }
`;
const Theme = styled(Flex)`
  height: 14px;
  width: 14px;
  padding: 13px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.radii.circle};
  background-color: ${({ theme }) => theme.colors.global.background.BG2};
  > svg,
  path {
    fill: ${({ theme }) => theme.colors.global.text.T1};
  }
`;

const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const { amm, networkId, network, setNetwork } = useNetwork();
  const [isMantaNetwork, setIsMantaNetwork] = useState(false);
  const { theme, toggleTheme } = useTheme();
  const { height } = useResize();
  const { navLinks, moreMenuItem } = useMenuStore();
  const isMounted = useIsMounted();
  const { drawerOpen, intentDrawerOpen } = useDrawer();

  useEffect(() => {
    if (networkId) {
      setIsMantaNetwork(isManta(networkId));
    }
  }, [networkId]);

  const ThemeBtn = (
    <Theme onClick={toggleTheme}>
      <ThemeIcon theme={theme} />
    </Theme>
  );

  if (!isMounted) {
    return null;
  }

  return (
    <Container height={height}>
      <NavBar
        navLinks={navLinks}
        moreMenuItem={moreMenuItem}
        networkInfo={{ amm, chain: network }}
        pathname={router.pathname}
        isMantaNetwork={isMantaNetwork}
        FooterBtn={TopBarLayout}
        bgImages={{
          More: `${CloudFrontURL}/images/nav-more.png`,
          Selector: `${CloudFrontURL}/images/nav-selector.png`,
        }}
        onSelect={(networkInfo, pathname) => {
          if (pathname && router.pathname !== `/${pathname}`) {
            router.push({
              pathname: `/${pathname}`,
              query: {
                [CommonQueryKey.CHAIN_ID]: getNetworkId(networkInfo.chain),
                [CommonQueryKey.AMM]: networkInfo.amm.name,
              },
            });
          } else {
            setNetwork(networkInfo.amm, networkInfo.chain);
          }
        }}
        ThemeBtn={ThemeBtn}
      >
        <Wrapper drawerOpen={drawerOpen || intentDrawerOpen}>
          {children}
          <IntentGptDrawer />
        </Wrapper>
      </NavBar>
      <FooterBar>
        <FooterItem>
          <TelegramIcon
            onClick={() => {
              window.open("https://t.me/aperture_finance");
            }}
          />
          <DiscordIcon
            onClick={() => {
              window.open("https://discord.com/invite/cWHQFH3GTz");
            }}
          />
          <TwitterIcon
            onClick={() => {
              window.open("https://x.com/ApertureFinance");
            }}
          />
          <MediumIcon
            onClick={() => {
              window.open("https://medium.com/@aperturefinance");
            }}
          />
        </FooterItem>
        <FooterLinkWrapper>
          <FooterLink
            onClick={() =>
              window.open("https://app.buildrmetrics.com/simulate")
            }
          >
            UNIV3 SIMULATE&nbsp;
            <LinkIcon />
          </FooterLink>
          <FooterLink
            onClick={() => window.open("https://docs.aperture.finance/")}
          >
            APERTURE DOCS
          </FooterLink>
          <FooterLink onClick={() => router.push("/privacy")}>
            PRIVACY POLICY
          </FooterLink>
          <FooterLink onClick={() => router.push("/terms")}>
            TERMS & CONDITIONS
          </FooterLink>
          <FooterLink onClick={() => router.push("/credits")}>
            CREDITS
          </FooterLink>
          <FooterLink
            onClick={() =>
              window.open(
                "https://docs.aperture.finance/docs/transparency/security-audit"
              )
            }
          >
            AUDITS
          </FooterLink>
          <FooterLink onClick={() => window.open("https://aperture.finance")}>
            OFFICIAL WEBSITE
          </FooterLink>
        </FooterLinkWrapper>
      </FooterBar>
    </Container>
  );
};

export default Layout;

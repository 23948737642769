import {
  ExitPosition,
  IPositionDetails,
} from "@/config/position/positionConfig";
import { filterTriggers, getTag } from "@/helper/triggerHelper";
import { AnalyticPosition } from "@/utils/analytics/helper";
import { getNetworkName } from "@/utils/networkHelper";
import { formatPrice } from "@/utils/numberFormat";
import { timestampFormat } from "@/utils/timeFormat";
import { SupportedChainId } from "@aperture/uikit";
import { PositionCardProps } from "@aperture/uikitv2";
import {
  ApertureSupportedChainId,
  ListTriggerResponse,
} from "@aperture_finance/uniswap-v3-automation-sdk";
import bigDecimal from "js-big-decimal";
import _ from "lodash";

interface IPositionCardProps {
  position: IPositionDetails;
  atStake: boolean;
  isSelected: boolean;
  triggerObj?: ListTriggerResponse;
  closedPositionResult?: ExitPosition;
  showRadio?: boolean;
  limitOrder?: boolean;
}

export function getFormattedPriceRange(minPrice: string, maxPrice: string) {
  return {
    min:
      bigDecimal.compareTo(minPrice, 0) > 0 &&
      bigDecimal.compareTo(bigDecimal.divide(1, minPrice, 18), 0) === 0
        ? "∞"
        : formatPrice(minPrice),
    max:
      bigDecimal.compareTo(maxPrice, 0) > 0 &&
      bigDecimal.compareTo(bigDecimal.divide(1, maxPrice, 18), 0) === 0
        ? "∞"
        : formatPrice(maxPrice),
    swapMin:
      bigDecimal.compareTo(maxPrice, 0) > 0
        ? formatPrice(bigDecimal.divide(1, maxPrice, 18))
        : "∞",
    swapMax:
      bigDecimal.compareTo(minPrice, 0) > 0
        ? formatPrice(bigDecimal.divide(1, minPrice, 18))
        : "∞",
  };
}

function getFormattedClosedInfo(closedPosition: ExitPosition) {
  return _.isEqual(closedPosition, {}) || !closedPosition
    ? undefined
    : {
        dateTime: timestampFormat(closedPosition.timestamp),
        exitedValueUsd: closedPosition.exitedValueUsd,
        exitedCollectedIncomeUsd: closedPosition.exitedCollectedIncomeUsd,
      };
}

export function getPositionCardProps(
  props: IPositionCardProps,
  currentChain: ApertureSupportedChainId,
  analyticsData: AnalyticPosition
): PositionCardProps {
  const {
    isSelected,
    atStake,
    triggerObj,
    closedPositionResult,
    showRadio,
    limitOrder,
    position: {
      positionId,
      chainId,
      tokenA,
      tokenB,
      feeTier,
      liquidity,
      feeAmount,
      tokenPercentages,
      minPrice,
      maxPrice,
      inRange,
      closed = false,
    },
  } = props;

  const pid = `${positionId}-${chainId}`;
  const autoCompound =
    filterTriggers(triggerObj, "Reinvest", positionId).length > 0;
  const rebalanced = getTag(triggerObj, "Rebalance", positionId);
  const prescheduled = getTag(triggerObj, "Close", positionId);
  const closedInfo = closed
    ? getFormattedClosedInfo(closedPositionResult)
    : undefined;
  const recurringRebalanced = getTag(
    triggerObj,
    [
      "RecurringPrice",
      "RecurringPercentage",
      "RecurringRatio",
      "RecurringDualAction",
    ],
    positionId
  );

  const priceRange = getFormattedPriceRange(minPrice, maxPrice);
  const dataV2: PositionCardProps = {
    id: `Position-${pid}`,
    chainId: chainId.toString(),
    supportedChainId: getNetworkName(chainId),
    positionId: positionId,
    tokens: [tokenA, tokenB],
    feeTier,
    liquidity,
    fees: feeAmount,
    tokenPercentages: [
      tokenPercentages.token0Percent,
      tokenPercentages.token1Percent,
    ],
    priceRange,
    autoCompound,
    rebalanced,
    recurringRebalanced,
    prescheduledPosition: prescheduled,
    inRange,
    limitOrder,
    isCurrentChain: currentChain === chainId,
    isManta: getNetworkName(currentChain) === SupportedChainId.MANTA,
    closed,
    closedInfo,
    selected: isSelected,
    atStake,
    checked: showRadio ? isSelected : undefined,
    totalAPR: analyticsData?.totalAPR ?? "",
    feeAPR: analyticsData?.feeAPR ?? "",
    positionHistory: analyticsData?.rebalancePositions
      .map((pos) => ({ positionId: pos.id, isActive: !pos.closedTimestamp }))
      .sort((a, b) => Number(b.positionId) - Number(a.positionId)),
  };

  return dataV2;
}

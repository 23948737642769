import { Colors } from "./types";

const colors = {
  grey: {
    white: "#FFFFFF",
    offWhite: "#F5F5F5",
    lightGrey1: "#F0EEF7",
    lightGrey2: "#E9E7F5",
    lightGrey3: "#E9EAEF",
    lightGrey4: "#474747",
    grey: "#B4B9CC",
    mediumGrey: "#6D6978",
    darkGrey1: "#2F3032",
    darkGrey2: "#46444F",
    black: "#000000",
  },
  transparent: {
    white1: "rgba(255, 255, 255, 0.65)",
    white2: "rgba(255, 255, 255, 0.4)",
    grey1: "rgba(158, 164, 196, 0.25)",
    grey2: "rgba(123, 123, 164, 0.25)",
    grey3: "rgba(17, 13, 24, 0.25)",
    black: "rgba(0, 0, 0, 0.5)",
    purple1: "rgba(104, 52, 217, 0.3)",
    transparent: "transparent",
  },
  errorWarning: {
    orange: "#D4801C",
    pink: "#DEC4C4",
    red: "#DC3939",
  },
  positive: {
    green: "#1A9A64",
  },
  primary: {
    lightPurple: "#8571FF",
    purple: "#7E51FD",
    purple2: "#5F3FB7B2",
    purple3: "#8E58FF8C",
    darkPurple: "#7357C2",
    greyPurple: "#71678F",
  },
};

const darkColors: Colors = {
  ...colors,
  global: {
    primary: "#FFE76B",
    primaryHover: "#FFE76BCC",
    primaryDisable: "#FFE76B4D",
    secondary: "#A176FF",
    secondaryHover: "#A176FFCC",
    secondaryDisable: "#A176FF4D",
    success: "#24FF00",
    error: "#FF473C",
    warning: "#ED9C00",
    text: {
      T1: "#FFFFFF",
      T2: "rgba(255, 255, 255, 0.70)",
      T3: "rgba(255, 255, 255, 0.50)",
      T4: "rgba(255, 255, 255, 0.30)",
      T5: "rgba(255, 255, 255, 0.10)",
    },
    textAlt: {
      T1: "#000000",
      T2: "rgba(0, 0, 0, 0.70)",
      T3: "rgba(0, 0, 0, 0.50)",
      T4: "rgba(0, 0, 0, 0.30)",
      T5: "rgba(0, 0, 0, 0.10)",
    },
    background: {
      BG1: "#161616",
      BG2: "#242424",
      BG3: "#363636",
      BG4: "#4F4F4F",
      Gradient: "linear-gradient(278.11deg, #BB9CFF 3.52%, #FFE76B 97.27%)",
      Gradient1: "linear-gradient(112deg, #745FA2 0%, #A29B78 100%)",
      Gradient2: "linear-gradient(278.68deg, #A176FF 0%, #FFE76B 100%)",
    },
    line: {
      Line: "rgba(255, 255, 255, 0.2)",
      Line1: "#383838",
      Line2: "#434343",
    },
  },
};

const lightColors: Colors = {
  ...colors,
  global: {
    primary: "#6834D9",
    primaryHover: "#6834D9CC",
    primaryDisable: "#6834D94D",
    secondary: "#FFD915",
    secondaryHover: "#FFD915CC",
    secondaryDisable: "#FFD9154D",
    success: "#19B000",
    error: "#FF0F00",
    warning: "#CA6D00",
    text: {
      T1: "#000000",
      T2: "rgba(0, 0, 0, 0.70)",
      T3: "rgba(0, 0, 0, 0.50)",
      T4: "rgba(0, 0, 0, 0.30)",
      T5: "rgba(0, 0, 0, 0.10)",
    },
    textAlt: {
      T1: "#FFFFFF",
      T2: "rgba(255, 255, 255, 0.70)",
      T3: "rgba(255, 255, 255, 0.50)",
      T4: "rgba(255, 255, 255, 0.30)",
      T5: "rgba(255, 255, 255, 0.10)",
    },
    background: {
      BG1: "#E6E6E6",
      BG2: "#F6F6F6",
      BG3: "#FFFFFF",
      BG4: "#F2F2F2",
      Gradient: "linear-gradient(98.68deg, #7D41FF 0%, #B39600 100%)",
      Gradient1: "linear-gradient(279deg, #A176FF 0%, #FFE76B 100%)",
      Gradient2: "linear-gradient(98.68deg, #7D41FF 0%, #B39600 100%)",
    },
    line: {
      Line: "rgba(0, 0, 0, 0.15)",
      Line1: "#D5D5D5",
      Line2: "#DCDCDC",
    },
  },
};

export { darkColors, lightColors };

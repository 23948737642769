import { useRef, useState } from "react";
import { useIsomorphicEffect } from "../../contexts";
import { formatUSDAmount, shortenAddress, timestampFormat } from "../../utils";
import { useDrawer } from "../Drawer";
import { PositionInfoTitle } from "../PositionInfoTitle";
import { AutoCompoundTag } from "../Tag";
import { SwapRate } from "./SwapRate";
import {
  Box,
  Container,
  Divider,
  HR,
  Row,
  S2,
  S3,
  StrategyDetailBox,
  Wrapper,
} from "./style";
import { StrategyDetailCardProps } from "./types";

export const StrategyDetailCard: React.FC<StrategyDetailCardProps> = ({
  tokens,
  feeTier,
  rebalancesExecuted,
  revenueEarned,
  createdTime,
  liquidity,
  fees,
  priceRange,
  autoCompound = false,
  selected = false,
  statusTags = undefined,
  index,
  onClick,
}) => {
  const { drawerOpen } = useDrawer();
  const ref = useRef<HTMLDivElement>(null);
  const [sm, setSm] = useState<boolean>(false);
  useIsomorphicEffect(() => {
    function updateSize() {
      if (ref.current) {
        setSm(ref.current.offsetWidth < 750);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [drawerOpen]);

  const strategyStatus = {
    "Number of rebalances executed": rebalancesExecuted,
    "Total fee revenue earned": formatUSDAmount(revenueEarned),
    "Strategy created time": `${
      createdTime ? timestampFormat(createdTime).date : "-"
    }`,
  };
  const currentPosition = {
    "Current liquidity": formatUSDAmount(liquidity),
    "Unclaimed fees": formatUSDAmount(fees),
  };

  return (
    <StrategyDetailBox
      sm={drawerOpen}
      ref={ref}
      selected={selected}
      onClick={onClick}
      id={`Strategy-${index}`}
    >
      <Box minWidth={323}>
        <Wrapper>
          <S2>Strategy {sm ? "Detail" : `#${shortenAddress(index, 6, 4)}`}</S2>
        </Wrapper>
        <PositionInfoTitle
          {...{ tokens, feeTier, size: 24, padding: "3px 12px" }}
        />
        <Container>
          {Object.entries(strategyStatus).map(([label, value]) => (
            <Row key={label}>
              <S3>{label}</S3>
              <S3 value>{value}</S3>
            </Row>
          ))}
        </Container>
      </Box>
      {drawerOpen ? <HR /> : <Divider />}
      <Box minWidth={280}>
        <Wrapper>
          <S2>Current Position {sm && "Detail"}</S2>
          {autoCompound && <AutoCompoundTag autoCompound wordOnly />}
        </Wrapper>
        <Container>
          <SwapRate {...{ tokens, priceRange }} />
          {Object.entries(currentPosition).map(([label, value]) => (
            <Row key={label}>
              <S3>{label}</S3>
              <S3 value>{value}</S3>
            </Row>
          ))}
        </Container>
      </Box>
    </StrategyDetailBox>
  );
};

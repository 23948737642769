import React from "react";
import styled from "styled-components";
import { BackgroundProps, background } from "styled-system";
import { LabelText } from "../Typography";
import { LabelProps } from "./types";

const StyledLabel = styled(LabelText)<BackgroundProps>`
  width: max-content;
  background: ${({ theme }) => theme.gradients.primary.medium};
  ${background}
  -webkit-background-clip: text;
  -webkit-text-fill-color: ${({ theme }) =>
    theme.colors.transparent.transparent};
  background-clip: text;
  text-fill-color: ${({ theme }) => theme.colors.transparent.transparent};
`;

export const Label: React.FC<LabelProps> = ({ text, ...props }) => {
  return <StyledLabel {...props}>{text}</StyledLabel>;
};

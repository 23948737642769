export const APTR_TOKEN_DECIMALS = 6;

export interface SerializedTokenInfo extends TokenInfo {
  balance: bigint;
  displayBalance: string;
  allowance: bigint;
}

export interface TokenInfo {
  readonly name: string;
  readonly ticker: string;
  readonly address: string;
  readonly decimals: number;
  readonly native: boolean;
}

export interface Tokens {
  [ticker: string]: TokenInfo;
}

export interface ITokenMap {
  [tokenContractAddress: string]: TokenInfo;
}

export interface BaseToken {
  id: string;
  decimals: string;
  symbol: string;
  name: string;
}

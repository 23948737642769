import { IToken } from "@aperture/types";
import { Flex, T3Regular } from "@aperture/uikitv2";
import {
  ActionTypeEnum,
  ConditionTypeEnum,
  RecurringCondition,
  RecurringDualAction,
  RecurringPercentageAction,
  RecurringPercentageCondition,
  RecurringPriceAction,
  RecurringPriceCondition,
  RecurringRatioAction,
  RecurringRatioCondition,
  TriggerItem,
} from "@aperture_finance/uniswap-v3-automation-sdk";
import {
  formatMarketPrice,
  formatTokenAmount,
  sqrtX96ToPrice,
} from "@ui/utils";
import bigDecimal from "js-big-decimal";
import { InfoItem } from "./TriggerInfoCard";
import { SwapButton2, SwapButtonIcon } from "./style";
import { formatDuration } from "./utils";

export function getActionType(
  action: (
    | RecurringDualAction["gteAction"]
    | RecurringDualAction["lteAction"]
  ) & { slippage: number; maxGasProportion: number; strategyId?: string }
): RecurringPercentageAction | RecurringPriceAction | RecurringRatioAction {
  if ("tickLowerOffset" in action && "tickUpperOffset" in action) {
    return {
      ...action,
      type: ActionTypeEnum.Values.RecurringPercentage,
      tickLowerOffset: action.tickLowerOffset,
      tickUpperOffset: action.tickUpperOffset,
    };
  } else if (
    "baseToken" in action &&
    "priceLowerOffset" in action &&
    "priceUpperOffset" in action
  ) {
    return {
      ...action,
      type: ActionTypeEnum.Values.RecurringPrice,
      baseToken: action.baseToken,
      priceLowerOffset: action.priceLowerOffset,
      priceUpperOffset: action.priceUpperOffset,
    };
  } else if ("tickRangeWidth" in action && "token0ValueProportion" in action) {
    return {
      ...action,
      type: ActionTypeEnum.Values.RecurringRatio,
      tickRangeWidth: action.tickRangeWidth,
      token0ValueProportion: action.token0ValueProportion,
    };
  } else {
    throw new Error("Invalid action type");
  }
}

export function parseDualAction(triggerAction: RecurringDualAction) {
  const { strategyId, slippage, maxGasProportion, lteAction, gteAction } =
    triggerAction;

  const [belowAction, aboveAction] = [lteAction, gteAction].map((action) =>
    getActionType({
      ...action,
      strategyId,
      slippage,
      maxGasProportion,
    })
  );
  return [belowAction, aboveAction];
}

export function getStrategyInfoListItems(
  trigger: TriggerItem,
  tokens: IToken[],
  executedTimes: number | undefined,
  autoCompoundOn: boolean = false,
  setSwap: (swap: boolean) => void
): InfoItem[] {
  const conditionSpotPrice = sqrtX96ToPrice(
    // @ts-ignore
    trigger?.condition?.sqrtPriceX96,
    tokens[0].decimals,
    tokens[1].decimals
  );

  const triggerValue = (function () {
    const { type: triggerType, durationSec } =
      trigger.condition as RecurringCondition;
    const duration = formatDuration(durationSec);

    if (triggerType === ConditionTypeEnum.Values.RecurringRatio) {
      const tokenA = tokens[0];
      const { gteToken0ValueProportion, lteToken0ValueProportion } =
        trigger.condition as RecurringRatioCondition;

      return [
        lteToken0ValueProportion ? (
          <div>
            {tokenA.ticker} ratio below{" "}
            {formatProportion(lteToken0ValueProportion)}% {duration}
          </div>
        ) : (
          <></>
        ),
        gteToken0ValueProportion ? (
          <div>
            {tokenA.ticker} ratio above{" "}
            {formatProportion(gteToken0ValueProportion)}% {duration}
          </div>
        ) : (
          <></>
        ),
      ];
    }

    if (triggerType === ConditionTypeEnum.Values.RecurringPrice) {
      const { baseToken, gtePriceOffset, ltePriceOffset } =
        trigger.condition as RecurringPriceCondition;
      const tokenA = tokens[baseToken];
      const tokenB = tokens[1 - baseToken];

      return [
        ltePriceOffset ? (
          <div>
            {tokenA.ticker} Price {formatTokenAmount(ltePriceOffset)}{" "}
            {tokenB.ticker} below spot price {duration}
          </div>
        ) : (
          <></>
        ),
        gtePriceOffset ? (
          <div>
            {tokenA.ticker} Price {formatTokenAmount(gtePriceOffset)}{" "}
            {tokenB.ticker} above spot price {duration}
          </div>
        ) : (
          <></>
        ),
      ];
    }
    if (triggerType === ConditionTypeEnum.Values.RecurringPercentage) {
      const { gteTickOffset, lteTickOffset } =
        trigger.condition as RecurringPercentageCondition;
      const tokenA = tokens[0];

      return [
        lteTickOffset ? (
          <div>
            {tokenA.ticker} Price {offsetTickToPercentage(lteTickOffset)}% below
            spot price {duration}
          </div>
        ) : (
          <></>
        ),
        gteTickOffset ? (
          <div>
            {tokenA.ticker} Price {offsetTickToPercentage(gteTickOffset)}% above
            spot price {duration}
          </div>
        ) : (
          <></>
        ),
      ];
    }
    return "";
  })();

  const rebalanceValue = (function () {
    if (trigger.action.type === ActionTypeEnum.Values.RecurringRatio) {
      const { tickRangeWidth, token0ValueProportion } =
        trigger.action as RecurringRatioAction;

      const tokenARatio = formatProportion(token0ValueProportion);
      const tokenBRatio = (100 - parseFloat(tokenARatio)).toFixed(2);

      return `${tokenARatio}% ${tokens[0].ticker} & ${tokenBRatio}% ${tokens[1].ticker} with ${tickRangeWidth} tick width based on future spot price`;
    }
    if (trigger.action.type === ActionTypeEnum.Values.RecurringPrice) {
      const { baseToken, priceLowerOffset, priceUpperOffset } =
        trigger.action as RecurringPriceAction;
      const tokenA = tokens[baseToken];
      const tokenB = tokens[1 - baseToken];
      return (
        <div>
          <div>
            Lower price bound: {formatTokenAmount(priceLowerOffset)}{" "}
            {tokenB.ticker} below {tokenA.ticker} future spot price
          </div>
          <div>
            Upper price bound: {formatTokenAmount(priceUpperOffset)}{" "}
            {tokenB.ticker} above {tokenA.ticker} future spot price
          </div>
        </div>
      );
    }
    if (trigger.action.type === ActionTypeEnum.Values.RecurringPercentage) {
      const { tickLowerOffset, tickUpperOffset } =
        trigger.action as RecurringPercentageAction;

      return (
        <div>
          <div>
            Lower price bound: {offsetTickToPercentage(tickLowerOffset)}% below{" "}
            {tokens[0].ticker} future spot price
          </div>
          <div>
            Upper price bound: {offsetTickToPercentage(tickUpperOffset)}% above{" "}
            {tokens[0].ticker} future spot price
          </div>
        </div>
      );
    }
    return "";
  })();

  const autoCompoundStatus = autoCompoundOn ? "On" : "Off";
  return [
    {
      label: "Condition Spot Price",
      value: conditionSpotPrice ? (
        <Flex gap="md">
          <T3Regular>
            {formatMarketPrice(conditionSpotPrice)} {tokens[1].ticker} per{" "}
            {tokens[0].ticker}
          </T3Regular>
          <SwapButton2
            onClick={(event) => {
              event.stopPropagation();
              setSwap(true);
            }}
          >
            <SwapButtonIcon />
          </SwapButton2>
        </Flex>
      ) : (
        ""
      ),
    },
    {
      label: "Condition Spot Price",
      value: !!Number(conditionSpotPrice) ? (
        <Flex gap="md">
          <T3Regular>
            {formatMarketPrice(bigDecimal.divide(1, conditionSpotPrice, 18))}{" "}
            {tokens[0].ticker} per {tokens[1].ticker}
          </T3Regular>
          <SwapButton2
            onClick={(event) => {
              event.stopPropagation();
              setSwap(false);
            }}
          >
            <SwapButtonIcon />
          </SwapButton2>
        </Flex>
      ) : (
        ""
      ),
    },
    {
      label: "Trigger",
      value: triggerValue,
    },
    {
      label: "Rebalance to",
      value: rebalanceValue,
    },
    {
      label: "Auto Compound Status",
      value: autoCompoundStatus,
    },
    {
      label: "Number of rebalances executed",
      value: `${executedTimes ?? "-"}`,
    },
  ];
}

const formatProportion = (proportion: string) => {
  return (parseFloat(proportion) * 100).toFixed(2);
};

export const TICK_MULTIPLIER = 1.0001;
function offsetTickToPercentage(offsetTick: number) {
  const target = TICK_MULTIPLIER ** offsetTick;
  return (Math.round(target * 1e4 - 1e4) / 100).toFixed(2);
}

import bigDecimal from "js-big-decimal";

export const MIN_FIXED_VALUE = 0.01;
export const MIN_PRICE_RANGE_VALUE = 0.000001;
export const DEFAULT_SIGNIFICANT = 5;
export const DEFAULT_FIXED_DECIMAL = 3;
const DEFAULT_COMMAS = true;
const DEFAULT_TRAILING_ZERO = true;
const regexExpression = {
  regexNumber: /^([-+]?)(0|[1-9]\d*)(\.\d+)?$/,
  regexComma: /,/g,
  regex3digits: /\B(?=(\d{3})+(?!\d))/g,
};

export function isValidNumberString(value: string) {
  return value.match(regexExpression.regexNumber);
}

/**
 * when parse with number, make sure value itself is valid and no overflow
 */
export function formatTokenAmount(
  value: string | number,
  precision: number | undefined = undefined,
  commas: boolean = DEFAULT_COMMAS
) {
  if (value === Infinity || value === "∞") return "∞";
  // Array ["0.00009", "", "0", ".00009"]
  // Array ["-0.00009", "-", "0", ".00009"]
  const matchValue = isValidNumberString(value.toString());
  if (matchValue === null) return "-";

  if (
    bigDecimal.compareTo(matchValue[2], 0) === 0 &&
    bigDecimal.compareTo(matchValue[3], MIN_FIXED_VALUE) < 0 &&
    bigDecimal.compareTo(matchValue[3], 0) > 0
  )
    return formatSignature(value, precision, commas);
  return formatFixed(value, precision, commas);
}

/**
 * when parse with number, make sure value itself is valid and no overflow
 */
export function formatUSDAmount(
  value: string | number,
  precision: number | undefined = undefined,
  commas: boolean = DEFAULT_COMMAS
) {
  if (value === "-") return value;
  let result = formatFixed(value, precision, commas);
  result =
    result === "<" + MIN_FIXED_VALUE ? "<$" + MIN_FIXED_VALUE : "$" + result;
  return result;
}

/**
 * when parse with number, make sure value itself is valid and no overflow
 */
export function formatPrice(
  value: string | number,
  precision: number = DEFAULT_FIXED_DECIMAL,
  commas: boolean = DEFAULT_COMMAS
) {
  // return formatFixed(value, precision, commas, false);
  if (value === Infinity || value === "∞") return "∞";
  // Array ["0.00009", "", "0", ".00009"]
  // Array ["-0.00009", "-", "0", ".00009"]
  const matchValue = isValidNumberString(value.toString());
  if (matchValue === null) return "-";

  const integerValue = numberParseLarge(
    matchValue[2],
    false,
    precision,
    commas,
    false
  );
  if (bigDecimal.compareTo(matchValue[2], 1e6) >= 0)
    return matchValue[1] + integerValue;
  else if (
    bigDecimal.compareTo(matchValue[2], 0) === 0 &&
    bigDecimal.compareTo(matchValue[3], MIN_PRICE_RANGE_VALUE) < 0 &&
    bigDecimal.compareTo(matchValue[3], 0) > 0
  )
    return "<" + MIN_PRICE_RANGE_VALUE;
  else if (
    bigDecimal.compareTo(matchValue[2], 0) === 0 &&
    bigDecimal.compareTo(matchValue[3], MIN_FIXED_VALUE) < 0 &&
    bigDecimal.compareTo(matchValue[3], MIN_PRICE_RANGE_VALUE) >= 0
  )
    return (
      matchValue[1] + integerValue + floatParse(matchValue[3], false, 6, false)
    );
  else if (
    bigDecimal.compareTo(matchValue[2], 0) === 0 &&
    bigDecimal.compareTo(matchValue[3], 0) === 0
  )
    return "0";
  return (
    matchValue[1] +
    integerValue +
    floatParse(matchValue[3], false, precision, false)
  );
}

/**
 * when parse with number, make sure value itself is valid and no overflow
 */
export function formatMarketPrice(
  value: string | number,
  precision: number | undefined = undefined,
  commas: boolean = DEFAULT_COMMAS
) {
  if (value === Infinity || value === "∞") return "∞";
  // Array ["0.00009", "", "0", ".00009"]
  // Array ["-0.00009", "-", "0", ".00009"]
  const matchValue = isValidNumberString(value.toString());
  if (matchValue === null) return "-";

  if (
    bigDecimal.compareTo(matchValue[2], 0) === 0 &&
    bigDecimal.compareTo(matchValue[3], MIN_FIXED_VALUE) < 0 &&
    bigDecimal.compareTo(matchValue[3], 0) > 0
  )
    return formatSignature(value, precision, commas);
  else if (
    bigDecimal.compareTo(matchValue[2], 0) === 0 &&
    bigDecimal.compareTo(matchValue[3], 0) === 0
  )
    return "0";
  return (
    matchValue[1] +
    matchValue[2] +
    floatParse(matchValue[3], false, precision ?? DEFAULT_FIXED_DECIMAL, false)
  );
}

/**
 * when parse with number, make sure value itself is valid and no overflow
 */
export function formatSignature(
  value: string | number,
  precision: number = DEFAULT_SIGNIFICANT,
  commas: boolean = DEFAULT_COMMAS
) {
  if (value === Infinity || value === "∞") return "∞";
  // Array ["0.00009", "", "0", ".00009"]
  // Array ["-0.00009", "-", "0", ".00009"]
  const matchValue = isValidNumberString(value.toString());
  if (matchValue === null) return "-";

  const integerValue = numberParseLarge(matchValue[2], true, precision, commas);
  if (bigDecimal.compareTo(matchValue[2], 1e6) >= 0)
    return matchValue[1] + integerValue;
  else if (
    bigDecimal.compareTo(matchValue[2], 0) === 0 &&
    bigDecimal.compareTo(matchValue[3], 0) === 0
  )
    return "0";
  return (
    matchValue[1] + integerValue + floatParse(matchValue[3], true, precision)
  );
}

/**
 * when parse with number, make sure value itself is valid and no overflow
 */
export function formatFixed(
  value: string | number,
  decimals: number = DEFAULT_FIXED_DECIMAL,
  commas: boolean = DEFAULT_COMMAS,
  trailingZero: boolean = DEFAULT_TRAILING_ZERO
) {
  if (value === Infinity || value === "∞") return "∞";
  // Array ["0.00009", "", "0", ".00009"]
  // Array ["-0.00009", "-", "0", ".00009"]
  const matchValue = isValidNumberString(value.toString());
  if (matchValue === null) return "-";

  const integerValue = numberParseLarge(
    matchValue[2],
    false,
    decimals,
    commas,
    trailingZero
  );
  if (bigDecimal.compareTo(matchValue[2], 1e6) >= 0)
    return matchValue[1] + integerValue;
  else if (
    bigDecimal.compareTo(matchValue[2], 0) === 0 &&
    bigDecimal.compareTo(matchValue[3], MIN_FIXED_VALUE) < 0 &&
    bigDecimal.compareTo(matchValue[3], 0) > 0
  )
    return "<" + MIN_FIXED_VALUE;
  else if (
    bigDecimal.compareTo(matchValue[2], 0) === 0 &&
    bigDecimal.compareTo(matchValue[3], 0) === 0
  )
    return "0";
  return (
    matchValue[1] +
    integerValue +
    floatParse(matchValue[3], false, decimals, trailingZero)
  );
}

export function numberParseLarge(
  intValue: string,
  toSignificant: boolean,
  precision: number,
  commas: boolean = DEFAULT_COMMAS,
  trailingZero: boolean = DEFAULT_TRAILING_ZERO
) {
  precision = precision <= 0 ? 0 : Math.floor(precision);
  const intLen = intValue.length;
  if (intLen < 7) return commas ? addCommas(intValue) : intValue;
  else if (intLen < 10)
    return formatToMillionBillionTrillion(
      intValue,
      intLen - 6,
      toSignificant,
      "M",
      precision,
      commas,
      trailingZero
    );
  else if (intLen < 13)
    return formatToMillionBillionTrillion(
      intValue,
      intLen - 9,
      toSignificant,
      "B",
      precision,
      commas,
      trailingZero
    );
  return formatToMillionBillionTrillion(
    intValue,
    intLen - 12,
    toSignificant,
    "T",
    precision,
    commas,
    trailingZero
  );
}

/**
 * floatValue should be started with '.'
 */
export function floatParse(
  floatValue: string,
  toSignificant: boolean,
  precision: number,
  trailingZero: boolean = DEFAULT_TRAILING_ZERO
) {
  precision = precision <= 0 ? 0 : Math.floor(precision);
  if (
    precision === 0 ||
    floatValue === undefined ||
    bigDecimal.compareTo(floatValue, 0) === 0
  )
    return trailingZero ? "" : ".".padEnd(precision + 1, "0");
  const startIndex = floatValue.match(/\.(0*[^0])/)![1].length;
  let result = floatValue.substring(0, startIndex + precision); // to significant
  if (!toSignificant) result = result.substring(0, precision + 1); // to fixed ${precision} decimals
  if (trailingZero) result = result.replace(/\.?0+$/, ""); // remove ending 0s
  return result;
}

export function formatToMillionBillionTrillion(
  intValue: string,
  floatStartIndex: number,
  toSignificant: boolean,
  suffix: "M" | "B" | "T",
  precision: number,
  commas: boolean = DEFAULT_COMMAS,
  trailingZero: boolean = DEFAULT_TRAILING_ZERO
) {
  const firstPart = commas
    ? addCommas(intValue.substring(0, floatStartIndex))
    : intValue.substring(0, floatStartIndex);
  if (precision === 0) return firstPart + suffix;
  const precisionFloat = floatParse(
    "." + intValue.substring(floatStartIndex),
    toSignificant,
    precision,
    trailingZero
  );
  return firstPart + precisionFloat + suffix;
}

/**
 * floatValue should be started with '.'
 */
export function getFirstNonZeroIndex(floatValue: string) {
  for (let i = 1; i < floatValue.length; i++) {
    if (floatValue[i] === "0") continue;
    else return i;
  }
  return 1;
}

export function addCommas(value: string | number) {
  const matchValue = isValidNumberString(value.toString());
  if (matchValue === null) return "-";
  const integerWithCommas = matchValue[2].replace(
    regexExpression.regex3digits,
    ","
  );
  if (matchValue[3] === undefined) return matchValue[1] + integerWithCommas;
  return matchValue[1] + integerWithCommas + matchValue[3];
}

export function removeCommas(value: string) {
  return value.replace(regexExpression.regexComma, "");
}

export const numStrToFixed = (str: string, decimal: number = 2) => {
  let regex = new RegExp("^\\d+\\.\\d{1," + decimal + "}");
  let match = str.match(regex);
  return str.includes(".")
    ? match
      ? match[0]
      : str
    : str + "." + "0".repeat(decimal);
};

export const trimNumber = (number: string | number, decimal?: number) => {
  if (typeof number === "number") number = number.toString();
  if (decimal) {
    number = numStrToFixed(number, decimal);
  }
  const regExp = /(?:\.0*|(\.\d+?)0+)$/;
  return number.replace(regExp, "$1");
};

export const convertFloatToString = (value: number) => {
  if (value === undefined) {
    return "0";
  }
  return new bigDecimal(value).getValue();
};

export function limitToScale(numStr: string, scale: number | undefined) {
  if (typeof scale !== "number") return numStr;
  const parts = numStr.split(".");
  if (parts.length < 2 || (parts.length === 2 && parts[1].length <= scale)) {
    return numStr;
  }
  // If there's a decimal point and the decimal part exceeds scale characters
  else if (parts.length === 2 && parts[1].length > scale) {
    return parts[0] + "." + parts[1].substring(0, scale);
  }
  return numStr;
}

import { Flex } from "@ui/common";
import {
  INetworkInfo,
  IPopoverRef,
  Popover,
  PopoverMenu,
} from "@ui/components";
import React, { useRef, useState } from "react";
import { IDropMenuProps } from ".";
import { DropdownBox, Title } from "./style";

export const DropMenu: React.FC<IDropMenuProps> = ({
  networkInfo,
  userMenu,
  title,
  bgImg,
  onNav,
  onSelect,
  internalMenuLabel = "",
  mainMenu,
  ...props
}) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const popoverRef = useRef<IPopoverRef>(null);
  const handleOnNav = (link?: string) => {
    if (!link) return;
    onNav && onNav(link);
    popoverRef?.current?.onClose();
  };

  const handleOnSelect = (item: INetworkInfo, pathname?: string) => {
    onSelect && onSelect(item, pathname);
    popoverRef?.current?.onClose();
  };

  return (
    <Popover
      ref={popoverRef}
      background={"transparent"}
      defaultPosition={{ left: 0, top: 0 }}
      contentStyles={{
        padding: 0,
        borderRadius: "10px",
        overflow: "visible",
        left: "50%",
        transform: "translateX(-50%)",
      }}
      noBorder={true}
      content={
        <DropdownBox bgImg={bgImg} isSubMenuOpen={isSubMenuOpen}>
          <Flex
            style={{
              justifyContent: "space-between",
              padding: 4,
              borderRadius: 10,
            }}
          >
            <Title>{title}</Title>
            <PopoverMenu
              networkInfo={networkInfo}
              onNav={handleOnNav}
              onSelect={handleOnSelect}
              internalMenuLabel={internalMenuLabel}
              mainMenu={mainMenu}
              onDisappeared={() => setIsSubMenuOpen(false)}
              onOpen={(status) => setIsSubMenuOpen(status)}
            />
          </Flex>
        </DropdownBox>
      }
      {...props}
    >
      {userMenu}
    </Popover>
  );
};

import React from "react";
import styled from "styled-components";
import { limitToScale } from "../../utils";
import { Headline4, Headline5 } from "../Typography";
import { Input } from "./Input";
import { InputStartingPriceProps } from "./types";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.lg};
`;
const InputContainer = styled.div`
  display: flex;
  padding: 12px 16px;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
  flex: 1 0 0;
  border-radius: ${({ theme }) => theme.radii.md};
  background: ${({ theme }) => theme.colors.global.background.BG1};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
`;
const StyledInput = styled(Input)`
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  padding: 0px;
  :focus {
    border-color: ${({ theme }) => theme.colors.transparent.transparent};
  }
  border: none !important;
  border-radius: 0;
`;
const Text = styled(Headline4)`
  font-weight: 500;
  white-space: nowrap;
`;

export const InputStartingPriceGroup: React.FC<InputStartingPriceProps> = ({
  base,
  ticker,
  value,
  onValueChange,
  decimalScale = 6,
  ...props
}) => {
  return (
    <Container>
      <Headline5>Starting {`${base}`} Price</Headline5>
      <InputContainer>
        <StyledInput
          placeholder="0.00"
          //@ts-ignore
          value={value ? limitToScale(value, decimalScale) : ""}
          onChange={(value: string) =>
            onValueChange(limitToScale(value, decimalScale))
          }
          {...props}
        />
        <Text>{ticker}</Text>
      </InputContainer>
    </Container>
  );
};

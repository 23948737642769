import { OutlineLogo } from "@aperture/assetkit";
import { FeeAmount } from "@aperture_finance/uniswap-v3-sdk";
import { format } from "d3";
import bigDecimal from "js-big-decimal";
import { saturate } from "polished";
import { useCallback, useMemo } from "react";
import styled, { useTheme } from "styled-components";
import { Button } from "../Button";
import { LinearLoader } from "../Loader";
import { Headline5 } from "../Typography";
import { Chart } from "./Chart";
import { Bound, LiquidityChartRangeInputProps, ZoomLevels } from "./types";

const ZOOM_LEVELS: Record<FeeAmount, ZoomLevels> = {
  [FeeAmount.LOWEST]: {
    initialMin: 0.9,
    initialMax: 1.1,
    min: 0.001,
    max: 20,
  },
  [FeeAmount.LOW]: {
    initialMin: 0.9,
    initialMax: 1.1,
    min: 0.001,
    max: 20,
  },
  [FeeAmount.MEDIUM]: {
    initialMin: 0.9,
    initialMax: 1.1,
    min: 0.001,
    max: 10,
  },
  [FeeAmount.PCS_V3_MEDIUM]: {
    initialMin: 0.9,
    initialMax: 1.1,
    min: 0.001,
    max: 10,
  },
  [FeeAmount.HIGH]: {
    initialMin: 0.9,
    initialMax: 1.1,
    min: 0.001,
    max: 10,
  },
};

const LoaderWrapper = styled.div<{ height: number }>`
  display: flex;
  align-items: center;
  height: ${({ height }) => `${height}px`};
`;

const ButtonWrapper = styled.div`
  position: absolute;
  left: 0;
  z-index: ${({ theme }) => theme.zIndices.ribbon};
`;

const StyledButton = styled(Button)`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 12px 24px;
`;

const InfoContent = styled.div<{ height: number }>`
  height: ${({ height }) => `${height}px`};
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  align-items: center;
`;
const Message = styled(Headline5)`
  color: ${({ theme }) => theme.colors.global.text.T3};
  text-align: center;
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export function LiquidityChartRangeInput({
  currencyA,
  currencyB,
  feeAmount,
  ticksAtLimit,
  price,
  priceLower,
  priceUpper,
  onLeftRangeInput,
  onRightRangeInput,
  interactive,
  chartHeight,
  dataState,
  currentRange,
}: LiquidityChartRangeInputProps) {
  const theme = useTheme();
  const { isLoading, error, formattedData } = dataState;

  const onBrushDomainChangeEnded = useCallback(
    (domain: [number, number], mode: string | undefined) => {
      let leftRangeValue = domain[0];
      const rightRangeValue = domain[1];

      if (bigDecimal.compareTo(leftRangeValue, 0) <= 0) {
        leftRangeValue = 1 / 1e6;
      }

      // simulate user input for auto-formatting and other validations
      // if mode is undefined, then ignore update
      if (
        (!ticksAtLimit[Bound.LOWER] || mode) &&
        bigDecimal.compareTo(leftRangeValue, 0) > 0
      ) {
        onLeftRangeInput(bigDecimal.round(leftRangeValue, 18));
      }

      if (
        (!ticksAtLimit[Bound.UPPER] || mode) &&
        bigDecimal.compareTo(rightRangeValue, 0) > 0
      ) {
        // sometimes fails to parse to tick.
        if (bigDecimal.compareTo(rightRangeValue, 1e35) < 0) {
          onRightRangeInput(bigDecimal.round(rightRangeValue, 18));
        }
      }
    },
    [onLeftRangeInput, onRightRangeInput, ticksAtLimit]
  );

  interactive = interactive && Boolean(formattedData?.length);

  const brushDomain: [number, number] | undefined = useMemo(() => {
    const leftPrice = priceLower;
    const rightPrice = priceUpper;

    return leftPrice && rightPrice
      ? [parseFloat(leftPrice), parseFloat(rightPrice)]
      : undefined;
  }, [priceLower, priceUpper]);

  const brushLabelValue = useCallback(
    (d: "w" | "e", x: number) => {
      if (!price) return "";
      const percent =
        (x < price ? -1 : 1) *
        ((Math.max(x, price) - Math.min(x, price)) / price) *
        100;
      return price
        ? `${format(Math.abs(percent) > 1 ? ".2~s" : ".2~f")(percent)}%`
        : "";
    },
    [price]
  );

  // We set priceUpper to the highest price in the dataset
  /* const setFullRange = useCallback(() => {
    const highestPrice = formattedData
      ? Math.max(...formattedData.map((item) => item.price0))
      : "∞";
    onRightRangeInput(String(highestPrice));
    onLeftRangeInput("0");
  }, [onLeftRangeInput, onRightRangeInput, formattedData]); */

  const setCurrentRange = useCallback(() => {
    if (currentRange) {
      onLeftRangeInput(String(currentRange[0]));
      onRightRangeInput(String(currentRange[1]));
    }
  }, [currentRange, onLeftRangeInput, onRightRangeInput]);

  const noTokenPairOrFeeAmount = !currencyA || !currencyB || !feeAmount;
  const isUninitialized = formattedData === undefined && !isLoading;

  const createInfoBox = (message: string, height: number) => (
    <InfoContent height={height}>
      <OutlineLogo fill={theme.colors.global.text.T1} />
      <Message>{message}</Message>
    </InfoContent>
  );

  return (
    <>
      {noTokenPairOrFeeAmount ? (
        createInfoBox(
          "Select token pair and fee tier to show your position.",
          chartHeight + 48
        )
      ) : isUninitialized ? (
        createInfoBox("Your position will appear here.", chartHeight)
      ) : isLoading ? (
        <LoaderWrapper height={chartHeight}>
          <LinearLoader size="100%" height="100px" radius="10px" />
        </LoaderWrapper>
      ) : error ? (
        createInfoBox("Liquidity data not available.", chartHeight)
      ) : !formattedData ||
        formattedData.length === 0 ||
        price === undefined ? (
        createInfoBox("There is no liquidity data.", chartHeight)
      ) : (
        <>
          {currentRange && (
            <ButtonWrapper>
              <StyledButton
                variant="outlined"
                color="secondary"
                size="sm"
                onClick={setCurrentRange}
              >
                Current Range
              </StyledButton>
            </ButtonWrapper>
          )}
          <Chart
            data={{ series: formattedData, current: price }}
            dimensions={{ width: 400, height: chartHeight }}
            margins={{ top: 0, right: 0, bottom: 20, left: 0 }}
            styles={{
              area: {
                selection: theme.colors.global.text.T1,
              },
              brush: {
                handle: {
                  west: saturate(0.1, theme.colors.global.primary),
                  east: saturate(0.1, theme.colors.global.primary),
                },
              },
              currentBrush: {
                handle: {
                  west: saturate(0.1, theme.colors.global.primary),
                  east: saturate(0.1, theme.colors.global.primary),
                },
              },
            }}
            interactive={interactive}
            brushLabels={brushLabelValue}
            brushDomain={brushDomain}
            onBrushDomainChange={onBrushDomainChangeEnded}
            zoomLevels={ZOOM_LEVELS[feeAmount] ?? ZOOM_LEVELS[FeeAmount.MEDIUM]}
            ticksAtLimit={ticksAtLimit}
            currentRange={currentRange}
            brushMinValue={0}
          />
        </>
      )}
    </>
  );
}

import { PropsWithChildren } from "react";
import { IconWrapper, StyledButton, StyledButtonWrapper } from "./styles";
import { ButtonProps } from "./types";

const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
  children,
  size = "lg",
  ...props
}) => {
  const { icon, iconPosition = "start" } = props;
  const startIcon = !!icon && iconPosition === "start";
  const endIcon = !!icon && iconPosition === "end";
  return (
    <StyledButtonWrapper width={props.width}>
      <StyledButton {...{ ...props, size }} disabled={props.disabled}>
        {startIcon && <IconWrapper size={size}>{icon}</IconWrapper>}
        {children}
        {endIcon && <IconWrapper size={size}>{icon}</IconWrapper>}
      </StyledButton>
    </StyledButtonWrapper>
  );
};

export default Button;

import { ArrowLeftIcon, ArrowReturnLeftIcon } from "@aperture/assetkit";
import { PropsWithChildren } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: end;
  gap: ${({ theme }) => theme.spacing.lg};
  cursor: pointer;
  height: 20px;
  text-align: end;
  color: ${({ theme }) => theme.colors.global.text.T1};
  transition: ${({ theme }) => theme.transitions.default};
  &:hover {
    color: ${({ theme }) => theme.colors.global.text.T2};
    svg {
      * {
        stroke: ${({ theme }) => theme.colors.global.text.T2};
        transition: ${({ theme }) => theme.transitions.default};
      }
    }
  }
  svg {
    * {
      transition: ${({ theme }) => theme.transitions.default};
    }
  }
`;
const ArrowLeft = styled(ArrowLeftIcon)`
  width: 32px;
  height: 32px;

  path {
    fill: ${({ theme }) => theme.colors.global.text.T1};
    stroke: ${({ theme }) => theme.colors.global.text.T1};
  }
`;
const ArrowReturn = styled(ArrowReturnLeftIcon)`
  width: 20px;
  height: 20px;

  path {
    stroke: ${({ theme }) => theme.colors.global.text.T1};
  }
`;

export const DrawerTitle: React.FC<
  PropsWithChildren<{
    onClick?: () => void;
    isDrawer?: boolean;
  }>
> = ({ children, isDrawer = false, ...props }) => {
  return (
    <Container {...props}>
      {isDrawer ? <ArrowReturn /> : <ArrowLeft />}
      {children}
    </Container>
  );
};

import { ITokenTickerIcon } from "@aperture/types";
import { FeeAmount } from "@aperture_finance/uniswap-v3-sdk";
import React from "react";

export enum OrderStatus {
  ACTIVE = "ACTIVE",
  FULFILLED = "FULFILLED",
  CANCELED = "CANCELED",
  EXPIRED = "EXPIRED",
  FAILED = "FAILED",
  UNAUTHORIZED = "UNAUTHORIZED",
}

export interface OrderCardProps {
  status: OrderStatus;
  from: ITokenTickerIcon;
  to: ITokenTickerIcon;
  dateTime: string;
  info: OrderCardInfo;
  notification?: string;
  Btn?: React.ReactNode;
  navTo?: () => void;
}

export interface OrderCardInfo {
  buyAmount: string;
  sellAmount: string;
  limitPrice: string;
  feeTier?: FeeAmount;
  min?: string;
  max?: string;
  revenue?: {
    earnedFeeInputToken: string | undefined;
    earnedFeeOutputToken: string | undefined;
  };
}

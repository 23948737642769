import { ITokenPriceMap } from "@/components/GlobalStore/allChain/types";
import { IPositionDetails } from "@/config/position/positionConfig";
import { ITokenMap } from "@/hooks/useFetchTokenMap";
import {
  AnalyticPosition,
  parseAnalyticPositionData,
} from "@/utils/analytics/helper";
import { getAutomanAPI } from "@/utils/helper";
import { getNetworkName } from "@/utils/networkHelper";
import { numberToPercent } from "@/utils/routing/utils/slippage";
import { getFormattedPriceRange } from "@/views/ActivePositions/getPositionCardProps";
import { OkxIcon } from "@aperture/assetkit";
import { ITokenTickerIconPair } from "@aperture/types";
import {
  AmmEnum,
  AmmInfo,
  FeeTierPercentage,
  IPriceRangeWithTick,
  ITransactionSettingsForm,
  ITriggerSetupForm,
} from "@aperture/uikit";
import { Projection } from "@aperture/uikit/src/components/TriggerList/types";
import {
  ActivityType,
  IActivityLog,
  IClosedPositionCardProps,
  IClosedPositions,
} from "@aperture/uikitv2";
import {
  ApertureSupportedChainId,
  AutomanClient,
  CheckPositionPermitRequest,
  ClientTypeEnum,
  PermitInfo,
  PriceCondition,
  RebalanceAction,
  TriggerItem,
  UpdatePositionPermitRequest,
  getLogger,
  normalizeTicks,
  parsePrice,
  priceToClosestUsableTick,
  viem,
} from "@aperture_finance/uniswap-v3-automation-sdk";
import { E_Solver } from "@aperture_finance/uniswap-v3-automation-sdk/dist/viem";
import {
  FeeAmount,
  IncreaseOptions,
  MintOptions,
  Pool,
  Position,
  RemoveLiquidityOptions,
  tickToPrice,
} from "@aperture_finance/uniswap-v3-sdk";
import {
  ITokenInfoProps,
  SwapPathProps,
  SwapRouteProps,
  TokenIcon,
} from "@ui/components";
import { IPositionAnalytics } from "@uiv2/components/PositionAnalytics/types";
import { IPositionOverview } from "@uiv2/components/PositionOverview";
import { BigintIsh, CurrencyAmount, Percent, Token } from "@uniswap/sdk-core";
import Big from "big.js";
import bigDecimal from "js-big-decimal";
import { Address, PublicClient, formatUnits, getAddress } from "viem";
import { getDeadlineWithMins } from "../deadlineHelper";
import { getTokenPercentages, getTokensFromInfo } from "../tokenHelper";
import { getTriggerCondition, getTriggerType } from "../triggerHelper";

export interface LiquidityDetails {
  tokenAAddress: string;
  tokenBAddress: string;
  tokenAAmount: string; // human-readable amount
  tokenBAmount: string;
  poolFee: FeeAmount;
  priceLower: number;
  priceUpper: number;
}

export function getPosition(
  pool: Pool,
  tokenAAddress: string,
  tokenAAmount: string,
  tokenBAmount: string,
  tickLower: number,
  tickUpper: number
) {
  const isTokenAToken0 = tokenAAddress === pool.token0.address;
  const amount0 = viem.getCurrencyAmount(
    pool.token0,
    bigDecimal.round(
      isTokenAToken0 ? tokenAAmount : tokenBAmount,
      pool.token0.decimals,
      bigDecimal.RoundingModes.DOWN
    )
  ).quotient;
  const amount1 = viem.getCurrencyAmount(
    pool.token1,
    bigDecimal.round(
      isTokenAToken0 ? tokenBAmount : tokenAAmount,
      pool.token1.decimals,
      bigDecimal.RoundingModes.DOWN
    )
  ).quotient;

  try {
    return Position.fromAmounts({
      pool,
      tickLower,
      tickUpper,
      amount0,
      amount1,
      useFullPrecision: false,
    });
  } catch (e) {
    getLogger().error("Position.fromAmounts.Error", {
      errorMessage: (e as Error).message,
      tickLower,
      tickUpper,
      amount0: amount0.toString(),
      amount1: amount1.toString(),
    });
    return null;
  }
}

export interface CreatePositionDetails {
  walletAddress: string;
  chainId: ApertureSupportedChainId;
  tokenAAddress: string;
  tokenBAddress: string;
  tokenAAmount: string;
  tokenBAmount: string;
  poolFee: FeeAmount;
  tickLower: number;
  tickUpper: number;
  slippage: BigintIsh;
  deadline: string;
  useNative?: Boolean;
}

export function getCreatePositionRequest(
  amm: AmmEnum,
  {
    tokenAAddress,
    tokenAAmount,
    tokenBAmount,
    tickLower,
    tickUpper,
    slippage,
    deadline,
    walletAddress,
    useNative,
    chainId,
  }: CreatePositionDetails,
  pool: Pool,
  client: PublicClient
) {
  const position = getPosition(
    pool,
    tokenAAddress,
    tokenAAmount,
    tokenBAmount,
    tickLower,
    tickUpper
  );

  if (!position) {
    throw new Error("Fail to get position");
  }

  const options: Omit<MintOptions, "createPool"> = {
    slippageTolerance: numberToPercent(Number(slippage)),
    deadline: Math.floor(Date.now() / 1000 + Number(deadline) * 60),
    recipient: walletAddress,
    useNative: useNative ? viem.getNativeCurrency(chainId) : undefined,
  };

  return viem.getCreatePositionTx(position, options, chainId, amm, client);
}

export async function getOptimalMintTxRequest(
  amm: AmmEnum,
  chainId: ApertureSupportedChainId,
  tokenA: ITokenInfoProps,
  tokenB: ITokenInfoProps,
  tokenAAmount: string,
  tokenBAmount: string,
  poolKey: number,
  tickLower: number,
  tickUpper: number,
  walletAddress: Address,
  deadline: string,
  slippage: number,
  swapData: Address,
  liquidity: bigint,
  client: PublicClient,
  token0FeeAmount?: bigint,
  token1FeeAmount?: bigint
) {
  const pool = await viem.getPool(
    tokenA.address,
    tokenB.address,
    poolKey,
    chainId,
    amm,
    client
  );
  const isTokenAToken0 = tokenA.address === pool.token0.address;
  const amount0 = bigDecimal.multiply(
    isTokenAToken0 ? tokenAAmount : tokenBAmount,
    new Big(10).pow(pool.token0.decimals)
  );
  const amount1 = bigDecimal.multiply(
    isTokenAToken0 ? tokenBAmount : tokenAAmount,
    new Big(10).pow(pool.token1.decimals)
  );
  const token0Amount = CurrencyAmount.fromRawAmount(
    (isTokenAToken0 ? tokenA : tokenB).native
      ? viem.getNativeCurrency(chainId)
      : pool.token0,
    amount0
  );
  const token1Amount = CurrencyAmount.fromRawAmount(
    (isTokenAToken0 ? tokenB : tokenA).native
      ? viem.getNativeCurrency(chainId)
      : pool.token1,
    amount1
  );

  return viem.getMintOptimalV3Tx(
    chainId,
    amm,
    token0Amount,
    token1Amount,
    poolKey,
    tickLower,
    tickUpper,
    walletAddress,
    BigInt(Math.floor(Date.now() / 1000) + Number(deadline) * 60),
    slippage,
    client,
    swapData,
    liquidity,
    token0FeeAmount,
    token1FeeAmount
  );
}

export async function getAddPositionRequest(
  amm: AmmEnum,
  chainId: number,
  details: IPositionDetails,
  pool: Pool,
  slippage: number,
  deadline: number,
  amount: [string, string],
  from: Address,
  client: PublicClient,
  useNative?: boolean
) {
  const position = getPosition(
    pool,
    details.tokenA.address,
    details.tokenA.amount,
    details.tokenB.amount,
    details.tickLower,
    details.tickUpper
  );

  if (!position) {
    throw new Error("Fail to get position");
  }

  const lpInfo = {
    pool: position.pool,
    tickLower: position.tickLower,
    tickUpper: position.tickUpper,
    amount0: viem.getCurrencyAmount(
      pool.token0,
      bigDecimal.round(
        amount[0],
        pool.token0.decimals,
        bigDecimal.RoundingModes.DOWN
      )
    ).quotient,
    amount1: viem.getCurrencyAmount(
      pool.token1,
      bigDecimal.round(
        amount[1],
        pool.token1.decimals,
        bigDecimal.RoundingModes.DOWN
      )
    ).quotient,
    useFullPrecision: false,
  };
  const liquidityToAdd = Position.fromAmounts(lpInfo).liquidity;
  const increaseLiquidityOptions: IncreaseOptions = {
    slippageTolerance: numberToPercent(slippage),
    deadline: Math.floor(Date.now() / 1000 + deadline * 60),
    tokenId: details.positionId,
    useNative: useNative ? viem.getNativeCurrency(chainId) : undefined,
  };
  return viem.getAddLiquidityTx(
    increaseLiquidityOptions,
    chainId,
    amm,
    from,
    client,
    liquidityToAdd.toString(),
    position
  );
}

export async function getRemovePositionRequest(
  walletAddress: string,
  amm: AmmEnum,
  chainId: ApertureSupportedChainId,
  positionId: string,
  percentage: Percent,
  slippage: Percent,
  deadline: number,
  client: PublicClient,
  useNative?: boolean
) {
  const { position } = await viem.PositionDetails.fromPositionId(
    chainId,
    amm,
    BigInt(positionId),
    client
  );
  const removeLiquidityOptions: Omit<RemoveLiquidityOptions, "collectOptions"> =
    {
      tokenId: positionId,
      liquidityPercentage: percentage,
      slippageTolerance: slippage,
      deadline: Math.floor(
        Date.now() / 1000 + (deadline === 0 ? 30 : deadline) * 60
      ),
    };
  return viem.getRemoveLiquidityTx(
    removeLiquidityOptions,
    walletAddress,
    chainId,
    amm,
    client,
    useNative,
    position
  );
}

/**
 * Computes a position with the maximum amount of liquidity received for a given amount of token0/token1, assuming an
 * unlimited amount of the other token.
 * @param address The address of the token for which the amount is given
 * @param amount The amount of the token
 * @param tickLower The lower tick
 * @param tickUpper The upper tick
 * @param pool The pool for which the position is created
 * @returns The amount of the other token required to create the position
 */
export function getOtherTokenAmount(
  address: string,
  amount: string,
  tickLower: number,
  tickUpper: number,
  pool: Pool
): CurrencyAmount<Token> {
  if (address === pool.token0.address) {
    return Position.fromAmount0({
      pool,
      tickLower,
      tickUpper,
      amount0: viem.getCurrencyAmount(
        pool.token0,
        bigDecimal.round(
          amount,
          pool.token0.decimals,
          bigDecimal.RoundingModes.DOWN
        )
      ).quotient,
      useFullPrecision: false,
    }).amount1;
  } else if (address === pool.token1.address) {
    return Position.fromAmount1({
      pool,
      tickLower,
      tickUpper,
      amount1: viem.getCurrencyAmount(
        pool.token1,
        bigDecimal.round(
          amount,
          pool.token1.decimals,
          bigDecimal.RoundingModes.DOWN
        )
      ).quotient,
    }).amount0;
  } else {
    throw new Error("Token address not in pool");
  }
}

export function priceToTick(
  token0: Token,
  token1: Token,
  humanAmount: string,
  tickSpacing: number,
  isToken0Amount?: boolean
) {
  const price = isToken0Amount
    ? parsePrice(token0, token1, humanAmount)
    : parsePrice(token1, token0, humanAmount);

  return priceToClosestUsableTick(price, undefined, tickSpacing);
}

export async function getRebalanceRequest(
  amm: AmmEnum,
  details: IPositionDetails,
  walletAddress: string,
  priceRange: IPriceRangeWithTick,
  txForm: ITransactionSettingsForm,
  permitInfo: PermitInfo | undefined,
  swapData: Address,
  liquidity: bigint,
  feeBips: bigint = 0n,
  client: PublicClient
) {
  const { position } = await viem.PositionDetails.fromPositionId(
    details.chainId,
    amm,
    BigInt(details.positionId),
    client
  );
  return viem.getRebalanceTx(
    details.chainId,
    amm,
    walletAddress as Address,
    BigInt(details.positionId),
    priceRange.tickLower,
    priceRange.tickUpper,
    numberToPercent(txForm.slippage),
    BigInt(
      getDeadlineWithMins(
        Number(txForm.deadline) === 0 || txForm.deadline === ""
          ? 30
          : Number(txForm.deadline)
      )
    ),
    client,
    swapData,
    liquidity,
    feeBips,
    position,
    permitInfo
  );
}

export async function checkPositionPermission(
  amm: AmmEnum,
  chainId: ApertureSupportedChainId,
  positionId: string
) {
  const autoClient = new AutomanClient(getAutomanAPI());
  const request: CheckPositionPermitRequest = {
    chainId,
    amm,
    tokenId: positionId,
    clientType: ClientTypeEnum.enum.FRONTEND,
  };
  return autoClient.checkPositionApproval(request);
}

export async function updatePositionPermit(
  amm: AmmEnum,
  chainId: ApertureSupportedChainId,
  positionId: string,
  permitInfo: PermitInfo
) {
  const autoClient = new AutomanClient(getAutomanAPI());
  const request: UpdatePositionPermitRequest = {
    chainId,
    amm,
    tokenId: positionId,
    permitInfo: permitInfo,
    clientType: ClientTypeEnum.enum.FRONTEND,
  };
  return autoClient.updatePositionPermit(request);
}

export async function checkPositionApproval(
  amm: AmmEnum,
  chainId: ApertureSupportedChainId,
  positionId: string,
  client: PublicClient
) {
  const { hasAuthority } = await viem.checkPositionApprovalStatus(
    BigInt(positionId),
    undefined,
    chainId,
    amm,
    client
  );
  return hasAuthority;
}

/**
 * Predict the position after rebalance assuming the pool price remains the same.
 * @param position The original position.
 * @param trigger The original trigger.
 * @param newTickLower The new lower tick.
 * @param newTickUpper The new upper tick.
 * @returns The token amounts after rebalance.
 */
export function getRebalanceEst(
  position: Position,
  trigger: TriggerItem,
  newTickLower: number,
  newTickUpper: number
) {
  if (trigger) {
    const action = trigger.action as RebalanceAction;
    const { tickLower, tickUpper } = normalizeTicks(action, position.pool);
    newTickLower = tickLower;
    newTickUpper = tickUpper;
  }
  try {
    const newPosition = viem.getRebalancedPosition(
      position,
      newTickLower,
      newTickUpper
    );
    return {
      token0Amount: newPosition.amount0.toExact(),
      token1Amount: newPosition.amount1.toExact(),
    };
  } catch (error) {
    getLogger().error(`Failed to getRebalanceEst: ${JSON.stringify(error)}`);
    return {
      token0Amount: "",
      token1Amount: "",
    };
  }
}

/**
 * Predict the position after rebalance assuming the pool price becomes the specified price.
 * @param position The original position.
 * @param newTickLower The new lower tick.
 * @param newTickUpper The new upper tick.
 * @param newPrice The new price.
 * @returns The token amounts after rebalance.
 */
export function getRebalanceEstAtPrice(
  position: Position,
  trigger: TriggerItem,
  newTickLower: number,
  newTickUpper: number,
  newPrice: Big
) {
  if (trigger) {
    const action = trigger.action as RebalanceAction;
    const { tickLower, tickUpper } = normalizeTicks(action, position.pool);
    newTickLower = tickLower;
    newTickUpper = tickUpper;
  }
  const newPosition = viem.projectRebalancedPositionAtPrice(
    position,
    newPrice,
    newTickLower,
    newTickUpper
  );
  return {
    token0Amount: newPosition.amount0.toExact(),
    token1Amount: newPosition.amount1.toExact(),
  };
}

/**
 * Predict the projected liquidity position info based on different trigger type
 * @param details The original position.
 * @param position The original position.
 * @param newTickLower The new lower tick.
 * @param newTickUpper The new upper tick.
 * @param tokenPrices The token price.
 * @param triggerSetupForm The trigger info when set up or edit a rebalance.
 * @param trigger The trigger info if it is from a trigger list.
 * @returns The projected liquidity position info.
 */
export function getProjectedLiquidityPosition(
  networkId: ApertureSupportedChainId,
  details: IPositionDetails,
  position: Position,
  newTickLower: number,
  newTickUpper: number,
  tokenPrices: [string, string],
  triggerSetupForm?: ITriggerSetupForm,
  trigger?: TriggerItem
): Projection {
  const triggerFormType = triggerSetupForm?.type;
  const triggerType = trigger ? getTriggerType(trigger) : undefined;
  const isTimeType = triggerFormType === "Time" || triggerType === "Time";

  if ((!trigger && !triggerFormType) || isTimeType) {
    const amounts = getRebalanceEst(
      position,
      trigger,
      newTickLower,
      newTickUpper
    );
    const percentages = getTokenPercentages(
      amounts.token0Amount,
      amounts.token1Amount,
      tokenPrices[0],
      tokenPrices[1]
    );
    return [
      { amount: amounts.token0Amount, percent: percentages.token0Percent },
      { amount: amounts.token1Amount, percent: percentages.token1Percent },
    ];
  }

  const triggerCondition = trigger
    ? (trigger.condition as PriceCondition)
    : (getTriggerCondition(
        triggerSetupForm,
        details.tokenA,
        details.tokenB,
        details.tickLower,
        details.tickUpper,
        networkId,
        false
      ) as PriceCondition);

  const priceAmount = triggerCondition.lte || triggerCondition.gte || "0";
  const price = new Big(priceAmount);

  const amounts =
    priceAmount === "0"
      ? getRebalanceEst(position, trigger, newTickLower, newTickUpper)
      : getRebalanceEstAtPrice(
          position,
          trigger,
          newTickLower,
          newTickUpper,
          price
        );
  const percentages = getTokenPercentages(
    amounts.token0Amount,
    amounts.token1Amount,
    priceAmount === "0"
      ? tokenPrices[0]
      : bigDecimal.multiply(
          price.toString(),
          Math.pow(10, position.pool.token0.decimals)
        ),
    priceAmount === "0"
      ? tokenPrices[1]
      : bigDecimal.multiply("1", Math.pow(10, position.pool.token1.decimals))
  );
  return [
    { amount: amounts.token0Amount, percent: percentages.token0Percent },
    { amount: amounts.token1Amount, percent: percentages.token1Percent },
  ];
}

export const filterPositions = (
  searchKeyword: string,
  positions: IPositionDetails[],
  stakePositionIds: bigint[]
) => {
  const newKey = searchKeyword.toLowerCase();
  if (newKey === "") return positions;
  return positions.filter(
    (position) =>
      ("auto-compound on auto compound on".includes(newKey) &&
        position.autoCompound) ||
      ("in range".includes(newKey) && position.inRange) ||
      ("out of range".includes(newKey) && !position.inRange) ||
      ("at staked".includes(newKey) &&
        stakePositionIds.includes(position.positionIdBN)) ||
      position.tokenA.ticker?.toLowerCase().includes(newKey) ||
      position.tokenB.ticker?.toLowerCase().includes(newKey) ||
      (position.feeTier / FeeTierPercentage + "%").startsWith(newKey) ||
      position.positionId.includes(newKey)
  );
};

export const calculateTotalValue = (
  amountA: string,
  amountB: string,
  priceA: string,
  priceB: string
) => {
  if (priceA && priceB)
    return bigDecimal.add(
      bigDecimal.multiply(amountA, priceA),
      bigDecimal.multiply(amountB, priceB)
    );
  return "-";
};

export const getPid = (position: IPositionDetails) => {
  return `${position.positionId}-${position.chainId}`;
};

const getApi = (
  solver: E_Solver,
  amm: AmmEnum,
  networkId: ApertureSupportedChainId
) => {
  if (solver === E_Solver.OneInch) {
    return {
      name: "1inch",
      Icon: (
        <TokenIcon
          src="https://assets.coingecko.com/coins/images/13469/thumb/1inch-token.png?1608803028"
          alt="1inch Icon"
        />
      ),
    };
  } else if (solver === E_Solver.OKX) {
    return {
      name: "OKX",
      Icon: <OkxIcon />,
    };
  } else {
    return {
      UNISWAP_V3: {
        name: "UNI V3",
        Icon: <AmmInfo.Uniswap.Icon />,
      },
      PANCAKESWAP_V3: {
        name: "PCS V3",
        Icon: <AmmInfo.PancakeSwap.Icon />,
      },
      SLIPSTREAM:
        networkId === ApertureSupportedChainId.BASE_MAINNET_CHAIN_ID
          ? {
              name: "Aerodrome",
              Icon: <AmmInfo.Aerodrome.Icon />,
            }
          : {
              name: "Velodrome",
              Icon: <AmmInfo.Velodrome.Icon />,
            },
    }[amm];
  }
};

export const getFormattedSwapInfo = (
  amm: AmmEnum,
  networkId: ApertureSupportedChainId,
  tokenMap: ITokenMap,
  swapInfo: Awaited<ReturnType<typeof viem.getMintOptimalSwapInfoV3>>
): SwapPathProps[] => {
  const nativeCurrency =
    tokenMap[`${viem.getNativeCurrency(networkId).wrapped.address}-true`];

  return swapInfo.map((data) => {
    const fromToken = tokenMap?.[`${data.swapPath.tokenIn}-${false}`];
    const toToken = tokenMap?.[`${data.swapPath.tokenOut}-${false}`];
    const routes: SwapRouteProps["routes"] =
      data.swapRoute?.map((route) => ({
        tokenDetails: route.map((item) => {
          const tokenAddress: string[] = [];
          const details = item.map((detail) => {
            tokenAddress.push(detail.toTokenAddress);
            return {
              title: detail.name,
              percentage: detail.part.toString(),
            };
          });
          return {
            token: tokenMap?.[`${getAddress(tokenAddress[0])}-false`] ?? {
              ticker: "Unknown",
              Icon: <TokenIcon alt="Unknown" />,
            },
            details,
          };
        }),
      })) ?? [];

    const api = {
      solver: data.solver,
      ...getApi(data.solver, amm, networkId),
    };
    return {
      priceImpact: bigDecimal.multiply(data.priceImpact, 100),
      finalAmount0: data.amount0,
      finalAmount1: data.amount1,
      api,
      fromToken: { ticker: fromToken.ticker, Icon: fromToken.Icon },
      swapData: [
        {
          to: { ticker: toToken.ticker, Icon: toToken.Icon },
          amountIn: formatUnits(
            BigInt(data.swapPath.amountIn),
            fromToken.decimals
          ),
          amountOut: formatUnits(
            BigInt(data.swapPath.amountOut),
            toToken.decimals
          ),
          minAmountOut: formatUnits(
            BigInt(data.swapPath.minAmountOut),
            toToken.decimals
          ),
        },
      ],
      swapDataHex: data.swapData,
      swapRoutes: routes,
      liquidity: data.liquidity,
      feeBips: data.feeBips,
      feeUSD: data.feeUSD,
      gasFeeUSD: data.gasFeeEstimation
        ? bigDecimal.multiply(
            nativeCurrency.price,
            formatUnits(data.gasFeeEstimation, nativeCurrency.decimals)
          )
        : "-",
      token0FeeAmount: data.token0FeeAmount,
      token1FeeAmount: data.token1FeeAmount,
    };
  });
};

export const getRebalanceSwapData = async (
  amm: AmmEnum,
  networkId: ApertureSupportedChainId,
  walletAddress: Address,
  positionId: bigint,
  tickLower: number,
  tickUpper: number,
  slippage: number,
  tokenPrices: [string, string],
  tokenMap: ITokenMap,
  client: PublicClient,
  solvers: E_Solver[]
) => {
  try {
    const rebalanceSwapInfo = await viem.getRebalanceSwapInfo(
      networkId,
      amm,
      walletAddress,
      positionId,
      tickLower,
      tickUpper,
      slippage,
      tokenPrices,
      client,
      solvers
    );
    return getFormattedSwapInfo(amm, networkId, tokenMap, rebalanceSwapInfo);
  } catch (error) {
    getLogger().error("Failed to get RebalanceSwapInfo: ", {
      error: JSON.stringify(error),
      tokenPrices,
      amm,
      positionId: positionId.toString(),
    });
    throw error;
  }
};

export const InsufficentTokensForZapIn =
  "Insufficient pool token, change token amount to retry.";

export const ErrorMsg = {
  AtStake: "Unstake position to view swap info.",
  InsufficientSlippage: "Insufficient slippage.",
  InsufficientPoolToken:
    "Insufficient pool token, change token amount to retry.",
  InsufficientAmount: "Amount to be zapped-in is too small.",
};

export const getSwapFailureMessage = (
  swapInfo,
  swapInfoError,
  amountUSD: string,
  atStake?: boolean
) => {
  if (atStake) {
    return ErrorMsg.AtStake;
  }
  if (
    !swapInfo &&
    swapInfoError &&
    swapInfoError.toString().includes("Price slippage check")
  ) {
    return ErrorMsg.InsufficientSlippage;
  }
  if (!swapInfo || swapInfo.length === 0) {
    return bigDecimal.compareTo(amountUSD, "0.01") < 1
      ? ErrorMsg.InsufficientAmount
      : ErrorMsg.InsufficientPoolToken;
  }
};

function generateIncreaseOptions(
  chainId: number,
  positionId: string,
  pool: Pool,
  slippage: number,
  deadline: number,
  amounts: [string, string],
  useNative?: boolean
) {
  const nativeCurrency = viem.getNativeCurrency(chainId);
  const amount0 = viem.getCurrencyAmount(
    useNative && pool.token0.address === nativeCurrency.wrapped.address
      ? nativeCurrency
      : pool.token0,
    bigDecimal.round(
      amounts[0],
      pool.token0.decimals,
      bigDecimal.RoundingModes.DOWN
    )
  );
  const amount1 = viem.getCurrencyAmount(
    useNative && pool.token1.address === nativeCurrency.wrapped.address
      ? nativeCurrency
      : pool.token1,
    bigDecimal.round(
      amounts[1],
      pool.token1.decimals,
      bigDecimal.RoundingModes.DOWN
    )
  );
  const increaseLiquidityOptions: IncreaseOptions = {
    slippageTolerance: numberToPercent(slippage),
    deadline,
    tokenId: positionId,
    useNative: useNative ? nativeCurrency : undefined,
  };
  return { amount0, amount1, increaseLiquidityOptions };
}

export async function getIncreaseLiquidityOptimalSwapInfo(
  amm: AmmEnum,
  networkId: ApertureSupportedChainId,
  walletAddress: Address,
  positionId: string,
  pool: Pool,
  slippage: number,
  deadline: number,
  amounts: [string, string],
  tokenMap: ITokenMap,
  client: PublicClient,
  solvers: E_Solver[]
) {
  try {
    const { amount0, amount1, increaseLiquidityOptions } =
      generateIncreaseOptions(
        networkId,
        positionId,
        pool,
        slippage,
        Math.floor(Date.now() / 1000 + deadline * 60),
        amounts,
        false
      );
    const price0 = tokenMap?.[`${pool.token0.address}-false`]?.price;
    const price1 = tokenMap?.[`${pool.token1.address}-false`]?.price;

    const increaseLiquiditySwapInfo = await viem
      .getIncreaseLiquidityOptimalSwapInfoV3(
        increaseLiquidityOptions,
        networkId,
        amm,
        amount0,
        amount1,
        walletAddress,
        [price0, price1],
        client,
        solvers
      )
      .catch((error) => {
        getLogger().error(
          "AddLPV2.getIncreaseLiquidityOptimalSwapInfoV3Failed: ",
          {
            amm,
            networkId,
            walletAddress,
            positionId,
            pool,
            slippage,
            deadline,
            amounts,
            error: JSON.stringify(error),
          }
        );
        throw error;
      });

    return getFormattedSwapInfo(
      amm,
      networkId,
      tokenMap,
      increaseLiquiditySwapInfo
    );
  } catch (error) {
    getLogger().error("AddLP.getIncreaseLiquidityOptimalSwapInfoFailed: ", {
      amm,
      networkId,
      walletAddress,
      positionId,
      pool,
      slippage,
      deadline,
      amounts,
      error: JSON.stringify(error),
    });
    throw error;
  }
}

export async function getIncreaseLiquidityOptimalTx(
  amm: AmmEnum,
  chainId: number,
  walletAddress: Address,
  positionId: string,
  pool: Pool,
  slippage: number,
  deadline: number,
  amounts: [string, string],
  swapData: Address,
  liquidity: bigint,
  client: PublicClient,
  token0FeeAmount?: bigint,
  token1FeeAmount?: bigint,
  useNative?: boolean
) {
  const { amount0, amount1, increaseLiquidityOptions } =
    generateIncreaseOptions(
      chainId,
      positionId,
      pool,
      slippage,
      Math.floor(Date.now() / 1000 + deadline * 60),
      amounts,
      useNative
    );
  return viem.getIncreaseLiquidityOptimalV3Tx(
    increaseLiquidityOptions,
    chainId,
    amm,
    amount0,
    amount1,
    walletAddress,
    client,
    swapData,
    liquidity,
    undefined,
    token0FeeAmount,
    token1FeeAmount
  );
}

export const getPositionAnalytics = (
  details: IPositionDetails,
  analyticsData: AnalyticPosition
) => {
  const filterTypes: ActivityType[] = ["Rebalance", "Reinvest"];

  const activityLogs: IActivityLog[] = analyticsData?.activityLogs?.reduce(
    (acc, log, idx) => {
      const nextLog =
        idx < analyticsData.activityLogs.length - 1
          ? analyticsData.activityLogs[idx + 1]
          : undefined;
      if (log.type === "Deposit") {
        if (!filterTypes.includes(nextLog?.type)) acc.push(log);
      } else {
        acc.push(log);
      }
      return acc;
    },
    []
  );

  const data: IPositionAnalytics = {
    ...{
      ...analyticsData,
      activityLogs,
    },
    tokens: [
      { ticker: details.tokenA.ticker, decimals: details.tokenA.decimals },
      { ticker: details.tokenB.ticker, decimals: details.tokenB.decimals },
    ],
    unit: " USD",
    setLogsShown: (show: boolean) => {
      getLogger().info("PositionAnalytics.toggleActivityLogs", {
        positionId: details.positionId,
        show,
      });
    },
  };
  return data;
};

export const getClosedPositions = (
  currentPosition: IPositionDetails,
  latestPosition: IPositionDetails,
  positionRebalanceHistory: viem.AnalyticPositionSubgraphData[],
  tokenPriceList: ITokenPriceMap,
  networkId: ApertureSupportedChainId,
  handleGoToPositionDetail?: (pid: string) => void
): IClosedPositions => {
  const tokens: ITokenTickerIconPair = [
    {
      ticker: currentPosition.tokenA.ticker,
      Icon: currentPosition.tokenA.Icon,
    },
    {
      ticker: currentPosition.tokenB.ticker,
      Icon: currentPosition.tokenB.Icon,
    },
  ];
  const isCurrentChain = currentPosition.chainId === networkId;
  const supportedChainId = getNetworkName(networkId);

  const { token0, token1 } = getTokensFromInfo(
    currentPosition.tokenA,
    currentPosition.tokenB,
    currentPosition.chainId
  );

  const list = positionRebalanceHistory
    ?.map((item) => {
      const native = viem.getNativeCurrency(currentPosition.chainId);
      const parsedData = parseAnalyticPositionData(
        item,
        latestPosition ?? currentPosition,
        native.decimals,
        tokenPriceList?.[native.wrapped.address]?.price,
        tokenPriceList?.[currentPosition.tokenA.address]?.price,
        tokenPriceList?.[currentPosition.tokenB.address]?.price
      );
      const minPrice = tickToPrice(token0, token1, item.tickLower).toFixed(18);
      const maxPrice = tickToPrice(token0, token1, item.tickUpper).toFixed(18);
      const positionId = item.tokenId.toString();

      const isCurrentPosition = positionId === currentPosition.positionId;
      const isActive = !parsedData.closedTimestamp;

      return {
        tokens,
        isCurrentChain,
        supportedChainId,
        positionId,
        closedTimestamp: parsedData.closedTimestamp,
        closedLiquidityUSD: parsedData.closedLiquidityUSD,
        closedAccruedFeesUSD: parsedData.closedAccruedFeesUSD,
        closedPriceRange: getFormattedPriceRange(minPrice, maxPrice),
        closedMarketPrice: parsedData.closedMarketPrice,
        closedTotalAPR: parsedData.totalAPR,
        closedFeeAPR: parsedData.feeAPR,
        onClick:
          !!handleGoToPositionDetail && isActive && !isCurrentPosition
            ? () => {
                handleGoToPositionDetail(
                  `${currentPosition.chainId}-${positionId}`
                );
                getLogger().info(
                  "PositionAnalytics.ClosedPositions.GoToPositionDetail",
                  {
                    positionId: `${currentPosition.chainId}-${positionId}`,
                  }
                );
              }
            : undefined,
      } as IClosedPositionCardProps;
    })
    .reverse();

  return {
    tokens,
    supportedChainId,
    isCurrentChain,
    list: list ?? [],
    onToggleShow: (show: boolean) => {
      getLogger().info("PositionAnalytics.ClosedPositions.onToggleShow", {
        show,
      });
    },
  };
};

export const getPositionOverview = (
  details: IPositionDetails,
  tokenPriceList: ITokenPriceMap
): Omit<IPositionOverview, "nftURL" | "isNFTLoading"> => {
  const { tokenA, tokenB, liquidity, feeAmount } = details;
  return {
    tokens: [tokenA, tokenB],
    liquidityInUSD: liquidity,
    collectableInUSD: feeAmount,
    tokenPrices: [
      tokenPriceList?.[tokenA.address]?.price || "0",
      tokenPriceList?.[tokenB.address]?.price || "0",
    ],
  };
};

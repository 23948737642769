import { useURLContext } from "@/components/Context/URLContext";
import { useNetwork } from "@/components/NetworkContext/NetworkContext";
import { useEventCallback } from "@/hooks/useEventCallback";
import { useFetchSlipstreamPools } from "@/hooks/useFetchSlipstreamPools";
import { ITokenMap, useFetchTokenMap } from "@/hooks/useFetchTokenMap";
import { useOnFirstLoad } from "@/hooks/useOnFirstLoad";
import {
  CommonQueryKey,
  OpenPositionQueryKey as QueryKey,
} from "@/utils/browserHistory";
import { checkAddresses } from "@/utils/helper";
import { IToken } from "@aperture/types";
import { Calculator_AMM, SupportedChainId } from "@aperture/uikitv2";
import {
  ApertureSupportedChainId,
  parsePrice,
  priceToClosestTickSafe,
  viem,
} from "@aperture_finance/uniswap-v3-automation-sdk";
import {
  FeeAmount,
  Pool,
  TickMath,
  encodeSqrtRatioX96,
} from "@aperture_finance/uniswap-v3-sdk";
import { Token } from "@uniswap/sdk-core";
import { useDebounce } from "ahooks";
import bigDecimal from "js-big-decimal";
import JSBI from "jsbi";
import { useSearchParams } from "next/navigation";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export const useCachedFetchTokenMap = (
  networkId?: ApertureSupportedChainId
) => {
  const res = useFetchTokenMap(networkId);
  const { data } = res;
  const [cachedTokenMap, setCachedTokenMap] = useState<ITokenMap | null>(null);

  if (data !== cachedTokenMap && data) {
    setCachedTokenMap(data);
  }

  const commonTokens = useMemo(
    () =>
      Object.values(cachedTokenMap ?? {}).sort((a, b) =>
        bigDecimal.compareTo(b.amount, a.amount)
      ),
    [cachedTokenMap]
  );

  return {
    ...res,
    data: cachedTokenMap,
    commonTokens,
  };
};

export const useUrlQuery = (tokenMap: ITokenMap) => {
  const searchParams = useSearchParams();
  const { updateUrlQuery } = useURLContext();
  const [isUrlUpdated, setIsUrlUpdated] = useState(false);

  const [calcDex, setCalcDex] = useUrlState<Calculator_AMM>(QueryKey.CALC_DEX);
  const [calcNetwork, setCalcNetwork] = useUrlState<SupportedChainId>(
    QueryKey.CALC_NETWORK
  );

  const tokenA = searchParams.get(QueryKey.TOKEN_A);
  const selectedTokenA = useMemo(() => {
    return tokenMap && tokenMap[tokenA];
  }, [tokenA, tokenMap]);

  const tokenB = searchParams.get(QueryKey.TOKEN_B);
  const selectedTokenB = useMemo(() => {
    return tokenMap && tokenMap[tokenB];
  }, [tokenB, tokenMap]);

  const [feeTier, setFeeTier] = useUrlState(QueryKey.FEE_TIER, Number);
  const [tickLower, setTickLowerUrl] = useUrlState(QueryKey.TICK_LOWER, Number);
  const [tickUpper, setTickUpperUrl] = useUrlState(QueryKey.TICK_UPPER, Number);
  const [zapIn, setZapIn] = useUrlState(QueryKey.ZAP_IN, (v) => v === "true");

  const setFeeTierUrl = useCallback(
    (feeTier: FeeAmount) => {
      setFeeTier(feeTier);
      setIsUrlUpdated(true);
    },
    [setFeeTier]
  );
  const setZapInUrl = useCallback(
    (zapIn: boolean) => {
      setZapIn(zapIn);
      setIsUrlUpdated(true);
    },
    [setZapIn]
  );

  const setTickUrl = useCallback(
    (key: QueryKey.TICK_LOWER | QueryKey.TICK_UPPER, tick: number) => {
      if (key === QueryKey.TICK_LOWER) {
        setTickLowerUrl(tick);
      } else {
        setTickUpperUrl(tick);
      }
      setIsUrlUpdated(true);
    },
    [setTickLowerUrl, setTickUpperUrl]
  );

  const setSelectedToken = useCallback(
    (key: QueryKey.TOKEN_A | QueryKey.TOKEN_B, token: IToken | null) => {
      updateUrlQuery(key, token ? `${token?.address}-${token?.native}` : null);
      setIsUrlUpdated(true);
    },
    [updateUrlQuery]
  );

  const setCalcDexUrl = useCallback(
    (dex: Calculator_AMM) => {
      setCalcDex(dex);
      setIsUrlUpdated(true);
    },
    [setCalcDex]
  );
  const setCalcNetworkUrl = useCallback(
    (network: SupportedChainId) => {
      setCalcNetwork(network);
      setIsUrlUpdated(true);
    },
    [setCalcDex]
  );

  return {
    selectedTokenA,
    selectedTokenB,
    feeTier,
    tickLower,
    tickUpper,
    zapIn,
    calcDex,
    calcNetwork,
    isUrlUpdated,
    setSelectedToken,
    setFeeTierUrl,
    setTickUrl,
    setZapInUrl,
    setCalcDexUrl,
    setCalcNetworkUrl,
  };
};

export function useUrlState<T = string>(
  key: QueryKey | CommonQueryKey,
  converter?: (v: string) => T
): [T | undefined, (v: T) => void] {
  const _converter = converter ?? ((v: string) => v as T);
  const searchParams = useSearchParams();
  const queryValue = searchParams.has(key)
    ? _converter(searchParams.get(key))
    : undefined;
  const { updateUrlQuery } = useURLContext();

  const [local, setLocal] = useState<T | undefined>(queryValue);

  useOnFirstLoad(queryValue, () => {
    setLocal(queryValue);
  });

  const setLocalWithUpdate = useCallback(
    (v: T) => {
      setLocal(v);
      updateUrlQuery(key, v === undefined ? undefined : String(v));
    },
    [key, updateUrlQuery]
  );

  return [local, setLocalWithUpdate];
}

export function useStartingPrice(
  startPrice: string,
  selectedTokenList: { token0: Token; token1: Token },
  useInvert: boolean
) {
  const debouncedPrice = useDebounce(startPrice, { wait: 500 });
  const useInvertRef = useRef(useInvert);
  useInvertRef.current = useInvert;

  const { token0, token1 } = selectedTokenList ?? {};

  const startingPrice = useMemo(() => {
    const validPrice = bigDecimal.multiply(debouncedPrice, "1.0");
    if (
      !startPrice ||
      !token0 ||
      !token1 ||
      bigDecimal.compareTo(validPrice, 0) <= 0
    ) {
      return undefined;
    }

    try {
      const newPrice = parsePrice(
        useInvertRef.current ? token1 : token0,
        useInvertRef.current ? token0 : token1,
        validPrice
      );
      return useInvertRef.current ? newPrice.invert() : newPrice;
    } catch (error) {
      console.log("Failed to parsePrice", error);
      return undefined;
    }
  }, [debouncedPrice, token0, token1, startPrice]);

  // check for invalid price input (converts to invalid ratio)
  const invalidPrice = useMemo(() => {
    const sqrtRatioX96 = startingPrice
      ? encodeSqrtRatioX96(startingPrice.numerator, startingPrice.denominator)
      : undefined;
    return (
      startingPrice &&
      sqrtRatioX96 &&
      !(
        JSBI.greaterThanOrEqual(sqrtRatioX96, TickMath.MIN_SQRT_RATIO) &&
        JSBI.lessThan(sqrtRatioX96, TickMath.MAX_SQRT_RATIO)
      )
    );
  }, [startingPrice]);

  return {
    startingPrice,
    invalidPrice,
  };
}

export const useCurrentPool = ({
  token0,
  token1,
  poolKey,
  startPrice,
  baseToken,
  currentPoolStatus,
}: {
  token0: Token;
  token1: Token;
  poolKey: FeeAmount;
  startPrice: string;
  baseToken: IToken;
  currentPoolStatus: boolean;
}) => {
  const { networkId, ammEnum, publicClient } = useNetwork();
  const [currentPool, setCurrentPool] = useState<Pool | null>(null);
  const getCacheKey = () => {
    return `${networkId}-${ammEnum}-${token0?.address}-${token1?.address}-${poolKey}`;
  };

  const cacheKey = useRef<string>(null);

  const fetchPool = useEventCallback(() => {
    if (
      !token0 ||
      !token1 ||
      !poolKey ||
      !networkId ||
      !ammEnum ||
      !publicClient
    )
      return;

    if (cacheKey.current !== getCacheKey()) {
      cacheKey.current = getCacheKey();
      viem
        .getPool(token0, token1, poolKey, networkId, ammEnum, publicClient)
        .then((pool) => {
          setCurrentPool(pool);
        })
        .catch((error) => {
          console.log(
            `Failed to get pool with fee tier ${poolKey}:`,
            error.message
          );
        });
    }
  });

  useEffect(() => {
    if (token0 && token1 && poolKey) {
      const timerId = setTimeout(() => {
        fetchPool();
      }, 500);
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [token0, token1, poolKey, fetchPool]);

  const { startingPrice, invalidPrice } = useStartingPrice(
    startPrice,
    {
      token0,
      token1,
    },
    !checkAddresses(token0, baseToken)
  );

  // used for ratio calculation when pool not initialized
  const initPool = useMemo(() => {
    if (token0 && token1 && poolKey && startingPrice && !invalidPrice) {
      try {
        const currentTick = priceToClosestTickSafe(startingPrice);
        const currentSqrt = TickMath.getSqrtRatioAtTick(currentTick);
        const generatedPool = new Pool(
          token0,
          token1,
          poolKey,
          currentSqrt,
          JSBI.BigInt(0),
          currentTick,
          []
        );
        return generatedPool;
      } catch (error) {
        console.log("Failed to initialize Pool", error);
        return null;
      }
    } else {
      return null;
    }
  }, [poolKey, startingPrice, token0, token1, invalidPrice]);

  if (currentPoolStatus) {
    return currentPool;
  } else {
    return initPool;
  }
};

// Available Slipstream Pools
export const useAvailableASPools = (
  token0Address: string,
  token1Address: string
) => {
  const { data: slipStreamPools } = useFetchSlipstreamPools();
  return useMemo(
    () =>
      slipStreamPools
        ? slipStreamPools
            .filter(
              (pool) =>
                pool.token0 === token0Address && pool.token1 === token1Address
            )
            .sort((a, b) => a.fee - b.fee)
        : [],
    [slipStreamPools, token0Address, token1Address]
  );
};

import { CaretIcon } from "@aperture/assetkit";
import { Flex, FlexColumn } from "@ui/common";
import styled, { useTheme } from "styled-components";
import { Headline5 } from "../../Typography";
import { StakePeriod, StakePeriodProps } from "../types";

const Title = styled(Headline5)`
  font-family: "Dunbar Tall";
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const Item = styled(Flex)<{ selected: boolean }>`
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.lg};
  border-radius: ${({ theme }) => theme.radii.md2};
  background-color: ${({ theme, selected }) =>
    theme.colors.global.background[selected ? "BG1" : "BG2"]};
`;
const Text1 = styled.div`
  font-family: "Roboto Flex";
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const Text2 = styled(Text1)<{ highlight?: boolean }>`
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme, highlight }) =>
    highlight ? theme.colors.global.success : theme.colors.global.text.T1};
`;
const Container = styled(Flex)`
  cursor: pointer;
  width: 276px;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.xl};
  border-radius: ${({ theme }) => theme.radii.md2};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
`;

export const SelectedStakePeriod = ({ days, APR, onClick }: StakePeriod) => {
  const theme = useTheme();
  return (
    <Container onClick={onClick}>
      <Text1>{days} Days</Text1>
      <Flex gap="sm" alignItems="center">
        <Flex gap="md">
          <Text2>APR</Text2>
          {APR.map(({ percent, unit }) => (
            <Flex key={`${percent}-${unit}`} gap="sm">
              <Text2 highlight>{percent}%</Text2>
              <Text2>{unit}</Text2>
            </Flex>
          ))}
        </Flex>
        <CaretIcon width={16} height={16} fill={theme.colors.global.text.T1} />
      </Flex>
    </Container>
  );
};

export const StakePeriodContent: React.FC<StakePeriodProps> = ({ options }) => {
  return (
    <FlexColumn gap="2xl" style={{ width: "372px" }}>
      <Title>Select Stake Period</Title>
      <FlexColumn gap="md">
        {options.map(({ days, APR, selected, onClick }, idx) => (
          <Item
            gap="md"
            selected={!!selected}
            onClick={onClick}
            alignItems="center"
            justifyContent="space-between"
            key={`${idx}-${days}`}
          >
            <Text1>{days} Days</Text1>
            <Flex gap="md">
              <Text2>APR</Text2>
              {APR.map(({ percent, unit }) => (
                <Flex gap="sm">
                  <Text2 highlight>{percent}%</Text2>
                  <Text2>{unit}</Text2>
                </Flex>
              ))}
            </Flex>
          </Item>
        ))}
      </FlexColumn>
    </FlexColumn>
  );
};

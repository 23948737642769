import React from "react";
import { DRAWER_WIDTH, DrawerContainer, DrawerWrapper } from "./style";
import { DrawerProps } from "./types";

export const DRAWER_CONTENT_WIDTH = "605px";
export const DrawerOpenCardWidth = "395px";

export const DrawerComponent: React.FC<
  React.PropsWithChildren<DrawerProps>
> = ({
  children,
  direction = "right",
  height,
  isOpen = true,
  width = DRAWER_WIDTH,
  ...props
}) => {
  // const drawRef = useRef<HTMLDivElement | null>(null);
  // useEffect(() => {
  //   if (!drawRef?.current) return;
  //   const el = drawRef.current;
  //   const handleClick = (e: MouseEvent) => {
  //     if (el !== e.target) return;
  //     e.stopPropagation();
  //   };
  //   el.addEventListener('click', handleClick);
  //   return () => {
  //     el.removeEventListener('click', handleClick);
  //   };
  // }, [drawRef]);

  return (
    <DrawerWrapper {...props}>
      <DrawerContainer
        // ref={drawRef}
        direction={direction}
        height={height}
        isOpen={isOpen}
        width={width}
      >
        {children}
      </DrawerContainer>
    </DrawerWrapper>
  );
};

import React from "react";
import styled from "styled-components";
import { compose, layout, space, typography } from "styled-system";
import { UITypographyProps } from "./types";

const StyledBody1 = styled.div<UITypographyProps>`
  font-size: 14px;
  ${compose(layout, space, typography)}
`;
export const Body1: React.FC<React.PropsWithChildren<UITypographyProps>> = ({
  children,
  ...props
}) => <StyledBody1 {...props}>{children}</StyledBody1>;

const StyledBody2 = styled.div<UITypographyProps>`
  font-size: 12px;
  ${compose(layout, space, typography)}
`;
export const Body2: React.FC<React.PropsWithChildren<UITypographyProps>> = ({
  children,
  ...props
}) => <StyledBody2 {...props}>{children}</StyledBody2>;

const StyledBody3 = styled.div<UITypographyProps>`
  font-size: 10px;
  ${compose(layout, space, typography)}
`;
export const Body3: React.FC<React.PropsWithChildren<UITypographyProps>> = ({
  children,
  ...props
}) => <StyledBody3 {...props}>{children}</StyledBody3>;

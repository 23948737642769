import { ReactNode } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  height: 30px;
  padding: 0px 10px;
  display: inline-flex;
  flex-grow: 0;
  align-items: center;
  justify-content: center;

  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;

  &:before {
    position: absolute;
    content: "";
    border-radius: ${({ theme }) => theme.radii.round};
    border: 1px solid ${({ theme }) => theme.colors.transparent.transparent};
    inset: 0;
    /**
     * @dev 
     * - in this section, we use background to create a gradient background
     * - there's two masks in -webkit-mask, we use that with the composite property
     * to create a cut out on the solid background to make it transparent
     * - the -web-mask-composite and mask-composite serves the same purpose
     * and is used for cross browser compatibility
     */
    background: ${({ theme }) => theme.gradients.primary.medium} border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;

const Button = styled.div`
  background: ${({ theme }) => theme.gradients.primary.medium};
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(255, 255, 255, 0.1);
  white-space: nowrap;
`;

export const ComingSoonButton = (props: { children: ReactNode }) => {
  return (
    <Wrapper>
      <Button>
        <span>{props.children}</span>
      </Button>
    </Wrapper>
  );
};

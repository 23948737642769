import styled from "styled-components";
import { FlexboxProps } from "styled-system";
import { Flex, FlexColumn } from "../../common/styles/global";
import { Body1, Subtitle3 } from "../Typography";

export const PastPositionStatus = styled(FlexColumn)<
  FlexboxProps & {
    isLast?: boolean;
  }
>`
  position: relative;
  height: fit-content;
  padding: 16px;
  width: 140px;
  box-sizing: border-box;
  gap: ${({ theme }) => theme.spacing.sm};
  border-radius: ${({ theme }) => theme.radii.md};
  background-color: ${({ theme }) => theme.colors.global.background.BG3};
  position: relative;
`;
export const StatusText = styled(Body1)<{ color: string }>`
  line-height: 16px;
  color: ${({ theme, color }) => {
    if (color === "first") {
      return theme.colors.global.text.T1;
    }
    if (color === "second") {
      return theme.colors.global.text.T2;
    }
    if (color === "third") {
      return theme.colors.global.success;
    }
  }};
`;

export const Label = styled(Subtitle3)`
  color: ${({ theme }) => theme.colors.global.text.T2};
`;

export const Container = styled(FlexColumn)`
  width: 640px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.global.text.T1};
  border-radius: ${({ theme }) => theme.radii.md};
  background-color: ${({ theme }) => theme.colors.global.background.BG2};
`;

export const PositionInfoItem = styled(FlexColumn)`
  min-width: 154px;
  gap: ${({ theme }) => theme.spacing.sm};
`;

export const DividerHorizontal = styled.div`
  width: 100%;
  height: 1px;
  margin: 24px 0;
  background-color: ${({ theme }) => theme.colors.global.line.Line2};
`;

export const StyledFlex = styled(Flex)`
  position: relative;
`;

export const Line = styled.div`
  position: absolute;
  left: 10%;
  width: 1px;
  height: 100%;
  ::after {
    position: absolute;
    top: 0;
    bottom: 0;
    content: "";
    width: 1px;
    height: 100%;
    // dot line
    background-image: linear-gradient(
      to bottom,
      ${({ theme }) => theme.colors.global.line.Line2} 50%,
      transparent 50%
    );
    background-size: 100% 6px;
  }
`;

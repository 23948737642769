"use client";

// https://github.com/wevm/wagmi/blob/b99d8da96ce89ec95f51baeb8f08176e0e2b99f4/packages/react/src/hooks/useWaitForTransactionReceipt.ts
// https://github.com/wevm/wagmi/blob/7a95b5418707ab38415d2eabab5bb12faabf2ad1/packages/react/src/utils/query.ts
import { getTxHashFromSafeTxHash } from "@/helper/transactionHelper";
import { type QueryOptions } from "@tanstack/query-core";
import {
  useQuery as tanstack_useQuery,
  type DefaultError,
  type QueryKey,
  type UseQueryOptions,
  type UseQueryResult,
} from "@tanstack/react-query";
import {
  waitForTransactionReceipt,
  type Config,
  type ResolvedRegister,
  type WaitForTransactionReceiptErrorType,
  type WaitForTransactionReceiptReturnType,
} from "@wagmi/core";
import {
  type Evaluate,
  type ExactPartial,
  type Omit,
} from "@wagmi/core/internal";
import {
  hashFn,
  waitForTransactionReceiptQueryKey,
  type WaitForTransactionReceiptData,
  type WaitForTransactionReceiptOptions,
  type WaitForTransactionReceiptQueryFnData,
  type WaitForTransactionReceiptQueryKey,
} from "@wagmi/core/query";
import {
  UseWaitForTransactionReceiptParameters,
  UseWaitForTransactionReceiptReturnType,
  useChainId,
  useConfig,
} from "wagmi";
import { useIsWalletContract } from "./useIsWalletContract";

type UseQueryParameters<
  queryFnData = unknown,
  error = DefaultError,
  data = queryFnData,
  queryKey extends QueryKey = QueryKey
> = Evaluate<
  ExactPartial<
    Omit<UseQueryOptions<queryFnData, error, data, queryKey>, "initialData">
  > & {
    // Fix `initialData` type
    initialData?:
      | UseQueryOptions<queryFnData, error, data, queryKey>["initialData"]
      | undefined;
  }
>;

function useQuery<queryFnData, error, data, queryKey extends QueryKey>(
  parameters: UseQueryParameters<queryFnData, error, data, queryKey> & {
    queryKey: QueryKey;
  }
): UseQueryReturnType<data, error> {
  const result = tanstack_useQuery({
    ...(parameters as any),
    queryKeyHashFn: hashFn, // for bigint support
  }) as UseQueryReturnType<data, error>;
  result.queryKey = parameters.queryKey;
  return result;
}

type UseQueryReturnType<data = unknown, error = DefaultError> = Evaluate<
  UseQueryResult<data, error> & {
    queryKey: QueryKey;
  }
>;

/** https://wagmi.sh/react/api/hooks/useWaitForTransactionReceipt */
export function useWaitForTransaction<
  config extends Config = ResolvedRegister["config"],
  chainId extends config["chains"][number]["id"] = config["chains"][number]["id"],
  selectData = WaitForTransactionReceiptData<config, chainId>
>(
  parameters: UseWaitForTransactionReceiptParameters<
    config,
    chainId,
    selectData
  > = {}
): UseWaitForTransactionReceiptReturnType<config, chainId, selectData> {
  const { hash, query = {} } = parameters;

  const config = useConfig(parameters);
  const chainId = useChainId({ config });
  const { data: isWalletContract } = useIsWalletContract();

  const options = waitForTransactionReceiptQueryOptions(
    config,
    {
      ...parameters,
      chainId: parameters.chainId ?? chainId,
    },
    isWalletContract ?? false
  );
  const enabled = Boolean(hash && (query.enabled ?? true));

  return useQuery({
    ...(query as any),
    ...options,
    enabled,
  }) as UseWaitForTransactionReceiptReturnType<config, chainId, selectData>;
}

function waitForTransactionReceiptQueryOptions<
  config extends Config,
  chainId extends config["chains"][number]["id"]
>(
  config: config,
  options: WaitForTransactionReceiptOptions<config, chainId> = {},
  isWalletContract?: boolean
) {
  return {
    async queryFn({ queryKey }) {
      const { hash, chainId, ...parameters } = queryKey[1];
      if (!hash) throw new Error("hash is required");
      let newHash = hash;
      if (isWalletContract)
        newHash = await getTxHashFromSafeTxHash(chainId, hash);
      if (!newHash) throw new Error("hash is required");
      return waitForTransactionReceipt(config, {
        ...parameters,
        onReplaced: options.onReplaced,
        hash: newHash,
      }) as unknown as Promise<
        WaitForTransactionReceiptReturnType<config, chainId>
      >;
    },
    queryKey: waitForTransactionReceiptQueryKey(options),
  } as const satisfies QueryOptions<
    WaitForTransactionReceiptQueryFnData<config, chainId>,
    WaitForTransactionReceiptErrorType,
    WaitForTransactionReceiptData<config, chainId>,
    WaitForTransactionReceiptQueryKey<config, chainId>
  >;
}

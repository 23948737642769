import { useEffect, useState } from "react";
import { IPercentageInput } from "./types";

export const useInput = ({
  amount,
  percentage,
  onChange,
  onError,
}: IPercentageInput) => {
  const [error, setError] = useState<boolean>(
    onError ? !!onError(percentage) : false
  );

  const handleChange = (percentage: number) => {
    onChange(percentage);
    setError(!!onError?.(percentage));
  };
  useEffect(() => {
    setError(!!onError?.(percentage));
  }, [amount, percentage]);

  return { error, handleChange };
};

import { useTheme } from "@/Providers";
import { getExplorerURL } from "@/config/chain";
import { openSnackBar } from "@aperture/uikit";
import React, { createContext, useContext } from "react";
import { Address } from "viem";
import { useNetwork } from "../NetworkContext/NetworkContext";

type Transaction = {
  hash?: Address;
  successMsg?: string;
  failureMsg?: string;
};

export type IAddSnackbarProps = {
  info: string;
  status: "completed" | "failed";
  url?: string;
  transaction?: Transaction;
  onTxSuccess?: () => void;
  onTxFailure?: () => void;
};

type ISnackbarContext = {
  addSnackbar: (props: IAddSnackbarProps) => void;
};

type ISnackbarProvider = {
  children: React.ReactNode;
};

const SnackbarContext = createContext<ISnackbarContext | undefined>(undefined);

export const useSnackbarV2 = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};

export const SnackbarV2Provider: React.FC<ISnackbarProvider> = ({
  children,
}) => {
  const { networkId } = useNetwork();
  const { theme } = useTheme();
  const { publicClient } = useNetwork();

  const addSnackbar = (props: IAddSnackbarProps) => {
    const { info, status, url, transaction, onTxSuccess, onTxFailure } = props;
    openSnackBar({ info, status, url, currentTheme: theme });

    if (transaction) {
      const { hash, successMsg, failureMsg } = transaction;
      const url = `${getExplorerURL(networkId)}/tx/${hash}`;

      return publicClient
        .waitForTransactionReceipt({
          hash,
        })
        .then((data) => {
          if (data?.status === "success") {
            addSnackbar({
              info: successMsg ?? "Your transaction is completed.",
              status: "completed",
              url,
            });

            onTxSuccess?.();
          }
        })
        .catch((error) => {
          console.warn("tx failed", error);
          addSnackbar({
            info: failureMsg ?? "Your transaction has failed.",
            status: "failed",
            url,
          });

          onTxFailure?.();
        });
    }
  };

  return (
    <SnackbarContext.Provider value={{ addSnackbar }}>
      {children}
      <div id="snackbarOuterContainer" />
    </SnackbarContext.Provider>
  );
};

import bigDecimal from "js-big-decimal";
import React from "react";
import { formatTokenAmount, formatUSDAmount } from "../../../utils";
import { Subtitle2 } from "../../Typography";
import { TokenItemText, TokenLogo, TokenRow, TokenValue } from "./style";
import { IPortfolioToken } from "./types";

export const PortfolioToken: React.FC<{ token: IPortfolioToken }> = ({
  token,
}) => {
  return (
    <TokenRow>
      <TokenLogo>{token.logo}</TokenLogo>
      <TokenItemText>
        <Subtitle2>{token.name}</Subtitle2>
        <Subtitle2 fontWeight={400} lineHeight={"normal"}>{`${formatTokenAmount(
          bigDecimal.multiply(token.quantity ?? 0, "1.0")
        )} ${token.symbol}`}</Subtitle2>
      </TokenItemText>
      {token.denominatedValue && (
        <TokenValue>
          {token.price === ""
            ? "$-"
            : formatUSDAmount(
                bigDecimal.multiply(token.denominatedValue ?? 0, "1.0")
              )}
        </TokenValue>
      )}
    </TokenRow>
  );
};

import { Flex } from "@aperture/uikitv2";
import { useMemo } from "react";
import styled from "styled-components";
import { T2 } from "../../Typography";

const StyledT2 = styled(T2)`
  color: ${({ theme }) => theme.colors.global.text.T3};
`;
const Text = styled(T2)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const Count = styled.span`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const PageBtn = styled.div<{ disabled?: boolean; isCurrent?: boolean }>`
  cursor: pointer;
  padding: 6px 12px;
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
  color: ${({ theme }) => theme.colors.global.text.T1};
  background-color: ${({ theme, isCurrent }) =>
    isCurrent && theme.colors.global.background.BG3};
  opacity: ${({ disabled }) => disabled && 0.5};
  :hover {
    background-color: ${({ theme }) => theme.colors.global.background.BG3};
  }
`;
const PageTextBtn = styled(PageBtn)<{ isPrev?: boolean }>`
  border-radius: ${({ isPrev }) =>
    isPrev ? "10px 0 0px 10px" : "0px 10px 10px 0px"};
  border-right: ${({ isPrev }) => isPrev && "0px"};
  border-left: ${({ isPrev }) => !isPrev && "0px"};
`;
const Container = styled(Flex)`
  padding: ${({ theme }) => theme.spacing.xl};
  align-items: center;
  justify-content: space-between;
`;

interface IPagination {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  siblingCount?: number;
}
interface IProps extends IPagination {
  onPageChange: (page: number) => void;
}

const getRange = (start: number, end: number) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

const DOTS = 0;

const usePagination = ({
  totalCount,
  pageSize,
  currentPage,
  siblingCount = 1,
}: IPagination) => {
  return useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);
    const totalPageNumbers = siblingCount * 2 + 3;

    if (totalPageNumbers >= totalPageCount) {
      return getRange(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    const showLeftDots = leftSiblingIndex > 2;
    const showRightDots = rightSiblingIndex < totalPageCount - 2;
    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    const itemCount = 2 + 2 * siblingCount;

    if (!showLeftDots && showRightDots) {
      let leftRange = getRange(1, itemCount);
      return [...leftRange, DOTS, lastPageIndex];
    }

    if (showLeftDots && !showRightDots) {
      let rightRange = getRange(totalPageCount - itemCount + 1, totalPageCount);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (showLeftDots && showRightDots) {
      let middleRange = getRange(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);
};

const Pagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage,
  onPageChange,
}: IProps) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || !paginationRange) {
    return;
  }

  const lastPage = paginationRange?.[paginationRange?.length - 1];
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === lastPage;

  const handleNextClick = () => {
    !isLastPage && onPageChange(currentPage + 1);
  };
  const handlePrevClick = () => {
    !isFirstPage && onPageChange(currentPage - 1);
  };

  return (
    <Container>
      <StyledT2>
        Showing{" "}
        <Count>{`${(currentPage - 1) * pageSize + 1}-${
          currentPage * pageSize
        }`}</Count>{" "}
        of <Count>{totalCount}</Count>
      </StyledT2>
      <Flex>
        <PageTextBtn
          onClick={handlePrevClick}
          isPrev={true}
          disabled={isFirstPage}
        >
          <Text>Previous</Text>
        </PageTextBtn>
        {paginationRange?.map((pageNumber, idx) => {
          if (pageNumber === 0) return;

          const rightDots =
            idx === 0 &&
            paginationRange[idx + 1] !== undefined &&
            paginationRange[idx + 1] === DOTS;
          const leftDots =
            idx === paginationRange.length - 1 &&
            paginationRange[idx - 1] === DOTS;
          return (
            <PageBtn
              key={pageNumber}
              onClick={() => onPageChange(pageNumber)}
              isCurrent={pageNumber === currentPage}
            >
              <Text>
                {`${leftDots ? ".." : ""}`}
                {pageNumber}
                {`${rightDots ? ".." : ""}`}
              </Text>
            </PageBtn>
          );
        })}
        <PageTextBtn onClick={handleNextClick} disabled={isLastPage}>
          <Text>Next</Text>
        </PageTextBtn>
      </Flex>
    </Container>
  );
};

export default Pagination;

import { ITokenTickerIconPair } from "@aperture/types";
import { CSSProperties, useState } from "react";
import { IPriceRange } from "../../common/types/Position";
import { Body1 } from "../Typography";
import { SwapBox, SwapButton, SwapButtonIcon } from "./style";

export const SwapRate: React.FC<{
  tokens: ITokenTickerIconPair;
  priceRange: IPriceRange;
  justifyContent?: CSSProperties["justifyContent"];
  iconColor?: CSSProperties["color"];
}> = ({ tokens, priceRange, justifyContent, iconColor }) => {
  const [swap, setSwap] = useState(false);
  return (
    <SwapBox justifyContent={justifyContent}>
      <Body1>
        {swap ? priceRange.swapMin : priceRange.min}{" "}
        {tokens[swap ? 0 : 1].ticker} per {tokens[swap ? 1 : 0].ticker}
      </Body1>
      <SwapButton
        onClick={(event) => {
          event.stopPropagation();
          setSwap(!swap);
        }}
        color={iconColor}
      >
        <SwapButtonIcon color={iconColor} />
      </SwapButton>
      <Body1>
        {swap ? priceRange.swapMax : priceRange.max}{" "}
        {tokens[swap ? 0 : 1].ticker} per {tokens[swap ? 1 : 0].ticker}
      </Body1>
    </SwapBox>
  );
};

import {
  ConfirmationIcon,
  LoadingCircle,
  LoadingIcon,
} from "@aperture/assetkit";
import React from "react";
import styled from "styled-components";
import { background } from "styled-system";
import { CircleSetting, SpinnerProps } from "./types";

const Container = styled.div<{ size: number }>`
  position: relative;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: ${({ theme }) => theme.radii.circle};
`;
const CircleLoader = styled.div`
  width: 100%;
  height: 100%;
  animation: spin 5s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
const SpinnerWrapper = styled.div`
  display: flex;
  width: max-content;
  height: max-content;
  -webkit-animation-name: spin;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 4000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
    }
  }

  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
const Circle = styled.div<{
  size: number;
  duration: number;
  setting: CircleSetting;
}>`
  --duration-time: ${({ duration }) => `${duration}s`};
  position: absolute;
  background: ${({ theme }) => theme.colors.global.text.T1};
  ${background}
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: ${({ theme }) => theme.radii.circle};
  transform: scale(0.4);
  animation: scale_anim var(--duration-time) ease-in-out infinite,
    fade-in-out var(--duration-time) linear infinite;
  animation-delay: ${({ setting }) => `${setting.delay}s`};
  top: ${({ setting }) => `${setting.top}px`};
  left: ${({ setting }) => `${setting.left}px`};

  @keyframes scale_anim {
    0% {
      transform: scale(0.8);
    }

    100% {
      transform: scale(0.4);
    }
  }

  @keyframes fade-in-out {
    0% {
      opacity: 0.8;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.8;
    }
  }
`;

export const Spinner: React.FC<SpinnerProps> = ({
  size = 20,
  duration = 1,
  partition = 9,
  ...props
}) => {
  const diameter = Math.round(
    (size * Math.tan(Math.PI / partition)) / (1 + Math.tan(Math.PI / partition))
  );
  return (
    <Container size={size}>
      <CircleLoader>
        {getCircleStyle(size / 2, duration, partition).map((item, index) => (
          <Circle
            key={`circular_${index}`}
            setting={item}
            size={diameter}
            duration={duration}
            {...props}
          />
        ))}
      </CircleLoader>
    </Container>
  );
};

function getCircleStyle(r: number, duration: number, partition: number) {
  const itemStyle = [];
  const slice = Math.PI / partition;
  const radius = r * (1 - Math.tan(slice) / (1 + Math.tan(slice)));
  const centerX = radius;
  const centerY = radius;

  for (let i = 0; i < partition; i++) {
    const angle = 2 * slice * i;
    const x = centerX + radius * Math.cos(angle);
    const y = centerY + radius * Math.sin(angle);

    itemStyle.push({
      top: Math.round(y),
      left: Math.round(x),
      delay: (i * duration) / partition,
    } as CircleSetting);
  }

  return itemStyle;
}

export const LoadingSpinner: React.FC<React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  return <SpinnerWrapper {...props}>{children}</SpinnerWrapper>;
};

export const ConfirmationSpinner: React.FC<React.SVGProps<SVGSVGElement>> = ({
  ...props
}) => (
  <LoadingSpinner>
    <ConfirmationIcon {...props} />
  </LoadingSpinner>
);

export const RingSpinner: React.FC<React.SVGProps<SVGSVGElement>> = ({
  ...props
}) => (
  <LoadingSpinner>
    <LoadingIcon {...props} />
  </LoadingSpinner>
);

export const RaindropSpinner: React.FC<React.SVGProps<SVGSVGElement>> = ({
  ...props
}) => (
  <LoadingSpinner>
    <LoadingCircle {...props} />
  </LoadingSpinner>
);

import { Flex, FlexColumn } from "@ui/common";
import { Button } from "@ui/components";
import styled from "styled-components";
import { DualTriggerProps, E_StrategyModalType } from "../types";

const ContentWrapper = styled(FlexColumn)`
  width: 444px;
`;
const Title = styled.div`
  font-family: Dunbar Tall;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const Text = styled.div`
  font-size: 14px;
  line-height: 16px;
  min-height: 32px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const ButtonBox = styled(Button)`
  padding-block: 10px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
`;

const data: Record<E_StrategyModalType, { title: string; text: string }> = {
  [E_StrategyModalType.Discard_Trigger_Edit]: {
    title: "Discard Edits?",
    text: "You will lose all changes you’ve made in this trigger setting.",
  },
  [E_StrategyModalType.Delete_Trigger]: {
    title: "Delete Trigger?",
    text: "You will delete this trigger and all rebalance actions associated.",
  },
  [E_StrategyModalType.Delete_Last_Trigger]: {
    title: "Delete Trigger / Strategy?",
    text: "You will delete the strategy if you delete this trigger. You could create a new strategy if you want to add different type of triggers.",
  },
  [E_StrategyModalType.Delete_Strategy]: {
    title: "Delete Strategy?",
    text: "You will lose all triggers and rebalance actions associated. You could create a new strategy if you want to add different type of triggers.",
  },
  [E_StrategyModalType.Cancel_Strategy_Edit]: {
    title: "Cancel Strategy Edit?",
    text: "You will lose all changes you’ve made in the strategy edit.",
  },
};

export const DualTriggerContent: React.FC<DualTriggerProps> = ({
  type,
  onCancel,
  onConfirm,
}) => {
  const isDelete = data[type].title.split(" ")[0] === "Delete";
  return (
    <ContentWrapper gap={20}>
      <Title>{data[type].title}</Title>
      <Text>{data[type].text}</Text>
      <Flex gap="lg">
        {onCancel && (
          <ButtonBox
            width="216px"
            variant="contained"
            color="default"
            size="lg"
            onClick={onCancel}
          >
            No
          </ButtonBox>
        )}
        {onConfirm && (
          <ButtonBox
            width="216px"
            variant="contained"
            color="primary"
            size="lg"
            onClick={onConfirm}
          >
            {`Yes, ${isDelete ? "Delete" : "Cancel"}`}
          </ButtonBox>
        )}
      </Flex>
    </ContentWrapper>
  );
};

import {
  ScaleLinear,
  select,
  zoom,
  ZoomBehavior,
  zoomIdentity,
  ZoomTransform,
} from "d3";
import { useEffect, useMemo, useRef } from "react";
import styled from "styled-components";

import { RefreshCcw, ZoomIn, ZoomOut } from "@aperture/assetkit";
import { Button } from "../Button";
import { ZoomLevels } from "./types";

const Wrapper = styled.div<{ count: number }>`
  display: grid;
  grid-template-columns: repeat(${({ count }) => count.toString()}, 1fr);
  grid-gap: 6px;
  position: absolute;
  top: 4px;
  right: 0;
`;
const ZoomButton = styled(Button)`
  background: ${({ theme }) => theme.colors.global.background.BG3};
  color: ${({ theme }) => theme.colors.global.text.T1};
  &:hover {
    color: ${({ theme }) => theme.colors.grey.black};
    border-color: ${({ theme }) => theme.colors.grey.grey};
  }
  svg {
    stroke: ${({ theme }) => theme.colors.global.text.T1};
  }
`;

export const ZoomOverlay = styled.rect`
  fill: transparent;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`;

export default function Zoom({
  svgRef,
  xScale,
  setZoom,
  width,
  height,
  resetBrush,
  showResetButton,
  zoomLevels,
}: {
  svgRef: React.RefObject<SVGRectElement>;
  xScale: ScaleLinear<number, number>;
  setZoom: (transform: ZoomTransform) => void;
  width: number;
  height: number;
  resetBrush: () => void;
  showResetButton: boolean;
  zoomLevels: ZoomLevels;
}) {
  const zoomBehavior = useRef<ZoomBehavior<Element, unknown>>();

  const [zoomIn, zoomOut, zoomInitial, zoomReset] = useMemo(
    () => [
      () =>
        svgRef.current &&
        zoomBehavior.current &&
        select(svgRef.current as Element)
          .transition()
          .call(zoomBehavior.current.scaleBy, 2),
      () =>
        svgRef.current &&
        zoomBehavior.current &&
        select(svgRef.current as Element)
          .transition()
          .call(zoomBehavior.current.scaleBy, 0.5),
      () =>
        svgRef.current &&
        zoomBehavior.current &&
        select(svgRef.current as Element)
          .transition()
          .call(zoomBehavior.current.scaleTo, 0.5),
      () =>
        svgRef.current &&
        zoomBehavior.current &&
        select(svgRef.current as Element)
          .call(
            zoomBehavior.current.transform,
            zoomIdentity.translate(0, 0).scale(1)
          )
          .transition()
          .call(zoomBehavior.current.scaleTo, 0.5),
    ],
    [svgRef]
  );

  useEffect(() => {
    if (!svgRef.current) return;

    zoomBehavior.current = zoom()
      .scaleExtent([zoomLevels.min, zoomLevels.max])
      .extent([
        [0, 0],
        [width, height],
      ])
      .on("zoom", ({ transform }: { transform: ZoomTransform }) =>
        setZoom(transform)
      );

    select(svgRef.current as Element).call(zoomBehavior.current);
  }, [
    height,
    width,
    setZoom,
    xScale,
    zoomBehavior,
    zoomLevels,
    zoomLevels.max,
    zoomLevels.min,
    svgRef,
  ]);

  useEffect(() => {
    // reset zoom to initial on zoomLevel change
    zoomInitial();
  }, [zoomInitial, zoomLevels]);

  return (
    <Wrapper count={showResetButton ? 3 : 2}>
      {showResetButton && (
        <ZoomButton
          startIcon={<RefreshCcw />}
          size="circle"
          onClick={() => {
            resetBrush();
            zoomReset();
          }}
          disabled={false}
        />
      )}
      <ZoomButton
        startIcon={<ZoomIn />}
        size="circle"
        onClick={zoomIn}
        disabled={false}
      />

      <ZoomButton
        startIcon={<ZoomOut />}
        size="circle"
        onClick={zoomOut}
        disabled={false}
      />
    </Wrapper>
  );
}

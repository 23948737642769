import { FeeAmount } from "@aperture_finance/uniswap-v3-sdk";

export const FEE_TIERS: Record<
  number,
  {
    value: number;
    description: string;
  }
> = {
  [FeeAmount.LOWEST]: {
    value: FeeAmount.LOWEST,
    description: "Best for very stable pairs",
  },
  [FeeAmount.LOW]: {
    value: FeeAmount.LOW,
    description: "Best for stable pairs",
  },
  [FeeAmount.MEDIUM]: {
    value: FeeAmount.MEDIUM,
    description: "Best for most pairs",
  },
  [FeeAmount.PCS_V3_MEDIUM]: {
    value: FeeAmount.PCS_V3_MEDIUM,
    description: "Best for most pairs",
  },
  [FeeAmount.HIGH]: {
    value: FeeAmount.HIGH,
    description: "Best for exotic pairs",
  },
};

export type IFeeTierProps = {
  tiers: Record<number, number> | undefined;
  createdStatus: Record<number, boolean> | undefined;
  selected: number | undefined;
  onSelect: (value: number | undefined) => void;
  disabled?: boolean;
  disableNotCreated?: boolean;
  tips?: string;
  options: {
    key: number;
    label: number;
  }[];
};

import styled from "styled-components";
import { Popover } from "../Popover";
import { IconButtonProps, Variant } from "./types";

export const StyledIconButton = styled.div<{ variant: Variant }>`
  width: fit-content;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  > * {
    z-index: 1;
  }

  ::before {
    position: absolute;
    content: " ";
    inset: 0;
  }
  :is(:hover, :focus, :active) {
    cursor: pointer;
  }
  :is(:hover, :focus, :active)::before {
    cursor: pointer;
    opacity: 1;
  }
`;

export const IconButton: React.FC<IconButtonProps> = ({
  variant,
  Icon,
  content = "",
  onHover = () => {},
  onClick = () => {},
  position,
}) => {
  return (
    <Popover {...{ content, defaultPosition: position }}>
      <StyledIconButton
        variant={variant}
        onMouseEnter={onHover}
        onClick={onClick}
      >
        {Icon}
      </StyledIconButton>
    </Popover>
  );
};

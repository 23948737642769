import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { InputProps } from "./types";

export const BaseInput = styled.input<{
  align: "left" | "right";
}>`
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  text-align: ${({ align }) => align};
  padding: 0;
  outline: none;
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.global.text.T1};
  &:disabled {
    color: ${({ theme }) => theme.colors.global.text.T3};
  }
`;

const formatNumber = (
  value: string,
  type: "number" | "integer",
  round?: number
) => {
  // format to a valid number >= 0 without prefix '-'
  let formatValue = value
    .replace(/[^0-9.]/g, "") // Remove all chars
    .replace(/^0+(?!\.|$)/, ""); // Truncate leading `0`s

  if (type === "integer") {
    formatValue = formatValue.replace(/\./g, ""); // Remove all dots
    formatValue = formatValue === "" ? "0" : formatValue;
    return (
      Math.round(parseFloat(formatValue) / (round ?? 1)) * (round ?? 1)
    ).toString();
  } else {
    // Keep only one `.`
    // formatValue = formatValue.replace(/(?<=\..*)\./g, "");

    // Older version of Safari doesn't support lookbehind yet
    // https://caniuse.com/js-regexp-lookbehind
    // Compromised solution for backward compatibility
    const firstDotIdx = formatValue.indexOf(".") + 1;
    formatValue =
      formatValue.substring(0, firstDotIdx) +
      formatValue.substring(firstDotIdx).replace(/\./g, "");
    return formatValue === "." ? "0." : formatValue;
  }
};

export const Input: FC<InputProps> = ({
  defaultValue = "",
  type,
  align,
  onBlur = (value: string) => value,
  onChange = (value: string) => value,
  ...props
}) => {
  const [value, setValue] = useState(
    type === "text"
      ? defaultValue || ""
      : formatNumber(defaultValue || "", type)
  );
  const handleChange = (event: any) => {
    let value = event.target.value;
    setValue(type === "text" ? value : formatNumber(value, type));
    onChange(type === "text" ? value : formatNumber(value, type));
  };
  const handleBlur = (event: any) => {
    let value = event.target.value;
    setValue(type === "text" ? value : formatNumber(value, type));
    onBlur(type === "text" ? value : formatNumber(value, type));
  };

  useEffect(() => {
    setValue(defaultValue ?? "");
  }, [defaultValue]);

  return (
    <BaseInput
      align={align ?? (type === "text" ? "left" : "right")}
      type="text"
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
      {...props}
    />
  );
};

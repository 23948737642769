import { useSearchParams } from "next/navigation";
import { useState } from "react";

export const useInitQueryValue = (queryKey: string) => {
  const searchParams = useSearchParams();
  const [initValue, setInitValue] = useState<string | null>(null);

  if (!initValue && searchParams.has(queryKey)) {
    setInitValue(searchParams.get(queryKey));
  }

  return initValue;
};

import { AUTOMATION_EXPIRATION_IN_DAYS } from "@aperture_finance/uniswap-v3-automation-sdk";

export const getDeadline = (days: number) =>
  Math.floor(new Date().setDate(new Date().getDate() + days) / 1000);

export const getPositionDeadline = () =>
  getDeadline(AUTOMATION_EXPIRATION_IN_DAYS);

export const getTriggerDeadline = () =>
  getDeadline(AUTOMATION_EXPIRATION_IN_DAYS);

export const getDeadlineWithMins = (mins: number) =>
  Math.floor(new Date().setMinutes(new Date().getMinutes() + mins) / 1000);

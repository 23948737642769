import { Gradients } from "./types";

export const gradients: Gradients = {
  primary: {
    light:
      "linear-gradient(50.13deg, #937EFF 14.11%, #B08AFF 55.86%, #C9B2FD 85.68%)",
    medium:
      "linear-gradient(50.13deg, #6E56FF 14.11%, #9A73EE 56.23%, #BB9BFF 85.68%)",
    dark: "linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), linear-gradient(50.13deg, #6E56FF 14.11%, #9A73EE 56.23%, #BB9BFF 85.68%)",
  },
};

import { IToken } from "@aperture/types";
import { Flex, FlexColumn } from "@uiv2/common";
import { Tab } from "../Tab";
import {
  ArrowDown,
  ArrowDownWrapper,
  SelectedToken,
  SelectedTokenIconWrapper,
  StyledT2,
  StyledT3,
} from "./styles";

export const TokenPairSelector: React.FC<{
  title: string;
  zapIn?: boolean;
  selectedTokenA: IToken | undefined;
  selectedTokenB: IToken | undefined;
  onZapInOutChange?: (value: boolean) => void;
  handleOpenTokenSelectModal: (isTokenA: boolean) => void;
}> = ({
  title,
  zapIn,
  selectedTokenA,
  selectedTokenB,
  onZapInOutChange,
  handleOpenTokenSelectModal,
}) => {
  const withZapIn = zapIn !== undefined && onZapInOutChange !== undefined;
  return (
    <FlexColumn gap="lg">
      <Flex justifyContent="space-between" alignItems="center">
        <StyledT3>{title}</StyledT3>
        {withZapIn && (
          <Tab
            type="switch"
            selectedIdx={zapIn ? 1 : 0}
            onSelect={(value) => onZapInOutChange(value > 0)}
          />
        )}
        {/* Todo: Add zap-in popover, confirm off-status colors */}
      </Flex>
      {/* Select Token Pair */}
      <Flex gap="lg">
        {[selectedTokenA, selectedTokenB].map((selectedToken, idx) => {
          const isTokenA = idx === 0;
          return (
            <SelectedToken
              key={`select-token-${isTokenA ? "A" : "B"}`}
              onClick={() => handleOpenTokenSelectModal(isTokenA)}
            >
              {selectedToken ? (
                <>
                  <SelectedTokenIconWrapper>
                    {selectedToken.Icon}
                  </SelectedTokenIconWrapper>
                  <StyledT2>{selectedToken.ticker}</StyledT2>
                </>
              ) : (
                <StyledT2>Select A Token</StyledT2>
              )}
              <ArrowDownWrapper>
                <ArrowDown />
              </ArrowDownWrapper>
            </SelectedToken>
          );
        })}
      </Flex>
    </FlexColumn>
  );
};

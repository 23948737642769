import { FlexColumn } from "@ui/common";
import styled from "styled-components";
import {
  formatTokenAmount,
  formatUSDAmount,
} from "../../../utils/numberFormat";
import { ModalTitle1, ModalTitle2 } from "../Typography";
import { ClaimFeeProps } from "../types";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 560px;
  gap: ${({ theme }) => theme.spacing.xl};
`;
const TitleText = styled(ModalTitle2)`
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const FeesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
`;
const TotalAmount = styled(ModalTitle1)`
  align-items: center;
  font-size: 32px;
  line-height: 38px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const InfoWrapper = styled(FlexColumn)`
  gap: ${({ theme }) => theme.spacing.md};
`;
const TokenInfo = styled.div`
  display: flex;
  height: 24px;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.md2};
`;
const TokenIconWrapper = styled.div`
  height: 24px;
  width: 24px;
`;
const TokenName = styled(ModalTitle2)`
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const TokenAmount = styled.div`
  align-items: flex-end;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.0025em;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const ClaimFeeContent: React.FC<ClaimFeeProps> = ({
  total,
  tokenInfo,
}) => {
  return (
    <ContentWrapper>
      <TitleText>Unclaimed Fees</TitleText>
      <FeesWrapper>
        <TotalAmount>{formatUSDAmount(total)}</TotalAmount>
        <InfoWrapper>
          {Object.values(tokenInfo).map((info, iconIndex) => {
            return (
              <TokenInfo key={iconIndex}>
                <TokenInfo>
                  <TokenIconWrapper>{info.Icon}</TokenIconWrapper>
                  <TokenName>{info.ticker}</TokenName>
                </TokenInfo>
                <TokenAmount>{formatTokenAmount(info.amount)}</TokenAmount>
              </TokenInfo>
            );
          })}
        </InfoWrapper>
      </FeesWrapper>
    </ContentWrapper>
  );
};

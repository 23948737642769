import React from "react";
import { StyledCheckedIcon, UncheckedCircle } from "./style";

export type ICheckboxProps = {
  checked: Boolean;
  size?: number;
  color?: string;
};

export const Checkbox: React.FC<ICheckboxProps> = ({
  checked,
  size = 24,
  color,
}) => {
  return (
    <>
      {checked ? (
        <StyledCheckedIcon size={size} fill={color} />
      ) : (
        <UncheckedCircle size={size} />
      )}
    </>
  );
};

import { Flex } from "@ui/common";
import React, { CSSProperties, useEffect, useState } from "react";
import styled from "styled-components";
import { ButtonText1 } from "../../Typography";
import { StyledBar, StyledItem, StyledSlider } from "../SharedTab";
import { BtnSwitchTabProps } from "./types";

const TabBar = styled(StyledBar)<{ sm?: boolean }>`
  background: ${({ theme }) => theme.colors.global.background.BG3};
  border-radius: ${({ theme }) => theme.radii.round};
  padding: 0px;
  height: ${({ sm }) => (sm ? "24px" : "40px")};
  width: fit-content;
`;
const Wrapper = styled(Flex)<{ sm?: boolean }>`
  padding: 6px;
  height: ${({ sm }) => (sm ? "24px" : "40px")};
  width: fit-content;
  border-radius: ${({ theme }) => theme.radii.round};
  background: ${({ theme }) => theme.colors.global.background.BG3};
`;
const TabSlider = styled(StyledSlider)<{ sm?: boolean }>`
  background: ${({ theme }) => theme.colors.global.text.T1};
  border-radius: ${({ theme }) => theme.radii.round};
  height: ${({ sm }) => (sm ? "24px" : "40px")};
`;
const TabItems = styled(StyledItem)<{ isSelected: boolean; sm?: boolean }>`
  height: calc(100% - 24px);
  padding: ${({ sm }) => (sm ? "4px 16px" : "12px 24px")};
  z-index: ${({ theme }) => theme.zIndices.tab};
  color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.colors.global.background.BG1
      : theme.colors.global.text.T1};
  width: fit-content;
`;
const Btn = styled.div`
  cursor: pointer;
  height: calc(100% - 24px);
  width: max-content;
  padding: 12px 24px;
  color: ${({ theme }) => theme.colors.global.primary};
  background: ${({ theme }) => theme.colors.global.background.BG3};
`;
const StyledText = styled(ButtonText1)`
  line-height: 16px;
`;

export const getTabWidth = (text: string, sm: boolean) => {
  const styles: CSSProperties = {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "16px",
    padding: sm ? "4px 16px" : "12px 24px",
    width: "fit-content",
    visibility: "hidden",
  };
  const label = document.createElement("div");
  label.textContent = text;
  Object.assign(label.style, styles);

  document.body.appendChild(label);
  const width = label.getBoundingClientRect().width;
  document.body.removeChild(label);
  return width;
};

export const BtnSwitchTab: React.FC<BtnSwitchTabProps> = ({
  sm = false,
  menu,
  selected,
  onSelect,
  btn = undefined,
  onBtnClick = () => {},
  ...props
}) => {
  const menuLength = menu.length ?? 1;
  const [fontLoading, setFontLoading] = useState(true);

  useEffect(() => {
    document.fonts.ready.then(() => setFontLoading(false));
  }, []);

  const tabWidths = menu.map((item) => getTabWidth(item, sm));

  return (
    <Wrapper sm={sm}>
      <TabBar {...props} sm={sm}>
        {!fontLoading && (
          <TabSlider
            sm={sm}
            selectedIndex={selected}
            length={menuLength}
            containerPadding="0px"
            width={tabWidths[selected]}
            style={{
              left: tabWidths.slice(0, selected).reduce((acc, w) => acc + w, 0),
            }}
          />
        )}
        {menu.map((item, index) => (
          <TabItems
            key={`${index}-${item}`}
            sm={sm}
            itemWidth={10}
            onClick={() => onSelect(index)}
            isSelected={index === selected}
          >
            <StyledText>{item}</StyledText>
          </TabItems>
        ))}
      </TabBar>
      {btn && <Btn onClick={onBtnClick}>{btn}</Btn>}
    </Wrapper>
  );
};

import { useEventCallback } from "@/hooks/useEventCallback";
import { getLogger } from "@aperture_finance/uniswap-v3-automation-sdk";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import React, { useEffect, useRef } from "react";

const Context = React.createContext<IContext>(null);

interface IContext {
  updateUrlQuery: (key: string, value?: string | number | null) => void;
}

export const URLContextProvider = ({ children }) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const searchParamsRef = useRef<URLSearchParams>();
  const pathnameRef = useRef<string>();
  pathnameRef.current = pathname;

  const batchUpdateQuery = useRef({
    handlerId: null,
    params: {},
  });

  useEffect(() => {
    searchParamsRef.current = new URLSearchParams(searchParams.toString());
  }, [searchParams]);

  useEffect(() => {
    try {
      getLogger().info("Page.PathChanged");
    } catch (e) {}
  }, [pathname]);

  const updateUrlQuery = useEventCallback(
    (key: string, value?: string | number | null) => {
      // need a batch update here, otherwise might miss some updates when update too frequently
      const { handlerId } = batchUpdateQuery.current;
      if (handlerId) {
        clearTimeout(handlerId);
      }
      batchUpdateQuery.current.params[key] = value;
      batchUpdateQuery.current.handlerId = setTimeout(() => {
        const params = new URLSearchParams(searchParamsRef.current.toString());
        const notChanged = Object.entries(
          batchUpdateQuery.current.params
        ).every(([key, value]) => {
          if (value === undefined || value === null) {
            return !params.has(key);
          }
          return params.get(key) === `${value}`;
        });

        if (!notChanged) {
          Object.entries(batchUpdateQuery.current.params).forEach(
            ([key, value]) => {
              if (value) {
                params.set(key, `${value}`);
              } else {
                params.delete(key);
              }
            }
          );
          router.replace(`${pathnameRef.current}?${params}`);
        }

        batchUpdateQuery.current.params = {};
        batchUpdateQuery.current.handlerId = null;
      }, 1000);
    }
  );

  return (
    <Context.Provider value={{ updateUrlQuery }}>{children}</Context.Provider>
  );
};

export const useURLContext = () => {
  return React.useContext(Context);
};

import { OrderRoutingContent } from "@aperture/uikit";
import { useSolverRoutingStore } from "../GlobalStore";

export const OrderRoutingModal: React.FC<{
  onSelect: (idx: number) => void;
  onClose?: () => void;
}> = ({ onSelect, onClose }) => {
  const { swapInfo, selectedSolver, suggestedSolver } = useSolverRoutingStore();

  // Close OrderRoutingModal if no valid swap info
  if (!swapInfo?.length) {
    onClose?.();
    return null;
  }

  return (
    <OrderRoutingContent
      swapInfo={swapInfo.map((route, idx) => ({
        ...route,
        showSwapRoutes: route?.swapRoutes?.length > 0,
        suggested: idx === suggestedSolver,
        selected: idx === selectedSolver,
        onClick: () => onSelect(idx),
      }))}
    />
  );
};

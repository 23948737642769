import { scaleLinear } from "d3";
import { useEffect, useMemo } from "react";

import { AxisBottom } from "./AxisBottom";
import { SingleBrush } from "./SingleBrush";
import { RatioChartProps } from "./types";

export function RatioChart({
  id = "ratioChartRangeInput",
  styles,
  dimensions: { width, height },
  margins,
  interactive = true,
  brushDomain,
  brushLabels,
  onBrushDomainChange,
  brushMinValue = 0,
  brushMaxValue = 100,
  axisSuffix,
}: RatioChartProps) {
  const [innerHeight, innerWidth] = useMemo(
    () => [
      height - margins.top - margins.bottom,
      width - margins.left - margins.right,
    ],
    [width, height, margins]
  );
  const xScale = useMemo(
    () =>
      scaleLinear()
        .domain([brushMinValue - 0.5, brushMaxValue]) // 0.5 for brush width
        .range([0, innerWidth]),
    [innerWidth, brushMinValue, brushMaxValue]
  );
  const axisScale = useMemo(
    () =>
      scaleLinear()
        .domain([brushMinValue - 0.5, brushMaxValue]) // 0.5 for brush width
        .range([8, innerWidth - 16]),
    [innerWidth, brushMinValue, brushMaxValue]
  );

  useEffect(() => {
    if (!brushDomain) {
      onBrushDomainChange(xScale.domain() as [number, number], undefined);
    }
  }, [brushDomain, onBrushDomainChange, xScale]);

  return (
    <div>
      <svg
        width="100%"
        height="100%"
        viewBox={`0 0 ${width} ${height}`}
        style={{ overflow: "visible" }}
      >
        <defs>
          <clipPath id={`${id}-chart-clip`}>
            <rect x="0" y="0" width={innerWidth} height={height} />
          </clipPath>
        </defs>

        <g transform={`translate(${margins.left},${margins.top})`}>
          <g clipPath={`url(#${id}-chart-clip)`}>
            <AxisBottom
              xScale={axisScale}
              innerHeight={innerHeight}
              suffix={axisSuffix}
            />
          </g>

          <SingleBrush
            id={id}
            xScale={xScale}
            interactive={interactive}
            brushLabelValue={brushLabels}
            brushExtent={brushDomain ?? (xScale.domain() as [number, number])}
            innerWidth={innerWidth}
            innerHeight={innerHeight}
            setBrushExtent={onBrushDomainChange}
            handleColor={styles.brush.handle}
            brushMinValue={brushMinValue}
            brushMaxValue={brushMaxValue}
          />
        </g>
      </svg>
    </div>
  );
}

import styled from "styled-components";

export const ActivityContainer = styled.div.attrs({
  className: 'activity-item'
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  &:last-child {
    margin: 0;
  }
  svg {
    flex-shrink: 0;
  }
`;

export const ActivityText = styled.span`
  font-size: 12px;
  margin: 0 4px 0 6px;
`;

export const RecentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

import {
  getApprovalForAllRequest,
  getRevokeForAllRequest,
} from "@/helper/authorizationHelper";
import { useEventCallback } from "@/hooks/useEventCallback";
import { useFetchUserAuthentication } from "@/hooks/useFetchUserAuthentication";
import { useWaitForTransaction } from "@/hooks/useWaitForTransaction";
import { useSendTransactionWithGas } from "@/hooks/wagmi/useSendTransactionWithGas";
import { getNetworkName } from "@/utils/networkHelper";
import { getChainInfo } from "@aperture/uikit";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Address } from "viem";
import { useNetwork } from "../NetworkContext/NetworkContext";
import { useSnackbarV2 } from "../SnackbarContext/SnackbarContextV2";

export const useAOStatus = () => {
  const { amm, ammEnum, networkId } = useNetwork();

  const {
    data: ifAuthorized,
    isLoading: ifAuthLoading,
    isValidating,
    mutate,
  } = useFetchUserAuthentication();

  const { addSnackbar } = useSnackbarV2();

  const [approvalRequest, revokeRequest] = useMemo(() => {
    const isSupported = getChainInfo(getNetworkName(networkId))
      .amm.map((platform) => platform.name)
      .includes(amm?.name);
    return [
      networkId && isSupported && getApprovalForAllRequest(ammEnum, networkId),
      networkId && isSupported && getRevokeForAllRequest(ammEnum, networkId),
    ];
  }, [amm, networkId]);

  const [authTxnHash, setAuthTxnHash] = useState<string | null>(null);

  const { sendTransactionAsync, isPending } = useSendTransactionWithGas({
    ...(ifAuthorized ? revokeRequest : approvalRequest),
  });

  const toggleAOStatusAsync = useEventCallback(() => {
    return sendTransactionAsync()
      .then((data) => {
        addSnackbar({
          info: "Your authorization request has been sent.",
          status: "completed",
        });
        setAuthTxnHash(data);
        return data;
      })
      .catch((error) => {
        addSnackbar({
          info: "Your authorization request has failed",
          status: "failed",
        });
        console.error(error);
        throw error;
      });
  });

  const { data: authTxnReceipt, isLoading: loadingAuthTxnReceipt } =
    useWaitForTransaction({
      hash: authTxnHash as Address,
    });

  const onAuthTxnReceiptLoaded = useEventCallback(() => {
    if (authTxnReceipt.status === "success") {
      addSnackbar({
        info: ifAuthorized
          ? "Authorized Operator status for all your positions has been revoked."
          : "You have successfully granted Authorized Operator status for all your positions.",
        status: "completed",
      });
      mutate();
    } else if (authTxnReceipt.status === "reverted") {
      addSnackbar({
        info: ifAuthorized
          ? "Failed to revoke Authorized Operator status for all your positions."
          : "Failed to grant Authorized Operator status for all your positions.",
        status: "failed",
      });
    }
    setAuthTxnHash(undefined);
  });

  useEffect(() => {
    if (authTxnReceipt) {
      onAuthTxnReceiptLoaded();
    }
  }, [authTxnReceipt, onAuthTxnReceiptLoaded]);

  const safeToggleAOStatus = useCallback(() => {
    return toggleAOStatusAsync().catch(() => {});
  }, [toggleAOStatusAsync]);

  const isAOLoading =
    isPending || ifAuthLoading || loadingAuthTxnReceipt || isValidating;

  return {
    ifAuthorized: ifAuthorized && !isAOLoading,
    mutate,
    safeToggleAOStatus,
    toggleAOStatusAsync,
    isAOStatusLoading: isAOLoading,
  };
};

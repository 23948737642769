import { Flex } from "@ui/common";
import { formatFixed } from "@ui/utils";
import { formatUSDAmount } from "../../utils";
import { RaindropLoader } from "../Loader/Spinner/Spinner.stories";
import { HighlightText } from "../SwapPath/style";
import { Route, getHeights } from "./data";
import {
  Container,
  Content,
  H5,
  IconWrapper,
  PureIconWrapper,
  S2,
  S3,
  StyledArrowIcon,
  Tag,
  Text,
  Text2,
  TextIconWrapper,
  TitleRow,
  VerticalLine,
  Wrapper,
} from "./style";
import { SwapRouteProps } from "./types";

export const SwapRoute: React.FC<SwapRouteProps> = ({
  dark = false,
  wBorder = false,
  loading,
  api,
  priceImpact,
  fromToken,
  routes,
  maxWidth,
  noConnectingLine = false,
  gasFeeUSD = undefined,
  suggested = false,
}) => {
  const headerVariant: "text" | "title" =
    gasFeeUSD !== undefined ? "text" : "title";
  const routesLength = routes?.length;
  const isMultiple = routesLength ? routesLength > 1 : false;
  const hasDetails = routes?.some((route) =>
    route.tokenDetails.some((td) => !!td.details)
  );
  const { verticalLineHeight, fromTokenMargin } = getHeights(routes);

  const maxes = routes?.reduce(
    (acc, route) => {
      const maxDetailsLength = Math.max(
        route.tokenDetails.length,
        acc.maxDetailsLength
      );
      const maxTickerLength = route.tokenDetails.reduce(
        (max, item) => Math.max(item.token.ticker.length, max),
        acc.maxTickerLength
      );
      return {
        maxDetailsLength,
        maxTickerLength,
      };
    },
    { maxDetailsLength: 0, maxTickerLength: 0 }
  );

  const labelLength = maxes
    ? hasDetails
      ? 132
      : 36 + 14 * maxes.maxTickerLength
    : undefined;
  const containerWidth =
    maxes && labelLength
      ? 60 +
        16 +
        maxes.maxDetailsLength * (68 + labelLength) +
        (isMultiple ? 68 : 0)
      : undefined;
  const minWidth = 400;
  const width =
    maxWidth ??
    (!containerWidth || containerWidth < minWidth ? minWidth : containerWidth);

  return (
    <Container
      wBorder={wBorder}
      dark={dark}
      w={width}
      maxW={maxWidth ?? width}
      isText={headerVariant === "text"}
    >
      {headerVariant === "text" ? (
        <Text2>
          <TextIconWrapper>{api?.Icon}</TextIconWrapper>
          {api?.name}{" "}
          <span>
            API route{" "}
            {gasFeeUSD !== "-" &&
              `costs ${formatUSDAmount(gasFeeUSD ?? "0")} in gas. This route `}
            optimizes your total output by considering split routes, multiple
            hops, and the gas cost of each step.
          </span>
        </Text2>
      ) : (
        <TitleRow isLoading={loading} minW={width}>
          <Flex gap="md" alignItems="center">
            <Text dark={dark}>API</Text>
            {!loading && (
              <Tag gap="sm" dark={dark}>
                <IconWrapper>{api?.Icon}</IconWrapper>
                <S3 dark={dark}>{api?.name}</S3>
              </Tag>
            )}
          </Flex>
          {loading ? (
            <RaindropLoader />
          ) : (
            <Flex gap="md" alignItems="center">
              {suggested && <HighlightText>Suggested</HighlightText>}
              <Text dark={dark}>Est. Price Impact</Text>
              <H5 dark={dark} priceImpact={Number(priceImpact)}>
                {formatFixed(priceImpact ?? "", 2)}%
              </H5>
            </Flex>
          )}
        </TitleRow>
      )}
      {!loading && !!routes && routes.length > 0 && (
        <Content alignItems="center">
          <Wrapper
            alignItems="center"
            gap="sm"
            marginTop={hasDetails ? fromTokenMargin + 5 : undefined}
            flex={isMultiple ? 0.1 : undefined}
          >
            <PureIconWrapper>{fromToken.Icon}</PureIconWrapper>
            <S2 dark={dark}>{fromToken.ticker}</S2>
          </Wrapper>
          {isMultiple && <StyledArrowIcon />}
          <VerticalLine h={verticalLineHeight} hasDetails={!!hasDetails} />
          <Wrapper
            gap="md"
            width="100%"
            flexDirection="column"
            flex={isMultiple ? 0.75 : undefined}
          >
            {routes.map((route, index) => (
              <Route
                key={index}
                noConnectingLine={noConnectingLine}
                dark={dark}
                isMultiple={isMultiple}
                alignTag={
                  !!routesLength &&
                  routesLength > 1 &&
                  route.tokenDetails.length < 2
                }
                tokenDetails={route.tokenDetails}
                percentage={route.percentage}
              />
            ))}
          </Wrapper>
        </Content>
      )}
    </Container>
  );
};

import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { DrawerComponent } from "./Drawer";
import { DrawerProps } from "./types";

interface DrawerContextType {
  drawerOpen: boolean;
  Drawer: React.FC<PropsWithChildren>;
  showDrawer: () => void;
  hideDrawer: () => void;
  intentDrawerOpen: boolean;
  showIntentDrawer: () => void;
  hideIntentDrawer: () => void;
}

const DrawerContext = createContext<DrawerContextType | undefined>(undefined);

export const useDrawer = () => {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error("useDrawer must be used within a DrawerProvider");
  }
  return context;
};

export const DrawerProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [intentDrawerOpen, setIntentDrawerOpen] = useState<boolean>(false);

  const showDrawer = useCallback(() => {
    setDrawerOpen(true);
  }, []);

  const hideDrawer = useCallback(() => {
    setDrawerOpen(false);
  }, []);

  const showIntentDrawer = useCallback(() => setIntentDrawerOpen(true), []);

  const hideIntentDrawer = useCallback(() => setIntentDrawerOpen(false), []);

  return (
    <DrawerContext.Provider
      value={{
        drawerOpen,
        Drawer,
        showDrawer,
        hideDrawer,
        intentDrawerOpen,
        showIntentDrawer,
        hideIntentDrawer,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

const Drawer: React.FC<PropsWithChildren & DrawerProps> = ({
  children,
  ...props
}) => {
  const { drawerOpen, hideDrawer } = useDrawer();
  const [mounted, setMounted] = useState<boolean>(false); // use it to hide close animation when mounted
  const childrenSnapshot = useRef(children); // save snapshot for children

  if (children) {
    childrenSnapshot.current = children;
  }

  useEffect(() => {
    setMounted(true);
    return () => {
      childrenSnapshot.current = null;
      hideDrawer();
    };
  }, [hideDrawer]);

  return (
    <DrawerComponent
      key="right-drawer"
      direction="right"
      isOpen={drawerOpen && mounted}
      {...props}
    >
      {childrenSnapshot.current}
    </DrawerComponent>
  );
};

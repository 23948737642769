import { AllChainAction, AllChainState, IRawAllPositionMap } from "./types";

export function reducer(state: AllChainState, action: AllChainAction) {
  switch (action.type) {
    case "initAllTokenMap":
      return {
        ...state,
        rawAllTokenMap: action.payload,
      };
    case "updateTokenMap":
      return {
        ...state,
        rawAllTokenMap: Object.assign({}, state.rawAllTokenMap ?? {}, {
          [action.payload.chainId]: {
            ...state.rawAllTokenMap?.[action.payload.chainId],
            ...action.payload.rawTokenMap,
          },
        }),
      };
    case "updateTokenPrice":
      return {
        ...state,
        rawAllTokenPriceMap: Object.assign({}, state.rawAllTokenPriceMap, {
          [action.payload.chainId]: {
            ...state.rawAllTokenPriceMap[action.payload.chainId],
            ...action.payload.tokenPriceMap,
          },
        }),
      };
    case "updatePosition": {
      const { walletAddress, chainId, amm, positionMap } = action.payload;
      const _allPositionMap: IRawAllPositionMap = {
        ...state.rawAllPositionMap,
        [walletAddress]: {
          ...state.rawAllPositionMap?.[walletAddress],
          [amm]: {
            ...state.rawAllPositionMap?.[walletAddress]?.[amm],
            [chainId]: {
              ...state.rawAllPositionMap?.[walletAddress]?.[amm]?.[chainId],
              ...positionMap,
            },
          },
        },
      };
      return {
        ...state,
        rawAllPositionMap: _allPositionMap,
      };
    }
    case "updateActivePositionMap": {
      return {
        ...state,
        activePositionMap: {
          ...state.activePositionMap,
          [action.payload.chainId]: {
            ...state.activePositionMap[action.payload.chainId],
            ...action.payload.positionMap,
          },
        },
      };
    }
    case "clearPostionMap":
      return {
        ...state,
        rawAllPositionMap: undefined,
        activePositionMap: {},
      };
    default:
      return state;
  }
}

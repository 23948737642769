import { CheckedIcon, CrossIcon, WarningIcon2 } from "@aperture/assetkit";
import { Flex, UIKitProvider } from "@aperture/uikit";
import { darkColors, defaultTheme, lightTheme } from "@aperture/uikitv2";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import {
  Box,
  CloseBtn,
  IconWrapper,
  Info,
  SnackbarContainer,
  TextSection,
  Url,
} from "./style";
import { ISnackbarProps, IStatus } from "./types";

const SNACKBAR_GAP = 12;

const handleClose = (containerId: string) => {
  const snackBars = document.querySelectorAll(".snack-bar-container");
  const index = Array.from(snackBars).findIndex(
    (bar) => bar.id === containerId
  );
  const containerEle = document.querySelector(`#${containerId}`) as HTMLElement;
  const height =
    (containerEle.querySelector(".snack-bar")?.clientHeight || 0) +
    SNACKBAR_GAP;
  const outerContainer = document.getElementById("snackbarOuterContainer");
  if (containerEle && outerContainer) {
    outerContainer.removeChild(containerEle);
  }

  for (let i = index + 1; i < snackBars.length; i++) {
    const barEle = snackBars[i].querySelector(".snack-bar") as HTMLElement;
    const topValue = parseInt(
      window.getComputedStyle(barEle).getPropertyValue("top")
    );
    barEle.style.top = `${topValue - height}px`;
  }
};

export const Snackbar: React.FC<ISnackbarProps> = ({
  id,
  info,
  status,
  top,
  url,
  showClose = true,
}) => {
  let timer2 = useRef<NodeJS.Timeout | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const timer1 = setTimeout(() => {
      setIsOpen(true);
      clearTimeout(timer1);
    }, 0);
    if (!timer2.current) {
      timer2.current = setTimeout(() => {
        handleClose(id);
        clearTimeout(timer2.current as NodeJS.Timeout);
      }, 10 * 1000);
    }
    // eslint-disable-next-line
  }, []);

  const statusIcons: Record<IStatus, ReactNode> = {
    completed: (
      <CheckedIcon color={darkColors.global.success} width={16} height={16} />
    ),
    failed: <CrossIcon fill={darkColors.global.error} width={15} height={15} />,
    warning: (
      <WarningIcon2 fill={darkColors.global.warning} width={16} height={16} />
    ),
    neutral: undefined,
  };

  return (
    <SnackbarContainer isOpen={isOpen} top={top} status={status}>
      <Flex gap="xl" alignItems="center">
        {status !== "neutral" && (
          <Box status={status}>{statusIcons[status]}</Box>
        )}
        <TextSection>
          <Info>{info}</Info>
          {url && (
            <Url
              state={status}
              onClick={() => {
                window.open(url, "_blank");
              }}
            >
              View on Explorer
            </Url>
          )}
        </TextSection>
      </Flex>
      {showClose && (
        <IconWrapper>
          <CloseBtn
            onClick={() => {
              handleClose(id);
              clearTimeout(timer2.current as NodeJS.Timeout);
            }}
          />
        </IconWrapper>
      )}
    </SnackbarContainer>
  );
};

let id = 0;

export const openSnackBar = ({
  info,
  status,
  url,
  showClose,
  currentTheme,
}: Pick<ISnackbarProps, "info" | "status" | "url" | "showClose"> & {
  currentTheme: "light" | "dark";
}) => {
  const snackbarElement = document.createElement("div");
  const containerId = `snackbar-${id}`;
  snackbarElement.id = containerId;
  id++;
  snackbarElement.classList.add("snack-bar-container");
  const outerContainer = document.getElementById("snackbarOuterContainer");
  if (outerContainer) {
    outerContainer.appendChild(snackbarElement);
  }
  const snackBars = document.querySelectorAll(".snack-bar-container");
  let top = 0;
  snackBars.forEach((bar) => {
    const existingHeight = bar.querySelector(".snack-bar")?.clientHeight || 0;
    top += existingHeight + SNACKBAR_GAP;
  });

  createRoot(snackbarElement).render(
    <UIKitProvider theme={currentTheme === "dark" ? defaultTheme : lightTheme}>
      <Snackbar
        id={containerId}
        info={info}
        status={status}
        url={url}
        top={top}
        showClose={showClose}
      />
    </UIKitProvider>
  );
};

export enum SupportedChainId {
  ETHEREUM = "ETHEREUM",
  ARBITRUM = "ARBITRUM",
  MANTA = "MANTA PACIFIC",
  OPTIMISM = "OPTIMISM",
  POLYGON = "POLYGON",
  BASE = "BASE",
  BNB = "BNB",
  AVALANCHE = "AVALANCHE",
  SCROLL = "SCROLL",
}
export interface BasicInfo {
  Icon: any;
  name: string;
}

export interface NetworkInfo extends BasicInfo {
  id: SupportedChainId;
  amm: BasicInfo[];
}

export type AmmEnum = "UNISWAP_V3" | "PANCAKESWAP_V3" | "SLIPSTREAM";
export enum E_AMM {
  Uniswap = "Uniswap",
  PancakeSwap = "PancakeSwap",
  ApertureSwap = "ApertureSwap",
  Aerodrome = "Aerodrome",
  Velodrome = "Velodrome",
}
export type Calculator_AMM = E_AMM.Uniswap | E_AMM.PancakeSwap;

import { format } from "d3";
import bigDecimal from "js-big-decimal";
import { saturate } from "polished";
import { useCallback, useMemo } from "react";
import styled, { useTheme } from "styled-components";
import { Headline5 } from "../Typography";
import { RatioChart } from "./RatioChart";
import { Bound, StrategyChartRangeInputProps } from "./types";

const InfoWrapper = styled(Headline5)<{ height: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => `${height}px`};
  color: ${({ theme }) => theme.colors.grey.mediumGrey};
`;

function InfoBox({ message, height }: { message?: string; height: number }) {
  return <InfoWrapper height={height}>{message}</InfoWrapper>;
}

export function RatioChartRangeInput({
  currencyA,
  currencyB,
  ticksAtLimit,
  price,
  priceLower,
  priceUpper,
  onLeftRangeInput,
  interactive,
  chartHeight,
}: StrategyChartRangeInputProps) {
  const brushMinValue = 0;
  const brushMaxValue = 100;
  const theme = useTheme();
  const onBrushDomainChangeEnded = useCallback(
    (domain: [number, number], mode: string | undefined) => {
      let rightRangeValue = domain[1];
      if (bigDecimal.compareTo(rightRangeValue, brushMinValue) <= 0) {
        rightRangeValue = brushMinValue + 1 / 1e6;
      }

      // simulate user input for auto-formatting and other validations
      if (
        (!ticksAtLimit[Bound.LOWER] || mode === "handle" || mode === "reset") &&
        bigDecimal.compareTo(rightRangeValue, brushMinValue) >= 0
      ) {
        onLeftRangeInput(bigDecimal.round(rightRangeValue, 18));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ticksAtLimit]
  );

  const brushDomain: [number, number] | undefined = useMemo(() => {
    const leftPrice = priceLower;
    const rightPrice = priceUpper;
    return leftPrice && rightPrice
      ? [brushMinValue, parseFloat(leftPrice)]
      : undefined;
  }, [brushMinValue, priceLower, priceUpper]);

  const brushLabelValue = (d: "w" | "e", x: number) => {
    return `${d === "w" ? currencyA : currencyB} ${format(
      Math.abs(x) > 1000 ? ".2~s" : ".2~f"
    )(d === "w" ? x : 100 - x)}%`;
  };

  return (
    <>
      {price === undefined ? (
        <InfoBox message={"There is no liquidity data."} height={chartHeight} />
      ) : (
        <RatioChart
          dimensions={{ width: 400, height: chartHeight }}
          margins={{ top: 0, right: 0, bottom: 20, left: 0 }}
          styles={{
            area: {
              selection: theme.colors.global.text.T1,
            },
            brush: {
              handle: saturate(0.1, theme.colors.global.primary),
            },
          }}
          interactive={interactive}
          brushLabels={brushLabelValue}
          brushDomain={brushDomain}
          onBrushDomainChange={onBrushDomainChangeEnded}
          brushMinValue={brushMinValue}
          brushMaxValue={brushMaxValue}
          axisSuffix="%"
        />
      )}
    </>
  );
}

import { CompletedIcon } from "@aperture/assetkit";
import styled from "styled-components";

export const StyledCheckedIcon = styled(CompletedIcon)<{ size: number }>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
`;

export const UncheckedCircle = styled.div<{ size: number }>`
  width: ${({ size }) => `${size - 4}px`};
  height: ${({ size }) => `${size - 4}px`};
  border-radius: ${({ theme }) => theme.radii.circle};
  border: 2px solid ${({ theme }) => theme.colors.grey.mediumGrey};
`;

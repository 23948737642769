import { IToken } from "@aperture/types";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Dropdown, IDropdownRef } from "../Dropdown";
import { SearchInput } from "../Input";
import { LinearLoader } from "../Loader";
import {
  ArrowDown,
  ArrowDownWrapper,
  ButtonContainer,
  ContentContainer,
  OptionList,
  Text,
  Title,
} from "./style";
import { IDropdownTokenSelectorProps } from "./types";

const isAddress = (address: string) => {
  return /^(0x){1}[0-9a-fA-F]{40}$/i.test(address);
};

export const DropdownTokenSelector = ({
  tokens,
  selectedToken = undefined,
  title = undefined,
  onSelectToken,
  onSelectUnknownToken,
  disabled,
  buttonComponent,
  optionComponent: Option,
  keyExtractor,
  searchOptions,
  searchPlaceholder,
  handleUnknownAddress,
  inModal = false,
  ...props
}: IDropdownTokenSelectorProps) => {
  const searchEnabled = searchOptions !== undefined;
  const dropdownRef = useRef<IDropdownRef>(null);
  const [list, setList] = useState(tokens || []);
  const [unknownToken, setUnknownToken] = useState<IToken | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSelect = (token: IToken, isUnknown: boolean) => {
    if (
      token.address !== selectedToken?.address ||
      token.native !== selectedToken?.native
    ) {
      isUnknown ? onSelectUnknownToken?.(token) : onSelectToken?.(token);
    }
  };

  const handleChange = async (value: string) => {
    const trimmedValue = value.toLowerCase().trim();
    const filteredOptions =
      trimmedValue && searchEnabled ? searchOptions(trimmedValue) : tokens;
    setList(filteredOptions);
    if (filteredOptions.length === 0 && isAddress(value)) {
      setLoading(true);
      const token = await handleUnknownAddress(value);
      setLoading(false);
      setUnknownToken(token);
    } else {
      setUnknownToken(null);
    }
  };

  useEffect(() => {
    setList(tokens || []);
  }, [tokens]);

  const dropdownBtn = (
    <ButtonContainer {...props} inModal={inModal}>
      {buttonComponent}
      <ArrowDownWrapper>
        <ArrowDown />
      </ArrowDownWrapper>
    </ButtonContainer>
  );
  const renderOption = (option: IToken, index: number) => {
    const key = keyExtractor ? keyExtractor(option, index) : index;
    return (
      <React.Fragment key={key}>
        {option && (
          <Option
            {...option}
            isSelected={_.isEqual(option, selectedToken)}
            onClick={() => handleSelect(option, false)}
          />
        )}
      </React.Fragment>
    );
  };

  const renderContent = () => {
    if (list.length > 0) {
      return list.map(renderOption);
    }
    if (loading) {
      return <LinearLoader size="100%" height="32px" radius="8px" />;
    }
    if (unknownToken) {
      return (
        <Option
          {...unknownToken}
          isSelected={_.isEqual(unknownToken, selectedToken)}
          onClick={() => handleSelect(unknownToken, true)}
        />
      );
    }
    return <Text>No results found.</Text>;
  };

  return (
    <Dropdown
      ref={dropdownRef}
      button={dropdownBtn}
      position="bottomRight"
      containerStyles={props.dropdownContainerStyles}
      disabled={disabled}
    >
      <ContentContainer style={props.contentContainerStyles}>
        {title && <Title>{title}</Title>}
        {searchEnabled && (
          <SearchInput
            height={44}
            onChange={handleChange}
            placeholder={searchPlaceholder}
          />
        )}
        <OptionList mt={!!title || searchEnabled}>{renderContent()}</OptionList>
      </ContentContainer>
    </Dropdown>
  );
};

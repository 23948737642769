import { LoadingCircle, SendIcon } from "@aperture/assetkit";
import { FormEventHandler, memo, useRef, useState } from "react";
import { useTheme } from "styled-components";
import { IconButton, IntentInput, IntentInputContainer } from "./styles";

export interface ChatInputBoxProps {
  input: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: FormEventHandler<HTMLFormElement>;
  isLoading?: boolean;
  bg?: "grey" | "transparent";
}

export const ChatInputBoxComponent: React.FC<ChatInputBoxProps> = ({
  input,
  isLoading,
  handleInputChange,
  onSubmit,
  bg = "transparent",
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const disabled = isLoading || input.length === 0;
  const theme = useTheme();
  const [composing, setComposing] = useState(false);

  return (
    <form
      ref={formRef}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(e);
      }}
    >
      <IntentInputContainer bg={bg}>
        <IntentInput
          tabIndex={0}
          autoFocus
          placeholder="Message IntentsGPT..."
          value={input}
          onChange={handleInputChange}
          onCompositionStart={() => setComposing(true)}
          onCompositionEnd={() => setComposing(false)}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            // Specify the generic type parameter for KeyboardEvent
            if (e.key === "Enter" && !e.shiftKey && !composing && !isLoading) {
              formRef.current?.requestSubmit();
              e.preventDefault();
            }
          }}
          spellCheck={false}
        />
        <IconButton disabled={disabled}>
          {isLoading ? (
            <LoadingCircle />
          ) : (
            <SendIcon fill={theme.colors.global.primary} />
          )}
        </IconButton>
      </IntentInputContainer>
    </form>
  );
};

export const ChatInputBox = memo(ChatInputBoxComponent);

import styled from "styled-components";
import { compose, layout, space } from "styled-system";
import { TabStyleProps } from "./types";

export const StyledBar = styled.div<TabStyleProps>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 32px;
  width: calc(100% - 4px);
  transition: ${({ theme }) => theme.transitions.default};
  ${compose(layout, space)}
`;
export const StyledSlider = styled.div.attrs({
  className: "baseSlider",
})<
  TabStyleProps & {
    length: number;
    height?: string;
    widthPercentage?: number;
    selectedIndex: number;
    selectWidth?: number;
    leftPercentage?: number;
    containerPadding?: string;
  }
>`
  position: absolute;
  height: ${({ height }) => height ?? "100%"};
  --container-padding: ${({ containerPadding }) => containerPadding ?? "2px"};
  --switch-padding: 8px;
  --container-inner-width: ${({ widthPercentage, length }) =>
    widthPercentage
      ? `calc(100% - var(--container-padding) * 2 - var(--switch-padding) * 2 * ${length})`
      : `calc(100% - var(--container-padding) * 2)`};
  --switch-width: ${({ widthPercentage, length }) =>
    widthPercentage
      ? `calc(var(--container-inner-width) * ${widthPercentage} + var(--switch-padding) * 2)`
      : `calc(var(--container-inner-width) / ${length})`};
  width: var(--switch-width);
  left: ${({ leftPercentage, selectedIndex }) =>
    leftPercentage
      ? `calc(calc(var(--container-inner-width) * ${leftPercentage}) + var(--switch-padding) * ${selectedIndex} * 2 + var(--container-padding))`
      : `calc(var(--container-padding) + var(--switch-width) * ${selectedIndex})`};
  transition: ${({ theme }) => theme.transitions.default};
  ${compose(layout)}
`;
export const StyledItem = styled.div<TabStyleProps & { itemWidth: number }>`
  height: calc(100% - 4px);
  padding: 2px 8px;
  width: ${({ itemWidth }) => `calc(${itemWidth}% - 16px)`};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ${({ theme }) => theme.transitions.default};
  ${compose(layout, space)}
`;

export const StyledItemNew = styled.div<TabStyleProps & { isOn: boolean }>`
  width: 100%;
  height: calc(100% - 4px);
  display: flex;
  justify-content: ${({ isOn }) => (isOn ? "flex-start" : "flex-end")};
  align-items: center;
  transition: ${({ theme }) => theme.transitions.default};
  ${compose(layout, space)}
`;

export const StyledSliderNew = styled.div<{
  initTop?: number;
  initLeft?: number;
  length: number;
  height?: number;
  selectedIndex: number;
}>`
  position: absolute;
  top: ${({ initTop }) => (initTop ? `${initTop}px` : "0")};
  ${({ selectedIndex, initLeft }) => {
    if (initLeft && selectedIndex === 0) {
      return `left: ${initLeft}px`;
    }
    return `left: calc(100% - 16px - ${initLeft}px)`;
  }};
  transition: ${({ theme }) => theme.transitions.default};
  ${compose(layout)}
`;

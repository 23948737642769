import { useEffect, useLayoutEffect } from "react";
import { DefaultTheme, ThemeProvider } from "styled-components";

export const useIsomorphicEffect =
  typeof window === "undefined" ? useEffect : useLayoutEffect;

export const UIKitProvider2: React.FC<
  React.PropsWithChildren<{ theme: DefaultTheme; children: React.ReactNode }>
> = ({ theme, children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

import { FlexColumn } from "@uiv2/common";
import styled from "styled-components";
import { T1, T2 } from "../Typography";

export const Wrapper = styled.div`
  width: 86px;
  height: 158px;
  > img {
    display: block;
    height: 158px;
  }
`;

export const TokenInfoContainer = styled(FlexColumn)<{
  minWidth?: number;
}>`
  flex: 1;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.radii.md};
  padding: ${({ theme }) => theme.spacing.lg};
  min-width: ${({ minWidth }) => minWidth && `${minWidth}px`};
  color: ${({ theme }) => theme.colors.global.text.T2};
  background: ${({ theme }) => theme.colors.global.background.BG2};
`;

export const Subtitle = styled(T2)`
  color: ${({ theme }) => theme.colors.global.text.T2};
`;
export const Amount = styled(T1)`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

import { ITokenTickerIconPair } from "@aperture/types";
import { FeeAmount } from "@aperture_finance/uniswap-v3-sdk";
import { IPriceRange } from "@ui/common";
import { SupportedChainId } from "@ui/utils";

export enum PriceRangeKey {
  MIN = "min",
  MAX = "max",
  SWAP_MIN = "swapMin",
  SWAP_MAX = "swapMax",
}

export interface IPriceRangeWithTick extends IPriceRange {
  tickLower: number;
  tickUpper: number;
}

export interface PositionCardProps {
  id: string;
  chainId: string;
  supportedChainId: SupportedChainId;
  positionId: string;
  tokens: ITokenTickerIconPair;
  feeTier: FeeAmount;
  liquidity: string;
  fees: string;
  priceRange: IPriceRange;
  tokenPercentages: [string, string];
  autoCompound?: Boolean;
  rebalanced?: Boolean;
  recurringRebalanced?: Boolean;
  prescheduledPosition?: Boolean;
  limitOrder?: Boolean;
  isCurrentChain: Boolean;
  isManta: Boolean;
  closed?: Boolean;
  closedInfo?: ClosedPositionInfo;
  inRange: Boolean;
  selected: Boolean;
  atStake: Boolean;
  checked?: Boolean;
  sm?: boolean;
  onClick?: (pid: string) => void;
}

export interface ClosedPositionInfo {
  exitedValueUsd: string;
  exitedCollectedIncomeUsd: string;
  dateTime: string;
}

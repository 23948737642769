import { useHistoryChatStore } from "@/components/GlobalStore";
import { useEventCallback } from "@/hooks/useEventCallback";
import { Message } from "ai/react";
import { useState } from "react";

const welcomeMessage: Message = {
  id: "welcome",
  content: "Please tell me what would you like to do?",
  role: "assistant",
};

export const useChatStore = () => {
  const { chats, setChats } = useHistoryChatStore();
  const [currentChatId, setCurrentChatId] = useState<string>(newChatId());
  const currentThreadId = chats[currentChatId]?.threadId;

  const [hideIntro, setHideIntro] = useState(false);
  const initMessages = !hideIntro ? [] : [welcomeMessage];
  const currentMessages = chats[currentChatId]?.messages ?? initMessages;

  const updateChat = useEventCallback(
    (chatId: string, messages: Message[], threadId?: string) => {
      if (currentChatId !== chatId) {
        setCurrentChatId(chatId);
      }

      const newChat = {
        ...chats[chatId],
        messages,
        date: Date.now(),
      };
      if (threadId) {
        newChat.threadId = threadId;
      }

      setHideIntro(true);

      setChats((prev) => ({
        ...prev,
        [chatId]: newChat,
      }));
    }
  );

  const deleteChat = (chatId: string) => {
    if (chatId === currentChatId) {
      setCurrentChatId(newChatId());
    }
    setChats((prev) => {
      const next = { ...prev };
      delete next[chatId];
      return next;
    });
  };

  const chatList = Object.entries(chats)
    .map(([id, chat]) => ({
      chatId: id,
      message: chat.messages?.find((m) => m.role === "user")?.content,
      date: new Date(chat.date),
    }))
    .sort((a, b) => b.date.getTime() - a.date.getTime());

  const newChat = () => {
    const chatId = newChatId();
    setCurrentChatId(chatId);
  };

  const showIntro =
    !hideIntro &&
    (currentMessages.length === 0 ||
      (currentMessages.length === 1 && currentMessages[0].role !== "user"));

  return {
    showIntro,
    currentMessages,
    currentChatId,
    currentThreadId,
    setCurrentChatId,
    chatList: chatList,
    updateChat,
    deleteChat,
    newChat,
  };
};

const newChatId = () => {
  return "chat_" + Math.random().toString().slice(2, 8);
};

import React from "react";
import styled from "styled-components";
import { AvatarProps } from "./types";

const StyledAvatar = styled.div<{ size: number }>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  img {
    transform: scaleX(-1);
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};
    border-radius: ${({ theme }) => theme.radii.circle};
    background-color: #d7cf15;
  }
`;

export const Avatar: React.FC<AvatarProps> = ({
  text,
  size = 32,
  ...props
}) => {
  const src = `https://robohash.org/${text}.png`;
  return (
    <StyledAvatar size={size} {...props}>
      <img src={src} alt="avatar" />
    </StyledAvatar>
  );
};

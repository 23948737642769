import usePrevious from "@ui/hooks/usePrevious";
import { BrushBehavior, brushX, D3BrushEvent, ScaleLinear, select } from "d3";
import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { brushHandleAccentPath, brushHandlePath } from "./svg";
import { SingleBrushProps } from "./types";

const Handle = styled.path<{ color: string }>`
  cursor: ew-resize;
  pointer-events: none;
  strokewidth: 3;
  stroke: ${({ color }) => color};
  fill: ${({ color }) => color};
`;

const HandleAccent = styled.path`
  cursor: ew-resize;
  pointer-events: none;
  strokewidth: 1.5;
  stroke: ${({ theme }) => theme.colors.global.background.BG1};
  fill: ${({ theme }) => theme.colors.global.background.BG1};
`;

const LabelGroup = styled.g<{ visible: boolean }>`
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  transition: opacity 300ms;
`;

const TooltipBackground = styled.rect`
  fill: ${({ theme }) => theme.colors.grey.lightGrey1};
  stroke: ${({ theme }) => theme.colors.grey.grey};
`;

const Tooltip = styled.text`
  text-anchor: middle;
  font-size: 14px;
  fill: ${({ theme }) => theme.colors.primary.greyPurple};
`;

// margin to prevent tick snapping from putting the brush off screen
const BRUSH_EXTENT_MARGIN_PX = 2;

/**
 * Returns true if every element in `a` maps to the
 * same pixel coordinate as elements in `b`
 */
const compare = (
  a: [number, number],
  b: [number, number],
  xScale: ScaleLinear<number, number>
): boolean => {
  // normalize pixels to 1 decimals
  const aNorm = a.map((x) => Math.round(xScale(x) * 10));
  const bNorm = b.map((x) => Math.round(xScale(x) * 10));
  return aNorm.every((v, i) => v === bNorm[i]);
};

export const SingleBrush = ({
  id,
  xScale,
  interactive,
  brushLabelValue,
  brushExtent,
  setBrushExtent,
  innerWidth,
  innerHeight,
  handleColor,
  brushMinValue,
  brushMaxValue,
}: SingleBrushProps) => {
  const brushRef = useRef<SVGGElement | null>(null);
  const brushBehavior = useRef<BrushBehavior<SVGGElement> | null>(null);

  // only used to drag the handles on brush for performance
  const [localBrushExtent, setLocalBrushExtent] = useState<
    [number, number] | null
  >(brushExtent);
  const previousBrushExtent = usePrevious(brushExtent);
  const [showLabels, setShowLabels] = useState(false);
  const [hovering, setHovering] = useState(false);

  const brushed = useCallback(
    (event: D3BrushEvent<unknown>) => {
      const { type, selection, mode, sourceEvent } = event;

      // if selection is null, custom the scaled area based on layerX
      const scaled = (
        selection
          ? (selection as [number, number])
          : [sourceEvent?.layerX - 20, sourceEvent?.layerX + 20]
      ).map(xScale.invert) as [number, number];

      if (brushMinValue !== undefined && scaled[1] < brushMinValue) {
        // avoid moving handle beyond the left side of min value
        scaled[1] = brushMinValue;
      } else if (brushMaxValue !== undefined && scaled[1] > brushMaxValue) {
        // avoid moving handle beyond the right side of max value
        scaled[1] = brushMaxValue;
        select(brushRef.current)
          .selectAll(".selection")
          .attr("x", xScale(scaled[0]))
          .attr("width", xScale(brushMaxValue) - xScale(scaled[0]));
        select(brushRef.current)
          .selectAll(".handle--e")
          .attr("x", xScale(scaled[1]));
      }

      // avoid infinite render loop by checking for change
      if (type === "end" && !compare(brushExtent, scaled, xScale)) {
        setBrushExtent(scaled, mode);
      }

      setLocalBrushExtent(scaled);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [xScale, brushExtent, setBrushExtent]
  );

  // keep local and external brush extent in sync
  // i.e. snap to ticks on brush end
  useEffect(() => {
    setLocalBrushExtent(brushExtent);
  }, [brushExtent]);

  // initialize the brush
  useEffect(() => {
    if (!brushRef.current) return;

    brushBehavior.current = brushX<SVGGElement>()
      .extent([
        [
          Math.max(
            0 + BRUSH_EXTENT_MARGIN_PX,
            brushMinValue === undefined ? 0 : xScale(brushMinValue)
          ),
          0,
        ],
        [
          Math.max(
            innerWidth - BRUSH_EXTENT_MARGIN_PX,
            brushMaxValue === undefined ? 100 : xScale(brushMaxValue)
          ),
          innerHeight,
        ],
      ])
      .handleSize(30)
      .filter((event) => {
        // when interactive, allow to drag brush only, the selection area and overlay should not be dragged
        return interactive && event.target.classList.contains("handle--e");
      })
      .on("brush end", brushed);

    brushBehavior.current(select(brushRef.current));

    if (
      previousBrushExtent &&
      compare(brushExtent, previousBrushExtent, xScale)
    ) {
      select(brushRef.current)
        .transition()
        .call(brushBehavior.current.move as any, brushExtent.map(xScale));
    }

    // brush linear gradient
    select(brushRef.current)
      .selectAll(".selection")
      .attr("stroke", "none")
      .attr("fillOpacity", "0.1")
      .attr("fill", `url(#${id}-gradient-selection)`);

    // allow to drag brush only, the selection area and overlay should not be dragged
    select(brushRef.current)
      .selectAll(".overlay,.selection,.handle--w")
      .attr("cursor", "auto");
  }, [
    brushExtent,
    brushed,
    id,
    innerHeight,
    innerWidth,
    interactive,
    previousBrushExtent,
    xScale,
    brushMinValue,
    brushMaxValue,
  ]);

  // respond to xScale changes only
  useEffect(() => {
    if (!brushRef.current || !brushBehavior.current) return;

    brushBehavior.current.move(
      select(brushRef.current) as any,
      brushExtent.map(xScale) as any
    );
  }, [brushExtent, xScale]);

  // show labels when local brush changes
  useEffect(() => {
    setShowLabels(true);
    const timeout = setTimeout(() => setShowLabels(false), 1500);
    return () => clearTimeout(timeout);
  }, [localBrushExtent]);

  return (
    <>
      <defs>
        <linearGradient
          id={`${id}-gradient-selection`}
          x1="0%"
          y1="100%"
          x2="100%"
          y2="100%"
        >
          <stop stopColor={handleColor} />
        </linearGradient>

        {/* clips at exactly the svg area */}
        <clipPath id={`${id}-brush-clip`}>
          <rect x="0" y="0" width={innerWidth} height={innerHeight} />
        </clipPath>
      </defs>

      {/* will host the d3 brush */}
      <g
        ref={brushRef}
        clipPath={`url(#${id}-brush-clip)`}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      />

      {/* custom brush handles */}
      {localBrushExtent && (
        <g
          transform={`translate(${Math.max(
            0,
            xScale(localBrushExtent[1])
          )}, 0), scale(-1, 1)`}
        >
          <g>
            <Handle color={handleColor} d={brushHandlePath(innerHeight)} />
            <HandleAccent
              d={brushHandleAccentPath(innerHeight)}
              transform={`translate(4,0), scale(1, 1)`}
            />
            <HandleAccent
              d={brushHandleAccentPath(innerHeight)}
              transform={`translate(-3,0), scale(-1, 1)`}
            />
          </g>

          <LabelGroup
            transform={`translate(50,0), scale(1, 1)`}
            visible={showLabels || hovering}
          >
            <TooltipBackground
              y={innerHeight / 2 - 14}
              x="-32"
              height="30"
              width="90"
              rx="8"
            />
            <Tooltip
              x="-12"
              y={innerHeight / 2 + 2}
              transform="scale(-1, 1)"
              dominantBaseline="middle"
            >
              {brushLabelValue("w", localBrushExtent[1])}
            </Tooltip>
          </LabelGroup>

          <LabelGroup
            transform={`translate(-50,0), scale(1, 1)`}
            visible={showLabels || hovering}
          >
            <TooltipBackground
              y={innerHeight / 2 - 14}
              x="-57"
              height="30"
              width="90"
              rx="8"
            />
            <Tooltip
              x="12"
              y={innerHeight / 2 + 2}
              transform="scale(-1, 1)"
              dominantBaseline="middle"
            >
              {brushLabelValue("e", localBrushExtent[1])}
            </Tooltip>
          </LabelGroup>
        </g>
      )}
    </>
  );
};

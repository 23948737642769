import { useIsomorphicEffect } from "@uiv2/Providers";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import {
  IDefaultPosition,
  IDefaultSize,
  IPopoverProps,
  IPopoverRef,
} from "./types";

const PopoverWrapper = styled.span`
  display: inline-block;
  position: relative;
  cursor: pointer;
`;
const AnchorContainer = styled.div`
  width: max-content;
  > * {
    display: block;
  }
`;
const ContentContainer = styled.div<{
  left: number;
  bottom: number;
  defaultPosition: IDefaultPosition;
  defaultSize: IDefaultSize;
  background: string;
  noBorder: boolean;
}>`
  position: absolute;
  padding: 8px;
  box-sizing: border-box;
  width: max-content;
  text-align: center;
  min-width: fit-content;
  max-width: ${({ defaultSize }) =>
    defaultSize?.width ? `${defaultSize.width}px` : "292px"};
  overflow: hidden;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  cursor: auto;
  background: ${({ theme, background }) => {
    if (background === "default") {
      return theme.colors.global.background.BG2;
    } else if (background === "transparent") {
      return theme.colors.transparent.transparent;
    } else return background;
  }};
  color: ${({ theme }) => theme.colors.global.text.T1};
  border-radius: ${({ theme, noBorder }) => (noBorder ? "0" : theme.radii.md)};
  box-shadow: ${({ theme, noBorder }) =>
    noBorder ? "none" : theme.shadows.medium};
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  top: ${({ defaultPosition }) =>
    defaultPosition?.top ? `${defaultPosition.top}px` : null};
  bottom: ${({ defaultPosition, bottom }) =>
    defaultPosition
      ? defaultPosition.bottom
        ? `${defaultPosition.bottom}px`
        : null
      : `${bottom}px`};
  left: ${({ defaultPosition, left }) =>
    defaultPosition
      ? defaultPosition.left
        ? `${defaultPosition.left}px`
        : null
      : `${left * -1}px`};
  right: ${({ defaultPosition }) =>
    defaultPosition?.right !== undefined ? `${defaultPosition.right}px` : null};
  transition: top 0s, left 0s, right 0s, bottom 0s !important;
`;

export const Popover = forwardRef<
  IPopoverRef,
  React.PropsWithChildren<IPopoverProps>
>(
  (
    {
      content = "",
      children,
      defaultPosition,
      defaultSize,
      disabled = false,
      contentStyles,
      noBorder = false,
      background = "default",
      onDisappeared,
      onOpen,
      ...props
    },
    ref
  ) => {
    const [showPopover, setShowPopover] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const [left, setLeft] = useState(0);
    const [bottom, setBottom] = useState(8);

    useIsomorphicEffect(() => {
      function updatePosition() {
        if (anchorRef.current && contentRef.current) {
          setLeft(
            (contentRef.current.offsetWidth - anchorRef.current.offsetWidth) / 2
          );
          setBottom(anchorRef.current.offsetHeight + 8);
        }
      }
      window.addEventListener("resize", updatePosition);
      updatePosition();
      return () => window.removeEventListener("resize", updatePosition);
    }, [showPopover]);

    const timerRef = useRef<NodeJS.Timeout>();
    const togglePopover = (show: boolean) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      if (show) {
        setShowPopover(true);
      } else {
        timerRef.current = setTimeout(() => {
          setShowPopover(false);
        }, 200);
      }
    };

    useEffect(() => {
      if (showPopover && contentRef?.current) onOpen && onOpen(true);
      if (!showPopover && !contentRef?.current) {
        onOpen && onOpen(false);
        onDisappeared && onDisappeared();
      }
    }, [showPopover]);

    useImperativeHandle(ref, () => ({
      onClose: () => setShowPopover(false),
      onShow: () => setShowPopover(true),
    }));

    return (
      <PopoverWrapper
        {...props}
        onMouseEnter={() => togglePopover(!disabled)}
        onMouseLeave={() => togglePopover(false)}
      >
        <AnchorContainer ref={anchorRef}>{children}</AnchorContainer>
        {showPopover && content && (
          <ContentContainer
            ref={contentRef}
            defaultPosition={defaultPosition}
            defaultSize={defaultSize}
            left={left}
            bottom={bottom}
            style={{ ...contentStyles }}
            onMouseEnter={() => togglePopover(true)}
            onMouseLeave={() => togglePopover(false)}
            background={background}
            noBorder={noBorder}
            onClick={(e) => {
              e.stopPropagation();
              togglePopover(false);
            }}
          >
            {content}
          </ContentContainer>
        )}
      </PopoverWrapper>
    );
  }
);

import Big from "big.js";
import bigDecimal from "js-big-decimal";
import {
  BasicInfo,
  Calculator_AMM,
  ChainList,
  NetworkInfo,
  SupportedChainId,
} from "./types";

export const CALC_AMM_CHAINS: Record<Calculator_AMM, SupportedChainId[]> = {
  Uniswap: [
    SupportedChainId.ETHEREUM,
    SupportedChainId.ARBITRUM,
    SupportedChainId.OPTIMISM,
    SupportedChainId.POLYGON,
    SupportedChainId.BASE,
    SupportedChainId.BNB,
    SupportedChainId.AVALANCHE,
    SupportedChainId.SCROLL,
  ],
  PancakeSwap: [SupportedChainId.ETHEREUM, SupportedChainId.BNB],
};

export const getChainInfo = (chainId: SupportedChainId): NetworkInfo => {
  const chain = ChainList.find((f) => f.id === chainId);
  return chain ?? ChainList[0];
};

export const getNetworksList = (amm: BasicInfo) =>
  ChainList.reduce<NetworkInfo[]>((acc, chain) => {
    if (chain.amm.map((platform) => platform.name).includes(amm.name)) {
      acc.push(chain);
    }
    return acc;
  }, []);

export function changeByPercentage(
  price: string,
  increase: boolean,
  percentage: number
) {
  const fraction = bigDecimal.multiply(
    bigDecimal.divide(price, 100, 6),
    percentage
  );
  const incrementFunc =
    (parseFloat(price) < 0 && increase) || (parseFloat(price) > 0 && !increase)
      ? bigDecimal.subtract
      : bigDecimal.add;
  return bigDecimal.round(incrementFunc(price, fraction), 6);
}

export const timestampFormat = (
  timestamp: number,
  showSecond?: boolean,
  hour12?: boolean
) => {
  const date = new Date(timestamp * 1e3);
  const dateFormat = date
    .toLocaleTimeString("en-US", {
      month: "short",
      year: "numeric",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: showSecond ? "2-digit" : undefined,
      hour12: hour12 ?? true,
    })
    .split(", ");
  const time = (dateFormat[2] ?? "-").split(":").join(":").toLowerCase();

  return { date: `${dateFormat[0]}, ${dateFormat[1]}`, time };
};

export const sqrtX96ToPrice = (
  sqrtPriceX96: string,
  token0Decimal: number,
  token1Decimal: number
) => {
  return sqrtPriceX96
    ? bigDecimal.divide(
        bigDecimal.multiply(
          bigDecimal.multiply(sqrtPriceX96, sqrtPriceX96),
          new Big(10).pow(token0Decimal - token1Decimal)
        ),
        0xffffffffffffffffffffffffffffffffffffffffffffffff, // 2^192
        18
      )
    : undefined;
};

export function shortenAddress(
  address = "",
  startCharts: number,
  endCharts: number
) {
  if (address.length < startCharts + endCharts) {
    return address;
  }
  return `${address.slice(0, startCharts)}...${address.slice(-endCharts)}`;
}

import React from "react";
import styled from "styled-components";
import { compose, layout, space, typography } from "styled-system";
import { UITypographyProps } from "./types";

const StyledButton1 = styled.div<UITypographyProps>`
  font-weight: 500;
  font-size: 24px;
  ${compose(layout, space, typography)}
`;
export const Button1: React.FC<React.PropsWithChildren<UITypographyProps>> = ({
  children,
  ...props
}) => <StyledButton1 {...props}>{children}</StyledButton1>;

const StyledButton2 = styled.div<UITypographyProps>`
  font-weight: 500;
  font-size: 18px;
  ${compose(layout, space, typography)}
`;
export const Button2: React.FC<React.PropsWithChildren<UITypographyProps>> = ({
  children,
  ...props
}) => <StyledButton2 {...props}>{children}</StyledButton2>;

const StyledButton3 = styled.div<UITypographyProps>`
  font-weight: 500;
  font-size: 16px;
  ${compose(layout, space, typography)}
`;
export const Button3: React.FC<React.PropsWithChildren<UITypographyProps>> = ({
  children,
  ...props
}) => <StyledButton3 {...props}>{children}</StyledButton3>;

const StyledButton4 = styled.div<UITypographyProps>`
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  ${compose(layout, space, typography)}
`;
export const Button4: React.FC<React.PropsWithChildren<UITypographyProps>> = ({
  children,
  ...props
}) => <StyledButton4 {...props}>{children}</StyledButton4>;

const StyledButton5 = styled.div<UITypographyProps>`
  font-weight: 500;
  font-size: 14px;
  ${compose(layout, space, typography)}
`;
export const Button5: React.FC<React.PropsWithChildren<UITypographyProps>> = ({
  children,
  ...props
}) => <StyledButton5 {...props}>{children}</StyledButton5>;

const StyledButton6 = styled.div<UITypographyProps>`
  font-size: 13px;
  line-height: 13px;
  ${compose(layout, space, typography)}
`;
export const Button6: React.FC<React.PropsWithChildren<UITypographyProps>> = ({
  children,
  ...props
}) => <StyledButton6 {...props}>{children}</StyledButton6>;

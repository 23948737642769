import { H2, T2, T3Regular, T4 } from "@uiv2/components/Typography";
import styled from "styled-components";

export const FeeTierContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing.lg};
  width: 100%;
`;

export const FeeTierItem = styled.div<{
  selected: boolean;
  disabled: boolean;
  notCreated: boolean;
  height?: number;
}>`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.lg};

  padding: ${({ theme }) => theme.spacing.lg};
  height: ${({ height }) => (height ? `${height}px` : "auto")};
  background: ${({ theme }) => theme.colors.global.background.BG1};

  cursor: ${({ disabled }) => (disabled ? "no-drop" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  border-radius: ${({ theme }) => theme.radii.md};
  border: 1px solid
    ${({ theme: { colors }, selected, notCreated }) =>
      selected && notCreated
        ? colors.errorWarning.orange
        : selected
        ? colors.global.text.T1
        : colors.global.line.Line1};
  ${({ theme: { colors }, disabled }) =>
    disabled && `color: ${colors.global.text.T2};`};

  &:hover {
    border: 1px solid
      ${({ theme: { colors }, selected, notCreated, disabled }) =>
        selected && notCreated
          ? colors.errorWarning.orange
          : selected
          ? colors.global.text.T1
          : disabled
          ? colors.global.line.Line1
          : colors.global.line.Line2};
    transition: all ${({ theme }) => theme.transitions.default};
  }
`;

export const Info = styled(H2)<{ disabled?: boolean }>`
  color: ${({ theme, disabled }) =>
    theme.colors.global.text[disabled ? "T3" : "T1"]};
`;

export const InfoSelect = styled(T4)`
  padding-block: 6px;
  text-align: center;
  border-radius: 6px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.global.text.T2};
  background: ${({ theme }) => theme.colors.global.background.BG2};
`;

export const Description = styled(T3Regular)<{ disabled?: boolean }>`
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.global.text.T3 : theme.colors.global.success};
  text-transform: capitalize;
`;

export const Tips = styled(T2)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  width: 100%;
  min-height: 72px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.global.text.T2};
`;

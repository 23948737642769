import { NetErrorIcon } from "@aperture/assetkit";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { OnClickProps } from "../types";

const Wrapper = styled.div`
  .selected {
    box-shadow: ${({ theme }) => theme.shadows.mediumPurple};
    &::after {
      opacity: 1 !important;
    }
  }
`;
const StyledWrongNetwork = styled.div`
  height: 40px;
  width: 40px;
  border-radius: ${({ theme }) => theme.radii.round};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.global.error};
  cursor: pointer;
`;

export const WrongNetworkButton: React.FC<OnClickProps> = ({
  isLoading = false,
  onClick,
  ...props
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    const modal = document.getElementById("wrong-network-modal-show");
    if (modal && buttonRef.current) {
      buttonRef.current.classList.add("selected");
    } else if (buttonRef.current) {
      buttonRef.current.classList.remove("selected");
    }
  });

  return (
    <Wrapper>
      <StyledWrongNetwork onClick={onClick} {...props}>
        <NetErrorIcon width={"22px"} height={"19px"} />
      </StyledWrongNetwork>
    </Wrapper>
  );
};

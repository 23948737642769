export function timestampFormat(timestamp: number) {
  const date = new Date(timestamp * 1e3);
  const dateFormat = date
    .toLocaleTimeString("en-US", {
      month: "short",
      year: "numeric",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "numeric",
      hour12: true,
    })
    .split(", ");

  return `${dateFormat[2]}, ${dateFormat[0]}, ${dateFormat[1]}`;
}

import { isProdEnv } from "@/utils/helper";
import { restrictedCountries } from "@ui/components";
import { useRouter } from "next/router";
import useSWR from "swr";

interface ILocationData {
  status: string;
  country: string;
  countryCode: string;
  region: string;
  regionName: string;
  city: string;
  zip: string;
  lat: number;
  lon: number;
  timezone: string;
  isp: string;
  org: string;
  as: string;
  query: string;
}

const ProxyURL = "https://ip-api.aperture.finance/json";
const URL = "https://pro.ip-api.com/json?key=va5cUWE0XAnBETa";

export type IPRestriction = {
  data: ILocationData;
  isRestricted: boolean;
};

export const useUserIPRestriction = () => {
  const router = useRouter();
  const showFetch =
    router.pathname.startsWith("/stake") ||
    router.pathname.startsWith("/claim");
  return useSWR(showFetch ? `user-ip-info` : null, () => getLocationData());
};

const getLocationData = async () => {
  try {
    return await getIpInfo(ProxyURL);
  } catch (error) {
    return await getIpInfo(URL);
  }
};

async function getIpInfo(server: string): Promise<IPRestriction> {
  const res = await fetch(server);
  const data: ILocationData = await res.json();

  if (data.status === "success") {
    const isRestricted = Object.values(restrictedCountries).includes(
      data.countryCode
    );
    return {
      data,
      isRestricted: isRestricted && isProdEnv(),
    };
  } else throw Error(data.status ?? "IP Restriction Error.");
}

import { FeeAmount } from "@aperture_finance/uniswap-v3-sdk";
import { Flex, FlexColumn } from "@ui/common";
import { AmmEnum, formatTokenAmount } from "@ui/utils";
import bigDecimal from "js-big-decimal";
import React, { useEffect, useRef, useState } from "react";
import { Dropdown, IDropdownRef } from "../Dropdown";
import { FeeTierPercentage } from "../FeeTier";
import { PercentageInputGroup } from "../Input";
import { QuestionPopover } from "../Popover";
import { ButtonText, Headline5, Subtitle2 } from "../Typography";
import {
  ArrowBtn,
  ArrowDown,
  ButtonContainer,
  Container,
  Content,
  DropdownArrow,
  DropdownContent,
  DropdownItem,
  DropdownList,
  ExpireWarning,
  FeeTierText,
  IconWrapper,
  InputWrapper,
  Revenue,
  SelectorWrapper,
  StyledInput,
  StyledText,
  StyledWarningIcon,
  Title,
  TitleWithQuestion,
} from "./style";
import { IAdvancedSettingProps } from "./types";

const RANGE_WIDTH_COUNT = 50;
const DEFAULT_FEE_TIER: Record<AmmEnum, FeeAmount> = {
  UNISWAP_V3: FeeAmount.MEDIUM,
  PANCAKESWAP_V3: FeeAmount.PCS_V3_MEDIUM,
  SLIPSTREAM: FeeAmount.MEDIUM,
};

export const AdvancedSetting: React.FC<IAdvancedSettingProps> = ({
  amm,
  sellToken,
  sellAmount,
  feeTier,
  defaultFeeTier,
  defaultRevenue,
  supportedFeeTier,
  onFeeTierChange,
  rangeWidth,
  onRangeWidthChange,
  gasCeilingAmount,
  gasCeilingPercentage,
  onGasCeilingChange,
  onGasCeilingError,
  expireDays,
  maxExpireDays,
  expireDaysOnError,
  onExpireInput,
}) => {
  const feeTierDropdownRef = useRef<IDropdownRef>(null);
  const rangeWidthDropdownRef = useRef<IDropdownRef>(null);
  const [expandable, setExpandable] = useState(true);
  const [isShow, setIsShow] = useState(false);
  const [overflow, setOverflow] = useState(false);
  const [error, setError] = useState<boolean>(
    onGasCeilingError ? !!onGasCeilingError(gasCeilingPercentage) : false
  );
  const handleFeeTierChange = (value: FeeAmount) => {
    onFeeTierChange(value);
    feeTierDropdownRef?.current?.onClose();
  };
  const handleRangeWidthChange = (value: number) => {
    onRangeWidthChange(value);
    rangeWidthDropdownRef?.current?.onClose();
  };
  const handleError = (percentage: number) => {
    const isError = !!onGasCeilingError?.(percentage);
    setError(isError);
    if (isError) {
      setIsShow(true);
      setTimeout(function () {
        setOverflow(true);
      }, 300);
    }
  };

  const handleGasCeilingChange = (percentage: number) => {
    onGasCeilingChange(percentage);
    handleError(percentage);
  };

  useEffect(() => {
    const getFeeTierSelection = () => {
      if (feeTier && supportedFeeTier?.has(feeTier)) {
        return feeTier;
      } else if (supportedFeeTier?.has(defaultFeeTier)) {
        return defaultFeeTier;
      } else if (supportedFeeTier?.size) {
        return supportedFeeTier?.values().next().value;
      } else {
        return undefined;
      }
    };
    handleFeeTierChange(getFeeTierSelection());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportedFeeTier]);

  useEffect(() => {
    handleError(gasCeilingPercentage);
  }, [gasCeilingPercentage]);

  const feeTierBtn = (
    <ButtonContainer disabled={!supportedFeeTier?.size ?? true}>
      <ButtonText>
        {feeTier ? feeTier / FeeTierPercentage + "%" : "Select"}
      </ButtonText>
      <DropdownArrow isShow={false} />
    </ButtonContainer>
  );

  const rangeWidthBtn = (
    <ButtonContainer>
      <ButtonText>{rangeWidth ?? "Select"}</ButtonText>
      <DropdownArrow isShow={false} />
    </ButtonContainer>
  );

  return (
    <Container>
      <Title
        onClick={() => {
          if (!expandable) return;
          setIsShow(!isShow);
          setExpandable(isShow);
          setTimeout(
            function () {
              setOverflow(!isShow);
              setExpandable(true);
            },
            isShow ? 0 : 300
          );
        }}
      >
        <StyledText>Advanced Settings</StyledText>
        <ArrowBtn>
          <ArrowDown isShow={isShow} />
        </ArrowBtn>
      </Title>
      <Content
        isShow={isShow}
        useOverflow={overflow}
        gasCeilingError={error}
        expireDayError={expireDaysOnError}
      >
        <InputWrapper>
          <Title>
            <StyledText>Gas Ceiling</StyledText>
            <QuestionPopover content="Input the maximum gas fee in either dollars or percentage of position. However, the actual gas ceiling will be based on the percentage not the dollar amount (e.g. if you choose $50 which is currently 1% of your total liquidity position then we will set a gas ceiling of 1%)." />
          </Title>
          <PercentageInputGroup
            amount={gasCeilingAmount}
            percentage={gasCeilingPercentage}
            onChange={handleGasCeilingChange}
            onError={onGasCeilingError}
          />
        </InputWrapper>
        <SelectorWrapper>
          <InputWrapper>
            <Title>
              <StyledText>Select Fee Tier</StyledText>
              <QuestionPopover
                content={`The default fee tier for any limit order is ${
                  DEFAULT_FEE_TIER[amm] / 10000
                }%. You can select another fee tier of your preference.`}
              />
            </Title>
            <Dropdown
              ref={feeTierDropdownRef}
              disabled={!supportedFeeTier?.size ?? true}
              button={feeTierBtn}
              position="bottomRight"
            >
              <DropdownContent>
                <DropdownList>
                  {(supportedFeeTier ? Array.from(supportedFeeTier) : []).map(
                    (feeAmount) => (
                      <DropdownItem
                        key={feeAmount}
                        onClick={() => {
                          handleFeeTierChange(feeAmount);
                        }}
                      >
                        <FeeTierText>
                          {feeAmount / FeeTierPercentage + "%"}
                        </FeeTierText>
                      </DropdownItem>
                    )
                  )}
                </DropdownList>
              </DropdownContent>
            </Dropdown>
          </InputWrapper>
          <InputWrapper>
            <Title justifyContent="flex-end">
              <StyledText>Select Range Width</StyledText>
              <QuestionPopover content="Only an uneven quantity of tick widths is accommodated for limit orders. Enlarging this width could potentially push the average price even farther from the current market price, in order to preserve the entire range." />
            </Title>
            <Dropdown
              ref={rangeWidthDropdownRef}
              button={rangeWidthBtn}
              position="bottomLeft"
            >
              <DropdownContent>
                <DropdownList>
                  {Array.from(
                    { length: RANGE_WIDTH_COUNT },
                    (e, i) => i * 2 + 1
                  ).map((range) => (
                    <DropdownItem
                      key={range}
                      onClick={() => {
                        handleRangeWidthChange(range);
                      }}
                    >
                      <Headline5>{range}</Headline5>
                    </DropdownItem>
                  ))}
                </DropdownList>
              </DropdownContent>
            </Dropdown>
          </InputWrapper>
        </SelectorWrapper>
        <Flex gap="xl">
          <FlexColumn gap={12}>
            <TitleWithQuestion>
              <StyledText>Min. Fee Revenue To Earn</StyledText>
              <QuestionPopover
                content={`Limit orders occur in the default ${
                  defaultFeeTier / 10000
                }% fee tier pool. Minimum fee revenue is thus ${
                  defaultFeeTier / 10000
                }% of the order size.
Actual fee revenue could be higher depending on trading volatility and size of order.`}
              />
            </TitleWithQuestion>
            <Revenue>
              {sellToken && <IconWrapper>{sellToken.Icon}</IconWrapper>}
              <Subtitle2>{sellToken?.ticker || "-"}</Subtitle2>
              <Subtitle2>
                {sellAmount
                  ? formatTokenAmount(
                      bigDecimal.multiply(
                        feeTier ? feeTier / 1e6 : defaultRevenue,
                        sellAmount
                      )
                    )
                  : "-"}
              </Subtitle2>
            </Revenue>
          </FlexColumn>
          <FlexColumn gap={12}>
            <Title justifyContent="flex-end">
              <StyledText>Expires In</StyledText>
            </Title>
            <StyledInput
              align="right"
              onError={(value: string) => Number(value) > maxExpireDays}
              placeholder="30 Days"
              type="number"
              //@ts-ignore
              value={expireDays}
              onChange={onExpireInput}
            />
          </FlexColumn>
        </Flex>
        {expireDaysOnError && (
          <ExpireWarning>
            <StyledWarningIcon />
            Exceed Maximum Days
          </ExpireWarning>
        )}
      </Content>
    </Container>
  );
};

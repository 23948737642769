import { FlexColumn } from "@uiv2/common";
import { Tab } from "@uiv2/components/Tab";
import { H2 } from "@uiv2/components/Typography";
import styled from "styled-components";

export const Container = styled(FlexColumn)`
  width: 368px;
  padding: 16px;
  gap: ${({ theme }) => theme.spacing.xl};
  border-radius: ${({ theme }) => theme.radii.xl};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
`;
export const Heading = styled(H2)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const StyledTab = styled(Tab)`
  height: 18px;
  padding: 4px;
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
  }
`;

import { ApertureTheme } from "@aperture/uikitv2";
import React, { ButtonHTMLAttributes, CSSProperties } from "react";
import { ButtonStyleProps } from "styled-system";

export const variants = {
  default: "default",
  outlined: "outlined",
  primary: "primary",
  secondary: "secondary",
  text: "text",
  error: "error",
} as const;

export const sizes = {
  xl: "xl",
  lg: "lg",
  md: "md",
  sm: "sm",
  xs: "xs",
} as const;

export type Variant = (typeof variants)[keyof typeof variants];
export type Size = (typeof sizes)[keyof typeof sizes];
export type IconPosition = "start" | "end";

export interface ButtonProps
  extends ButtonStyleProps,
    Partial<ButtonHTMLAttributes<HTMLButtonElement>> {
  disabled?: boolean;
  variant?: Variant;
  size?: Size;
  icon?: React.ReactNode;
  iconGap?: number;
  iconPosition?: IconPosition;
  width?: CSSProperties["width"];
  onClick?: (() => {}) | (() => void);
}

export interface ThemedProps extends ButtonProps {
  theme: ApertureTheme;
}

import { StyledSlider, StyledThumb, StyledTrack } from "./style";
import { SliderProps } from "./types";

export const Slider: React.FC<SliderProps> = ({
  value,
  min,
  max,
  onChange,
}) => {
  let sliderValue;
  if (typeof value === "object") {
    const valueArray = [];
    valueArray[0] = isNaN(value[0]) ? 0 : value[0];
    valueArray[1] = isNaN(value[1]) ? 0 : value[1];
    sliderValue = valueArray;
  } else {
    sliderValue = isNaN(value) ? 0 : value;
  }

  return (
    <StyledSlider
      renderTrack={(props: any) => <StyledTrack {...props} key={props.key} />}
      renderThumb={(props: any) => (
        <StyledThumb {...props} value={value} key={props.key} />
      )}
      min={isNaN(min) ? 0 : min}
      max={isNaN(max) ? 0 : max}
      onChange={onChange}
      value={sliderValue}
      step={0.000001}
    />
  );
};

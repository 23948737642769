import styled from "styled-components";

export const DropdownBox = styled.div<{
  bgImg: string;
  isSubMenuOpen: boolean;
}>`
  width: 318px;
  height: 250px;
  flex-shrink: 0;
  border-radius: ${({ isSubMenuOpen }) =>
    isSubMenuOpen ? "10px 0 0 10px" : "10px"};
  color: ${({ theme }) => theme.colors.global.text.T1};
  background: ${({ theme }) => theme.colors.global.background.BG3};
  position: relative;
  pointer-events: auto;
  visibility: visible;
  z-index: ${({ theme }) => theme.zIndices.dropdown};

  ::before {
    content: "";
    position: absolute;
    border-radius: 10px;
    background: url(${({ bgImg }) => bgImg});
    filter: blur(0px);
    background-repeat: no-repeat;
    background-size: cover;
    width: 174px;
    height: 206px;
    left: 0;
    bottom: 0;
    z-index: -1;
  }

  ::after {
    display: block;
    position: absolute;
    top: -10px;
    right: 149px;
    width: 20px;
    height: 12px;
    pointer-events: none;
    content: "";
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
    background: ${({ theme }) => theme.colors.global.background.BG3};
  }
`;

export const Title = styled.div`
  font-family: "Dunbar Tall";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-transform: uppercase;
  padding: 8px;
  height: 100%;
`;

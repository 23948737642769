import {
  FlexColumn,
  ITriggerAction,
  RebalanceActionTab,
  StrategySelector,
} from "@aperture/uikit";
import {
  ActionTypeEnum,
  RecurringAction,
} from "@aperture_finance/uniswap-v3-automation-sdk";
import { useEventCallback } from "@mui/material";
import bigDecimal from "js-big-decimal";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SwitchMenuTab } from "../Tabs";
import {
  convertActionToClient,
  getHighPrice,
  getLowPrice,
  getRebalanceActionTab,
  offsetTickToPercentage,
  percentageToOffsetTick,
} from "./utils";

const RebalanceActionContainer = styled(FlexColumn)`
  box-sizing: border-box;
  position: relative;
  transition: all ${({ theme }) => theme.transitions.default};
`;
const StyledTokenTab = styled(SwitchMenuTab)`
  position: absolute;
  right: 0;
`;

export const TriggerAction: React.FC<ITriggerAction> = ({
  tickSpacing,
  tokens,
  tokenMarketPrice,
  defaultAction,
  triggerAction,
  setTriggerAction,
}) => {
  const [enableGraph, setEnableGraph] = useState<boolean>(false);
  const tokenTickers = tokens.map((token) => token.ticker);
  const [selectedToken, setSelectedToken] = useState<number>(
    triggerAction && triggerAction.type === ActionTypeEnum.Values.RecurringPrice
      ? triggerAction.baseToken
      : 0
  );
  const [rebalanceActionTab, setRebalanceActionTab] =
    useState<RebalanceActionTab>(
      triggerAction
        ? getRebalanceActionTab(triggerAction.type)
        : RebalanceActionTab.PERCENTAGES
    );

  const formatTriggerAction = (
    value: string,
    tab?: RebalanceActionTab,
    token?: number
  ) => {
    if (!tab) tab = rebalanceActionTab;
    if (token === undefined) token = selectedToken;

    if (tab === RebalanceActionTab.TOKEN_TERMS) {
      const decimals = tokens[1 - token].decimals;
      let result = bigDecimal
        .round(value, decimals)
        // Remove trailing zeros
        .replace(/\.?0+$/, "");
      const float = result.split(".")[1];
      if (!float) {
        result += ".00";
      } else if (float.length === 1) {
        result += "0";
      }
      return result;
    } else {
      return bigDecimal.round(value, 2);
    }
  };

  const [lowPrice, setLowPrice] = useState<string>(
    triggerAction
      ? getLowPrice(triggerAction)
      : formatTriggerAction(defaultAction.percentLower)
  );
  const [highPrice, setHighPrice] = useState<string>(
    triggerAction
      ? getHighPrice(triggerAction)
      : formatTriggerAction(defaultAction.percentUpper)
  );
  const [tickWidth, setTickWidth] = useState<string>(
    triggerAction && triggerAction.type === ActionTypeEnum.Values.RecurringRatio
      ? triggerAction.tickRangeWidth.toString()
      : defaultAction.rangeWidth
  );

  const updateTriggerAction = useEventCallback(() => {
    setTriggerAction(
      (prev) =>
        ({
          ...convertActionToClient(
            selectedToken,
            lowPrice,
            highPrice,
            tickWidth,
            rebalanceActionTab
          ),
          ..._.pick(prev, ["slippage", "maxGasProportion", "strategyId"]),
        } as RecurringAction)
    );
  });

  // update trigger action when state changes
  // this will also initialize triggerAction when isCreate
  useEffect(() => {
    updateTriggerAction();
  }, [
    selectedToken,
    lowPrice,
    highPrice,
    tickWidth,
    tickSpacing,
    rebalanceActionTab,
    updateTriggerAction,
  ]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setEnableGraph(true);
    }, 1000);
    return () => {
      clearTimeout(timeId);
    };
  }, []);

  const handleLeftRangeInput = (val: string, clickPlusMinus?: boolean) => {
    if (!enableGraph) return;
    val = formatTriggerAction(val);
    if (
      !clickPlusMinus &&
      rebalanceActionTab !== RebalanceActionTab.RATIO &&
      !val.startsWith("-")
    )
      val = "-" + val;
    switch (rebalanceActionTab) {
      case RebalanceActionTab.PERCENTAGES:
        val =
          bigDecimal.compareTo(val, -100) < 0
            ? "-100.00"
            : bigDecimal.compareTo(val, 0) > 0
            ? "0.00"
            : val;
        setLowPrice(val);
        break;
      case RebalanceActionTab.TOKEN_TERMS:
        val = bigDecimal.compareTo(val, 0) > 0 ? "0.00" : val;
        setLowPrice(val);
        break;
      case RebalanceActionTab.RATIO:
        val =
          bigDecimal.compareTo(val, 0) < 0
            ? "0.00"
            : bigDecimal.compareTo(val, 100) > 0
            ? "100.00"
            : val;
        setLowPrice(val);
        setHighPrice(bigDecimal.round(bigDecimal.subtract(100, val), 2));
        break;
    }
  };
  const handleRightRangeInput = (val: string, clickPlusMinus?: boolean) => {
    if (!enableGraph) return;
    val = formatTriggerAction(val);
    if (
      !clickPlusMinus &&
      rebalanceActionTab !== RebalanceActionTab.RATIO &&
      val.startsWith("-")
    )
      val = val.substring(1);
    switch (rebalanceActionTab) {
      case RebalanceActionTab.PERCENTAGES:
      case RebalanceActionTab.TOKEN_TERMS:
        val = bigDecimal.compareTo(val, 0) < 0 ? "0.00" : val;
        setHighPrice(val);
        break;
      case RebalanceActionTab.RATIO:
        val =
          bigDecimal.compareTo(val, 0) < 0
            ? "0.00"
            : bigDecimal.compareTo(val, 100) > 0
            ? "100.00"
            : val;
        setHighPrice(val);
        setLowPrice(bigDecimal.round(bigDecimal.subtract(100, val), 2));
        break;
    }
  };
  return (
    <RebalanceActionContainer>
      <StyledTokenTab
        width={140}
        height="24px"
        menu={[tokenTickers[0], tokenTickers[1]]}
        defaultSelected={selectedToken}
        onSelect={(value: string) => {
          if (enableGraph) {
            const newToken = tokenTickers.indexOf(value);
            if (newToken !== selectedToken) {
              setSelectedToken(newToken);
              let newLow = "";
              let newHigh = "";
              switch (rebalanceActionTab) {
                case RebalanceActionTab.PERCENTAGES:
                  newLow = offsetTickToPercentage(
                    -percentageToOffsetTick(Number(highPrice), 0)
                  );
                  break;
                case RebalanceActionTab.TOKEN_TERMS:
                  newLow = formatTriggerAction(
                    bigDecimal.multiply(highPrice, -1),
                    rebalanceActionTab,
                    newToken
                  );
                  break;
                case RebalanceActionTab.RATIO:
                  newLow = highPrice;
                  break;
              }
              switch (rebalanceActionTab) {
                case RebalanceActionTab.PERCENTAGES:
                  newHigh = offsetTickToPercentage(
                    -percentageToOffsetTick(Number(lowPrice), -100, 0)
                  );
                  break;
                case RebalanceActionTab.TOKEN_TERMS:
                  newHigh = formatTriggerAction(
                    bigDecimal.multiply(lowPrice, -1),
                    rebalanceActionTab,
                    newToken
                  );
                  break;
                case RebalanceActionTab.RATIO:
                  newHigh = lowPrice;
                  break;
              }
              if (
                rebalanceActionTab === RebalanceActionTab.PERCENTAGES &&
                bigDecimal.compareTo(newLow, -100) < 0
              )
                newLow = "-100.00";
              setLowPrice(newLow);
              setHighPrice(newHigh);
            }
          }
        }}
      />
      <StrategySelector
        direction={"vertical"}
        price={Number(tokenMarketPrice.tokenA)}
        priceLower={lowPrice}
        priceUpper={highPrice}
        tickWidth={tickWidth}
        tickSpacing={tickSpacing}
        getDecrementLower={() =>
          handleLeftRangeInput(bigDecimal.subtract(lowPrice, 1), true)
        }
        getIncrementLower={() =>
          handleLeftRangeInput(bigDecimal.add(lowPrice, 1), true)
        }
        getDecrementUpper={() =>
          handleRightRangeInput(bigDecimal.subtract(highPrice, 1), true)
        }
        getIncrementUpper={() =>
          handleRightRangeInput(bigDecimal.add(highPrice, 1), true)
        }
        onLeftRangeInput={handleLeftRangeInput}
        onRightRangeInput={handleRightRangeInput}
        onTickWidthInput={setTickWidth}
        currencyA={tokenTickers[selectedToken]}
        currencyB={tokenTickers[1 - selectedToken]}
        ticksAtLimit={{ LOWER: true, UPPER: true }}
        interactive={true}
        chartHeight={180}
        showStrategyTab={true}
        strategyTabPosition={"top"}
        strategyTab={rebalanceActionTab}
        onSwitchStrategyTab={(tab) => {
          if (tab !== rebalanceActionTab) {
            switch (tab) {
              case RebalanceActionTab.PERCENTAGES:
                setLowPrice(formatTriggerAction(defaultAction.percentLower));
                setHighPrice(formatTriggerAction(defaultAction.percentUpper));
                break;
              case RebalanceActionTab.TOKEN_TERMS:
                setLowPrice(formatTriggerAction(defaultAction.priceLower));
                setHighPrice(formatTriggerAction(defaultAction.priceUpper));
                break;
              case RebalanceActionTab.RATIO:
                setLowPrice(formatTriggerAction(defaultAction.ratioLower));
                setHighPrice(formatTriggerAction(defaultAction.ratioUpper));
                break;
            }
            setRebalanceActionTab(tab);
          }
        }}
      />
    </RebalanceActionContainer>
  );
};

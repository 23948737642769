import { CaretIcon } from "@aperture/assetkit";
import { Flex } from "@ui/common";
import styled from "styled-components";
import { Button } from "../../Button";
import { Body1 } from "../../Typography";
import { Input } from "../Input";

export const SwapContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
`;

export const SwapWrapper = styled.div<{ noBorder: boolean }>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: ${({ noBorder }) => (noBorder ? "0" : "16px")};
  border-radius: ${({ theme }) => theme.radii.md};
  gap: ${({ theme }) => theme.spacing.lg};
  border: ${({ theme, noBorder }) =>
    noBorder ? "none" : `1px solid ${theme.colors.global.line.Line1}`};
  :focus-within {
    border-color: ${({ theme, noBorder }) =>
      noBorder ? "none" : theme.colors.global.line.Line2};
  }
`;
export const Title = styled(Body1)`
  width: 100%;
  margin-bottom: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const InputButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 40px;
`;

export const StyledButton = styled(Button)`
  padding: 0 !important;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.transparent.transparent};
  border-radius: ${({ theme }) => theme.radii.round};
  background: ${({ theme }) => theme.colors.global.background.BG2};
  color: ${({ theme }) => theme.colors.global.text.T1};
  &:hover {
    box-shadow: none;
    opacity: 0.8;
  }
`;

export const StyledInput = styled(Input)`
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 32px !important;
  line-height: 37.5px !important;
  min-height: 38px;
  padding: 0;
  color: ${({ theme }) => theme.colors.global.text.T1};
  :focus {
    border-color: ${({ theme }) => theme.colors.transparent.transparent};
  }
  border: none !important;
  border-radius: 0;
`;

export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 12px;
`;

export const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 16px;
  gap: ${({ theme }) => theme.spacing.sm};
`;

export const USDValue = styled.div<{ priceImpact?: string }>`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme, priceImpact }) => {
    if (priceImpact) {
      return priceImpact.startsWith("+")
        ? theme.colors.global.success
        : theme.colors.global.error;
    }
    return theme.colors.global.text.T3;
  }};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

export const BalanceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

export const BalanceName = styled.span`
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.global.text.T3};
`;
export const BalanceValue = styled.span`
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const Max = styled.span<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  color: ${({ theme }) => theme.colors.global.primary};
`;

export const AddTokenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  height: 12px;
  gap: ${({ theme }) => theme.spacing.sm};
`;

export const PlusIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 8px;
  svg {
    fill: ${({ theme }) => theme.colors.global.primary};
  }
`;

export const ArrowDown = styled(CaretIcon)`
  width: 10px;
  height: 10px;
  path {
    transform: scale(2.4);
    transform-origin: center;
  }
`;

export const SelectedTokenIconWrapper = styled.div`
  height: 20px;
  width: 20px;
`;

export const TokenInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  gap: 8px;
  padding: 8px 16px;
`;

export const ArrowDownWrapper = styled(Flex)`
  margin-bottom: 2px;
  > svg,
  path {
    fill: ${({ theme }) => theme.colors.global.text.T1};
  }
`;

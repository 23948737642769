import { MetamaskIcon, PlusIcon } from "@aperture/assetkit";
import bigDecimal from "js-big-decimal";
import React, { useEffect, useRef, useState } from "react";
import {
  formatTokenAmount,
  formatUSDAmount,
  limitToScale,
} from "../../../utils";
import { LinearLoader } from "../../Loader";
import { Popover } from "../../Popover";
import { IInputRef } from "../types";
import {
  AddTokenWrapper,
  ArrowDown,
  ArrowDownWrapper,
  BalanceName,
  BalanceValue,
  BalanceWrapper,
  InputButton,
  InputContainer,
  Max,
  PlusIconWrapper,
  SelectedTokenIconWrapper,
  StyledButton,
  StyledInput,
  SwapContainer,
  SwapWrapper,
  Title,
  TokenInfoWrapper,
  USDValue,
  ValueContainer,
  ValueWrapper,
} from "./style";
import { ISwapInputProps } from "./types";

export const SwapInput: React.FC<ISwapInputProps> = ({
  title,
  token,
  price,
  value,
  showMax = true,
  showAddToken = false,
  showBalance = true,
  openTokenSelectModal,
  onValueChange,
  openAddTokenPopup = () => {},
  size = "large",
  loading = false,
  disabled = false,
  noBorder = false,
  priceImpact,
  decimalScale,
  ...props
}) => {
  const [convertedValue, setConvertedValue] = useState<string>("0.00");
  const inputRef = useRef<IInputRef>(null);
  const convertValue = (value: string, tokenPrice: string) =>
    tokenPrice && tokenPrice !== "-"
      ? bigDecimal.multiply(value, tokenPrice)
      : "";

  useEffect(() => {
    if (!token || (!value && value !== "")) return;
    setConvertedValue(convertValue(value, price ?? ""));
  }, [value, price, token]);

  return (
    <SwapContainer>
      <SwapWrapper noBorder={noBorder}>
        <InputContainer>
          {!!title && <Title>{title}</Title>}
          <InputButton>
            <StyledInput
              ref={inputRef}
              align="left"
              placeholder="0"
              defaultValue="0"
              //@ts-ignore
              value={
                token ? (value ? limitToScale(value, decimalScale) : "") : ""
              }
              disabled={!token || loading || disabled}
              type="number"
              onChange={(value: string) =>
                onValueChange(limitToScale(value, decimalScale))
              }
              {...props}
            />
            <StyledButton
              variant="contained"
              size="md"
              width="max-content"
              disabled={loading || disabled}
              onClick={openTokenSelectModal}
            >
              <TokenInfoWrapper>
                {token && (
                  <SelectedTokenIconWrapper>
                    {token.Icon}
                  </SelectedTokenIconWrapper>
                )}
                {token
                  ? token.ticker
                  : size === "large"
                  ? "Select A Token"
                  : "Select"}
                <ArrowDownWrapper>
                  <ArrowDown />
                </ArrowDownWrapper>
              </TokenInfoWrapper>
            </StyledButton>
          </InputButton>
        </InputContainer>
        <ValueContainer>
          {loading ? (
            <LinearLoader size="80px" height="16px" radius="8px" />
          ) : (
            <ValueWrapper>
              <USDValue>
                {token && value && bigDecimal.compareTo(value, 0) > 0
                  ? convertedValue
                    ? formatUSDAmount(convertedValue)
                    : "-"
                  : "-"}
              </USDValue>
              <Popover
                defaultPosition={{
                  top: 0,
                  left: 0,
                }}
                content={
                  "The estimated difference between the USD values of input and output amounts."
                }
              >
                <USDValue priceImpact={priceImpact}>
                  {token && value && priceImpact && ` (${priceImpact}%)`}
                </USDValue>
              </Popover>
            </ValueWrapper>
          )}
          <BalanceWrapper>
            <BalanceName>Balance</BalanceName>
            {showBalance && (
              <BalanceValue>
                {formatTokenAmount(token?.amount || "0")}
              </BalanceValue>
            )}
            {showMax &&
              token &&
              bigDecimal.compareTo(token?.amount, "0") > 0 && (
                <Max
                  disabled={loading || disabled}
                  onClick={() =>
                    !loading &&
                    !disabled &&
                    inputRef?.current?.setValue(token?.amount || "0")
                  }
                >
                  Max
                </Max>
              )}
            {showAddToken && token && !token.native && (
              <AddTokenWrapper onClick={openAddTokenPopup}>
                <MetamaskIcon width={27} height={16} />
                <PlusIconWrapper>
                  <PlusIcon width={8} height={16} />
                </PlusIconWrapper>
              </AddTokenWrapper>
            )}
          </BalanceWrapper>
        </ValueContainer>
      </SwapWrapper>
    </SwapContainer>
  );
};

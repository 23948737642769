import styled from "styled-components";
import { T4 } from "../Typography";

const Tag = styled(T4)`
  width: fit-content;
  color: ${({ theme }) => theme.colors.grey.black};
  padding: 3px ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.spacing.sm};
  background-color: ${({ theme }) => theme.colors.global.success};
`;

export const ZapTag: React.FC<{ status: "In" | "Out" }> = ({
  status = "In",
}) => {
  return <Tag>Zap {status}</Tag>;
};

import { FlexColumn, RebalanceActionTab } from "@ui/common";
import bigDecimal from "js-big-decimal";
import styled from "styled-components";
import { InputGroupPlusMinus } from "../Input";
import { InputWithTicker } from "../Input/InputWithTicker";
import { FutureSpotChartRangeInput } from "../LiquidityChartRangeInput";
import { RatioChartRangeInput } from "../LiquidityChartRangeInput/RatioChartRangeInput";
import { QuestionPopover } from "../Popover";
import { BtnSwitchTab } from "../Tabs";
import { Subtitle1, Subtitle2 } from "../Typography";
import { StrategySelectorProps } from "./types";

const TitleWrapper = styled(Subtitle2)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.global.text.T2};
  margin-bottom: -16px;
`;

const StrategySelectorWrapper = styled.div<{
  direction: "horizontal" | "vertical";
}>`
  display: flex;
  flex-direction: ${({ direction }) =>
    direction === "horizontal" ? "row" : "column"};
  position: relative;
  gap: ${({ direction, theme }) =>
    direction === "horizontal" ? "40px" : theme.spacing["xl"]};
`;

const StrategyChartWrapper = styled.div<{
  direction: "horizontal" | "vertical";
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${({ direction }) => (direction === "horizontal" ? "720px" : "100%")};
  gap: ${({ direction, theme }) =>
    direction === "horizontal" ? theme.spacing.xl : theme.spacing["3xl"]};
`;

const StrategyInputWrapper = styled.div<{
  direction: "horizontal" | "vertical";
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ direction, theme }) =>
    direction === "horizontal" ? theme.spacing.md : theme.spacing.xl};
`;

const StrategyTabWrapper = styled.div<{ direction: "horizontal" | "vertical" }>`
  display: flex;
  /* margin-top: ${({ direction }) =>
    direction === "horizontal" ? "0" : "-14px"}; */
`;

const StrategyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.global.text.T2};
`;

const InputsWrapper = styled.div<{ direction: "horizontal" | "vertical" }>`
  display: grid;
  ${({ direction }) =>
    direction === "horizontal"
      ? `width: 352px`
      : `grid-template-columns: repeat(2, 1fr)`};
  gap: ${({ direction, theme }) =>
    direction === "horizontal" ? theme.spacing.md : theme.spacing.xl};
`;

const TickInputWrapper = styled(FlexColumn)`
  width: 280px;
  gap: ${({ theme }) => theme.spacing.md};
`;

const TickInputTitle = styled(Subtitle1)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

const TickInnerTitle = styled(Subtitle1)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

const StyledInputWithTicker = styled(InputWithTicker)`
  width: 280px;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.global.background.BG3};
  & + div {
    top: 11px;
    color: ${({ theme }) => theme.colors.global.text.T1};
  }
`;

export function StrategySelector({
  direction,
  currencyA,
  currencyB,
  ticksAtLimit,
  price,
  priceLower,
  priceUpper,
  tickWidth,
  tickSpacing,
  showStrategyTab,
  strategyTab,
  strategyTabPosition = "bottom",
  onSwitchStrategyTab,
  getDecrementLower,
  getIncrementLower,
  getDecrementUpper,
  getIncrementUpper,
  onLeftRangeInput,
  onRightRangeInput,
  onTickWidthInput,
  chartHeight,
  interactive,
}: StrategySelectorProps) {
  const tickWidthPopoverText =
    "Enter a width in terms of ticks that defines the next position. The specified ratio above will be applied to the new position with this position width.";
  const chartArgs = {
    currencyA,
    currencyB,
    ticksAtLimit,
    price,
    priceLower,
    priceUpper,
    onLeftRangeInput,
    onRightRangeInput,
    interactive,
    chartHeight,
  };
  return (
    <StrategySelectorWrapper direction={direction}>
      {showStrategyTab && strategyTabPosition === "top" && (
        <StrategyTabWrapper direction={direction}>
          <BtnSwitchTab
            sm={true}
            height="24px"
            menu={Object.values(RebalanceActionTab)}
            selected={Object.values(RebalanceActionTab).indexOf(
              strategyTab ?? RebalanceActionTab.RATIO
            )}
            onSelect={(tab: number) => {
              onSwitchStrategyTab?.(Object.values(RebalanceActionTab)[tab]);
            }}
          />
        </StrategyTabWrapper>
      )}
      <StrategyChartWrapper direction={direction}>
        {currencyA && currencyB && (
          <TitleWrapper height={40}>
            {strategyTab === RebalanceActionTab.RATIO
              ? `${currencyA ?? ""}-${currencyB ?? ""} Ratio`
              : "Future Spot Price"}
          </TitleWrapper>
        )}
        {strategyTab === RebalanceActionTab.RATIO ? (
          <RatioChartRangeInput {...chartArgs} />
        ) : (
          <FutureSpotChartRangeInput
            {...chartArgs}
            futureSpotTab={strategyTab}
          />
        )}
      </StrategyChartWrapper>
      <StrategyInputWrapper direction={direction}>
        {showStrategyTab && strategyTabPosition === "bottom" && (
          <StrategyTabWrapper direction={direction}>
            <BtnSwitchTab
              sm={true}
              menu={Object.values(RebalanceActionTab)}
              selected={Object.values(RebalanceActionTab).indexOf(
                strategyTab ?? RebalanceActionTab.RATIO
              )}
              onSelect={(tab) => {
                onSwitchStrategyTab?.(Object.values(RebalanceActionTab)[tab]);
              }}
            />
          </StrategyTabWrapper>
        )}
        <InputsWrapper direction={direction}>
          <InputGroupPlusMinus
            placeholder="0.00"
            value={priceLower}
            onChange={onLeftRangeInput}
            defaultValue={priceLower ?? "0"}
            title={
              strategyTab === RebalanceActionTab.RATIO && currencyA
                ? currencyA
                : "Min"
            }
            subTitle={
              strategyTab === RebalanceActionTab.TOKEN_TERMS ? (
                <StrategyWrapper>
                  <Subtitle2>
                    {currencyB ?? ""} per {currencyA ?? ""}
                  </Subtitle2>
                  <Subtitle2>below future spot price</Subtitle2>
                </StrategyWrapper>
              ) : strategyTab === RebalanceActionTab.PERCENTAGES ? (
                "below future spot price"
              ) : (
                "Ratio"
              )
            }
            minusClick={getDecrementLower}
            plusClick={getIncrementLower}
            disabled={!interactive}
            allowNegative={strategyTab !== RebalanceActionTab.RATIO}
            format={{
              suffix:
                strategyTab === RebalanceActionTab.TOKEN_TERMS
                  ? undefined
                  : "%",
            }}
          />
          <InputGroupPlusMinus
            placeholder="0.00"
            value={priceUpper}
            onChange={onRightRangeInput}
            defaultValue={priceUpper ?? "0"}
            title={
              strategyTab === RebalanceActionTab.RATIO && currencyB
                ? currencyB
                : "Max"
            }
            subTitle={
              strategyTab === RebalanceActionTab.TOKEN_TERMS ? (
                <StrategyWrapper>
                  <Subtitle2>
                    {currencyB ?? ""} per {currencyA ?? ""}
                  </Subtitle2>
                  <Subtitle2>above future spot price</Subtitle2>
                </StrategyWrapper>
              ) : strategyTab === RebalanceActionTab.PERCENTAGES ? (
                "above future spot price"
              ) : (
                "Ratio"
              )
            }
            minusClick={getDecrementUpper}
            plusClick={getIncrementUpper}
            disabled={!interactive}
            allowNegative={strategyTab !== RebalanceActionTab.RATIO}
            format={{
              suffix:
                strategyTab === RebalanceActionTab.TOKEN_TERMS
                  ? undefined
                  : "%",
            }}
          />
        </InputsWrapper>
        {strategyTab === RebalanceActionTab.RATIO && (
          <TickInputWrapper>
            <TickInputTitle>
              <TickInnerTitle>Position Width</TickInnerTitle>
              <QuestionPopover
                content={tickWidthPopoverText}
                defaultSize={{ width: 400 }}
                defaultPosition={{ bottom: 18, left: -124 }}
              />
            </TickInputTitle>
            <StyledInputWithTicker
              align="right"
              type="integer"
              defaultValue={tickWidth}
              format={{ round: tickSpacing }}
              ticker={bigDecimal.compareTo(tickWidth, 1) > 0 ? "ticks" : "tick"}
              popover={tickWidthPopoverText}
              onBlur={onTickWidthInput}
            />
          </TickInputWrapper>
        )}
      </StrategyInputWrapper>
    </StrategySelectorWrapper>
  );
}

import { NotFoundWrapper, StyledT2 } from "./style";

interface IEmptyState {
  text?: string;
}

export const EmptyState: React.FC<IEmptyState> = ({
  text = "Page not found.",
}) => {
  return (
    <NotFoundWrapper alignItems="center" justifyContent="center">
      <StyledT2>{text}</StyledT2>
    </NotFoundWrapper>
  );
};

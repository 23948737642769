import styled from "styled-components";
import { Headline5 } from "../Typography";

export const TokenInfoContainer = styled.div<{ marginBottom: number, minWidth?: number }>`
  width: 100%;
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px;`}
  display: flex;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  color: ${({ theme }) => theme.colors.global.text.T1};
  &:last-child {
    margin: 0;
  }
`;
export const IconWrapper = styled.div`
  height: 20px;
  width: 20px;
`;
export const Amount = styled.span<{ maxWidth?: number }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ShortAmount = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  max-width: 68px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledHeadline5 = styled(Headline5)`
min-width: 72px;
`
export const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.md2};
`;

export const RightWrapper = styled.div<{ maxWidth: number }>`
  flex: 1 1 0;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: ${({ theme }) => theme.spacing.md2};
  max-width: ${({ maxWidth }) => maxWidth >= 999 ? "100%" : `${maxWidth}px`};
`;

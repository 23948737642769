import { useNetwork } from "@/components/NetworkContext/NetworkContext";
import { getNativeIcon, getTokenIcon } from "@/config/token/tokenIconConfig";
import { OpenPositionQueryKey as QueryKey } from "@/utils/browserHistory";
import { getNetworkId } from "@/utils/networkHelper";
import { MIN_FIXED_VALUE, formatTokenAmount } from "@/utils/numberFormat";
import { IToken } from "@aperture/types";
import { SupportedChainId } from "@aperture/uikitv2";

import { IRawTokenMap } from "@/components/GlobalStore/allChain/types";
import { getTokenPrice } from "@/helper/fetchHelper/fetchTokenPrices";
import {
  ApertureSupportedChainId,
  viem,
} from "@aperture_finance/uniswap-v3-automation-sdk";
import { NativeCurrency, Token } from "@uniswap/sdk-core";
import bigDecimal from "js-big-decimal";
import { useSearchParams } from "next/navigation";
import { useMemo } from "react";
import { Address, PublicClient, getAddress } from "viem";
import { useCurrentChainToken } from "./globalState/useCurrentChainToken";
import { TokenPriceMap } from "./useFetchAllChainsTokensPrice";
import {
  TokenBalanceMap,
  getTokenBalance,
  useFetchUserTokensBalance,
} from "./useFetchUserTokensBalance";

export type ITokenMap = {
  [address: string]: IToken;
};

export const useFetchTokenMap = (networkId?: ApertureSupportedChainId) => {
  const { networkId: currentNetworkId } = useNetwork();

  const searchParams = useSearchParams();
  const calc_network = searchParams.get(QueryKey.CALC_NETWORK);
  const _networkId =
    networkId ??
    (calc_network
      ? getNetworkId(calc_network as SupportedChainId)
      : currentNetworkId);

  const { data: balanceData, isLoading: isBalanceLoading } =
    useFetchUserTokensBalance();

  const { currentTokenMap, currentTokenPrice } =
    useCurrentChainToken(_networkId);

  const tokenMap = useMemo(() => {
    if (!currentTokenMap) return {};

    return convertRawTokenMapToITokenMap(
      _networkId,
      currentTokenMap,
      balanceData,
      currentTokenPrice
    );
  }, [_networkId, balanceData, currentTokenMap, currentTokenPrice]);

  return {
    data: tokenMap,
    isLoading: isBalanceLoading || !currentTokenPrice,
  };
};

function convertRawTokenMapToITokenMap(
  chainId: ApertureSupportedChainId,
  rawTokenMap: IRawTokenMap,
  balanceData?: TokenBalanceMap,
  priceData?: TokenPriceMap
) {
  if (!chainId) {
    return {};
  }
  const allWarningTokens = localStorage.getItem(`all-chains-warning-tokens`);
  const warningTokens = allWarningTokens
    ? JSON.parse(allWarningTokens)[chainId] || []
    : [];
  let warningAddresses = warningTokens.map((token) => token.address);

  if (chainId === ApertureSupportedChainId.ARBITRUM_MAINNET_CHAIN_ID) {
    // temporary workaround to remove warning for Airdrop and Apture
    warningAddresses = warningAddresses.filter(
      (address) =>
        ![
          "0xdc5F1BF636DcAdaE7e285A484Dc71A1F5adeE0A1",
          "0x1C986661170c1834db49C3830130D4038eEeb866",
        ].includes(address)
    );
  }

  const native = viem.getNativeCurrency(chainId);
  const nativeAddress = native.wrapped.address;

  let tokens: ITokenMap = {
    [`${nativeAddress}-${true}`]: convertNativeToken(
      native,
      balanceData,
      priceData
    ),
  };
  Object.values(rawTokenMap).forEach((token: Token) => {
    tokens[`${token.address}-${false}`] = convertToIToken(
      token,
      chainId,
      warningAddresses,
      balanceData,
      priceData
    );
  });
  return tokens;
}

function convertNativeToken(
  native: NativeCurrency,
  balanceData?: TokenBalanceMap,
  priceData?: TokenPriceMap
): IToken {
  const wrapped = native.wrapped;
  const address = wrapped.address;
  const balanceKey = `${address}-${true}`;
  return {
    name: native.name,
    ticker: native.symbol,
    Icon: getNativeIcon(native.chainId),
    amount: balanceData?.[balanceKey] ? balanceData[balanceKey].balance : "",
    formatAmount: balanceData?.[balanceKey]
      ? formatTokenAmount(balanceData[balanceKey].balance)
      : "",
    price: priceData?.[address]?.price ?? "",
    decimals: native.decimals,
    address: wrapped.address,
    native: true,
  };
}

export function convertToIToken(
  token: Token,
  chainId: ApertureSupportedChainId,
  warningAddresses: string[],
  balanceData?: TokenBalanceMap,
  priceData?: TokenPriceMap
): IToken {
  const address = token.address;
  const balanceKey = `${address}-${token.isNative}`;
  return {
    name: token.name ?? "Unknown",
    ticker: token.symbol ?? "Unknown",
    Icon: getTokenIcon(token.address, token.symbol, chainId),
    amount: balanceData?.[balanceKey] ? balanceData[balanceKey].balance : "",
    formatAmount: balanceData?.[balanceKey]
      ? formatAmount(
          priceData?.[address]?.price,
          balanceData[balanceKey].balance
        )
      : "",
    price: priceData?.[address]?.price ?? "",
    decimals: token.decimals,
    address: token.address,
    native: token.isNative,
    warning: warningAddresses.includes(address),
  };
}

function formatAmount(price: string, balance: string) {
  if (
    bigDecimal.compareTo(balance, 0) > 0 &&
    bigDecimal.compareTo(balance, MIN_FIXED_VALUE) < 0 &&
    bigDecimal.compareTo(bigDecimal.multiply(balance, price), 0) > 0 &&
    bigDecimal.compareTo(bigDecimal.multiply(balance, price), MIN_FIXED_VALUE) <
      0
  ) {
    return "<" + MIN_FIXED_VALUE;
  } else {
    return balance ? formatTokenAmount(balance) : "";
  }
}

export async function fetchUnknownToken(
  address: string,
  chainId: ApertureSupportedChainId,
  publicClient: PublicClient,
  showBalance: boolean,
  walletAddress: Address
) {
  const tokenAddress = getAddress(address) as Address;
  const token = await viem.getToken(
    tokenAddress,
    chainId,
    publicClient,
    undefined,
    true
  );
  const balanceMap = showBalance
    ? await getTokenBalance(publicClient, walletAddress, token)
    : {};
  const priceMap = await getTokenPrice(chainId, token);
  return convertToIToken(token, chainId, [tokenAddress], balanceMap, priceMap);
}

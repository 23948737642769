import styled from "styled-components";
import { Button } from "../Button";
import { SlippageContainer } from "../Slippage/style";

export const StyledButton = styled(Button)`
  width: 254px;
  height: 44px;
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-color: ${({ theme }) => theme.colors.global.background.BG2};
  color: ${({ theme }) => theme.colors.global.text.T1};
  background: ${({ theme }) => theme.colors.global.background.BG2};
  border-radius: ${({ theme }) => theme.radii.round};
  box-shadow: ${({ theme: { isDropdownOpen, shadows } }) =>
    isDropdownOpen ? shadows.small : "none"};

  :hover {
    background: ${({ theme }) => theme.colors.global.background.BG3};
    border-color: ${({ theme }) => theme.colors.global.background.BG3};
    color: ${({ theme }) => theme.colors.global.text.T2};
  }
`;

export const Content = styled.div`
  padding: 24px;
  width: 300px;
  box-sizing: border-box;
`;

export const TransactionContainer = styled(SlippageContainer)`
  margin-top: 16px;
`;

export const IconWrapper = styled.div<{
  iconWidth: number;
  iconHeight: number;
}>`
  height: ${({ iconHeight }) => `${iconHeight / 2}px`};
  width: ${({ iconWidth }) => `${iconWidth / 2}px`};
  margin: auto;
  svg {
    circle,
    path {
      stroke: ${({ theme }) => theme.colors.global.text.T1};
    }
  }
`;

export const IconButton = styled.div<{
  iconWidth: number;
  iconHeight: number;
  isBorderless: boolean;
}>`
  height: ${({ iconHeight }) => `${iconHeight}px`};
  width: ${({ iconWidth }) => `${iconWidth}px`};
  ${({ theme, isBorderless }) => {
    if (isBorderless) {
      return `
        background: transparent;
        border: none;
        box-shadow: none;
      `;
    } else {
      return `
        background:  ${theme.colors.global.background.BG3};
        border-radius: ${theme.radii.round};
      `;
    }
  }};
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  > svg {
    height: 28px;
    width: 28px;
  }
`;

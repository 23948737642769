import { CheckMarkIcon } from "@aperture/assetkit";
import styled, { useTheme } from "styled-components";
import { CommonLgText } from "../Typography";

const StyledStakeTag = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
`;

const Text = styled(CommonLgText)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const StakeTag: React.FC = () => {
  const theme = useTheme();
  return (
    <StyledStakeTag>
      <CheckMarkIcon
        height={16}
        width={16}
        fill={theme.colors.global.primary}
        stroke={theme.colors.global.textAlt.T1}
      />
      <Text>Staked</Text>
    </StyledStakeTag>
  );
};

import { QuestionMarkIcon } from "@aperture/assetkit";
import { Flex } from "@ui/common";
import { Popover, StatusTab, StatusTabProps, Subtitle2 } from "@ui/components";
import styled from "styled-components";

const StyledIcon = styled(QuestionMarkIcon)`
  width: 12px;
  height: 12px;
  fill: ${({ theme }) => theme.colors.global.text.T2};
`;

const Label = styled(Subtitle2)`
  width: 56px;
  color: ${({ theme }) => theme.colors.global.text.T2};
`;

export const ZapInOutStatusTab: React.FC<
  StatusTabProps & { zapOut?: boolean }
> = (props) => {
  return (
    <Flex gap={8}>
      <Flex gap="md" alignItems="center">
        <StatusTab {...{ ...props, noText: true }} />
        <Flex gap="sm" alignItems="center">
          <Popover
            content={
              props.zapOut
                ? "Zap out allows you to remove liquidity or collect fees to a token of your choice so you don’t have to swap them afterwards."
                : "Zap in allows you to add liquidity to a new or existing position by using any amount of the two liquidity tokens you have in your wallet so you don’t have to swap them beforehand."
            }
            defaultPosition={{ top: 20, right: -46 }}
          >
            <StyledIcon />
          </Popover>
          <Label fontWeight={400} lineHeight={"16px"}>
            Zap {props.zapOut ? "Out" : "In"}
          </Label>
        </Flex>
      </Flex>
    </Flex>
  );
};

import { CaretIcon, ViewIcon2 } from "@aperture/assetkit";
import { T2 } from "@aperture/uikitv2";
import { Flex, FlexColumn } from "@ui/common";
import styled from "styled-components";
import { Button } from "../Button";
import { Notification } from "../Notification";
import { Body2, CommonLgText, Headline5, Subtitle2 } from "../Typography";

export const Container = styled.div`
  margin-top: 16px;
  width: 100%;
`;
export const TitleRow = styled(Flex)<{ isShow: boolean }>`
  justify-content: space-between;
  padding: 16px;
  border-radius: ${({ theme, isShow }) =>
    !isShow ? theme.radii.md : `${theme.radii.md} ${theme.radii.md} 0px 0px`};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line2};
  border-bottom: ${({ theme, isShow }) =>
    isShow ? "none" : `1px solid ${theme.colors.global.line.Line2}`};
`;
export const Title = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  cursor: pointer;
`;
export const StyledText = styled(Subtitle2)`
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const Text = styled(Subtitle2)`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const H5 = styled(Headline5)<{ priceImpact: number }>`
  color: ${({ theme, priceImpact }) =>
    priceImpact >= 10
      ? theme.colors.global.error
      : priceImpact >= 2.5
      ? theme.colors.errorWarning.orange
      : theme.colors.global.text.T1};
`;
export const ArrowBtn = styled.span`
  width: 20px;
  height: 20px;
`;
export const ArrowDown = styled(CaretIcon)`
  width: 11px;
  height: 11px;
  transform: rotate(180deg);
`;
export const Content = styled.div<{
  isShow: boolean;
  isError: boolean;
  useOverflow: boolean;
}>`
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme, isShow }) =>
    isShow ? `0px 0px ${theme.radii.md} ${theme.radii.md}` : undefined};
  border: ${({ theme, isShow, isError }) =>
    isShow && !isError
      ? `1px solid ${theme.colors.global.line.Line2}`
      : undefined};
  border-top: none;
  overflow: ${({ useOverflow }) => (useOverflow ? "initial" : "hidden")};
  height: ${({ isShow, isError }) =>
    isShow ? (isError ? "max-content" : "228px") : "0"};
  transition: height ${({ theme }) => theme.transitions.default};
  gap: ${({ theme }) => theme.spacing.lg};
`;
export const Split = styled.div<{ isShow: boolean }>`
  width: 100%;
  border: ${({ theme, isShow }) =>
    isShow ? `0.5px solid ${theme.colors.global.line.Line2}` : "none"};
`;
export const Tag = styled(Flex)`
  align-items: center;
  color: ${({ theme }) => theme.colors.global.text.T1};
  border-radius: ${({ theme }) => theme.radii.round};
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  background-color: ${({ theme }) => theme.colors.global.background.BG3};
`;
export const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  > svg {
    width: 20px;
    height: 20px;
  }
`;
export const StyledViewIcon = styled(ViewIcon2)`
  fill: ${({ theme }) => theme.colors.global.text.T1};
  &:hover {
    fill: ${({ theme }) => theme.colors.global.primaryHover};
  }
`;

export const Card = styled(FlexColumn)`
  width: 100%;
  padding: ${({ theme }) => `0 ${theme.spacing.xl}`};
  gap: ${({ theme }) => theme.spacing.lg};
  border-color: ${({ theme }) => theme.colors.global.line.Line2};
`;
export const CardTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const B2 = styled(Body2)`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.global.text.T2};
`;

export const StyledCommonText = styled(CommonLgText)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const S2 = styled(Subtitle2)`
  color: ${({ theme }) => theme.colors.global.text.T3};
`;
export const StyledNotification = styled(Notification)`
  margin-top: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.global.error};
`;
export const StyledButton = styled(Button)`
  padding: 6px 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.global.text.T1};
  border: 1px solid ${({ theme }) => theme.colors.global.text.T1};
  transition: all ${({ theme }) => theme.transitions.fast} ease-in-out !important;
  &:hover {
    background: ${({ theme }) => theme.colors.global.text.T1};
    border: 1px solid ${({ theme }) => theme.colors.global.text.T1};
    color: ${({ theme }) => `${theme.colors.global.background.BG1}`};
    transition-duration: ${({ theme }) => theme.transitions.default} !important;
  }
  :disabled {
    background: ${({ theme }) =>
      `${theme.colors.global.line.Line1} !important`};
    color: ${({ theme }) => `${theme.colors.global.text.T3} !important`};
    border: ${({ theme }) =>
      `1.5px solid ${theme.colors.global.line.Line1} !important`};
  }
  &:active {
    background: ${({ theme }) => `${theme.colors.global.text.T1}`};
    color: ${({ theme }) => `${theme.colors.global.background.BG1}`};
  }
`;
export const HighlightText = styled.div`
  font-size: 11px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.global.success};
`;

export const ApertureFee = styled(Flex)`
  margin-top: 16px;
`;
export const FeeLabel = styled(T2)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const FeeValue = styled(T2)`
  color: ${({ theme }) => theme.colors.global.text.T3};
`;

import { TokenInfo as TokenInfoType } from "@aperture/types";
import { Flex, FlexColumn } from "@uiv2/common";
import { formatUSDAmount } from "@uiv2/utils/numberFormat";
import bigDecimal from "js-big-decimal";
import { ReactNode } from "react";
import { LinearLoader } from "../Loader";
import { NFTPositionCard } from "../NFTPositionCard";
import { TokenInfo } from "../TokenInfo";
import { Amount, Subtitle, TokenInfoContainer, Wrapper } from "./style";

export interface ITokenInfo extends TokenInfoType {
  Icon: ReactNode;
  amount: string;
  collectableAmount: string;
  price: string;
}

export interface IPositionOverview {
  tokens: [ITokenInfo, ITokenInfo];
  nftURL?: string;
  isNFTLoading: boolean;
  liquidityInUSD: string;
  collectableInUSD: string;
  tokenPrices: [string, string];
}
export const PositionOverview: React.FC<IPositionOverview> = ({
  tokens,
  nftURL,
  isNFTLoading,
  liquidityInUSD,
  collectableInUSD,
  tokenPrices,
}) => {
  return (
    <Flex gap="lg">
      {(nftURL || isNFTLoading) && (
        <Wrapper>
          {isNFTLoading ? (
            <LinearLoader size="100%" height="158px" radius="25px" />
          ) : (
            nftURL && <NFTPositionCard NFTStr={nftURL} />
          )}
        </Wrapper>
      )}
      <TokenInfoContainer>
        <FlexColumn gap="md">
          <Subtitle>Liquidity</Subtitle>
          <Amount>{formatUSDAmount(liquidityInUSD)}</Amount>
        </FlexColumn>
        <FlexColumn gap="lg">
          {tokens.map((token, index) => (
            <TokenInfo
              key={`liquidity-${index}`}
              marginBottom={0}
              Icon={token.Icon}
              ticker={token.ticker}
              address={token.address}
              native={token.native}
              decimals={token.decimals}
              amount={token.amount}
              price={tokenPrices[index]}
              tag={
                liquidityInUSD === "-" ||
                bigDecimal.compareTo(liquidityInUSD, 0) === 0
                  ? "-"
                  : `${bigDecimal.multiply(
                      bigDecimal.divide(
                        bigDecimal.multiply(token.amount, tokenPrices[index]),
                        liquidityInUSD,
                        2
                      ),
                      100
                    )}%`
              }
            />
          ))}
        </FlexColumn>
      </TokenInfoContainer>
      <TokenInfoContainer gap={"lg"}>
        <FlexColumn gap="md">
          <Subtitle>Unclaimed Fees</Subtitle>
          <Amount>{formatUSDAmount(collectableInUSD)}</Amount>
        </FlexColumn>
        <FlexColumn gap="lg">
          {tokens.map((token, index) => (
            <TokenInfo
              key={`unclaimed-fees-${index}`}
              marginBottom={0}
              Icon={token.Icon}
              ticker={token.ticker}
              address={token.address}
              decimals={token.decimals}
              native={token.native}
              amount={token.collectableAmount}
              price={tokenPrices[index]}
            />
          ))}
        </FlexColumn>
      </TokenInfoContainer>
    </Flex>
  );
};

import { CaretIcon } from "@aperture/assetkit";
import { Flex } from "@ui/common";
import styled from "styled-components";
import { WhiteCard } from "../Card";

export const Container = styled(WhiteCard)<{
  isShow: boolean;
  bg?: string;
}>`
  flex-direction: column;
  padding: 0;
  margin-bottom: 12px;
  padding: 16px;
  background: none;
  gap: ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.radii.lg};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
  transition: padding ${({ theme }) => theme.transitions.default};
`;
export const TitleBar = styled(Flex)`
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  cursor: pointer;
`;
export const TitleBox = styled(Flex)`
  align-items: center;
`;
export const ArrowBtn = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 8px;
  margin: -12px -14px -12px auto;
  border-radius: ${({ theme }) => theme.radii.md};
`;
export const ArrowDown = styled(CaretIcon)<{ isShow: boolean }>`
  width: 24px;
  height: 24px;
  transition: transform ${({ theme }) => theme.transitions.default};
  transform: ${({ isShow }) => (isShow ? "rotate(180deg)" : "")};
  path {
    fill: ${({ theme }) => theme.colors.global.text.T1};
  }
`;
export const Content = styled(Flex)<{
  isShow: boolean;
  useOverflow: boolean;
  isCreate: boolean;
}>`
  margin-top: ${({ isShow }) => (isShow ? "8px" : "-8px")};
  justify-content: flex-start;
  flex-direction: ${({ isCreate }) => (isCreate ? "column" : "row")};
  gap: ${({ theme }) => theme.spacing.md};
  overflow: ${({ useOverflow }) => (useOverflow ? "initial" : "hidden")};
  height: ${({ isShow }) => (isShow ? "240px" : "0")};
  transition: height ${({ theme }) => theme.transitions.default},
    padding ${({ theme }) => theme.transitions.default};
`;
export const Split = styled.hr`
  margin: 0;
  width: 0;
  height: 100%;
`;
export const FlexBox = styled(Flex)`
  align-items: center;
  gap: 8px;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.md};
`;

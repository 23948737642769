type QueuedRequest<T> = {
  execute: () => Promise<T>;
  resolve: (value: T) => void;
  reject: (error: any) => void;
};

export class RequestQueue {
  private queue: QueuedRequest<any>[] = [];
  private processing = false;
  private concurrentLimit: number;
  private activeRequests = 0;
  private requestDelay: number;

  constructor(concurrentLimit = 5, requestDelay = 200) {
    this.concurrentLimit = concurrentLimit;
    this.requestDelay = requestDelay;
  }

  async enqueue<T>(request: () => Promise<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      this.queue.push({
        execute: request,
        resolve,
        reject,
      });
      this.processQueue();
    });
  }

  private async processQueue() {
    if (this.processing) return;
    this.processing = true;

    const processRequest = async (request: QueuedRequest<any>) => {
      this.activeRequests++;
      try {
        const result = await request.execute();
        await new Promise((resolve) => setTimeout(resolve, this.requestDelay));
        request.resolve(result);
      } catch (error) {
        request.reject(error);
      } finally {
        this.activeRequests--;
        // Check if we can process more requests
        if (this.queue.length > 0) {
          this.processQueue();
        }
      }
    };

    // Start as many requests as we can up to the concurrent limit
    while (
      this.queue.length > 0 &&
      this.activeRequests < this.concurrentLimit
    ) {
      const request = this.queue.shift();
      if (!request) continue;

      // Don't await here - let it process independently
      processRequest(request);
    }

    this.processing = false;
  }

  public clear() {
    this.queue = [];
  }

  public get pendingRequests(): number {
    return this.queue.length;
  }

  public get activeRequestCount(): number {
    return this.activeRequests;
  }

  async enqueueBatch<T>(
    requests: (() => Promise<T>)[],
    batchSize = 10
  ): Promise<T[]> {
    const results: T[] = [];
    for (let i = 0; i < requests.length; i += batchSize) {
      const batch = requests.slice(i, i + batchSize);
      const batchResults = await Promise.all(
        batch.map((request) => this.enqueue(request))
      );
      results.push(...batchResults);
    }
    return results;
  }
}

export const requestQueue = new RequestQueue(4, 300);

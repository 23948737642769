import {
  CopiedIcon,
  CrossCircleIcon,
  CrossIcon,
  WarningIcon,
} from "@aperture/assetkit";
import { useState } from "react";
import styled from "styled-components";
import { variant } from "styled-system";
import { styleVariants } from "./theme";
import { INotificationProps, IType, notificationVariants } from "./types";

const getColor = (theme: any, colorKey: string) => {
  const [category, color] = colorKey.split(".");
  return theme.colors[category][color];
};
const NotificationContainer = styled.div<IType>`
  box-sizing: border-box;
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  padding: 4px 12px;
  gap: ${({ theme }) => theme.spacing.md};
  ${variant({ variants: styleVariants })}
`;
const IconWrapper = styled.div<IType>`
  display: flex;
  text-align: center;
  svg {
    path {
      ${variant({ variants: styleVariants })}
    }
    stop {
      ${variant({ variants: styleVariants })}
    }
  }
`;
const Content = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  word-break: break-word;
`;
const CloseBtn = styled.div<IType>`
  display: flex;
  position: absolute;
  width: 24px;
  height: 24px;
  top: -12px;
  right: 0px;
  text-align: center;
  border: 1px solid;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.radii.round};
  box-shadow: ${({ theme }) => theme.shadows.small};
  ${variant({ variants: styleVariants })}
  background: ${({ theme }) => theme.colors.grey.offWhite};
  cursor: pointer;
  svg {
    ${variant({ variants: styleVariants })}
    margin: auto;
    fill: ${({ theme, variant }) =>
      getColor(theme, styleVariants[variant].stroke)};
  }
`;

export const iconMap = {
  [notificationVariants.DEFAULT]: CopiedIcon,
  [notificationVariants.WARNING]: WarningIcon,
  [notificationVariants.ERROR]: CrossCircleIcon,
  [notificationVariants.SUCCESS]: CopiedIcon,
};

export const Notification: React.FC<INotificationProps> = ({
  variant = notificationVariants.DEFAULT,
  text = "",
  button = <></>,
  showClose = false,
  onClose,
  showIcon = true,
  icon,
  ...props
}) => {
  const Icon = icon ?? iconMap[variant];
  const [open, setOpen] = useState(true);
  const handleCloseBtnClick = () => {
    setOpen(false);
    onClose && onClose();
  };
  return (
    <>
      {open && (
        <NotificationContainer variant={variant} {...props}>
          {variant !== notificationVariants.DEFAULT && showIcon && (
            <IconWrapper variant={variant}>
              <Icon height={18} width={18} />
            </IconWrapper>
          )}
          <Content>{text}</Content>
          {button}
          {showClose && (
            <CloseBtn variant={variant} onClick={handleCloseBtnClick}>
              <CrossIcon height={9} width={9} />
            </CloseBtn>
          )}
        </NotificationContainer>
      )}
    </>
  );
};

import { Flex, FlexColumn } from "@uiv2/common";
import { Button } from "@uiv2/components/Button";
import { T3Regular } from "@uiv2/components/Typography";
import {
  CalculatorAmmInfo,
  Calculator_AMM,
  SupportedChainId,
  getChainInfo,
} from "@uiv2/utils";
import { FC } from "react";
import styled from "styled-components";

const StyledT3 = styled(T3Regular)`
  color: ${({ theme }) => theme.colors.global.text.T2};
`;
const BtnText = styled(T3Regular)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const StyledButton = styled(Button)<{ selected?: boolean }>`
  padding-left: 8px;
  padding-right: 16px;
  border: 1px solid ${({ theme }) => theme.colors.global.background.BG3};
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.global.background.BG3 : "initial"};

  :hover,
  :active {
    border-color: ${({ theme }) => theme.colors.global.background.BG3};
    background-color: ${({ theme }) => theme.colors.global.background.BG3};
  }
`;

type IAmmNetworkSelectorProps = {
  list: Record<Calculator_AMM, SupportedChainId[]>;
  selectedExchange: Calculator_AMM;
  setSelectedExchange: (dex: Calculator_AMM, network: SupportedChainId) => void;
  selectedNetwork: SupportedChainId;
  setSelectedNetwork: (dex: Calculator_AMM, network: SupportedChainId) => void;
};

export const AmmNetworkSelector: FC<IAmmNetworkSelectorProps> = ({
  list,
  selectedExchange,
  setSelectedExchange,
  selectedNetwork,
  setSelectedNetwork,
}) => {
  return (
    <FlexColumn gap="lg">
      <Flex gap="xl" alignItems="center">
        <StyledT3>Exchange</StyledT3>
        <Flex gap="sm">
          {Object.keys(list).map((amm) => {
            const Icon = CalculatorAmmInfo[amm as Calculator_AMM].Icon;
            return (
              <StyledButton
                key={amm}
                size="md"
                variant="outlined"
                icon={<Icon />}
                iconGap={8}
                selected={amm === selectedExchange}
                onClick={() => {
                  if (amm === selectedExchange) return;
                  setSelectedExchange(
                    amm as Calculator_AMM,
                    list[amm as Calculator_AMM][0]
                  );
                  setSelectedNetwork(
                    amm as Calculator_AMM,
                    list[amm as Calculator_AMM][0]
                  );
                }}
              >
                <BtnText>{amm}</BtnText>
              </StyledButton>
            );
          })}
        </Flex>
      </Flex>
      <Flex gap="xl" alignItems="center">
        <StyledT3>Network</StyledT3>
        <Flex gap="sm">
          {Object.values(list[selectedExchange]).map((network) => {
            const Icon = getChainInfo(network).Icon;
            return (
              <StyledButton
                key={network}
                size="md"
                variant="outlined"
                icon={<Icon />}
                iconGap={8}
                selected={network === selectedNetwork}
                onClick={() => setSelectedNetwork(selectedExchange, network)}
              >
                <BtnText>{getChainInfo(network).name}</BtnText>
              </StyledButton>
            );
          })}
        </Flex>
      </Flex>
    </FlexColumn>
  );
};

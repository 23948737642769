import { LinkIcon } from "@aperture/assetkit";
import React from "react";
import styled from "styled-components";
import { INavCardProps } from "./types";

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  padding: 8px 12px;
  align-items: center;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.radii.md};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
  gap: ${({ theme }) => theme.spacing.md};
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.global.background.BG2};
  }
  transition: ${({ theme }) => theme.transitions.default};
  background: ${({ theme }) => theme.colors.global.background.BG3};
`;
const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  align-items: center;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const NextIcon = styled(LinkIcon)`
  width: 20px;
  height: 20px;
  path {
    fill: ${({ theme }) => theme.colors.global.primary};
    stroke: ${({ theme }) => theme.colors.global.primary};
  }
`;

export const NavCard: React.FC<INavCardProps> = ({
  title = "",
  navTo = () => {},
}) => {
  return (
    <Container onClick={navTo}>
      <Title>{title}</Title>
      <NextIcon />
    </Container>
  );
};

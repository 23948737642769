import React, { HTMLAttributes } from "react";
import {
  Box,
  Circle,
  Container,
  Label,
  Marker,
  Spot,
  SuccessText,
  Text,
  Title,
} from "./style";

interface ILabel {
  index: number;
  title: string;
  text: string;
}

export interface IProgressCircleProps extends HTMLAttributes<HTMLDivElement> {
  percentage: number;
  labels?: ILabel[];
  successText?: string;
  size?: number;
  lineW?: number;
  n?: number;
  labelGap?: number;
}

export const ProgressCircle: React.FC<IProgressCircleProps> = ({
  percentage,
  labels,
  successText,
  size = 252,
  lineW = 20,
  n = 8,
  labelGap = 20,
  ...containerProps
}) => {
  const progress = (percentage / 100) * 360;
  const markers = [0, progress];
  const [labelW, labelH] = [76, 42];

  return (
    <Box
      w={size + (labelW + labelGap) * 2}
      h={size + (labelH + labelGap) * 2}
      {...containerProps}
    >
      {labels?.map(({ index, text, title }, idx) => {
        const angle = (360 / n) * index;
        return (
          index < n && (
            <Label
              key={idx}
              gap={2}
              {...{
                D: size + labelGap * 2 + labelW,
                angle,
                w: labelW,
                h: labelH,
              }}
            >
              <Title>{title}</Title>
              <Text>{text}</Text>
            </Label>
          )
        );
      })}
      <Container size={size}>
        {Array.from({ length: n }, (_, index) => {
          const angle = (360 / n) * index;
          const isVisible = angle > progress;
          return (
            <Spot
              key={index}
              {...{
                d: lineW,
                D: size - lineW,
                angle,
                isVisible,
              }}
            />
          );
        })}
        {markers.map((angle, idx) => (
          <Marker key={idx} d={lineW} D={size - lineW} angle={angle} />
        ))}
        <Circle size={size} d={lineW} progress={progress} />
        {successText && (
          <SuccessText maxW={size - lineW * 2 - 16}>{successText}</SuccessText>
        )}
      </Container>
    </Box>
  );
};

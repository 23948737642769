import {
  IPriceRange,
  ITokenTickerIconPair,
  SupportedChainId,
} from "@aperture/types";
import { FeeAmount } from "@aperture_finance/uniswap-v3-sdk";

export enum PriceRangeKey {
  MIN = "min",
  MAX = "max",
  SWAP_MIN = "swapMin",
  SWAP_MAX = "swapMax",
}

export interface IPriceRangeWithTick extends IPriceRange {
  tickLower: number;
  tickUpper: number;
}
export interface IPositionHistory {
  positionId: string;
  isActive: boolean;
}

export interface PositionCardProps {
  id: string;
  chainId: string;
  supportedChainId: SupportedChainId;
  positionId: string;
  tokens: ITokenTickerIconPair;
  feeTier: FeeAmount;
  liquidity: string;
  fees: string;
  priceRange: IPriceRange;
  tokenPercentages: [string, string];
  totalAPR: string;
  feeAPR: string;
  positionHistory?: IPositionHistory[];
  autoCompound?: Boolean;
  rebalanced?: Boolean;
  recurringRebalanced?: Boolean;
  prescheduledPosition?: Boolean;
  limitOrder?: Boolean;
  isCurrentChain: Boolean;
  isManta: Boolean;
  closed?: Boolean;
  closedInfo?: ClosedPositionInfo;
  inRange: Boolean;
  selected: Boolean;
  atStake: Boolean;
  checked?: Boolean;
  sm?: boolean;
  onClick?: (pid: string) => void;
}

export interface ClosedPositionInfo {
  exitedValueUsd: string;
  exitedCollectedIncomeUsd: string;
  dateTime: string;
}

export interface IClosedPosition {
  positionId: string;
  closedTimestamp: number;
  closedLiquidityUSD: string;
  closedAccruedFeesUSD: string;
  closedPriceRange: IPriceRange;
  closedMarketPrice: string; // sqrtX96Price
  closedTotalAPR: string;
  closedFeeAPR: string;
}

export interface IClosedPositionCardProps extends IClosedPosition {
  tokens: ITokenTickerIconPair;
  supportedChainId: SupportedChainId;
  isCurrentChain: boolean;
  onClick?: () => void;
}

import { IToken } from "@aperture/types";
import { Flex } from "@ui/common";
import React, { useMemo, useRef, useState } from "react";
import { Dropdown, IDropdownRef } from "../Dropdown";
import { SearchInput } from "../Input";
import { TokenIcons } from "../TokenIcons";
import { ButtonText } from "../Typography";
import {
  ArrowDown,
  ArrowDownWrapper,
  Balance,
  ButtonContainer,
  ContentContainer,
  SelectedTokenIconWrapper,
  Title,
  TokenItem,
  TokenItemIcon,
  TokenList,
} from "./style";
import { ITokenSelectorProps } from "./types";

export const TokenSelector: React.FC<ITokenSelectorProps> = ({
  enableSearch,
  selectedToken = undefined,
  size = "large",
  title = undefined,
  tokens,
  sameTokens = undefined,
  onSelect,
  onSelectWSameTokens,
  ...props
}) => {
  const canSelectSameTokens = !!sameTokens && onSelectWSameTokens;
  const [inputValue, setInputValue] = useState("");

  const dropdownRef = useRef<IDropdownRef>(null);
  const handleSelect = (token: IToken | [IToken, IToken]) => {
    if (canSelectSameTokens) {
      onSelectWSameTokens(token);
    } else if (onSelect) {
      onSelect(token as IToken);
    }
    dropdownRef?.current?.onClose();
  };

  const list = useMemo(() => {
    const trimmedValue = inputValue.toLowerCase().trim();
    const filtered = trimmedValue
      ? tokens?.filter(
          (token) =>
            token.ticker?.toLowerCase().indexOf(trimmedValue) > -1 ||
            token.address.toLowerCase().indexOf(trimmedValue) > -1
        )
      : tokens;

    const includesSameTokens =
      canSelectSameTokens &&
      (!trimmedValue || "same tokens".includes(trimmedValue));

    return includesSameTokens ? [sameTokens, ...filtered] : filtered;
  }, [canSelectSameTokens, inputValue, sameTokens, tokens]);

  const dropdownBtn = (
    <ButtonContainer size={size} {...props}>
      {selectedToken ? (
        <Flex alignItems="center" gap={6}>
          {Array.isArray(selectedToken) ? (
            <TokenIcons
              leftIcon={selectedToken[0].Icon}
              rightIcon={selectedToken[1].Icon}
              size={24}
              overlap={8}
              overlapRight
            />
          ) : (
            <SelectedTokenIconWrapper>
              {selectedToken.Icon}
            </SelectedTokenIconWrapper>
          )}
          <ButtonText>
            {Array.isArray(selectedToken)
              ? "Same Tokens"
              : selectedToken.ticker}
          </ButtonText>
        </Flex>
      ) : (
        <ButtonText>
          {size === "large" ? "Select A Token" : "Select"}
        </ButtonText>
      )}
      <ArrowDownWrapper>
        <ArrowDown />
      </ArrowDownWrapper>
    </ButtonContainer>
  );

  return (
    <Dropdown ref={dropdownRef} button={dropdownBtn} position="bottomRight">
      <ContentContainer>
        <Title showSearch={!!enableSearch}>
          {title ? title : "Select Token"}
        </Title>
        {enableSearch && (
          <SearchInput
            onChange={setInputValue}
            placeholder="Search Name or Address"
          />
        )}
        <TokenList>
          {list.map((token, index) => {
            const isSameTokens = Array.isArray(token);
            return (
              <TokenItem
                key={
                  isSameTokens
                    ? `${sameTokens}-${index}`
                    : `${token.address}-${token.native}`
                }
                onClick={() => {
                  handleSelect(token);
                }}
              >
                {isSameTokens ? (
                  <>
                    <TokenIcons
                      leftIcon={token[0].Icon}
                      rightIcon={token[1].Icon}
                      size={32}
                      overlap={16}
                      overlapRight
                    />
                    <ButtonText>Same Tokens</ButtonText>
                  </>
                ) : (
                  <>
                    <TokenItemIcon>{token.Icon}</TokenItemIcon>
                    <ButtonText>{token.ticker}</ButtonText>
                    <Balance>{token.formatAmount}</Balance>
                  </>
                )}
              </TokenItem>
            );
          })}
        </TokenList>
      </ContentContainer>
    </Dropdown>
  );
};

import React from "react";
import styled from "styled-components";
import { AvatarProps } from "./types";

const StyledAvatar = styled.div`
  width: 32px;
  height: 32px;
  img {
    transform: scaleX(-1);
    width: 32px;
    height: 32px;
    border-radius: ${({ theme }) => theme.radii.circle};
    background-color: #d7cf15;
  }
`;

export const Avatar: React.FC<AvatarProps> = ({ text, ...props }) => {
  const src = `https://robohash.org/${text}.png`;
  return (
    <StyledAvatar {...props}>
      <img src={src} alt="avatar" />
    </StyledAvatar>
  );
};

import { notificationVariants } from "./types";

export const styleVariants = {
  [notificationVariants.DEFAULT]: {
    color: "primary.greyPurple",
    borderColor: "primary.greyPurple",
    backgroundColor: "transparent.transparent",
    stroke: "primary.greyPurple",
    stopColor: "primary.greyPurple",
  },
  [notificationVariants.WARNING]: {
    color: "global.warning",
    stroke: "global.warning",
    stopColor: "global.warning",
  },
  [notificationVariants.ERROR]: {
    color: "global.error",
    stroke: "global.error",
    stopColor: "global.error",
  },
  [notificationVariants.SUCCESS]: {
    color: "global.success",
    backgroundColor: "transparent.transparent",
    stroke: "positive.green",
    stopColor: "positive.green",
  },
};

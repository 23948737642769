import { ExchangeIcon2 } from "@aperture/assetkit";
import { RebalanceActionTab } from "@aperture/types";
import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { Tab } from "../Tab";
import { TokenPrice } from "./TokenPrice";
import { ITokenPriceGroupProps } from "./types";

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
`;
const Title = styled.div`
  width: 100%;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
`;
const SwitchMenuTab = styled(Tab)<{ tabPosition: String }>`
  position: absolute;
  ${({ tabPosition }) => (tabPosition === "right" ? "right: 0;" : "left: 0;")}
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.xl};
`;

const DoubleArrowWrapper = styled.div<{ showIcon?: Boolean }>`
  display: ${({ showIcon }) => (showIcon ? "flex" : "none")};
  align-items: center;
  transform: rotate(90deg);
`;

export const TokenPriceGroup: React.FC<ITokenPriceGroupProps> = ({
  buyToken,
  sellToken,
  tabPosition = "right",
  title = <></>,
  futureSpotTab,
  setSelected = () => {},
  ...props
}) => {
  const theme = useTheme();
  const [tabIdx, setTabIdx] = useState(0);
  const tabs = [buyToken.ticker, sellToken.ticker];
  const isBuy = tabIdx === 0;

  return (
    <GroupContainer {...props}>
      <Title>
        {title}
        <SwitchMenuTab
          tabPosition={tabPosition}
          type="pill"
          tabs={tabs}
          selectedIdx={tabIdx}
          onSelect={(value: number) => {
            setTabIdx(value);
            setSelected(tabs[value]);
          }}
        />
      </Title>
      <Content>
        <TokenPrice
          title="Min"
          subTitle={
            futureSpotTab === RebalanceActionTab.PERCENTAGES
              ? "below future spot price"
              : `
            ${isBuy ? sellToken.ticker : buyToken.ticker} per 
            ${isBuy ? buyToken.ticker : sellToken.ticker}`
          }
          amount={`${isBuy ? buyToken.min : sellToken.min}`}
        />
        <DoubleArrowWrapper showIcon={true}>
          <ExchangeIcon2
            width={24}
            height={24}
            fill={theme.colors.global.text.T1}
          />
        </DoubleArrowWrapper>
        <TokenPrice
          title="Max"
          subTitle={
            futureSpotTab === RebalanceActionTab.PERCENTAGES
              ? "above future spot price"
              : `
            ${isBuy ? sellToken.ticker : buyToken.ticker} per 
            ${isBuy ? buyToken.ticker : sellToken.ticker}`
          }
          amount={`${isBuy ? buyToken.max : sellToken.max}`}
        />
      </Content>
    </GroupContainer>
  );
};

import { Flex } from "@aperture/uikitv2";
import { CSSProperties } from "react";
import styled from "styled-components";
import { Tag } from "../Tag";

export const TitleContainer = styled(Flex)<{
  drawerOpen: boolean;
  forceLeft: boolean;
}>`
  justify-content: ${({ drawerOpen, forceLeft }) =>
    !forceLeft && drawerOpen ? "space-between" : "flex-start"};
  align-items: center;
`;

export const TokenText = styled.div<{ size: "regular" | "sm" | "lg" | "lg2" }>`
  ${({ size }) =>
    size === "sm"
      ? `
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      `
      : size === "lg"
      ? `
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      `
      : size === "lg2"
      ? `
      font-size: 24px;
      font-weight: 500;
      line-height: 28.13px;
      `
      : `
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      `};
  font-style: normal;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const PercentageTag = styled(Tag)<{
  padding: string;
  color?: CSSProperties["color"];
}>`
  padding: ${({ padding }) => padding};
  background-color: ${({ theme }) => theme.colors.global.success};
  color: ${({ theme, color }) => color ?? theme.colors.grey.black};
  transition: none;
`;

export const TokenWrapper = styled(Flex)`
  align-items: center;
`;

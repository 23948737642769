// Define the strings

export const strings = {
  positionRangeAtExitLabel: "Position range at exit",
  positionIdLabel: "Position ID",
  lifetimeFeeRevenueEarnedLabel: "Lifetime fee revenue earned",
  lifetimeFeeRevenueTip:
    "This is the total fee revenue earned from this position, since this position becomes part of the current strategy till it’s closed.",
  positionLiquidityAtExitLabel: "Position liquidity at exit",
  lifetimeGasFeeSpentLabel: "Lifetime gas fee spent",
  lifetimeGasFeeSpentTip:
    "This is the total gas fee spent by this position, since this position becomes part of the current strategy till it’s closed.",
  totalDurationLabel: "Total Duration",
};

import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: "Dunbar Tall";
  src: url("https://d40r7rvp4exit.cloudfront.net/fonts/DUNBARTALL.TTF") format("truetype"),
        url("https://d40r7rvp4exit.cloudfront.net/fonts/DunbarTall-Regular.ttf");
  font-display: swap;
}

  body {
    margin: 0;
    font-family: 'Roboto Flex','Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 0px;
  }

  * {
      transition: width 0.3s ease, 
      height 0.3s ease, 
      padding 0.3s ease,
      left 0.3s ease,
      top 0.3s ease,
      opacity 0.3s ease,
      transform 0.3s ease,
      translateZ(0),
      background-color 0s,
      color 0s !important,
      backface-visibility: hidden;
    }

  input {
  font-family: 'Roboto Flex','Dunbar Tall','Poppins', sans-serif;
  }

  button {
  font-family: 'Roboto Flex','Dunbar Tall','Poppins', sans-serif;
  }

  .fbYFoQ{
    z-index: 9 !important;
  }
  .BmFKr{
    z-index: 9 !important;
  }

`;

import { CaretIcon } from "@aperture/assetkit";
import { Flex } from "@uiv2/common";
import { T2, T3Regular } from "@uiv2/components/Typography";
import styled from "styled-components";

export const SelectedToken = styled(Flex)<{ disabled?: boolean }>`
  width: 254px;
  height: 40px;
  padding: 0 14.5px 0 12px;
  align-items: center;
  box-sizing: border-box;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.global.text.T4 : theme.colors.global.text.T1};
  background-color: ${({ theme, disabled }) =>
    disabled
      ? theme.colors.global.background.BG4
      : theme.colors.global.background.BG2};
  border-radius: ${({ theme }) => theme.radii.round};
  &:hover {
    opacity: 0.8;
  }
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
`;

export const SelectedTokenIconWrapper = styled.div`
  margin-right: 8px;
  height: 28px;
  width: 28px;
`;

export const ArrowDownWrapper = styled.div`
  margin-left: auto;
  > svg,
  path {
    fill: ${({ theme }) => theme.colors.global.text.T1};
  }
`;

export const ArrowDown = styled(CaretIcon)`
  width: 20px;
  height: 20px;
`;

export const StyledT3 = styled(T3Regular)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const StyledT2 = styled(T2)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

import { Flex, T3Regular } from "@aperture/uikitv2";
import {
  formatMarketPrice,
  formatTokenAmount,
  formatUSDAmount,
} from "@ui/utils";
import bigDecimal from "js-big-decimal";
import { useState } from "react";
import { NoteCard } from "../NoteCard";
import { getStrategyText } from "./getStrategyText";
import {
  B1,
  Box,
  InfoContent,
  Item,
  ItemTitle,
  ItemValue,
  StrategyInfoBox,
  StyledDeleteIcon,
  StyledEditIcon,
  SwapButton,
  SwapButtonIcon,
  Title,
  TitleButtons,
  TitleRow,
  ValueWrapper,
} from "./style";
import { StrategyInfoCardProps } from "./types";

export const StrategyInfoCard: React.FC<StrategyInfoCardProps> = ({
  tokens,
  conditionSpotPrice,
  triggerInfo,
  timeBuffer,
  frequency,
  rebalancesExecuted,
  revenueEarned,
  onEdit,
  onDelete,
  renderNotification,
  autoCompoundBtn,
}) => {
  const { total, token0, token1 } = revenueEarned;
  const isDualAction = triggerInfo.length > 1;

  const strategyText = triggerInfo.map(
    ({ triggerType, rebalanceType, triggerValue, rebalanceValue }, idx) => {
      const text = getStrategyText(
        triggerType,
        rebalanceType,
        tokens,
        triggerValue,
        rebalanceValue
      );
      return isDualAction ? { ...text, Trigger: text.Trigger[idx] } : text;
    }
  );
  const triggerData = isDualAction
    ? strategyText.reduce((acc, text, idx) => {
        acc = {
          ...acc,
          [`Trigger ${idx + 1}`]: text.Trigger,
          [`New Price Range${idx + 1}`]: text["New Price Range"],
        };
        return acc;
      }, {})
    : strategyText[0];

  const [swap, setSwap] = useState(false);
  const data = {
    "Condition Spot Price": conditionSpotPrice ? (
      <Flex gap="md">
        <T3Regular>
          {formatMarketPrice(
            swap
              ? bigDecimal.divide(1, conditionSpotPrice, 18)
              : conditionSpotPrice
          )}{" "}
          {tokens[swap ? 0 : 1].ticker} per {tokens[swap ? 1 : 0].ticker}
        </T3Regular>
        <SwapButton
          onClick={(event) => {
            event.stopPropagation();
            setSwap(!swap);
          }}
        >
          <SwapButtonIcon />
        </SwapButton>
      </Flex>
    ) : undefined,
    ...triggerData,
    "TWAP Duration": timeBuffer
      ? `${timeBuffer} ${
          bigDecimal.compareTo(timeBuffer, 1) > 0 ? "hrs" : "hr"
        }`
      : undefined,
    "Max Frequency": frequency
      ? `${frequency} time${
          bigDecimal.compareTo(frequency, 1) > 0 ? "s" : ""
        } / day`
      : undefined,
    "# of Rebalances Executed": rebalancesExecuted,
  };

  return (
    <StrategyInfoBox>
      <TitleRow>
        <Title>
          <B1>Strategy Info</B1>
        </Title>
        <TitleButtons>
          {autoCompoundBtn}
          <StyledEditIcon onClick={onEdit} />
          <StyledDeleteIcon onClick={onDelete} />
        </TitleButtons>
      </TitleRow>
      <InfoContent>
        <Box>
          {Object.entries(data)
            .slice(0, 3)
            .map(([label, value]) => {
              const isArray = Array.isArray(value);
              return (
                value && (
                  <Item key={label}>
                    <ItemTitle>
                      {isDualAction && label.includes("New Price Range")
                        ? label.slice(0, -1)
                        : label}
                    </ItemTitle>
                    <ValueWrapper>
                      {isArray ? (
                        value.map((v, idx) => (
                          <ItemValue key={idx}>{v}</ItemValue>
                        ))
                      ) : (
                        <ItemValue>{value}</ItemValue>
                      )}
                    </ValueWrapper>
                  </Item>
                )
              );
            })}
          {Object.entries(data)
            .slice(3)
            .map(([label, value]) => {
              const isArray = Array.isArray(value);
              return (
                value && (
                  <Item key={label}>
                    <ItemTitle>
                      {isDualAction && label.includes("New Price Range")
                        ? label.slice(0, -1)
                        : label}
                    </ItemTitle>
                    <ValueWrapper>
                      {isArray ? (
                        value.map((v, idx) => (
                          <ItemValue key={idx}>{v}</ItemValue>
                        ))
                      ) : (
                        <ItemValue>{value}</ItemValue>
                      )}
                    </ValueWrapper>
                  </Item>
                )
              );
            })}
          <Item>
            <ItemTitle>Total Fee Earned</ItemTitle>
            <ValueWrapper>
              <ItemValue>{formatUSDAmount(total)}</ItemValue>
              <B1>{`${formatTokenAmount(token0.amount)} ${
                tokens[0].ticker
              } (~${formatUSDAmount(token0.usdAmount)})`}</B1>
              <B1>{`${formatTokenAmount(token1.amount)} ${
                tokens[1].ticker
              } (~${formatUSDAmount(token1.usdAmount)})`}</B1>
            </ValueWrapper>
          </Item>
        </Box>
      </InfoContent>
      <NoteCard text="Your uncollected fees will be rebalanced into your next position." />
      {renderNotification?.()}
    </StrategyInfoBox>
  );
};

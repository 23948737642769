import { CaretIcon } from "@aperture/assetkit";
import { Flex } from "@uiv2/common";
import styled from "styled-components";
import { T2 } from "../Typography";

export const Title = styled(Flex)<{ clickable: boolean }>`
  cursor: ${({ clickable }) => (clickable ? "pointer" : "auto")};
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const TitleText = styled(T2)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const ArrowBtn = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ArrowDown = styled(CaretIcon)<{ isShow: boolean }>`
  width: 24px;
  height: 24px;
  transition: transform ${({ theme }) => theme.transitions.default};
  transform: ${({ isShow }) => (isShow ? "rotate(180deg)" : "")};
  path {
    fill: ${({ theme }) => theme.colors.global.text.T1};
  }
`;

export const Content = styled.div<{
  isShow: boolean;
  useOverflow: boolean;
  length: number;
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
  overflow: ${({ useOverflow }) => (useOverflow ? "initial" : "hidden")};
  padding-top: ${({ isShow }) => (isShow ? "12px" : "0")};
  height: ${({ isShow, length }) =>
    isShow ? `calc(${421 * length}px + ${12 * length}px)` : "0"};
  transition: height ${({ theme }) => theme.transitions.default},
    padding-top ${({ theme }) => theme.transitions.default};
`;

import { useClickOutside } from "@ui/common/hooks/useClickOutSide";
import React, { useCallback, useEffect, useRef } from "react";
import { ButtonText } from "../Typography";
import {
  ArrowDown,
  Container,
  SelectButton,
  SelectList,
  SelectListContainer,
  SelectListItem,
} from "./style";
import { ISelectOption, ISelectOptionLabel, ISelectProps } from "./type";

export function Select<T extends string>({
  bordered = true,
  selected,
  onSelect,
  disabled,
  options,
  width,
  ...props
}: ISelectProps<T>) {
  const [open, setOpen] = React.useState(false);
  const selectedOption =
    options.find((option) => option.value === selected) || options[0];

  const close = useCallback(() => {
    setOpen(false);
  }, []);
  const handleSelect = useCallback(
    (value: T, option: ISelectOption<T>) => {
      onSelect && onSelect(value, option);
      close();
    },
    [close, onSelect]
  );

  useEffect(() => {
    if (disabled) {
      close();
    }
  }, [close, disabled]);

  const ref = useRef<HTMLDivElement>(null);
  useClickOutside([ref], close);

  return (
    <Container bordered={bordered} width={width || "100%"} {...props} ref={ref}>
      <SelectButton
        disabled={disabled}
        onClick={() => {
          if (!disabled) {
            setOpen(!open);
          }
        }}
      >
        {renderLabel(selectedOption.label)}
        <ArrowDown />
      </SelectButton>
      <SelectListContainer>
        {open && (
          <SelectList>
            {options.map((option) => (
              <SelectListItem
                key={option.value}
                onClick={() => handleSelect(option.value, option)}
              >
                {renderLabel(option.label)}
              </SelectListItem>
            ))}
          </SelectList>
        )}
      </SelectListContainer>
    </Container>
  );
}

const renderLabel = (label: ISelectOptionLabel) => {
  return typeof label === "string" ? <ButtonText>{label}</ButtonText> : label;
};

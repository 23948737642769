import { Checkbox } from "@ui/components/Checkbox";
import { getChainInfo } from "@ui/utils";
import React from "react";
import { LgPositionCard } from "./LgPositionCard";
import { Container } from "./style";
import { PositionCardProps } from "./types";

export const PositionCardComponent: React.FC<PositionCardProps> = ({
  id,
  chainId,
  supportedChainId,
  positionId,
  selected = false,
  atStake,
  checked,
  sm = false,
  onClick,
  ...props
}) => {
  const { name, Icon } = getChainInfo(supportedChainId);

  return (
    <Container
      tabIndex={0}
      selected={selected}
      onClick={() => onClick?.(`${chainId}-${positionId}`)}
      id={id}
    >
      {checked !== undefined && <Checkbox checked={checked} />}
      <LgPositionCard
        {...{ ...props, positionId, selected, atStake, sm, name, Icon }}
      />
    </Container>
  );
};

export const PositionCard = React.memo(PositionCardComponent);

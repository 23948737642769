import { CaretIcon } from "@aperture/assetkit";
import styled from "styled-components";
import { IconWrapper } from "../Button/styles";
import { Input } from "../Input";
import { InputWithTicker } from "../Input/InputWithTicker";
import { Notification } from "../Notification";
import { TokenSelector } from "../TokenSelector";
import { CommonLgText } from "../Typography";

export const TriggerSetupContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
  box-sizing: border-box;
`;

export const Title = styled(CommonLgText)`
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const StyledTokenSelector = styled(TokenSelector)`
  background: ${({ theme }) => theme.colors.global.background.BG3};
  padding: 0 12px;
  svg:first-child {
    height: 24px !important;
    width: 24px !important;
  }
  > svg,
  path {
    fill: ${({ theme }) => theme.colors.global.text.T1};
  }
`;

export const AboveBelowBtn = styled.div`
  background: ${({ theme }) => theme.colors.global.background.BG3};
  min-width: 120px;
  height: 40px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.global.text.T1};
  padding: 0 12px;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.radii.round};
  display: flex;
  align-items: center;
  cursor: pointer;
  > svg,
  path {
    fill: ${({ theme }) => theme.colors.global.text.T1};
  }
`;

export const StyledIconWrapper = styled(IconWrapper)`
  width: 100%;
`;

export const ArrowDown = styled(CaretIcon)`
  width: 20px;
  height: 20px;
  margin-left: auto;
`;

export const AboveBelowContent = styled.ul`
  list-style: none;
  padding: 10px 24px;
  margin: 0;
  > li {
    font-size: 16px;
    font-weight: 500;
    height: 32px;
    line-height: 32px;
    cursor: pointer;
  }
`;

export const StyledInputWithTicker = styled(InputWithTicker)`
  min-width: 293px;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.global.background.BG3};
  & + div {
    top: 11px;
    right: 24px;
    color: ${({ theme }) => theme.colors.global.text.T1};
  }
`;

export const TimeBuffer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const InputWapper = styled.div`
  position: relative;
`;

export const Checkbox = styled.input.attrs({ type: "checkbox" })`
  cursor: pointer;
  font-size: 14px;
  height: 20px;
  width: 20px;
  position: relative;
  background: ${({ theme }) => theme.colors.transparent.transparent};
  outline: none;
  box-shadow: none;
  appearance: none;
  border-radius: ${({ theme }) => theme.radii.round};
  &:after {
    position: absolute;
    width: 18px;
    height: 18px;
    left: -2px;
    top: -2px;
    content: "";
    display: inline-block;
    visibility: visible;
    background: ${({ theme }) => theme.colors.global.background.BG2};
    border: 1px solid ${({ theme }) => theme.colors.global.line.Line2};
    border-radius: ${({ theme }) => theme.radii.round};
  }
  &:checked:after {
    content: "✔";
    text-align: center;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.global.text.T1};
    border: 1px solid ${({ theme }) => theme.colors.global.line.Line2};
  }
`;

export const SliderLabels = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4px 0 20px 0;
  color: ${({ theme }) => theme.colors.global.text.T3};
  span {
    color: ${({ theme }) => theme.colors.global.text.T1};
  }
`;

export const TimeSection = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const StyledNotification = styled(Notification)`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;

export const StyledInputText = styled(CommonLgText)`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
`;

export const StyledInput = styled(Input)`
  max-width: 160px;
  background: ${({ theme }) => theme.colors.global.background.BG3};
`;

import { FlexColumn } from "@uiv2/common";
import { H2, T2 } from "@uiv2/components/Typography";
import styled from "styled-components";
import { StakePositionProps } from "../types";

const ContentWrapper = styled(FlexColumn)`
  width: 400px;
  gap: ${({ theme }) => theme.spacing.xl};
`;
const TitleText = styled(H2)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const Text = styled(T2)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const StakePositionContent: React.FC<StakePositionProps> = ({
  amm,
  atStake,
}) => {
  return (
    <ContentWrapper>
      <TitleText>
        {`${amm} Position ${atStake ? "Unstake" : "Stake"}`}
      </TitleText>
      <Text>
        {atStake
          ? `After unstaking the staked ${amm} position, you will be able to use the manual and automation related features for the unstaked position.`
          : "Once your position is successfully staked, automation triggers and strategies will stop working and will eventually be deleted."}
      </Text>
    </ContentWrapper>
  );
};

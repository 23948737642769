import { ExchangeIcon2 } from "@aperture/assetkit";
import { RebalanceActionTab } from "@ui/common";
import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { SwitchMenuTab } from "../Tabs";
import { TokenPrice } from "./TokenPrice";
import { ITokenPriceGroupProps } from "./types";

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
`;
const Title = styled.div`
  width: 100%;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
`;
const Tab = styled(SwitchMenuTab)<{ tabPosition: String }>`
  position: absolute;
  ${({ tabPosition }) => (tabPosition === "right" ? "right: 0;" : "left: 0;")}
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.xl};
`;

const DoubleArrowWrapper = styled.div<{ showIcon?: Boolean }>`
  display: ${({ showIcon }) => (showIcon ? "flex" : "none")};
  align-items: center;
  transform: rotate(90deg);
`;

export const TokenPriceGroup: React.FC<ITokenPriceGroupProps> = ({
  buyToken,
  sellToken,
  tabPosition = "right",
  title = <></>,
  futureSpotTab,
  setSelected = () => {},
  ...props
}) => {
  const [tab, setTab] = useState<"buy" | "sell">("buy");
  const theme = useTheme();
  return (
    <GroupContainer {...props}>
      <Title>
        {title}
        <Tab
          tabPosition={tabPosition}
          menu={[buyToken.ticker, sellToken.ticker]}
          defaultSelected={[buyToken.ticker, sellToken.ticker].indexOf(
            buyToken.ticker
          )}
          onSelect={(value: string) => {
            setTab(buyToken.ticker === value ? "buy" : "sell");
            setSelected(value);
          }}
          width={140}
        />
      </Title>
      <Content>
        <TokenPrice
          title="Min"
          subTitle={
            futureSpotTab === RebalanceActionTab.PERCENTAGES
              ? "below future spot price"
              : `
            ${tab === "buy" ? sellToken.ticker : buyToken.ticker} per 
            ${tab === "buy" ? buyToken.ticker : sellToken.ticker}`
          }
          amount={`${tab === "buy" ? buyToken.min : sellToken.min}`}
        />
        <DoubleArrowWrapper showIcon={true}>
          <ExchangeIcon2
            width={24}
            height={24}
            fill={theme.colors.global.text.T1}
          />
        </DoubleArrowWrapper>
        <TokenPrice
          title="Max"
          subTitle={
            futureSpotTab === RebalanceActionTab.PERCENTAGES
              ? "above future spot price"
              : `
            ${tab === "buy" ? sellToken.ticker : buyToken.ticker} per 
            ${tab === "buy" ? buyToken.ticker : sellToken.ticker}`
          }
          amount={`${tab === "buy" ? buyToken.max : sellToken.max}`}
        />
      </Content>
    </GroupContainer>
  );
};

import { useState } from "react";
import { PositionBreakdownByToken } from "./PositionBreakdownByToken";
import { PositionBreakdownByTotal } from "./PositionBreakdownByTotal";
import { PositionBreakdownByValue } from "./PositionBreakdownByValue";
import { Container, Heading, StyledTab } from "./style";
import { PositionBreakdownChartProps } from "./types";

export const PositionBreakdownChart: React.FC<PositionBreakdownChartProps> = ({
  ...props
}) => {
  const [tabIdx, setTabIdx] = useState(0);
  return (
    <Container>
      <Heading>Position Breakdown</Heading>
      <StyledTab
        size="sm"
        type="pill"
        tabs={["By Token", "By Value", "By Total Value"]}
        selectedIdx={tabIdx}
        onSelect={(value: number) => setTabIdx(value)}
      />
      {tabIdx === 0 && <PositionBreakdownByToken {...props} />}
      {tabIdx === 1 && <PositionBreakdownByValue {...props} />}
      {tabIdx === 2 && <PositionBreakdownByTotal {...props} />}
    </Container>
  );
};

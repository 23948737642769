import { RecurringAction } from "@aperture_finance/uniswap-v3-automation-sdk";
import { RebalanceActionTab, TriggerType } from "@ui/common";
import bigDecimal from "js-big-decimal";
import {
  E_MaxFreqPeriod,
  IDualTriggerState,
  IEditTriggerState,
  IStrategyTriggerState,
} from "./type";

export const DEFAULT_TIME_BUFFER = 0.25;
export const MAX_TIME_BUFFER = 24;

export const emptyForm: IStrategyTriggerState = {
  baseToken: 0,
  triggerType: TriggerType.PRICE,

  timeBufferChecked: false,
  aboveChecked: true,
  belowChecked: true,
  maxFreqChecked: false,

  timeBuffer: DEFAULT_TIME_BUFFER.toString(),
  above: "0",
  below: "0",
  maxFreq: 5,
  maxFreqPeriod: E_MaxFreqPeriod.Week,
};
export const emptyDualTriggerForm: IDualTriggerState = {
  baseToken: 0,
  triggerType: TriggerType.PRICE,

  timeBufferChecked: false,
  aboveChecked: false,
  belowChecked: false,

  timeBuffer: DEFAULT_TIME_BUFFER.toString(),
  above: "0",
  below: "0",

  aboveAction: undefined,
  belowAction: undefined,
};

export function getDefaultCacheForm(
  currentTokenRatio: number,
  spotPrice: string,
  decimals: number,
  isDualTrigger?: boolean
) {
  const cacheEmptyForm = isDualTrigger ? emptyDualTriggerForm : emptyForm;
  return {
    [TriggerType.PRICE]: {
      ...cacheEmptyForm,
      triggerType: TriggerType.PRICE,
      ...getTokenDefaultPrice(spotPrice, decimals),
    },
    [TriggerType.PRICE_PERCENTAGE]: {
      ...cacheEmptyForm,
      triggerType: TriggerType.PRICE_PERCENTAGE,
      above: "20",
      below: "20",
    },
    [TriggerType.RATIO]: {
      ...cacheEmptyForm,
      triggerType: TriggerType.RATIO,
      above: `${Math.min(currentTokenRatio / 100 + 10, 100)}`,
      below: `${Math.max(currentTokenRatio / 100 - 10, 0)}`,
    },
  };
}

export function getTokenDefaultPrice(spotPrice: string, decimals: number) {
  const twenty = bigDecimal.round(
    bigDecimal.multiply(spotPrice, 0.2),
    decimals
  );
  return {
    above: spotPrice === "0" ? "0" : twenty,
    below: spotPrice === "0" ? "0" : twenty,
  };
}

export const TICK_MULTIPLIER = 1.0001;
export function offsetTickToPercentage(offsetTick: number, abs?: boolean) {
  let target = TICK_MULTIPLIER ** offsetTick * 1e4 - 1e4;
  if (abs) {
    target = Math.abs(target);
  }
  return (Math.round(target) / 100).toFixed(2);
}
export function percentageToOffsetTick(
  percentage: number,
  minPercentage: number,
  maxPercentage?: number
) {
  percentage = Math.round(percentage * 100) / 100;
  let target = 0;
  if (percentage <= minPercentage) {
    target = Math.round((100 + minPercentage) * 100) / 1e4 + 1 / 10e6;
  } else if (maxPercentage !== undefined && percentage >= maxPercentage) {
    target = Math.round((100 + maxPercentage) * 100) / 1e4;
  } else {
    target = Math.round((100 + percentage) * 100) / 1e4;
  }

  return Math.round(Math.log(target) / Math.log(TICK_MULTIPLIER));
}

export const formatProportion = (proportion: string) => {
  return (parseFloat(proportion) * 100).toFixed(2);
};

export function generateTriggerList(data: IEditTriggerState) {
  const triggers = [];
  if (data.belowChecked && data.belowAction) {
    triggers.push({ ...data.belowAction, id: "below" });
  }
  if (data.aboveChecked && data.aboveAction) {
    triggers.push({ ...data.aboveAction, id: "above" });
  }
  return triggers;
}

export function getLowPrice(action: RecurringAction | null): string {
  const actionType = action?.type;
  switch (actionType) {
    case "RecurringPercentage":
      return action?.tickLowerOffset === undefined
        ? "-20.00"
        : offsetTickToPercentage(action.tickLowerOffset);
    case "RecurringPrice":
      return action?.priceLowerOffset ?? "-20.00";
    case "RecurringRatio":
      return action?.token0ValueProportion === undefined
        ? "50.00"
        : bigDecimal.round(
            bigDecimal.multiply(action.token0ValueProportion, 100),
            2
          );
    default:
      return "-20.00";
  }
}

export function getHighPrice(action: RecurringAction | null): string {
  const actionType = action?.type;
  switch (actionType) {
    case "RecurringPercentage":
      return action?.tickUpperOffset === undefined
        ? "20.00"
        : offsetTickToPercentage(action.tickUpperOffset);
    case "RecurringPrice":
      return action?.priceUpperOffset ?? "20.00";
    case "RecurringRatio":
      return action?.token0ValueProportion === undefined
        ? "50.00"
        : bigDecimal.round(
            bigDecimal.multiply(
              bigDecimal.subtract(1, action.token0ValueProportion),
              100
            ),
            2
          );
    default:
      return "20.00";
  }
}

export function getRebalanceActionTab(
  actionType: RecurringAction["type"]
): RebalanceActionTab {
  switch (actionType) {
    case "RecurringPrice":
      return RebalanceActionTab.TOKEN_TERMS;
    case "RecurringRatio":
      return RebalanceActionTab.RATIO;
    case "RecurringPercentage":
    default:
      return RebalanceActionTab.PERCENTAGES;
  }
}

export function getRebalanceType(
  actionTab: RebalanceActionTab
): RecurringAction["type"] {
  switch (actionTab) {
    case RebalanceActionTab.TOKEN_TERMS:
      return "RecurringPrice";
    case RebalanceActionTab.RATIO:
      return "RecurringRatio";
    case RebalanceActionTab.PERCENTAGES:
    default:
      return "RecurringPercentage";
  }
}

export function convertActionToClient(
  selectedToken: number,
  lowPrice: string,
  highPrice: string,
  tickWidth: string,
  rebalanceActionTab: RebalanceActionTab
): Partial<RecurringAction> {
  const actionType = getRebalanceType(rebalanceActionTab);

  switch (actionType) {
    case "RecurringPercentage":
      return {
        type: actionType,
        tickLowerOffset:
          selectedToken === 0
            ? percentageToOffsetTick(Number(lowPrice), -100, 0)
            : -percentageToOffsetTick(Number(highPrice), 0),
        tickUpperOffset:
          selectedToken === 0
            ? percentageToOffsetTick(Number(highPrice), 0)
            : -percentageToOffsetTick(Number(lowPrice), -100, 0),
      };
    case "RecurringPrice":
      return {
        type: actionType,
        baseToken: selectedToken as 0 | 1,
        priceLowerOffset: lowPrice,
        priceUpperOffset: highPrice,
      };
    case "RecurringRatio":
      return {
        type: actionType,
        tickRangeWidth: Number(tickWidth),
        token0ValueProportion: bigDecimal.divide(
          selectedToken === 0 ? lowPrice : highPrice,
          100,
          4
        ),
      };
    case "RecurringDualAction":
      return {};
    default:
      return {};
  }
}

import { DoubleArrowIcon } from "@aperture/assetkit";
import { Flex, FlexColumn, formatTokenAmount } from "@aperture/uikit";
import { FeeAmount } from "@aperture_finance/uniswap-v3-sdk";
import bigDecimal from "js-big-decimal";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { useIsomorphicEffect } from "../../../contexts/MatchBreakpoints/Provider";
import { FeeTierPercentage } from "../../FeeTier";
import { NavCard } from "../../NavCard";
import { Notification, notificationVariants } from "../../Notification";
import { Tag, variants } from "../../Tag";
import { TokenIcons } from "../../TokenIcons";
import {
  Body1,
  Headline5,
  Subtitle1,
  Subtitle2,
  Subtitle3,
} from "../../Typography";
import { OrderCardProps, OrderStatus } from "./types";

const OrderCardBox = styled(FlexColumn)`
  justify-content: center;
  padding: 16px;
  border-radius: ${({ theme }) => theme.radii.lg};
  gap: ${({ theme }) => theme.spacing.lg};
  background: ${({ theme }) => theme.colors.global.background.BG1};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
`;
const TokenWrapper = styled(Flex)`
  justify-content: space-between;
`;
const TokenInfo = styled(Flex)`
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm2};
`;
const StatusTag = styled(Tag)<{
  status: "in-progress" | "fulfilled" | "deleted" | "canceled";
}>`
  color: ${({ theme }) => theme.colors.global.textAlt.T1};
  font-family: "Roboto Flex";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  height: 20px;
  padding: 0 8px;
  background: ${({ theme, status }) =>
    status === "in-progress"
      ? theme.colors.global.success
      : status === "fulfilled"
      ? theme.colors.global.error
      : status === "deleted"
      ? theme.colors.global.error
      : theme.colors.global.warning};
`;
const TitleBtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TitleWrapper = styled(FlexColumn)`
  gap: ${({ theme }) => theme.spacing.sm};
`;
const TitleText = styled(Subtitle2)<{ sm: boolean }>`
  display: flex;
  flex-direction: ${({ sm }) => (sm ? "column" : "row")};
  gap: ${({ sm, theme }) => (sm ? theme.spacing.md : "6px")};
  color: ${({ theme }) => theme.colors.global.text.T1};
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
const DateTimeText = styled(Body1)`
  color: ${({ theme }) => theme.colors.global.text.T2};
  font-family: "Roboto Flex";
  line-height: 16px;
`;
const PoolBox = styled(FlexColumn)`
  align-items: flex-start;
  padding: 16px;
  gap: ${({ theme }) => theme.spacing.lg};
  background: ${({ theme }) => theme.colors.global.background.BG2};
  border-radius: ${({ theme }) => theme.radii.md};
`;
const PoolTokenWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm2};
`;
const MinMaxWrapper = styled(Flex)<{ sm: boolean }>`
  align-items: center;
  flex-direction: ${({ sm }) => (sm ? "column" : "row")};
`;
const MinMaxText = styled(FlexColumn)`
  align-items: flex-start;
  gap: 0;
`;
const MinMaxTitle = styled(Subtitle2)`
  color: ${({ theme }) => theme.colors.global.text.T3};
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
const DoubleArrowWrapper = styled.div<{ sm: boolean }>`
  display: flex;
  margin: ${({ sm }) => (sm ? "8px 0" : "0 8px")};
  svg {
    transform: ${({ sm }) => (sm ? "rotate(90deg)" : "none")};
    fill: ${({ theme }) => theme.colors.grey.mediumGrey};
  }
`;
const DetailsContainer = styled(FlexColumn)`
  gap: ${({ theme }) => theme.spacing.md};
`;
const DetailText = styled(Flex)<{ sm: boolean }>`
  justify-content: space-between;
  flex-direction: ${({ sm }) => (sm ? "column" : "row")};
  gap: ${({ sm }) => (sm ? "6px" : "0")};
`;
const FeeRevenueContainer = styled(FlexColumn)<{ sm: boolean }>`
  gap: ${({ sm }) => (sm ? "6px" : "8px")};
  text-align: ${({ sm }) => (sm ? "left" : "right")};
`;
const Title1 = styled(Subtitle1)`
  color: ${({ theme }) => theme.colors.global.text.T1};
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
`;
const Title2 = styled(Body1)`
  color: ${({ theme }) => theme.colors.global.text.T3};
  font-family: "Roboto Flex";
  line-height: 16px;
`;
const Title3 = styled(Subtitle3)`
  color: ${({ theme }) => theme.colors.global.text.T2};
`;

const OrderCard: React.FC<OrderCardProps> = ({
  status,
  from,
  to,
  dateTime = "",
  info = {
    buyAmount: 0,
    sellAmount: 0,
    limitPrice: 0,
    feeTier: FeeAmount.LOWEST,
    min: 0,
    max: 0,
    revenue: { earnedFeeInputToken: "0", earnedFeeOutputToken: "0" },
  },
  notification,
  Btn,
  navTo = () => {},
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [sm, setSm] = useState<boolean>(false);
  useIsomorphicEffect(() => {
    function updateSize() {
      if (ref.current) {
        setSm(ref.current.offsetWidth < 500);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const getTokenIcons = () => {
    return <TokenIcons leftIcon={from.Icon} rightIcon={to.Icon} size={20} />;
  };
  const hasEarnedFeeInputToken =
    bigDecimal.compareTo(info.revenue?.earnedFeeInputToken, "0") > 0;
  const hasEarnedFeeOutputToken =
    bigDecimal.compareTo(info.revenue?.earnedFeeOutputToken, "0") > 0;

  return (
    <OrderCardBox ref={ref}>
      <TokenWrapper>
        <TokenInfo>
          {getTokenIcons()}
          <Headline5>
            Buy {from.ticker} / Sell {to.ticker}
          </Headline5>
        </TokenInfo>
        {status === OrderStatus.ACTIVE && (
          <StatusTag variant={variants.PRIMARY} status="in-progress">
            In Progress
          </StatusTag>
        )}
        {status === OrderStatus.UNAUTHORIZED && (
          <StatusTag variant={variants.FAILED} status="fulfilled">
            Unauthorized
          </StatusTag>
        )}
        {status === OrderStatus.FULFILLED && (
          <StatusTag variant={variants.SUCCESS} status="in-progress">
            Fulfilled
          </StatusTag>
        )}
        {status === OrderStatus.CANCELED && (
          <StatusTag variant={variants.ERROR} status="canceled">
            Canceled
          </StatusTag>
        )}
      </TokenWrapper>
      <TitleBtnWrapper>
        <TitleWrapper>
          {(status === OrderStatus.ACTIVE ||
            status === OrderStatus.UNAUTHORIZED ||
            status === OrderStatus.CANCELED) && (
            <TitleText sm={sm}>
              <span>
                Buy {info.buyAmount} {from.ticker}
              </span>
              <span>
                @ {info.sellAmount} {to.ticker} Limit Price
              </span>
            </TitleText>
          )}
          <DateTimeText>{dateTime}</DateTimeText>
        </TitleWrapper>
        {(status === OrderStatus.ACTIVE ||
          status === OrderStatus.UNAUTHORIZED) &&
          Btn}
      </TitleBtnWrapper>
      {(status === OrderStatus.ACTIVE || status === OrderStatus.UNAUTHORIZED) &&
        notification !== undefined && (
          <Notification
            variant={notificationVariants.WARNING}
            text={notification}
          />
        )}
      {(status === OrderStatus.ACTIVE ||
        status === OrderStatus.UNAUTHORIZED ||
        status === OrderStatus.CANCELED) && (
        <PoolBox>
          <PoolTokenWrapper>
            <TokenInfo>
              {getTokenIcons()}
              <Title2>
                {from.ticker} / {to.ticker} Pool
              </Title2>
            </TokenInfo>
            <Tag padding={"0 8px"} variant={variants.RISE}>
              {(info.feeTier ?? FeeAmount.LOWEST) / FeeTierPercentage}%
            </Tag>
          </PoolTokenWrapper>
          <MinMaxWrapper sm={sm}>
            <MinMaxText>
              <MinMaxTitle>Min</MinMaxTitle>
              <Flex gap={8}>
                <Title1>{info.min}</Title1>
                <Title2>
                  {from.ticker} per {to.ticker}
                </Title2>
              </Flex>
            </MinMaxText>
            <DoubleArrowWrapper sm={sm}>
              <DoubleArrowIcon />
            </DoubleArrowWrapper>
            <MinMaxText>
              <MinMaxTitle>Max</MinMaxTitle>
              <Flex gap={8}>
                <Title1>{info.max}</Title1>
                <Title2>
                  {from.ticker} per {to.ticker}
                </Title2>
              </Flex>
            </MinMaxText>
          </MinMaxWrapper>
          {status === OrderStatus.CANCELED && (
            <NavCard
              title="You could find this in your Active Positions"
              navTo={navTo}
            />
          )}
        </PoolBox>
      )}
      {status === OrderStatus.FULFILLED && (
        <DetailsContainer>
          <DetailText sm={sm}>
            <Title3>Fulfilled Order</Title3>
            <Title2>
              Buy {info.buyAmount} {from.ticker} @ {info.limitPrice} {to.ticker}
            </Title2>
          </DetailText>
          {(hasEarnedFeeInputToken || hasEarnedFeeOutputToken) && (
            <DetailText sm={sm}>
              <Title3>Fee Revenue Earned</Title3>
              <FeeRevenueContainer sm={sm}>
                {hasEarnedFeeOutputToken && (
                  <Title2>
                    {formatTokenAmount(info.revenue?.earnedFeeOutputToken ?? 0)}{" "}
                    {from.ticker}
                  </Title2>
                )}
                {hasEarnedFeeInputToken && (
                  <Title2>
                    {formatTokenAmount(info.revenue?.earnedFeeInputToken ?? 0)}{" "}
                    {to.ticker}
                  </Title2>
                )}
              </FeeRevenueContainer>
            </DetailText>
          )}
        </DetailsContainer>
      )}
    </OrderCardBox>
  );
};

export default OrderCard;

import { useEffect, useRef, useState } from "react";
import { useTheme } from "styled-components";
import {
  averageArray,
  findMax,
  findMin,
  processPriceChartData,
} from "../utils";
import { D3CorrelationChart } from "./D3CorrelationChart";
import {
  Container,
  Heading,
  StatContainer,
  StatItem,
  StatTitle,
  StatValue,
} from "./style";
import { ChartPoint, CorrelationChartProps, VOLUME_DAYS } from "./types";

let d3Chart: D3CorrelationChart | null = null;
export const CorrelationChart: React.FC<CorrelationChartProps> = ({
  isFullRange = false,
  token0,
  token1,
  token0PriceChart,
  token1PriceChart,
  priceRangeValue,
  priceAssumptionValue,
  poolDayDatas,
}) => {
  const theme = useTheme();
  const [data, setData] = useState<ChartPoint[]>([]);
  const refElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!token0PriceChart || !token1PriceChart) return;
    const volumes = poolDayDatas
      ? poolDayDatas
          .map((data) => Number(data.volumeUSD))
          .slice(0, VOLUME_DAYS)
          .reverse()
      : [];
    const data = processPriceChartData(token0PriceChart, token1PriceChart);
    setData(data);
    if (d3Chart) d3Chart.destroy();

    d3Chart = new D3CorrelationChart(refElement.current, {
      data,
      volumes,
      width: 368,
      height: 177,
      minRange: priceRangeValue[0],
      maxRange: priceRangeValue[1],
      minPercentage: Math.round(
        ((priceRangeValue[0] - priceAssumptionValue) / priceRangeValue[0]) * 100
      ).toFixed(1),
      maxPercentage: Math.round(
        ((priceRangeValue[1] - priceAssumptionValue) / priceRangeValue[1]) * 100
      ).toFixed(1),
      mostActivePrice: priceAssumptionValue,
      colors: {
        graph: theme.colors.global.primaryHover,
        current: theme.colors.global.secondary,
        minMax: theme.colors.global.primary,
        axis: theme.colors.global.text.T2,
        tagBg: theme.colors.global.background.BG4,
        tagTxt: theme.colors.global.text.T1,
      },
    });

    return () => {
      d3Chart?.destroy();
    };
    // eslint-disable-next-line
  }, [
    refElement,
    theme.colors,
    priceRangeValue,
    priceAssumptionValue,
    token0PriceChart,
    token1PriceChart,
  ]);

  useEffect(() => {
    if (!d3Chart) return;

    d3Chart.updateMostActivePriceAssumption(priceAssumptionValue);
  }, [priceAssumptionValue]);

  useEffect(() => {
    if (!d3Chart) return;

    d3Chart.updateMinMaxPriceRange(
      priceRangeValue[0],
      priceRangeValue[1],
      isFullRange
    );
  }, [priceRangeValue, isFullRange]);

  if (token0PriceChart === null || token1PriceChart === null) {
    return <></>;
  }

  return (
    <Container>
      <Heading>
        {token1?.ticker} / {token0?.ticker} Correlation
      </Heading>
      <StatContainer>
        <StatItem>
          <StatTitle>MIN</StatTitle>
          <StatValue>{findMin(data.map((d) => d.y)).toFixed(4)}</StatValue>
        </StatItem>
        <StatItem>
          <StatTitle>MAX</StatTitle>
          <StatValue>{findMax(data.map((d) => d.y)).toFixed(4)}</StatValue>
        </StatItem>
        <StatItem>
          <StatTitle>AVG</StatTitle>
          <StatValue>{averageArray(data.map((d) => d.y)).toFixed(4)}</StatValue>
        </StatItem>
      </StatContainer>
      <div ref={refElement} />
    </Container>
  );
};

import { CSSProperties } from "react";
import { variant } from "styled-system";
import { Size, ThemedProps, sizes, variants } from "./types";

export const iconSizeVariants = () => {
  const iconSizeMap: Record<Size, CSSProperties["height"]> = {
    xl: "22px",
    lg: "20px",
    md: "20px",
    sm: "18px",
    xs: "16px",
  };
  const iconSizes = Object.entries(iconSizeMap).reduce(
    (acc, [size, height]) => {
      acc[size as Size] = {
        height,
        ">svg": {
          height: height,
          width: height,
        },
      };
      return acc;
    },
    {} as Record<Size, CSSProperties & { ">svg": CSSProperties }>
  );

  return variant({
    prop: "size",
    variants: iconSizes,
  });
};

export const buttonVariants = (props: ThemedProps) =>
  variant({
    prop: "variant",
    variants: {
      [variants.outlined]: {
        border: "1.5px solid",
        borderColor: props.theme.colors.global.primary,
        color: props.theme.colors.global.primary,
        background: props.theme.isDropdownOpen
          ? props.theme.colors.transparent.grey1
          : props.theme.colors.transparent.transparent,
        "&:hover": {
          borderColor: props.theme.colors.global.primary,
          color: props.theme.colors.global.textAlt.T1,
          background: props.theme.colors.global.primaryHover,
        },
        "&:active": {
          background: props.theme.colors.global.primaryHover,
          opacity: "0.7",
        },
      },
      [variants.text]: {
        textDecoration: "underline",
        boxShadow: "none !important",
        padding: "0 !important",
        minWidth: 0,
        background: props.theme.colors.transparent.transparent,
        color: props.theme.isDropdownOpen
          ? props.theme.colors.primary.darkPurple
          : props.theme.colors.global.primary,
        "&:hover": {
          color: props.theme.colors.global.primaryHover,
        },
        "&:active": {
          color: props.theme.colors.global.primaryHover,
        },
      },
      [variants.primary]: {
        color: props.theme.colors.global.textAlt.T1,
        background: props.theme.isDropdownOpen
          ? props.theme.colors.primary.darkPurple
          : props.theme.colors.global.primary,
        "&:hover": {
          background: props.theme.colors.global.primaryHover,
        },
        "&:active": {
          background: props.theme.colors.global.primaryHover,
        },
      },
      [variants.secondary]: {
        color: props.theme.colors.global.textAlt.T1,
        background: props.theme.colors.global.secondary,
        "&:hover": {
          background: props.theme.colors.global.secondaryHover,
        },
        "&:active": {
          background: props.theme.colors.global.secondaryHover,
        },
      },
      [variants.default]: {
        color: props.theme.colors.global.text.T1,
        background: props.theme.colors.global.background.BG3,
        "&:hover": {
          background: `${props.theme.colors.global.background.BG1}CC`,
        },
        "&:active": {
          background: `${props.theme.colors.global.background.BG3}CC`,
        },
      },
      [variants.error]: {
        color: props.theme.colors.global.error,
        background: props.theme.colors.global.background.BG3,
      },
    },
  });

export const buttonSizeVariants = () => {
  const common: CSSProperties = {
    fontSize: "16px",
    lineHeight: "20px",
    borderRadius: "100px",
  };

  return variant({
    prop: "size",
    variants: {
      [sizes.xl]: {
        ...common,
        padding: "14px",
      },
      [sizes.lg]: {
        ...common,
        padding: "10px",
      },
      [sizes.md]: {
        ...common,
        padding: "8px",
      },
      [sizes.sm]: {
        ...common,
        padding: "6px",
      },
      [sizes.xs]: {
        ...common,
        fontSize: "14px",
        lineHeight: "16px",
        padding: "4px",
      },
    },
  });
};

export const disabledButtonVariants = (props: ThemedProps) => {
  const common = {
    opacity: "1 !important",
    border: "none !important",
    // cursor: "no-drop",
  };
  return variant({
    prop: "variant",
    variants: {
      [variants.outlined]: {
        ...common,
        background: `${props.theme.colors.global.line.Line1} !important`,
        color: `${props.theme.colors.global.textAlt.T3} !important`,
      },
      [variants.text]: {
        ...common,
        background: `${props.theme.colors.transparent.transparent} !important`,
        color: `${props.theme.colors.grey.mediumGrey} !important`,
      },
      [variants.primary]: {
        ...common,
        background: `${props.theme.colors.global.primaryDisable} !important`,
        color: `${props.theme.colors.global.textAlt.T1} !important`,
      },
      [variants.secondary]: {
        ...common,
        background: `${props.theme.colors.global.secondaryDisable} !important`,
        color: `${props.theme.colors.global.textAlt.T1} !important`,
      },
      [variants.default]: {
        ...common,
        background: `${props.theme.colors.transparent.grey2} !important`,
        color: `${props.theme.colors.global.textAlt.T3} !important`,
      },
    },
  });
};

import { WarningIcon } from "@aperture/assetkit";
import {
  ActionTypeEnum,
  RecurringDualAction,
} from "@aperture_finance/uniswap-v3-automation-sdk";
import { Notification } from "@ui/components/Notification";
import { Subtitle2 } from "@ui/components/Typography";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { getOtherInfoListItems } from "./getOtherInfo";
import { getRebalanceInfoItems } from "./getRebalanceInfo";
import { getStrategyInfoListItems, parseDualAction } from "./getStrategyInfo";
import {
  ActionWrapper,
  Actions,
  Content,
  ExpiredTag,
  Info,
  Label,
  StyledDeleteIcon,
  StyledEditIcon,
  Value,
} from "./style";
import { ITriggerInfoCardProps } from "./types";
import {
  convertRelativePriceToHumanValue,
  getType,
  isStrategyTrigger,
} from "./utils";
export type InfoItem = {
  label: string;
  value: string | JSX.Element | JSX.Element[];
  showBubble?: boolean;
};

export const TriggerInfoCard: React.FC<ITriggerInfoCardProps> = ({
  trigger,
  tokens,
  chainId,
  tickRange,
  enableEdit,
  executedTimes,
  autoCompoundOn,
  getPrice,
  onDelete,
  getPercentage,
  renderNotification,
  projectLP,
  onEdit,
}) => {
  const isStrategy = isStrategyTrigger(trigger);

  const handleClickEditTrigger = useCallback(() => {
    const convertTrigger = _.cloneDeep(trigger);
    if (
      convertTrigger.condition.type === "Price" &&
      convertTrigger.condition.frontendType === "RELATIVE_PRICE"
    ) {
      const { lte, gte } = convertTrigger.condition;
      Object.assign(convertTrigger.condition, {
        ...(lte && { lte: convertRelativePriceToHumanValue(lte, tokens) }),
        ...(gte && { gte: convertRelativePriceToHumanValue(gte, tokens) }),
      });
    }
    onEdit(convertTrigger);
  }, [onEdit, tokens, trigger]);

  const handleDelete = useCallback(() => {
    onDelete(trigger.taskId);
  }, [onDelete, trigger.taskId]);

  const triggerType = getType(trigger);

  const tags: string[] = [];
  if (!isStrategy) {
    if (moment(trigger.expiration * 1000).diff(moment(), "days") < 0) {
      tags.push("Trigger Expired");
    }
    if (!enableEdit) {
      tags.push("Invalid Trigger");
    }
  }

  const [swap, setSwap] = useState(false);
  const listItems = useMemo(() => {
    if (isStrategy) {
      const isDualAction =
        trigger.action.type === ActionTypeEnum.Values.RecurringDualAction;

      let items: InfoItem[];

      if (isDualAction) {
        const parsedActions = parseDualAction(
          trigger.action as RecurringDualAction
        );
        const formattedItems = parsedActions.map((action, idx) =>
          getStrategyInfoListItems(
            { ...trigger, action },
            tokens,
            executedTimes,
            autoCompoundOn,
            setSwap
          ).map(({ label, value, showBubble }) => ({
            label: label === "Trigger" ? `Trigger ${idx + 1}` : label,
            value: label === "Trigger" ? (value as JSX.Element[])[idx] : value,
            showBubble,
          }))
        );
        items = [
          formattedItems[1][swap ? 1 : 0], // Condition Spot Price
          ...formattedItems[0].slice(2, -2), // Trigger 1 action
          ...formattedItems[1].slice(2), // Trigger 2 action, Auto compound status, and number of execution
        ];
      } else {
        const formattedItems = getStrategyInfoListItems(
          trigger,
          tokens,
          executedTimes,
          autoCompoundOn,
          setSwap
        );
        items = [formattedItems[swap ? 1 : 0], ...formattedItems.slice(2)];
      }

      return items;
    }
    const list = getOtherInfoListItems(trigger, tokens, tickRange, chainId);
    if (trigger.action.type === "Rebalance") {
      // insert rebalance info items after the first item(Trigger)
      list.splice(
        1,
        0,
        ...getRebalanceInfoItems(
          trigger,
          tokens,
          getPrice,
          getPercentage,
          projectLP
        )
      );
    }

    return list;
  }, [
    autoCompoundOn,
    executedTimes,
    getPercentage,
    getPrice,
    isStrategy,
    projectLP,
    tickRange,
    tokens,
    trigger,
    chainId,
    swap,
  ]);

  const props: IPureTriggerInfoCardProps = {
    title: `Trigger By ${triggerType}`,
    tags,
    labelKey: isStrategy ? "Strategy" : "Trigger",
    enableEdit: isStrategy ? true : enableEdit,
    onClickEdit: handleClickEditTrigger,
    onClickDelete: handleDelete,
    infoItems: listItems,
  };

  if (isStrategy) {
    props.renderNotification = renderNotification;
  } else {
    props.notifyText = trigger.lastFailedMessage;
  }

  return <PureTriggerInfoCard {...props} />;
};

interface IPureTriggerInfoCardProps {
  title: string;
  tags: string[];
  labelKey: string;
  enableEdit: boolean;
  notifyText?: string;
  renderNotification?: () => React.ReactNode;

  onClickEdit: () => void;
  onClickDelete: () => void;
  infoItems: InfoItem[];
}

const PureTriggerInfoCard: React.FC<IPureTriggerInfoCardProps> = ({
  title,
  tags,
  labelKey,
  enableEdit,
  notifyText,
  renderNotification,
  onClickEdit,
  onClickDelete,
  infoItems,
}) => {
  return (
    <Content>
      <Actions>
        <Subtitle2 lineHeight={"16px"} marginRight={"auto"}>
          {title}
        </Subtitle2>
        <ActionWrapper>
          {tags.map((tag, index) => (
            <ExpiredTag key={`${index}-${tag}`}>
              <WarningIcon />
              <span>{tag}</span>
            </ExpiredTag>
          ))}
          {enableEdit && <StyledEditIcon onClick={onClickEdit} />}
          <StyledDeleteIcon onClick={onClickDelete} />
        </ActionWrapper>
      </Actions>
      {infoItems.map(({ label, value, showBubble }, idx) => (
        <Info key={`${label}-${idx}`}>
          <Label>{label}</Label>
          <Value showBubble={showBubble}>
            {Array.isArray(value) ? <div>{value}</div> : value}
          </Value>
        </Info>
      ))}
      {!!renderNotification && renderNotification()}
      {notifyText && (
        <Info>
          <Notification variant="warning" text={notifyText} />
        </Info>
      )}
    </Content>
  );
};

import { Flex, FlexColumn } from "@uiv2/common";
import { Button } from "@uiv2/components/Button";
import { Input } from "@uiv2/components/Input";
import { H1, H2, T3Regular } from "@uiv2/components/Typography";
import styled from "styled-components";
import { IValuePecentProps, ValuePercentBox } from "../ValuePercentBox";

const Container = styled(FlexColumn)`
  width: 400px;
  box-sizing: border-box;
  border-radius: 15px;
  padding: ${({ theme }) => theme.spacing.xl};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
`;
const StyledH2 = styled(H2)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const StyledT3 = styled(T3Regular)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const InputText = styled(H1)`
  color: ${({ theme }) => theme.colors.global.primary};
`;
const StyledInput = styled(Input)`
  width: 154px;
  font-family: Dunbar Tall;
  font-size: 32px;
  line-height: 32px;
  font-weight: 500;
  letter-spacing: -0.04em;
  color: ${({ theme }) => theme.colors.global.primary};
  text-overflow: ellipsis;
`;
const IconWrapper = styled.div<{ size?: number }>`
  height: ${({ size }) => `${size ?? 20}px`};
  width: ${({ size }) => `${size ?? 20}px`};
  svg {
    height: ${({ size }) => `${size ?? 20}px`};
    width: ${({ size }) => `${size ?? 20}px`};
  }
`;

type IDepositAmountProps = {
  defaultAmount: string;
  tokens: ({ ticker: string; Icon: React.ReactNode } & IValuePecentProps)[];
  onInputChange: (value: string) => void;
  onInputBlur?: (value: string) => void;
  onClick: () => void;
};

export const PureDepositAmount: React.FC<IDepositAmountProps> = ({
  defaultAmount,
  tokens,
  onInputChange,
  onInputBlur,
  onClick,
}) => {
  return (
    <Container gap="3xl">
      <StyledH2>Deposit Amount</StyledH2>
      <Flex alignItems="center">
        <InputText>$</InputText>
        <StyledInput
          align="left"
          type="number"
          placeholder="0.00"
          defaultValue={defaultAmount ?? "0"}
          onChange={(value) => onInputChange(value)}
          onBlur={(value) => onInputBlur?.(value)}
        />
      </Flex>
      <FlexColumn gap="lg">
        {tokens.map(({ ticker, Icon, value, percentage }, idx) => (
          <Flex
            key={`${idx}-${ticker}-${value}`}
            alignItems="center"
            justifyContent="space-between"
          >
            <Flex gap={6} alignItems="center">
              <IconWrapper>{Icon}</IconWrapper>
              <StyledT3>{ticker}</StyledT3>
            </Flex>
            <ValuePercentBox {...{ value, percentage }} />
          </Flex>
        ))}
      </FlexColumn>
      <Button size="xl" variant="primary" width="100%" onClick={onClick}>
        Create Position
      </Button>
    </Container>
  );
};

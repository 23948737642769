import { WarningIcon } from "@aperture/assetkit";
import { QuestionPopover } from "@aperture/uikit";
import { Flex, FlexColumn } from "@ui/common";
import bigDecimal from "js-big-decimal";
import React, { useRef } from "react";
import { trimNumber } from "../../utils/numberFormat";
import { IInputRef } from "../Input";
import { Body1, Subtitle0 } from "../Typography";
import {
  Checkbox,
  IconWrapper,
  RightButton,
  Row,
  SlippageContainer,
  StyledInput,
  Text,
  WarningText,
  WarningWrapper,
} from "./style";
import { ISlippageProps } from "./types";

export const Slippage: React.FC<ISlippageProps> = ({
  defaultValue = 0.005,
  globalValue,
  warningValue = 0.0005,
  value,
  disabled,
  onSlippageChange,
  maxWidth,
  hideAuto = false,
  isSm = false,
  showWarning = true,
  applyGlobally = false,
  onApplyGlobally = undefined,
}) => {
  const showGlobal = onApplyGlobally !== undefined;
  const inputRef = useRef<IInputRef>(null);
  const handleChange = (newValue: string) => {
    if (!newValue) return;
    onSlippageChange(
      parseFloat(trimNumber(bigDecimal.divide(newValue, 100, 8), 4))
    );
    onApplyGlobally?.(false);
  };
  const handleClickAuto = () => {
    if (disabled) return;
    inputRef?.current?.setValue(bigDecimal.multiply(defaultValue, 100));
  };
  return (
    <SlippageContainer>
      <Flex alignItems={"center"} gap={"sm"}>
        {isSm ? (
          <Body1>Slippage Setting</Body1>
        ) : (
          <Subtitle0>Slippage Setting</Subtitle0>
        )}
        <QuestionPopover
          content="This setting defines the max pool price difference allowed between the time the transaction is submitted and when its execution starts. Transactions will be reverted if the output token amounts do not meet the minimum thresholds calculated from this slippage setting."
          defaultPosition={{ bottom: 18, left: -100 }}
        />
      </Flex>
      <FlexColumn>
        <Flex
          alignItems={"center"}
          gap="md"
          style={{ position: "relative", width: isSm ? "280px" : "100%" }}
        >
          <StyledInput
            maxWidth={maxWidth}
            ref={inputRef}
            align="left"
            defaultValue={bigDecimal.multiply(value, 100)}
            disabled={disabled}
            warning={
              showWarning && bigDecimal.compareTo(value, warningValue) < 0
            }
            type="number"
            format={{
              suffix: "%",
            }}
            onChange={handleChange}
          />
          {!hideAuto && (
            <RightButton
              isPrimary={false}
              onClick={handleClickAuto}
              disabled={disabled}
            >
              Auto
            </RightButton>
          )}
        </Flex>
        {showWarning && bigDecimal.compareTo(value, warningValue) < 0 && (
          <WarningWrapper>
            <IconWrapper>
              <WarningIcon height={15} width={15} />
            </IconWrapper>
            <WarningText>
              {`Slippage below ${
                warningValue * 100
              }% may result in a failed transaction`}
            </WarningText>
          </WarningWrapper>
        )}
        {showGlobal && (
          <Row gap="md" alignItems={"center"}>
            <Checkbox
              disabled={disabled}
              checked={applyGlobally}
              onChange={(e) =>
                onApplyGlobally?.((e.target as HTMLInputElement).checked)
              }
            />
            <Text fontWeight={400} fontSize={14}>
              Apply this slippage setting to all transactions.
            </Text>
          </Row>
        )}
      </FlexColumn>
    </SlippageContainer>
  );
};

import { ArrowRightIcon } from "@aperture/assetkit";
import { Flex } from "@ui/common";
import { CSSProperties } from "react";
import styled from "styled-components";
import { Body2, Headline5, Subtitle2, Subtitle3 } from "../Typography";

export const Container = styled.div<{
  dark: boolean;
  wBorder: boolean;
  w: number;
  maxW: number;
  isText?: boolean;
}>`
  ${({ w, maxW }) => {
    if (maxW >= 999) {
      return "max-width: 100%";
    }
    return `max-width: ${maxW}px;width: ${w}px;`;
  }};
  overflow: auto;
  padding: ${({ theme, isText, wBorder }) =>
    isText ? theme.spacing.md : wBorder ? theme.spacing.xl : 0};
  border-radius: ${({ theme }) => theme.radii.md};
  ::-webkit-scrollbar {
    width: 10px;
    border-radius: ${({ theme }) => theme.radii.md};
    background-color: ${({ theme }) => theme.colors.global.background.BG3};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.global.line.Line2};
    border-radius: ${({ theme }) => theme.radii.md};
  }
  border: ${({ theme, wBorder }) =>
    !wBorder ? "none" : `1px solid ${theme.colors.global.line.Line2}`};
`;
export const TitleRow = styled(Flex)<{ isLoading: boolean; minW: number }>`
  min-width: ${({ minW }) => `${minW}px`};
  justify-content: space-between;
  padding-bottom: ${({ theme, isLoading }) =>
    !isLoading ? theme.spacing.lg : 0};
  border-bottom: ${({ theme, isLoading }) =>
    isLoading ? "none" : `1px solid ${theme.colors.global.line.Line2}`};
`;
export const Text = styled(Subtitle2)<{ dark: boolean }>`
  line-height: 20px;
  color: ${({ theme, dark }) => theme.colors.global.text.T2};
`;
export const H5 = styled(Headline5)<{ dark: boolean; priceImpact: number }>`
  line-height: 20px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const Tag = styled(Flex)<{ dark: boolean }>`
  align-items: center;
  border-radius: ${({ theme }) => theme.radii.round};
  padding: 6px 0px;
  background-color: ${({ theme }) => theme.colors.global.background.BG3};
`;
export const TokenTag = styled(Tag)<{
  dark: boolean;
  tagWithoutDetails?: boolean;
}>`
  padding-block: 6px;
  margin-top: ${({ tagWithoutDetails }) =>
    tagWithoutDetails ? "8px" : undefined};
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  width: ${({ tagWithoutDetails }) =>
    tagWithoutDetails ? "fit-content" : undefined};
  border-radius: ${({ theme }) => theme.radii.md};
  background-color: ${({ theme }) => theme.colors.global.background.BG3};
`;
export const TagWrapper = styled.div<{ minW?: string }>`
  min-width: ${({ minW }) => minW ?? undefined};
`;
export const PercentageTagWrapper = styled.div<{
  dark: boolean;
  isAligned?: boolean;
}>`
  position: absolute;
  top: 3px;
  width: 36px;
  border-radius: ${({ theme }) => theme.radii.md};
  left: ${({ isAligned }) => (isAligned ? "4%" : "10%")};
  background-color: ${({ theme, dark }) =>
    dark ? theme.colors.grey.darkGrey1 : undefined};
`;

export const PercentageTag = styled(TokenTag)<{
  dark: boolean;
}>`
  padding-block: 4px;
  justify-content: center;
  background-color: ${({ theme, dark }) =>
    dark ? theme.colors.transparent.grey1 : theme.colors.grey.lightGrey2};
`;
export const S2 = styled(Subtitle2)<{ dark: boolean }>`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const IconWrapper = styled.div`
  padding-left: 8px;
  width: 16px;
  height: 16px;
  > svg {
    width: 16px;
    height: 16px;
  }
`;
export const PureIconWrapper = styled.div`
  width: 20px;
  height: 20px;
`;

export const Content = styled(Flex)`
  padding-top: ${({ theme }) => theme.spacing.lg};
`;
export const Line = styled.div`
  position: absolute;
  width: 100%;
  top: 12px;
  height: 0px;
  border-block: 1px dashed ${({ theme }) => theme.colors.grey.grey};
`;
export const StyledArrowIcon = styled(ArrowRightIcon)`
  width: 8px;
  height: 7.5px;
  path {
    fill: ${({ theme }) => theme.colors.grey.grey};
  }
`;
export const RightArrow = styled(Flex)<{ dark: boolean }>`
  position: absolute;
  left: 50%;
  top: 6px;
  width: 14px;
  height: 14px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.radii.circle};
  border: 1.5px solid ${({ theme }) => theme.colors.grey.grey};
  background-color: ${({ theme, dark }) =>
    dark ? theme.colors.grey.darkGrey1 : theme.colors.grey.white};
`;
export const Connect = styled.div<{ marginTop?: number }>`
  position: relative;
  width: 100%;
  height: 28px;
  min-width: 68px;
  align-self: ${({ marginTop }) => (marginTop ? "flex-start" : undefined)};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : undefined)};
`;
export const IconConnect = styled.div<{ marginTop?: number }>`
  position: relative;
  margin: auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 68px;
`;

export const VerticalLine = styled.div<{ h: number; hasDetails: boolean }>`
  width: 0px;
  align-self: baseline;
  height: ${({ h }) => `${h}px`};
  margin-top: ${({ hasDetails }) => (hasDetails ? `${13 + 8}px` : "13px")};
  border-inline: 1px dashed ${({ theme }) => theme.colors.grey.grey};
`;
export const Wrapper = styled(Flex)<{
  flex?: CSSProperties["flex"];
  width?: CSSProperties["width"];
  isAligned?: boolean;
  marginTop?: number;
}>`
  flex: ${({ flex }) => flex ?? undefined};
  width: ${({ width }) => width ?? undefined};
  align-self: ${({ marginTop }) => (marginTop ? "flex-start" : "center")};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : undefined)};
`;
export const B2 = styled(Body2)`
  line-height: 18px;
  color: ${({ theme }) => theme.colors.global.text.T1};
  word-break: break-all;
`;
export const S3 = styled(Subtitle3)<{ dark: boolean }>`
  padding-right: 8px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const DetailsRow = styled(Flex)`
  max-width: 132px;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
`;
export const Text2 = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-family: "Roboto Flex";
  font-weight: 400;
  color: ${({ theme }) => theme.colors.global.text.T1};
  text-align: left;
`;
export const TextIconWrapper = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 4px;
  margin-bottom: -2px;
  svg {
    width: 16px;
    height: 16px;
    margin-bottom: -4px;
  }
`;

import { RebalanceActionTab } from "@ui/common";
import { format } from "d3";
import bigDecimal from "js-big-decimal";
import { saturate } from "polished";
import { useCallback, useMemo } from "react";
import styled, { useTheme } from "styled-components";
import { Headline5 } from "../Typography";
import { Chart } from "./Chart";
import { Bound, FutureSpotChartRangeInputProps, ZoomLevels } from "./types";

const ZOOM_LEVELS: Record<
  RebalanceActionTab.TOKEN_TERMS | RebalanceActionTab.PERCENTAGES,
  ZoomLevels
> = {
  [RebalanceActionTab.TOKEN_TERMS]: {
    initialMin: -120,
    initialMax: 120,
    min: -10000,
    max: 10000,
  },
  [RebalanceActionTab.PERCENTAGES]: {
    initialMin: -120,
    initialMax: 120,
    min: -120,
    max: 10000,
  },
};

const InfoWrapper = styled(Headline5)<{ height: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => `${height}px`};
  color: ${({ theme }) => theme.colors.grey.mediumGrey};
`;

function InfoBox({ message, height }: { message?: string; height: number }) {
  return <InfoWrapper height={height}>{message}</InfoWrapper>;
}

export function FutureSpotChartRangeInput({
  currencyA,
  currencyB,
  ticksAtLimit,
  price,
  priceLower,
  priceUpper,
  onLeftRangeInput,
  onRightRangeInput,
  interactive,
  chartHeight,
  futureSpotTab,
}: FutureSpotChartRangeInputProps) {
  const theme = useTheme();
  const brushMinValue = useMemo(
    () => (futureSpotTab === RebalanceActionTab.PERCENTAGES ? -100 : undefined),
    [futureSpotTab]
  );

  const onBrushDomainChangeEnded = useCallback(
    (domain: [number, number], mode: string | undefined) => {
      let leftRangeValue = domain[0];
      const rightRangeValue = domain[1];
      if (
        brushMinValue !== undefined &&
        bigDecimal.compareTo(leftRangeValue, brushMinValue) <= 0
      ) {
        leftRangeValue = brushMinValue + 1 / 1e6;
      }

      // simulate user input for auto-formatting and other validations
      if (
        (!ticksAtLimit[Bound.LOWER] || mode) &&
        (brushMinValue === undefined ||
          bigDecimal.compareTo(leftRangeValue, brushMinValue) > 0)
      ) {
        onLeftRangeInput(bigDecimal.round(leftRangeValue, 18));
      }

      if (
        (!ticksAtLimit[Bound.UPPER] || mode) &&
        (brushMinValue === undefined ||
          bigDecimal.compareTo(rightRangeValue, brushMinValue) > 0)
      ) {
        if (bigDecimal.compareTo(rightRangeValue, 1e35) < 0) {
          onRightRangeInput(bigDecimal.round(rightRangeValue, 18));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ticksAtLimit, brushMinValue]
  );

  const brushDomain: [number, number] | undefined = useMemo(() => {
    const leftPrice = priceLower;
    const rightPrice = priceUpper;
    return leftPrice && rightPrice
      ? [parseFloat(leftPrice), parseFloat(rightPrice)]
      : undefined;
  }, [priceLower, priceUpper]);

  const brushLabelValue = (d: "w" | "e", x: number) => {
    return `${format(Math.abs(x) > 1000 ? ".2~s" : ".2~f")(x)}${
      futureSpotTab === RebalanceActionTab.PERCENTAGES ? "%" : ""
    }`;
  };

  return (
    <>
      {price === undefined ? (
        <InfoBox message={"There is no liquidity data."} height={chartHeight} />
      ) : (
        <Chart
          id="futureSpotChartRangeInput"
          data={{ series: [], current: 0 }}
          dimensions={{ width: 400, height: chartHeight }}
          margins={{ top: 0, right: 0, bottom: 20, left: 0 }}
          styles={{
            area: {
              selection: theme.colors.global.text.T1,
            },
            brush: {
              handle: {
                west: saturate(0.1, theme.colors.global.primary),
                east: saturate(0.1, theme.colors.global.primary),
              },
            },
            currentBrush: {
              handle: {
                west: saturate(0.1, theme.colors.global.primary),
                east: saturate(0.1, theme.colors.global.primary),
              },
            },
          }}
          interactive={interactive}
          brushLabels={brushLabelValue}
          brushDomain={brushDomain}
          onBrushDomainChange={onBrushDomainChangeEnded}
          zoomLevels={
            ZOOM_LEVELS[futureSpotTab ?? RebalanceActionTab.PERCENTAGES]
          }
          ticksAtLimit={ticksAtLimit}
          brushMinValue={brushMinValue}
          brushBoundary={0}
          axisSuffix={
            futureSpotTab === RebalanceActionTab.PERCENTAGES ? "%" : ""
          }
        />
      )}
    </>
  );
}

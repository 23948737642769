import { APTR_TOKEN_DECIMALS, TokenInfo } from "@/config/token/tokenConfig";
import { ITokenPercentages } from "@aperture/types";
import {
  ApertureSupportedChainId,
  viem,
} from "@aperture_finance/uniswap-v3-automation-sdk";
import { Token } from "@uniswap/sdk-core";
import bigDecimal from "js-big-decimal";
import { formatUnits } from "viem";

export function tokenToTokenInfo(token: Token): TokenInfo {
  return {
    name: token.name!,
    ticker: token.symbol!,
    decimals: token.decimals,
    address: token.address,
    native: token.isNative,
  };
}

export function getTokensFromInfo(
  tokenA: { address: string; decimals: number },
  tokenB: { address: string; decimals: number },
  chainId: number
): { token0: Token; token1: Token } {
  return tokenA.address.toLowerCase() <= tokenB.address.toLowerCase() // sortsbefore caused error here
    ? {
        token0: new Token(chainId, tokenA.address, tokenA.decimals),
        token1: new Token(chainId, tokenB.address, tokenB.decimals),
      }
    : {
        token0: new Token(chainId, tokenB.address, tokenB.decimals),
        token1: new Token(chainId, tokenA.address, tokenA.decimals),
      };
}

export function tokenPairMatchToPool(
  tokenAAddress: string,
  tokenBAddress: string,
  poolToken0Address: string,
  poolToken1Address: string
) {
  return (
    (tokenAAddress === poolToken0Address &&
      tokenBAddress === poolToken1Address) ||
    (tokenAAddress === poolToken1Address && tokenBAddress === poolToken0Address)
  );
}

export function getTicker(
  ticker: string,
  networkId: ApertureSupportedChainId,
  useNative?: boolean
) {
  const nativeCurrency = viem.getNativeCurrency(networkId);
  if (useNative && ticker === nativeCurrency.wrapped.symbol)
    return nativeCurrency.symbol;
  return ticker;
}

export function isWrappedNative(
  wrappedTokenAddress: string,
  networkId: ApertureSupportedChainId
) {
  const nativeCurrency = viem.getNativeCurrency(networkId);
  return wrappedTokenAddress === nativeCurrency.wrapped.address;
}

/*
 * Returns the percentage of the total liquidity pool that each token represents
 * @param token0Amount the amount of token0 in the pool
 * @param token1Amount the amount of token1 in the pool
 * @param token0Price the price of token0 in USD
 * @param token1Price the price of token1 in USD
 * @returns an object with the percentage of the pool that each token represents, 0-100
 */
export function getTokenPercentages(
  token0Amount: string,
  token1Amount: string,
  token0Price: string,
  token1Price: string
): ITokenPercentages {
  const lpInUs = bigDecimal.add(
    bigDecimal.multiply(token0Amount, token0Price),
    bigDecimal.multiply(token1Amount, token1Price)
  );
  if (bigDecimal.compareTo(lpInUs, 0) === 0) {
    return { token0Percent: "-", token1Percent: "-" };
  } else {
    const token0Value = bigDecimal.multiply(token0Amount, token0Price);
    const token0Percent =
      bigDecimal.compareTo(token0Value, 0) === 0
        ? "0"
        : bigDecimal.multiply(bigDecimal.divide(token0Value, lpInUs, 2), 100);
    const token1Value = bigDecimal.multiply(token1Amount, token1Price);
    const token1Percent =
      bigDecimal.compareTo(token1Value, 0) === 0
        ? "0"
        : bigDecimal.multiply(bigDecimal.divide(token1Value, lpInUs, 2), 100);
    return { token0Percent, token1Percent };
  }
}

export const formatAptrAmount = (aptrAmount?: bigint) => {
  return parseFloat(formatUnits(aptrAmount ?? 0n, APTR_TOKEN_DECIMALS));
};

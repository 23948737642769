// @ts-nocheck
import { formatPrice } from "@uiv2/utils/numberFormat";
import * as d3 from "d3";
import { ChartColor } from "../types";
import { findMax, findMin, getPriceFromTick } from "../utils";
import { D3LiquidityHistogramProps } from "./types";

class D3LiquidityHistogram {
  containerEl;
  props;
  svg;
  x;
  y;
  xAxis;
  xAxisHeight;
  isPairToggled;
  positions;
  currentTick;
  minTick;
  maxTick;
  token0Symbol;
  token1Symbol;
  colors;

  constructor(containerEl: any, props: D3LiquidityHistogramProps) {
    this.containerEl = containerEl;
    this.xAxisHeight = 20;
    this.props = props;
    this.isPairToggled = props.isPairToggled;
    this.token0Symbol = props.token0Symbol;
    this.token1Symbol = props.token1Symbol;
    this.colors = props.colors;

    this.svg = d3
      .select(containerEl)
      .append("svg")
      .attr("viewBox", `0 0 ${props.width} ${props.height}`);

    // add x axis
    const x = d3
      .scaleLinear()
      .domain([this.props.minTick, this.props.maxTick])
      .range(this.isPairToggled ? [props.width, 0] : [0, props.width]);
    this.xAxis = this.svg
      .append("g")
      .attr(
        "transform",
        "translate(0," + (props.height - this.xAxisHeight) + ")"
      )
      .attr("color", this.colors.axis)
      .call(d3.axisBottom(x));
    this.x = x;

    // add y axis
    const y = d3
      .scaleLinear()
      .domain([
        findMin(this.props.data.map((d) => d.y)) * 0.1,
        findMax(this.props.data.map((d) => d.y)) * 1.25,
      ])
      .range([props.height, 0]);
    this.y = y;

    this.positions = this.svg
      .selectAll("rect")
      .data(this.props.data)
      .enter()
      .append("rect")
      .attr("x", 1)
      .attr("transform", function (d) {
        return "translate(" + x(d.x0) + "," + (y(d.y) - 20) + ")";
      })
      .attr("width", function (d) {
        return (props.isPairToggled ? -1 : 1) * (x(d.x1) - x(d.x0));
      })
      .attr("height", function (d) {
        const height = props.height - y(d.y);
        return height < 0 ? 0 : height;
      })
      .style("fill", this.colors.graph);

    this.currentTick = this.renderCurrentTick(this.props.currentTick);
    const { minTickSVG, maxTickSVG } = this.renderMinMaxTickRange(
      props.minTick,
      props.maxTick
    );
    this.minTick = minTickSVG;
    this.maxTick = maxTickSVG;
    this.handleMouseMove();
  }

  destroy() {
    this.svg.remove();
  }

  renderMinMaxTickRange(minTick: number, maxTick: number) {
    const minTickSVG = this.svg
      .append("g")
      .append("line")
      .style("stroke-width", 1.25)
      .style("stroke-dasharray", "10, 3")
      .style("stroke", this.colors.minMax)
      .attr("y1", 0)
      .attr("x1", this.x(minTick))
      .attr("y2", this.props.height - this.xAxisHeight)
      .attr("x2", this.x(minTick));

    const maxTickSVG = this.svg
      .append("g")
      .append("line")
      .style("stroke-width", 1.25)
      .style("stroke-dasharray", "10, 3")
      .style("stroke", this.colors.minMax)
      .attr("y1", 0)
      .attr("x1", this.x(maxTick))
      .attr("y2", this.props.height - this.xAxisHeight)
      .attr("x2", this.x(maxTick));

    return { minTickSVG, maxTickSVG };
  }
  updateMinMaxTickRange(
    minTick: number,
    maxTick: number,
    isFullRange: boolean
  ) {
    this.minTick.attr("opacity", !isFullRange ? 1 : 0.4);
    this.maxTick.attr("opacity", !isFullRange ? 1 : 0.4);

    this.minTick
      .attr("y1", 0)
      .attr("x1", this.x(minTick))
      .attr("y2", this.props.height - this.xAxisHeight)
      .attr("x2", this.x(minTick));

    this.maxTick
      .attr("y1", 0)
      .attr("x1", this.x(maxTick))
      .attr("y2", this.props.height - this.xAxisHeight)
      .attr("x2", this.x(maxTick));
  }

  renderCurrentTick(currentTick: number) {
    return this.svg
      .append("g")
      .append("line")
      .style("stroke-width", 1.25)
      .style("stroke-dasharray", "10, 3")
      .style("stroke", this.colors.current)
      .attr("x1", this.x(currentTick))
      .attr("y1", 0)
      .attr("x2", this.x(currentTick))
      .attr("y2", this.props.height - this.xAxisHeight);
  }
  updateCurrentTick(currentTick: number) {
    this.currentTick
      .attr("x1", this.x(currentTick))
      .attr("y1", 0)
      .attr("x2", this.x(currentTick))
      .attr("y2", this.props.height - this.xAxisHeight);
  }
  updateColors(colors: ChartColor) {
    this.colors = colors;
    this.xAxis.attr("color", this.colors.axis);
    this.positions.style("fill", this.colors.graph);
    this.currentTick.style("stroke", this.colors.current);
    this.minTick.style("stroke", this.colors.minMax);
    this.maxTick.style("stroke", this.colors.minMax);
  }

  handleMouseMove() {
    const focusTextToken0 = this.svg
      .append("g")
      .append("text")
      .style("opacity", 0)
      .attr("fill", this.colors.axis)
      .attr("font-size", "0.6rem")
      .attr("alignment-baseline", "middle");
    const focusTextToken1 = this.svg
      .append("g")
      .append("text")
      .style("opacity", 0)
      .attr("fill", this.colors.axis)
      .attr("font-size", "0.6rem")
      .attr("alignment-baseline", "middle");
    const verticalLine = this.svg
      .append("g")
      .append("line")
      .style("stroke-width", 0.5)
      .style("stroke", this.colors.axis);

    const onMouseMove = (e: any) => {
      let coords = d3.pointer(e);
      const x0 = this.x.invert(coords[0]);
      verticalLine
        .attr("x1", this.x(x0))
        .attr("y1", 0)
        .attr("x2", this.x(x0))
        .attr("y2", this.props.height - this.xAxisHeight);

      const self = this;
      if (this.x(x0) > this.props.width * 0.5) {
        focusTextToken0
          .html(
            `${this.token0Symbol}: ${formatPrice(
              1 /
                getPriceFromTick(
                  x0,
                  this.props.token0Decimal,
                  this.props.token1Decimal
                )
            )} ${this.token1Symbol}`
          )
          .attr("x", function (d: any) {
            return self.x(x0) - (this.getComputedTextLength() + 5);
          })
          .attr("text-anchor", "right")
          .attr("y", 5);
        focusTextToken1
          .html(
            `${this.token1Symbol}: ${formatPrice(
              getPriceFromTick(
                x0,
                this.props.token0Decimal,
                this.props.token1Decimal
              )
            )} ${this.token0Symbol}`
          )
          .attr("x", function (d: any) {
            return self.x(x0) - (this.getComputedTextLength() + 5);
          })
          .attr("text-anchor", "right")
          .attr("y", 15);
      } else {
        focusTextToken0
          .html(
            `${this.token0Symbol}: ${formatPrice(
              1 /
                getPriceFromTick(
                  x0,
                  this.props.token0Decimal,
                  this.props.token1Decimal
                )
            )} ${this.token1Symbol}`
          )
          .attr("x", this.x(x0) + 5)
          .attr("text-anchor", "left")
          .attr("y", 5);
        focusTextToken1
          .html(
            `${this.token1Symbol}: ${formatPrice(
              getPriceFromTick(
                x0,
                this.props.token0Decimal,
                this.props.token1Decimal
              )
            )} ${this.token0Symbol}`
          )
          .attr("x", this.x(x0) + 5)
          .attr("text-anchor", "left")
          .attr("y", 15);
      }
    };

    this.svg
      .append("rect")
      .style("fill", "none")
      .style("pointer-events", "all")
      .attr("width", this.props.width)
      .attr("height", this.props.height)
      .on("mouseover", () => {
        focusTextToken0.style("opacity", 1);
        focusTextToken1.style("opacity", 1);
        verticalLine.style("opacity", 1);
        focusTextToken0.attr("fill", this.colors.axis);
        focusTextToken1.attr("fill", this.colors.axis);
        verticalLine.style("stroke", this.colors.axis);
      })
      .on("mouseout", () => {
        focusTextToken0.style("opacity", 0);
        focusTextToken1.style("opacity", 0);
        verticalLine.style("opacity", 0);
      })
      .on("mousemove", onMouseMove);
  }
}

export default D3LiquidityHistogram;

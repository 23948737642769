import styled from "styled-components";
import { ButtonText1 } from "../../Typography";
import { StyledBar, StyledItemNew, StyledSliderNew } from "../SharedTab";
import { StatusTabProps } from "./types";

const TabBar = styled(StyledBar)<{
  isOn: boolean;
  marginLeft: number;
  radius?: string;
  noText: boolean;
  statusStyle: string;
}>`
  position: relative;
  background: ${({ theme, isOn, statusStyle }) => {
    if (statusStyle === "success") {
      return isOn
        ? theme.colors.global.success
        : theme.colors.global.background.BG4;
    }
    return isOn
      ? theme.colors.global.success
      : theme.colors.global.background.BG3;
  }};
  border-radius: ${({ theme, radius }) => {
    if (radius === "sm") {
      return theme.radii.sm;
    }
    if (radius === "md") {
      return theme.radii.md;
    }
    if (radius === "round") {
      return theme.radii.round;
    }
    return theme.radii.lg;
  }};
  height: ${({ noText, height }) => (noText ? "20px" : `${height}px`)};
  width: ${({ noText, width }) => (noText ? "38px" : `${width}px`)};
  margin-left: ${({ marginLeft }) => `${marginLeft}px`};

  :hover,
  :focus {
    .baseSlider {
      background: ${({ theme }) => theme.colors.grey.offWhite};
      box-shadow: none;
    }
  }
`;
const TabItems = styled(StyledItemNew)<{
  isOn: boolean;
  noText: boolean;
  height?: number;
}>`
  height: ${({ noText, height }) => (noText ? "12px" : `${height}px`)};
  padding: ${({ noText }) => (noText ? "0px" : "4px 6px")};
  color: ${({ theme, isOn }) =>
    isOn ? theme.colors.grey.black : theme.colors.global.text.T1};
`;

const TabSlider = styled(StyledSliderNew)<{
  isOn: boolean;
  noText: boolean;
  sliderWidth: number;
  sliderHeight: number;
  sliderPadding: number;
  statusStyle: string;
  initTop?: number;
  initLeft?: number;
}>`
  background: ${({ isOn, theme, statusStyle }) => {
    if (statusStyle === "default") {
      return isOn
        ? theme.colors.global.background.BG1
        : theme.colors.global.text.T1;
    }
    if (statusStyle === "success") {
      return isOn
        ? theme.colors.global.background.BG1
        : theme.colors.global.text.T1;
    }
  }};
  border-radius: ${({ theme }) => theme.radii.circle};
  width: ${({ noText, sliderWidth }) => (noText ? "16px" : `${sliderWidth}px`)};
  height: ${({ noText, sliderHeight }) =>
    noText ? "16px" : `${sliderHeight}px`};
  z-index: ${({ theme }) => theme.zIndices.tab};
`;

const StyledText = styled(ButtonText1)`
  font-size: 16px;
  line-height: 20px;
`;

export const StatusTab: React.FC<StatusTabProps> = ({
  disabled,
  onStatus,
  onChange,
  radius = "lg",
  initTop = 2,
  initLeft = 2,
  width = 38,
  height = 20,
  sliderWidth = 16,
  sliderHeight = 16,
  sliderPadding = 2,
  marginLeft = 0,
  noText = false,
  statusStyle = "default",
  ...props
}) => {
  return (
    <TabBar
      marginLeft={marginLeft}
      {...props}
      isOn={onStatus}
      statusStyle={statusStyle}
      radius={radius}
      width={width}
      height={height}
      noText={noText}
      onClick={() => {
        if (disabled) return;
        onChange(!onStatus);
      }}
    >
      <TabSlider
        selectedIndex={onStatus ? 1 : 0}
        isOn={onStatus}
        length={2}
        sliderPadding={sliderPadding}
        sliderWidth={sliderWidth}
        sliderHeight={sliderHeight}
        noText={noText}
        initTop={initTop}
        initLeft={initLeft}
        statusStyle={statusStyle}
      />
      {noText ? null : (
        <TabItems isOn={onStatus} noText={noText}>
          {!noText && <StyledText>{onStatus ? "ON" : "OFF"}</StyledText>}
        </TabItems>
      )}
    </TabBar>
  );
};

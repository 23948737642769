import { RpcSettingsType } from "@/config/feature_flags";
import { getCommonTokenMap } from "@/config/token/tokenMap";
import { fetchPositions } from "@/helper/fetchHelper/fetchPositions";
import { fetchTokenPrices } from "@/helper/fetchHelper/fetchTokenPrices";
import { getActivePositions } from "@/helper/positionHelper";
import { TokenPriceMap } from "@/hooks/useFetchAllChainsTokensPrice";
import { generateTokenMap } from "@/utils/helper";
import { AmmEnum } from "@aperture/uikit";
import { ApertureSupportedChainId } from "@aperture_finance/uniswap-v3-automation-sdk";
import { Token } from "@uniswap/sdk-core";
import { Dispatch } from "react";
import { Address } from "viem";
import { AllChainAction, IPositionMap, IRawTokenMap } from "./types";

export const updateTokenPriceMapAction = async (
  dispatch: Dispatch<AllChainAction>,
  chainId: ApertureSupportedChainId,
  tokens: Token[],
  okxQuoteTokens: string[]
): Promise<TokenPriceMap> => {
  // TODO: implement cache to avoid re-fetching token prices
  return fetchTokenPrices(chainId, tokens, okxQuoteTokens).then(
    (tokenPriceMap) => {
      dispatch({
        type: "updateTokenPrice",
        payload: { chainId, tokenPriceMap },
      });
      return tokenPriceMap;
    }
  );
};

export const updatePositionMapAction = async (
  dispatch: Dispatch<AllChainAction>,
  chainId: ApertureSupportedChainId,
  amm: AmmEnum,
  walletAddress: Address,
  rpcSetting?: RpcSettingsType
) => {
  return fetchPositions(chainId, walletAddress, amm, rpcSetting).then(
    (positionMap) => {
      dispatch({
        type: "updatePosition",
        payload: { chainId, amm, positionMap, walletAddress },
      });

      return positionMap;
    }
  );
};

export const updateTokenMapAction = async (
  dispatch: Dispatch<AllChainAction>,
  chainId: ApertureSupportedChainId,
  tokenMap: IRawTokenMap
) => {
  dispatch({
    type: "updateTokenMap",
    payload: { chainId, rawTokenMap: tokenMap },
  });
};

export const updateActivePositionMapAction = async (
  dispatch: Dispatch<AllChainAction>,
  chainId: ApertureSupportedChainId,
  rawPositionMap: IPositionMap,
  tokenMap: IRawTokenMap,
  tokenPriceMap?: TokenPriceMap
) => {
  const positionMap = getActivePositions(
    rawPositionMap,
    tokenMap,
    chainId,
    tokenPriceMap
  );

  dispatch({
    type: "updateActivePositionMap",
    payload: { chainId, positionMap },
  });
};

export const initAllTokenMapAction = async (
  dispatch: Dispatch<AllChainAction>
) => {
  const allChainsWarningTokensJson = localStorage.getItem(
    `all-chains-warning-tokens`
  );
  const allChainsWarningTokens = allChainsWarningTokensJson
    ? JSON.parse(allChainsWarningTokensJson)
    : undefined;

  const initTokenMap = {};
  Object.values(ApertureSupportedChainId).forEach((chainId) => {
    if (typeof chainId !== "number") return;
    const tokenMap = getCommonTokenMap(chainId as ApertureSupportedChainId);
    const warningTokenMap = generateTokenMap(allChainsWarningTokens?.[chainId]);

    initTokenMap[chainId] = Object.fromEntries(
      new Map([...tokenMap, ...warningTokenMap])
    );
  });

  dispatch({
    type: "initAllTokenMap",
    payload: initTokenMap,
  });
};

import { SpaceProps, TypographyProps } from "styled-system";
import { BasicInfo } from "../../utils";

export const variants = {
  PRIMARY: "primary",
  INFO: "info",
  ERROR: "error",
  TEXT: "text",
  LIGHT_GRAY: "lightGray",
  GRAY: "gray",
  WHITE_GRAY: "whiteGray",
  DARK_GRAY: "darkGray",
  SUCCESS: "success",
  AUTO: "auto",
  RISE: "rise",
  LIGHT_FAILED: "lightFailed",
  FAILED: "failed",
  CLOSED: "closed",
} as const;

export type Variant = (typeof variants)[keyof typeof variants];

export interface TagProps extends SpaceProps, TypographyProps {
  variant: Variant;
}

export interface AutoCompoundProps {
  autoCompound: boolean;
  wordOnly?: boolean;
}

export interface RangeProps {
  closed: boolean | Boolean;
  inRange: Boolean;
}

export interface HeaderTagProps {
  amm: BasicInfo;
}

export interface BoostProps {
  percentage: string;
}

import { ApertureLogo } from "@aperture/assetkit";
import { Headline3 } from "@ui/components/Typography";
import styled from "styled-components";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: black;
  width: 100vw;
  height: 100vh;
  padding: 20px;
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 20px;
  margin: auto;
`;
const Title = styled(Headline3)`
  font-family: "Dunbar Tall";
  color: white;
`;
const Subtitle = styled.div`
  font-family: "Roboto Flex";
  color: #ffffffb2;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;

export const MobileAlertContent: React.FC = () => {
  return (
    <ContentWrapper>
      <ApertureLogo width={199} height={46} />
      <TextContainer>
        <img src="bg-blur.png" width="95%" alt="bg" />
        <Title>OOOOPS!</Title>
        <Subtitle>
          Aperture Web-App doesn’t support mobile view at this point. Please log
          on to Aperture with your laptop or PC.
        </Subtitle>
      </TextContainer>
    </ContentWrapper>
  );
};

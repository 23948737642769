import { ElementType } from "react";
import styled from "styled-components";
import { border, color, compose, layout, space } from "styled-system";
import { ButtonProps } from "./types";

// all the button should be extend on this basic button
export const StyledButton = styled.button<ButtonProps>`
  /* font-family: "Poppins", sans-serif; */
  /* font-style: normal; */
`;

const Button = <E extends ElementType = "button">(
  props: ButtonProps<E> // props need to fill out with default values
): JSX.Element => {
  return <StyledButton>{props.children}</StyledButton>;
};

export default Button;

export const BaseButton = styled(StyledButton)`
  box-sizing: border-box;
  padding: 8px 16px;
  border-radius: ${({ theme }) => theme.radii.round};
  border: none;
  outline: none;
  color: ${({ theme }) => theme.colors.global.text.T1};
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  background: ${({ theme }) => theme.colors.global.primary};
  &:hover {
    background: ${({ theme }) => theme.colors.global.primaryHover};
  }
  &:active {
    background: ${({ theme }) => theme.colors.global.primaryHover};
  }
  &:disabled {
    background: ${({ theme }) => theme.colors.global.primaryDisable};
    cursor: auto;
    pointer-events: none;
  }

  &:disabled > * {
    opacity: 0.5;
  }

  > a {
    text-decoration: none;
  }
  ${compose(color, border, layout, space)}

  transition: ${({ theme }) => theme.transitions.default};
`;

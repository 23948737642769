import { useEventCallback } from "@mui/material";
import { Avatar } from "@ui/components/TopBar";
import { useDebounce } from "ahooks";
import { Message } from "ai/react";
import { memo, useMemo } from "react";
import { ChatInputBox } from "./ChatInput";
import { FirstLook } from "./FirstLook";
import { MessageListBox } from "./MessageListBox";
import { PopularQuestions } from "./PopularQuestions";
import { Content, ContentWrapper, InputWrapper } from "./styles";

interface ChatViewProps {
  address: string;
  showIntro?: boolean;
  messages: Message[];
  input: string;
  setInput: (input: string) => void;
  onSubmit: (event?: React.FormEvent<HTMLFormElement>) => void;
  presetQuestions: string[];
  isLoading: boolean;
  isDrawer?: boolean;
}

export const ChatViewComponent: React.FC<ChatViewProps> = ({
  isDrawer = true,
  showIntro,
  address,
  messages,
  input,
  setInput,
  onSubmit,
  presetQuestions,
  isLoading,
}) => {
  const handleInputChange = useEventCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInput(e.target.value);
    }
  );

  const questions = useMemo(() => {
    return presetQuestions.filter((q) =>
      messages.every((m) => m.content !== q)
    );
  }, [presetQuestions, messages]);

  const showPopularQuestions = useDebounce(!showIntro, { wait: 300 });

  return (
    <Content showIntro={showIntro}>
      <ContentWrapper>
        {showIntro ? (
          <FirstLook
            isDrawer={isDrawer}
            presetQuestions={presetQuestions}
            onPromptSelected={(prompt) => {
              setInput(prompt);
              setTimeout(() => {
                onSubmit();
              }, 0);
            }}
          />
        ) : (
          <MessageListBox
            userAvatar={
              <Avatar text={address} style={{ width: 32, height: 32 }} />
            }
            messages={messages}
            isLoading={isLoading}
          />
        )}
      </ContentWrapper>
      <InputWrapper gap={20} isChatMode={!showIntro}>
        {showPopularQuestions && (
          <PopularQuestions
            isDrawer={isDrawer}
            questions={questions}
            onQuestionClick={setInput}
          />
        )}
        <ChatInputBox
          {...{
            input,
            isChatMode: !showIntro,
            handleInputChange,
            onSubmit,
            isLoading,
            bg: "grey",
          }}
        />
      </InputWrapper>
    </Content>
  );
};

export const ChatView = memo(ChatViewComponent);

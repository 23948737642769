import { ChartIcon, LightningIcon, StockMarketIcon } from "@aperture/assetkit";
import { IDefaultPopoverPosition } from "@aperture/types";
import { Flex } from "@uiv2/common";
import styled from "styled-components";
import { IconButton } from ".";
import { variants } from "./types";

const Wrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
  align-items: center;
`;
const LimitOrderWrapper = styled(Wrapper)`
  padding-block: 1px;
`;
const RecurringIconWrapper = styled(Flex)`
  position: relative;
  justify-content: center;
  align-items: center;
`;

const AutomatedRebalanceIcon = styled(LightningIcon)`
  path {
    fill: ${({ theme }) => theme.colors.global.primary};
  }
`;
const PrescheduledPositionCloseIcon = styled(ChartIcon)`
  path {
    fill: ${({ theme }) => theme.colors.global.primary};
  }
`;
const RecurringRebalancedIcon = styled(LightningIcon)`
  path {
    fill: ${({ theme }) => theme.colors.global.success};
  }
`;
const LimitOrderIcon = styled(StockMarketIcon)`
  path {
    fill: ${({ theme }) => theme.colors.global.primary};
  }
`;

export const RebalancedIconBtn: React.FC<{
  warning?: Boolean;
  position?: IDefaultPopoverPosition;
}> = ({ warning, position }) => (
  <RecurringIconWrapper>
    <IconButton
      variant={warning ? variants.ERROR : variants.PRIMARY}
      Icon={
        <Wrapper>
          <AutomatedRebalanceIcon height={16} width={16} />
        </Wrapper>
      }
      content={"Automated Rebalance"}
      position={position}
    />
  </RecurringIconWrapper>
);

export const PrescheduledPIconBtn: React.FC<{
  warning?: boolean;
  position?: IDefaultPopoverPosition;
}> = ({ warning, position }) => (
  <RecurringIconWrapper>
    <IconButton
      variant={warning ? variants.ERROR : variants.PRIMARY}
      Icon={
        <Wrapper>
          <PrescheduledPositionCloseIcon height={16} width={16} />
        </Wrapper>
      }
      content={"Prescheduled Position Close"}
      position={position}
    />
  </RecurringIconWrapper>
);

export const RecurringRebalanceIconBtn: React.FC<{
  warning?: Boolean;
  position?: IDefaultPopoverPosition;
}> = ({ warning, position }) => (
  <RecurringIconWrapper>
    <IconButton
      variant={warning ? variants.ERROR : variants.RECURRING}
      Icon={
        <Wrapper>
          <RecurringRebalancedIcon height={16} width={16} />
        </Wrapper>
      }
      content={"Recurring Rebalance"}
      position={position}
    />
  </RecurringIconWrapper>
);

export const LimitOrderIconBtn: React.FC<{
  warning?: boolean;
  position?: IDefaultPopoverPosition;
}> = ({ warning, position }) => (
  <RecurringIconWrapper>
    <IconButton
      variant={warning ? variants.ERROR : variants.PRIMARY}
      Icon={
        <LimitOrderWrapper>
          <LimitOrderIcon height={16} width={16} />
        </LimitOrderWrapper>
      }
      content={"Limit Order"}
      position={position}
    />
  </RecurringIconWrapper>
);

import { InfoIcon2 } from "@aperture/assetkit";
import { Flex, FlexColumn } from "@uiv2/common";
import { CSSProperties } from "react";
import styled from "styled-components";
import { T2, T3Regular } from "../Typography";

export const Container = styled(FlexColumn)<{ wBorder: boolean }>`
  padding: ${({ wBorder }) => (wBorder ? "16px" : 0)};
  border-radius: ${({ theme }) => theme.radii.md2};
  border: ${({ theme, wBorder }) =>
    wBorder ? `1px solid ${theme.colors.global.line.Line1}` : "none"};
`;
export const Label = styled(T2)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const Value = styled(T2)<{
  color?: CSSProperties["color"];
}>`
  font-weight: 400;
  color: ${({ theme, color }) => color ?? theme.colors.global.text.T1};
`;
export const Line = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.global.line.Line1};
`;
export const Text = styled(T3Regular)<{
  color?: CSSProperties["color"];
}>`
  color: ${({ theme, color }) => color ?? theme.colors.global.text.T3};
`;
export const Wrapper = styled(Flex)`
  border-radius: ${({ theme }) => theme.radii.round};
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.lg}`};
  background-color: ${({ theme }) => theme.colors.global.background.BG3};
`;
export const StyledInfoIcon = styled(InfoIcon2)`
  fill: ${({ theme }) => theme.colors.global.text.T3};
`;
export const Content = styled.div`
  padding: ${({ theme }) => theme.spacing.md2};
  border-radius: ${({ theme }) => theme.radii.md2};
  color: ${({ theme }) => theme.colors.global.text.T1};
  background-color: ${({ theme }) => theme.colors.global.background.BG3};
`;

import { DoubleArrowIcon } from "@aperture/assetkit";
import { IPriceRange, ITokenTickerIconPair } from "@aperture/types";
import { Flex, FlexColumn } from "@uiv2/common";
import { useState } from "react";
import { useTheme } from "styled-components";
import { Label, SwapButtonWrapper, Text } from "./style";

export const PriceRange: React.FC<{
  tokens: ITokenTickerIconPair;
  priceRange: IPriceRange;
}> = ({ tokens, priceRange }) => {
  const theme = useTheme();
  const [swap, setSwap] = useState(false);
  return (
    <Flex alignItems="center" justifyContent="space-between">
      <FlexColumn gap="xs">
        <Label>Min</Label>
        <Flex gap="md" alignItems="center">
          <Text>{swap ? priceRange.swapMin : priceRange.min} </Text>
          <Label>
            {tokens[swap ? 0 : 1].ticker} per {tokens[swap ? 1 : 0].ticker}
          </Label>
        </Flex>
      </FlexColumn>
      <SwapButtonWrapper
        onClick={(event) => {
          event.stopPropagation();
          setSwap(!swap);
        }}
        color={theme.colors.global.text.T1}
      >
        <DoubleArrowIcon width={20} height={20} />
      </SwapButtonWrapper>
      <FlexColumn gap="xs">
        <Label>Max</Label>
        <Flex gap="md" alignItems="center">
          <Text>{swap ? priceRange.swapMax : priceRange.max} </Text>
          <Label>
            {tokens[swap ? 0 : 1].ticker} per {tokens[swap ? 1 : 0].ticker}
          </Label>
        </Flex>
      </FlexColumn>
    </Flex>
  );
};

import { WarningIcon } from "@aperture/assetkit";
import bigDecimal from "js-big-decimal";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { IInputRef } from "..";
import { formatUSDAmount, trimNumber } from "../../../utils/numberFormat";
import {
  Container,
  Conversion,
  ErrorText,
  ErrorWrapper,
  IconWrapper,
  StyledInput,
  StyledSwitch,
  SwitchWrapper,
} from "./style";
import { IPercentageInputGroupProps } from "./types";

export const PercentageInputAdditional: React.FC<
  IPercentageInputGroupProps & {
    additionalAmount?: string;
    noText?: boolean;
  }
> = ({
  amount,
  defaultUnit = "%",
  disabled,
  showError = true,
  inputHeight = 40,
  width,
  percentage,
  color,
  onChange,
  onError,
  additionalAmount = "0",
  noText = false,
  ...props
}) => {
  const inputRef = useRef<IInputRef>(null);
  const [unit, setUnit] = useState(defaultUnit);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const validAmount = amount === "-" || !amount ? "0" : amount;
  const validAdditionalAmount =
    additionalAmount === "-" || !additionalAmount ? "0" : additionalAmount;

  const [conversion, setConversion] = useState(
    unit === "%"
      ? `~$${bigDecimal.add(
          bigDecimal.multiply(validAmount, percentage),
          validAdditionalAmount
        )}`
      : `~${percentage * 100}% of the position`
  );
  const convertValue = useCallback(() => {
    if (bigDecimal.compareTo(validAmount, 0) === 0) return "0";
    return bigDecimal.add(
      bigDecimal.multiply(validAmount, percentage),
      validAdditionalAmount
    );
  }, [validAmount, percentage, additionalAmount]);
  const dollarValueRef = useRef(convertValue());

  const handleInputChange = (value: string) => {
    const formattedValue = getFormattedValue(
      unit,
      validAmount,
      validAdditionalAmount,
      value
    );
    setConversion(
      unit === "%"
        ? `~${formatUSDAmount(
            bigDecimal.add(
              bigDecimal.divide(
                bigDecimal.multiply(validAmount, formattedValue),
                100,
                8
              ),
              validAdditionalAmount
            )
          )}`
        : `~${
            bigDecimal.compareTo(validAmount, 0) === 0
              ? "0"
              : trimNumber(
                  bigDecimal.round(
                    bigDecimal.multiply(
                      bigDecimal.divide(
                        bigDecimal.subtract(
                          formattedValue,
                          validAdditionalAmount
                        ),
                        validAmount,
                        18
                      ),
                      100
                    ),
                    6,
                    0
                  ),
                  4
                )
          }% of the position`
    );
  };

  const handleInputBlur = (value: string) => {
    const formattedValue = getFormattedValue(
      unit,
      validAmount,
      validAdditionalAmount,
      value
    );
    if (unit === "%") {
      onChange(
        parseFloat(trimNumber(bigDecimal.divide(formattedValue, 100, 6)))
      );
    } else {
      dollarValueRef.current = formattedValue;
      onChange(
        bigDecimal.compareTo(validAmount, 0) === 0
          ? 0
          : parseFloat(
              trimNumber(
                bigDecimal.round(
                  bigDecimal.divide(
                    bigDecimal.subtract(formattedValue, validAdditionalAmount),
                    validAmount,
                    18
                  ),
                  6,
                  0
                )
              )
            )
      );
    }
  };

  useEffect(() => {
    dollarValueRef.current = convertValue();
    inputRef?.current?.setValue(
      unit === "%"
        ? trimNumber(bigDecimal.multiply(percentage, 100))
        : dollarValueRef.current
    );
  }, [unit, percentage, convertValue, onChange]);

  useEffect(() => {
    setErrorMsg(onError?.(percentage));
  }, [amount, percentage]);

  return (
    <Container width={width} {...props}>
      <StyledInput
        ref={inputRef}
        align="right"
        type="number"
        color={color}
        height={inputHeight}
        format={unit === "%" ? { suffix: "%" } : { prefix: "$" }}
        disabled={disabled}
        error={!disabled && !!errorMsg}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
      />
      <SwitchWrapper height={inputHeight}>
        <StyledSwitch
          leftValue={"%"}
          rightValue={"$"}
          disabled={disabled}
          selected={unit === "%" ? 0 : 1}
          sliderColor="primary"
          onSelect={(value) => {
            setUnit(value);
          }}
        />
      </SwitchWrapper>
      {!!errorMsg && showError ? (
        <ErrorWrapper>
          <IconWrapper>
            <WarningIcon height={15} width={15} />
          </IconWrapper>
          <ErrorText>{errorMsg}</ErrorText>
        </ErrorWrapper>
      ) : (
        !noText && (
          <Conversion fontWeight={400} lineHeight={"16px"}>
            {conversion}
          </Conversion>
        )
      )}
    </Container>
  );
};

const getFormattedValue = (
  unit: "$" | "%",
  amount: string,
  additionalAmount: string,
  value: string
) => {
  let formattedValue = value;
  if (unit === "$") {
    const minDollarValue = bigDecimal.add("0", additionalAmount);
    const maxDollarValue = bigDecimal.add(amount, additionalAmount);

    if (bigDecimal.compareTo(value, minDollarValue) < 0) {
      formattedValue = minDollarValue;
    } else if (bigDecimal.compareTo(value, maxDollarValue) > 0) {
      formattedValue = maxDollarValue;
    }
  } else {
    const maxPercentageValue = "100";
    if (bigDecimal.compareTo(value, maxPercentageValue) > 0) {
      formattedValue = maxPercentageValue;
    }
  }
  return formattedValue;
};

import { FlexColumn } from "@uiv2/common";
import { H2 } from "@uiv2/components/Typography";
import styled from "styled-components";

export const Container = styled(FlexColumn)`
  width: 368px;
  padding: 16px;
  gap: ${({ theme }) => theme.spacing["3xl"]};
  border-radius: ${({ theme }) => theme.radii.xl};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
`;
export const Heading = styled(H2)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

import { IToken } from "@aperture/types";
import bigDecimal from "js-big-decimal";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { DateSelector } from "../DateSelector";
import { Dropdown, IDropdownRef } from "../Dropdown";
import { notificationVariants } from "../Notification";
import { QuestionPopover } from "../Popover";
import { Slider } from "../Slider";
import { DEFAULT_TIME_BUFFER, MAX_TIME_BUFFER } from "../Strategy/utils";
import { SwitchMenuTab } from "../Tabs";
import { getTriggerNotification, getTriggerPopoverText } from "../TriggerList";
import { Button3, Headline5, Subtitle0 } from "../Typography";
import {
  AboveBelowBtn,
  AboveBelowContent,
  ArrowDown,
  Checkbox,
  InputContainer,
  InputWapper,
  SliderLabels,
  StyledInput,
  StyledInputText,
  StyledInputWithTicker,
  StyledNotification,
  StyledTokenSelector,
  TimeBuffer,
  TimeSection,
  Title,
  TriggerSetupContainer,
} from "./style";
import { ITriggerSetupForm, ITriggerSetupProps, ITriggerType } from "./types";

const blankForm = (token: IToken): ITriggerSetupForm => ({
  type: "Price",
  Price: {
    token: token,
    aboveOrBelow: "Above",
    amount: "0",
    timeBuffer: DEFAULT_TIME_BUFFER,
    timeBufferChecked: false,
  },
  Ratio: {
    token: token,
    aboveOrBelow: "Above",
    percentage: 0.5,
    timeBuffer: DEFAULT_TIME_BUFFER,
    timeBufferChecked: false,
  },
  Time: {
    dateTime: "",
  },
});

export const TriggerSetup: React.FC<ITriggerSetupProps> = ({
  errorMsg,
  showTimeBuffer = true,
  initForm,
  tokens,
  tooltip,
  expireDays,
  onFormChange,
}) => {
  const [form, setForm] = useState<ITriggerSetupForm>(
    initForm || blankForm(tokens[0])
  );
  const [tab, setTab] = useState<ITriggerType>(form.type);
  const [date, setDate] = useState<string | null>(
    form.Time.dateTime ? form.Time.dateTime : null
  );
  const [time, setTime] = useState<string | null>(
    form.Time.dateTime ? form.Time.dateTime : null
  );
  const aboveBelowRef = useRef<IDropdownRef>(null);

  const updateForm = (
    tab: "Price" | "Ratio" | "Time",
    key: string,
    value: string | number | IToken | boolean | Date
  ) => {
    setForm((prevState) => ({
      ...prevState,
      [tab]: {
        ...prevState[tab],
        [key]: value,
      },
    }));
  };

  const handleChangeDate = (type: string, value: string) => {
    if (type === "date") {
      setDate(value);
    } else {
      setTime(value);
    }
  };

  const handleRatioPercentageChange = (value: string) => {
    let newValue = parseFloat(bigDecimal.divide(value, 100, 8));
    if (newValue < 0) newValue = 0;
    else if (newValue > 1) newValue = 1;
    updateForm(tab, "percentage", newValue);
  };

  const handleTabChange = (value: ITriggerType) => {
    setTab(value);
    if (form.type !== value) {
      setForm((prevState) => ({
        ...prevState,
        type: value,
      }));
    }
  };

  useEffect(() => {
    onFormChange(form);
    // eslint-disable-next-line
  }, [form]);

  useEffect(() => {
    if (date && time) {
      updateForm(
        tab,
        "dateTime",
        `${moment(date).format("MMMM DD, yyyy")} ${moment(time).format(
          "hh:mm a"
        )}`
      );
    }
    // eslint-disable-next-line
  }, [date, time]);

  useEffect(() => {
    if (initForm && initForm.type !== tab) {
      setTab(initForm.type);
    }
  }, [initForm]);

  return (
    <TriggerSetupContainer>
      <Title>
        <Headline5>Trigger Setup</Headline5>
        {tooltip && (
          <QuestionPopover
            content={tooltip}
            defaultPosition={{ top: 16, left: -100 }}
          />
        )}
      </Title>
      <SwitchMenuTab
        menu={["Price", "Ratio", "Time"]}
        defaultSelected={["Price", "Ratio", "Time"].indexOf(form.type)}
        onSelect={handleTabChange}
        width={292}
      />
      {tab !== "Time" && (
        <>
          <InputContainer>
            <StyledTokenSelector
              size="large"
              selectedToken={form[tab].token}
              tokens={tokens}
              onSelect={(token) => {
                updateForm(tab, "token", token);
              }}
            />
            <Dropdown
              ref={aboveBelowRef}
              button={
                <AboveBelowBtn>
                  <Button3>{form[tab].aboveOrBelow}</Button3>
                  <ArrowDown />
                </AboveBelowBtn>
              }
            >
              <AboveBelowContent>
                <li
                  onClick={() => {
                    updateForm(tab, "aboveOrBelow", "Above");
                    aboveBelowRef?.current?.onClose();
                  }}
                >
                  Above
                </li>
                <li
                  onClick={() => {
                    updateForm(tab, "aboveOrBelow", "Below");
                    aboveBelowRef?.current?.onClose();
                  }}
                >
                  Below
                </li>
              </AboveBelowContent>
            </Dropdown>
            {tab === "Price" && (
              <StyledInputWithTicker
                align="left"
                type="number"
                defaultValue={form.Price.amount}
                ticker={
                  tokens.find(
                    (token) => token.address !== form.Price.token.address
                  )?.ticker || "USD"
                }
                popover={getTriggerPopoverText("Price")}
                onChange={(value) => {
                  updateForm(tab, "amount", value);
                }}
              />
            )}
            {tab === "Ratio" && (
              <StyledInputWithTicker
                align="left"
                type="number"
                format={{ suffix: "%" }}
                ticker=""
                popover={getTriggerPopoverText(
                  "Ratio",
                  form.Ratio.token.ticker
                )}
                defaultValue={bigDecimal
                  .multiply(form.Ratio.percentage, 100)
                  .toString()}
                disabled={!form.Ratio.token}
                onChange={handleRatioPercentageChange}
              />
            )}
          </InputContainer>
          {tab === "Ratio" && form.Ratio.token && (
            <>
              <Slider
                value={form.Ratio.percentage}
                onChange={(value) => {
                  updateForm(tab, "percentage", value);
                }}
              />
              <SliderLabels>
                <Headline5>{form.Ratio.token?.ticker}</Headline5>
                <Headline5>
                  <span>
                    {bigDecimal.multiply(
                      bigDecimal.subtract(1, form.Ratio.percentage),
                      100
                    )}
                    %
                  </span>{" "}
                  {tokens.find(
                    (token) => token.ticker !== form.Ratio.token.ticker
                  )?.ticker || "-"}
                </Headline5>
              </SliderLabels>
            </>
          )}
          {showTimeBuffer && (
            <TimeBuffer>
              <Checkbox
                checked={form[tab].timeBufferChecked}
                onChange={(e) => {
                  updateForm(
                    tab,
                    "timeBufferChecked",
                    (e.target as HTMLInputElement).checked
                  );
                }}
              />
              <Subtitle0>TWAP Duration</Subtitle0>
              <QuestionPopover
                defaultPosition={{ left: -100, bottom: 24 }}
                defaultSize={{ width: 500 }}
                content="Enabling TWAP (time-weight average price) on your trigger means the average price or ratio based trigger between past specified time and now must be met before the trigger executes. This helps prevent potential price manipulation and examples are for rebalancing if 1) average ETH goes above $3,500 for past 30+ mins or 2) average pool above 80% USDC for past 2+ hrs."
              />
              <InputWapper>
                <StyledInput
                  align="left"
                  type="number"
                  placeholder="0"
                  defaultValue={form[tab]?.timeBuffer?.toString()}
                  disabled={!form[tab].timeBufferChecked}
                  onChange={(value) =>
                    updateForm(tab, "timeBuffer", parseFloat(value || "0"))
                  }
                  onBlur={() => {
                    if (
                      bigDecimal.compareTo(
                        form[tab]?.timeBuffer,
                        MAX_TIME_BUFFER
                      ) > 0
                    )
                      updateForm(tab, "timeBuffer", MAX_TIME_BUFFER);
                  }}
                />
                <StyledInputText>
                  {bigDecimal.compareTo(form[tab]?.timeBuffer, 1) > 0
                    ? "hrs"
                    : "hr"}
                </StyledInputText>
              </InputWapper>
            </TimeBuffer>
          )}
        </>
      )}
      {tab === "Time" && (
        <TimeSection>
          <Headline5>Select Date and Time</Headline5>
          <DateSelector
            date={time ? new Date(time) : null}
            type="time"
            onChange={(time) => {
              handleChangeDate("time", time);
            }}
          />
          <DateSelector
            date={date ? new Date(date) : null}
            expireDays={expireDays}
            onChange={(value) => {
              handleChangeDate("date", value);
            }}
          />
        </TimeSection>
      )}
      {errorMsg && (
        <StyledNotification
          variant={notificationVariants.WARNING}
          text={errorMsg}
        />
      )}
      {/*@ts-ignore*/}
      {!errorMsg && form[tab].timeBufferChecked && (
        <StyledNotification
          variant={notificationVariants.WARNING}
          text={getTriggerNotification()}
        />
      )}
    </TriggerSetupContainer>
  );
};

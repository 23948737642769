import { ComponentStory } from "@storybook/react";
import {
  ConfirmationSpinner,
  RaindropSpinner,
  RingSpinner,
  Spinner,
} from "./Spinner";

export default {
  title: "Components/Loader/Spinner",
  component: Spinner,
  parameters: {
    layout: "fullscreen",
  },
};

const Template: ComponentStory<typeof Spinner> = (args) => (
  <Spinner {...args} />
);

export const Default = Template.bind({});
Default.args = { size: 20, duration: 1, partition: 9 };

const Confirmation: ComponentStory<typeof ConfirmationSpinner> = (args) => (
  <ConfirmationSpinner {...args} />
);

export const ConfirmationLoader = Confirmation.bind({});
ConfirmationLoader.args = { width: 21, height: 21 };

const Ring: ComponentStory<typeof RingSpinner> = (args) => (
  <RingSpinner {...args} />
);

export const RingLoader = Ring.bind({});
RingLoader.args = { width: 24, height: 24 };

const Raindrop: ComponentStory<typeof RaindropSpinner> = (args) => (
  <RaindropSpinner {...args} />
);

export const RaindropLoader = Raindrop.bind({});
RaindropLoader.args = { width: 16, height: 16 };

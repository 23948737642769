import { max, scaleLinear, ZoomTransform } from "d3";
import { useEffect, useMemo, useRef, useState } from "react";

import { Area } from "./Area";
import { AxisBottom } from "./AxisBottom";
import { Brush, CurrentBrush } from "./Brush";
import { Line } from "./Line";
import { ChartEntry, LiquidityChartProps } from "./types";
import Zoom, { ZoomOverlay } from "./Zoom";

const xAccessor = (d: ChartEntry) => d.price0;
const yAccessor = (d: ChartEntry) => d.activeLiquidity;

export function Chart({
  id = "liquidityChartRangeInput",
  data: { series, current },
  styles,
  dimensions: { width, height },
  margins,
  interactive = true,
  brushDomain,
  brushLabels,
  onBrushDomainChange,
  zoomLevels,
  currentRange,
  brushMinValue,
  brushBoundary,
  axisSuffix,
}: LiquidityChartProps) {
  const zoomRef = useRef<SVGRectElement>(null);
  const [zoom, setZoom] = useState<ZoomTransform | null>(null);
  const [innerHeight, innerWidth] = useMemo(
    () => [
      height - margins.top - margins.bottom,
      width - margins.left - margins.right,
    ],
    [width, height, margins]
  );
  const seriesUpdate = useMemo(() => series.length > 0 && series, [series]);
  const { xScale, yScale } = useMemo(() => {
    const scales = {
      xScale: scaleLinear()
        .domain([
          (current === 0 ? 1 : current) * zoomLevels.initialMin,
          (current === 0 ? 1 : current) * zoomLevels.initialMax,
        ] as number[])
        .range([0, innerWidth]),
      yScale: scaleLinear()
        .domain([0, max(series, yAccessor)] as number[])
        .range([innerHeight, 0]),
    };

    if (zoom) {
      const newXscale = zoom.rescaleX(scales.xScale);
      scales.xScale.domain(newXscale.domain());
    }
    return scales;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    current,
    seriesUpdate,
    zoomLevels.initialMin,
    zoomLevels.initialMax,
    innerWidth,
    innerHeight,
    zoom,
  ]);

  useEffect(() => {
    // reset zoom as necessary
    setZoom(null);
  }, [zoomLevels]);

  return (
    <div>
      <Zoom
        svgRef={zoomRef}
        xScale={xScale}
        setZoom={setZoom}
        width={innerWidth}
        height={
          // allow zooming inside the x-axis
          height
        }
        resetBrush={() => {
          onBrushDomainChange(
            [
              (current === 0 ? 1 : current) * zoomLevels.initialMin,
              (current === 0 ? 1 : current) * zoomLevels.initialMax,
            ] as [number, number],
            "reset"
          );
        }}
        showResetButton={false}
        zoomLevels={zoomLevels}
      />
      <svg
        width="100%"
        height="100%"
        viewBox={`0 0 ${width} ${height}`}
        style={{ overflow: "visible" }}
      >
        <defs>
          <clipPath id={`${id}-chart-clip`}>
            <rect x="0" y="0" width={innerWidth} height={height} />
          </clipPath>

          {brushDomain && (
            // mask to highlight selected area
            <mask id={`${id}-chart-area-mask`}>
              <rect
                fill="white"
                x={xScale(brushDomain[0])}
                y="0"
                width={xScale(brushDomain[1]) - xScale(brushDomain[0])}
                height={innerHeight}
              />
            </mask>
          )}
        </defs>

        <g transform={`translate(${margins.left},${margins.top})`}>
          <g clipPath={`url(#${id}-chart-clip)`}>
            <Area
              series={series}
              xScale={xScale}
              yScale={yScale}
              xValue={xAccessor}
              yValue={yAccessor}
            />
            {brushDomain && (
              <g mask={`url(#${id}-chart-area-mask)`}>
                <Area
                  series={series}
                  xScale={xScale}
                  yScale={yScale}
                  xValue={xAccessor}
                  yValue={yAccessor}
                />
              </g>
            )}

            <Line value={current} xScale={xScale} innerHeight={innerHeight} />
            <AxisBottom
              xScale={xScale}
              innerHeight={innerHeight}
              suffix={axisSuffix}
            />
          </g>

          <ZoomOverlay width={innerWidth} height={height} ref={zoomRef} />

          {currentRange && (
            <CurrentBrush
              xScale={xScale}
              brushExtent={currentRange}
              innerWidth={innerWidth}
              innerHeight={innerHeight}
              westHandleColor={styles.currentBrush.handle.west}
              eastHandleColor={styles.currentBrush.handle.east}
            />
          )}

          <Brush
            id={id}
            xScale={xScale}
            interactive={interactive}
            brushLabelValue={brushLabels}
            brushExtent={brushDomain ?? (xScale.domain() as [number, number])}
            innerWidth={innerWidth}
            innerHeight={innerHeight}
            setBrushExtent={onBrushDomainChange}
            westHandleColor={styles.brush.handle.west}
            eastHandleColor={styles.brush.handle.east}
            brushMinValue={brushMinValue}
            brushBoundary={brushBoundary}
          />
        </g>
      </svg>
    </div>
  );
}

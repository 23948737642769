import styled from "styled-components";
import { variant } from "styled-system";
import { IDropdownContainer } from "./types";

export const Container = styled.div`
  position: relative;
  display: inline-block;
  width: fit-content;
`;

export const DropdownContainer = styled.div<IDropdownContainer>`
  position: absolute;
  background: ${({ theme }) => theme.colors.global.background.BG2};
  color: ${({ theme }) => theme.colors.global.text.T1};
  border-radius: ${({ theme }) => theme.radii.xl};
  transition: opacity ${({ theme }) => theme.transitions.default};
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.shadows.medium};
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  ${variant({
    prop: "display",
    variants: {
      show: {
        opacity: "100%",
        pointerEvents: "unset",
      },
      hide: {
        opacity: "0",
        pointerEvents: "none",
      },
    },
  })};
  ${({ buttonHeight, gap }) =>
    variant({
      prop: "position",
      variants: {
        topLeft: {
          left: 0,
          bottom: `calc(${buttonHeight}px + ${gap}px)`,
        },
        topRight: {
          left: 0,
          bottom: `calc(${buttonHeight}px + ${gap}px)`,
        },
        bottomLeft: {
          right: 0,
          top: `calc(${buttonHeight}px + ${gap}px)`,
        },
        bottomRight: {
          left: 0,
          top: `calc(${buttonHeight}px + ${gap}px)`,
        },
      },
    })};
`;

// @ts-nocheck
import { formatPrice, formatTokenAmount } from "@uiv2/utils/numberFormat";
import * as d3 from "d3";
import { findMax } from "../../utils";
import { D3PositionBreakdownBin, D3PositionBreakdownProps } from "../types";

class D3PositionBreakdownByTotal {
  containerEl;
  props;
  svg;
  x;
  y0;
  xAxis;
  xAxisHeight;
  yAxis;
  yAxisWidth;
  currentTick;
  priceRange;
  token0Symbol;
  token1Symbol;
  colors;

  constructor(containerEl: any, props: D3PositionBreakdownProps) {
    this.containerEl = containerEl;
    this.xAxisHeight = 20;
    this.yAxisWidth = 45;
    this.props = props;
    this.token0Symbol = props.token0Symbol;
    this.token1Symbol = props.token1Symbol;
    this.colors = props.colors;

    this.svg = d3
      .select(containerEl)
      .append("svg")
      .attr("viewBox", `0 0 ${props.width} ${props.height}`);

    // add x axis
    const x = d3
      .scaleLinear()
      .domain([this.props.minTick, this.props.maxTick])
      .range([this.yAxisWidth, props.width - this.yAxisWidth]);
    this.xAxis = this.svg
      .append("g")
      .attr(
        "transform",
        "translate(0," + (props.height - this.xAxisHeight) + ")"
      )
      .attr("color", this.colors.axis)
      .call(d3.axisBottom(x).ticks(5));
    this.x = x;

    // add y axis for token 0 to the left
    const y0 = d3
      .scaleLinear()
      .domain([0, findMax(this.props.data.map((d) => d.y0))])
      .range([props.height - this.xAxisHeight, 1]);
    this.yAxis = this.svg
      .append("g")
      .attr("transform", `translate(${this.yAxisWidth}, 0)`)
      .attr("color", this.colors.axis)
      .call(d3.axisLeft(y0));
    this.y0 = y0;

    // render chart for total value
    this.svg
      .append("path")
      .datum(this.props.data as any)
      .attr("fill", "transparent")
      .attr("stroke", this.colors.graph)
      .attr("stroke-width", 1.5)
      .attr(
        "d",
        d3
          .line()
          .x((d: any) => x(d.x))
          .y((d: any) => y0(d.y0))
      );

    this.priceRange = this.renderPriceRange(props.minTick, props.maxTick);
    this.currentTick = this.renderCurrentTick(this.props.currentTick);
    this.handleMouseMove();
  }

  destroy() {
    this.svg.remove();
  }

  renderPriceRange(minTick: number, maxTick: number) {
    const minX = this.x(minTick);
    const maxX = this.x(maxTick);
    return this.svg
      .append("path")
      .datum(this.props.data as any)
      .attr("fill", this.colors.graph)
      .attr("stroke", "transparent")
      .attr("stroke-width", 1.5)
      .attr(
        "d",
        d3
          .area()
          .x((d: any) => this.x(d.x))
          .y0(this.props.height - this.xAxisHeight)
          .y1((d: any) =>
            this.x(d.x) > minX && this.x(d.x) < maxX
              ? this.y0(d.y0)
              : this.props.height - this.xAxisHeight
          )
      );
  }
  updateMinMaxTickRange(
    minTick: number,
    maxTick: number,
    isFullRange: boolean
  ) {
    const minX = isFullRange ? 0 : this.x(minTick);
    const maxX = isFullRange
      ? this.props.width - this.yAxisWidth
      : this.x(maxTick);
    this.priceRange.attr(
      "d",
      d3
        .area()
        .x((d: any) => this.x(d.x))
        .y0(this.props.height - this.xAxisHeight)
        .y1((d: any) =>
          this.x(d.x) > minX && this.x(d.x) < maxX
            ? this.y0(d.y0)
            : this.props.height - this.xAxisHeight
        )
    );
  }

  renderCurrentTick(currentTick: number) {
    return this.svg
      .append("g")
      .append("line")
      .style("stroke-width", 1.25)
      .style("stroke-dasharray", "10, 3")
      .style("stroke", this.colors.current)
      .attr("x1", this.x(currentTick))
      .attr("y1", 0)
      .attr("x2", this.x(currentTick))
      .attr("y2", this.props.height - this.xAxisHeight);
  }
  updateCurrentTick(currentTick: number) {
    this.currentTick
      .attr("x1", this.x(currentTick))
      .attr("y1", 0)
      .attr("x2", this.x(currentTick))
      .attr("y2", this.props.height - this.xAxisHeight);
  }

  handleMouseMove() {
    const bisect = d3.bisector(function (d: D3PositionBreakdownBin) {
      return d.x;
    }).left;
    const focusTextLP = this.svg
      .append("g")
      .append("text")
      .style("opacity", 0)
      .attr("fill", this.colors.axis)
      .attr("font-size", "0.6rem")
      .attr("alignment-baseline", "middle");
    const focusTextPrice = this.svg
      .append("g")
      .append("text")
      .style("opacity", 0)
      .attr("fill", this.colors.axis)
      .attr("font-size", "0.6rem")
      .attr("alignment-baseline", "middle");
    const verticalLine = this.svg
      .append("g")
      .append("line")
      .style("stroke-width", 0.5)
      .style("stroke", this.colors.axis);

    const onMouseMove = (e: any) => {
      let coords = d3.pointer(e);
      const x0 = this.x.invert(coords[0]);
      const i = bisect(this.props.data, x0, 1) - 1;
      const selectedData = this.props.data[i];
      const y0 = selectedData.y0;
      if (
        this.x(x0) > this.yAxisWidth &&
        this.x(x0) < this.props.width - this.yAxisWidth
      ) {
        verticalLine
          .attr("x1", this.x(x0))
          .attr("y1", 0)
          .attr("x2", this.x(x0))
          .attr("y2", this.props.height - this.xAxisHeight);

        const self = this;
        if (this.x(x0) > this.props.width * 0.5) {
          focusTextLP
            .html(`LP Value: ${formatTokenAmount(y0)} ${this.token0Symbol}`)
            .attr("x", function (d: any) {
              return self.x(x0) - (this.getComputedTextLength() + 5);
            })
            .attr("text-anchor", "right")
            .attr("y", 5);
          focusTextPrice
            .html(`Price: ${formatPrice(x0)} ${this.token0Symbol}`)
            .attr("x", function (d: any) {
              return self.x(x0) - (this.getComputedTextLength() + 5);
            })
            .attr("text-anchor", "right")
            .attr("y", 15);
        } else {
          focusTextLP
            .html(`LP Value: ${formatTokenAmount(y0)} ${this.token0Symbol}`)
            .attr("x", this.x(x0) + 5)
            .attr("text-anchor", "left")
            .attr("y", 5);
          focusTextPrice
            .html(`Price: ${formatPrice(x0)} ${this.token0Symbol}`)
            .attr("x", this.x(x0) + 5)
            .attr("text-anchor", "left")
            .attr("y", 15);
        }
      }
    };

    this.svg
      .append("rect")
      .style("fill", "none")
      .style("pointer-events", "all")
      .attr("width", this.props.width)
      .attr("height", this.props.height)
      .on("mouseover", () => {
        focusTextLP.style("opacity", 1);
        focusTextPrice.style("opacity", 1);
        verticalLine.style("opacity", 1);
      })
      .on("mouseout", () => {
        focusTextLP.style("opacity", 0);
        focusTextPrice.style("opacity", 0);
        verticalLine.style("opacity", 0);
      })
      .on("mousemove", onMouseMove);
  }
}

export default D3PositionBreakdownByTotal;

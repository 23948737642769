import { Flex } from "@uiv2/common";
import { formatUSDAmount } from "@uiv2/utils/numberFormat";
import { T1, T3 } from "./style";

export const Fees: React.FC<{
  liquidity: string;
  fees: string;
  closed?: boolean;
}> = ({ liquidity = "", fees = "", closed = false }) => {
  return (
    <Flex gap="xl" alignItems={"center"}>
      <Flex gap="md" alignItems={"center"}>
        <T3>Liquidity</T3>
        <T1>{formatUSDAmount(liquidity)}</T1>
      </Flex>
      <Flex gap="md" alignItems={"center"}>
        <T3>{closed ? "Accrued Fees" : "Unclaimed Fees"}</T3>
        <T1>{formatUSDAmount(fees)}</T1>
      </Flex>
    </Flex>
  );
};

import { ITokenPair } from "@aperture/types";
import bigDecimal from "js-big-decimal";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import styled from "styled-components";
import { formatTokenAmount } from "../../utils/numberFormat";
import { notificationVariants } from "../Notification";
import { InfoPopover } from "../Popover/InfoPopover";
import { getTriggerNotification, getTriggerPopoverText } from "../TriggerList";
import { Button3, Headline5 } from "../Typography";
import { StyledNotification, Title, TriggerSetupContainer } from "./style";
import { ITriggerSetupForm } from "./types";

type IProps = {
  form: ITriggerSetupForm | null;
  tokens: ITokenPair;
};

const SetupInfo = styled(Button3)`
  max-width: fit-content;
  padding: 12px;
  background: ${({ theme }) => theme.colors.global.background.BG3};
  border-radius: ${({ theme }) => theme.radii.round};
  line-height: 20px;
  display: inline-flex;
  align-items: center;
  > span {
    font-size: 16px;
  }
  p {
    margin: 0;
  }
`;
const TriggerInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const TokenIconWrapper = styled.div`
  height: 20px;
  width: 20px;
`;

const timeZone = momentTimeZone.tz.guess();

export const TriggerSetupPreview: React.FC<IProps> = ({ form, tokens }) => {
  return (
    <TriggerSetupContainer>
      <Title>
        <Headline5>Trigger Setup</Headline5>
      </Title>
      {form && (
        <>
          <SetupInfo>
            {form.type === "Price" && (
              <TriggerInfoWrapper>
                <TokenIconWrapper>{form.Price.token.Icon}</TokenIconWrapper>
                <p>
                  {form.Price.token.ticker} Price {form.Price.aboveOrBelow}{" "}
                  <span>
                    {formatTokenAmount(form.Price.amount)}{" "}
                    {tokens.find(
                      (token) => token.address !== form.Price.token.address
                    )?.ticker || "USD"}
                  </span>
                  {form.Price.timeBufferChecked &&
                    form.Price.timeBuffer !== 0 && (
                      <span>
                        {" "}
                        for {form.Price.timeBuffer}{" "}
                        {bigDecimal.compareTo(form.Price.timeBuffer, 1) > 0
                          ? "hrs"
                          : "hr"}
                      </span>
                    )}
                </p>
                {!(
                  form.Price.timeBufferChecked && form.Price.timeBuffer !== 0
                ) && (
                  <InfoPopover
                    defaultPosition={{ top: 20, left: -120 }}
                    content={getTriggerPopoverText("Price")}
                  />
                )}
              </TriggerInfoWrapper>
            )}
            {form.type === "Ratio" && (
              <TriggerInfoWrapper>
                <TokenIconWrapper>{form.Ratio.token.Icon}</TokenIconWrapper>
                <p>
                  {form.Ratio.token.ticker} Token Ratio{" "}
                  {form.Ratio.aboveOrBelow}
                  <span>
                    {" "}
                    {bigDecimal.multiply(form.Ratio.percentage, 100)}%
                  </span>
                  {form.Ratio.timeBufferChecked &&
                    form.Ratio.timeBuffer !== 0 && (
                      <span>
                        {" "}
                        for {form.Ratio.timeBuffer}{" "}
                        {bigDecimal.compareTo(form.Ratio.timeBuffer, 1) > 0
                          ? "hrs"
                          : "hr"}
                      </span>
                    )}
                </p>
                {!(
                  form.Ratio.timeBufferChecked && form.Ratio.timeBuffer !== 0
                ) && (
                  <InfoPopover
                    content={getTriggerPopoverText(
                      "Ratio",
                      form.Ratio.token.ticker
                    )}
                    defaultPosition={{ top: 20, left: -138 }}
                  />
                )}
              </TriggerInfoWrapper>
            )}
            {form.type === "Time" && form.Time.dateTime !== "" && (
              <TriggerInfoWrapper>
                Time:
                <span>
                  {" "}
                  {moment(form.Time.dateTime).format("MM/DD/YYYY hh:mm A")}{" "}
                  {momentTimeZone.tz(timeZone).zoneAbbr()}
                </span>
              </TriggerInfoWrapper>
            )}
          </SetupInfo>
          {((form.type === "Price" &&
            form.Price.timeBufferChecked &&
            form.Price.timeBuffer !== 0) ||
            (form.type === "Ratio" &&
              form.Ratio.timeBufferChecked &&
              form.Ratio.timeBuffer !== 0)) && (
            <StyledNotification
              variant={notificationVariants.WARNING}
              text={getTriggerNotification()}
            />
          )}
        </>
      )}
    </TriggerSetupContainer>
  );
};

import ReactSlider from "react-slider";
import styled from "styled-components";

export const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 4px;
`;
export const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.colors.global.line.Line2};
`;
export const StyledThumb = styled.div<{ value: number | number[] }>`
  height: 20px;
  width: 4px;
  text-align: center;
  cursor: grab;
  outline: none;
  border-radius: 100px;
  transform: translateY(calc(4px / 2 + -20px / 2));
  background-color: ${({ value, theme }) =>
    typeof value === "object"
      ? theme.colors.global.primary
      : theme.colors.global.secondary};
`;

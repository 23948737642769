import { ILogger } from "@aperture_finance/uniswap-v3-automation-sdk";
import { datadogLogs } from "@datadog/browser-logs";

export class DatadogLogger implements ILogger {
  constructor(
    clientToken: string,
    version: string,
    site = "us3.datadoghq.com",
    env: "production" | "development" = "production",
    forwardErrorsToLogs = true,
    sessionSampleRate = 100
  ) {
    datadogLogs.init({
      clientToken,
      site,
      forwardErrorsToLogs,
      sessionSampleRate,
      env,
      service: "web-app",
      version,
    });
    datadogLogs.setGlobalContextProperty("client_version", version); //TODO: deprecate later
  }

  info(message: string, messageContext?: object): void {
    datadogLogs.logger.info(message, messageContext);
  }
  debug(message: string, messageContext?: object): void {
    datadogLogs.logger.debug(message, messageContext);
  }
  error(message: string, messageContext?: object): void {
    datadogLogs.logger.error(message, messageContext);
  }
  warn(message: string, messageContext?: object): void {
    datadogLogs.logger.warn(message, messageContext);
  }
}

import { ReactNode } from "react";

export enum Direction {
  Up = "UP",
  Down = "DOWN",
  Left = "LEFT",
  Right = "RIGHT",
}

export interface TokenIconsProps {
  leftIcon: ReactNode;
  rightIcon: ReactNode;
  size: number;
  overlap?: number;
  overlapRight?: boolean;
}

import { LinkIcon2 } from "@aperture/assetkit";
import { ActionTypeEnum } from "@aperture_finance/uniswap-v3-automation-sdk";
import { Flex } from "@ui/common/styles/global";
import React from "react";
import { useTheme } from "styled-components";
import { TriggerInfoCard } from "./TriggerInfoCard";
import {
  GoToButton,
  Head,
  IconWrapper,
  StyledTag,
  StyledWarningIcon,
  Title,
  TriggerGroupContainer,
} from "./style";
import { ITriggerListProps } from "./types";
import { isStrategyTrigger } from "./utils";

export const TriggerGroup: React.FC<ITriggerListProps> = ({
  triggers,
  getErrorTag,
  onClickGoTo,
  ...props
}) => {
  const theme = useTheme();

  if (!triggers || !triggers.length) {
    return null;
  }

  const isStrategy = isStrategyTrigger(triggers[0]);
  const actionType = triggers[0].action.type;
  const errorTag = getErrorTag?.(triggers[0]);

  return (
    <TriggerGroupContainer>
      <Head>
        <Title>{getTitle(actionType)}</Title>
        {isStrategy && (
          <Flex gap={"md"} alignItems="center">
            {errorTag && (
              <StyledTag variant="lightFailed">
                <StyledWarningIcon width={16} height={16} />
                {errorTag}
              </StyledTag>
            )}
            <GoToButton
              onClick={() => {
                onClickGoTo && onClickGoTo(triggers[0]);
              }}
            >
              Strategy
              <IconWrapper>
                <LinkIcon2 stroke={theme.colors.global.primary} />
              </IconWrapper>
            </GoToButton>
          </Flex>
        )}
      </Head>
      {triggers.map((trigger) => (
        <TriggerInfoCard
          key={`${trigger.taskId}-${trigger.nftId}`}
          trigger={trigger}
          {...props}
        />
      ))}
    </TriggerGroupContainer>
  );
};

const getTitle = (key: ActionTypeEnum) => {
  switch (key) {
    case "Close":
      return "Pre-scheduled Position Close";
    case "Rebalance":
      return "Automated Rebalance Triggers";
    case "RecurringPercentage":
    case "RecurringPrice":
    case "RecurringRatio":
    case "RecurringDualAction":
      return "Recurring Rebalance";
    default:
      return "";
  }
};

import {
  Breakpoints,
  MediaQueries,
  Radii,
  Shadows,
  Spacing,
  Transitions,
  ZIndices,
} from "./types";

export const breakpointMap: { [key: string]: number } = {
  "3xs": 389,
  "2xs": 599,
  xs: 899,
  sm: 1199,
  md: 1439,
  lg: 1919,
};

const breakpoints: Breakpoints = Object.values(breakpointMap).map(
  (breakpoint) => `${breakpoint}px`
);

const mediaQueries: MediaQueries = {
  "3xs": `@media screen and (max-width: ${breakpointMap["3xs"]}px)`,
  "2xs": `@media screen and (max-width: ${breakpointMap["2xs"]}px)`,
  xs: `@media screen and (max-width: ${breakpointMap.xs}px)`,
  sm: `@media screen and (max-width: ${breakpointMap.sm}px)`,
  md: `@media screen and (max-width: ${breakpointMap.md}px)`,
  lg: `@media screen and (max-width: ${breakpointMap.lg}px)`,
  xl: `@media screen and (min-width: ${breakpointMap.lg}px)`,
};

export const shadows: Shadows = {
  small: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  medium: "0px 0px 8px rgba(0, 0, 0, 0.25)",
  mediumPurple: "0px 0px 8px rgba(89, 53, 166, 0.35)",
  mediumYellow: "0px 0px 8px rgba(255, 223, 0, 0.35)",
  large: "0px 0px 12px rgba(0, 0, 0, 0.75)",
  xLarge: "0px 0px 20px rgba(0, 0, 0, 0.25)",
};

const spacing: Spacing = {
  zero: "0",
  xs: "2px",
  sm: "4px",
  sm2: "6px",
  md: "8px",
  md2: "10px",
  lg: "12px",
  lg2: "14px",
  xl: "16px",
  "2xl": "20px",
  "3xl": "24px",
  "4xl": "28px",
  "5xl": "32px",
  "6xl": "36px",
  "7xl": "40px",
  "8xl": "44px",
  "9xl": "48px",
  "10xl": "52px",
  "11xl": "56px",
  "12xl": "60px",
  "13xl": "64px",
  "14xl": "68px",
  "15xl": "72px",
  "16xl": "76px",
};

const radii: Radii = {
  sm: "4px",
  md: "8px",
  md2: "10px",
  lg: "12px",
  xl: "16px",
  "2xl": "20px",
  "3xl": "24px",
  circle: "50%",
  round: "999999999px",
};

const transitions: Transitions = {
  fast: "0.1s",
  default: "0.3s",
  slow: "0.5s",
};

const zIndices: ZIndices = {
  tab: 4,
  ribbon: 5,
  dropdown: 6,
  modal: 7,
  snackbar: 8,
  backdrop: 89,
};

// eslint-disable-next-line
export default {
  siteWidth: 1920, // to be figured out
  breakpoints,
  mediaQueries,
  spacing,
  shadows,
  radii,
  transitions,
  zIndices,
};

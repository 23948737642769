import { CaretIcon } from "@aperture/assetkit";
import styled from "styled-components";
import { InfoCard } from "../InfoCard";
import { Popover } from "../Popover";
import { Subtitle2, Subtitle3 } from "../Typography";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px !important;
`;

export const Title = styled.div<{ isLoading: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  color: ${({ isLoading, theme }) =>
    isLoading ? theme.colors.global.text.T2 : theme.colors.global.text.T2};
  cursor: pointer;
`;

export const Detail = styled.div`
  font-family: "Roboto Flex";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

export const S2 = styled(Subtitle2)`
  line-height: 20px;
  color: ${({ theme }) => theme.colors.global.text.T2};
`;

export const S3 = styled(Subtitle3)`
  line-height: 14px;
`;

export const S4 = styled(Subtitle2)`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding: 6px 16px;
`;

export const ArrowBtn = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ArrowDown = styled(CaretIcon)<{ isShow: boolean }>`
  width: 24px;
  height: 24px;
  transition: transform ${({ theme }) => theme.transitions.default};
  transform: ${({ isShow }) => (isShow ? "rotate(180deg)" : "")};
  path {
    fill: ${({ theme }) => theme.colors.global.text.T1};
  }
`;

export const Content = styled.div<{
  isShow: boolean;
  useOverflow: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xl};
  overflow: ${({ useOverflow }) => (useOverflow ? "initial" : "hidden")};
  margin-top: ${({ isShow }) => (isShow ? "20px" : "0")};
  height: ${({ isShow }) => (isShow ? "148px" : "0")};
  transition: height ${({ theme }) => theme.transitions.default},
    padding-top ${({ theme }) => theme.transitions.default};
`;

export const SplitTop = styled.hr`
  width: 100%;
  margin: 0;
`;

export const SplitBottomDiv = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const SplitBottom = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
`;

export const StyledInfoCard = styled(InfoCard)`
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  span {
    color: ${({ theme }) => theme.colors.global.text.T2};
  }
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const ApertureAPI = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  border-radius: ${({ theme }) => theme.radii.round};
  background: ${({ theme }) => theme.colors.global.background.BG2};
`;

export const OrderRoutingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 410px;
  padding: 4px;
  gap: ${({ theme }) => theme.spacing.md};
`;

export const OrderRoutingText = styled.div`
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.global.text.T1};
  overflow-wrap: break-word;
`;

export const RouteWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const RouteSellTokenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RouteIcon = styled.div`
  > div {
    height: 32px;
    width: 32px;
  }
`;

export const RouteTag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  gap: ${({ theme }) => theme.spacing.sm};
  border-radius: ${({ theme }) => theme.radii.md};
  color: ${({ theme }) => theme.colors.global.text.T1};
  background: ${({ theme }) => theme.colors.global.background.BG4};
`;

export const DotLine = styled.div<{ width?: number }>`
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  height: 2px;
  margin: ${({ width }) => (width ? "0 8px" : "0 4px")};
  text-align: center;
  justify-content: center;

  background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.global.text.T4} 75%,
      transparent 25%
    )
    top repeat-x;
  background-size: 6px;
`;

export const StyledPopover = styled(Popover)`
  > div {
    width: 100%;
    cursor: auto;
  }
`;

export const PopoverText = styled.div`
  color: ${({ theme }) => theme.colors.global.text.T1};
  cursor: auto;
`;

export const WarningContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xl};
  display: flex;
  position: relative;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  border-radius: ${({ theme }) => theme.radii.round};
  color: ${({ theme }) => theme.colors.global.error};
  background: ${({ theme }) => `${theme.colors.global.error}1A`};
`;

export const WarningText = styled(Subtitle2)`
  font-weight: 400;
  line-height: 16px;
`;

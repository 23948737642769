import { ConfirmationSpinner } from "@ui/components/Loader";
import styled from "styled-components";
import { ButtonText } from "../../Typography";
import { ModalText1, ModalTitle1 } from "../Typography";
import { ConfirmationProps } from "../types";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 416px;
  padding-top: 32px;
  align-items: center;
  text-align: center;
`;
const TitleText = styled(ModalTitle1)`
  padding-top: 36px;
  line-height: 24px;
  letter-spacing: 0.0025em;
  color: ${({ theme }) => theme.colors.grey.black};
`;
const MainText = styled(ModalText1)`
  padding-top: 56px;
  color: ${({ theme }) => theme.colors.grey.black};
`;
const SubText = styled(ButtonText)`
  padding-top: 16px;
  line-height: 16px;
  letter-spacing: 0.0025em;
  color: ${({ theme }) => theme.colors.grey.mediumGrey};
`;

export const ConfirmationContent: React.FC<ConfirmationProps> = ({ text }) => {
  return (
    <ContentWrapper>
      <ConfirmationSpinner />
      <TitleText>Waiting for Confirmation</TitleText>
      <MainText>{text}</MainText>
      <SubText>Confirm this transaction in your wallet</SubText>
    </ContentWrapper>
  );
};

import { IToken } from "@aperture/types";
import {
  ActionTypeEnum,
  RecurringAction,
  RecurringPercentageAction,
  RecurringPriceAction,
  RecurringRatioAction,
} from "@aperture_finance/uniswap-v3-automation-sdk";
import { TriggerType } from "@ui/common/types/Strategy";
import { formatTokenAmount } from "@ui/utils";
import React, { useMemo } from "react";
import { IStrategyTriggerDetailProps } from "../type";
import {
  emptyDualTriggerForm,
  formatProportion,
  offsetTickToPercentage,
} from "../utils";
import { PureStrategyTriggerDetail } from "./PureStrategyTriggerDetail";
import { IconWrapper, ItemValue, TriggerRangeItem } from "./style";

export const StrategyTriggerDetail: React.FC<IStrategyTriggerDetailProps> = ({
  onEdit,
  tokens,
  trigger,
}) => {
  const {
    triggerType,
    baseToken,
    timeBufferChecked,
    aboveChecked,
    belowChecked,
    below,
    above,
    aboveAction,
    belowAction,
    timeBuffer,
  } = trigger ?? emptyDualTriggerForm;

  const token2 = tokens[1 - baseToken];

  const aboveValue = useMemo(() => {
    if (!aboveChecked) {
      return "";
    }

    if (triggerType === TriggerType.PRICE) {
      return `${formatTokenAmount(above, token2.decimals)} ${token2.ticker}`;
    }
    return `${above}%`;
  }, [above, aboveChecked, token2.decimals, token2.ticker, triggerType]);

  const belowValue = useMemo(() => {
    if (!belowChecked) {
      return "";
    }

    const prefix = triggerType === TriggerType.RATIO ? "" : "-";
    if (triggerType === TriggerType.PRICE) {
      return `${prefix}${formatTokenAmount(below, token2.decimals)} ${
        token2.ticker
      }`;
    }
    return `${prefix}${below}%`;
  }, [below, belowChecked, token2.decimals, token2.ticker, triggerType]);

  const belowRange = useMemo(() => {
    if (!belowAction) return undefined;
    return getRebalanceRange(belowAction, tokens);
  }, [belowAction, tokens]);
  const aboveRange = useMemo(() => {
    if (!aboveAction) return undefined;
    return getRebalanceRange(aboveAction, tokens);
  }, [aboveAction, tokens]);

  return (
    <PureStrategyTriggerDetail
      token={tokens[baseToken]}
      above={aboveValue}
      below={belowValue}
      aboveRange={aboveRange}
      belowRange={belowRange}
      triggerType={triggerType}
      timeBuffer={timeBufferChecked ? parseFloat(timeBuffer || "0") : 0}
      onClickEdit={onEdit}
    />
  );
};

export const getRebalanceRange = (
  triggerAction: RecurringAction,
  tokens: IToken[]
) => {
  if (triggerAction.type === ActionTypeEnum.Values.RecurringRatio) {
    const { tickRangeWidth, token0ValueProportion } =
      triggerAction as RecurringRatioAction;

    const tokenARatio = formatProportion(token0ValueProportion);
    const tokenBRatio = (100 - parseFloat(tokenARatio)).toFixed(2);

    return (
      <ItemValue>
        {tokenARatio}% {tokens[0].ticker} & {tokenBRatio}% {tokens[1].ticker}{" "}
        with {tickRangeWidth} tick width based on future spot price
      </ItemValue>
    );
  }
  if (triggerAction.type === ActionTypeEnum.Values.RecurringPrice) {
    const { baseToken, priceLowerOffset, priceUpperOffset } =
      triggerAction as RecurringPriceAction;
    const tokenA = tokens[baseToken];
    const tokenB = tokens[1 - baseToken];
    return (
      <>
        <TriggerRangeItem>
          Min <IconWrapper>{tokenA.Icon}</IconWrapper> {tokenA.ticker}{" "}
          {formatTokenAmount(priceLowerOffset)} {tokenB.ticker} below future
          spot price
        </TriggerRangeItem>
        <TriggerRangeItem>
          Max <IconWrapper>{tokenA.Icon}</IconWrapper> {tokenA.ticker}{" "}
          {formatTokenAmount(priceUpperOffset)} {tokenB.ticker} above future
          spot price
        </TriggerRangeItem>
      </>
    );
  }
  if (triggerAction.type === ActionTypeEnum.Values.RecurringPercentage) {
    const { tickLowerOffset, tickUpperOffset } =
      triggerAction as RecurringPercentageAction;

    return (
      <>
        <TriggerRangeItem>
          Min <IconWrapper>{tokens[0].Icon}</IconWrapper> {tokens[0].ticker}{" "}
          {offsetTickToPercentage(tickLowerOffset)}% below future spot price
        </TriggerRangeItem>
        <TriggerRangeItem>
          Max <IconWrapper>{tokens[0].Icon}</IconWrapper> {tokens[0].ticker}{" "}
          {offsetTickToPercentage(tickUpperOffset)}% above future spot price
        </TriggerRangeItem>
      </>
    );
  }
  return undefined;
};

import styled from "styled-components";
import { variant } from "styled-system";
import { IStepIcon, IStepsLine, IStepsLineActive, IStepsProps } from "./types";

const StepsContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  color: ${({ theme }) => theme.colors.grey.black};
`;
const StepItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  z-index: 2;
  gap: ${({ theme }) => theme.spacing.md};
`;
const StepIcon = styled.div<IStepIcon>`
  flex: 1;
  width: 20px;
  min-height: 20px;
  line-height: 20px;
  margin: auto;
  border-radius: ${({ theme }) => theme.radii.circle};
  transition: all ${({ theme }) => theme.transitions.default};

  ${({ theme }) =>
    variant({
      variants: {
        active: {
          background: theme.colors.global.primary,
        },
        inactive: {
          background: theme.colors.global.line.Line2,
        },
        current: {
          background: theme.colors.global.primary,
        },
      },
    })}
`;
const StepsLine = styled.div<IStepsLine>`
  position: absolute;
  top: 10px;
  height: 1px;
  background: ${({ theme }) => theme.colors.global.line.Line2};
  z-index: 0;
  left: ${({ itemsLen }) => 100 / itemsLen / 2}%;
  right: ${({ itemsLen }) => 100 / itemsLen / 2}%;
`;
const StepsLineActive = styled.div<IStepsLineActive>`
  position: absolute;
  top: 10px;
  height: 1px;
  background: ${({ theme }) => theme.colors.global.primary};
  z-index: 1;
  left: ${({ itemsLen }) => 100 / itemsLen / 2}%;
  right: ${({ itemsLen, activeIndex }) =>
    100 - (100 / itemsLen / 2) * (activeIndex * 2 + 1)}%;
  transition: right ${({ theme }) => theme.transitions.default};
`;
const Title = styled.div`
  font-family: "Roboto Flex";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const Subtitle = styled.div`
  font-family: "Roboto Flex";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.global.text.T2};
`;

export const Steps: React.FC<IStepsProps> = ({ activeIndex, stepsList }) => {
  const itemsLen = stepsList.length;
  let stepIndex = activeIndex || 0;
  if (stepIndex > itemsLen - 1) {
    stepIndex = 0;
    console.error("Steps component: Incorrect activeIndex");
  }
  return (
    <StepsContainer>
      {stepsList?.map((step, ix) => (
        <StepItem key={ix}>
          <StepIcon
            variant={
              stepIndex === ix
                ? "current"
                : stepIndex > ix
                ? "active"
                : "inactive"
            }
          ></StepIcon>
          <Title>{`Step ${ix + 1}`}</Title>
          <Subtitle>{step}</Subtitle>
        </StepItem>
      ))}
      <StepsLine itemsLen={itemsLen} />
      <StepsLineActive itemsLen={itemsLen} activeIndex={stepIndex} />
    </StepsContainer>
  );
};

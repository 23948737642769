import { formatTokenAmount } from "@ui/utils/numberFormat";
import {
  axisWidth,
  checkOverlap,
  generateSpots,
  getEdge,
  normalizeValue,
  parseMarker,
} from "./getData";
import {
  Axis,
  AxisContainer,
  AxisInner,
  Marker,
  MarkerLabel,
  MarkerWrapper,
  OverlayLine,
  Spot,
  SpotLabel,
  SpotWrapper,
  StyledPopover,
  StyledViewIcon,
  Text,
  ViewButton,
} from "./style";
import { ViewTriggerProps } from "./types";

export const ViewTrigger: React.FC<ViewTriggerProps> = ({
  type = "filled",
  below,
  above,
  unit = undefined,
  disabled = false,
  position,
}) => {
  const isPriceTrigger = unit !== undefined;
  const isRatioTrigger = !isPriceTrigger;

  const belowMarker = parseMarker(below, isRatioTrigger, isPriceTrigger);
  const aboveMarker = parseMarker(above, isRatioTrigger);
  const spots = isPriceTrigger
    ? generateSpots(belowMarker ?? 0, aboveMarker ?? 0)
    : Array.from({ length: 5 }, (_, i) => i * 25);
  const [minSpot, maxSpot] = [Math.min(...spots), Math.max(...spots)];
  const markers = isPriceTrigger
    ? [belowMarker, 0, aboveMarker]
    : [belowMarker, aboveMarker];

  return (
    <StyledPopover
      noBorder={true}
      background="transparent"
      disabled={disabled}
      content={
        <AxisContainer axisWidth={axisWidth}>
          <Axis>
            <AxisInner>
              {/* Overlay Lines */}
              {belowMarker ? (
                <OverlayLine
                  width={normalizeValue(belowMarker, minSpot, maxSpot)}
                  below={true}
                  isPriceTrigger={isPriceTrigger}
                />
              ) : undefined}
              {aboveMarker ? (
                <OverlayLine
                  left={normalizeValue(aboveMarker, minSpot, maxSpot)}
                  width={100 - normalizeValue(aboveMarker, minSpot, maxSpot)}
                  below={false}
                  isPriceTrigger={isPriceTrigger}
                />
              ) : undefined}

              {/* Spots */}
              {(isPriceTrigger ? spots.slice(1, 4) : spots).map(
                (spot, index) => (
                  <SpotWrapper
                    key={`${spot}-${index}`}
                    left={normalizeValue(spot, minSpot, maxSpot)}
                  >
                    <Spot />
                    <SpotLabel spot={spot} isPriceTrigger={isPriceTrigger}>
                      {spot === 0
                        ? isPriceTrigger
                          ? "Spot Price"
                          : spot
                        : `${formatTokenAmount(spot)}${
                            !isPriceTrigger || unit === "%" ? "%" : ""
                          }`}
                    </SpotLabel>
                  </SpotWrapper>
                )
              )}

              {/* Markers */}
              {markers.map((marker, index) => {
                const isSpotPrice = marker === 0;
                if (marker !== undefined && !Number.isNaN(marker)) {
                  let left;
                  let right;
                  const isBelow = index === 0;
                  const isAbove = index === markers.length - 1;
                  const edge = getEdge(marker, minSpot, maxSpot);
                  if (isBelow) {
                    left = edge === "L" ? "-20px" : undefined;
                    right = edge !== "L" ? "-20px" : undefined;
                  }
                  if (isAbove) {
                    left = edge !== "R" && isRatioTrigger ? "-20px" : undefined;
                    right = edge === "R" ? "-20px" : undefined;
                  }
                  const suffix = `${
                    !isPriceTrigger || unit === "%" ? "%" : ` ${unit}`
                  }`;

                  // Handle possible marker label overlap
                  if (isRatioTrigger) {
                    if (markers[0] !== undefined && markers[1] !== undefined) {
                      const { difference, aEdge } = checkOverlap(
                        markers[0],
                        markers[1],
                        minSpot,
                        maxSpot
                      );
                      const isOverlapping = difference > 0;
                      const shift = difference + 2;

                      if (isOverlapping) {
                        if (isBelow && aEdge === "R" && right) {
                          right = `${(
                            parseInt(right.substring(0, right.length - 2)) +
                            shift
                          ).toFixed(2)}px`;
                        }
                        if (isAbove && aEdge !== "R" && left) {
                          left = `${(
                            parseInt(left.substring(0, left.length - 2)) + shift
                          ).toFixed(2)}px`;
                        }
                      }
                    }
                  }
                  return (
                    <MarkerWrapper
                      left={normalizeValue(marker, minSpot, maxSpot)}
                      key={`${marker}-${index}`}
                    >
                      <Marker key={marker} spotPrice={isSpotPrice} />
                      {!isSpotPrice && (
                        <MarkerLabel
                          variant="primary"
                          left={left}
                          right={right}
                          fontSize={14}
                          lineHeight={"16px"}
                          fontWeight={400}
                        >
                          {`${formatTokenAmount(marker)}${suffix}`}
                        </MarkerLabel>
                      )}
                    </MarkerWrapper>
                  );
                }
                return undefined;
              })}
            </AxisInner>
          </Axis>
        </AxisContainer>
      }
      defaultSize={{ width: axisWidth + 32 }}
      defaultPosition={position ?? { right: 0, bottom: 48 }}
    >
      <ViewButton disabled={disabled} t={type}>
        <StyledViewIcon disabled={disabled} t={type} />
        <Text disabled={disabled} t={type}>
          View Trigger
        </Text>
      </ViewButton>
    </StyledPopover>
  );
};

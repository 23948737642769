import { Flex, FlexColumn } from "@uiv2/common";
import { formatFixed } from "@uiv2/utils/numberFormat";
import React from "react";
import { useTheme } from "styled-components";
import { ActivityLogs } from "../ActivityLog";
import { Popover } from "../Popover";
import {
  Container,
  Label,
  Line,
  StyledInfoIcon,
  Text,
  Value,
  Wrapper,
} from "./style";
import { IPositionAnalytics } from "./types";

export const PositionAnalytics: React.FC<IPositionAnalytics> = ({
  tokens,
  activityLogs,
  pnl,
  timeAPR,
  getMoneyAPR,
  totalAPR,
  feeAPR,
  divergenceLoss,
  gasCost,
  unit,
  wBorder = true,
  setLogsShown,
  totalFees,
  avgLiquidityUSD,
}: IPositionAnalytics) => {
  const theme = useTheme();

  const isProd = process.env.NEXT_PUBLIC_ENV === "prod";

  const devData = {
    "Total Fees": [formatFixed(totalFees, 2), " USD"],
    "AVG Liquidity": [formatFixed(avgLiquidityUSD, 2), " USD"],
  };

  const data = {
    PNL: [formatFixed(pnl, 2), unit, Number(pnl) >= 0],
    // TODO Analytics: Add time-weighted and money-weighted APR
    // "Time-Weighted APR": [formatFixed(timeAPR, 2), "%", Number(timeAPR) >= 0],
    "Money-Weighted APR": [
      formatFixed(getMoneyAPR(), 2),
      "%",
      Number(getMoneyAPR()) >= 0,
    ],
    "Total APR": [formatFixed(totalAPR, 2), "%", Number(totalAPR) >= 0],
    "Fee APR": [formatFixed(feeAPR, 2), "%", Number(feeAPR) >= 0],
    "Divergence Loss": [
      formatFixed(divergenceLoss, 2),
      unit,
      Number(divergenceLoss) > 0,
    ],
    "Gas Cost Associated": [
      formatFixed(-gasCost, 2),
      unit,
      Number(gasCost) < 0,
    ],
    ...(isProd ? {} : devData),
  };
  return (
    <Container gap="xl" wBorder={wBorder}>
      <Flex alignItems="center" justifyContent="space-between">
        <Label>Position Analytics</Label>
        <Flex gap="md" alignItems="center">
          <Text>Ref Val</Text>
          <Wrapper gap="sm">
            <Text color={theme.colors.global.text.T1}>HOLD</Text>
            <Popover
              contentStyles={{
                textAlign: "left",
                fontSize: "14px",
                lineHeight: "20px",
                padding: 10,
                borderRadius: 10,
                maxWidth: 320,
              }}
              defaultPosition={{ right: -28, bottom: 27 }}
              content="Benchmark represents the current value of deposited tokens, as though 
                they were held in your wallet instead of being deposited into the position."
            >
              <StyledInfoIcon />
            </Popover>
          </Wrapper>
        </Flex>
      </Flex>
      <FlexColumn gap="lg">
        {Object.entries(data).map(([label, value]) => {
          const color =
            value[2] === undefined
              ? theme.colors.global.text.T1
              : value[2]
              ? theme.colors.global.success
              : theme.colors.global.error;
          return (
            <Flex
              key={label}
              alignItems="center"
              justifyContent="space-between"
            >
              <Label>{label}</Label>
              <Value color={color}>{`${value[0]}${value[1]}`}</Value>
            </Flex>
          );
        })}
      </FlexColumn>
      <Line />
      <ActivityLogs {...{ tokens, logs: activityLogs, setLogsShown }} />
    </Container>
  );
};

import { Flex, FlexColumn } from "@ui/common/styles/global";
import { InputWTickerProps, notificationVariants } from "@ui/components";
import React, { useMemo } from "react";
import { TriggerAction } from "../TriggerAction";
import { E_TriggerTypeTab, E_WidthUnit, IPureEditTriggerProps } from "../type";
import {
  ButtonBox,
  IconWrapper,
  InputContainer,
  StyledInputWithTicker,
  StyledNotification,
  Subtitle2,
  Title,
  TriggerActionWrapper,
  TriggerSetupContainer,
} from "./style";

const PureComponent: React.FC<IPureEditTriggerProps> = ({
  triggerIdx,
  triggerType,
  tokens,

  selectedToken,
  widthUnit,

  aboveChecked,
  above,
  onAboveInput,

  belowChecked,
  below,
  onBelowInput,

  tickSpacing,
  defaultAction,
  tokenMarketPrice,

  aboveTriggerAction,
  belowTriggerAction,
  setAboveTriggerAction,
  setBelowTriggerAction,

  isAddTrigger,

  errorMsg,
  invalidInput,

  onSave,
  onDiscard,
  bg,
}) => {
  const isRatio = triggerType === E_TriggerTypeTab.Ratio;

  const aboveLabel = isRatio
    ? strings.ratioAboveLabel
    : strings.priceAboveLabel;
  const belowLabel = isRatio
    ? strings.ratioBelowLabel
    : strings.priceBelowLabel;

  const inputProps = useMemo<InputWTickerProps>(() => {
    const isPercentage =
      triggerType === E_TriggerTypeTab.Ratio ||
      widthUnit === E_WidthUnit.Percentage;
    return {
      align: "right",
      type: "number",
      ticker: "",
      ...(isPercentage
        ? {
            format: {
              suffix: "%",
            },
          }
        : {
            ticker:
              tokens.find((token) => token.address !== selectedToken.address)
                ?.ticker || "USD",
          }),
    };
  }, [selectedToken.address, tokens, triggerType, widthUnit]);

  return (
    <TriggerSetupContainer bg={bg}>
      <FlexColumn gap="lg">
        <Title gap="sm">
          Edit
          <Flex gap="sm" alignItems="center">
            <IconWrapper>{selectedToken.Icon}</IconWrapper>
            {selectedToken.ticker}
          </Flex>
          {isRatio ? "Ratio" : "Price"} Trigger {triggerIdx}
        </Title>

        <InputContainer
          alignItems="flex-start"
          gap={!aboveChecked && !belowChecked ? "xl" : 48}
        >
          {aboveChecked && (
            <FlexColumn gap="lg">
              <FlexColumn gap="lg" style={{ width: "fit-content" }}>
                <Subtitle2>{aboveLabel}</Subtitle2>
                <StyledInputWithTicker
                  {...inputProps}
                  align="left"
                  disabled={!aboveChecked}
                  onError={() => invalidInput === "above"}
                  defaultValue={above.toString()}
                  popover={getTriggerPopoverText(triggerType, "upper")}
                  onChange={onAboveInput}
                />
              </FlexColumn>
              <TriggerActionWrapper gap="lg">
                <Subtitle2>{strings.setRangeTitle}</Subtitle2>
                <TriggerAction
                  tickSpacing={tickSpacing}
                  defaultAction={defaultAction}
                  tokens={[tokens[0], tokens[1]]}
                  tokenMarketPrice={tokenMarketPrice}
                  triggerAction={aboveTriggerAction}
                  setTriggerAction={setAboveTriggerAction}
                />
              </TriggerActionWrapper>
            </FlexColumn>
          )}

          {belowChecked && (
            <FlexColumn gap="lg">
              <FlexColumn gap="lg" style={{ width: "fit-content" }}>
                <Subtitle2>{belowLabel}</Subtitle2>
                <StyledInputWithTicker
                  {...inputProps}
                  align="left"
                  format={{
                    ...inputProps.format,
                    prefix: triggerType === E_TriggerTypeTab.Price ? "-" : "",
                  }}
                  onError={() => invalidInput === "below"}
                  disabled={!belowChecked}
                  defaultValue={below?.toString()}
                  popover={getTriggerPopoverText(triggerType, "lower")}
                  onChange={onBelowInput}
                />
              </FlexColumn>
              <TriggerActionWrapper gap="lg">
                <Subtitle2>{strings.setRangeTitle}</Subtitle2>
                <TriggerAction
                  tickSpacing={tickSpacing}
                  defaultAction={defaultAction}
                  tokens={[tokens[0], tokens[1]]}
                  tokenMarketPrice={tokenMarketPrice}
                  triggerAction={belowTriggerAction}
                  setTriggerAction={setBelowTriggerAction}
                />
              </TriggerActionWrapper>
            </FlexColumn>
          )}
        </InputContainer>

        {errorMsg && (
          <StyledNotification
            variant={notificationVariants.WARNING}
            text={errorMsg}
          />
        )}

        <Flex gap="xl">
          <ButtonBox
            variant="contained"
            size="md"
            width="201px"
            color="default"
            onClick={onDiscard}
          >
            {strings.discardButtonLabel}
          </ButtonBox>
          <ButtonBox
            variant="contained"
            size="md"
            width="201px"
            color="secondary"
            disabled={
              !!(errorMsg || invalidInput || (!aboveChecked && !belowChecked))
            }
            onClick={onSave}
          >
            {isAddTrigger ? strings.addButtonLabel : strings.saveButtonLabel}
          </ButtonBox>
        </Flex>
      </FlexColumn>
    </TriggerSetupContainer>
  );
};
export const PureEditTriggerSetup = React.memo(PureComponent);

const strings = {
  priceAboveLabel: "Above Spot Price By",
  priceBelowLabel: "Below Spot Price By",
  ratioAboveLabel: "Ratio Above",
  ratioBelowLabel: "Ratio Below",
  saveButtonLabel: "Save Edits",
  addButtonLabel: "Add Trigger",
  discardButtonLabel: "Discard",
  title: "Create Trigger",
  setRangeTitle: "Set New Rebalance Range",
};

const getTriggerPopoverText = (
  triggerType: E_TriggerTypeTab,
  upperOrLower: "upper" | "lower"
) => {
  if (triggerType === E_TriggerTypeTab.Ratio) {
    return `This is the ${upperOrLower} ratio threshold to trigger the rebalance. Strategy will execute when the token ratio crosses this threshold.`;
  }
  return "";
};

import { IToken } from "@aperture/types";
import {
  ApertureSupportedChainId,
  PriceCondition,
  TriggerItem,
  getTokenValueProportionFromPriceRatio,
} from "@aperture_finance/uniswap-v3-automation-sdk";
import { InfoPopover } from "@ui/components/Popover";
import Big from "big.js";
import bigDecimal from "js-big-decimal";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import { RelativePriceInfo } from "./RelativePriceInfo";
import { InfoItem } from "./TriggerInfoCard";
import { TimeZone } from "./style";
import {
  formatDuration,
  getTriggerNotification,
  getTriggerPopoverText,
  getType,
} from "./utils";

const timeZone = momentTimeZone.tz.guess();

export function getOtherInfoListItems(
  trigger: TriggerItem,
  tokens: IToken[],
  tickRange: number[],
  chainId: ApertureSupportedChainId
): InfoItem[] {
  const triggerType = getType(trigger);
  // @ts-ignore
  const popoverText = !!trigger.condition.durationSec
    ? getTriggerNotification()
    : triggerType === "Ratio"
    ? getTriggerPopoverText("Ratio", tokens[0].ticker)
    : triggerType === "Price" &&
      (trigger.condition as PriceCondition)?.singleToken !== undefined
    ? ""
    : getTriggerPopoverText(trigger.condition.type);
  const hidePopover =
    trigger.condition.type === "Price" &&
    trigger.condition.frontendType === "RELATIVE_PRICE";

  const listItems: InfoItem[] = [
    {
      label: "Trigger",
      value: (
        <>
          {getTriggerInfo(trigger, tokens, tickRange, chainId, popoverText)}
          {!hidePopover && (
            <InfoPopover
              content={popoverText}
              defaultPosition={{ top: 20, right: 16 }}
            />
          )}
        </>
      ),
      showBubble: !!popoverText,
    },
  ];

  if (trigger.condition.type === "Price") {
    listItems.push({
      label: "Expiration",
      value: (
        <>
          <TimeZone>
            {`${timeZone} (GMT${momentTimeZone.tz(timeZone).format("Z")})`}
          </TimeZone>
          {`${moment(trigger.expiration * 1000).format(
            "MMM Do, YYYY"
          )} (${moment(trigger.expiration * 1000).diff(
            moment(),
            "days"
          )} Days)`}
        </>
      ),
    });
  }

  return listItems;
}

const getTriggerInfo = (
  trigger: TriggerItem,
  tokens: IToken[],
  tickRange: number[],
  chainId: ApertureSupportedChainId,
  popoverText: string
) => {
  const tokenA = tokens[0];

  if (trigger.condition.type === "Time") {
    return (
      <>
        <TimeZone>
          {timeZone} (GMT{momentTimeZone.tz(timeZone).format("Z")})
        </TimeZone>
        <TimeZone>
          {moment(trigger.condition.timeAfterEpochSec * 1000).format(
            "hh:mm a, MMM Do, YYYY"
          )}
        </TimeZone>
      </>
    );
  }

  if (trigger.condition.type === "Price") {
    const { durationSec, singleToken, frontendType, gte, lte } =
      trigger.condition;

    const duration = formatDuration(durationSec);
    const amount = (gte || lte) as string;
    let aboveOrBelow = gte ? "Above" : "Below";
    if (frontendType === "RELATIVE_PRICE") {
      return (
        <RelativePriceInfo
          trigger={trigger}
          tokens={tokens}
          chainId={chainId}
          popoverText={popoverText}
        />
      );
    } else if (singleToken !== undefined) {
      // Display legacy price triggers (xxx token above / below xxx USD)
      // single token usd price condition
      return `${tokens[singleToken].ticker} Price ${aboveOrBelow} ${amount} ${duration}`;
    } else {
      // if the price of `token0` is `gte`, then the ratio is `Below`
      aboveOrBelow = gte ? "Below" : "Above";
      const price = new Big(amount || "0");
      try {
        // ratio condition is based on the current tick range
        const ratio = getTokenValueProportionFromPriceRatio(
          tickRange[0],
          tickRange[1],
          price
        ).toFixed(4);
        return `${tokenA.ticker} Ratio ${aboveOrBelow} ${bigDecimal.multiply(
          ratio,
          100
        )}% ${duration}`;
      } catch (e) {
        return `${tokenA.ticker} Ratio ${aboveOrBelow} 0%`;
      }
    }
  }
};

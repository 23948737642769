import React from "react";
import styled from "styled-components";
import { compose, layout, space, typography } from "styled-system";
import { UITypographyProps } from "./types";

const StyledH1 = styled.div<UITypographyProps>`
  font-family: "Dunbar Tall";
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
  ${compose(layout, space, typography)}
`;
export const H1: React.FC<React.PropsWithChildren<UITypographyProps>> = ({
  children,
  ...props
}) => <StyledH1 {...props}>{children}</StyledH1>;

const StyledH2 = styled.div<UITypographyProps>`
  font-family: "Dunbar Tall";
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  ${compose(layout, space, typography)}
`;
export const H2: React.FC<React.PropsWithChildren<UITypographyProps>> = ({
  children,
  ...props
}) => <StyledH2 {...props}>{children}</StyledH2>;

const StyledH3 = styled.div<UITypographyProps>`
  font-family: "Dunbar Tall";
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  ${compose(layout, space, typography)}
`;
export const H3: React.FC<React.PropsWithChildren<UITypographyProps>> = ({
  children,
  ...props
}) => <StyledH3 {...props}>{children}</StyledH3>;

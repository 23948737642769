import { CopyFileIcon } from "@aperture/assetkit";
import { Flex } from "@ui/common";
import { PositionInfoTitle } from "@ui/components/PositionInfoTitle";
import { StakeTag } from "@ui/components/Tag";
import { T1Regular, T3Regular } from "@ui/components/Typography";
import { useCopyText } from "@ui/utils/hooks";
import { useTheme } from "styled-components";
import { Fees } from "./Fees";
import { Status } from "./Status";
import { SwapRate } from "./SwapRate";
import {
  ChainInfo,
  CopyWrapper,
  CurrentChainDot,
  IconWrapper,
  PositionInfo,
  StyledInRangeIcon,
  StyledPercentage,
  StyledRangeTag,
  StyledSubtitle2,
  Wrapper,
} from "./style";
import { PositionCardProps } from "./types";

export const LgPositionCard: React.FC<
  Omit<PositionCardProps, "id" | "chainId" | "supportedChainId"> & {
    Icon: any;
    name: string;
  }
> = ({
  positionId,
  tokens,
  feeTier,
  liquidity,
  fees,
  priceRange,
  tokenPercentages,
  autoCompound = undefined,
  inRange,
  rebalanced,
  recurringRebalanced,
  prescheduledPosition,
  limitOrder,
  closed,
  closedInfo,
  selected = false,
  atStake,
  sm = false,
  isCurrentChain,
  isManta,
  Icon,
  name,
}) => {
  const { hasCopied, handleCopyAddress } = useCopyText(positionId);
  const theme = useTheme();
  return (
    <Wrapper gap="lg">
      <PositionInfo sm={sm}>
        <Flex gap="lg" alignItems="center">
          <Flex gap="md" alignItems="end">
            <T3Regular>Position ID</T3Regular>
            <T1Regular>#{positionId}</T1Regular>
            <CopyWrapper onClick={handleCopyAddress}>
              {hasCopied ? (
                <StyledInRangeIcon width={16} height={16} />
              ) : (
                <CopyFileIcon
                  width={16}
                  height={16}
                  fill={theme.colors.global.text.T1}
                />
              )}
            </CopyWrapper>
          </Flex>
          <Status
            {...{
              rebalanced,
              recurringRebalanced,
              prescheduledPosition,
              limitOrder,
              position: { left: 0, bottom: -40 },
            }}
          />
        </Flex>
        <Flex gap={15} alignItems="center">
          <StyledRangeTag closed={Boolean(closed)} inRange={inRange} />
          {atStake && <StakeTag />}
          <ChainInfo
            gap="sm"
            alignItems="center"
            selected={selected}
            isManta={isManta}
          >
            {isCurrentChain && <CurrentChainDot />}
            <Icon width={16} height={16} />
            <T1Regular>{name}</T1Regular>
          </ChainInfo>
        </Flex>
      </PositionInfo>
      <PositionInfo sm={sm}>
        <PositionInfoTitle
          padding="0 8px"
          fontSize="12px"
          lineHeight="20px"
          size={20}
          {...{
            tokens,
            feeTier,
            autoCompound,
            tagVariant: "rise",
            wordOnly: true,
          }}
          forceLeft={true}
        />
        {closed ? (
          <Fees
            {...{
              liquidity: closedInfo?.exitedValueUsd ?? "-",
              fees: closedInfo?.exitedCollectedIncomeUsd ?? "-",
              closed: true,
            }}
          />
        ) : (
          <Fees {...{ liquidity, fees }} />
        )}
      </PositionInfo>
      {closed && closedInfo ? (
        <StyledSubtitle2>Closed at {closedInfo.dateTime}</StyledSubtitle2>
      ) : (
        <PositionInfo sm={sm}>
          <SwapRate {...{ sm, tokens, priceRange }} />
          <Flex gap="sm2" alignItems="center">
            {tokenPercentages.map((percentage, index) => (
              <Flex gap="sm2" alignItems="center" key={index}>
                <IconWrapper>{tokens[index].Icon}</IconWrapper>
                <StyledSubtitle2>{tokens[index].ticker}</StyledSubtitle2>
                <StyledPercentage>{percentage}%</StyledPercentage>
                {index === 0 && (
                  <StyledPercentage padding={"0 1px 1px"}>|</StyledPercentage>
                )}
              </Flex>
            ))}
          </Flex>
        </PositionInfo>
      )}
    </Wrapper>
  );
};

import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { BaseButton } from "../../Buttonb";
import { Spinner } from "../../Loader";
import { ButtonText } from "../../Typography";
import { ConnectWalletButtonProps } from "./types";

const Wrapper = styled.div`
  .selected {
    background: ${({ theme }) => theme.colors.global.primary};
    opacity: 0.8;
  }
`;
const StyledConnectWallet = styled(BaseButton)`
  height: 40px;
  padding: 0 4px;
  gap: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.grey.black};
  background: ${({ theme }) => theme.colors.global.primary};
  &:hover,
  &:active {
    background: ${({ theme }) => theme.colors.global.primary};
    opacity: 0.8;
  }

  path,
  rect {
    fill: ${({ theme }) => theme.colors.grey.white};
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.global.primaryDisable};
    opacity: 1;
  }
`;
const Pending = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
`;

const ConnectText = styled(ButtonText)`
  font-size: 14px;
  font-style: normal;
  text-transform: uppercase;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.global.textAlt.T1};
`;
export const ConnectWalletButton: React.FC<ConnectWalletButtonProps> = ({
  onClick,
  isPending = false,
  ...props
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    const modal = document.getElementById("connect-wallet-modal-show");
    if (modal && buttonRef.current) {
      buttonRef.current.classList.add("selected");
    } else if (buttonRef.current) {
      buttonRef.current.classList.remove("selected");
    }
  });

  return (
    <Wrapper>
      <StyledConnectWallet
        ref={buttonRef}
        {...props}
        onClick={onClick}
        disabled={isPending}
      >
        {isPending ? (
          <Pending>
            <Spinner />
            <ButtonText lineHeight={"32px"} height={"32px"}>
              Pending
            </ButtonText>
          </Pending>
        ) : (
          <ConnectText>Connect Wallet</ConnectText>
        )}
      </StyledConnectWallet>
    </Wrapper>
  );
};

import styled from "styled-components";
import { Button } from "../Button";
import { NoteCard } from "../NoteCard";
import { QuestionPopover } from "../Popover";
import { Tag } from "../Tag";
import { Body2, CommonLgText } from "../Typography";

export const Content = styled.div`
  padding: 24px;
  width: 369px;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.radii.xl};
  background: ${({ theme }) => theme.colors.global.background.BG3};
`;

export const StyledTag = styled(Tag)<{
  disabled: boolean;
}>`
  padding: 2px 8px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.global.primary};
  cursor: pointer;
  path,
  circle,
  line {
    fill: ${({ theme }) => theme.colors.primary.purple};
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  margin: 12px 0;
  line-height: 1;
  svg {
    vertical-align: top;
  }
`;

export const AutoCompoundToggleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const StyledQuestionPopover = styled(QuestionPopover)`
  position: static;
`;

export const Hint = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.global.warning};
  gap: ${({ theme }) => theme.spacing.md};
  padding-right: 16px;
  margin: 16px 0 20px 0;
  svg {
    flex-shrink: 0;
    margin-top: 1px;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 16px;
  width: 100%;
`;

export const ButtonText = styled(Body2)`
  line-height: 14px;
`;

export const TitleText = styled(CommonLgText)``;

export const UncollectFeeNoteCard = styled(NoteCard)`
  padding-top: 24px;
`;

export const InputNoteCard = styled(NoteCard)`
  padding-top: 8px;
  padding-bottom: 20px;
`;

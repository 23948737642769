import { Color } from "@ui/components/Button/types";
import styled from "styled-components";
import { Input } from "..";
import { BaseTab } from "../../Tabs";
import { Subtitle2 } from "../../Typography";

export const Container = styled.div<{ width?: number }>`
  position: relative;
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  border-radius: ${({ theme }) => theme.radii.round};
`;

export const StyledInput = styled(Input)<{
  height: number;
  error: boolean;
  color?: Color;
}>`
  width: 100%;
  height: ${({ height }) => `${height}px`};
  box-sizing: border-box;
  padding-left: 76px;
  background-color: ${({ theme, color }) => {
    if (color === "primary") {
      return theme.colors.global.background.BG3;
    } else {
      return theme.colors.global.background.BG2;
    }
  }};
  border: 1px solid
    ${({ error, theme }) =>
      error ? theme.colors.global.error : theme.colors.transparent.transparent};
  &:disabled {
    color: ${({ theme }) => theme.colors.global.text.T3};
  }
  text-overflow: ellipsis;
`;

export const SwitchWrapper = styled.div<{ height: number }>`
  position: absolute;
  top: 0;
  height: ${({ height }) => height}px;
`;

export const StyledSwitch = styled(BaseTab)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
  background: ${({ theme }) => theme.colors.transparent.transparent};
  :hover {
    color: ${({ theme }) => theme.colors.global.text.T1};
  }
`;

export const ErrorWrapper = styled.div`
  display: flex;
  margin: 6px 16px 0 16px;
  svg {
    path {
      stroke: ${({ theme }) => theme.colors.global.error};
    }
    stop {
      stop-color: ${({ theme }) => theme.colors.global.error};
    }
  }
`;

export const IconWrapper = styled.div`
  margin-top: -2px;
  height: 15px;
  width: 15px;
`;

export const ErrorText = styled(Subtitle2)`
  margin-left: 4px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.global.error};
`;

export const Conversion = styled(Subtitle2)`
  text-align: right;
  margin: 12px 16px 0 16px;
  color: ${({ theme }) => theme.colors.global.text.T2};
  input:focus + div + & {
    color: ${({ theme }) => theme.colors.grey.darkGrey2};
  }
  input:disabled + div + & {
    color: ${({ theme }) => theme.colors.grey.grey};
  }
`;

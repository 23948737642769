import { CompletedIcon, InfoIcon } from "@aperture/assetkit";
import { Flex, FlexColumn } from "@ui/common";
import { Body1, Body2, Subtitle0, Subtitle2 } from "@ui/components/Typography";
import {
  formatTokenAmount,
  getDuration,
  MIN_FIXED_VALUE,
  timestampFormat,
} from "@ui/utils";
import bigDecimal from "js-big-decimal";
import styled from "styled-components";
import { Button } from "../Button";
import { Spinner } from "../Loader";
import { InfoPopover } from "../Popover";
import { StakeCardProps, StakeToken } from "./types";

const ContentWrapper = styled(FlexColumn)`
  width: 500px;
  padding: 16px;
  gap: ${({ theme }) => theme.spacing.lg};
  border-radius: ${({ theme }) => theme.radii.xl};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
`;
const InfoWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
`;
const InfoTitle = styled(Body1)`
  line-height: 16px;
  font-family: "Roboto Flex";
  color: ${({ theme }) => theme.colors.global.text.T2};
`;
const InfoValueWrapper = styled(Flex)`
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
`;
const InfoValue = styled(Subtitle0)<{ highlight?: boolean }>`
  font-size: 20px;
  font-family: "Roboto Flex";
  color: ${({ theme, highlight }) =>
    highlight ? theme.colors.global.success : theme.colors.global.text.T1};
`;
const InfoUnit = styled(Body2)`
  line-height: 14px;
  font-family: "Roboto Flex";
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const InfoTimestamp = styled(Body1)`
  line-height: 16px;
  font-family: "Roboto Flex";
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const InfoIconWrapper = styled.div`
  height: 14px;
  width: 14px;
  svg {
    fill: ${({ theme }) => theme.colors.grey.mediumGrey};
  }
`;
const InfoText = styled(Body2)`
  line-height: 14px;
  font-family: "Roboto Flex";
  color: ${({ theme }) => theme.colors.global.text.T2};
`;
const ClaimedText = styled(Subtitle2)`
  font-family: "Roboto Flex";
  line-height: 16px;
  color: ${({ theme }) => theme.colors.global.success};
`;
const StyledButton = styled(Button)`
  padding: 8px 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.global.text.T1};
  border: 1px solid ${({ theme }) => theme.colors.global.text.T1};
  transition: all ${({ theme }) => theme.transitions.fast} ease-in-out !important;
  &:hover {
    background: ${({ theme }) => theme.colors.global.text.T1};
    border: 1px solid ${({ theme }) => theme.colors.global.text.T1};
    color: ${({ theme }) => `${theme.colors.global.background.BG1}`};
    transition-duration: ${({ theme }) => theme.transitions.default} !important;
  }
  :disabled {
    background: ${({ theme }) =>
      `${theme.colors.global.line.Line1} !important`};
    color: ${({ theme }) => `${theme.colors.global.text.T3} !important`};
    border: ${({ theme }) =>
      `1.5px solid ${theme.colors.global.line.Line1} !important`};
  }
  &:active {
    background: ${({ theme }) => `${theme.colors.global.text.T1}`};
    color: ${({ theme }) => `${theme.colors.global.background.BG1}`};
  }
`;

export const StakeCard: React.FC<StakeCardProps> = ({
  positionId,
  token,
  APR,
  stakeReward,
  lastStakedTime,
  stakePeriod,
  gracePeriod,
  isStakePaused,
  isClaimingRewards,
  isRewardsClaimed,
  isRedeemingAll,
  onClaim,
  onRedeemAll,
}) => {
  const timestamp = bigDecimal.divide(Date.now(), 1000, 0);
  const lastStakedTimeFormat = timestampFormat(
    Number(lastStakedTime),
    true,
    false
  );
  const durationToRedeem = getDuration(
    Number(timestamp),
    lastStakedTime + stakePeriod,
    true
  );
  const redeemTimeFormat = timestampFormat(
    Number(
      bigDecimal.add(bigDecimal.add(lastStakedTime, stakePeriod), gracePeriod)
    ),
    true,
    false
  );

  const getStakeTokenCard = (token: StakeToken, isReward: boolean) => (
    <>
      <Flex gap="sm">
        <token.Icon width={20} height={20} />
        <InfoValue>
          {isReward && isRewardsClaimed
            ? "0"
            : bigDecimal.compareTo(token.amount, MIN_FIXED_VALUE) >= 0 ||
              bigDecimal.compareTo(token.amount, 0) === 0
            ? formatTokenAmount(token.amount)
            : `<${MIN_FIXED_VALUE}`}
        </InfoValue>
      </Flex>
      <InfoUnit>{token.ticker}</InfoUnit>
    </>
  );

  return (
    bigDecimal.compareTo(timestamp, lastStakedTime) > 0 && (
      <ContentWrapper>
        <InfoWrapper>
          <InfoTitle>Stake Token</InfoTitle>
          <InfoValueWrapper>{getStakeTokenCard(token, false)}</InfoValueWrapper>
        </InfoWrapper>
        <InfoWrapper>
          <InfoTitle>Last Staked Time</InfoTitle>
          <InfoValueWrapper>
            <InfoTimestamp>{`${lastStakedTimeFormat.time}, ${lastStakedTimeFormat.date}`}</InfoTimestamp>
          </InfoValueWrapper>
        </InfoWrapper>
        <InfoWrapper>
          <Flex gap="sm">
            <InfoTitle>Staking APR</InfoTitle>
            <InfoPopover
              content="USDC APR is calculated based on the current price of APTR token."
              iconSize={14}
            />
          </Flex>
          <Flex gap="lg">
            <InfoValueWrapper>
              <InfoValue highlight>{`${APR[0].percent}%`}</InfoValue>
              <InfoUnit>{APR[0].unit}</InfoUnit>
            </InfoValueWrapper>
            <InfoValueWrapper>
              <InfoValue highlight>{`${APR[1].percent}%`}</InfoValue>
              <InfoUnit>{APR[1].unit}</InfoUnit>
            </InfoValueWrapper>
          </Flex>
        </InfoWrapper>
        <FlexColumn gap="md">
          <InfoWrapper>
            <InfoTitle>Unclaimed Staking Rewards</InfoTitle>
            <Flex gap="lg">
              <InfoValueWrapper>
                {getStakeTokenCard(stakeReward[0], true)}
              </InfoValueWrapper>
              <InfoValueWrapper>
                {getStakeTokenCard(stakeReward[1], true)}
              </InfoValueWrapper>
            </Flex>
          </InfoWrapper>
          {!isStakePaused && (
            <Flex justifyContent="flex-end">
              <StyledButton
                color="secondary"
                size="sm"
                width="100px"
                variant="outlined"
                disabled={
                  isRedeemingAll ||
                  isClaimingRewards ||
                  isRewardsClaimed ||
                  (bigDecimal.compareTo(stakeReward[0].amount, 0) === 0 &&
                    bigDecimal.compareTo(stakeReward[1].amount, 0) === 0)
                }
                onClick={() => onClaim()}
              >
                {isRewardsClaimed ? (
                  <Flex gap="sm">
                    <CompletedIcon width={16} height={16} />
                    <ClaimedText>Claimed</ClaimedText>
                  </Flex>
                ) : isClaimingRewards ? (
                  <Spinner />
                ) : (
                  "Claim"
                )}
              </StyledButton>
            </Flex>
          )}
        </FlexColumn>
        <FlexColumn gap="md">
          {!isStakePaused && (
            <InfoWrapper>
              <InfoTitle>Redeem</InfoTitle>
              <InfoValueWrapper>
                {bigDecimal.compareTo(
                  timestamp,
                  bigDecimal.add(lastStakedTime, stakePeriod)
                ) < 0 ? (
                  <>
                    <InfoValue>{durationToRedeem.split(" ")[0]}</InfoValue>
                    <InfoUnit>
                      {durationToRedeem.split(" ")[1]} Left Until Redeem
                    </InfoUnit>
                  </>
                ) : (
                  <InfoTimestamp>
                    {`Redeem all tokens before ${redeemTimeFormat.time}, ${redeemTimeFormat.date}`}
                  </InfoTimestamp>
                )}
              </InfoValueWrapper>
            </InfoWrapper>
          )}
          {(bigDecimal.compareTo(
            timestamp,
            bigDecimal.add(lastStakedTime, stakePeriod)
          ) > 0 ||
            isStakePaused) && (
            <Flex gap="lg" width="500px" justifyContent="flex-end">
              {!isStakePaused && (
                <Flex gap="sm">
                  <InfoIconWrapper>
                    <InfoIcon width={14} height={14} />
                  </InfoIconWrapper>
                  <InfoText>
                    You will have {gracePeriod / 86400} days to redeem your
                    tokens and staking rewards when staking ends. After{" "}
                    {gracePeriod / 86400} days, your APTR tokens will be
                    automatically restaked.
                  </InfoText>
                </Flex>
              )}
              <Flex>
                <StyledButton
                  color="secondary"
                  size="sm"
                  width="122px"
                  variant="outlined"
                  disabled={isRedeemingAll || isClaimingRewards}
                  onClick={() => onRedeemAll()}
                >
                  {isRedeemingAll ? <Spinner /> : "Redeem All"}
                </StyledButton>
              </Flex>
            </Flex>
          )}
        </FlexColumn>
      </ContentWrapper>
    )
  );
};

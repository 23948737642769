import { useEstimateGas, useSendTransaction } from "wagmi";

export const useSendTransactionWithGas = (tx: any) => {
  const { data } = useEstimateGas(tx);
  const { sendTransaction, sendTransactionAsync, ...res } =
    useSendTransaction();

  const _sendTransaction = data
    ? () =>
        sendTransaction({
          gas: data,
          ...tx,
        })
    : undefined;

  const _sendTransactionAsync = data
    ? () =>
        sendTransactionAsync({
          gas: data,
          ...tx,
        })
    : undefined;

  return {
    sendTransaction: _sendTransaction,
    sendTransactionAsync: _sendTransactionAsync,
    ...res,
    ready: Boolean(data),
  };
};

import { IToken } from "@aperture/types";
import { ReactNode } from "react";
import {
  AmmEnum,
  BasicInfo,
  ConnectionType,
  SupportedChainId,
} from "../../utils";
import { IPriceRangeWithTick } from "../LiquidityPosition";
import { APR, StakeToken } from "../StakeCard";
import { StrategyInfoCardProps } from "../StrategyInfoCard";
import { SwapPathProps } from "../SwapPath";
import { ITokenInfoProps } from "../TokenInfo";
import { Projection } from "../TriggerList/types";

export interface ModalProps {
  disableBackgroundOnClose?: boolean;
  closeIcon?: boolean;
  padding?: number;
  onClose: () => void;
}

export interface ClaimFeeToken {
  Icon: ReactNode;
  ticker: string;
  amount: string;
  address: string;
}

export interface ClaimFeeProps {
  total: string;
  tokenInfo: ClaimFeeToken[];
}

export interface ConfirmationProps {
  text: string;
}

export interface ConnectModalProps {
  defaultAMM: BasicInfo;
  defaultChain: SupportedChainId;
  currentConnectType: ConnectionType;
  chainSelect: (amm: BasicInfo, chain: SupportedChainId) => void;
  walletSelect: (chain: SupportedChainId, walletId: ConnectionType) => void;
}

export interface PreviewLPProps {
  amm: AmmEnum;
  baseToken: IToken;
  tokenInfo: [ITokenInfoProps, ITokenInfoProps];
  zapTokenInfo?: ITokenInfoProps | [ITokenInfoProps, ITokenInfoProps];
  tokenPrice: [string, string];
  total: string | number;
  feeTier: number;
  poolKey: number;
  priceRange: IPriceRangeWithTick;
  slippage: number;
  amounts?: string[];
  estimatedGas?: string;
  apertureFee?: number;
  swapInfo?: SwapPathProps;
}

export interface StepsProps {
  amm: AmmEnum;
  step: number;
  approveOnChain: boolean;
}

export interface WrongNetworkProps {
  networkName: SupportedChainId;
  content?: ReactNode;
  isSwitchChain?: Boolean;
  isUnsupported?: boolean;
}

export type IReinvestFeesContentProps = {
  amm: AmmEnum;
  liquidity: string;
  feeAmount: string;
  tokens: [ITokenInfoProps, ITokenInfoProps];
  unClaimedFees: [string, string];
  predictedLiquidity: string;
  predictedProjection: Projection;
  fee: number;
};

export type ITokenSelectContentProps = {
  commonTokens: IToken[];
  selectedToken: IToken | null;
  selectToken: (token: IToken) => void;
  selectUnknownToken: (token: IToken) => void;
  handleUnknownAddress: (address: string) => Promise<IToken>;
  customClose?: boolean;
  onClose?: () => void;
};

export type ITokenWarningContentProps = {
  amm: AmmEnum;
  token: IToken;
  exploreURL: string;
  confirmClick: (token: IToken) => void;
  cancelClick: () => void;
  customClose?: boolean;
  onClose?: () => void;
};

export type IDeleteStrategyProps = Omit<
  StrategyInfoCardProps,
  "notification" | "rebalancesExecuted"
>;

type OrderRoute = SwapPathProps & {
  selected?: boolean;
  onClick: () => void;
};

export type OrderRoutingProps = {
  swapInfo: OrderRoute[];
};

export type IpRestrictProps = {
  list: string[];
  handleClose: () => void;
};

export type ClaimAirdropProps = {
  amount: number;
  unit: string;
};

export type StakePeriod = {
  days: number;
  APR: APR[];
  selected?: boolean;
  onClick: () => void;
};

export type StakePeriodProps = {
  options: StakePeriod[];
};

export type StakeFinishProps = {
  token: StakeToken;
  bonus: number;
  bonusLink: string;
};

export type StakeRedeemProps = {
  tokens: StakeToken[];
};

export interface PreviewStakeProps {
  token: StakeToken;
  days: number;
  APR: APR[];
  share: string;
}

export enum E_StrategyModalType {
  Discard_Trigger_Edit = "Discard Trigger Edit",
  Delete_Trigger = "Delete Trigger",
  Delete_Last_Trigger = "Delete Last Trigger",
  Delete_Strategy = "Delete Startegy",
  Cancel_Strategy_Edit = "Cancel Strategy Edit",
}

export interface DualTriggerProps {
  type: E_StrategyModalType;
  onCancel?: () => void;
  onConfirm?: () => void;
}

import {
  NumberValue,
  ScaleLinear,
  axisBottom,
  Axis as d3Axis,
  select,
} from "d3";
import { useMemo } from "react";
import styled from "styled-components";

const StyledLine = styled.line`
  strokeWidth: 1;
  stroke: ${({ theme }) => theme.colors.global.line.Line2};
  fill: none;
`;

const StyledGroup = styled.g`
  line {
    display: none;
  }

  text {
    color: ${({ theme }) => theme.colors.global.text.T3};
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    transform: translateY(5px);
  }
`;

const Axis = ({ axisGenerator }: { axisGenerator: d3Axis<NumberValue> }) => {
  const axisRef = (axis: SVGGElement) => {
    axis &&
      select(axis)
        .call(axisGenerator)
        .call((g) => g.select(".domain").remove());
  };

  return <g ref={axisRef} />;
};

export const AxisBottom = ({
  xScale,
  innerHeight,
  offset = -5,
  suffix = "",
}: {
  xScale: ScaleLinear<number, number>;
  innerHeight: number;
  offset?: number;
  suffix?: string;
}) =>
  useMemo(
    () => (
      <>
        <StyledGroup transform={`translate(0, ${innerHeight + offset})`}>
          <Axis
            axisGenerator={axisBottom(xScale)
              .ticks(5)
              .tickFormat((d) => d + suffix)}
          />
        </StyledGroup>
        <StyledLine x1="0" y1={innerHeight} x2="400" y2={innerHeight} />
      </>
    ),
    [innerHeight, offset, xScale, suffix]
  );

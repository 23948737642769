import { CaretIcon, InRangeIcon, MoreIcon } from "@aperture/assetkit";
import { DrawerOpenCardWidth, Flex, FlexColumn } from "@uiv2/common";
import { CSSProperties } from "react";
import styled from "styled-components";
import { Button } from "../Button";
import { RangeTag } from "../Tag";
import { T2, T3Medium, T3Regular, T4 } from "../Typography";

export const Container = styled.div<{ selected: Boolean; sm?: boolean }>`
  width: ${({ sm }) => (sm ? DrawerOpenCardWidth : "100%")};
  padding: 16px;
  display: flex;
  box-sizing: border-box;
  gap: ${({ theme }) => theme.spacing.xl};
  align-items: center;
  border-radius: ${({ theme }) => theme.radii.xl};
  background-color: ${({ theme, selected }) =>
    selected
      ? theme.colors.global.background.BG2
      : theme.colors.global.background.BG1};
  border: 1.5px solid
    ${({ theme, selected }) =>
      selected
        ? theme.colors.transparent.transparent
        : theme.colors.global.line.Line1};
  transition: all ${({ theme }) => theme.transitions.default} ease;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.global.background.BG2};
  }
`;
export const PositionInfo = styled.div<{ sm: boolean }>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: ${({ sm }) => (sm ? "column" : "row")};
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.lg};
`;
export const MinMaxWrapper = styled.div<{ sm: boolean }>`
  display: flex;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing.lg};
  flex-direction: ${({ sm }) => (sm ? "column" : "row")};
`;
export const SwapButtonWrapper = styled.div<{ color?: CSSProperties["color"] }>`
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  svg {
    fill: ${({ theme, color }) => color ?? theme.colors.global.primary};
  }
`;
export const ChainInfo = styled(Flex)`
  transition: all ${({ theme }) => theme.transitions.default} ease;
`;
export const CurrentChainDot = styled.div<{ size?: number }>`
  width: ${({ size }) => `${size ?? 6}px`};
  height: ${({ size }) => `${size ?? 6}px`};
  border-radius: ${({ theme }) => theme.radii.circle};
  background-color: ${({ theme }) => theme.colors.global.success};
`;
export const Wrapper = styled(FlexColumn)`
  width: 100%;
`;
export const IconWrapper = styled.div`
  height: 16px;
  width: 16px;
`;
export const StyledPercentage = styled(T3Medium)`
  color: ${({ theme }) => theme.colors.global.text.T2};
`;
export const StyledSubtitle2 = styled(T3Medium)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const StyledRangeTag = styled(RangeTag)`
  height: fit-content;
`;
export const CopyWrapper = styled.div`
  cursor: pointer;
  svg {
    display: block;
  }
`;
export const StyledInRangeIcon = styled(InRangeIcon)`
  fill: ${({ theme }) => theme.colors.global.text.T2};
`;
export const T3 = styled(T3Regular)`
  color: ${({ theme }) => theme.colors.global.text.T3};
`;
export const T1 = styled(T3Regular)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const StyledT4 = styled(T4)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const APRText = styled(T3Regular)<{ isPositive: boolean }>`
  color: ${({ isPositive, theme }) =>
    isPositive ? theme.colors.global.success : theme.colors.global.error};
`;
export const APRTextLg = styled(T2)<{ isPositive: boolean }>`
  color: ${({ isPositive, theme }) =>
    isPositive ? theme.colors.global.success : theme.colors.global.error};
`;
export const AnalyticsInfo = styled(Flex)<{ sm?: boolean }>`
  flex-direction: ${({ sm }) => (sm ? "column" : "row")};
  align-items: ${({ sm }) => (sm ? "flex-start" : "center")};
  justify-content: space-between;
  row-gap: ${({ theme }) => theme.spacing.lg};
`;
export const APRContainer = styled(Flex)<{ sm?: boolean }>`
  width: ${({ sm }) => sm && "100%"};
  justify-content: ${({ sm }) => sm && "space-between"};
  gap: ${({ theme }) => theme.spacing.md};
`;
export const APR = styled(Flex)<{
  sm?: boolean;
  ta?: CSSProperties["textAlign"];
}>`
  flex-direction: ${({ sm }) => (sm ? "column" : "row")};
  gap: ${({ theme }) => theme.spacing.md};
  text-align: ${({ ta }) => ta};
`;
export const Dot = styled.div<{ isActive?: boolean }>`
  height: 8px;
  width: 8px;
  border-radius: ${({ theme }) => theme.radii.circle};
  background-color: ${({ theme, isActive }) =>
    isActive
      ? theme.colors.global.success
      : theme.colors.global.background.BG4};
`;
export const ClosedPosID = styled(Flex)`
  border-radius: ${({ theme }) => theme.radii.round};
  gap: ${({ theme }) => theme.spacing.sm};
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  background-color: ${({ theme }) => theme.colors.global.background.BG2};
`;
export const ClosedPosContainer = styled(Flex)<{ sm?: boolean }>`
  width: ${({ sm }) => sm && "100%"};
`;
export const MoreIconWrapper = styled(Flex)`
  width: 16px;
  height: 22px;
  align-items: center;
`;
export const StyledMoreIcon = styled(MoreIcon)`
  transform: rotate(90deg);
  fill: ${({ theme }) => theme.colors.global.text.T1};
`;
export const Value = styled(T2)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const Label = styled(T2)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.global.text.T3};
`;
export const Link = styled.div<{ underline?: boolean }>`
  cursor: ${({ underline }) => (underline ? "pointer" : "auto")};
  text-decoration: ${({ underline }) => underline && "underline"};
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const Text = styled(T2)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const Timestamp = styled(T2)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.global.warning};
`;
export const CardContainer = styled(FlexColumn)`
  padding: 16px;
  border-radius: ${({ theme }) => theme.radii.md2};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line2};
`;
export const Section = styled(FlexColumn)`
  gap: ${({ theme }) => theme.spacing.lg};
`;
export const Row = styled(Flex)`
  justify-content: space-between;
  align-items: center;
`;
export const Line = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.global.line.Line2};
`;

export const ExplorerContainer = styled(Flex)<{
  sm?: boolean;
}>`
  width: ${({ sm }) => (sm ? DrawerOpenCardWidth : "100%")};
  padding: 16px;
  box-sizing: border-box;
  gap: ${({ theme }) => theme.spacing.xl};
  align-items: center;
  border-radius: ${({ theme }) => theme.radii.xl};
  background-color: ${({ theme }) => theme.colors.global.background.BG1};
  border: 1.5px solid ${({ theme }) => theme.colors.global.line.Line1};
  transition: all ${({ theme }) => theme.transitions.default} ease;
  cursor: pointer;
`;

export const Tab = styled.div<{ selected?: boolean }>`
  padding: 6px 10px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
  background-color: ${({ theme, selected }) =>
    selected && theme.colors.global.background.BG4};
  :hover {
    background-color: ${({ theme }) => theme.colors.global.background.BG4};
  }
`;
export const TabText = styled(T3Regular)`
  line-height: 18px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const ArrowBtn = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ArrowDown = styled(CaretIcon)<{ isShow: boolean }>`
  width: 24px;
  height: 24px;
  transition: transform ${({ theme }) => theme.transitions.default};
  transform: ${({ isShow }) => (isShow ? "rotate(180deg)" : "")};
  path {
    fill: ${({ theme }) => theme.colors.global.text.T1};
  }
`;

export const Content = styled(FlexColumn)<{
  isShow: boolean;
  useOverflow: boolean;
  h: number;
}>`
  gap: ${({ theme }) => theme.spacing.lg};
  overflow: ${({ useOverflow }) => (useOverflow ? "initial" : "hidden")};
  padding-top: ${({ isShow }) => (isShow ? "12px" : "0")};
  height: ${({ isShow, h }) => (isShow ? `${h}px` : "0")};
  transition: height ${({ theme }) => theme.transitions.default},
    padding-top ${({ theme }) => theme.transitions.default};
`;
export const StyledButton = styled(Button)`
  padding-block: 16px;
  border-radius: ${({ theme }) => theme.radii.sm};
`;

import { CopiedIcon, CrossCircleIcon, WarningIcon } from "@aperture/assetkit";
import { Flex, FlexColumn } from "@ui/common";
import styled from "styled-components";
import { variant } from "styled-system";
import { Subtitle1 } from "../Typography";
import { styleVariants } from "./theme";
import { INotificationV2Props, IType, notificationVariants } from "./types";

const iconMap = {
  [notificationVariants.DEFAULT]: CopiedIcon,
  [notificationVariants.WARNING]: CrossCircleIcon,
  [notificationVariants.ERROR]: WarningIcon,
  [notificationVariants.SUCCESS]: CopiedIcon,
};

export const NotificationV2: React.FC<INotificationV2Props> = ({
  title = "Warning",
  variant = notificationVariants.DEFAULT,
  text = "",
  showIcon = true,
  renderButton,
  icon,
  ...props
}) => {
  const Icon = icon ?? iconMap[variant];

  return (
    <>
      <NotificationContainer variant={variant} {...props}>
        <Flex alignItems="center" gap="sm">
          {variant !== notificationVariants.DEFAULT && showIcon && (
            <IconWrapper variant={variant}>
              <Icon height={18} width={18} />
            </IconWrapper>
          )}
          <Title>{title}</Title>
        </Flex>
        <Flex alignItems="center">
          <Content>{text}</Content>
          {renderButton?.()}
        </Flex>
      </NotificationContainer>
    </>
  );
};

const NotificationContainer = styled(FlexColumn)<IType>`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: 8px;
  border: 1px solid;
  border-radius: ${({ theme }) => theme.radii.md};
  gap: ${({ theme }) => theme.spacing.sm};
  ${variant({ variants: styleVariants })}
`;

const IconWrapper = styled.div<IType>`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 22px;
  svg {
    path {
      ${variant({ variants: styleVariants })}
    }
    stop {
      ${variant({ variants: styleVariants })}
    }
  }
`;

const Title = styled(Subtitle1)``;

const Content = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  word-break: break-word;
`;

import { useNetwork } from "@/components/NetworkContext/NetworkContext";
import {
  ApertureSupportedChainId,
  ICommonNonfungiblePositionManager__factory,
  getChainInfo,
} from "@aperture_finance/uniswap-v3-automation-sdk";
import { AmmEnum } from "@ui/utils";
import useSWR from "swr/immutable";
import { Address, PublicClient } from "viem";
import { useAccount } from "wagmi";

export function useFetchUserAuthentication() {
  const { address: walletAddress, isConnected } = useAccount();
  const { ammEnum, networkId, publicClient, isChainSupported } = useNetwork();
  const showFetch =
    isConnected &&
    walletAddress &&
    ammEnum &&
    isChainSupported &&
    networkId === publicClient.chain?.id;

  return useSWR(
    showFetch
      ? `user-token-lp-authentication-${ammEnum}-${networkId}-${walletAddress}`
      : null,
    () =>
      checkApproval(
        ammEnum,
        networkId,
        publicClient,
        walletAddress!,
        getChainInfo(networkId).amms[ammEnum].apertureAutoman
      )
  );
}

export const useIfAuthorized = () => {
  const { data: ifAuthorized } = useFetchUserAuthentication();
  return { ifAuthorized };
};

async function checkApproval(
  ammEnum: AmmEnum,
  chainId: ApertureSupportedChainId,
  client: PublicClient,
  walletAddress: Address,
  operatorAddress: Address
) {
  return client.readContract({
    address: getChainInfo(chainId).amms[ammEnum].nonfungiblePositionManager,
    abi: ICommonNonfungiblePositionManager__factory.abi,
    functionName: "isApprovedForAll",
    args: [walletAddress, operatorAddress],
  });
}

import { Flex } from "@uiv2/common";
import { InputGroupPlusMinus } from "@uiv2/components/Input";
import { Tab } from "@uiv2/components/Tab";
import { H2, T3Regular } from "@uiv2/components/Typography";
import { useEffect, useState } from "react";
import { Slider } from "../Slider";
import {
  Container,
  HeaderContainer,
  InputContainer,
  MinMaxContainer,
} from "./style";
import { PriceRangeProps } from "./types";

export const PurePriceRange: React.FC<PriceRangeProps> = ({
  min,
  max,
  isFullRange,
  token0Ticker,
  token1Ticker,
  minValue,
  maxValue,
  assumptionValue,
  onFullRangeToggle,
  updateMinValue,
  updateMaxValue,
  updateAssumptionValue,
}) => {
  const plusMinus = ((max - min) * 2) / 100; // 2%

  const [priceRangeSlider, setPriceRangeSlider] = useState([
    (100 * (minValue - min)) / (max - min),
    (100 * (maxValue - min)) / (max - min),
  ]);
  const [assumptionSlider, setAssumptionSlider] = useState(
    (100 * (assumptionValue - min)) / (max - min)
  );

  const handleMinMaxChange = (newMinValue: number, newMaxValue: number) => {
    updateMinValue(newMinValue);
    updateMaxValue(newMaxValue);
    setPriceRangeSlider([
      (100 * (newMinValue - min)) / (max - min),
      (100 * (newMaxValue - min)) / (max - min),
    ]);
  };

  const handleAssumptionChange = (newAssumption: number) => {
    updateAssumptionValue(newAssumption);
    // percent = 100 * (price - min) / (max - min)
    setAssumptionSlider((100 * (newAssumption - min)) / (max - min));
  };

  useEffect(() => {
    setAssumptionSlider((100 * (assumptionValue - min)) / (max - min));
  }, [assumptionSlider, assumptionValue, max, min]);

  useEffect(() => {
    setPriceRangeSlider([
      (100 * (minValue - min)) / (max - min),
      (100 * (maxValue - min)) / (max - min),
    ]);
  }, [minValue, maxValue, min, max]);

  const subtitle = `${token0Ticker} per ${token1Ticker}`;

  return (
    <Container>
      <HeaderContainer>
        <H2>Price Range</H2>
        <Flex gap="md" alignItems="center">
          <Tab
            type="switch"
            selectedIdx={isFullRange ? 1 : 0}
            onSelect={(value: number) => onFullRangeToggle(value === 1)}
          />
          <T3Regular>Full Range</T3Regular>
        </Flex>
      </HeaderContainer>
      <InputContainer>
        <Flex gap={12}>
          <MinMaxContainer>
            <InputGroupPlusMinus
              sm={true}
              disabled={isFullRange}
              value={isFullRange ? "0" : minValue.toString()}
              title="Min"
              subTitle={subtitle}
              allowNegative={false}
              onChange={(value: string) =>
                handleMinMaxChange(Number(value), maxValue)
              }
              plusClick={() =>
                handleMinMaxChange(minValue + plusMinus, maxValue)
              }
              minusClick={() =>
                handleMinMaxChange(
                  minValue - plusMinus > 0 ? minValue - plusMinus : 0,
                  maxValue
                )
              }
            />
          </MinMaxContainer>
          <MinMaxContainer>
            <InputGroupPlusMinus
              sm={true}
              disabled={isFullRange}
              value={isFullRange ? "∞" : maxValue.toString()}
              title="Max"
              subTitle={subtitle}
              allowNegative={false}
              onChange={(value: string) =>
                handleMinMaxChange(minValue, Number(value))
              }
              plusClick={() =>
                handleMinMaxChange(minValue, maxValue + plusMinus)
              }
              minusClick={() =>
                handleMinMaxChange(
                  minValue,
                  maxValue - plusMinus > 0 ? maxValue - plusMinus : 0
                )
              }
            />
          </MinMaxContainer>
        </Flex>
        {!isFullRange && (
          <Slider
            min={0}
            max={100}
            value={priceRangeSlider}
            onChange={(value, _) => {
              setPriceRangeSlider(value);
              handleMinMaxChange(
                min + ((max - min) * value[0]) / 100,
                min + ((max - min) * value[1]) / 100
              );
            }}
          />
        )}
      </InputContainer>
      <InputContainer>
        <InputGroupPlusMinus
          sm={true}
          value={assumptionValue.toString()}
          title="Most Active Price Assumption"
          subTitle={subtitle}
          allowNegative={false}
          onChange={(value: string) => handleAssumptionChange(Number(value))}
          plusClick={() => handleAssumptionChange(assumptionValue + plusMinus)}
          minusClick={() =>
            handleAssumptionChange(
              assumptionValue - plusMinus > 0 ? assumptionValue - plusMinus : 0
            )
          }
        />
        <Slider
          min={0}
          max={100}
          value={assumptionSlider}
          onChange={(value, _) =>
            handleAssumptionChange(min + ((max - min) * value) / 100)
          }
        />
      </InputContainer>
    </Container>
  );
};

import { CaretIcon, CrossIcon } from "@aperture/assetkit";
import { Flex } from "@ui/common";
import styled from "styled-components";
import { SpaceProps, space } from "styled-system";
import { BaseButton } from "../../Buttonb";
import { ButtonText } from "../../Typography";

export const StyledBox = styled(BaseButton)`
  background: ${({ theme }) => theme.colors.global.background.BG2};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
  padding: 6px 14px 6px 10px;
  height: 40px;
  > svg {
    display: flex;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.global.background.BG2};
    opacity: 0.8;
  }
`;

export const ChevronDown = styled(CaretIcon)`
  width: 24px;
  height: 24px;
  fill: ${({ theme }) => theme.colors.global.text.T1};

  > path {
    fill: ${({ theme }) => theme.colors.global.text.T1};
  }
`;

export const ContentContainerBox = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
`;

export const ContentContainer = styled.div`
  box-sizing: border-box;
  min-width: 420px;
  padding: 24px;
  background: ${({ theme }) => theme.colors.global.background.BG2};
  position: absolute;
  border-radius: 15px;
  top: 118px;
  left: 50%;
  transform: translateX(-50%);
`;

export const Title = styled.div`
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
`;

export const TitleText = styled.div`
  color: ${({ theme }) => theme.colors.global.text.T1};
  margin-bottom: 8px;
  font-family: "Dunbar Tall";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  text-transform: capitalize;
`;

export const TitleClose = styled(CrossIcon)`
  cursor: pointer;
  fill: ${({ theme }) => theme.colors.global.text.T1} !important;
`;

export const ChainItem = styled.div<{ selected: boolean }>`
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.global.text.T1};
  font-size: 16px;
  font-weight: 500;
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
  align-items: center;
  padding: 12px;
  margin: 8px 0;
  width: 372px;
  height: 44px;
  border-radius: ${({ theme }) => theme.radii.md};
  cursor: pointer;
  background: ${({ theme, selected }) =>
    selected ? theme.colors.global.background.BG3 : "none"};
  :hover {
    background: ${({ theme }) => theme.colors.global.background.BG3};
  }
`;

export const SelectedMark = styled.div<{ selected: boolean }>`
  height: 8px;
  width: 8px;
  background: ${({ theme, selected }) =>
    selected ? theme.colors.global.success : "none"};
  border-radius: ${({ theme }) => theme.radii.circle};
`;

export const NetworkContainer = styled.div<SpaceProps>`
  box-sizing: border-box;
  display: flex;
  padding: 0px 8px;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  border: 1px solid ${({ theme }) => theme.colors.grey.lightGrey3};
  background: ${({ theme }) => theme.colors.transparent.white1};
  border-radius: ${({ theme }) => theme.radii.round};
  color: ${({ theme }) => theme.colors.grey.darkGrey2};
  ${space}
`;

export const ButtonTextBox = styled(ButtonText)<{
  px?: number;
}>`
  color: ${({ theme }) => theme.colors.global.text.T1};
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding-inline: 0;
`;

export const AmmBox = styled(Flex)<{ selected: boolean }>`
  cursor: pointer;
  width: fit-content;
  padding: ${({ theme }) => `${theme.spacing.md} ${theme.spacing.lg}`};
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.colors.global.text.T1 : theme.colors.global.line.Line2};
  border-radius: ${({ theme }) => theme.radii.round};
`;

export const AmmContainer = styled(Flex)`
  margin-block: 20px;
  max-width: 305px;
  flex-wrap: wrap;
`;

export const IconWithAmm = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
`;

import _ from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { Dropdown, IDropdownRef } from "../Dropdown";
import { SearchInput } from "../Input";
import {
  ArrowDown,
  ArrowDownWrapper,
  ButtonContainer,
  ContentContainer,
  OptionList,
  Title,
} from "./style";
import { ISelectorProps } from "./types";

export const DropdownSelector = <T,>({
  options,
  selectedOption = undefined,
  title = undefined,
  onSelect,
  disabled,
  buttonComponent,
  optionComponent: Option,
  keyExtractor,
  searchOptions,
  searchPlaceholder,
  inModal = false,
  ...props
}: ISelectorProps<T>) => {
  const searchEnabled = searchOptions !== undefined;
  const [inputValue, setInputValue] = useState("");
  const dropdownRef = useRef<IDropdownRef>(null);
  const handleSelect = (option: T) => {
    onSelect?.(option);
    dropdownRef?.current?.onClose();
  };

  const list = useMemo(() => {
    const trimmedValue = inputValue.toLowerCase().trim();
    const filteredOptions =
      trimmedValue && searchEnabled ? searchOptions(trimmedValue) : options;

    return filteredOptions;
  }, [inputValue, options]);

  const dropdownBtn = (
    <ButtonContainer {...props} inModal={inModal}>
      {buttonComponent}
      <ArrowDownWrapper>
        <ArrowDown />
      </ArrowDownWrapper>
    </ButtonContainer>
  );

  return (
    <Dropdown
      ref={dropdownRef}
      button={dropdownBtn}
      position="bottomRight"
      containerStyles={props.dropdownContainerStyles}
      disabled={disabled}
    >
      <ContentContainer style={props.contentContainerStyles}>
        {title && <Title>{title}</Title>}
        {searchEnabled && (
          <SearchInput
            onChange={setInputValue}
            placeholder={searchPlaceholder}
          />
        )}
        <OptionList mt={!!title || searchEnabled}>
          {list.map((option, index) => {
            const key = keyExtractor ? keyExtractor(option, index) : index;
            return (
              <React.Fragment key={key}>
                {option && (
                  <Option
                    {...{
                      ...option,
                      inModal,
                      isSelected: _.isEqual(option, selectedOption),
                    }}
                    onClick={() => handleSelect(option)}
                  />
                )}
              </React.Fragment>
            );
          })}
        </OptionList>
      </ContentContainer>
    </Dropdown>
  );
};

import { Flex, FlexColumn } from "@ui/common/styles/global";
import {
  BaseInput,
  Button,
  Input,
  Notification,
  QuestionPopover,
} from "@ui/components";
import { InputWithTicker } from "@ui/components/Input/InputWithTicker";
import { TokenSelector } from "@ui/components/TokenSelector";
import { Button3 } from "@ui/components/Typography";
import styled from "styled-components";
export const TriggerSetupContainer = styled.div<{ bg?: string }>`
  background: ${({ theme, bg }) =>
    bg ? bg : theme.colors.transparent.transparent};
  border-radius: ${({ theme }) => theme.radii.md};
  box-sizing: border-box;
`;

export const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
`;
export const Title = styled(Flex)`
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const Subtitle1 = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const Subtitle2 = styled(Subtitle1)`
  line-height: 16px;
`;

export const InputContainer = styled(Flex)``;

export const TokenWithInputContainer = styled(InputContainer)`
  gap: ${({ theme }) => theme.spacing.xl};
  flex-wrap: wrap;
`;

export const StyledTokenSelector = styled(TokenSelector)`
  height: 44px;
  background: ${({ theme }) => theme.colors.global.background.BG3};
  svg:first-child {
    height: 24px !important;
    width: 24px !important;
  }
  > svg,
  path {
    fill: ${({ theme }) => theme.colors.global.text.T1};
  }
`;

export const StyledInputWithTicker = styled(InputWithTicker)<{
  disabled: Boolean;
}>`
  width: ${({ width }) => `${width ?? 280}px`};
  height: 44px;
  text-overflow: ellipsis;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.global.background.BG3};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
  & + div {
    top: 50%;
    transform: translateY(-50%);
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.global.text.T3 : theme.colors.global.text.T1};
  }
  :hover {
    border-color: ${({ theme }) => theme.colors.global.line.Line2};
  }
  :focus {
    border-color: ${({ theme }) => theme.colors.global.line.Line2};
  }
  :disabled {
    color: ${({ theme }) => theme.colors.global.text.T3};
    border-color: ${({ theme }) => theme.colors.global.background.BG1};
    background-color: ${({ theme }) => theme.colors.global.background.BG4};
  }
`;

export const Checkbox = styled.input.attrs({ type: "checkbox" })`
  margin: 0;
  cursor: pointer;
  font-size: 14px;
  height: 18px;
  width: 18px;
  min-width: 18px;
  position: relative;
  border-radius: ${({ theme }) => theme.radii.round};
  background: ${({ theme }) => theme.colors.transparent.transparent};
  outline: none;
  box-shadow: none;
  appearance: none;
  &:after {
    position: absolute;
    width: 18px;
    height: 18px;
    content: "";
    display: inline-block;
    visibility: visible;
    background: ${({ theme }) => theme.colors.global.background.BG2};
    border: 1px solid ${({ theme }) => theme.colors.global.line.Line2};
    border-radius: ${({ theme }) => theme.radii.round};
  }
  &:checked:after {
    content: "✔";
    text-align: center;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.grey.black};
    background-color: ${({ theme }) => theme.colors.global.success};
  }
`;

export const StyledInput = styled(Input)`
  width: 86px;
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
  :hover {
    border-color: ${({ theme }) => theme.colors.global.line.Line2};
  }
  :focus {
    border-color: ${({ theme }) => theme.colors.global.line.Line2};
  }
  :disabled {
    color: ${({ theme }) => theme.colors.global.text.T3};
    border-color: ${({ theme }) => theme.colors.global.background.BG1};
    background-color: ${({ theme }) => theme.colors.global.background.BG3};
  }
`;

export const StyledIntInput = styled(BaseInput)`
  width: 86px;
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
  :hover {
    border-color: ${({ theme }) => theme.colors.global.line.Line2};
  }
  :focus {
    border-color: ${({ theme }) => theme.colors.global.line.Line2};
  }
  :disabled {
    color: ${({ theme }) => theme.colors.global.text.T3};
    border-color: ${({ theme }) => theme.colors.global.background.BG1};
    background-color: ${({ theme }) => theme.colors.global.background.BG3};
  }
`;

export const StyledNotification = styled(Notification)`
  margin: -4px 0;
`;

export const TokenInputLabel = styled(Button3)`
  line-height: 24px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const TokenSelectLabel = styled(TokenInputLabel)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const TriggerConfig = styled(FlexColumn)<{ marginTop?: number }>`
  gap: ${({ theme }) => theme.spacing.lg};
`;

export const TriggerConfigContainer = styled(Flex)`
  row-gap: ${({ theme }) => theme.spacing.md};
  column-gap: ${({ theme }) => theme.spacing.lg};
  flex-wrap: wrap;
`;

export const TriggerConfigLabel = styled(Subtitle2)<{ active: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ theme, active }) =>
    active ? theme.colors.global.text.T1 : theme.colors.global.text.T2};
`;

export const StyledQuestionPopover = styled(QuestionPopover)`
  margin-left: 8px;
  margin-bottom: -1px;
`;

export const ViewTriggerContainer = styled.div`
  align-self: flex-end;
  margin-bottom: 3px;
`;

export const ButtonBox = styled(Button)<{ marginTop?: number }>`
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px;`}
`;

export const Title1 = styled(Subtitle1)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const StyledFlex = styled(Flex)`
  flex: 1;
  min-width: 327px;
  width: 100%;
  justify-content: flex-end;
`;

export const TriggerActionWrapper = styled(FlexColumn)`
  width: 581px;
`;

import { QuestionPopover } from "@ui/components/Popover";
import styled from "styled-components";
import { Subtitle0 } from "../../Typography";
import { StatusTab } from "./StatusTab";
import { StatusTabProps } from "./types";

const EnableWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const ContentWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 20px;
  gap: ${({ theme }) => theme.spacing.md};
`;

export const AOStatusTab: React.FC<StatusTabProps> = ({
  disabled,
  onStatus,
  onChange,
  isLoading = false,
  ...props
}) => {
  return (
    <ContentWrapper>
      <StatusTab
        noText={true}
        onStatus={onStatus}
        statusStyle="success"
        onChange={() => {
          if (disabled || isLoading) return;
          onChange(!onStatus);
        }}
      />
      <EnableWrapper>
        {onStatus ? (
          <>
            <Subtitle0 fontWeight={400}>AO Enabled</Subtitle0>
            <QuestionPopover
              className="AO-popover"
              defaultPosition={{ right: -46, top: 26 }}
              content="Authorized Operator status is required for Aperture to manage your recurring rebalance strategy. Unlike instant or automated rebalance we cannot use the permit function since it is only valid for a single position (not future positions). As is the case with all of our liquidity tooling you will still retain full possession of your liquidity and Aperture can only act on your behalf based on the intents you’ve filled out when setting up a trigger or recurring rebalance strategy."
            />
          </>
        ) : (
          <Subtitle0>AO Disabled</Subtitle0>
        )}
      </EnableWrapper>
    </ContentWrapper>
  );
};

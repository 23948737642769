import { AddIcon } from "@aperture/assetkit";
import { Flex, FlexColumn } from "@ui/common/styles/global";
import { TriggerType } from "@ui/common/types/Strategy";
import { Headline5, IPureEditTriggerDetailProps } from "@ui/components";
import React from "react";
import { useTheme } from "styled-components";
import {
  AddTrigger,
  BodyContainer,
  Container,
  EditorButton,
  IconWrapper,
  ItemValue,
  StyledDeleteIcon,
  Title,
  TriggerRangeItem,
} from "./style";

export const PureEditTriggerDetail: React.FC<IPureEditTriggerDetailProps> = ({
  type,
  triggerIdx,
  token,
  above,
  below,
  aboveRange,
  belowRange,
  triggerType,
  onClickEdit,
  onClickDelete,
  onAddTrigger,
  disabled,
}) => {
  const theme = useTheme();

  const labelAbove =
    triggerType === TriggerType.RATIO
      ? strings.ratioAboveLabel
      : strings.priceAboveLabel;
  const labelBelow =
    triggerType === TriggerType.RATIO
      ? strings.ratioBelowLabel
      : strings.priceBelowLabel;

  const triggerTitle = {
    [TriggerType.RATIO]: "Trigger by Ratio",
    [TriggerType.PRICE]: "Trigger by Token Price",
    [TriggerType.PRICE_PERCENTAGE]: "Trigger by Percentage",
  }[triggerType];

  return (
    <Container>
      <BodyContainer>
        {((type === "below" && below) || (type === "above" && above)) && (
          <FlexColumn gap="lg" width="100%">
            <Flex alignItems="center" justifyContent="space-between">
              <Title>Trigger {triggerIdx}</Title>
              <Flex gap="sm">
                <EditorButton
                  disabled={disabled}
                  variant="contained"
                  color="secondary"
                  size="sm"
                  onClick={onClickEdit}
                >
                  {strings.editButtonLabel}
                </EditorButton>
                <StyledDeleteIcon
                  disabled={disabled}
                  onClick={() => !disabled && onClickDelete()}
                />
              </Flex>
            </Flex>
            <Flex justifyContent="space-between">
              {triggerTitle}
              <TriggerRangeItem>
                <IconWrapper>{token.Icon}</IconWrapper>
                <ItemValue>{token.ticker}</ItemValue>
                <ItemValue>{type === "below" ? below : above}</ItemValue>
                <ItemValue>
                  {type === "below" ? labelBelow : labelAbove}
                </ItemValue>
              </TriggerRangeItem>
            </Flex>
            {((type === "below" && belowRange) ||
              (type === "above" && aboveRange)) && (
              <Flex justifyContent="space-between">
                {strings.rebalanceRangeLabel}
                <FlexColumn gap="md">
                  {type === "below" ? belowRange : aboveRange}
                </FlexColumn>
              </Flex>
            )}
          </FlexColumn>
        )}
        <FlexColumn gap="lg" width="100%">
          {!(above && below) && (
            <AddTrigger onClick={() => !disabled && onAddTrigger()}>
              <AddIcon fill={theme.colors.global.primary} />
              <Headline5 marginBottom="-1px">Add Trigger 2</Headline5>
            </AddTrigger>
          )}
        </FlexColumn>
      </BodyContainer>
    </Container>
  );
};

const strings = {
  priceAboveLabel: "Above Spot Price",
  priceBelowLabel: "Below Spot Price",
  ratioAboveLabel: "Ratio Above",
  ratioBelowLabel: "Ratio Below",
  editButtonLabel: "Edit Trigger",
  rebalanceRangeLabel: "Rebalance Range",
};

import { Flex, FlexColumn } from "@ui/common/styles/global";
import {
  InputWTickerProps,
  SwitchMenuTab,
  ViewTrigger,
  getTriggerNotification,
  notificationVariants,
} from "@ui/components";
import bigDecimal from "js-big-decimal";
import React, { useMemo } from "react";
import { TriggerAction } from "../TriggerAction";
import { E_TriggerTypeTab, E_WidthUnit, IPureTriggerSetupProps } from "../type";
import { MAX_TIME_BUFFER } from "../utils";
import {
  ButtonBox,
  Checkbox,
  Container,
  InputContainer,
  StyledInputWithTicker,
  StyledNotification,
  StyledQuestionPopover,
  StyledTokenSelector,
  Subtitle1,
  Subtitle2,
  Title,
  TokenWithInputContainer,
  TriggerActionWrapper,
  TriggerConfig,
  TriggerConfigContainer,
  TriggerConfigLabel,
  TriggerSetupContainer,
  ViewTriggerContainer,
} from "./style";

const triggerTypeItems = [E_TriggerTypeTab.Price, E_TriggerTypeTab.Ratio];
const widthUnitItems = [E_WidthUnit.Percentage, E_WidthUnit.TokenTerm];

const PureComponent: React.FC<IPureTriggerSetupProps> = ({
  triggerType,
  onSelectTriggerType,
  widthUnit,
  onSelectWidthUnit,
  tokens,
  selectedToken,
  onSelectToken,
  aboveChecked,
  above,
  onAboveInput,
  onAboveChecked,

  belowChecked,
  below,
  onBelowInput,
  onBelowChecked,

  tickSpacing,
  defaultAction,
  tokenMarketPrice,

  aboveTriggerAction,
  belowTriggerAction,
  setAboveTriggerAction,
  setBelowTriggerAction,

  errorMsg,
  invalidInput,

  timeBufferChecked,
  timeBuffer,
  onTimeBufferChecked,
  onTimeBufferInput,

  onSave,
  bg,
}) => {
  const isRatio = triggerType === E_TriggerTypeTab.Ratio;

  const aboveLabel = isRatio
    ? strings.ratioAboveLabel
    : strings.priceAboveLabel;
  const belowLabel = isRatio
    ? strings.ratioBelowLabel
    : strings.priceBelowLabel;

  const inputProps = useMemo<InputWTickerProps>(() => {
    const isPercentage =
      triggerType === E_TriggerTypeTab.Ratio ||
      widthUnit === E_WidthUnit.Percentage;
    return {
      align: "right",
      type: "number",
      ticker: "",
      ...(isPercentage
        ? {
            format: {
              suffix: "%",
            },
          }
        : {
            ticker:
              tokens.find((token) => token.address !== selectedToken.address)
                ?.ticker || "USD",
          }),
    };
  }, [selectedToken.address, tokens, triggerType, widthUnit]);

  return (
    <Container bg={bg} gap="lg">
      <Title>Trigger Setup</Title>
      <TriggerSetupContainer gap="lg">
        <Subtitle2>{strings.title}</Subtitle2>
        <SwitchMenuTab
          menu={triggerTypeItems}
          defaultSelected={triggerType === E_TriggerTypeTab.Ratio ? 1 : 0}
          onSelect={onSelectTriggerType}
          width={228}
          height="24px"
        />

        <TokenWithInputContainer>
          <Flex gap="xl" justifyContent="space-between">
            <FlexColumn gap="lg">
              <Subtitle1>{strings.selectTokenLabel}</Subtitle1>
              <StyledTokenSelector
                selectedToken={selectedToken}
                tokens={tokens}
                onSelect={onSelectToken}
                style={{ width: "240px" }}
              />
            </FlexColumn>
            {triggerType === E_TriggerTypeTab.Price && (
              <FlexColumn gap="lg">
                <Subtitle1>{strings.widthUnitLabel}</Subtitle1>
                <SwitchMenuTab
                  menu={widthUnitItems}
                  defaultSelected={widthUnit === E_WidthUnit.Percentage ? 0 : 1}
                  onSelect={(newUnit) => onSelectWidthUnit(newUnit)}
                  width={224}
                  height="24px"
                />
              </FlexColumn>
            )}
            {(aboveChecked || belowChecked) && (
              <ViewTriggerContainer>
                <ViewTrigger
                  position={{
                    left: triggerType === E_TriggerTypeTab.Ratio ? -10 : -160,
                    bottom: 48,
                  }}
                  type="text"
                  {...{
                    below: `${
                      invalidInput === "below" || !belowChecked ? "" : below
                    }`,
                    above: `${
                      invalidInput === "above" || !aboveChecked ? "" : above
                    }`,
                    unit: !isRatio
                      ? widthUnit === E_WidthUnit.Percentage
                        ? "%"
                        : tokens.find(
                            (token) => token.address !== selectedToken.address
                          )?.ticker || "USD"
                      : undefined,
                    disabled: !!errorMsg,
                  }}
                />
              </ViewTriggerContainer>
            )}
          </Flex>
        </TokenWithInputContainer>
        <InputContainer
          alignItems="flex-start"
          gap={!aboveChecked && !belowChecked ? "xl" : 48}
        >
          <FlexColumn gap="lg">
            <FlexColumn gap="lg" style={{ width: "fit-content" }}>
              <Flex gap="md" alignItems="center">
                <Checkbox
                  checked={belowChecked}
                  onChange={(e) => {
                    onBelowChecked((e.target as HTMLInputElement).checked);
                  }}
                />
                <Subtitle2>{belowLabel}</Subtitle2>
              </Flex>
              <StyledInputWithTicker
                {...inputProps}
                align="left"
                format={{
                  ...inputProps.format,
                  prefix: triggerType === E_TriggerTypeTab.Price ? "-" : "",
                }}
                onError={() => invalidInput === "below"}
                disabled={!belowChecked}
                defaultValue={below?.toString()}
                popover={getTriggerPopoverText(triggerType, "lower")}
                onChange={onBelowInput}
              />
            </FlexColumn>
            {belowChecked && (
              <TriggerActionWrapper gap="lg">
                <Subtitle2>{strings.setRangeTitle}</Subtitle2>
                <TriggerAction
                  tickSpacing={tickSpacing}
                  defaultAction={defaultAction}
                  tokens={[tokens[0], tokens[1]]}
                  tokenMarketPrice={tokenMarketPrice}
                  triggerAction={belowTriggerAction}
                  setTriggerAction={setBelowTriggerAction}
                />
              </TriggerActionWrapper>
            )}
          </FlexColumn>

          <FlexColumn gap="lg">
            <FlexColumn gap="lg" style={{ width: "fit-content" }}>
              <Flex gap="md" alignItems="center">
                <Checkbox
                  checked={aboveChecked}
                  onChange={(e) => {
                    onAboveChecked((e.target as HTMLInputElement).checked);
                  }}
                />
                <Subtitle2>{aboveLabel}</Subtitle2>
              </Flex>
              <StyledInputWithTicker
                {...inputProps}
                align="left"
                disabled={!aboveChecked}
                onError={() => invalidInput === "above"}
                defaultValue={above.toString()}
                popover={getTriggerPopoverText(triggerType, "upper")}
                onChange={onAboveInput}
              />
            </FlexColumn>
            {aboveChecked && (
              <TriggerActionWrapper gap="lg">
                <Subtitle2>{strings.setRangeTitle}</Subtitle2>
                <TriggerAction
                  tickSpacing={tickSpacing}
                  defaultAction={defaultAction}
                  tokens={[tokens[0], tokens[1]]}
                  tokenMarketPrice={tokenMarketPrice}
                  triggerAction={aboveTriggerAction}
                  setTriggerAction={setAboveTriggerAction}
                />
              </TriggerActionWrapper>
            )}
          </FlexColumn>
        </InputContainer>

        {errorMsg && (
          <StyledNotification
            variant={notificationVariants.WARNING}
            text={errorMsg}
          />
        )}

        <TriggerConfigContainer>
          <TriggerConfig>
            <Flex gap="md">
              <Checkbox
                checked={timeBufferChecked}
                onChange={(e) => {
                  onTimeBufferChecked((e.target as HTMLInputElement).checked);
                }}
              />
              <TriggerConfigLabel active={timeBufferChecked}>
                {strings.timeBufferLabel}
                <StyledQuestionPopover
                  inActive={!timeBufferChecked}
                  content={strings.timeBufferPopover}
                  defaultSize={{ width: 500 }}
                  defaultPosition={{ left: -105, top: 30 }}
                />
              </TriggerConfigLabel>
            </Flex>
            <StyledInputWithTicker
              align="left"
              type="number"
              placeholder="0"
              defaultValue={timeBuffer?.toString()}
              disabled={!timeBufferChecked}
              onChange={(value) => onTimeBufferInput(value)}
              onBlur={() => {
                if (parseFloat(timeBuffer) > MAX_TIME_BUFFER)
                  onTimeBufferInput(MAX_TIME_BUFFER.toString());
              }}
              ticker={bigDecimal.compareTo(timeBuffer, 1) > 0 ? "hrs" : "hr"}
              width={240}
            />
          </TriggerConfig>
          {timeBufferChecked && (
            <StyledNotification
              variant={notificationVariants.WARNING}
              text={getTriggerNotification()}
            />
          )}
        </TriggerConfigContainer>

        <ButtonBox
          variant="contained"
          size="md"
          width="201px"
          color="secondary"
          disabled={
            !!(errorMsg || invalidInput || (!aboveChecked && !belowChecked))
          }
          onClick={onSave}
        >
          {strings.saveButtonLabel}
        </ButtonBox>
      </TriggerSetupContainer>
    </Container>
  );
};

export const PureDualTriggerSetup = React.memo(PureComponent);

const strings = {
  priceAboveLabel: "Above Spot Price By",
  priceBelowLabel: "Below Spot Price By",
  ratioAboveLabel: "Ratio Above",
  ratioBelowLabel: "Ratio Below",
  timeBufferLabel: "TWAP Duration",
  timeBufferPopover:
    "Enabling TWAP (time-weight average price) on your trigger means the average price or ratio based trigger between past specified time and now must be met before the trigger executes. This helps prevent potential price manipulation and examples are for rebalancing if 1) average ETH goes above $3,500 for past 30+ mins or 2) average pool above 80% USDC for past 2+ hrs.",
  saveButtonLabel: "Save Trigger Setting",
  widthUnitLabel: "Width Unit",
  selectTokenLabel: "Select Token",
  title: "Create Trigger",
  setRangeTitle: "Set New Rebalance Range",
};

const getTriggerPopoverText = (
  triggerType: E_TriggerTypeTab,
  upperOrLower: "upper" | "lower"
) => {
  if (triggerType === E_TriggerTypeTab.Ratio) {
    return `This is the ${upperOrLower} ratio threshold to trigger the rebalance. Strategy will execute when the token ratio crosses this threshold.`;
  }
  return "";
};

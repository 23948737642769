import { useEffect } from "react";
import { useEventCallback } from "./useEventCallback";

export function useOnUpdateExist<T>(v: T, callback: () => void) {
  const cb = useEventCallback(callback);
  useEffect(() => {
    if (v) {
      cb();
    }
  }, [cb, v]);
}

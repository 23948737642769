import { FlexColumn } from "@ui/common/styles/global";
import { Body1, Headline5 } from "@ui/components/Typography";
import styled from "styled-components";

export interface IBasicModalContentProps {
  title: string;
  content: string | JSX.Element;
}

const H5 = styled(Headline5)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const B1 = styled(Body1)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const BasicModalContent: React.FC<IBasicModalContentProps> = ({
  title,
  content,
}) => {
  return (
    <Container>
      <H5>{title}</H5>
      <B1>{content}</B1>
    </Container>
  );
};

const Container = styled(FlexColumn)`
  gap: ${({ theme }) => theme.spacing.xl};
  width: 361px;
  box-sizing: border-box;
`;

import { GoToIcon } from "@aperture/assetkit";
import { FlexColumn } from "@uiv2/common";
import React from "react";
import styled, { useTheme } from "styled-components";
import { QuestionPopover } from ".";
import { T4 } from "../Typography";
import {
  IApertureFeePopoverProps,
  IRecurringRebalanceFeePopoverProps,
} from "./types";

const PopoverContent = styled(FlexColumn)`
  text-align: left;
`;
const Link = styled.a`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.global.primary};
`;
const GoTo = styled(GoToIcon)`
  margin-bottom: -2px;
  width: 14px;
  height: 14px;
  fill: ${({ theme }) => theme.colors.global.text.T1} !important;
`;

export const ApertureFeePopover: React.FC<IApertureFeePopoverProps> = ({
  hint,
  fixColor,
  defaultPosition,
}) => {
  const theme = useTheme();
  return (
    <QuestionPopover
      defaultSize={{ width: 345 }}
      defaultPosition={defaultPosition}
      fill={fixColor ? theme.colors.global.textAlt.T1 : ""}
      content={
        <PopoverContent gap={"md"}>
          {hint && <span>{hint}</span>}
          <span>
            See our{" "}
            <Link
              href="https://docs.aperture.finance/docs/transparency/fees"
              target="_blank"
            >
              Fee Structure
            </Link>{" "}
            <GoTo /> , or{" "}
            <Link
              href="https://d40pie7y7yv.typeform.com/to/vNvYs6AS"
              target="_blank"
            >
              reach out
            </Link>{" "}
            if you're an institutional LP interested in a custom pricing
            solution.
          </span>
        </PopoverContent>
      }
    />
  );
};

export const RecurringRebalanceFeePopover: React.FC<
  IRecurringRebalanceFeePopoverProps
> = ({ hint, defaultPosition }) => {
  const theme = useTheme();
  return (
    <QuestionPopover
      defaultSize={{ width: 400 }}
      defaultPosition={defaultPosition}
      content={
        <FlexColumn gap="md">
          <T4 lineHeight="normal" textAlign="left">
            {hint}
          </T4>
        </FlexColumn>
      }
      fill={theme.colors.global.textAlt.T1}
    />
  );
};

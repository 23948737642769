import { ITokenTickerIcon } from "@aperture/types";
import { Flex, FlexColumn } from "@uiv2/common";
import { TokenIcon } from "@uiv2/components/TokenIcon";
import { H2, T1, T3Regular, T4 } from "@uiv2/components/Typography";
import { formatUSDAmount } from "@uiv2/utils/numberFormat";
import { CSSProperties, ReactNode } from "react";
import styled, { useTheme } from "styled-components";

const ContentWrapper = styled(FlexColumn)`
  width: 370px;
  gap: ${({ theme }) => theme.spacing["3xl"]};
`;
const Title = styled(H2)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const StyledT4 = styled(T4)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const BoxText = styled(T4)`
  color: ${({ theme }) => theme.colors.global.textAlt.T1};
`;
const Amount = styled(T1)`
  color: ${({ theme }) => theme.colors.global.primary};
`;
const ContentBox = styled(FlexColumn)`
  border-radius: 6px;
  gap: ${({ theme }) => theme.spacing.md};
  padding: ${({ theme }) => theme.spacing.lg};
  background-color: ${({ theme }) => theme.colors.global.background.BG3};
`;
const Box = styled.div<{ bg?: CSSProperties["backgroundColor"] }>`
  text-align: center;
  border-radius: ${({ theme }) => theme.radii.sm};
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  background-color: ${({ theme, bg }) =>
    bg ?? theme.colors.global.background.BG4};
`;
const IconWrapper = styled.div`
  height: 20px;
  width: 20px;
  svg {
    height: 20px;
    width: 20px;
  }
`;
const StyledT3 = styled(T3Regular)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

interface IStrategy {
  amountUSD: number;
  strategyPL: number;
  tokenA: {
    amt: number;
    amtUSD: number;
  };
  tokenB: {
    amt: number;
    amtUSD: number;
  };
  lpYield?: PnL;
  days?: number;
}

interface PnL {
  percentage: number;
  amountUSD: number;
}

export interface IImpermanentLossContentProps {
  tokenA: ITokenTickerIcon;
  tokenB: ITokenTickerIcon;
  strategyA: IStrategy;
  strategyB: IStrategy;
  impermanentLoss: PnL;
  PnL: PnL;
  days: number;
}

const ItemRow = ({
  label,
  value,
  tag,
  Icon,
}: {
  label: string;
  value: string;
  tag: string;
  Icon?: ReactNode;
}) => {
  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex gap={10} alignItems="center">
        {Icon && <IconWrapper>{Icon}</IconWrapper>}
        <StyledT3>{label}</StyledT3>
      </Flex>
      <Flex gap={10} alignItems="center">
        <StyledT4>{value}</StyledT4>
        <Box style={{ minWidth: "50px" }}>
          <StyledT4>{tag}</StyledT4>
        </Box>
      </Flex>
    </Flex>
  );
};
const TitleRow = ({
  title,
  strategyPL,
  amountUSD,
}: Pick<IStrategy, "strategyPL" | "amountUSD"> & { title: string }) => {
  const theme = useTheme();
  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Box>
        <StyledT4>{title}</StyledT4>
      </Box>
      <Flex alignItems="center" gap="lg">
        <Box bg={theme.colors.global.success}>
          <BoxText>{strategyPL.toFixed(2)}%</BoxText>
        </Box>
        <Amount>{amountUSD.toFixed(2)}</Amount>
      </Flex>
    </Flex>
  );
};

export const ImpermanentLossContent: React.FC<IImpermanentLossContentProps> = ({
  tokenA,
  tokenB,
  strategyA,
  strategyB,
  impermanentLoss,
  PnL,
  days,
}) => {
  const data = {
    "Impermanent Loss": impermanentLoss,
    [`PnL (${days}d)`]: PnL,
  };

  const strategyData = [
    {
      ...{
        ...strategyA,
        title: "Strategy A: HODL",
        tokenA: { ...strategyA.tokenA, ...tokenA },
        tokenB: { ...strategyA.tokenB, ...tokenB },
      },
    },
    {
      ...{
        ...strategyB,
        title: "Strategy B: LP",
        tokenA: { ...strategyB.tokenA, ...tokenA },
        tokenB: { ...strategyB.tokenB, ...tokenB },
      },
    },
  ];

  return (
    <ContentWrapper>
      <Title>Impermanent Loss Calculator</Title>
      <FlexColumn gap="lg">
        {strategyData.map(
          ({ title, strategyPL, amountUSD, tokenA, tokenB, lpYield }) => {
            const key = !!lpYield ? "B" : "A";
            return (
              <ContentBox key={`strategy-${key}-${title}`}>
                <TitleRow
                  {...{
                    title,
                    strategyPL,
                    amountUSD,
                  }}
                />
                {[tokenA, tokenB].map(({ ticker, Icon, amt, amtUSD }) => (
                  <ItemRow
                    key={`strategy${key}-${ticker}`}
                    {...{
                      Icon,
                      label: ticker,
                      value: amt.toFixed(5),
                      tag: `${formatUSDAmount(amtUSD, 2)}`,
                    }}
                  />
                ))}
                {lpYield && (
                  <ItemRow
                    {...{
                      label: `LP Yield (${days ?? "-"}d)`,
                      value: `${lpYield.percentage.toFixed(2)}%`,
                      tag: `$${lpYield.amountUSD.toFixed(2)}`,
                    }}
                  />
                )}
              </ContentBox>
            );
          }
        )}
        <ContentBox>
          {Object.entries(data).map(([label, { percentage, amountUSD }]) => (
            <ItemRow
              key={label}
              {...{
                label,
                value: `${amountUSD < 0 ? "-$" : "$"}${Math.abs(
                  amountUSD
                ).toFixed(2)}`,
                tag: `${percentage.toFixed(2)}%`,
              }}
            />
          ))}
        </ContentBox>
      </FlexColumn>
    </ContentWrapper>
  );
};

export const mockImpermanentLossData: IImpermanentLossContentProps = {
  tokenA: {
    ticker: "ETH",
    Icon: (
      <TokenIcon
        src="https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png"
        alt="ETH"
      />
    ),
  },
  tokenB: {
    ticker: "USDT",
    Icon: (
      <TokenIcon
        src="https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png"
        alt="USDT"
      />
    ),
  },
  strategyA: {
    amountUSD: 1025.51,
    strategyPL: 2.55,
    tokenA: {
      amt: 351.78204,
      amtUSD: 351.82,
    },
    tokenB: {
      amt: 0.24768,
      amtUSD: 673.69,
    },
  },
  strategyB: {
    amountUSD: 1025.56,
    strategyPL: 2.56,
    tokenA: {
      amt: 441.65837,
      amtUSD: 441.71,
    },
    tokenB: {
      amt: 0.21399,
      amtUSD: 582.05,
    },
    lpYield: { percentage: 0.18, amountUSD: 1.8 },
  },
  impermanentLoss: { percentage: 0.18, amountUSD: -1.8 },
  PnL: { percentage: 0.01, amountUSD: 0.05 },
  days: 1,
};

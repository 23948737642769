import { ZondiconsLink } from "@aperture/assetkit";
import { Flex } from "@ui/common";
import styled from "styled-components";
import { ButtonText } from "../Typography";

export const Container = styled(Flex)`
  width: fit-content;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
`;

export const ButtonTextBox = styled(ButtonText)`
  color: ${({ theme }) => theme.colors.global.text.T1};
  font-family: "Roboto Flex";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  padding: 0;
`;

export const Zondicons = styled(ZondiconsLink)`
  stroke: ${({ theme }) => theme.colors.global.text.T1};
`;

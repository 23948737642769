import { EvmChainMap } from "@/config/chain";
import { RpcSettingsType } from "@/config/feature_flags";
import {
  ApertureSupportedChainId,
  getChainInfo,
} from "@aperture_finance/uniswap-v3-automation-sdk";
import { createPublicClient, fallback, http } from "viem";

export function getPublicClient(
  chainId: ApertureSupportedChainId,
  rpcSetting?: RpcSettingsType
) {
  const rpcs = rpcSetting?.[chainId];
  return createPublicClient({
    chain: getChainInfo(chainId).chain,
    batch: {
      multicall: true,
    },
    transport: fallback(
      (rpcs ?? EvmChainMap[chainId].rpc).map((rpc) => http(rpc))
    ),
  });
}

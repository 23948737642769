import styled from "styled-components";
import { compose, layout, space, typography } from "styled-system";
import {
  buttonSizeVariants,
  buttonVariants,
  iconSizeVariants,
  lockedButtonVariants,
} from "./themes";
import { ThemedProps, colors } from "./types";

export const StyledButtonWrapper = styled.div<ThemedProps>`
  ${({ color, variant, theme }) =>
    color === colors.gradient && variant === "contained"
      ? `
      opacity: 1;
      transition: all ${theme.transitions.default}  ease-in-out;
      &:hover {
        opacity: 0.8;
      }  
  `
      : ""}
  ${({ width }) => (width === "100%" ? `width: 100%;` : "")}
`;

export const StyledButton = styled.button<ThemedProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (!!props.disabled ? "auto" : "pointer")};
  font-weight: 500;
  gap: ${({ theme, iconGap }) => (iconGap ? `${iconGap}px` : theme.spacing.sm)};
  border: none;
  transition: all ${({ theme }) => theme.transitions.fast} ease-in-out;

  &:hover {
    transition-duration: ${({ theme }) => theme.transitions.default};
  }

  &:disabled > * {
    opacity: 0.5;
  }

  // Button Variants
  ${(props) => buttonVariants(props)}
  // Size Variants
  ${(props) => buttonSizeVariants(props)}

  ${(props) => (!!props.width ? `width: ${props.width}` : "")};

  ${(props) =>
    props.invalid
      ? `
        ${
          props.variant !== "text"
            ? `
              border: none !important;
              background: ${props.theme.colors.global.text.T1} !important;
            `
            : ``
        }
        color: ${props.theme.colors.global.error} !important;
      `
      : ``}

  ${(props) =>
    !!props.disabled
      ? `background: ${
          props.variant === "text"
            ? props.theme.colors.transparent.transparent
            : props.theme.colors.global.primaryDisable
        } !important;
    color: ${props.theme.colors.global.textAlt.T1} !important;
    border: none !important;
    &:hover {
      box-shadow: none !important;
    }`
      : ""};
  ${(props) => (!!props.locked ? lockedButtonVariants(props) : "")};
  ${compose(layout, space, typography)}
`;

export const IconWrapper = styled.div<ThemedProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  // Size Variants
  ${(props) => iconSizeVariants(props)}
`;

import React, { useEffect, useState } from "react";
import { PercentageInputGroup, T1Regular } from "..";
import { Flex, FlexColumn } from "../../common/styles/global";
import { IDefaultPosition, QuestionPopover } from "../Popover";
import { Slippage } from "../Slippage";
import { StatusTab } from "../Tabs";
import {
  ArrowBtn,
  ArrowDown,
  Container,
  Content,
  FlexBox,
  TitleBar,
  TitleBox,
  Wrapper,
} from "./style";
import { IPercentageInput, StrategyAdvancedSettingsProps } from "./types";
import { useInput } from "./useInput";

export const StrategyAdvancedSettings: React.FC<
  StrategyAdvancedSettingsProps
> = ({
  expanded,
  autoCompound = false,
  hideAutoCompound = false,
  disableAutoCompound = false,
  handleAutoCompoundChange,
  gasCeiling,
  acGasCeiling,
  acMaxGasFee,
  acSlippage,
  bg,
  isCreate,
}) => {
  const [expandable, setExpandable] = useState(true);
  const [isShow, setIsShow] = useState(true);
  const [overflow, setOverflow] = useState(true);
  const { handleChange: handleGasCeilingChange } = useInput(gasCeiling);
  const { handleChange: handleAcGasCeilingChange } = useInput(acGasCeiling);
  const { handleChange: handleAcMaxGasChange } = useInput(acMaxGasFee);

  useEffect(() => {
    if (!expanded) return;
    setIsShow(expanded);
    setOverflow(expanded);
  }, [expanded]);

  const data: {
    title: string;
    popoverText: string;
    data: IPercentageInput;
    defaultPosition?: IDefaultPosition;
  }[] = [
    {
      title: "Gas Fee Ceiling",
      popoverText:
        "Input the maximum gas fee in either dollars or percentage of position. However, the actual gas ceiling will be based on the percentage not the dollar amount (e.g. if you choose $50 which is currently 1% of your total liquidity position then we will set a gas ceiling of 1%). This gas fee ceiling is the global setting for the strategy and does not apply to auto compound.",
      data: { ...gasCeiling, onChange: handleGasCeilingChange },
      defaultPosition: { bottom: 18, left: -120 },
    },
    {
      title: "Minimum Fees",
      popoverText:
        "Input the minimum amount of fees needed to trigger fee compounding in either dollars or percentage of position. However, the actual compound trigger will be based on the percentage (e.g. if you choose $200 which is currently 5% of your total liquidity position we will compound based on the 5%).",
      data: { ...acGasCeiling, onChange: handleAcGasCeilingChange },
      defaultPosition: { bottom: 18, left: -98 },
    },
    {
      title: "Maximum Gas Fee",
      popoverText:
        "Input the maximum gas fee in either dollars or percentage of position. However, the actual gas ceiling will be based on the percentage not the dollar amount (e.g. if you choose $50 which is currently 1% of your total liquidity position then we will set a gas ceiling of 1%). This gas fee ceiling applies to auto compound only.",
      data: { ...acMaxGasFee, onChange: handleAcMaxGasChange },
      defaultPosition: { bottom: 18, left: -122 },
    },
  ];

  return (
    <Container isShow={isShow} bg={bg}>
      <TitleBar
        onClick={() => {
          if (!expandable) return;
          setIsShow(!isShow);
          setExpandable(isShow);
          setTimeout(
            function () {
              setOverflow(!isShow);
              setExpandable(true);
            },
            isShow ? 0 : 300
          );
        }}
      >
        <T1Regular fontWeight={500}>Advanced Setting</T1Regular>
        <ArrowBtn>
          <ArrowDown isShow={isShow} />
        </ArrowBtn>
      </TitleBar>
      <Content isShow={isShow} useOverflow={overflow} isCreate={isCreate}>
        <FlexColumn gap="xl">
          <FlexColumn gap={"lg"}>
            <TitleBox gap="sm">
              <T1Regular>{data[0].title}</T1Regular>
              <QuestionPopover
                content={data[0].popoverText}
                defaultPosition={data[0].defaultPosition}
              />
            </TitleBox>
            <PercentageInputGroup color="primary" width={280} {...gasCeiling} />
          </FlexColumn>
          {isCreate ? null : (
            <Slippage
              {...acSlippage}
              isSm={true}
              disabled={!autoCompound || disableAutoCompound}
              hideAuto={disableAutoCompound}
              showWarning={autoCompound && !disableAutoCompound}
            />
          )}
        </FlexColumn>
        {!hideAutoCompound && (
          <FlexColumn gap="lg">
            <FlexBox>
              {!disableAutoCompound && (
                <StatusTab
                  onStatus={autoCompound}
                  onChange={handleAutoCompoundChange}
                  noText
                />
              )}
              <Flex gap="sm" alignItems="center">
                <T1Regular>Auto-Compound</T1Regular>
                <QuestionPopover
                  content={
                    "Previously enabled auto-compounding settings on the initial position will automatically roll over to your new recurring rebalance strategy."
                  }
                  defaultPosition={{ bottom: 18, left: -133 }}
                />
              </Flex>
            </FlexBox>
            <Wrapper>
              {data.slice(1).map(
                ({ title, popoverText, data, defaultPosition }) =>
                  autoCompound && (
                    <FlexColumn gap="lg" key={title}>
                      <Flex gap="sm" alignItems="center">
                        <T1Regular>{title}</T1Regular>
                        <QuestionPopover
                          content={popoverText}
                          defaultPosition={defaultPosition}
                        />
                      </Flex>
                      <PercentageInputGroup
                        color="primary"
                        width={280}
                        {...data}
                        showError={autoCompound && !disableAutoCompound}
                        disabled={!autoCompound || disableAutoCompound}
                      />
                    </FlexColumn>
                  )
              )}
              {isCreate ? (
                <FlexColumn gap="lg">
                  <Slippage
                    {...acSlippage}
                    isSm={true}
                    disabled={!autoCompound || disableAutoCompound}
                    hideAuto={disableAutoCompound}
                    showWarning={autoCompound && !disableAutoCompound}
                  />
                </FlexColumn>
              ) : null}
            </Wrapper>
          </FlexColumn>
        )}
      </Content>
    </Container>
  );
};

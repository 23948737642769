import { CaretIcon } from "@aperture/assetkit";
import { Flex } from "@uiv2/common";
import { T2, T3Regular } from "@uiv2/components/Typography";
import styled from "styled-components";

export const ButtonContainer = styled(Flex)<{ inModal?: boolean }>`
  cursor: pointer;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;

  height: 44px;
  padding: ${({ theme }) => theme.spacing.lg};
  border-radius: ${({ theme }) => theme.radii.round};

  color: ${({ theme }) => theme.colors.global.text.T1};
  background-color: ${({ theme, inModal }) =>
    inModal
      ? theme.colors.global.background.BG3
      : theme.colors.global.background.BG2};

  transition: all ${({ theme }) => theme.transitions.default};

  &:hover {
    opacity: 0.8;
  }
`;

export const ArrowDownWrapper = styled.div`
  margin-left: auto;
  margin-bottom: -4px;
  > svg,
  path {
    fill: ${({ theme }) => theme.colors.global.text.T1};
  }
`;

export const ArrowDown = styled(CaretIcon)`
  width: 20px;
  height: 20px;
  margin-left: auto;
`;

export const ContentContainer = styled.div`
  min-width: 282px;
  padding: ${({ theme }) => theme.spacing.xl};
  box-sizing: border-box;
`;

export const Title = styled(T2)`
  margin-bottom: 8px;
`;

export const OptionList = styled.div<{ mt: boolean }>`
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  max-height: 240px;
  margin-top: ${({ mt }) => mt && "8px"};
`;

export const IconWrapper = styled.div<{ size?: number }>`
  height: ${({ size }) => `${size ?? 20}px`};
  width: ${({ size }) => `${size ?? 20}px`};
  svg {
    height: ${({ size }) => `${size ?? 20}px`};
    width: ${({ size }) => `${size ?? 20}px`};
  }
`;

export const DropdownItem = styled(Flex)<{
  selected?: boolean;
  inModal?: boolean;
}>`
  box-sizing: border-box;
  align-items: center;
  margin-bottom: 8px;
  padding: ${({ theme }) => theme.spacing.lg};
  gap: ${({ theme }) => theme.spacing.sm};
  border-radius: ${({ theme }) => theme.radii.md};
  cursor: pointer;
  background-color: ${({ selected, inModal, theme }) =>
    selected
      ? inModal
        ? theme.colors.global.background.BG4
        : theme.colors.global.background.BG3
      : theme.colors.transparent.transparent};

  &:hover {
    background-color: ${({ theme, inModal }) =>
      inModal
        ? theme.colors.global.background.BG4
        : theme.colors.global.background.BG3};
  }
  transition: all ${({ theme }) => theme.transitions.default};
`;

export const StyledT2 = styled(T2)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const StyledT3 = styled(T3Regular)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const Balance = styled(StyledT2)`
  margin-left: auto;
`;
export const Text = styled(T2)`
  padding: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.global.text.T2};
`;

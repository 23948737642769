import { useEffect, useRef } from "react";
import { useTheme } from "styled-components";
import { ChartTick } from "../types";
import { getTickFromPrice } from "../utils";
import D3LiquidityHistogram from "./D3LiquidityHistogram";
import { Container, Heading } from "./style";
import { ChartBin, LiquidityPositionChartProps } from "./types";

let d3Chart: D3LiquidityHistogram | null = null;
export const LiquidityPositionChart: React.FC<LiquidityPositionChartProps> = ({
  isPairToggled = false,
  isFullRange = false,
  token0,
  token1,
  token0PriceChart,
  token1PriceChart,
  priceRangeValue,
  priceAssumptionValue,
  poolTicks,
}) => {
  const theme = useTheme();
  const refElement = useRef<HTMLDivElement>(null);
  const processData = (
    ticks: ChartTick[],
    minTick: number,
    maxTick: number
  ): ChartBin[] => {
    const bins: ChartBin[] = [];
    let liquidity = 0;
    for (let i = 0; i < ticks.length - 1; ++i) {
      liquidity += ticks[i].liquidityNet;

      const lowerTick = ticks[i].tickIdx;
      const upperTick = ticks[i + 1].tickIdx;

      if (upperTick > minTick && lowerTick < maxTick) {
        bins.push({
          x0: ticks[i].tickIdx,
          x1: ticks[i + 1].tickIdx,
          y: liquidity,
        });
      }
    }
    return bins;
  };

  const calculateInitialMinMaxTick = (
    ticks: ChartTick[],
    minimumTick: number,
    maximumTick: number
  ) => {
    const liquidity: ChartBin[] = [];
    for (let i = 0; i < ticks.length - 1; ++i) {
      liquidity.push({
        x0: ticks[i].tickIdx,
        x1: ticks[i].tickIdx,
        y:
          (liquidity[liquidity.length - 1] || { y: 0 }).y +
          ticks[i].liquidityNet,
      });
    }
    const result = liquidity.filter(
      (b) => b.y > 0 && b.x0 >= minimumTick && b.x0 <= maximumTick
    );
    if (result.length === 0) {
      return { minTick: 0, maxTick: 0 };
    }
    return { minTick: result[0].x0, maxTick: result[result.length - 1].x0 };
  };

  useEffect(() => {
    if (!poolTicks || !token0 || !token1) return;
    if (d3Chart) d3Chart.destroy();

    const currentPrice = priceAssumptionValue;
    let currentTick = getTickFromPrice(
      currentPrice,
      token0.decimals,
      token1.decimals
    );
    if (isPairToggled) {
      currentTick = -currentTick;
    }
    let minimumTick = getTickFromPrice(
      currentPrice * 0.5,
      token0.decimals,
      token1.decimals
    );
    let maximumTick = getTickFromPrice(
      currentPrice * 2,
      token0.decimals,
      token1.decimals
    );
    if (isPairToggled) {
      minimumTick = -minimumTick;
      maximumTick = -maximumTick;
    }

    let _ticks = [minimumTick, maximumTick].sort((a, b) => a - b);
    let { minTick, maxTick } = calculateInitialMinMaxTick(
      poolTicks,
      _ticks[0],
      _ticks[1]
    );
    let ticks = [minTick, maxTick].sort((a, b) => a - b);
    // Handle case when there is liquidityNet = []; indexing_error
    if (poolTicks.length === 2) {
      ticks = _ticks;
    }

    let token0Symbol;
    let token1Symbol;
    if (isPairToggled) {
      token0Symbol = token1?.ticker;
      token1Symbol = token0?.ticker;
    } else {
      token0Symbol = token0?.ticker;
      token1Symbol = token1?.ticker;
    }

    let token0Decimal;
    let token1Decimal;
    if (isPairToggled) {
      token0Decimal = token1?.decimals;
      token1Decimal = token0?.decimals;
    } else {
      token0Decimal = token0?.decimals;
      token1Decimal = token1?.decimals;
    }

    const margin = (ticks[1] - ticks[0]) / 10;
    d3Chart = new D3LiquidityHistogram(refElement.current, {
      width: 368,
      height: 171,
      minTick: ticks[0] - margin,
      maxTick: ticks[1] + margin,
      isPairToggled: isPairToggled,
      currentTick,
      token0Symbol,
      token1Symbol,
      token0Decimal,
      token1Decimal,
      data: processData(poolTicks, ticks[0], ticks[1]),
      colors: {
        graph: theme.colors.global.primaryDisable,
        current: theme.colors.global.secondary,
        minMax: theme.colors.global.primary,
        axis: theme.colors.global.text.T2,
      },
    });

    return () => {
      d3Chart?.destroy();
    };
    // eslint-disable-next-line
  }, [refElement, poolTicks, token0PriceChart, token1PriceChart]);

  useEffect(() => {
    if (!d3Chart) return;
    if (!token0 || !token1) return;

    const currentPrice = priceAssumptionValue;
    if (!isPairToggled) {
      d3Chart.updateCurrentTick(
        getTickFromPrice(currentPrice, token0.decimals, token1.decimals)
      );
    } else {
      d3Chart.updateCurrentTick(
        -getTickFromPrice(currentPrice, token0.decimals, token1.decimals)
      );
    }
    // eslint-disable-next-line
  }, [priceAssumptionValue, token0, token1]);

  useEffect(() => {
    if (!d3Chart) return;
    if (!token0 || !token1) return;

    let minTick: number;
    let maxTick: number;

    if (!isPairToggled) {
      minTick = getTickFromPrice(
        priceRangeValue[0],
        token0.decimals,
        token1.decimals
      );
      maxTick = getTickFromPrice(
        priceRangeValue[1],
        token0.decimals,
        token1.decimals
      );
    } else {
      minTick = -getTickFromPrice(
        priceRangeValue[0],
        token0.decimals,
        token1.decimals
      );
      maxTick = -getTickFromPrice(
        priceRangeValue[1],
        token0.decimals,
        token1.decimals
      );
    }

    d3Chart.updateMinMaxTickRange(minTick, maxTick, isFullRange);
    // eslint-disable-next-line
  }, [priceRangeValue, token0, token1, isFullRange]);

  useEffect(() => {
    if (!d3Chart) return;
    d3Chart.updateColors({
      graph: theme.colors.global.primaryDisable,
      current: theme.colors.global.secondary,
      minMax: theme.colors.global.primary,
      axis: theme.colors.global.text.T2,
    });
  }, [theme]);

  return (
    <Container>
      <Heading>Liquidity Position</Heading>
      <div ref={refElement} />
    </Container>
  );
};

import { PropsWithChildren } from "react";
import { IconWrapper, StyledButton, StyledButtonWrapper } from "./styles";
import { ButtonProps } from "./types";

const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
  children,
  ...props
}) => {
  const { startIcon, lockedIcon } = props;
  return (
    <StyledButtonWrapper width={props.width || ""}>
      <StyledButton {...props} disabled={props.disabled || props.locked}>
        {!!startIcon && (
          <IconWrapper size={props.size}>{startIcon}</IconWrapper>
        )}
        {!!lockedIcon && props.locked && (
          <IconWrapper size={props.size}>{lockedIcon}</IconWrapper>
        )}
        {children}
      </StyledButton>
    </StyledButtonWrapper>
  );
};

export default Button;

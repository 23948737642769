import { IPopoverRef, Popover } from "@ui/components";
import { SelectedMark } from "@ui/components/TopBar/Network/style";
import { SupportedChainId } from "@ui/utils";
import _ from "lodash";
import React, { useCallback, useRef, useState } from "react";
import { useTheme } from "styled-components";
import { IMenuItem, IPopoverMenuProps } from ".";
import { Caret, MenuItem, MenuWrapper, NavLink, Title } from "./style";

export const PopoverMenu: React.FC<IPopoverMenuProps> = ({
  onNav,
  onSelect,
  onOpen,
  onDisappeared,
  internalMenuLabel = "",
  mainMenu,
  networkInfo,
}) => {
  const theme = useTheme();
  const [hovered, setHovered] = useState<IMenuItem | undefined>(undefined);
  const popoverRef = useRef<IPopoverRef>(null);
  const handleOnNav = (link?: string) => {
    if (!link) return;
    onNav && onNav(link);
    close();
  };

  const handleOnSelect = (main: IMenuItem, sub: IMenuItem) => {
    onSelect &&
      onSelect(
        {
          amm: { Icon: main.Icon, name: main.name },
          chain: sub.id ?? SupportedChainId.ETHEREUM,
        },
        sub.link
      );
    close();
  };

  const timerRef = useRef<NodeJS.Timeout>();
  const toggleHover = (show: boolean, hoveredItem: IMenuItem | undefined) => {
    if (show) {
      if (hoveredItem && (mainMenu.get(hoveredItem) ?? []).length > 0) {
        onOpen && onOpen(true);
        popoverRef?.current?.onShow();
      }

      setHovered(hoveredItem);
    } else {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        close();
      }, 200);
    }
  };

  const close = useCallback(() => {
    onOpen && onOpen(false);
    setHovered(undefined);
    onDisappeared && onDisappeared();
  }, []);

  return (
    <Popover
      ref={popoverRef}
      background={theme.colors.global.background.BG3}
      defaultPosition={{ left: 192, top: -4 }}
      contentStyles={{
        padding: 0,
        borderRadius: "0 10px 10px 0",
      }}
      noBorder={true}
      content={
        hovered && (mainMenu.get(hovered) ?? []).length > 0 ? (
          <MenuWrapper hasItems={true} minHeight={250}>
            {internalMenuLabel && <Title>{internalMenuLabel}</Title>}
            {(mainMenu.get(hovered) ?? []).map(
              ({ Icon, name, id, link }, index) => (
                <MenuItem
                  isSelected={false}
                  hasItems={true}
                  gap={8}
                  key={`SubMenuItem#${index}-${name}`}
                  onClick={() =>
                    handleOnSelect(hovered, { Icon, name, id, link })
                  }
                >
                  {networkInfo && (
                    <SelectedMark
                      selected={
                        hovered.name === networkInfo.amm?.name &&
                        networkInfo.chain === id
                      }
                    />
                  )}
                  {Icon && <Icon width={20} height={20} />}
                  {name}
                </MenuItem>
              )
            )}
          </MenuWrapper>
        ) : (
          ""
        )
      }
      onDisappeared={() => {
        setHovered(undefined);
        onDisappeared && onDisappeared();
      }}
    >
      <MenuWrapper hasItems={false} height={242}>
        {Array.from(mainMenu.keys()).map((mainMenuItem, index) => {
          const { Icon, name, link } = mainMenuItem;
          const internalMenu = mainMenu.get(mainMenuItem);
          const type = internalMenu?.length ?? 0 ? "Selector" : "Nav";
          return (
            <MenuItem
              isSelected={_.isEqual(mainMenuItem, hovered)}
              hasItems={false}
              gap={type === "Selector" ? 8 : 10}
              key={`MenuItem#${index}-${name}`}
              onMouseEnter={() => toggleHover(true, mainMenuItem)}
            >
              {type === "Selector" ? (
                <>
                  {networkInfo && (
                    <SelectedMark selected={networkInfo.amm.name === name} />
                  )}
                  {Icon && <Icon width={20} height={20} />}
                  {name}
                  <Caret />
                </>
              ) : (
                <NavLink
                  href={link ? `/${link}` : {}}
                  style={{ width: "100%" }}
                >
                  {Icon && (
                    <Icon
                      width={20}
                      height={20}
                      fill={theme.colors.global.text.T1}
                    />
                  )}
                  {name}
                </NavLink>
              )}
            </MenuItem>
          );
        })}
      </MenuWrapper>
    </Popover>
  );
};

import { useEffect } from "react";
import { useAccount } from "wagmi";
import { useEventCallback } from "../useEventCallback";

export const useOnLogout = (cb: () => void) => {
  const { isConnected, address } = useAccount();

  const callback = useEventCallback(cb);

  useEffect(() => {
    if (!isConnected) {
      callback();
    }
  }, [callback, isConnected]);

  useEffect(() => {
    if (address) {
      return () => {
        callback();
      };
    }
  }, [address, callback]);
};

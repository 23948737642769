import { SearchIcon } from "@aperture/assetkit";
import React from "react";
import styled from "styled-components";
import { SpaceProps, TypographyProps } from "styled-system";
import { Input } from "./Input";

interface SearchInputProps extends SpaceProps, TypographyProps {
  disabled?: boolean;
  placeholder?: string;
  width?: number;
  height?: number;
  onChange?: (value: string) => void;
}

const Container = styled.div<{ width?: number; height?: number }>`
  position: relative;
  height: ${({ height }) => height && `${height}px`};
  width: ${({ width }) => width && `${width}px`};
  border-radius: ${({ theme }) => theme.radii.round};
`;
const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
  fill: ${({ theme }) => theme.colors.global.text.T1};
`;

const StyledInput = styled(Input)<{
  fontSize?: TypographyProps["fontSize"];
  lineHeight?: TypographyProps["lineHeight"];
}>`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-left: 50px;
  font-weight: 400;
  font-size: ${({ fontSize }) =>
    fontSize && typeof fontSize === "string" ? fontSize : "14px"};
  line-height: ${({ lineHeight }) =>
    lineHeight && typeof lineHeight === "string" ? lineHeight : "16px"};
  color: ${({ theme }) => theme.colors.global.text.T1};
  background: ${({ theme }) => theme.colors.global.background.BG1};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
  transition: border-color 0.3s ease !important;
  border-radius: ${({ theme }) => theme.radii.round};

  :focus {
    border-color: ${({ theme }) => theme.colors.global.text.T1};
  }
`;

export const SearchInput: React.FC<SearchInputProps> = ({ ...props }) => {
  return (
    <Container width={props.width} height={props.height}>
      <StyledSearchIcon height={16} width={16} />
      <StyledInput type="text" align="left" {...props} />
    </Container>
  );
};

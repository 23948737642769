import styled from "styled-components";
import { Tag } from "./Tag";
import { AutoCompoundProps, variants } from "./types";

const StyledAutoCompoundTag = styled(Tag)<{ autoCompound: boolean }>`
  padding: 0 8px;
  line-height: 20px;
  color: ${({ theme, autoCompound }) =>
    autoCompound
      ? theme.colors.global.textAlt.T1
      : theme.colors.global.text.T1};
  border-radius: ${({ theme }) => theme.radii.sm};
  path,
  circle,
  line {
    fill: ${({ theme }) => theme.colors.global.primary};
  }
`;
const Text = styled.span`
  font-size: 12px;
  font-weight: 500;
`;

export const AutoCompoundTag: React.FC<AutoCompoundProps> = ({
  autoCompound,
  wordOnly = false,
  ...props
}) => {
  return (
    <StyledAutoCompoundTag
      autoCompound={autoCompound}
      variant={autoCompound ? variants.AUTO : variants.GRAY}
      {...props}
    >
      <Text>Auto-Compound {!wordOnly && (autoCompound ? "On" : "Off")}</Text>
    </StyledAutoCompoundTag>
  );
};

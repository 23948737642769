import { useCallback, useEffect } from "react";

export const useClickOutside = (
  refs: React.RefObject<HTMLElement>[],
  callback: (event: MouseEvent) => void
) => {
  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (
        refs.every(
          (ref) => ref.current && !ref.current.contains(e.target as Node)
        )
      ) {
        callback(e);
      }
    },
    [callback, refs]
  );

  useEffect(() => {
    document.addEventListener("click", handleClick, { capture: true });
    return () => {
      document.removeEventListener("click", handleClick, { capture: true });
    };
  }, [handleClick]);
};

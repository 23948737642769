import { useEffect, useState } from "react";
import styled from "styled-components";
import { ButtonText1 } from "../../Typography";
import { StyledBar, StyledItem, StyledSlider } from "../SharedTab";
import { ProMenuTabProps } from "./types";

const TabBar = styled(StyledBar)`
  position: relative;
  padding: 0px;
  width: 100%;
  padding: 6px;
  align-items: center;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.radii.round};
  background: ${({ theme }) => theme.colors.global.background.BG3};
`;
const TabItems = styled(StyledItem).attrs({ className: "itemOption" })<{
  isSelected: boolean;
  width: number;
}>`
  width: ${({ width }) => width}px;
  text-align: start;
  height: calc(100% - 8px);
  transition: color 0.3s linear;
  z-index: ${({ theme }) => theme.zIndices.tab};
  gap: ${({ theme }) => theme.spacing.sm};
  ${({ theme, isSelected }) =>
    isSelected
      ? `
      color: ${theme.colors.global.background.BG1};
      `
      : `
      color: ${theme.colors.global.text.T1};
      `}
`;

const TabSlider = styled(StyledSlider)<{
  left: number;
}>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: ${({ theme }) => theme.colors.global.text.T1};
  border-radius: ${({ theme }) => theme.radii.round};
  height: 32px;
  width: ${({ selectWidth }) => `${selectWidth}px`};
  margin: 0 6px;
  padding: 0;
  --switch-padding: 8px;
  --container-inner-width: ${({ maxWidth }) => `${maxWidth}px`};
  --switch-width: ${({ widthPercentage, length }) =>
    widthPercentage
      ? `calc(var(--container-inner-width) * ${widthPercentage} + var(--switch-padding) * 2)`
      : `calc(var(--container-inner-width) / ${length})`};
  left: ${({ selectedIndex, left }) =>
    selectedIndex === 0 ? `6px` : `${left + 10}px`};
`;

const StyledText = styled(ButtonText1)`
  font-size: 14px;
  line-height: 16px;
`;

export const SelectMenuTab: React.FC<ProMenuTabProps> = ({
  menu,
  isPro = [],
  defaultSelected = 0,
  maxWidth,
  selectWidth,
  itemsWidth,
  onSelect,
  containerPadding = "4px",
  ...props
}) => {
  const menuLength = menu.length ?? 1;
  const [selected, setSelected] = useState(
    defaultSelected < 0 || defaultSelected > menuLength ? 0 : defaultSelected
  );

  useEffect(() => {
    onSelect(menu[selected]);
  }, [onSelect, menu, selected]);

  return (
    <TabBar {...props}>
      <TabSlider
        left={itemsWidth[0]}
        maxWidth={maxWidth}
        selectedIndex={selected}
        selectWidth={selectWidth[selected]}
        length={menu.length}
        containerPadding={containerPadding}
      />
      {menu.map((item, index) => (
        <TabItems
          width={itemsWidth[index]}
          key={`${index}-${item}`}
          itemWidth={100 / menuLength}
          onClick={() => {
            setSelected(index);
          }}
          isSelected={index === selected}
        >
          <StyledText>{item}</StyledText>
        </TabItems>
      ))}
    </TabBar>
  );
};

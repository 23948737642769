import { viem } from "@aperture_finance/uniswap-v3-automation-sdk";
import { ReactNode } from "react";
import { ConnectionType } from "../../../utils";

export enum AuthStatus {
  AUTHORIZED = "authorized",
  UNAUTHORIZED = "unauthorized",
  LOADING = "loading",
}

export interface AuthInfo {
  address: string;
  balance: number | string | undefined;
  ticker: string;
}

export interface AuthUserProps extends ITabContents {
  authUser: AuthInfo;
  authStatus: AuthStatus;
  authKeepOpen?: boolean;
  onDisconnect: () => void;
  connectType?: ConnectionType;
  url?: string;
  onClick?: () => void;
  onAuthClick: () => void;
}

export type IPortfolioToken = {
  id: string;
  chainId: number;
  address: string | null;
  name: string;
  symbol: string;
  decimals: number | string;
  native: boolean;
  logo: ReactNode;
  price?: number | string;
  denominatedValue?: number | string;
  quantity?: number | string;
};

export type PortfolioBalances = {
  totalBalance: string;
  tokenBalances: Array<IPortfolioToken>;
};

export type ITabContents = {
  portfolioBalances: PortfolioBalances | undefined;
  activities: { [hash: string]: viem.Activity } | undefined;
  explorerURL: string;
};

import React from "react";
import styled from "styled-components";
import { color, compose, layout, space, typography } from "styled-system";
import { UITypographyProps } from "./types";

const StyledLogoTag = styled.span`
  font-size: 11px;
  font-weight: 500;
  ${space}
  ${layout}
  ${color}
`;
export const LogoTag: React.FC<React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  return <StyledLogoTag {...props}>{children}</StyledLogoTag>;
};

const StyledNavLinkText = styled.span`
  font-size: 18px;
  font-weight: 700;
  ${space}
  ${layout}
  ${color}
`;
export const NavLinkText: React.FC<React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  return <StyledNavLinkText {...props}>{children}</StyledNavLinkText>;
};

const StyledButtonText1 = styled.span`
  font-size: 14px;
  font-weight: 500;
  ${space}
  ${layout}
  ${color}
`;
export const ButtonText1: React.FC<
  React.PropsWithChildren<{ color?: string }>
> = ({ color, children, ...props }) => {
  return (
    <StyledButtonText1 color={color} {...props}>
      {children}
    </StyledButtonText1>
  );
};

const StyledButtonText2 = styled.span`
  font-size: 14px;
  font-weight: 600;
  ${space}
  ${layout}
  ${color}
`;
export const ButtonText2: React.FC<
  React.PropsWithChildren<{ color?: string }>
> = ({ color, children, ...props }) => {
  return (
    <StyledButtonText2 color={color} {...props}>
      {children}
    </StyledButtonText2>
  );
};

const StyledButtonText = styled.div<UITypographyProps>`
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  padding: 0 8px;
  ${compose(layout, space, typography)}
`;

export const ButtonText: React.FC<
  React.PropsWithChildren<UITypographyProps>
> = ({ children, ...props }) => (
  <StyledButtonText {...props}>{children}</StyledButtonText>
);
const StyledWalletAddress = styled.div<UITypographyProps>`
  font-family: "Roboto Flex";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.global.textAlt.T1};
  ${compose(layout, space, typography)}
`;

export const WalletText: React.FC<
  React.PropsWithChildren<UITypographyProps>
> = ({ children, ...props }) => (
  <StyledWalletAddress {...props}>{children}</StyledWalletAddress>
);
const StyledBalance = styled.div<UITypographyProps>`
  font-family: "Roboto Flex";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  ${compose(layout, space, typography)};
`;

export const BalanceText: React.FC<
  React.PropsWithChildren<UITypographyProps>
> = ({ children, ...props }) => (
  <StyledBalance {...props}>{children}</StyledBalance>
);

const StyledLabelText = styled.div<UITypographyProps>`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  ${compose(layout, space, typography)}
`;

export const LabelText: React.FC<
  React.PropsWithChildren<UITypographyProps>
> = ({ children, ...props }) => (
  <StyledLabelText {...props}>{children}</StyledLabelText>
);

const StyledCommonLgText = styled.div<UITypographyProps>`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  font-style: normal;
  ${compose(layout, space, typography)}
`;

export const CommonLgText: React.FC<
  React.PropsWithChildren<UITypographyProps>
> = ({ children, ...props }) => (
  <StyledCommonLgText {...props}>{children}</StyledCommonLgText>
);

const StyledCommonXlText = styled.div<UITypographyProps>`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  ${compose(layout, space, typography)}
`;

export const CommonXlText: React.FC<
  React.PropsWithChildren<UITypographyProps>
> = ({ children, ...props }) => (
  <StyledCommonXlText {...props}>{children}</StyledCommonXlText>
);

export const T1Regular = styled(CommonLgText)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const T3Regular = styled(CommonLgText)`
  color: ${({ theme }) => theme.colors.global.text.T3};
`;

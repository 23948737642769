import styled, { keyframes } from "styled-components";
import { Button4 } from "../../Typography";
import { NumericalInput } from "./NumericalInput";

const pulse = (color: string) => keyframes`
  0% {
    box-shadow: 0 0 0 0 ${color};
  }
  70% {
    box-shadow: 0 0 0 2px ${color};
  }
  100% {
    box-shadow: 0 0 0 0 ${color};
  }
`;

export const IGPlusMinus = styled.div<{
  active: boolean;
  pulsing: boolean;
  warning?: boolean;
  error?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
  padding: 16px 16px;
  border: ${({ active, warning, theme }) =>
    warning
      ? "1px solid " + theme.colors.errorWarning.orange
      : active
      ? "1px solid " + theme.colors.global.line.Line2
      : "1px solid " + theme.colors.global.line.Line1};
  border-radius: 10px;

  animation: ${({ warning, pulsing, theme }) =>
      !warning && pulsing && pulse(theme.colors.global.primary)}
    0.8s linear;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
`;

export const StyledNumericalInput = styled(NumericalInput)`
  height: 24px;
  padding: 8px 0px;
  font-size: 32px;
  line-height: 37.5px;
  letter-spacing: 0.0025em;
  align-items: center;
  text-align: center;
  border-radius: 0;
  border: none !important;
  -webkit-appearance: textfield;
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.global.text.T3 : theme.colors.global.text.T1};

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  [type="number"] {
    -moz-appearance: textfield;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: gray;
  }
`;

export const PlusMinusMark = styled.button<{ disabled: boolean }>`
  display: flex;
  width: 36px;
  height: 24px;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  border: none;
  border-radius: ${({ theme }) => theme.radii.md};
  background: ${({ theme }) => theme.colors.transparent.transparent};
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: ${({ disabled }) => (disabled ? "" : "pointer")};

  path {
    fill: ${({ disabled, theme }) =>
      disabled ? theme.colors.global.text.T3 : theme.colors.global.text.T1};
  }

  :hover {
    background: ${({ disabled, theme }) =>
      disabled
        ? theme.colors.transparent.transparent
        : theme.colors.global.background.BG2};
  }
  :active {
    background: ${({ disabled, theme }) =>
      disabled
        ? theme.colors.transparent.transparent
        : theme.colors.global.background.BG3};
  }
`;

export const Title = styled(Button4)`
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.global.text.T2};
  text-align: center;
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
`;

import React from "react";
import styled from "styled-components";
import { compose, layout, space, typography } from "styled-system";
import { UITypographyProps } from "./types";

const StyledT1 = styled.div<UITypographyProps>`
  font-family: "Roboto Flex";
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  ${compose(layout, space, typography)}
`;
export const T1: React.FC<React.PropsWithChildren<UITypographyProps>> = ({
  children,
  ...props
}) => <StyledT1 {...props}>{children}</StyledT1>;

const StyledT2 = styled.div<UITypographyProps>`
  font-family: "Roboto Flex";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  ${compose(layout, space, typography)}
`;
export const T2: React.FC<React.PropsWithChildren<UITypographyProps>> = ({
  children,
  ...props
}) => <StyledT2 {...props}>{children}</StyledT2>;

const StyledT3Medium = styled.div<UITypographyProps>`
  font-family: "Roboto Flex";
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  ${compose(layout, space, typography)}
`;
export const T3Medium: React.FC<React.PropsWithChildren<UITypographyProps>> = ({
  children,
  ...props
}) => <StyledT3Medium {...props}>{children}</StyledT3Medium>;

const StyledT3Regular = styled.div<UITypographyProps>`
  font-family: "Roboto Flex";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  ${compose(layout, space, typography)}
`;
export const T3Regular: React.FC<
  React.PropsWithChildren<UITypographyProps>
> = ({ children, ...props }) => (
  <StyledT3Regular {...props}>{children}</StyledT3Regular>
);

const StyledT4 = styled.div<UITypographyProps>`
  font-family: "Roboto Flex";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  ${compose(layout, space, typography)}
`;
export const T4: React.FC<React.PropsWithChildren<UITypographyProps>> = ({
  children,
  ...props
}) => <StyledT4 {...props}>{children}</StyledT4>;

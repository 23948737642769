import { FeeAmount } from "@aperture_finance/uniswap-v3-sdk";
import { RebalanceActionTab } from "@ui/common";
import { ScaleLinear } from "d3";
import { Dispatch, SetStateAction } from "react";

export enum Bound {
  LOWER = "LOWER",
  UPPER = "UPPER",
}

export interface ChartEntry {
  activeLiquidity: number;
  price0: number;
}

interface Dimensions {
  width: number;
  height: number;
}

interface Margins {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export interface ZoomLevels {
  initialMin: number;
  initialMax: number;
  min: number;
  max: number;
}

export interface LiquidityChartProps {
  // to distringuish between multiple charts in the DOM
  id?: string;
  data: {
    series: ChartEntry[];
    current: number;
  };
  ticksAtLimit: { [bound in Bound]?: boolean | undefined };

  styles: {
    area: {
      // color of the ticks in range
      selection: string;
    };
    brush: {
      handle: {
        west: string;
        east: string;
      };
    };
    currentBrush: {
      handle: {
        west: string;
        east: string;
      };
    };
  };

  dimensions: Dimensions;
  margins: Margins;
  interactive?: boolean;

  brushLabels: (d: "w" | "e", x: number) => string;
  brushDomain: [number, number] | undefined;
  onBrushDomainChange: (
    domain: [number, number],
    mode: string | undefined
  ) => void;

  zoomLevels: ZoomLevels;
  currentRange?: [number, number] | undefined;
  brushMinValue?: number;
  brushMaxValue?: number;
  brushBoundary?: number;
  axisSuffix?: string;
}

export interface RatioChartProps {
  id?: string;
  styles: {
    area: {
      // color of the ticks in range
      selection: string;
    };
    brush: {
      handle: string;
    };
  };
  dimensions: Dimensions;
  margins: Margins;
  interactive?: boolean;

  brushLabels: (d: "w" | "e", x: number) => string;
  brushDomain: [number, number] | undefined;
  onBrushDomainChange: (
    domain: [number, number],
    mode: string | undefined
  ) => void;
  brushMinValue?: number;
  brushMaxValue?: number;
  axisSuffix?: string;
}

export interface LiquidityDataProps {
  isLoading: boolean;
  error?: string;
  formattedData: ChartEntry[] | undefined;
}

export interface ChartRangeInputProps {
  // TODO: define correct type for currency
  currencyA: string | undefined;
  currencyB: string | undefined;
  feeAmount?: FeeAmount;
  ticksAtLimit: { [bound in Bound]?: boolean | undefined };
  price: number | undefined; // TODO: define correct types for price
  priceLower?: string;
  priceUpper?: string;
  warningMsg?: string;
  onLeftRangeInput: (typedValue: string) => void;
  onRightRangeInput: (typedValue: string) => void;
  interactive: boolean;
  chartHeight: number;
  currentRange?: [number, number] | undefined;
}

export interface LiquidityChartRangeInputProps extends ChartRangeInputProps {
  dataState: LiquidityDataProps;
}

export interface FutureSpotChartRangeInputProps extends ChartRangeInputProps {
  futureSpotTab?:
    | RebalanceActionTab.TOKEN_TERMS
    | RebalanceActionTab.PERCENTAGES;
  onSwitchFutureSpotTab?: Dispatch<
    SetStateAction<
      RebalanceActionTab.TOKEN_TERMS | RebalanceActionTab.PERCENTAGES
    >
  >;
}

export interface StrategyChartRangeInputProps extends ChartRangeInputProps {
  strategyTabPosition?: "top" | "bottom";
  showStrategyTab?: boolean;
  strategyTab?: RebalanceActionTab;
  onSwitchStrategyTab?: (tab: RebalanceActionTab) => void;
}

export interface BrushProps {
  id: string;
  xScale: ScaleLinear<number, number>;
  interactive: boolean;
  brushLabelValue: (d: "w" | "e", x: number) => string;
  brushExtent: [number, number];
  setBrushExtent: (extent: [number, number], mode: string | undefined) => void;
  innerWidth: number;
  innerHeight: number;
  westHandleColor: string;
  eastHandleColor: string;
  brushMinValue?: number;
  brushBoundary?: number;
}

export interface SingleBrushProps {
  id: string;
  xScale: ScaleLinear<number, number>;
  interactive: boolean;
  brushLabelValue: (d: "w" | "e", x: number) => string;
  brushExtent: [number, number];
  setBrushExtent: (extent: [number, number], mode: string | undefined) => void;
  innerWidth: number;
  innerHeight: number;
  handleColor: string;
  brushMinValue?: number;
  brushMaxValue?: number;
}

import { useRpcSettings } from "@/config/feature_flags";
import { getPublicClient } from "@/helper/getPublicClient";
import { useInitQueryValue } from "@/hooks/urls/useInitQueryValue";
import { useEventCallback } from "@/hooks/useEventCallback";
import { CommonQueryKey } from "@/utils/browserHistory";
import {
  DEFAULT_NETWORK,
  getChainDefaultAmm,
  getNetworkId,
  getNetworkName,
  isAmmValid,
  isChainIdValid,
} from "@/utils/networkHelper";
import { useUrlState } from "@/views/NewPosition/hooks";
import {
  AmmEnum,
  AmmInfo,
  BasicInfo,
  E_AMM,
  SupportedChainId,
  getAmmEnum,
} from "@aperture/uikit";
import { ApertureSupportedChainId } from "@aperture_finance/uniswap-v3-automation-sdk";
import { usePathname, useSearchParams } from "next/navigation";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { PublicClient } from "viem";
import { useAccount } from "wagmi";
import { useURLContext } from "../Context/URLContext";

interface NetworkContextType {
  amm: BasicInfo; // Automated Market Maker
  ammEnum: AmmEnum;
  network: SupportedChainId;
  networkId: ApertureSupportedChainId;
  publicClient: PublicClient;
  setNetwork: (amm: BasicInfo, chain: SupportedChainId) => void;
  isChainSupported: boolean;
}

const NetworkContext = createContext<NetworkContextType>({
  amm: undefined,
  ammEnum: "UNISWAP_V3",
  network: undefined,
  networkId: undefined,
  publicClient: undefined,
  setNetwork: () => {},
  isChainSupported: true,
});

export const useNetwork = () => {
  const context = useContext(NetworkContext);
  if (!context) {
    throw new Error("useNetwork must be used within a NetworkProvider");
  }
  return context;
};

interface NetworkProviderProps {
  children: ReactNode;
}

export const NetworkProvider: React.FC<NetworkProviderProps> = ({
  children,
}) => {
  const { isConnected, chain } = useAccount();
  const chainIdRef = useRef(chain?.id);
  const pathname = usePathname();
  const { ammCache, networkId, setNetwork } = useSwitchNetwork(chain?.id);
  const ammEnum = getAmmEnum(ammCache);

  const isChainSupported = useMemo(() => {
    if (isConnected && chain) {
      if (ammEnum === "SLIPSTREAM") {
        if (
          pathname.startsWith("/limit-orders") ||
          pathname.startsWith("/swap")
        ) {
          return false;
        }
      } else if (
        pathname.startsWith("/limit-orders") ||
        pathname.startsWith("/recurring-rebalance")
      ) {
        return (
          chain.id !== getNetworkId(SupportedChainId.MANTA) &&
          chain.id === networkId
        );
      }
      return chain.id === networkId;
    } else {
    }
    return true;
  }, [isConnected, chain, ammEnum, pathname, networkId]);

  useEffect(() => {
    // switch network when wallet connected or chain changed
    if (
      !isConnected ||
      !chain ||
      !chain.id ||
      chainIdRef.current === chain.id
    ) {
      return;
    }
    chainIdRef.current = chain.id;
    if (isChainIdValid(chain.id)) {
      setNetwork(chain.id);
    }
  }, [chain, isConnected, setNetwork, ammCache]);

  const _setNetwork = useCallback(
    (amm: BasicInfo, chain: SupportedChainId) => {
      setNetwork(getNetworkId(chain), amm.name as E_AMM);
    },
    [setNetwork]
  );

  const rpcSetting = useRpcSettings();
  const publicClient = useMemo(() => {
    return networkId && getPublicClient(networkId, rpcSetting);
  }, [networkId, rpcSetting]);

  return (
    <NetworkContext.Provider
      value={{
        amm: AmmInfo[ammCache],
        ammEnum,
        network: getNetworkName(networkId),
        networkId,
        publicClient,
        setNetwork: _setNetwork,
        isChainSupported,
      }}
    >
      {children}
    </NetworkContext.Provider>
  );
};

const useSwitchNetwork = (chainId: number) => {
  const defaultNetworkId = isChainIdValid(chainId)
    ? chainId
    : getNetworkId(DEFAULT_NETWORK);
  const [networkId, setNetworkId] = useState<number>(defaultNetworkId);
  const { updateUrlQuery } = useURLContext();

  const initChainIdStr = useInitQueryValue(CommonQueryKey.CHAIN_ID);
  const initAmmStr = useInitQueryValue(CommonQueryKey.AMM);

  const [amm, setAmm] = useUrlState<E_AMM>(CommonQueryKey.AMM);

  const setNetwork = useCallback(
    (networkId: ApertureSupportedChainId, ammCache?: E_AMM) => {
      setNetworkId(networkId);
      if (ammCache) {
        setAmm(ammCache);
      }
    },
    [setAmm]
  );

  useEffect(() => {
    if (isChainIdValid(initChainIdStr)) {
      const timerId = setTimeout(() => {
        // wait after wallet connected
        setNetwork(Number(initChainIdStr), initAmmStr as E_AMM);
      }, 900);
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [initAmmStr, initChainIdStr, setNetwork]);

  const onNetworkChange = useEventCallback((_networkId: number) => {
    updateUrlQuery(CommonQueryKey.CHAIN_ID, _networkId.toString());
    if (!isAmmValid(_networkId, amm)) {
      setAmm(getChainDefaultAmm(_networkId));
    } else {
      updateUrlQuery(CommonQueryKey.AMM, amm);
    }
  });

  const pathname = usePathname();
  const searchParams = useSearchParams();

  const handlePathnameChange = useEventCallback(() => {
    if (!searchParams.get(CommonQueryKey.CHAIN_ID)) {
      if (networkId) {
        onNetworkChange(networkId);
      }
    } else {
      setNetwork(
        Number(searchParams.get(CommonQueryKey.CHAIN_ID)),
        searchParams.get(CommonQueryKey.AMM) as E_AMM
      );
    }
  });

  useEffect(() => {
    // update network in url when pathname changed
    handlePathnameChange();
  }, [pathname, handlePathnameChange]);

  useEffect(() => {
    onNetworkChange(networkId);
  }, [networkId, onNetworkChange]);

  return {
    ammCache: amm,
    networkId,
    setNetwork,
  };
};

import { Flex, FlexColumn } from "@ui/common";
import { WhiteCardUIProps } from "@ui/components/Card/types";
import { FeeInfoCard } from "@ui/components/InfoCard";
import styled from "styled-components";
import {
  FlexboxProps,
  LayoutProps,
  compose,
  flexbox,
  grid,
  layout,
  space,
} from "styled-system";
import { formatUSDAmount } from "../../../utils/numberFormat";
import { TokenInfo } from "../../TokenInfo";
import { Headline3, Headline5 } from "../../Typography";
import { IReinvestFeesContentProps } from "../types";

const ContentWrapper = styled(FlexColumn)<FlexboxProps>`
  position: relative;
  width: 560px;
  gap: ${({ theme }) => theme.spacing.xl};
  ${flexbox}
`;
const ModalTitle = styled(Headline5)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const Title = styled(Headline5)`
  color: ${({ theme }) => theme.colors.global.text.T2};
`;
const USDAmount = styled(Headline3)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

const StyledCard = styled(Flex)<WhiteCardUIProps>`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.lg};
  ${compose(flexbox, grid, layout, space)}
`;

const TokenInfoContainer = styled.div<LayoutProps>`
  ${layout}
`;

const DividerHorizontal = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.global.line.Line2};
  align-self: stretch;
`;

const DividerVertical = styled.div`
  ::after {
    position: absolute;
    top: 0;
    bottom: 0;
    content: "";
    width: 1px;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.global.line.Line2};
  }
`;

const StyledTokenInfo = styled(TokenInfo)`
  margin-bottom: 8px;
`;

export const ReinvestFeesContent: React.FC<IReinvestFeesContentProps> = ({
  amm,
  liquidity,
  feeAmount,
  tokens,
  unClaimedFees,
  predictedLiquidity,
  predictedProjection,
  fee,
}) => {
  return (
    <ContentWrapper>
      <ModalTitle fontFamily={"Dunbar Tall"} fontSize={24} lineHeight={"32px"}>
        Reinvest Fees
      </ModalTitle>
      <ContentWrapper flexDirection={"row"}>
        <StyledCard flexDirection={"column"}>
          <Title lineHeight={"20px"}>Liquidity</Title>
          <USDAmount fontSize={32} lineHeight={"37.5px"}>
            {formatUSDAmount(liquidity)}
          </USDAmount>
          <TokenInfoContainer>
            {tokens.map((tokenItem, index) => {
              return (
                <TokenInfo
                  marginBottom={8}
                  key={`Reinvest-Fees-${tokenItem.address}-${tokenItem.native}-${index}`}
                  Icon={tokenItem.Icon}
                  ticker={tokenItem.ticker}
                  amount={tokenItem.amount}
                  price={tokenItem.price}
                  address={tokenItem.address}
                  native={tokenItem.native}
                  decimals={tokenItem.decimals}
                  tag={`${tokenItem.tag}%`}
                  minWidth={257}
                  maxWidth={999}
                />
              );
            })}
          </TokenInfoContainer>
        </StyledCard>
        <DividerVertical />
        <StyledCard flexDirection={"column"}>
          <Title lineHeight={"20px"}>Unclaimed Fees</Title>
          <USDAmount fontSize={32} lineHeight={"37.5px"}>
            {formatUSDAmount(feeAmount)}
          </USDAmount>
          <TokenInfoContainer>
            {tokens.map((tokenItem, index) => {
              return (
                <TokenInfo
                  marginBottom={8}
                  key={`Unclaimed-Fees-${tokenItem.address}-${tokenItem.native}-${index}`}
                  Icon={tokenItem.Icon}
                  ticker={tokenItem.ticker}
                  amount={unClaimedFees[index]}
                  price={tokenItem.price}
                  address={tokenItem.address}
                  native={tokenItem.native}
                  decimals={tokenItem.decimals}
                  minWidth={257}
                  maxWidth={999}
                />
              );
            })}
          </TokenInfoContainer>
        </StyledCard>
      </ContentWrapper>
      <DividerHorizontal />
      <StyledCard flexDirection={"column"}>
        <Title lineHeight={"20px"}>Projected Liquidity</Title>
        <USDAmount fontSize={32} lineHeight={"37.5px"}>
          {formatUSDAmount(predictedLiquidity)}
        </USDAmount>
        <TokenInfoContainer>
          {tokens.map((tokenItem, index) => {
            return (
              <StyledTokenInfo
                marginBottom={8}
                key={`Projected-Liquidity-${tokenItem.address}-${tokenItem.native}-${index}`}
                Icon={tokenItem.Icon}
                ticker={tokenItem.ticker}
                amount={predictedProjection[index].amount}
                price={tokenItem.price}
                address={tokenItem.address}
                native={tokenItem.native}
                decimals={tokenItem.decimals}
                maxWidth={999}
                tag={`${predictedProjection[index].percent}%`}
              />
            );
          })}
        </TokenInfoContainer>
      </StyledCard>
      <DividerHorizontal />
      <StyledCard>
        <FeeInfoCard
          styles={{ size: "xl" }}
          amm={amm}
          feeInfo={{ fee, amount: feeAmount, active: true }}
          hint={`${fee * 100}% of the reinvested amount.`}
        />
      </StyledCard>
    </ContentWrapper>
  );
};

import { QuestionMarkIcon } from "@aperture/assetkit";
import React from "react";
import styled, { useTheme } from "styled-components";
import { Popover } from ".";
import { IQuestionPopoverProps } from "./types";

export const StyledPopover = styled(Popover)<{ fill?: string }>`
  display: inline-block !important;
  svg {
    fill: ${({ theme, fill }) => (fill ? fill : theme.colors.global.text.T2)};
  }
`;

export const QuestionPopover: React.FC<IQuestionPopoverProps> = ({
  content,
  defaultPosition,
  defaultSize,
  inActive,
  fill,
  iconSize = 12,
  ...props
}) => {
  const theme = useTheme();
  const fillColor = inActive ? theme.colors.grey.mediumGrey : fill;

  return (
    <StyledPopover
      {...{ content, defaultPosition, defaultSize, fill: fillColor }}
    >
      <QuestionMarkIcon height={iconSize} width={iconSize} {...props} />
    </StyledPopover>
  );
};

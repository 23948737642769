import { FlexColumn } from "@ui/common";
import styled, { useTheme } from "styled-components";
import {
  HistoryDate,
  HistoryDelete,
  HistoryTitle,
  HistoryWrapper,
} from "./style";

interface IIntentsGptHistoryListProps {
  histories: IntentsGptHistory[];
  onClick: (chatId: string) => void;
  onDelete: (chatId: string) => void;
}

export interface IntentsGptHistory {
  date: Date;
  chatId: string;
  message: string;
}

export const HistoryList: React.FC<IIntentsGptHistoryListProps> = ({
  histories,
  onClick,
  onDelete,
}) => {
  const today = new Date();
  const theme = useTheme();
  return (
    <ListWrapper>
      {histories.map((history, idx) => (
        <FlexColumn gap="lg" key={idx}>
          {(!idx ||
            history.date.getDate() !== histories[idx - 1].date.getDate()) && (
            <HistoryDate>
              {history.date.getDate() === today.getDate() &&
              history.date.getMonth() === today.getMonth() &&
              history.date.getFullYear() === today.getFullYear()
                ? "Today"
                : `${
                    history.date.toLocaleString("default", { month: "long" }) +
                    " " +
                    history.date.getDate()
                  }`}
            </HistoryDate>
          )}

          <FlexColumn gap={6}>
            <HistoryWrapper
              key={history.chatId}
              onClick={() => onClick(history.chatId)}
            >
              <HistoryTitle>{history.message}</HistoryTitle>
              <HistoryDelete
                fill={theme.colors.global.primary}
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(history.chatId);
                }}
              />
            </HistoryWrapper>
          </FlexColumn>
        </FlexColumn>
      ))}
    </ListWrapper>
  );
};

const ListWrapper = styled(FlexColumn)`
  gap: 6px;
  max-height: 70vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.colors.global.background.BG2}
    transparent;
`;

import { TokenPriceMap } from "@/hooks/useFetchAllChainsTokensPrice";
import { CHAIN_USDC_ADDRESS } from "@/utils/networkHelper";
import {
  ApertureSupportedChainId,
  getLogger,
  getTokenPriceListFromCoingeckoWithAddresses,
  getTokenPriceListWithAddresses,
  viem,
} from "@aperture_finance/uniswap-v3-automation-sdk";
import { Token } from "@uniswap/sdk-core";
import bigDecimal from "js-big-decimal";
import _ from "lodash";
import { parseUnits } from "viem";
import { requestQueue } from "./RequestQueue";

interface CacheEntry {
  prices: TokenPriceMap;
  timestamp: number;
}

const TOKEN_PRICE_CACHE = new Map<string, CacheEntry>();
const CACHE_EXPIRY_TIME = 10 * 60 * 1000; // 10 minutes in milliseconds

export const fetchTokenPrices = async (
  chainId: ApertureSupportedChainId,
  tokens: Token[],
  defaultOkxTokens: string[] = []
): Promise<TokenPriceMap> => {
  // Check cache first
  const { cachedPrices, tokensToFetch } = getValidCachedPrices(chainId, tokens);

  // If all prices are cached, return immediately
  if (tokensToFetch.length === 0) {
    return cachedPrices;
  }

  // Fetch prices for tokens not in cache
  const tokenAddresses = tokensToFetch.map((token) => token.address);
  const coingeckoPrices = await fetchCoingeckoPrices(chainId, tokenAddresses);

  // Initialize price map and track tokens without prices
  const noPriceTokens = new Map<string, Token>();
  const priceMap: TokenPriceMap = { ...cachedPrices };

  // Process each token that needed fetching
  for (const token of tokensToFetch) {
    const coingeckoPrice =
      coingeckoPrices[token.address.toLowerCase()]?.toString() ?? "";

    priceMap[token.address] = { price: coingeckoPrice };

    // Track tokens that need routing API prices
    if (
      (!coingeckoPrice && Object.keys(coingeckoPrices).length) ||
      !token.address
    ) {
      noPriceTokens.set(token.address, token);
    }
  }

  // Get missing prices from routing API
  if (noPriceTokens.size) {
    const routingApiPrices = await getTokenPriceMapFromRoutingApi(
      chainId,
      noPriceTokens,
      defaultOkxTokens
    );

    // Update price map with routing API prices
    for (const [address, price] of Object.entries(routingApiPrices)) {
      if (price.price) {
        priceMap[address] = price;
      }
    }
  }

  // Update cache with new prices
  tokensToFetch.forEach((token) => {
    const cacheKey = getCacheKey(chainId, token.address);
    if (priceMap[token.address]) {
      TOKEN_PRICE_CACHE.set(cacheKey, {
        prices: { [token.address]: priceMap[token.address] },
        timestamp: Date.now(),
      });
    }
  });

  return priceMap;
};

function getCacheKey(
  chainId: ApertureSupportedChainId,
  tokenAddress: string
): string {
  return `${chainId}-${tokenAddress.toLowerCase()}`;
}

function getValidCachedPrices(
  chainId: ApertureSupportedChainId,
  tokens: Token[]
): {
  cachedPrices: TokenPriceMap;
  tokensToFetch: Token[];
} {
  const now = Date.now();
  const cachedPrices: TokenPriceMap = {};
  const tokensToFetch: Token[] = [];

  tokens.forEach((token) => {
    const cacheKey = getCacheKey(chainId, token.address);
    const cacheEntry = TOKEN_PRICE_CACHE.get(cacheKey);

    if (cacheEntry && now - cacheEntry.timestamp < CACHE_EXPIRY_TIME) {
      Object.assign(cachedPrices, cacheEntry.prices);
    } else {
      tokensToFetch.push(token);
    }
  });

  return { cachedPrices, tokensToFetch };
}

async function fetchCoingeckoPrices(
  chainId: ApertureSupportedChainId,
  tokenAddresses: string[]
): Promise<Record<string, number>> {
  if (tokenAddresses.length === 0) return {};

  // Split tokens into chunks of 150 (Coingecko's limit)
  const CHUNK_SIZE = 150;
  const tokenChunks = _.chunk(tokenAddresses, CHUNK_SIZE);
  const allPrices: Record<string, number> = {};

  const requests = tokenChunks.map((chunk, index) => {
    return async () => {
      try {
        const prices = await getTokenPriceListWithAddresses(chainId, chunk);
        return prices;
      } catch (error) {
        getLogger().error("Coingecko.FetchPrices.Error", {
          chainId,
          chunkIndex: index,
          error: (error as Error).message,
        });
        return {};
      }
    };
  });

  const results = await requestQueue.enqueueBatch(requests, 3);

  Object.assign(allPrices, ...results);

  return allPrices;
}

async function getTokenPriceMapFromRoutingApi(
  chainId: ApertureSupportedChainId,
  tokenMap: Map<string, Token>,
  defaultOkxTokens: string[] = []
): Promise<TokenPriceMap> {
  const priceMap: TokenPriceMap = {};
  const errors: Error[] = [];

  // Process tokens in batches
  const tokens = Array.from(tokenMap.entries());

  const requests = tokens.map(([address, token]) => {
    return async () => {
      try {
        const price = await getTokenPriceFromRoutingApi(
          chainId,
          token,
          defaultOkxTokens.includes(address)
        );

        return { address, price };
      } catch (error) {
        errors.push(error as Error);
        return { address, price: "" };
      }
    };
  });

  const results = await requestQueue.enqueueBatch(requests, 5);

  results.forEach((result) => {
    if (result.price) {
      priceMap[result.address] = { price: result.price };
    }
  });

  // Log errors if any occurred
  if (errors.length > 0) {
    getLogger().error("TokenPrice.RoutingApi.BatchErrors", {
      chainId,
      errorCount: errors.length,
      errors: errors.map((e) => e.message),
    });
  }

  return priceMap;
}

// Optimize getTokenPriceFromRoutingApi with better error handling and retries
export async function getTokenPriceFromRoutingApi(
  chainId: ApertureSupportedChainId,
  token: Token,
  defaultOkxQuote: boolean = false
): Promise<string> {
  const usdcAddress = CHAIN_USDC_ADDRESS[chainId].id;
  const usdcDecimals = CHAIN_USDC_ADDRESS[chainId].decimals;

  if (token.address === usdcAddress) {
    return "1";
  }

  const rawAmount = parseUnits("0.01", usdcDecimals);
  let quoteDecimals = "0";

  try {
    if (defaultOkxQuote) {
      throw new Error("Should quote from OKX.");
    }

    // Try routing API first
    const quote = await viem.fetchQuoteFromRoutingApi(
      chainId,
      token.address,
      usdcAddress,
      rawAmount,
      "exactOut"
    );
    quoteDecimals = quote.quoteDecimals;
  } catch (error) {
    // Fallback to OKX quote
    try {
      quoteDecimals = await getOkxQuotePrice(
        chainId,
        token,
        rawAmount,
        usdcAddress
      );
    } catch (okxError) {
      getLogger().error("TokenPrice.AllQuotesFailed", {
        chainId,
        token: token.address,
        error: (okxError as Error).message,
      });
      return "";
    }
  }

  return bigDecimal.compareTo(quoteDecimals, 0) === 0
    ? ""
    : bigDecimal.divide(
        1,
        bigDecimal.multiply(quoteDecimals, 100),
        token.decimals
      );
}

const getOkxQuotePrice = async (
  chainId: ApertureSupportedChainId,
  token: Token,
  rawAmount: bigint,
  usdcAddress: string
) => {
  try {
    const quote = await viem.getOkxQuote(
      chainId,
      usdcAddress,
      token.address,
      rawAmount.toString()
    );

    return bigDecimal.divide(
      quote.toAmount,
      Math.pow(10, token.decimals),
      token.decimals
    );
  } catch (error) {
    getLogger().error("TokenPrice.GetOkxQuote.Error", {
      chainId,
      token: token.address,
      error: (error as Error).message,
    });
    return "0";
  }
};

export async function getTokenPrice(
  chainId: ApertureSupportedChainId,
  token: Token,
  defaultOkxQuote: boolean = false
): Promise<TokenPriceMap> {
  let price = "";
  const priceMapKey = token.address;
  if (priceMapKey) {
    const priceMap = await getTokenPriceListFromCoingeckoWithAddresses(
      chainId,
      [priceMapKey]
    );
    price = priceMap?.[priceMapKey.toLowerCase()]?.toString();
  }
  if (!price) {
    price = await getTokenPriceFromRoutingApi(chainId, token, defaultOkxQuote);
  }
  return {
    [token.address]: {
      price: price,
    },
  };
}

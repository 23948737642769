import {
  DeleteIcon,
  EditIcon,
  ExchangeIcon,
  WarningIcon,
} from "@aperture/assetkit";
import { Flex, FlexColumn } from "@ui/common/styles/global";
import styled from "styled-components";
import { Tag } from "../Tag";
import { Body1, Subtitle2 } from "../Typography";

export const TriggerGroupContainer = styled(FlexColumn)`
  padding: 16px;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.radii.md2};
  background: ${({ theme }) => theme.colors.global.background.BG3};
  gap: ${({ theme }) => theme.spacing.xl};
`;

export const Head = styled(Flex)`
  height: 24px;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled(Subtitle2)`
  line-height: 16px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const IconWrapper = styled(Flex)`
  margin: auto;
  margin-left: ${({ theme }) => theme.spacing.md};
`;

export const GoToButton = styled.button`
  display: flex;
  margin-right: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  background: ${({ theme }) => theme.colors.transparent.transparent};
  border: none;
  color: ${({ theme }) => theme.colors.global.text.T1};
  :hover {
    cursor: pointer;
  }
`;

export const StyledTag = styled(Tag)`
  path {
    stroke: ${({ theme }) => theme.colors.grey.white};
  }
`;

export const StyledWarningIcon = styled(WarningIcon)`
  path {
    stroke: ${({ theme }) => theme.colors.global.error};
  }
`;

export const Content = styled(FlexColumn)`
  padding: 16px;
  gap: ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.radii.md2};
  color: ${({ theme }) => theme.colors.global.text.T1};
  background: ${({ theme }) => theme.colors.global.background.BG4};
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.lg};
  cursor: pointer;
`;

export const ExpiredTag = styled(Flex)`
  background: ${({ theme }) => theme.colors.grey.offWhite};
  color: ${({ theme }) => theme.colors.errorWarning.orange};
  border-radius: ${({ theme }) => theme.radii.round};
  padding: 4px 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  svg {
    path {
      stroke: ${({ theme }) => theme.colors.errorWarning.orange};
    }
    height: 14px;
    width: 14px;
    flex-shrink: 0;
    margin-right: 5px;
  }
`;

export const EditBtn = styled.div`
  color: ${({ theme }) => theme.colors.primary.purple};
  border: 1px solid ${({ theme }) => theme.colors.primary.purple};
  border-radius: ${({ theme }) => theme.radii.round};
  padding: 8px;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  path {
    fill: ${({ theme }) => theme.colors.global.primary};
  }
`;
export const StyledEditIcon = styled(EditIcon)`
  path {
    fill: ${({ theme }) => theme.colors.global.primary};
  }
`;

export const Info = styled(Body1)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 16px;
  gap: ${({ theme }) => theme.spacing.md};
`;

export const Label = styled(Body1)`
  line-height: 16px;
  color: ${({ theme }) => theme.colors.global.text.T3};
`;

export const Value = styled.div<{ showBubble?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme, showBubble }) =>
    showBubble ? theme.spacing.md : theme.spacing.md};
  span + span {
    margin-left: 16px;
  }
`;

export const SwapButtonWrapper = styled(Flex)`
  height: 16px;
  align-items: center;
  cursor: pointer;
`;

export const SwapButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.radii.sm};
  border: 1px solid ${({ theme }) => theme.colors.grey.mediumGrey};
  background: ${({ theme }) => theme.colors.transparent.transparent};
`;

export const SwapButton2 = styled.button`
  width: 20px;
  height: 20px;
  padding: 0;
  margin-top: -2px;
  cursor: pointer;
  border: none;
  background: none;
`;

export const SwapButtonIcon = styled(ExchangeIcon)`
  transform: rotate(0);
  transition: transform ${({ theme }) => theme.transitions.default} ease;
  fill: ${({ theme }) => theme.colors.global.primary};
`;

export const TimeZone = styled(Body1)`
  line-height: 16px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

import { HandSuccessIcon } from "@aperture/assetkit";
import { FlexColumn } from "@ui/common";
import { formatTokenAmount } from "@ui/utils";
import styled, { useTheme } from "styled-components";
import { StakeRedeemProps } from "../types";

const Container = styled(FlexColumn)`
  padding: 28px 0px 18px;
  width: 320px;
`;
const Title = styled.div`
  font-family: Dunbar Tall;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  text-align: center;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const Text = styled.div`
  font-family: Roboto Flex;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.colors.global.text.T2};
`;

export const StakeRedeemContent = ({ tokens }: StakeRedeemProps) => {
  const theme = useTheme();
  return (
    <Container gap="3xl" alignItems="center">
      <HandSuccessIcon
        fill={theme.colors.global.text.T1}
        highlightFill={theme.colors.global.primary}
      />
      <FlexColumn gap="lg">
        <Title>Congratulations</Title>
        <FlexColumn>
          <Text>
            {`You have redeemed ${formatTokenAmount(tokens?.[0].amount)} ${
              tokens?.[0].ticker
            }${tokens?.[1] ? " and" : "!"}`}
          </Text>
          {tokens?.[1] && (
            <Text>
              {`${formatTokenAmount(tokens?.[1].amount)} ${
                tokens?.[1].ticker
              }!`}
            </Text>
          )}
        </FlexColumn>
      </FlexColumn>
    </Container>
  );
};

import { IToken } from "@aperture/types";
import {
  ApertureSupportedChainId,
  PriceCondition,
  TriggerItem,
  parsePrice,
} from "@aperture_finance/uniswap-v3-automation-sdk";
import { InfoPopover } from "@ui/components/Popover";
import { formatTokenAmount } from "@ui/utils/numberFormat";
import { Token } from "@uniswap/sdk-core";
import Big from "big.js";
import bigDecimal from "js-big-decimal";
import { useState } from "react";
import { convertRelativePriceToHumanValue, formatDuration } from "./utils";

interface RelativePriceProps {
  trigger: TriggerItem;
  tokens: IToken[];
  chainId: ApertureSupportedChainId;
  popoverText: string;
}
export const RelativePriceInfo: React.FC<RelativePriceProps> = ({
  trigger,
  tokens,
  chainId,
  popoverText,
}) => {
  const [swap, setSwap] = useState(false);
  const token0 = new Token(
    chainId,
    tokens[0].address,
    tokens[0].decimals,
    tokens[0].ticker,
    tokens[0].name
  );
  const token1 = new Token(
    chainId,
    tokens[1].address,
    tokens[1].decimals,
    tokens[1].ticker,
    tokens[1].name
  );
  const { durationSec, gte, lte } = trigger.condition as PriceCondition;
  const duration = formatDuration(durationSec);
  const amount = (gte || lte) as string;
  const aboveOrBelow = gte ? "Above" : "Below";
  const humanValue = convertRelativePriceToHumanValue(amount, tokens);

  let invertPrice = new Big(0);
  if (bigDecimal.compareTo(humanValue, 0) !== 0) {
    const price = parsePrice(
      token0,
      token1,
      bigDecimal.multiply(humanValue, "1.0")
    );
    invertPrice = new Big(
      bigDecimal.divide(
        price.invert().numerator.toString(),
        price.invert().denominator.toString(),
        18
      )
    );
  }

  return (
    <>
      {swap
        ? `${token1.symbol} Price ${
            aboveOrBelow === "Above" ? "Below" : "Above"
          } ${formatTokenAmount(
            convertRelativePriceToHumanValue(invertPrice.toString(), [
              tokens[1],
              tokens[0],
            ])
          )} ${token0.symbol} ${duration}`
        : `${token0.symbol} Price ${aboveOrBelow} ${formatTokenAmount(
            humanValue
          )} ${token1.symbol} ${duration}`}
      <InfoPopover
        content={popoverText}
        defaultPosition={{ top: 20, right: 16 }}
      />
    </>
  );
};

import { Flex, FlexColumn } from "@ui/common/styles/global";
import { TriggerType } from "@ui/common/types/Strategy";
import { IPureStrategyTriggerDetailProps, InfoPopover } from "@ui/components";
import bigDecimal from "js-big-decimal";
import React from "react";
import {
  BodyContainer,
  Container,
  EditorButton,
  IconWrapper,
  ItemValue,
  Title,
  TriggerRangeItem,
} from "./style";

export const PureStrategyTriggerDetail: React.FC<
  IPureStrategyTriggerDetailProps
> = ({
  token,
  above,
  below,
  aboveRange,
  belowRange,
  triggerType,
  timeBuffer,
  onClickEdit,
}) => {
  const labelAbove =
    triggerType === TriggerType.RATIO
      ? strings.ratioAboveLabel
      : strings.priceAboveLabel;
  const labelBelow =
    triggerType === TriggerType.RATIO
      ? strings.ratioBelowLabel
      : strings.priceBelowLabel;

  const triggerTitle = {
    [TriggerType.RATIO]: "Trigger by Ratio",
    [TriggerType.PRICE]: "Trigger by Token Price",
    [TriggerType.PRICE_PERCENTAGE]: "Trigger by Percentage",
  }[triggerType];

  const renderConfig = () => (
    <FlexColumn gap="xl">
      <Flex alignItems="center" justifyContent="space-between">
        {strings.timeBufferLabel}
        <Flex gap="sm" alignItems="center">
          <ItemValue>
            {timeBuffer
              ? `${timeBuffer} ${
                  bigDecimal.compareTo(timeBuffer, 1) > 0 ? "hrs" : "hr"
                }`
              : "None"}
          </ItemValue>
          {timeBuffer > 0 && (
            <InfoPopover
              content={strings.timeBufferPopover}
              iconSize={14}
              style={{ marginTop: "2px" }}
              defaultSize={{ width: 500 }}
              defaultPosition={{ top: 0, right: 20 }}
            />
          )}
        </Flex>
      </Flex>
    </FlexColumn>
  );

  return (
    <Container>
      <Flex alignItems="center" justifyContent="space-between">
        <Title>{strings.title}</Title>
        <EditorButton
          variant="contained"
          color="secondary"
          size="sm"
          onClick={onClickEdit}
        >
          {strings.editButtonLabel}
        </EditorButton>
      </Flex>
      <BodyContainer>
        {below && (
          <FlexColumn gap="xl" width="50%">
            <Title>Trigger 1</Title>
            <Flex justifyContent="space-between">
              {triggerTitle}
              <TriggerRangeItem>
                <IconWrapper>{token.Icon}</IconWrapper>
                <ItemValue>{token.ticker}</ItemValue>
                <ItemValue>{below}</ItemValue>
                <ItemValue>{labelBelow}</ItemValue>
              </TriggerRangeItem>
            </Flex>
            {belowRange && (
              <Flex justifyContent="space-between">
                {strings.rebalanceRangeLabel}
                <FlexColumn gap="md">{belowRange}</FlexColumn>
              </Flex>
            )}
            <>{renderConfig()}</>
          </FlexColumn>
        )}
        {above && (
          <FlexColumn gap="xl" width="50%">
            <Title>Trigger {below ? 2 : 1}</Title>
            <Flex justifyContent="space-between">
              {triggerTitle}
              <TriggerRangeItem>
                <IconWrapper>{token.Icon}</IconWrapper>
                <ItemValue> {token.ticker}</ItemValue>
                <ItemValue>{above}</ItemValue>
                <ItemValue>{labelAbove}</ItemValue>
              </TriggerRangeItem>
            </Flex>
            {aboveRange && (
              <Flex justifyContent="space-between">
                {strings.rebalanceRangeLabel}
                <FlexColumn gap="md">{aboveRange}</FlexColumn>
              </Flex>
            )}
            <>{renderConfig()}</>
          </FlexColumn>
        )}
      </BodyContainer>
    </Container>
  );
};

const strings = {
  title: "Trigger Setup",
  priceAboveLabel: "Above Spot Price",
  priceBelowLabel: "Below Spot Price",
  ratioAboveLabel: "Ratio Above",
  ratioBelowLabel: "Ratio Below",
  editButtonLabel: "Edit Trigger",
  rebalanceRangeLabel: "Rebalance Range",
  timeBufferLabel: "TWAP Duration",
  timeBufferPopover:
    "Enabling TWAP (time-weight average price) on your trigger means the average price or ratio based trigger between past specified time and now must be met before the trigger executes. This helps prevent potential price manipulation and examples are for rebalancing if 1) average ETH goes above $3,500 for past 30+ mins or 2) average pool above 80% USDC for past 2+ hrs.",
};

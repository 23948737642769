import { IToken, ITokenPair } from "@aperture/types";
import { RecurringAction } from "@aperture_finance/uniswap-v3-automation-sdk";
import { TriggerType } from "@ui/common/types/Strategy";

export interface IStrategyTriggerDetailProps {
  tokens: ITokenPair;
  trigger: IDualTriggerState;
  onEdit: () => void;
}
export enum StrategyTriggerEditType {
  ABOVE = "above",
  BELOW = "below",
}
export interface IEditTriggerDetailProps {
  type: StrategyTriggerEditType;
  triggerIdx: IEditTriggerSetupProps["triggerIdx"];
  tokens: ITokenPair;
  trigger: IEditTriggerState;
  onEdit: () => void;
  onDelete: () => void;
  onAddTrigger: () => void;
  disabled?: boolean;
}
export interface IPureStrategyTriggerDetailProps {
  token: IToken;
  above: string;
  below: string;
  aboveRange?: JSX.Element;
  belowRange?: JSX.Element;
  triggerType: TriggerType;
  timeBuffer: number;
  onClickEdit: () => void;
}

export interface IPureEditTriggerDetailProps {
  type: IEditTriggerDetailProps["type"];
  triggerIdx: IEditTriggerSetupProps["triggerIdx"];
  token: IToken;
  above: string;
  below: string;
  aboveRange?: JSX.Element;
  belowRange?: JSX.Element;
  triggerType: TriggerType;
  onClickEdit: () => void;
  onClickDelete: () => void;
  onAddTrigger: () => void;
  disabled: boolean;
}

export interface IStrategyTriggerState {
  triggerType: TriggerType;

  baseToken: 0 | 1;
  timeBufferChecked: boolean;
  aboveChecked: boolean;
  belowChecked: boolean;
  maxFreqChecked: boolean;

  below: string;
  above: string;
  timeBuffer: string;
  maxFreq: number;

  maxFreqPeriod: E_MaxFreqPeriod;
}

export enum E_MaxFreqPeriod {
  Day = "a day",
  Week = "a week",
  Month = "a month",
}

export interface IDualTriggerState {
  triggerType: TriggerType;

  baseToken: 0 | 1;
  timeBufferChecked: boolean;
  aboveChecked: boolean;
  belowChecked: boolean;

  below: string;
  above: string;
  timeBuffer: string;

  aboveAction?: RecurringAction;
  belowAction?: RecurringAction;
}
export enum E_TriggerTypeTab {
  Price = "Token Price",
  Ratio = "Token Ratio",
}

export enum E_WidthUnit {
  Percentage = "Percentages",
  TokenTerm = "Token Terms",
}

export type IDualTriggerSetupProps = {
  tokens: [IToken, IToken];
  tokenSpotPrice: [string, string];
  tokenMarketPrice: {
    tokenA: string;
    tokenB: string;
  };
  defaultAction: StrategyDefaultAction;
  tickSpacing: number;
  tokenRatio: number; // 0-10000
  initForm?: IDualTriggerState;
  onSave?: (f: IDualTriggerState) => void;
  bg?: string;
};

export interface IPureTriggerSetupProps {
  triggerType: E_TriggerTypeTab;
  onSelectTriggerType: (menu: E_TriggerTypeTab) => void;

  widthUnit: E_WidthUnit;
  onSelectWidthUnit: (unit: E_WidthUnit) => void;

  tokens: IToken[];
  selectedToken: IToken;
  onSelectToken: (token: IToken) => void;

  aboveChecked: boolean;
  onAboveChecked: (checked: boolean) => void;

  above: string;
  onAboveInput: (value: string) => void;

  belowChecked: boolean;
  onBelowChecked: (checked: boolean) => void;

  below: string;
  onBelowInput: (value: string) => void;

  timeBufferChecked: boolean;
  onTimeBufferChecked: (checked: boolean) => void;

  timeBuffer: string;
  onTimeBufferInput: (value: string) => void;

  tokenMarketPrice: {
    tokenA: string;
    tokenB: string;
  };
  tickSpacing: number;
  defaultAction: StrategyDefaultAction;
  aboveTriggerAction?: RecurringAction;
  setAboveTriggerAction: React.Dispatch<
    React.SetStateAction<RecurringAction | undefined>
  >;
  belowTriggerAction?: RecurringAction;
  setBelowTriggerAction: React.Dispatch<
    React.SetStateAction<RecurringAction | undefined>
  >;
  errorMsg?: string;
  invalidInput?: "above" | "below" | "timeBuffer" | "";
  onSave: () => void;
  bg?: string;
}

export interface IEditTriggerState {
  triggerType: TriggerType;

  baseToken: 0 | 1;
  timeBufferChecked: boolean;
  aboveChecked: boolean;
  belowChecked: boolean;

  below: string;
  above: string;
  timeBuffer: string;

  aboveAction?: RecurringAction;
  belowAction?: RecurringAction;
}

export type IEditTriggerSetupProps = {
  triggerIdx: number;
  tokens: [IToken, IToken];
  tokenSpotPrice: [string, string];
  defaultAction: StrategyDefaultAction;
  tickSpacing: number;
  tokenRatio: number; // 0-10000
  initForm: IEditTriggerState;
  onSave?: (f: IEditTriggerState) => void;
  onDiscard?: () => void;
  isAddTrigger?: boolean;
};

export interface IPureEditTriggerProps {
  triggerIdx: number;
  triggerType: E_TriggerTypeTab;
  tokens: IToken[];
  selectedToken: IToken;
  widthUnit: E_WidthUnit;

  aboveChecked: boolean;
  above: string;
  onAboveInput: (value: string) => void;

  belowChecked: boolean;
  below: string;
  onBelowInput: (value: string) => void;

  tokenMarketPrice: {
    tokenA: string;
    tokenB: string;
  };
  tickSpacing: number;
  defaultAction: StrategyDefaultAction;
  aboveTriggerAction?: RecurringAction;
  setAboveTriggerAction: React.Dispatch<
    React.SetStateAction<RecurringAction | undefined>
  >;
  belowTriggerAction?: RecurringAction;
  setBelowTriggerAction: React.Dispatch<
    React.SetStateAction<RecurringAction | undefined>
  >;
  isAddTrigger?: boolean;
  errorMsg?: string;
  invalidInput?: "above" | "below" | "";
  onSave: () => void;
  onDiscard: () => void;
  bg?: string;
}

export interface ITriggerAction {
  tickSpacing: number;
  tokens: ITokenPair;
  tokenMarketPrice: {
    tokenA: string;
    tokenB: string;
  };
  defaultAction: StrategyDefaultAction;
  triggerAction?: RecurringAction;
  setTriggerAction: React.Dispatch<
    React.SetStateAction<RecurringAction | undefined>
  >;
}

export interface StrategyDefaultAction {
  percentLower: string;
  percentUpper: string;
  priceLower: string;
  priceUpper: string;
  ratioLower: string;
  ratioUpper: string;
  rangeWidth: string;
}

import { ApertureSupportedChainId } from "@aperture_finance/uniswap-v3-automation-sdk";
import { EvmChainMap } from "./chainMap";

export interface EvmChainInfo {
  name: string;
  chainId: number;
  shortName: string;
  networkId: number;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  rpc: string[];
  faucets: string[];
  infoURL: string;
  exploreURL: string;
  network: string;
  multicallAddresses: string;
  rpcPublicNode?: string;
  analyticsEndpoint?: string;
}

export type TEvmChainMap = {
  [chainId: number]: EvmChainInfo;
};

export const ChainExplorerRegex = /(^.+\/)\/?(\$\{TYPE\})(.+)?(\$\{ADDRESS\})/;

export const getExplorerURL = (chainId: ApertureSupportedChainId): string => {
  return EvmChainMap[chainId]?.exploreURL ?? "";
};

export const getAnalyticsEndpoint = (
  chainId: ApertureSupportedChainId
): string => {
  return EvmChainMap[chainId]?.analyticsEndpoint ?? "";
};

import { AmmEnum } from "@aperture/uikit";
import { viem } from "@aperture_finance/uniswap-v3-automation-sdk";

export function getApprovalForAllRequest(amm: AmmEnum, chainId: number) {
  return viem.getSetApprovalForAllTx(chainId, amm, true);
}

export function getRevokeForAllRequest(amm: AmmEnum, chainId: number) {
  return viem.getSetApprovalForAllTx(chainId, amm, false);
}

import { useMemo, useState } from "react";
import styled from "styled-components";
import {
  AmmInfo,
  ChainList,
  ConnectionType,
  E_AMM,
  NetworkInfo,
  SupportedChainId,
  WalletList,
} from "../../../utils";
import { BaseButton } from "../../Buttonb";
import { Headline1, Subtitle3 } from "../../Typography";
import { ConnectModalProps } from "../types";

const ContentWrapper = styled.div.attrs({ id: "connect-wallet-modal-show" })`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing["2xl"]};
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  gap: ${({ theme }) => theme.spacing.lg};
`;
const Title = styled(Headline1)`
  color: ${({ theme }) => theme.colors.global.text.T1};
  font-family: "Dunbar Tall";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  text-transform: capitalize;
`;
const Head = styled(Headline1)`
  color: ${({ theme }) => theme.colors.global.text.T1};
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;
const ButtonGroups = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media screen and (max-width: 692px) {
    grid-template-columns: repeat(3, 1fr);
  }
  column-gap: 12px;
  row-gap: 12px;
`;
const OptionContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;
const OptionItem = styled(BaseButton)<{ selected: boolean }>`
  width: 116px;
  min-height: 76px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 0;
  gap: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.grey.white};
  background: none;
  &:hover {
    background: ${({ theme }) => theme.colors.global.background.BG3};
  }
  ${({ theme, selected }) =>
    selected &&
    `
    background: ${theme.colors.global.background.BG3};
    `}
  &:disabled {
    opacity: 1;
  }
  border-radius: ${({ theme }) => theme.radii.xl};
`;
const Title3 = styled(Subtitle3)`
  color: ${({ theme }) => theme.colors.global.text.T1};
  font-family: "Roboto Flex";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;

export const ButtonGroup: React.FC<{
  title: string;
  selected: any;
  itemList: any[];
  onSelect: (onClickValue: any) => void;
  itemAttr?: "id" | "name";
}> = ({ title, selected, itemList, itemAttr = "id", onSelect }) => {
  const [selectedIndex, setIndex] = useState(
    itemList.findIndex((item) =>
      itemAttr === "id" ? item.id === selected : item.name === selected.name
    )
  );
  return (
    <Container>
      <Head fontWeight={500}>{title}</Head>
      <ButtonGroups>
        {itemList.map((item, index) => {
          const Icon = item.Icon;
          return (
            <OptionContainer key={`${index}-${item.name}`}>
              <OptionItem
                selected={index === selectedIndex}
                onClick={() => {
                  setIndex(index);
                  onSelect(itemAttr === "id" ? item.id : item.name);
                }}
              >
                <Icon width={20} height={20} />
                <Title3 textAlign={"center"}>{item.name}</Title3>
              </OptionItem>
            </OptionContainer>
          );
        })}
      </ButtonGroups>
    </Container>
  );
};

const defaultAmmChain: Record<E_AMM, SupportedChainId> = {
  [E_AMM.Uniswap]: SupportedChainId.ETHEREUM,
  [E_AMM.PancakeSwap]: SupportedChainId.ETHEREUM,
  [E_AMM.ApertureSwap]: SupportedChainId.MANTA,
  [E_AMM.Aerodrome]: SupportedChainId.BASE,
  [E_AMM.Velodrome]: SupportedChainId.OPTIMISM,
};

export const ConnectModal: React.FC<ConnectModalProps> = ({
  defaultAMM = AmmInfo.Uniswap,
  defaultChain = SupportedChainId.ETHEREUM,
  currentConnectType = ConnectionType.NONE,
  chainSelect,
  walletSelect,
}) => {
  const [dex, setDex] = useState(defaultAMM);
  const [chain, setChain] = useState(defaultChain);

  const chainList = useMemo(
    () =>
      ChainList.reduce<NetworkInfo[]>((acc, chain) => {
        if (chain.amm.map((platform) => platform.name).includes(dex.name)) {
          acc.push(chain);
        }
        return acc;
      }, []),
    [dex]
  );

  return (
    <>
      <ContentWrapper>
        <Title>Connect Wallet</Title>
        <ButtonGroup
          key={`amm: ${dex.name}--${chain}`}
          title="Select DEX"
          selected={dex}
          itemList={Object.values(AmmInfo)}
          onSelect={(ammSelection: E_AMM) => {
            chainSelect(AmmInfo[ammSelection], defaultAmmChain[ammSelection]);
            setChain(defaultAmmChain[ammSelection]);
            setDex(AmmInfo[ammSelection]);
          }}
          itemAttr="name"
        />
        <ButtonGroup
          key={`network: ${dex.name}--${chain}`}
          title="Select a Network"
          selected={chain}
          itemList={chainList}
          onSelect={(chain: SupportedChainId) => {
            chainSelect(dex, chain);
            setChain(chain);
          }}
        />
        <ButtonGroup
          title="Select a Wallet"
          selected={currentConnectType}
          itemList={WalletList.filter((f) => f.id !== ConnectionType.SAFE)}
          onSelect={(walletId: ConnectionType) => {
            walletSelect(chain, walletId);
          }}
        />
      </ContentWrapper>
    </>
  );
};

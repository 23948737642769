import styled from "styled-components";
import { ButtonText } from "../Typography";

export const FeeTierContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing.lg};
  width: 100%;
`;

export const FeeTierItem = styled.div<{
  selected: boolean;
  disabled: boolean;
  notCreated: boolean;
  height?: number;
}>`
  height: ${({ height }) => (height ? `${height}px` : "auto")};
  box-sizing: border-box;
  padding: 16px;
  background: ${({ theme }) => theme.colors.global.background.BG1};
  border-radius: ${({ theme }) => theme.radii.md};
  cursor: ${({ disabled }) => (disabled ? "no-drop" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  justify-content: space-between;
  border: 1px solid
    ${({ theme: { colors }, selected, notCreated }) =>
      selected && notCreated
        ? colors.errorWarning.orange
        : selected
        ? colors.global.text.T1
        : colors.global.line.Line1};
  ${({ theme: { colors }, disabled }) =>
    disabled &&
    `
      color: ${colors.global.text.T2};
    `};
  &:hover {
    border: 1px solid
      ${({ theme: { colors }, selected, notCreated, disabled }) =>
        selected && notCreated
          ? colors.errorWarning.orange
          : selected
          ? colors.global.text.T1
          : disabled
          ? colors.global.line.Line1
          : colors.global.line.Line2};
    transition: all ${({ theme }) => theme.transitions.default};
  }
`;

export const InfoContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.global.text.T1};
  font-family: "Roboto Flex";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const InfoSelect = styled.div`
  width: 95px;
  height: 28px;
  background: ${({ theme }) => theme.colors.global.primary};
  color: ${({ theme }) => theme.colors.global.textAlt.T1};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.radii.sm};
  font-family: "Roboto Flex";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
`;

export const Description = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  > svg {
    height: 10px;
    width: 10px;
    margin-right: 5px;
    > path {
      stroke: ${({ theme }) => theme.colors.grey.black};
    }
  }
  font-size: 12px;
  color: ${({ theme }) => theme.colors.global.success};
  font-family: "Roboto Flex";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
`;

export const ButtonBox = styled(ButtonText)`
  padding: 0;
`;

export const Tips = styled.div`
  color: ${({ theme }) => theme.colors.global.text.T2};
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  width: 100%;
  min-height: 72px;
`;

import React from "react";
import { LayoutProps } from "styled-system";

export interface ISelectStyleProps extends LayoutProps {
  bordered?: boolean;
}

export interface ISelectProps<T extends string> extends ISelectStyleProps {
  selected: T;
  disabled?: boolean;
  onSelect: (value: T, option: ISelectOption<T>) => void;
  options: ISelectOption<T>[];
}

export type ISelectOptionLabel = string | React.ReactNode;

export type ISelectOption<T extends string> = {
  label: ISelectOptionLabel;
  value: T;
  disabled?: boolean;
};

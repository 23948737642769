import { DefaultTheme } from "styled-components";
import base from "./base";
import { darkColors, lightColors } from "./colors";
import { gradients } from "./gradients";

const defaultTheme: DefaultTheme = {
  ...base,
  isDark: true,
  colors: darkColors,
  gradients: gradients,
};

const lightTheme: DefaultTheme = {
  ...base,
  isDark: false,
  colors: lightColors,
  gradients: gradients,
};

export { defaultTheme, lightTheme };

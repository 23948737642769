import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Dropdown, IDropdownRef } from "../Dropdown";
import { PercentageInputGroup } from "../Input";
import { IDefaultPosition } from "../Popover";
import { Slippage } from "../Slippage";
import { StatusTab } from "../Tabs";
import { CommonLgText } from "../Typography";
import {
  AutoCompoundToggleWrapper,
  ButtonText,
  Content,
  InputNoteCard,
  StyledQuestionPopover,
  SubtitleContainer,
  TitleContainer,
  TitleText,
  UncollectFeeNoteCard,
} from "./style";
import {
  IAutoCompoundFee,
  IAutoCompoundProps,
  IAutoCompoundRef,
} from "./types";

export const AutoCompound = forwardRef<IAutoCompoundRef, IAutoCompoundProps>(
  (
    {
      amount,
      initForm,
      isOn,
      defaultSlippage,
      warningSlippage,
      globalSlippage,
      applyGlobally,
      onApplyGlobally,
      isLoading,
      onChange,
      onDelete,
      onGasCeilingError,
      confirmBtn,
      disabled = false,
    },
    ref
  ) => {
    const [form, setForm] = useState(initForm);
    const [isSwitchOn, setIsSwitchOn] = useState(isOn);
    const dropdownRef = useRef<IDropdownRef>(null);
    const statusRef = useRef<HTMLDivElement>(null);

    const handleSwitchChange = useCallback(
      (value: boolean) => {
        if (isLoading) return;
        setIsSwitchOn(value);
        handleChange("isSwitchBtnOn", value);
        if (!value && isSwitchOn) {
          onDelete();
        }
      },
      [isLoading, isSwitchOn, onDelete]
    );

    const dropdownBtn = (
      <AutoCompoundToggleWrapper>
        <StatusTab
          initTop={2}
          initLeft={2}
          noText={true}
          disabled={disabled || isLoading}
          onStatus={isSwitchOn}
          onChange={handleSwitchChange}
        />
        <ButtonText>Auto-Compound</ButtonText>
      </AutoCompoundToggleWrapper>
    );

    const renderSubtitle = (
      title: string,
      hint: string,
      padding: string,
      defaultPosition?: IDefaultPosition
    ) => (
      <SubtitleContainer>
        <CommonLgText>{title}</CommonLgText>
        <StyledQuestionPopover
          content={hint}
          defaultPosition={defaultPosition}
        />
      </SubtitleContainer>
    );

    const handleChange = (
      key: string,
      value: boolean | number | string | IAutoCompoundFee
    ) => {
      setForm((prevState) => ({ ...prevState, [key]: value }));
    };

    const handleDropdownDisplayChanged = (isShow: boolean) => {
      if (!isShow) {
        setForm(initForm);
        setIsSwitchOn(isOn);
      }
    };

    useEffect(() => {
      onChange(form);
    }, [form]);

    useImperativeHandle(ref, () => ({
      setSwitchStatus: (status: boolean) => {
        setIsSwitchOn(status);
      },
      onDropdownClose: () => {
        dropdownRef && dropdownRef.current?.onClose();
      },
      resetForm: () => {
        setForm(initForm);
        setIsSwitchOn(isOn);
      },
    }));

    return (
      <Dropdown
        ref={dropdownRef}
        disabled={disabled}
        button={dropdownBtn}
        onDisplayChanged={handleDropdownDisplayChanged}
        insideRef={statusRef}
      >
        <Content>
          <TitleContainer>
            <TitleText>Auto-Compound</TitleText>
            <StatusTab
              radius="round"
              width={70}
              height={28}
              sliderWidth={20}
              sliderHeight={20}
              initTop={4}
              initLeft={6}
              noText={false}
              statusStyle="success"
              disabled={isLoading}
              onStatus={isSwitchOn}
              onChange={handleSwitchChange}
            />
          </TitleContainer>
          {isSwitchOn && (
            <UncollectFeeNoteCard text="Your uncollected fees will be rebalanced into your next position." />
          )}
          {renderSubtitle(
            "Minimum Fees",
            "Input the minimum amount of fees needed to trigger fee compounding in either dollars or percentage of position. However, the actual compound trigger will be based on the percentage (e.g. if you choose $200 which is currently 5% of your total liquidity position we will compound based on the 5%).",
            "12px 0;",
            { top: 18, left: -108 }
          )}
          <PercentageInputGroup
            amount={amount}
            disabled={!isSwitchOn}
            defaultUnit={form.minimumFees.unit}
            percentage={form.minimumFees.percentage}
            onChange={(percentage) => {
              handleChange("minimumFees", { ...form.minimumFees, percentage });
            }}
          />
          {renderSubtitle(
            "Maximum Gas Fee",
            "Input the maximum gas fee in either dollars or percentage of position. However, the actual gas ceiling will be based on the percentage not the dollar amount (e.g. if you choose $50 which is currently 1% of your total liquidity position then we will set a gas ceiling of 1%).",
            "20px 0 12px 0;"
          )}
          <PercentageInputGroup
            amount={amount}
            disabled={!isSwitchOn}
            defaultUnit={form.maximumGasFee.unit}
            percentage={form.maximumGasFee.percentage}
            onChange={(percentage) => {
              handleChange("maximumGasFee", {
                ...form.maximumGasFee,
                percentage,
              });
            }}
            showError={isSwitchOn}
            onError={onGasCeilingError}
          />
          <InputNoteCard
            text="Input in dollars or percentage of position but note that the actual
            gas ceiling will be based on the percentage of your position"
          />
          <Slippage
            defaultValue={defaultSlippage}
            globalValue={globalSlippage}
            warningValue={warningSlippage}
            value={form.slippage}
            disabled={!isSwitchOn}
            onSlippageChange={(value) => {
              handleChange("slippage", value);
            }}
            applyGlobally={applyGlobally}
            onApplyGlobally={onApplyGlobally}
          />
          {isSwitchOn && confirmBtn}
        </Content>
      </Dropdown>
    );
  }
);

import bigDecimal from "js-big-decimal";
import React from "react";
import { Input, SliderContainer, Tag, ValidLine } from "./style";
import { ISliderProps } from "./types";

export const Slider: React.FC<ISliderProps> = ({
  value,
  onChange,
  ...props
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.value) return;
    const newValue = parseFloat(bigDecimal.divide(e.target.value, 100, 8));
    onChange(newValue);
  };
  const percentage = parseFloat(bigDecimal.multiply(value, 100));
  return (
    <SliderContainer {...props}>
      <Input value={percentage} onChange={handleChange} />
      <ValidLine value={percentage} />
      <Tag value={percentage}>{percentage}%</Tag>
    </SliderContainer>
  );
};

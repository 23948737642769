import React from "react";
import styled from "styled-components";
import { formatTokenAmount } from "../../utils/numberFormat";
import { ButtonText, Headline3, Subtitle3 } from "../Typography";
import { TokenCardProps } from "./types";

const Container = styled.div`
  display: flex;
  padding: 12px;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.global.background.BG1};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
  border-radius: ${({ theme }) => theme.radii.md};
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.md};
`;
const IconImgWrapper = styled.div`
  height: 28px;
  width: 28px;
  img {
    width: 28px;
  }
`;
const IconName = styled(ButtonText)`
  line-height: 16px;
  letter-spacing: 0.0025em;
  text-align: left;
  color: ${({ theme }) => theme.colors.global.text.T1}
`;
const AmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: ${({ theme }) => theme.spacing.sm};
`;
const Amount = styled(Headline3)`
  letter-spacing: 0.0025em;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const SubText = styled(Subtitle3)`
  color: ${({ theme }) => theme.colors.global.text.T2};
`;

export const TokenCard: React.FC<TokenCardProps> = ({
  Icon,
  ticker = "",
  amount = "",
  subText = "",
}) => {
  return (
    <Container>
      <IconWrapper>
        <IconImgWrapper>{Icon}</IconImgWrapper>
        <IconName>{ticker}</IconName>
      </IconWrapper>
      <AmountWrapper>
        <Amount>{formatTokenAmount(amount)}</Amount>
        <SubText>{subText}</SubText>
      </AmountWrapper>
    </Container>
  );
};

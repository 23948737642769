import React, { PropsWithChildren, useEffect } from "react";
import styled from "styled-components";
import { SupportedChainId } from "../../utils";
import { useDrawer } from "../Drawer";
import { AuthUser } from "./AuthUser";
import { ConnectWalletButton } from "./ConnectWalletButton";
import { NetworkSelector } from "./Network";
import { WrongNetworkButton } from "./WrongNetworkButton";
import { TopBarProps } from "./types";

const StyledBox = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  gap: ${({ theme }) => theme.spacing.lg};
  z-index: ${({ theme }) => theme.zIndices.modal};
`;

const AuthUserBox = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs};
`;

export const SupportedChains = [
  SupportedChainId.ETHEREUM,
  SupportedChainId.ARBITRUM,
  SupportedChainId.OPTIMISM,
  SupportedChainId.POLYGON,
  SupportedChainId.BASE,
  SupportedChainId.BNB,
  SupportedChainId.AVALANCHE,
  SupportedChainId.SCROLL,
  SupportedChainId.MANTA,
];

export const TopBar: React.FC<PropsWithChildren<TopBarProps>> = ({
  currentChainId,
  currentAmm,
  supportedAmmList,
  walletConnected = false,
  connectType,
  authUser,
  authStatus,
  authKeepOpen,
  explorerURL,
  wrongNetwork = false,
  wrongNetworkLoading = false,
  chainOnSelect,
  openConnectWalletModal,
  openWrongNetworkModal,
  portfolioBalances,
  activities,
  onDisconnect,
  url,
  onClick,
  onAuthClick,
  ...props
}) => {
  const { drawerOpen } = useDrawer();

  useEffect(() => {
    if (drawerOpen) {
    } else {
    }
  }, [drawerOpen]);

  return (
    <StyledBox {...props}>
      <NetworkSelector
        currentAmm={currentAmm}
        supportedAmmList={supportedAmmList}
        currentChainId={currentChainId}
        supportedChainIdList={SupportedChains}
        chainOnSelect={chainOnSelect}
      />
      {walletConnected ? (
        <>
          {wrongNetwork ? (
            <WrongNetworkButton
              isLoading={wrongNetworkLoading}
              onClick={openWrongNetworkModal}
            />
          ) : (
            <AuthUserBox>
              <AuthUser
                {...{
                  authUser,
                  authStatus,
                  authKeepOpen,
                  portfolioBalances,
                  activities,
                  explorerURL,
                  onDisconnect,
                  connectType,
                  url,
                  onClick,
                  onAuthClick,
                }}
              />
            </AuthUserBox>
          )}
        </>
      ) : (
        <ConnectWalletButton onClick={openConnectWalletModal} />
      )}
    </StyledBox>
  );
};

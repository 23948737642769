import { Flex } from "@ui/common";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { formatUSDAmount } from "../../utils/numberFormat";
import { LinearLoader } from "../Loader";
import { CommonLgText } from "../Typography";
import { Input } from "./Input";
import { InputGroupMaxProps } from "./types";

const IGMax = styled.div<{
  disabled: boolean;
  error: boolean;
  warning: boolean;
  ticker?: string;
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  height: ${({ ticker }) => (ticker ? "auto" : "126px")};
  min-width: calc(45% - 48px);
  gap: ${({ theme }) => theme.spacing.md};
  border: 1px solid
    ${({ disabled, warning, error, theme }) =>
      disabled
        ? theme.colors.global.line.Line1
        : warning
        ? theme.colors.global.error
        : error
        ? theme.colors.global.error
        : theme.colors.global.line.Line2};
  border-radius: ${({ theme }) => theme.radii.lg};

  :focus-within {
    border-color: ${({ warning, error, theme }) =>
      warning
        ? theme.colors.errorWarning.orange
        : error
        ? theme.colors.global.error
        : theme.colors.global.line.Line2};
  }
  transition: all ${({ theme }) => theme.transitions.default};
  &:hover:not(:focus-within) {
    border-color: ${({ disabled, warning, error, theme: { colors } }) =>
      disabled
        ? colors.global.line.Line2
        : warning
        ? colors.errorWarning.orange
        : error
        ? colors.errorWarning.red
        : colors.global.line.Line2};
  }
`;
const StyledInput = styled(Input)<{ disabled: boolean }>`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 38px;
  padding: 0;
  font-size: 32px;
  line-height: 38px;
  align-items: center;
  border-radius: 0;
  border: none !important;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.global.text.T2 : theme.colors.global.text.T1};
  :focus-within {
    border-color: ${({ theme, disabled }) =>
      disabled
        ? theme.colors.global.line.Line1
        : theme.colors.global.line.Line2};
  }
`;

const StyledText = styled(CommonLgText)`
  color: ${({ theme }) => theme.colors.global.text.T2};
`;

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.global.text.T1};
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
`;
const BalanceInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
const BalanceText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const MaxButton = styled.button<{ disabled: boolean }>`
  background-color: transparent;
  border: none;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "unset")};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.global.text.T2 : theme.colors.global.primary};
  &:hover {
    color: ${({ theme, disabled }) =>
      disabled
        ? theme.colors.global.text.T2
        : theme.colors.global.primaryHover};
  }
`;
const MaxButtonText = styled.div`
  height: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;

const None = styled.div`
  color: ${({ theme }) => theme.colors.global.text.T1};
  font-family: "Roboto Flex";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const InputGroupMax: React.FC<InputGroupMaxProps> = ({
  defaultValue = "",
  placeholder = "",
  ticker = "",
  subTitle = "",
  balance = "",
  displayBalance = "",
  disabled = false,
  loading = false,
  onChange = (value: string) => value,
  onMaxClick = (value: string) => value,
  onError = () => false,
  warning = false,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState(false);

  useEffect(() => {
    setValue(defaultValue);
    onError && setError(onError(defaultValue));
  }, [defaultValue, onError]);

  return (
    <IGMax disabled={disabled} error={error} warning={warning} ticker={ticker}>
      {disabled ? (
        <None>-</None>
      ) : (
        <Flex gap="md" justifyContent={"space-between"} alignItems={"center"}>
          {loading ? (
            <LinearLoader size="100%" height="24px" radius="27px" />
          ) : (
            <StyledInput
              defaultValue={value}
              placeholder={placeholder}
              type="number"
              align="left"
              disabled={disabled || loading}
              onChange={(value) => {
                setValue(value);
                onChange && onChange(value);
                onError && setError(onError(value));
              }}
              onError={onError}
              {...props}
            />
          )}
          {ticker && <StyledText>{ticker}</StyledText>}
        </Flex>
      )}
      {subTitle ? <SubTitle>{formatUSDAmount(subTitle)}</SubTitle> : "00000"}
      <BalanceInfo>
        {balance ? <BalanceText>Balance: {displayBalance}</BalanceText> : "-"}
        <MaxButton
          disabled={disabled}
          onClick={() => {
            let value = balance ? balance.toString() : "";
            setValue(value);
            onMaxClick && onMaxClick(value);
            onError && setError(onError(value));
          }}
        >
          <MaxButtonText>Max</MaxButtonText>
        </MaxButton>
      </BalanceInfo>
    </IGMax>
  );
};

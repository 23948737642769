import { Flex } from "@uiv2/common";
import { T3Regular } from "@uiv2/components/Typography";
import styled from "styled-components";

const TimeframeTag = styled.div<{ selected?: boolean; disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  padding: 4px 12px;
  border: 1px solid
    ${({ theme, selected }) =>
      selected
        ? theme.colors.global.background.BG3
        : theme.colors.global.line.Line1};
  border-radius: ${({ theme }) => theme.radii.round};
  background-color: ${({ theme, selected }) =>
    selected
      ? theme.colors.global.background.BG3
      : theme.colors.global.background.BG1};
`;
const StyledT3 = styled(T3Regular)`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const Wrapper = styled(Flex)`
  align-self: flex-end;
`;

export const timeframes = [1, 7, 30, 90] as const;
export type ITimeframe = (typeof timeframes)[number];

interface ITimeframeProps {
  selected: ITimeframe;
  onSelect: (t: ITimeframe) => void;
  disabled?: boolean;
}

export const TimeFrame = ({
  selected,
  onSelect,
  disabled,
}: ITimeframeProps) => {
  return (
    <Wrapper gap="sm">
      {timeframes.map((timeframe) => (
        <TimeframeTag
          key={timeframe}
          selected={timeframe === selected}
          onClick={() => !disabled && onSelect(timeframe)}
          disabled={disabled}
        >
          <StyledT3>{`${timeframe}D`}</StyledT3>
        </TimeframeTag>
      ))}
    </Wrapper>
  );
};

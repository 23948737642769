import { FlexColumn } from "@ui/common";
import styled from "styled-components";
import { LinearLoader } from "./LinearLoader";
import { LoaderGroupProps } from "./types";

const LoaderWrapper = styled(FlexColumn)`
  gap: ${({ theme }) => theme.spacing.md};
`;

export const LinearLoaderGroup: React.FC<LoaderGroupProps> = ({
  count = 3,
  size,
  height,
  radius,
  ...props
}) => {
  return (
    <LoaderWrapper>
      {[...Array(count)].map((x, i) => (
        <LinearLoader
          key={i}
          size={size}
          height={height}
          radius={radius}
          {...props}
        />
      ))}
    </LoaderWrapper>
  );
};

import { FlexColumn } from "@ui/common";
import { formatFixed, formatUSDAmount } from "@ui/utils";
import React from "react";
import styled from "styled-components";
import { TotalCardProps } from "./types";

const CardWrapper = styled.div<{ bgImg: string }>`
  min-width: 450px;
  width: max-content;
  min-height: 460px;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.radii.xl};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  overflow: hidden;

  font-family: "Roboto Flex";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.global.text.T2};
  z-index: 1;

  ::before {
    content: "";
    position: absolute;
    background: url(${({ bgImg }) => bgImg});
    filter: blur(0px);
    background-repeat: no-repeat;
    background-size: cover;
    width: 431px;
    height: 257px;
    left: 17px;
    bottom: 0;
    z-index: -1;
  }
`;
const TotalVolume = styled.div`
  color: ${({ theme }) => theme.colors.global.primary};
  font-family: "Roboto Flex";
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  text-transform: capitalize;
`;
const HR = styled.hr`
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
  border-bottom: none;
  height: 0;
  width: 100%;
`;
const DetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  align-items: center;
`;
const Item = styled(FlexColumn)`
  width: 188px;
  gap: 12px;
`;
const Values = styled.div`
  color: ${({ theme }) => theme.colors.global.text.T1};
  font-family: "Roboto Flex";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const TotalCard: React.FC<React.PropsWithChildren<TotalCardProps>> = ({
  bgImg,
  totalVolume,
  totalUsers,
  totalTransactions,
  todayVolume,
  todayUsers,
  ...props
}) => {
  return (
    <CardWrapper bgImg={bgImg} {...props}>
      <Item>Total Intents Volume</Item>
      <TotalVolume>{formatUSDAmount(totalVolume ?? "-", 2)}</TotalVolume>
      <HR />
      <DetailsWrapper>
        <Item>
          Total Unique Wallet Users
          <Values>{formatFixed(totalUsers ?? "")}</Values>
        </Item>
        {!!todayVolume && (
          <Item>
            Today's Intents Volume
            <Values>{formatUSDAmount(todayVolume ?? "-", 2)}</Values>
          </Item>
        )}
        <Item>
          Total Transactions
          <Values>{formatFixed(totalTransactions ?? "")}</Values>
        </Item>
        {!!todayUsers && (
          <Item>
            Today's Unique Wallet Users
            <Values>{formatFixed(todayUsers ?? "")}</Values>
          </Item>
        )}
      </DetailsWrapper>
    </CardWrapper>
  );
};

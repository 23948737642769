import { RebalanceActionTab } from "@ui/common";
import styled from "styled-components";
import { InputGroupPlusMinus } from "../Input";
import { FutureSpotChartRangeInput } from "../LiquidityChartRangeInput";
import { Subtitle2 } from "../Typography";
import { FutureSpotSelectorProps } from "./types";

const TitleWrapper = styled(Subtitle2)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.global.text.T2};
  margin-bottom: -16px;
`;

const FutureSpotSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: ${({ theme }) => theme.spacing["3xl"]};
`;

const FutureSpotWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.grey.darkGrey2};
`;

const InputsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacing.xl};
`;

export function FutureSpotSelector({
  currencyA,
  currencyB,
  ticksAtLimit,
  price,
  priceLower,
  priceUpper,
  futureSpotTab,
  onSwitchFutureSpotTab,
  getDecrementLower,
  getIncrementLower,
  getDecrementUpper,
  getIncrementUpper,
  onLeftRangeInput,
  onRightRangeInput,
  chartHeight,
  interactive,
}: FutureSpotSelectorProps) {
  return (
    <FutureSpotSelectorWrapper>
      {currencyA && currencyB && (
        <TitleWrapper fontSize={16} lineHeight={"20px"} height={40}>
          Future Spot Price
        </TitleWrapper>
      )}
      <FutureSpotChartRangeInput
        currencyA={currencyA}
        currencyB={currencyB}
        ticksAtLimit={ticksAtLimit}
        price={price}
        priceLower={priceLower}
        priceUpper={priceUpper}
        onLeftRangeInput={onLeftRangeInput}
        onRightRangeInput={onRightRangeInput}
        interactive={true}
        chartHeight={chartHeight}
        futureSpotTab={futureSpotTab}
      />
      {/* TODO: For now, support the `Percentages` tab only */}
      {/* <FutureSpotTabWrapper>
        <FutureSpotMenuTab
          menu={[RebalanceActionTab.PERCENTAGES, RebalanceActionTab.TOKEN_TERMS]}
          selected={futureSpotTab === RebalanceActionTab.PERCENTAGES ? 0 : 1}
          onSelect={(tab) => {
            onSwitchFutureSpotTab?.(
              tab === 0 ? RebalanceActionTab.PERCENTAGES : RebalanceActionTab.TOKEN_TERMS
            );
          }}
        />
      </FutureSpotTabWrapper> */}
      <InputsWrapper>
        <InputGroupPlusMinus
          placeholder="0.00"
          value={priceLower}
          onChange={onLeftRangeInput}
          defaultValue={priceLower ?? "0"}
          title={"Min"}
          subTitle={
            futureSpotTab === RebalanceActionTab.TOKEN_TERMS ? (
              <FutureSpotWrapper>
                <Subtitle2>
                  {currencyB ?? ""} per {currencyA ?? ""}
                </Subtitle2>
                <Subtitle2>below future spot price</Subtitle2>
              </FutureSpotWrapper>
            ) : (
              "below future spot price"
            )
          }
          minusClick={getDecrementLower}
          plusClick={getIncrementLower}
          disabled={!interactive}
          allowNegative={true}
          format={{
            suffix:
              futureSpotTab === RebalanceActionTab.PERCENTAGES
                ? "%"
                : undefined,
          }}
        />
        <InputGroupPlusMinus
          placeholder="0.00"
          value={priceUpper}
          onChange={onRightRangeInput}
          defaultValue={priceUpper ?? "0"}
          title={"Max"}
          subTitle={
            futureSpotTab === RebalanceActionTab.TOKEN_TERMS ? (
              <FutureSpotWrapper>
                <Subtitle2>
                  {currencyB ?? ""} per {currencyA ?? ""}
                </Subtitle2>
                <Subtitle2>above future spot price</Subtitle2>
              </FutureSpotWrapper>
            ) : (
              "above future spot price"
            )
          }
          minusClick={getDecrementUpper}
          plusClick={getIncrementUpper}
          disabled={!interactive}
          allowNegative={true}
          format={{
            suffix:
              futureSpotTab === RebalanceActionTab.PERCENTAGES
                ? "%"
                : undefined,
          }}
        />
      </InputsWrapper>
    </FutureSpotSelectorWrapper>
  );
}

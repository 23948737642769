import { DeleteIcon } from "@aperture/assetkit";
import { Flex, FlexColumn } from "@ui/common/styles/global";
import { Button, WhiteCard } from "@ui/components";
import { InputWithTicker } from "@ui/components/Input/InputWithTicker";
import styled from "styled-components";

export const Container = styled(WhiteCard)`
  padding: 0;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
  color: ${({ theme }) => theme.colors.global.text.T3};
  background: none;
`;
export const Wrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.lg};
  border-radius: ${({ theme }) => theme.radii.md2};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line2};
`;

export const BodyContainer = styled(FlexColumn)`
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  gap: ${({ theme }) => theme.spacing.xl};
`;

export const Title = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const EditorButton = styled(Button)`
  width: 120px;
  height: 22px;
  padding: 0;
  font-family: "Roboto Flex";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  background: ${({ theme }) => theme.colors.global.primary};
  :hover {
    opacity: 0.8;
  }
`;

export const StyledDeleteIcon = styled(DeleteIcon)<{ disabled?: boolean }>`
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.colors.global.primary};
    opacity: ${({ disabled }) => disabled && 0.5};
  }
`;

export const TriggerRangeItem = styled(Flex)`
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const ItemValue = styled.span`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const IconWrapper = styled.div`
  height: 20px;
  width: 20px;
`;

export const AddTrigger = styled(Flex)`
  align-items: center;
  line-height: 20px;
  cursor: pointer;
  gap: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.global.primary};
  width: fit-content;
`;

export const StyledInputWithTicker = styled(InputWithTicker)`
  width: 214px;
  height: 44px;
  text-overflow: ellipsis;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.global.background.BG3};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
  & + div {
    top: 50%;
    transform: translateY(-50%);
    color: ${({ theme }) => theme.colors.global.text.T1};
  }
  :hover {
    border-color: ${({ theme }) => theme.colors.global.line.Line2};
  }
  :focus {
    border-color: ${({ theme }) => theme.colors.global.line.Line2};
  }
  :disabled {
    color: ${({ theme }) => theme.colors.global.text.T3};
    border-color: ${({ theme }) => theme.colors.global.background.BG1};
    background-color: ${({ theme }) => theme.colors.global.background.BG4};
  }
`;

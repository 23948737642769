import {
  CompletedIcon,
  FailedIcon,
  LinkIcon,
  PendingTxnIcon,
} from "@aperture/assetkit";
import {
  TransactionStatus,
  viem,
} from "@aperture_finance/uniswap-v3-automation-sdk";
import React from "react";
import styled from "styled-components";
import { shortenAddress } from "../../utils";
import { ActivityContainer, ActivityText, RecentWrapper } from "./style";

export type IActivityProps = {
  explorerURL: string;
  data: viem.Activity;
};

const Link = styled(LinkIcon)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  > svg,
  path {
    fill: ${({ theme }) => theme.colors.global.primary};
    stroke: ${({ theme }) => theme.colors.global.primary};
  }
`;

export const Activity: React.FC<IActivityProps> = ({ explorerURL, data }) => {
  const getIcon = () => {
    switch (data.status) {
      case TransactionStatus.Confirmed:
        return <CompletedIcon />;
      case TransactionStatus.Failed:
        return <FailedIcon />;
      case TransactionStatus.Pending:
        return <PendingTxnIcon height="20" width="20" />;
    }
  };
  const getText = () => {
    switch (data.status) {
      case TransactionStatus.Confirmed:
        return "Success";
      case TransactionStatus.Failed:
        return "Failed";
      case TransactionStatus.Pending:
        return "Pending";
    }
  };
  const getDescriptor = () => {
    if (!data.descriptor) return "";
    const arr = data.descriptor.split(" ");
    const formattedStr = arr.map((str) => {
      if (str.indexOf("0x") > -1) {
        return shortenAddress(str, 6, 4);
      }
      return str;
    });
    return formattedStr.join(" ");
  };
  const getAddress = () => {
    if (!data.descriptor) return "";
    const arr = data.descriptor.split(" ");
    if (arr[arr.length - 2] === "from") {
      return shortenAddress(data.receipt?.from || "", 6, 4);
    }
    if (arr[arr.length - 2] === "to") {
      return shortenAddress(data.receipt?.to || "", 6, 4);
    }
  };
  const renderIconAndText = () => {
    return (
      <>
        <RecentWrapper>
          {getIcon()}
          <ActivityText>
            {getText()} {data.title} {getDescriptor()} {getAddress()}.
          </ActivityText>
        </RecentWrapper>
      </>
    );
  };
  return (
    <ActivityContainer>
      {renderIconAndText()}
      {data.hash && (
        <Link
          onClick={() => {
            window.open(`${explorerURL}/tx/${data.hash}`, "_blank");
          }}
        />
      )}
    </ActivityContainer>
  );
};

import { useState } from "react";

export const useCopyText = (text: string) => {
  const [hasCopied, setHasCopied] = useState(false);

  const handleCopyAddress = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (!hasCopied) {
      navigator.clipboard
        .writeText(text)
        .catch((error) => console.error("copy has failed: ", error));
      setHasCopied(true);
    }
    const st = setTimeout(() => {
      setHasCopied(false);
      clearTimeout(st);
    }, 1000);
  };

  return { hasCopied, handleCopyAddress };
};

import bigDecimal from "js-big-decimal";
import React, { ReactNode } from "react";
import { MIN_FIXED_VALUE, formatTokenAmount } from "../../utils/numberFormat";
import { LinearLoader } from "../Loader";
import { Tag } from "../Tag";
import {
  Amount,
  Headline5,
  IconWrapper,
  LeftWrapper,
  RightWrapper,
  ShortAmount,
  StyledHeadline5,
  TokenInfoContainer,
} from "./style";

export type ITokenInfoProps = {
  Icon: ReactNode;
  ticker: string;
  address?: string;
  marginBottom?: number;
  amount?: string;
  price?: string;
  tag?: string;
  decimals?: number;
  native?: boolean;
  loading?: boolean;
  minWidth?: number;
  maxWidth?: number;
};

export const TokenInfo: React.FC<ITokenInfoProps> = ({
  Icon,
  ticker = "",
  amount,
  price,
  tag,
  marginBottom = 8,
  maxWidth,
  minWidth = 263,
  loading = false,
  ...props
}) => {
  let formattedAmount = amount
    ? amount === "-"
      ? "-"
      : formatTokenAmount(amount)
    : "";
  if (
    price &&
    amount &&
    amount !== "-" &&
    bigDecimal.compareTo(amount, 0) > 0 &&
    bigDecimal.compareTo(amount, MIN_FIXED_VALUE) < 0 &&
    bigDecimal.compareTo(bigDecimal.multiply(amount, price), 0) > 0 &&
    bigDecimal.compareTo(bigDecimal.multiply(amount, price), MIN_FIXED_VALUE) <
      0
  ) {
    formattedAmount = "<" + MIN_FIXED_VALUE;
  }

  return (
    <TokenInfoContainer
      minWidth={minWidth}
      marginBottom={marginBottom}
      {...props}
    >
      <LeftWrapper>
        <IconWrapper>{Icon}</IconWrapper>
        <StyledHeadline5 lineHeight={"20px"}>{ticker}</StyledHeadline5>
      </LeftWrapper>
      <RightWrapper maxWidth={maxWidth}>
        {tag && maxWidth && maxWidth <= 118 ? (
          <ShortAmount title={formattedAmount}>
            {loading ? (
              <LinearLoader size="80px" height="18px" radius="27px" />
            ) : (
              formattedAmount
            )}
          </ShortAmount>
        ) : (
          <Amount maxWidth={maxWidth} title={formattedAmount}>
            {loading ? (
              <LinearLoader size="80px" height="18px" radius="27px" />
            ) : (
              formattedAmount
            )}
          </Amount>
        )}
        {tag && (
          <Tag variant="gray" padding={"2px 8px"}>
            <Headline5
              textAlign={"center"}
              fontWeight={400}
              fontSize={12}
              lineHeight={"16px"}
            >
              {tag}
            </Headline5>
          </Tag>
        )}
      </RightWrapper>
    </TokenInfoContainer>
  );
};

import {
  getSafeTransactionService,
  isL2NetworkType,
} from "@/utils/networkHelper";
import {
  ApertureSupportedChainId,
  getLogger,
  viem,
} from "@aperture_finance/uniswap-v3-automation-sdk";
import type {
  EstimateGasExecutionError,
  Hash,
  PublicClient,
  TransactionRequest,
} from "viem";

export async function getTxHashFromSafeTxHash(
  chainId: ApertureSupportedChainId,
  safeTxHash: Hash,
  maxAttempts = 20,
  pollingInterval = 3000
): Promise<Hash> {
  if (!safeTxHash) {
    console.log("Invalid safeTxHash");
    return undefined;
  }
  const url = getSafeTransactionService(chainId);
  if (!url) {
    console.log(`${chainId} is not supported from Safe`);
    return undefined;
  }
  try {
    let attempts = 0;
    while (attempts < maxAttempts) {
      const txHash = (
        await fetch(`${url}/api/v1/multisig-transactions/${safeTxHash}/`).then(
          (response) => {
            if (response.ok) return response.json();
            else if (response.status === 404)
              // it is possible that the safeTxHash is actually the transaction hash
              return { transactionHash: safeTxHash };
          }
        )
      )?.transactionHash;
      if (txHash) return txHash as Hash;
      await new Promise((resolve) => setTimeout(resolve, pollingInterval));
      attempts++;
    }
  } catch (e) {
    console.log("Failed to get tx hash", e);
    return undefined;
  }
}

export async function estimateGas(
  tx: TransactionRequest,
  chainId: ApertureSupportedChainId,
  client: PublicClient
): Promise<{
  totalGasCost?: bigint;
  l2GasAmount: bigint;
  gasCost: bigint;
}> {
  if (isL2NetworkType(chainId)) {
    try {
      const { totalGasCost, l2GasAmount, l2GasPrice } =
        await viem.estimateTotalGasCostForOptimismLikeL2Tx(tx, chainId, client);
      return {
        totalGasCost,
        l2GasAmount,
        gasCost: l2GasPrice * l2GasAmount,
      };
    } catch (error) {
      const gasError = error as EstimateGasExecutionError;
      getLogger().error("Failed to estimate TotalGasCost as L2 Tx: ", {
        error: gasError.message,
      });
    }
  }
  const [l2GasPrice, l2GasAmount] = await Promise.all([
    client.getGasPrice(),
    client.estimateGas({
      ...tx,
      account: tx.from,
    }),
  ]);

  return { l2GasAmount, gasCost: l2GasPrice * l2GasAmount };
}

import { ApertureTheme } from "@aperture/uikitv2";
import React, { ButtonHTMLAttributes } from "react";
import { ButtonStyleProps } from "styled-system";

export const variants = {
  contained: "contained",
  outlined: "outlined",
  text: "text",
} as const;

export const colors = {
  primary: "primary",
  secondary: "secondary",
  gradient: "gradient",
  black: "black",
  default: "default",
} as const;

export const sizes = {
  xl: "xl",
  lg: "lg",
  md: "md",
  sm: "sm",
  xs: "xs",
  "2xs": "2xs",
  circle: "circle",
} as const;

export type Variant = (typeof variants)[keyof typeof variants];
export type Color = (typeof colors)[keyof typeof colors];
export type Size = (typeof sizes)[keyof typeof sizes];

export interface ButtonProps
  extends ButtonStyleProps,
    Partial<ButtonHTMLAttributes<HTMLButtonElement>> {
  disabled?: boolean;
  locked?: boolean;
  invalid?: boolean;
  variant?: Variant;
  color?: Color;
  size?: Size;
  startIcon?: React.ReactNode;
  lockedIcon?: React.ReactNode;
  iconGap?: number;
  width?: string;
  minWidth?: string;
  onClick?: (() => {}) | (() => void);
}

export interface ThemedProps extends ButtonProps {
  theme: ApertureTheme;
}

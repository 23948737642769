import { ApertureSupportedChainId } from "@aperture_finance/uniswap-v3-automation-sdk";
import { TokenIconMap } from "./tokenIconConfig";

export const tokensIcon: TokenIconMap = {
  [ApertureSupportedChainId.ETHEREUM_MAINNET_CHAIN_ID]: {
    "0x111111111117dC0aa78b770fA6A738034120C302":
      "https://assets.coingecko.com/coins/images/13469/thumb/1inch-token.png?1608803028",
    "0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9":
      "https://assets.coingecko.com/coins/images/12645/thumb/AAVE.png?1601374110",
    "0xB98d4C97425d9908E66E53A6fDf673ACcA0BE986":
      "https://assets.coingecko.com/coins/images/2341/thumb/arcblock.png?1547036543",
    "0xEd04915c23f00A313a544955524EB7DBD823143d":
      "https://assets.coingecko.com/coins/images/12390/thumb/ACH_%281%29.png?1599691266",
    "0xADE00C28244d5CE17D72E40330B1c318cD12B7c3":
      "https://assets.coingecko.com/coins/images/847/thumb/Ambire_AdEx_Symbol_color.png?1655432540",
    "0x91Af0fBB28ABA7E31403Cb457106Ce79397FD4E6":
      "https://assets.coingecko.com/coins/images/4490/thumb/aergo.png?1647696770",
    "0x32353A6C91143bfd6C7d363B546e62a9A2489A20":
      "https://assets.coingecko.com/coins/images/18125/thumb/lpgblc4h_400x400.jpg?1630570955",
    "0x626E8036dEB333b408Be468F951bdB42433cBF18":
      "https://assets.coingecko.com/coins/images/14631/thumb/aioz_logo.png?1617413126",
    "0xdBdb4d16EdA451D0503b854CF79D55697F90c8DF":
      "https://assets.coingecko.com/coins/images/14113/thumb/Alchemix.png?1614409874",
    "0x27702a26126e0B3702af63Ee09aC4d1A084EF628":
      "https://assets.coingecko.com/coins/images/11676/thumb/Monochram-aleph.png?1608483725",
    "0x6B0b3a982b4634aC68dD83a4DBF02311cE324181":
      "https://assets.coingecko.com/coins/images/22062/thumb/alethea-logo-transparent-colored.png?1642748848",
    "0xAC51066d7bEC65Dc4589368da368b212745d63E8":
      "https://assets.coingecko.com/coins/images/14375/thumb/alice_logo.jpg?1615782968",
    "0xa1faa113cbE53436Df28FF0aEe54275c13B40975":
      "https://assets.coingecko.com/coins/images/12738/thumb/AlphaToken_256x256.png?1617160876",
    "0xfF20817765cB7f73d4bde2e66e067E58D11095C2":
      "https://assets.coingecko.com/coins/images/12409/thumb/amp-200x200.png?1599625397",
    "0x8290333ceF9e6D528dD5618Fb97a76f268f3EDD4":
      "https://assets.coingecko.com/coins/images/4324/thumb/U85xTl2.png?1608111978",
    "0xa117000000f279D81A1D3cc75430fAA017FA5A2e":
      "https://assets.coingecko.com/coins/images/681/thumb/JelZ58cv_400x400.png?1601449653",
    "0x4d224452801ACEd8B2F0aebE155379bb5D594381":
      "https://assets.coingecko.com/coins/images/24383/small/apecoin.jpg?1647476455",
    "0x0b38210ea11411557c13457D4dA7dC6ea731B88a":
      "https://assets.coingecko.com/coins/images/13256/thumb/api3.jpg?1606751424",
    "0xB50721BCf8d664c30412Cfbc6cf7a15145234ad1":
      "https://arbitrum.foundation/logo.png",
    "0xBA50933C268F567BDC86E1aC131BE072C6B0b71a":
      "https://assets.coingecko.com/coins/images/8506/thumb/9u0a23XY_400x400.jpg?1559027357",
    "0x64D91f12Ece7362F91A6f8E7940Cd55F05060b92":
      "https://assets.coingecko.com/coins/images/15714/thumb/omnPqaTY.png?1622820503",
    "0x2565ae0385659badCada1031DB704442E1b69982":
      "https://assets.coingecko.com/coins/images/11605/thumb/gpvrlkSq_400x400_%281%29.jpg?1591775789",
    "0x27054b13b1B798B345b591a4d22e6562d47eA75a":
      "https://assets.coingecko.com/coins/images/1019/thumb/Airswap.png?1630903484",
    "0xA2120b9e674d3fC3875f415A7DF52e382F141225":
      "https://assets.coingecko.com/coins/images/15985/thumb/ATA.jpg?1622535745",
    "0xA9B1Eb5908CfC3cdf91F9B8B3a74108598009096":
      "https://assets.coingecko.com/coins/images/13860/thumb/1_KtgpRIJzuwfHe0Rl0avP_g.jpeg?1612412025",
    "0x18aAA7115705e8be94bfFEBDE57Af9BFc265B998":
      "https://assets.coingecko.com/coins/images/12913/thumb/AudiusCoinLogo_2x.png?1603425727",
    "0x845576c64f9754CF09d87e45B720E82F3EeF522C":
      "https://assets.coingecko.com/coins/images/19727/thumb/ewnektoB_400x400.png?1635767094",
    "0x467719aD09025FcC6cF6F8311755809d45a5E5f3":
      "https://assets.coingecko.com/coins/images/27277/large/V-65_xQ1_400x400.jpeg",
    "0xBB0E17EF65F82Ab018d8EDd776e8DD940327B28b":
      "https://assets.coingecko.com/coins/images/13029/thumb/axie_infinity_logo.png?1604471082",
    "0x3472A5A71965499acd81997a54BBA8D852C6E53d":
      "https://assets.coingecko.com/coins/images/13287/thumb/badger_dao_logo.jpg?1607054976",
    "0xba100000625a3754423978a60c9317c58a424e3D":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xba100000625a3754423978a60c9317c58a424e3D/logo.png",
    "0xBA11D00c5f74255f56a5E366F4F77f5A186d7f55":
      "https://assets.coingecko.com/coins/images/9545/thumb/band-protocol.png?1568730326",
    "0x0D8775F648430679A709E98d2b0Cb6250d2887EF":
      "https://assets.coingecko.com/coins/images/677/thumb/basic-attention-token.png?1547034427",
    "0xF17e65822b568B3903685a7c9F496CF7656Cc6C2":
      "https://assets.coingecko.com/coins/images/21061/thumb/biconomy_logo.jpg?1638269749",
    "0x1A4b46696b2bB4794Eb3D4c26f1c55F9170fa4C5":
      "https://assets.coingecko.com/coins/images/17627/thumb/rI_YptK8.png?1653983088",
    "0x5283D291DBCF85356A21bA090E6db59121208b44":
      "https://assets.coingecko.com/coins/images/28453/large/blur.png?1670745921",
    "0x5732046A883704404F284Ce41FfADd5b007FD668":
      "https://assets.coingecko.com/coins/images/2848/thumb/ColorIcon_3x.png?1622516510",
    "0x1F573D6Fb3F13d689FF844B4cE37794d79a7FF1C":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x1F573D6Fb3F13d689FF844B4cE37794d79a7FF1C/logo.png",
    "0x42bBFa2e77757C645eeaAd1655E0911a7553Efbc":
      "https://assets.coingecko.com/coins/images/20285/thumb/BOBA.png?1636811576",
    "0x0391D2021f89DC339F60Fff84546EA23E337750f":
      "https://assets.coingecko.com/coins/images/12811/thumb/barnbridge.jpg?1602728853",
    "0x799ebfABE77a6E34311eeEe9825190B9ECe32824":
      "https://assets.coingecko.com/coins/images/18100/thumb/braintrust.PNG?1630475394",
    "0x4Fabb145d64652a948d72533023f6E7A623C7C53":
      "https://assets.coingecko.com/coins/images/9576/thumb/BUSD.png?1568947766",
    "0xAE12C5930881c53715B369ceC7606B70d8EB229f":
      "https://assets.coingecko.com/coins/images/17117/thumb/logo.png?1626412904",
    "0xBe9895146f7AF43049ca1c1AE358B0541Ea49704":
      "https://assets.coingecko.com/coins/images/27008/large/cbeth.png",
    "0x4F9254C83EB525f9FCf346490bbb3ed28a81C667":
      "https://assets.coingecko.com/coins/images/4379/thumb/Celr.png?1554705437",
    "0x8A2279d4A90B6fe1C4B30fa660cC9f926797bAA2":
      "https://assets.coingecko.com/coins/images/5000/thumb/Chromia.png?1559038018",
    "0x3506424F91fD33084466F402d5D97f05F8e3b4AF":
      "https://assets.coingecko.com/coins/images/8834/thumb/Chiliz.png?1561970540",
    "0x80C62FE4487E1351b47Ba49809EBD60ED085bf52":
      "https://assets.coingecko.com/coins/images/15278/thumb/clover.png?1645084454",
    "0xc00e94Cb662C3520282E6f5717214004A7f26888":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xc00e94Cb662C3520282E6f5717214004A7f26888/logo.png",
    "0xDDB3422497E61e13543BeA06989C0789117555c5":
      "https://assets.coingecko.com/coins/images/2962/thumb/Coti.png?1559653863",
    "0x3D658390460295FB963f54dC0899cfb1c30776Df":
      "https://assets.coingecko.com/coins/images/588/thumb/coval-logo.png?1599493950",
    "0xD417144312DbF50465b1C641d016962017Ef6240":
      "https://assets.coingecko.com/coins/images/14168/thumb/covalent-cqt.png?1624545218",
    "0xA0b73E1Ff0B80914AB6fe0444E65848C4C34450b":
      "https://assets.coingecko.com/coins/images/7310/thumb/oCw2s3GI_400x400.jpeg?1645172042",
    "0x08389495D7456E1951ddF7c3a1314A4bfb646d8B":
      "https://assets.coingecko.com/coins/images/1901/thumb/crypt.png?1547036205",
    "0xD533a949740bb3306d119CC777fa900bA034cd52":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xD533a949740bb3306d119CC777fa900bA034cd52/logo.png",
    "0x491604c0FDF08347Dd1fa4Ee062a822A5DD06B5D":
      "https://assets.coingecko.com/coins/images/11038/thumb/cartesi.png?1592288021",
    "0x321C2fE4446C7c963dc41Dd58879AF648838f98D":
      "https://assets.coingecko.com/coins/images/14932/thumb/glossy_icon_-_C200px.png?1619073171",
    "0xDf801468a808a32656D2eD2D2d80B72A129739f4":
      "https://assets.coingecko.com/coins/images/10687/thumb/CUBE_icon.png?1617026861",
    "0x41e5560054824eA6B0732E656E3Ad64E20e94E45":
      "https://assets.coingecko.com/coins/images/788/thumb/civic.png?1547034556",
    "0x4e3FBD56CD56c3e72c1403e103b45Db9da5B9D2B":
      "https://assets.coingecko.com/coins/images/15585/thumb/convex.png?1621256328",
    "0x6B175474E89094C44Da98b954EedeAC495271d0F":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png",
    "0x081131434f93063751813C619Ecca9C4dC7862a3":
      "https://assets.coingecko.com/coins/images/19837/thumb/dar.png?1636014223",
    "0x3A880652F47bFaa771908C07Dd8673A787dAEd3A":
      "https://assets.coingecko.com/coins/images/13453/thumb/ddx_logo.png?1608741641",
    "0x3597bfD533a99c9aa083587B074434E61Eb0A258":
      "https://assets.coingecko.com/coins/images/1152/thumb/gLCEA2G.png?1604543239",
    "0xfB7B4564402E5500dB5bB6d63Ae671302777C75a":
      "https://assets.coingecko.com/coins/images/11603/thumb/dext.png?1605790188",
    "0x84cA8bc7997272c7CfB4D0Cd3D55cd942B3c9419":
      "https://assets.coingecko.com/coins/images/11955/thumb/image.png?1646041751",
    "0x0AbdAce70D3790235af448C88547603b945604ea":
      "https://assets.coingecko.com/coins/images/849/thumb/district0x.png?1547223762",
    "0x1494CA1F11D487c2bBe4543E90080AeBa4BA3C2b":
      "https://assets.coingecko.com/coins/images/12465/thumb/defi_pulse_index_set.png?1600051053",
    "0x3Ab6Ed69Ef663bd986Ee59205CCaD8A20F98b4c2":
      "https://assets.coingecko.com/coins/images/14578/thumb/KotgsCgS_400x400.jpg?1617094445",
    "0x92D6C1e31e14520e676a687F0a93788B716BEff5":
      "https://assets.coingecko.com/coins/images/17500/thumb/hjnIm9bV.jpg?1628009360",
    "0x961C8c0B1aaD0c0b10a51FeF6a867E3091BCef17":
      "https://assets.coingecko.com/coins/images/13480/thumb/DYP_Logo_Symbol-8.png?1655809066",
    "0xe6fd75ff38Adca4B97FBCD938c86b98772431867":
      "https://assets.coingecko.com/coins/images/2780/thumb/Elastos.png?1597048112",
    "0x761D38e5ddf6ccf6Cf7c55759d5210750B5D60F3":
      "https://assets.coingecko.com/coins/images/14962/thumb/6GxcPRo3_400x400.jpg?1619157413",
    "0xF629cBd94d3791C9250152BD8dfBDF380E2a3B9c":
      "https://assets.coingecko.com/coins/images/1102/thumb/enjin-coin-logo.png?1547035078",
    "0xC18360217D8F7Ab5e7c516566761Ea12Ce7F9D72":
      "https://assets.coingecko.com/coins/images/19785/thumb/acatxTm8_400x400.jpg?1635850140",
    "0xBBc2AE13b23d715c30720F079fcd9B4a74093505":
      "https://assets.coingecko.com/coins/images/14238/thumb/LOGO_HIGH_QUALITY.png?1647831402",
    "0xd9Fcd98c322942075A5C3860693e9f4f03AAE07b":
      "https://assets.coingecko.com/coins/images/26149/thumb/YCvKDfl8_400x400.jpeg?1656041509",
    "0x1aBaEA1f7C830bD89Acc67eC4af516284b1bC33c":
      "https://assets.coingecko.com/coins/images/26045/thumb/euro-coin.png?1655394420",
    "0xa0246c9032bC3A600820415aE600c6388619A14D":
      "https://assets.coingecko.com/coins/images/12304/thumb/Harvest.png?1613016180",
    "0xaea46A60368A7bD060eec7DF8CBa43b7EF41Ad85":
      "https://assets.coingecko.com/coins/images/5681/thumb/Fetch.jpg?1572098136",
    "0xef3A930e1FfFFAcd2fc13434aC81bD278B0ecC8d":
      "https://assets.coingecko.com/coins/images/12423/thumb/stafi_logo.jpg?1599730991",
    "0x41545f8b9472D758bB669ed8EaEEEcD7a9C4Ec29":
      "https://assets.coingecko.com/coins/images/25060/thumb/Forta_lgo_%281%29.png?1655353696",
    "0x77FbA179C79De5B7653F68b5039Af940AdA60ce0":
      "https://assets.coingecko.com/coins/images/14917/thumb/photo_2021-04-22_00.00.03.jpeg?1619020835",
    "0xc770EEfAd204B5180dF6a14Ee197D99d808ee52d":
      "https://assets.coingecko.com/coins/images/9988/thumb/FOX.png?1574330622",
    "0x853d955aCEf822Db058eb8505911ED77F175b99e":
      "https://assets.coingecko.com/coins/images/13422/thumb/frax_logo.png?1608476506",
    "0x4E15361FD6b4BB609Fa63C81A2be19d873717870":
      "https://assets.coingecko.com/coins/images/4001/thumb/Fantom.png?1558015016",
    "0x8c15Ef5b4B21951d50E53E4fbdA8298FFAD25057":
      "https://assets.coingecko.com/coins/images/8186/thumb/47271330_590071468072434_707260356350705664_n.jpg?1556096683",
    "0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0":
      "https://assets.coingecko.com/coins/images/13423/thumb/frax_share.png?1608478989",
    "0x5fAa989Af96Af85384b8a938c2EdE4A7378D9875":
      "https://assets.coingecko.com/coins/images/24530/thumb/GAL-Token-Icon.png?1651483533",
    "0x15D4c048F83bd7e37d49eA4C83a07267Ec4203dA":
      "https://assets.coingecko.com/coins/images/12493/thumb/GALA-COINGECKO.png?1600233435",
    "0xdab396cCF3d84Cf2D07C4454e10C8A6F5b008D2b":
      "https://assets.coingecko.com/coins/images/19081/thumb/GOLDFINCH.png?1634369662",
    "0x3F382DbD960E3a9bbCeaE22651E88158d2791550":
      "https://assets.coingecko.com/coins/images/12467/thumb/ghst_200.png?1600750321",
    "0x7DD9c5Cba05E151C895FDe1CF355C9A1D5DA6429":
      "https://assets.coingecko.com/coins/images/542/thumb/Golem_Submark_Positive_RGB.png?1606392013",
    "0x6810e776880C02933D47DB1b9fc05908e5386b96":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6810e776880C02933D47DB1b9fc05908e5386b96/logo.png",
    "0xccC8cb5229B0ac8069C51fd58367Fd1e622aFD97":
      "https://assets.coingecko.com/coins/images/17139/thumb/10631.png?1635718182",
    "0xc944E90C64B2c07662A292be6244BDf05Cda44a7":
      "https://assets.coingecko.com/coins/images/13397/thumb/Graph_Token.png?1608145566",
    "0xDe30da39c46104798bB5aA3fe8B9e0e1F348163F":
      "https://assets.coingecko.com/coins/images/15810/thumb/gitcoin.png?1621992929",
    "0x056Fd409E1d7A124BD7017459dFEa2F387b6d5Cd":
      "https://assets.coingecko.com/coins/images/5992/thumb/gemini-dollar-gusd.png?1536745278",
    "0xC08512927D12348F6620a698105e1BAac6EcD911":
      "https://assets.coingecko.com/coins/images/14191/thumb/icon_gyen_200_200.png?1614843343",
    "0xb3999F658C0391d94A37f7FF328F3feC942BcADC":
      "https://assets.coingecko.com/coins/images/26136/large/hashflow-icon-cmc.png",
    "0x71Ab77b7dbB4fa7e017BC15090b2163221420282":
      "https://assets.coingecko.com/coins/images/18973/thumb/logosq200200Coingecko.png?1634090470",
    "0xF5581dFeFD8Fb0e4aeC526bE659CFaB1f8c781dA":
      "https://assets.coingecko.com/coins/images/14061/thumb/Shared_HOPR_logo_512px.png?1614073468",
    "0xB705268213D593B8FD88d3FDEFF93AFF5CbDcfAE":
      "https://assets.coingecko.com/coins/images/2565/thumb/logomark-purple-286x286.png?1638362736",
    "0x767FE9EDC9E0dF98E07454847909b5E959D7ca0E":
      "https://assets.coingecko.com/coins/images/14468/large/ILV.JPG",
    "0xF57e7e7C23978C3cAEC3C3548E3D615c346e79fF":
      "https://assets.coingecko.com/coins/images/17233/thumb/imx.png?1636691817",
    "0x0954906da0Bf32d5479e25f46056d22f08464cab":
      "https://assets.coingecko.com/coins/images/12729/thumb/index.png?1634894321",
    "0xe28b3B32B6c345A34Ff64674606124Dd5Aceca30":
      "https://assets.coingecko.com/coins/images/12882/thumb/Secondary_Symbol.png?1628233237",
    "0x41D5D79431A913C4aE7d69a668ecdfE5fF9DFB68":
      "https://assets.coingecko.com/coins/images/14205/thumb/inverse_finance.jpg?1614921871",
    "0x6fB3e0A217407EFFf7Ca062D46c26E5d60a14d69":
      "https://s2.coinmarketcap.com/static/img/coins/64x64/2777.png",
    "0x23894DC9da6c94ECb439911cAF7d337746575A72":
      "https://assets.coingecko.com/coins/images/24648/thumb/ey40AzBN_400x400.jpg?1648507272",
    "0x7420B4b9a0110cdC71fB720908340C03F9Bc03EC":
      "https://assets.coingecko.com/coins/images/13876/thumb/JASMY200x200.jpg?1612473259",
    "0x4B1E80cAC91e2216EEb63e29B957eB91Ae9C2Be8":
      "https://assets.coingecko.com/coins/images/10351/thumb/logo512.png?1632480932",
    "0x85Eee30c52B0b379b046Fb0F85F4f3Dc3009aFEC":
      "https://assets.coingecko.com/coins/images/3373/thumb/IuNzUb5b_400x400.jpg?1589526336",
    "0x4CC19356f2D37338b9802aa8E8fc58B0373296E7":
      "https://assets.coingecko.com/coins/images/2034/thumb/selfkey.png?1548608934",
    "0xdd974D5C2e2928deA5F71b9825b8b646686BD200":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdd974D5C2e2928deA5F71b9825b8b646686BD200/logo.png",
    "0x1cEB5cB57C4D4E2b2433641b95Dd330A33185A44":
      "https://assets.coingecko.com/coins/images/12966/thumb/kp3r_logo.jpg?1607057458",
    "0x464eBE77c293E473B48cFe96dDCf88fcF7bFDAC0":
      "https://assets.coingecko.com/coins/images/2807/thumb/krl.png?1547036979",
    "0x037A54AaB062628C9Bbae1FDB1583c195585fe41":
      "https://assets.coingecko.com/coins/images/9985/thumb/zRPSu_0o_400x400.jpg?1574327008",
    "0x5A98FcBEA516Cf06857215779Fd812CA3beF1B32":
      "https://assets.coingecko.com/coins/images/13573/thumb/Lido_DAO.png?1609873644",
    "0x514910771AF9Ca656af840dff83E8264EcF986CA":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x514910771AF9Ca656af840dff83E8264EcF986CA/logo.png",
    "0x61E90A50137E1F645c9eF4a0d3A4f01477738406":
      "https://assets.coingecko.com/coins/images/22572/thumb/loka_64pix.png?1642643271",
    "0xA4e8C3Ec456107eA67d3075bF9e3DF3A75823DB0":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA4e8C3Ec456107eA67d3075bF9e3DF3A75823DB0/logo.png",
    "0x58b6A8A3302369DAEc383334672404Ee733aB239":
      "https://assets.coingecko.com/coins/images/7137/thumb/logo-circle-green.png?1619593365",
    "0x6DEA81C8171D0bA574754EF6F8b412F2Ed88c54D":
      "https://assets.coingecko.com/coins/images/14665/thumb/200-lqty-icon.png?1617631180",
    "0xBBbbCA6A901c926F240b89EacB641d8Aec7AEafD":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xBBbbCA6A901c926F240b89EacB641d8Aec7AEafD/logo.png",
    "0x5f98805A4E8be255a32880FDeC7F6728C6568bA0":
      "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x5f98805A4E8be255a32880FDeC7F6728C6568bA0/logo.png",
    "0x0F5D2fB29fb7d3CFeE444a200298f468908cC942":
      "https://assets.coingecko.com/coins/images/878/thumb/decentraland-mana.png?1550108745",
    "0x69af81e73A73B40adF4f3d4223Cd9b1ECE623074":
      "https://assets.coingecko.com/coins/images/14051/thumb/Mask_Network.jpg?1614050316",
    "0x08d967bb0134F2d07f7cfb6E246680c53927DD30":
      "https://assets.coingecko.com/coins/images/11335/thumb/2020-05-19-token-200.png?1589940590",
    "0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0":
      "https://assets.coingecko.com/coins/images/4713/thumb/matic-token-icon.png?1624446912",
    "0x949D48EcA67b17269629c7194F4b727d4Ef9E5d6":
      "https://assets.coingecko.com/coins/images/19304/thumb/Db4XqML.png?1634972154",
    "0xfC98e825A2264D890F9a1e68ed50E1526abCcacD":
      "https://assets.coingecko.com/coins/images/14414/thumb/ENtxnThA_400x400.jpg?1615948522",
    "0x814e0908b12A99FeCf5BC101bB5d0b8B5cDf7d26":
      "https://assets.coingecko.com/coins/images/2441/thumb/mdt_logo.png?1569813574",
    "0x9E32b13ce7f2E80A01932B42553652E053D6ed8e":
      "https://assets.coingecko.com/coins/images/15595/thumb/metis.jpeg?1660285312",
    "0x99D8a9C45b2ecA8864373A26D1459e3Dff1e17F3":
      "https://assets.coingecko.com/coins/images/16786/thumb/mimlogopng.png?1624979612",
    "0x09a3EcAFa817268f77BE1283176B946C4ff2E608":
      "https://assets.coingecko.com/coins/images/13295/thumb/mirror_logo_transparent.png?1611554658",
    "0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2/logo.png",
    "0xec67005c4E498Ec7f55E092bd1d35cbC47C91892":
      "https://assets.coingecko.com/coins/images/605/thumb/melon.png?1547034295",
    "0x275f5Ad03be0Fa221B4C6649B8AeE09a42D9412A":
      "https://assets.coingecko.com/coins/images/13298/thumb/monavale_logo.jpg?1607232721",
    "0x33349B282065b0284d756F0577FB39c158F935e6":
      "https://assets.coingecko.com/coins/images/14097/thumb/photo_2021-05-03_14.20.41.jpeg?1620022863",
    "0xF433089366899D83a9f26A773D59ec7eCF30355e":
      "https://assets.coingecko.com/coins/images/763/thumb/Metal.png?1592195010",
    "0x65Ef703f5594D2573eb71Aaf55BC0CB548492df4":
      "https://assets.coingecko.com/coins/images/22087/thumb/1_Wyot-SDGZuxbjdkaOeT2-A.png?1640764238",
    "0xe2f2a5C287993345a840Db3B0845fbC70f5935a5":
      "https://assets.coingecko.com/coins/images/11576/thumb/mStable_USD.png?1595591803",
    "0xB6Ca7399B4F9CA56FC27cBfF44F4d2e4Eef1fc81":
      "https://assets.coingecko.com/coins/images/13230/thumb/muse_logo.png?1606460453",
    "0xAE788F80F2756A86aa2F410C651F2aF83639B95b":
      "https://s2.coinmarketcap.com/static/img/coins/64x64/17704.png",
    "0x5Ca381bBfb58f0092df149bD3D243b08B9a8386e":
      "https://assets.coingecko.com/coins/images/4604/thumb/mxc.png?1655534336",
    "0x9E46A38F5DaaBe8683E10793b06749EEF7D733d1":
      "https://assets.coingecko.com/coins/images/2843/thumb/ImcYCVfX_400x400.jpg?1628519767",
    "0x04abEdA201850aC0124161F037Efd70c74ddC74C":
      "https://assets.coingecko.com/coins/images/11284/thumb/52954052.png?1589868539",
    "0x5Cf04716BA20127F1E2297AdDCf4B5035000c9eb":
      "https://assets.coingecko.com/coins/images/3375/thumb/nkn.png?1548329212",
    "0x1776e1F26f98b1A5dF9cD347953a26dd3Cb46671":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x1776e1F26f98b1A5dF9cD347953a26dd3Cb46671/logo.png",
    "0x4fE83213D56308330EC302a8BD641f1d0113A4Cc":
      "https://assets.coingecko.com/coins/images/3318/thumb/photo1198982838879365035.jpg?1547037916",
    "0x967da4048cD07aB37855c090aAF366e4ce1b9F48":
      "https://assets.coingecko.com/coins/images/3687/thumb/ocean-protocol-logo.jpg?1547038686",
    "0x8207c1FfC5B6804F6024322CcF34F29c3541Ae26":
      "https://assets.coingecko.com/coins/images/3296/thumb/op.jpg?1547037878",
    "0xd26114cd6EE289AccF82350c8d8487fedB8A0C07":
      "https://assets.coingecko.com/coins/images/776/thumb/OMG_Network.jpg?1591167168",
    "0x6F59e0461Ae5E2799F1fB3847f05a63B16d0DbF8":
      "https://s2.coinmarketcap.com/static/img/coins/64x64/5183.png",
    "0x0258F474786DdFd37ABCE6df6BBb1Dd5dfC4434a":
      "https://assets.coingecko.com/coins/images/11841/thumb/orion_logo.png?1594943318",
    "0x4575f41308EC1483f3d399aa9a2826d74Da13Deb":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x4575f41308EC1483f3d399aa9a2826d74Da13Deb/logo.png",
    "0xc1D204d77861dEf49b6E769347a883B15EC397Ff":
      "https://assets.coingecko.com/coins/images/1601/thumb/pax.png?1547035800",
    "0x45804880De22913dAFE09f4980848ECE6EcbAf78":
      "https://assets.coingecko.com/coins/images/9519/thumb/paxg.PNG?1568542565",
    "0x6982508145454Ce325dDbE47a25d4ec3d2311933":
      "https://assets.coingecko.com/coins/images/29850/large/pepe-token.jpeg?1682922725",
    "0xbC396689893D065F41bc2C6EcbeE5e0085233447":
      "https://assets.coingecko.com/coins/images/12381/thumb/60d18e06844a844ad75901a9_mark_only_03.png?1628674771",
    "0xD8912C10681D8B21Fd3742244f44658dBA12264E":
      "https://assets.coingecko.com/coins/images/1241/thumb/pluton.png?1548331624",
    "0x83e6f1E41cdd28eAcEB20Cb649155049Fac3D5Aa":
      "https://assets.coingecko.com/coins/images/12648/thumb/polkastarter.png?1609813702",
    "0x9992eC3cF6A55b00978cdDF2b27BC6882d88D1eC":
      "https://assets.coingecko.com/coins/images/2784/thumb/inKkF01.png?1605007034",
    "0x57B946008913B82E4dF85f501cbAeD910e58D26C":
      "https://assets.coingecko.com/coins/images/8903/thumb/POND_200x200.png?1622515451",
    "0x595832F8FC6BF59c85C527fEC3740A1b7a361269":
      "https://assets.coingecko.com/coins/images/1104/thumb/power-ledger.png?1547035082",
    "0xb23d80f5FefcDDaa212212F028021B41DEd428CF":
      "https://assets.coingecko.com/coins/images/29053/large/PRIMELOGOOO.png?1676976222",
    "0x226bb599a12C826476e3A771454697EA52E9E220":
      "https://assets.coingecko.com/coins/images/869/thumb/propy.png?1548332100",
    "0x362bc847A3a9637d3af6624EeC853618a43ed7D2":
      "https://assets.coingecko.com/coins/images/11973/thumb/DsNgK0O.png?1596590280",
    "0xfB5c6815cA3AC72Ce9F5006869AE67f18bF77006":
      "https://assets.coingecko.com/coins/images/23931/thumb/PSTAKE_Dark.png?1645709930",
    "0x6c3ea9036406852006290770BEdFcAbA0e23A0e8":
      "https://assets.coingecko.com/coins/images/31212/large/PYUSD_Logo_%282%29.png?1691458314",
    "0x4a220E6096B25EADb88358cb44068A3248254675":
      "https://assets.coingecko.com/coins/images/3370/thumb/5ZOu7brX_400x400.jpg?1612437252",
    "0x4123a133ae3c521FD134D7b13A2dEC35b56c2463":
      "https://assets.coingecko.com/coins/images/17541/thumb/qrdo.png?1630637735",
    "0x99ea4dB9EE77ACD40B119BD1dC4E33e1C070b80d":
      "https://assets.coingecko.com/coins/images/1219/thumb/0_E0kZjb4dG4hUnoDD_.png?1604815917",
    "0x6c28AeF8977c9B773996d0e8376d2EE379446F2f":
      "https://assets.coingecko.com/coins/images/13970/thumb/1_pOU6pBMEmiL-ZJVb0CYRjQ.png?1613386659",
    "0x31c8EAcBFFdD875c74b94b077895Bd78CF1E64A3":
      "https://assets.coingecko.com/coins/images/14013/thumb/radicle.png?1614402918",
    "0x03ab458634910AaD20eF5f1C8ee96F1D6ac54919":
      "https://assets.coingecko.com/coins/images/14004/thumb/RAI-logo-coin.png?1613592334",
    "0xba5BDe662c17e2aDFF1075610382B9B691296350":
      "https://assets.coingecko.com/coins/images/17753/thumb/RARE.jpg?1629220534",
    "0xFca59Cd816aB1eaD66534D82bc21E7515cE441CF":
      "https://assets.coingecko.com/coins/images/11845/thumb/Rari.png?1594946953",
    "0xA4EED63db85311E22dF4473f87CcfC3DaDCFA3E3":
      "https://assets.coingecko.com/coins/images/12629/thumb/200x200.png?1607952509",
    "0x6123B0049F904d730dB3C36a31167D9d4121fA6B":
      "https://assets.coingecko.com/coins/images/15823/thumb/RBN_64x64.png?1633529723",
    "0x408e41876cCCDC0F92210600ef50372656052a38":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x408e41876cCCDC0F92210600ef50372656052a38/logo.png",
    "0x1985365e9f78359a9B6AD760e32412f4a445E862":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x1985365e9f78359a9B6AD760e32412f4a445E862/logo.png",
    "0x221657776846890989a759BA2973e427DfF5C9bB":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x221657776846890989a759BA2973e427DfF5C9bB/logo.png",
    "0x8f8221aFbB33998d8584A2B05749bA73c37a938a":
      "https://assets.coingecko.com/coins/images/1031/thumb/Request_icon_green.png?1643250951",
    "0x557B933a7C2c45672B610F8954A3deB39a51A8Ca":
      "https://assets.coingecko.com/coins/images/12373/thumb/REVV_TOKEN_Refined_2021_%281%29.png?1627652390",
    "0xD291E7a03283640FDc51b121aC401383A46cC623":
      "https://assets.coingecko.com/coins/images/12900/thumb/Rari_Logo_Transparent.png?1613978014",
    "0x607F4C5BB672230e8672085532f7e901544a7375":
      "https://assets.coingecko.com/coins/images/646/thumb/pL1VuXm.png?1604543202",
    "0xf1f955016EcbCd7321c7266BccFB96c68ea5E49b":
      "https://assets.coingecko.com/coins/images/12843/thumb/image.png?1611212077",
    "0x6De037ef9aD2725EB40118Bb1702EBb27e4Aeb24":
      "https://assets.coingecko.com/coins/images/11636/thumb/rndr.png?1638840934",
    "0xfA5047c9c78B8877af97BDcb85Db743fD7313d4a":
      "https://assets.coingecko.com/coins/images/13005/thumb/keeper_dao_logo.jpg?1604316506",
    "0x3845badAde8e6dFF049820680d1F14bD3903a5d0":
      "https://assets.coingecko.com/coins/images/12129/thumb/sandbox_logo.jpg?1597397942",
    "0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE":
      "https://assets.coingecko.com/coins/images/11939/thumb/shiba.png?1622619446",
    "0x7C84e62859D0715eb77d1b1C4154Ecd6aBB21BEC":
      "https://assets.coingecko.com/coins/images/2588/thumb/r_yabKKi_400x400.jpg?1639470164",
    "0x00c83aeCC790e8a4453e5dD3B0B4b3680501a7A7":
      "https://assets.coingecko.com/coins/images/13245/thumb/SKALE_token_300x300.png?1606789574",
    "0xCC8Fa225D80b9c7D42F96e9570156c65D6cAAa25":
      "https://assets.coingecko.com/coins/images/10366/thumb/SLP.png?1578640057",
    "0x744d70FDBE2Ba4CF95131626614a1763DF805B9E":
      "https://assets.coingecko.com/coins/images/779/thumb/status.png?1548610778",
    "0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F/logo.png",
    "0x23B608675a2B2fB1890d3ABBd85c5775c51691d5":
      "https://assets.coingecko.com/coins/images/10717/thumb/qFrcoiM.png?1582525244",
    "0xD31a59c85aE9D8edEFeC411D448f90841571b89c":
      "https://assets.coingecko.com/coins/images/22876/thumb/SOL_wh_small.png?1644224316",
    "0x090185f2135308BaD17527004364eBcC2D37e5F6":
      "https://assets.coingecko.com/coins/images/15861/thumb/abracadabra-3.png?1622544862",
    "0xAf5191B0De278C7286d6C7CC6ab6BB8A73bA2Cd6":
      "https://assets.coingecko.com/coins/images/24413/thumb/STG_LOGO.png?1647654518",
    "0xB64ef51C888972c908CFacf59B47C1AfBC0Ab8aC":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xB64ef51C888972c908CFacf59B47C1AfBC0Ab8aC/logo.png",
    "0x006BeA43Baa3f7A6f765F14f10A1a1b08334EF45":
      "https://assets.coingecko.com/coins/images/1230/thumb/stox-token.png?1547035256",
    "0x0763fdCCF1aE541A5961815C0872A8c5Bc6DE4d7":
      "https://assets.coingecko.com/coins/images/11969/thumb/UmfW5S6f_400x400.jpg?1596602238",
    "0xe53EC727dbDEB9E2d5456c3be40cFF031AB40A55":
      "https://assets.coingecko.com/coins/images/14040/thumb/6YPdWn6.png?1613975899",
    "0x57Ab1ec28D129707052df4dF418D58a2D46d5f51":
      "https://assets.coingecko.com/coins/images/5013/thumb/sUSD.png?1616150765",
    "0x6B3595068778DD592e39A122f4f5a5cF09C90fE2":
      "https://assets.coingecko.com/coins/images/12271/thumb/512x512_Logo_no_chop.png?1606986688",
    "0x0bb217E40F8a5Cb79Adf04E1aAb60E5abd0dfC1e":
      "https://assets.coingecko.com/coins/images/2346/thumb/SWFTCoin.jpg?1618392022",
    "0x8CE9137d39326AD0cD6491fb5CC0CbA0e089b6A9":
      "https://assets.coingecko.com/coins/images/9368/thumb/swipe.png?1566792311",
    "0xf293d23BF2CDc05411Ca0edDD588eb1977e8dcd4":
      "https://assets.coingecko.com/coins/images/6430/thumb/SYLO.svg?1589527756",
    "0x0f2D719407FdBeFF09D87557AbB7232601FD9F29":
      "https://assets.coingecko.com/coins/images/18024/thumb/syn.png?1635002049",
    "0xCdF7028ceAB81fA0C6971208e83fa7872994beE5":
      "https://assets.coingecko.com/coins/images/22228/thumb/nFPNiSbL_400x400.jpg?1641220340",
    "0x18084fbA666a33d37592fA2633fD49a74DD93a88":
      "https://raw.githubusercontent.com/uniswap/assets/master/blockchains/ethereum/assets/0x18084fbA666a33d37592fA2633fD49a74DD93a88/logo.png",
    "0x485d17A6f1B8780392d53D64751824253011A260":
      "https://assets.coingecko.com/coins/images/604/thumb/time-32x32.png?1627130666",
    "0x888888848B652B3E3a0f34c96E00EEC0F3a23F72":
      "https://assets.coingecko.com/coins/images/14676/thumb/kY-C4o7RThfWrDQsLCAG4q4clZhBDDfJQVhWUEKxXAzyQYMj4Jmq1zmFwpRqxhAJFPOa0AsW_PTSshoPuMnXNwq3rU7Imp15QimXTjlXMx0nC088mt1rIwRs75GnLLugWjSllxgzvQ9YrP4tBgclK4_rb17hjnusGj_c0u2fx0AvVokjSNB-v2poTj0xT9BZRCbzRE3-lF1.jpg?1617700061",
    "0x2e9d63788249371f1DFC918a52f8d799F4a38C94":
      "https://assets.coingecko.com/coins/images/17495/thumb/tokemak-avatar-200px-black.png?1628131614",
    "0x2Ab6Bb8408ca3199B8Fa6C92d5b455F820Af03c4":
      "https://assets.coingecko.com/coins/images/2325/thumb/tec.png?1547036538",
    "0xaA7a9CA87d3694B5755f213B5D04094b8d0F0A6F":
      "https://assets.coingecko.com/coins/images/1877/thumb/TRAC.jpg?1635134367",
    "0x88dF592F8eb5D7Bd38bFeF7dEb0fBc02cf3778a0":
      "https://assets.coingecko.com/coins/images/9644/thumb/Blk_icon_current.png?1584980686",
    "0xc7283b66Eb1EB5FB86327f08e1B5816b0720212B":
      "https://assets.coingecko.com/coins/images/14575/thumb/tribe.PNG?1617487954",
    "0x4C19596f5aAfF459fA38B0f7eD92F11AE6543784":
      "https://assets.coingecko.com/coins/images/13180/thumb/truefi_glyph_color.png?1617610941",
    "0xd084B83C305daFD76AE3E1b4E1F1fe2eCcCb3988":
      "https://assets.coingecko.com/coins/images/13330/thumb/virtua_original.png?1656043619",
    "0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828/logo.png",
    "0x441761326490cACF7aF299725B6292597EE822c2":
      "https://assets.coingecko.com/coins/images/13152/thumb/logo-2.png?1605748967",
    "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984":
      "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984/logo.png",
    "0x70D2b7C19352bB76e4409858FF5746e500f2B67c":
      "https://assets.coingecko.com/coins/images/12186/thumb/pawtocol.jpg?1597962008",
    "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
    "0xdAC17F958D2ee523a2206206994597C13D831ec7":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png",
    "0x3C4B6E6e1eA3D4863700D7F76b36B7f3D3f13E3d":
      "https://assets.coingecko.com/coins/images/794/thumb/Voyager-vgx.png?1575693595",
    "0xEDB171C18cE90B633DB442f2A6F72874093b49Ef":
      "https://assets.coingecko.com/coins/images/20825/thumb/photo_2021-11-25_02-05-11.jpg?1637811951",
    "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png",
    "0xc221b7E65FfC80DE234bbB6667aBDd46593D34F0":
      "https://assets.coingecko.com/coins/images/17106/thumb/WCFG.jpg?1626266462",
    "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
    "0x4691937a7508860F876c9c0a2a617E7d9E945D4B":
      "https://assets.coingecko.com/coins/images/12921/thumb/w2UiemF__400x400.jpg?1603670367",
    "0xA2cd3D43c775978A96BdBf12d733D5A1ED94fb18":
      "https://assets.coingecko.com/coins/images/24210/thumb/Chain_icon_200x200.png?1646895054",
    "0x55296f69f40Ea6d20E478533C15A6B08B654E758":
      "https://assets.coingecko.com/coins/images/4519/thumb/XYO_Network-logo.png?1547039819",
    "0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e":
      "https://assets.coingecko.com/coins/images/11849/thumb/yfi-192x192.png?1598325330",
    "0xa1d0E215a23d7030842FC67cE582a6aFa3CCaB83":
      "https://assets.coingecko.com/coins/images/11902/thumb/YFII-logo.78631676.png?1598677348",
    "0x25f8087EAD173b73D6e8B84329989A8eEA16CF73":
      "https://assets.coingecko.com/coins/images/17358/thumb/le1nzlO6_400x400.jpg?1632465691",
    "0xE41d2489571d322189246DaFA5ebDe1F4699F498":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xE41d2489571d322189246DaFA5ebDe1F4699F498/logo.png",
  },
  [ApertureSupportedChainId.ARBITRUM_MAINNET_CHAIN_ID]: {
    "0x6314C31A7a1652cE482cffe247E9CB7c3f4BB9aF":
      "https://assets.coingecko.com/coins/images/13469/thumb/1inch-token.png?1608803028",
    "0xba5DdD1f9d7F570dc94a51479a000E3BCE967196":
      "https://assets.coingecko.com/coins/images/12645/thumb/AAVE.png?1601374110",
    "0xb7910E8b16e63EFD51d5D1a093d56280012A3B9C":
      "https://assets.coingecko.com/coins/images/18125/thumb/lpgblc4h_400x400.jpg?1630570955",
    "0xeC76E8fe6e2242e6c2117caA244B9e2DE1569923":
      "https://assets.coingecko.com/coins/images/14631/thumb/aioz_logo.png?1617413126",
    "0xe7dcD50836d0A28c959c72D72122fEDB8E245A6C":
      "https://assets.coingecko.com/coins/images/11676/thumb/Monochram-aleph.png?1608483725",
    "0xeF6124368c0B56556667e0de77eA008DfC0a71d1":
      "https://assets.coingecko.com/coins/images/22062/thumb/alethea-logo-transparent-colored.png?1642748848",
    "0xC9CBf102c73fb77Ec14f8B4C8bd88e050a6b2646":
      "https://assets.coingecko.com/coins/images/12738/thumb/AlphaToken_256x256.png?1617160876",
    "0x1bfc5d35bf0f7B9e15dc24c78b8C02dbC1e95447":
      "https://assets.coingecko.com/coins/images/4324/thumb/U85xTl2.png?1608111978",
    "0x74885b4D524d497261259B38900f54e6dbAd2210":
      "https://assets.coingecko.com/coins/images/24383/small/apecoin.jpg?1647476455",
    "0xF01dB12F50D0CDF5Fe360ae005b9c52F92CA7811":
      "https://assets.coingecko.com/coins/images/13256/thumb/api3.jpg?1606751424",
    "0x912CE59144191C1204E64559FE8253a0e49E6548":
      "https://arbitrum.foundation/logo.png",
    "0xAC9Ac2C17cdFED4AbC80A53c5553388575714d03":
      "https://assets.coingecko.com/coins/images/15985/thumb/ATA.jpg?1622535745",
    "0x23ee2343B892b1BB63503a4FAbc840E0e2C6810f":
      "https://assets.coingecko.com/coins/images/27277/large/V-65_xQ1_400x400.jpeg",
    "0xe88998Fb579266628aF6a03e3821d5983e5D0089":
      "https://assets.coingecko.com/coins/images/13029/thumb/axie_infinity_logo.png?1604471082",
    "0xBfa641051Ba0a0Ad1b0AcF549a89536A0D76472E":
      "https://assets.coingecko.com/coins/images/13287/thumb/badger_dao_logo.jpg?1607054976",
    "0x040d1EdC9569d4Bab2D15287Dc5A4F10F56a56B8":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xba100000625a3754423978a60c9317c58a424e3D/logo.png",
    "0x3450687EF141dCd6110b77c2DC44B008616AeE75":
      "https://assets.coingecko.com/coins/images/677/thumb/basic-attention-token.png?1547034427",
    "0xa68Ec98D7ca870cF1Dd0b00EBbb7c4bF60A8e74d":
      "https://assets.coingecko.com/coins/images/21061/thumb/biconomy_logo.jpg?1638269749",
    "0x406C8dB506653D882295875F633bEC0bEb921C2A":
      "https://assets.coingecko.com/coins/images/17627/thumb/rI_YptK8.png?1653983088",
    "0xEf171a5BA71348eff16616fd692855c2Fe606EB2":
      "https://assets.coingecko.com/coins/images/28453/large/blur.png?1670745921",
    "0x0D81E50bC677fa67341c44D7eaA9228DEE64A4e1":
      "https://assets.coingecko.com/coins/images/12811/thumb/barnbridge.jpg?1602728853",
    "0x31190254504622cEFdFA55a7d3d272e6462629a2":
      "https://assets.coingecko.com/coins/images/9576/thumb/BUSD.png?1568947766",
    "0x1DEBd73E752bEaF79865Fd6446b0c970EaE7732f":
      "https://assets.coingecko.com/coins/images/27008/large/cbeth.png",
    "0x3a8B787f78D775AECFEEa15706D4221B40F345AB":
      "https://assets.coingecko.com/coins/images/4379/thumb/Celr.png?1554705437",
    "0x354A6dA3fcde098F8389cad84b0182725c6C91dE":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xc00e94Cb662C3520282E6f5717214004A7f26888/logo.png",
    "0x6FE14d3CC2f7bDdffBa5CdB3BBE7467dd81ea101":
      "https://assets.coingecko.com/coins/images/2962/thumb/Coti.png?1559653863",
    "0x8ea3156f834A0dfC78F1A5304fAC2CdA676F354C":
      "https://assets.coingecko.com/coins/images/7310/thumb/oCw2s3GI_400x400.jpeg?1645172042",
    "0x11cDb42B0EB46D95f990BeDD4695A6e3fA034978":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xD533a949740bb3306d119CC777fa900bA034cd52/logo.png",
    "0x319f865b287fCC10b30d8cE6144e8b6D1b476999":
      "https://assets.coingecko.com/coins/images/11038/thumb/cartesi.png?1592288021",
    "0x84F5c2cFba754E76DD5aE4fB369CfC920425E12b":
      "https://assets.coingecko.com/coins/images/14932/thumb/glossy_icon_-_C200px.png?1619073171",
    "0x9DfFB23CAd3322440bCcFF7aB1C58E781dDBF144":
      "https://assets.coingecko.com/coins/images/788/thumb/civic.png?1547034556",
    "0xaAFcFD42c9954C6689ef1901e03db742520829c5":
      "https://assets.coingecko.com/coins/images/15585/thumb/convex.png?1621256328",
    "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png",
    "0x3Be7cB2e9413Ef8F42b4A202a0114EB59b64e227":
      "https://assets.coingecko.com/coins/images/11603/thumb/dext.png?1605790188",
    "0xca642467C6Ebe58c13cB4A7091317f34E17ac05e":
      "https://assets.coingecko.com/coins/images/11955/thumb/image.png?1646041751",
    "0xE3696a02b2C9557639E29d829E9C45EFa49aD47A":
      "https://assets.coingecko.com/coins/images/849/thumb/district0x.png?1547223762",
    "0x4667cf53C4eDF659E402B733BEA42B18B68dd74c":
      "https://assets.coingecko.com/coins/images/12465/thumb/defi_pulse_index_set.png?1600051053",
    "0x51863cB90Ce5d6dA9663106F292fA27c8CC90c5a":
      "https://assets.coingecko.com/coins/images/17500/thumb/hjnIm9bV.jpg?1628009360",
    "0x3e4Cff6E50F37F731284A92d44AE943e17077fD4":
      "https://assets.coingecko.com/coins/images/14962/thumb/6GxcPRo3_400x400.jpg?1619157413",
    "0x7fa9549791EFc9030e1Ed3F25D18014163806758":
      "https://assets.coingecko.com/coins/images/1102/thumb/enjin-coin-logo.png?1547035078",
    "0xfeA31d704DEb0975dA8e77Bf13E04239e70d7c28":
      "https://assets.coingecko.com/coins/images/19785/thumb/acatxTm8_400x400.jpg?1635850140",
    "0x2354c8e9Ea898c751F1A15Addeb048714D667f96":
      "https://assets.coingecko.com/coins/images/14238/thumb/LOGO_HIGH_QUALITY.png?1647831402",
    "0x8553d254Cb6934b16F87D2e486b64BbD24C83C70":
      "https://assets.coingecko.com/coins/images/12304/thumb/Harvest.png?1613016180",
    "0x4BE87C766A7CE11D5Cc864b6C3Abb7457dCC4cC9":
      "https://assets.coingecko.com/coins/images/5681/thumb/Fetch.jpg?1572098136",
    "0x849B40AB2469309117Ed1038c5A99894767C7282":
      "https://assets.coingecko.com/coins/images/12423/thumb/stafi_logo.jpg?1599730991",
    "0x3A1429d50E0cBBc45c997aF600541Fe1cc3D2923":
      "https://assets.coingecko.com/coins/images/25060/thumb/Forta_lgo_%281%29.png?1655353696",
    "0xf929de51D91C77E42f5090069E0AD7A09e513c73":
      "https://assets.coingecko.com/coins/images/9988/thumb/FOX.png?1574330622",
    "0x7468a5d8E02245B00E8C0217fCE021C70Bc51305":
      "https://assets.coingecko.com/coins/images/13422/thumb/frax_logo.png?1608476506",
    "0xd42785D323e608B9E99fa542bd8b1000D4c2Df37":
      "https://assets.coingecko.com/coins/images/4001/thumb/Fantom.png?1558015016",
    "0xd9f9d2Ee2d3EFE420699079f16D9e924affFdEA4":
      "https://assets.coingecko.com/coins/images/13423/thumb/frax_share.png?1608478989",
    "0x2Ed5C8fA0D7Ad71025638aFe5fd72E4fbc5A2667":
      "https://assets.coingecko.com/coins/images/12493/thumb/GALA-COINGECKO.png?1600233435",
    "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a":
      "https://assets.coingecko.com/coins/images/18323/large/arbit.png?1631532468",
    "0xa0b862F60edEf4452F25B4160F177db44DeB6Cf1":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6810e776880C02933D47DB1b9fc05908e5386b96/logo.png",
    "0x9623063377AD1B27544C965cCd7342f7EA7e88C7":
      "https://assets.coingecko.com/coins/images/13397/thumb/Graph_Token.png?1608145566",
    "0x7f9a7DB853Ca816B9A138AEe3380Ef34c437dEe0":
      "https://assets.coingecko.com/coins/images/15810/thumb/gitcoin.png?1621992929",
    "0x589d35656641d6aB57A545F08cf473eCD9B6D5F7":
      "https://assets.coingecko.com/coins/images/14191/thumb/icon_gyen_200_200.png?1614843343",
    "0xd12Eeb0142D4Efe7Af82e4f29E5Af382615bcEeA":
      "https://assets.coingecko.com/coins/images/18973/thumb/logosq200200Coingecko.png?1634090470",
    "0x177F394A3eD18FAa85c1462Ae626438a70294EF7":
      "https://assets.coingecko.com/coins/images/14061/thumb/Shared_HOPR_logo_512px.png?1614073468",
    "0x61cA9D186f6b9a793BC08F6C79fd35f205488673":
      "https://assets.coingecko.com/coins/images/14468/large/ILV.JPG",
    "0x3cFD99593a7F035F717142095a3898e3Fca7783e":
      "https://assets.coingecko.com/coins/images/17233/thumb/imx.png?1636691817",
    "0x2A2053cb633CAD465B4A8975eD3d7f09DF608F80":
      "https://assets.coingecko.com/coins/images/12882/thumb/Secondary_Symbol.png?1628233237",
    "0x13Ad51ed4F1B7e9Dc168d8a00cB3f4dDD85EfA60":
      "https://assets.coingecko.com/coins/images/13573/thumb/Lido_DAO.png?1609873644",
    "0xf97f4df75117a78c1A5a0DBb814Af92458539FB4":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x514910771AF9Ca656af840dff83E8264EcF986CA/logo.png",
    "0x289ba1701C2F088cf0faf8B3705246331cB8A839":
      "https://assets.coingecko.com/coins/images/7137/thumb/logo-circle-green.png?1619593365",
    "0xfb9E5D956D889D91a82737B9bFCDaC1DCE3e1449":
      "https://assets.coingecko.com/coins/images/14665/thumb/200-lqty-icon.png?1617631180",
    "0x46d0cE7de6247b0A95f67b43B589b4041BaE7fbE":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xBBbbCA6A901c926F240b89EacB641d8Aec7AEafD/logo.png",
    "0x93b346b6BC2548dA6A1E7d98E9a421B42541425b":
      "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/arbitrum/assets/0x93b346b6BC2548dA6A1E7d98E9a421B42541425b/logo.png",
    "0x442d24578A564EF628A65e6a7E3e7be2a165E231":
      "https://assets.coingecko.com/coins/images/878/thumb/decentraland-mana.png?1550108745",
    "0x533A7B414CD1236815a5e09F1E97FC7d5c313739":
      "https://assets.coingecko.com/coins/images/14051/thumb/Mask_Network.jpg?1614050316",
    "0x99F40b01BA9C469193B360f72740E416B17Ac332":
      "https://assets.coingecko.com/coins/images/11335/thumb/2020-05-19-token-200.png?1589940590",
    "0x561877b6b3DD7651313794e5F2894B2F18bE0766":
      "https://assets.coingecko.com/coins/images/4713/thumb/matic-token-icon.png?1624446912",
    "0x7F728F3595db17B0B359f4FC47aE80FAd2e33769":
      "https://assets.coingecko.com/coins/images/15595/thumb/metis.jpeg?1660285312",
    "0xB20A02dfFb172C474BC4bDa3fD6f4eE70C04daf2":
      "https://assets.coingecko.com/coins/images/16786/thumb/mimlogopng.png?1624979612",
    "0x2e9a6Df78E42a30712c10a9Dc4b1C8656f8F2879":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2/logo.png",
    "0x8f5c1A99b1df736Ad685006Cb6ADCA7B7Ae4b514":
      "https://assets.coingecko.com/coins/images/605/thumb/melon.png?1547034295",
    "0x29024832eC3baBF5074D4F46102aA988097f0Ca0":
      "https://assets.coingecko.com/coins/images/14097/thumb/photo_2021-05-03_14.20.41.jpeg?1620022863",
    "0x53236015A675fcB937485F1AE58040e4Fb920d5b":
      "https://assets.coingecko.com/coins/images/2843/thumb/ImcYCVfX_400x400.jpg?1628519767",
    "0xBE06ca305A5Cb49ABf6B1840da7c42690406177b":
      "https://assets.coingecko.com/coins/images/3375/thumb/nkn.png?1548329212",
    "0x597701b32553b9fa473e21362D480b3a6B569711":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x1776e1F26f98b1A5dF9cD347953a26dd3Cb46671/logo.png",
    "0x933d31561e470478079FEB9A6Dd2691fAD8234DF":
      "https://assets.coingecko.com/coins/images/3687/thumb/ocean-protocol-logo.jpg?1547038686",
    "0x6FEb262FEb0f775B5312D2e009923f7f58AE423E":
      "https://assets.coingecko.com/coins/images/3296/thumb/op.jpg?1547037878",
    "0xd962C1895c46AC0378C502c207748b7061421e8e":
      "https://assets.coingecko.com/coins/images/776/thumb/OMG_Network.jpg?1591167168",
    "0xfEb4DfC8C4Cf7Ed305bb08065D08eC6ee6728429":
      "https://assets.coingecko.com/coins/images/9519/thumb/paxg.PNG?1568542565",
    "0x35E6A59F786d9266c7961eA28c7b768B33959cbB":
      "https://assets.coingecko.com/coins/images/29850/large/pepe-token.jpeg?1682922725",
    "0x753D224bCf9AAFaCD81558c32341416df61D3DAC":
      "https://assets.coingecko.com/coins/images/12381/thumb/60d18e06844a844ad75901a9_mark_only_03.png?1628674771",
    "0xeeeB5EaC2dB7A7Fc28134aA3248580d48b016b64":
      "https://assets.coingecko.com/coins/images/12648/thumb/polkastarter.png?1609813702",
    "0xE12F29704F635F4A6E7Ae154838d21F9B33809e9":
      "https://assets.coingecko.com/coins/images/2784/thumb/inKkF01.png?1605007034",
    "0xdA0a57B710768ae17941a9Fa33f8B720c8bD9ddD":
      "https://assets.coingecko.com/coins/images/8903/thumb/POND_200x200.png?1622515451",
    "0x4e91F2AF1ee0F84B529478f19794F5AFD423e4A6":
      "https://assets.coingecko.com/coins/images/1104/thumb/power-ledger.png?1547035082",
    "0x8d8e1b6ffc6832E8D2eF0DE8a3d957cAE7ac5067":
      "https://assets.coingecko.com/coins/images/29053/large/PRIMELOGOOO.png?1676976222",
    "0x82164a8B646401a8776F9dC5c8Cba35DcAf60Cd2":
      "https://assets.coingecko.com/coins/images/11973/thumb/DsNgK0O.png?1596590280",
    "0xC7557C73e0eCa2E1BF7348bB6874Aee63C7eFF85":
      "https://assets.coingecko.com/coins/images/3370/thumb/5ZOu7brX_400x400.jpg?1612437252",
    "0xaeF5bbcbFa438519a5ea80B4c7181B4E78d419f2":
      "https://assets.coingecko.com/coins/images/14004/thumb/RAI-logo-coin.png?1613592334",
    "0xCF8600347Dc375C5f2FdD6Dab9BB66e0b6773cd7":
      "https://assets.coingecko.com/coins/images/11845/thumb/Rari.png?1594946953",
    "0x2E9AE8f178d5Ea81970C7799A377B3985cbC335F":
      "https://assets.coingecko.com/coins/images/12629/thumb/200x200.png?1607952509",
    "0x9fA891e1dB0a6D1eEAC4B929b5AAE1011C79a204":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x408e41876cCCDC0F92210600ef50372656052a38/logo.png",
    "0x1Cb5bBc64e148C5b889E3c667B49edF78BB92171":
      "https://assets.coingecko.com/coins/images/1031/thumb/Request_icon_green.png?1643250951",
    "0xef888bcA6AB6B1d26dbeC977C455388ecd794794":
      "https://assets.coingecko.com/coins/images/12900/thumb/Rari_Logo_Transparent.png?1613978014",
    "0xC8a4EeA31E9B6b61c406DF013DD4FEc76f21E279":
      "https://assets.coingecko.com/coins/images/11636/thumb/rndr.png?1638840934",
    "0xd1318eb19DBF2647743c720ed35174efd64e3DAC":
      "https://assets.coingecko.com/coins/images/12129/thumb/sandbox_logo.jpg?1597397942",
    "0x5033833c9fe8B9d3E09EEd2f73d2aaF7E3872fd1":
      "https://assets.coingecko.com/coins/images/11939/thumb/shiba.png?1622619446",
    "0x4F9b7DEDD8865871dF65c5D26B1c2dD537267878":
      "https://assets.coingecko.com/coins/images/13245/thumb/SKALE_token_300x300.png?1606789574",
    "0x707F635951193dDaFBB40971a0fCAAb8A6415160":
      "https://assets.coingecko.com/coins/images/779/thumb/status.png?1548610778",
    "0xcBA56Cd8216FCBBF3fA6DF6137F3147cBcA37D60":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F/logo.png",
    "0xb2BE52744a804Cc732d606817C2572C5A3B264e7":
      "https://assets.coingecko.com/coins/images/10717/thumb/qFrcoiM.png?1582525244",
    "0xb74Da9FE2F96B9E0a5f4A3cf0b92dd2bEC617124":
      "https://assets.coingecko.com/coins/images/22876/thumb/SOL_wh_small.png?1644224316",
    "0x3E6648C5a70A150A88bCE65F4aD4d506Fe15d2AF":
      "https://assets.coingecko.com/coins/images/15861/thumb/abracadabra-3.png?1622544862",
    "0xe018C7a3d175Fb0fE15D70Da2c874d3CA16313EC":
      "https://assets.coingecko.com/coins/images/24413/thumb/STG_LOGO.png?1647654518",
    "0xE6320ebF209971b4F4696F7f0954b8457Aa2FCC2":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xB64ef51C888972c908CFacf59B47C1AfBC0Ab8aC/logo.png",
    "0x7f9cf5a2630a0d58567122217dF7609c26498956":
      "https://assets.coingecko.com/coins/images/14040/thumb/6YPdWn6.png?1613975899",
    "0xA970AF1a584579B618be4d69aD6F73459D112F95":
      "https://assets.coingecko.com/coins/images/5013/thumb/sUSD.png?1616150765",
    "0xd4d42F0b6DEF4CE0383636770eF773390d85c61A":
      "https://assets.coingecko.com/coins/images/12271/thumb/512x512_Logo_no_chop.png?1606986688",
    "0x1bCfc0B4eE1471674cd6A9F6B363A034375eAD84":
      "https://assets.coingecko.com/coins/images/18024/thumb/syn.png?1635002049",
    "0x0945Cae3ae47cb384b2d47BC448Dc6A9dEC21F55":
      "https://assets.coingecko.com/coins/images/22228/thumb/nFPNiSbL_400x400.jpg?1641220340",
    "0x7E2a1eDeE171C5B19E6c54D73752396C0A572594":
      "https://raw.githubusercontent.com/uniswap/assets/master/blockchains/ethereum/assets/0x18084fbA666a33d37592fA2633fD49a74DD93a88/logo.png",
    "0xd58D345Fd9c82262E087d2D0607624B410D88242":
      "https://assets.coingecko.com/coins/images/9644/thumb/Blk_icon_current.png?1584980686",
    "0xBfAE6fecD8124ba33cbB2180aAb0Fe4c03914A5A":
      "https://assets.coingecko.com/coins/images/14575/thumb/tribe.PNG?1617487954",
    "0xd693Ec944A85eeca4247eC1c3b130DCa9B0C3b22":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828/logo.png",
    "0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0":
      "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/arbitrum/assets/0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0/logo.png",
    "0xaf88d065e77c8cC2239327C5EDb3A432268e5831":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
    "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
    "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png",
    "0x1c8Ec4DE3c2BFD3050695D89853EC6d78AE650bb":
      "https://assets.coingecko.com/coins/images/20825/thumb/photo_2021-11-25_02-05-11.jpg?1637811951",
    "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png",
    "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
    "0xcAFcD85D8ca7Ad1e1C6F82F651fA15E33AEfD07b":
      "https://assets.coingecko.com/coins/images/12921/thumb/w2UiemF__400x400.jpg?1603670367",
    "0x58BbC087e36Db40a84b22c1B93a042294deEAFEd":
      "https://assets.coingecko.com/coins/images/24210/thumb/Chain_icon_200x200.png?1646895054",
    "0x82e3A8F066a6989666b031d916c43672085b1582":
      "https://assets.coingecko.com/coins/images/11849/thumb/yfi-192x192.png?1598325330",
    "0xBD591Bd4DdB64b77B5f76Eab8f03d02519235Ae2":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xE41d2489571d322189246DaFA5ebDe1F4699F498/logo.png",
    "0x1C986661170c1834db49C3830130D4038eEeb866": "/tokenIcons/aptr-logo.svg",
    "0xdc5F1BF636DcAdaE7e285A484Dc71A1F5adeE0A1":
      "/tokenIcons/airdrop-logo.jpg",
  },
  [ApertureSupportedChainId.MANTA_PACIFIC_MAINNET_CHAIN_ID]: {
    "0x95CeF13441Be50d20cA4558CC0a27B601aC544E5": "/tokenIcons/manta-logo.svg",
    "0x0Dc808adcE2099A9F62AA87D9670745AbA741746":
      "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
    "0xb73603C5d87fA094B7314C74ACE2e64D165016fb":
      "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
    "0x305E88d809c9DC03179554BFbf85Ac05Ce8F18d6":
      "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png",
    "0xf417F5A458eC102B90352F697D6e2Ac3A3d2851f":
      "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png",
    "0x1c466b9371f8aBA0D7c458bE10a62192Fcb8Aa71":
      "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png",
    "0x2FE3AD97a60EB7c79A976FC18Bb5fFD07Dd94BA5":
      "https://assets.coingecko.com/coins/images/18834/large/wstETH.png?1633565443",
    "0x6E9655611b42C10b9aF25B6ca08bE349Df45c370":
      "https://assets.coingecko.com/coins/images/20764/large/reth.png?1637652366",
    "0xEc901DA9c68E90798BbBb74c11406A32A70652C3": "/tokenIcons/stone-logo.svg",
    "0x6Fae4D9935E2fcb11fC79a64e917fb2BF14DaFaa":
      "https://assets.coingecko.com/coins/images/23994/large/tia.png?1645952362",
    "0x20A512dbdC0D006f46E6cA11329034Eb3d18c997":
      "/tokenIcons/layerbank-logo.svg",
    "0xbdAd407F77f44F7Da6684B416b1951ECa461FB07":
      "https://assets.coingecko.com/coins/images/31719/large/usdm.png?1694587333",
  },
  [ApertureSupportedChainId.OPTIMISM_MAINNET_CHAIN_ID]: {
    "0xAd42D013ac31486B73b6b059e748172994736426":
      "https://assets.coingecko.com/coins/images/13469/thumb/1inch-token.png?1608803028",
    "0x76FB31fb4af56892A25e32cFC43De717950c9278":
      "https://assets.coingecko.com/coins/images/12645/thumb/AAVE.png?1601374110",
    "0xFE8B128bA8C78aabC59d4c64cEE7fF28e9379921":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xba100000625a3754423978a60c9317c58a424e3D/logo.png",
    "0x3e7eF8f50246f725885102E8238CBba33F276747":
      "https://assets.coingecko.com/coins/images/12811/thumb/barnbridge.jpg?1602728853",
    "0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39":
      "https://assets.coingecko.com/coins/images/9576/thumb/BUSD.png?1568947766",
    "0xadDb6A0412DE1BA0F936DCaeb8Aaa24578dcF3B2":
      "https://assets.coingecko.com/coins/images/27008/large/cbeth.png",
    "0x0994206dfE8De6Ec6920FF4D779B0d950605Fb53":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xD533a949740bb3306d119CC777fa900bA034cd52/logo.png",
    "0xEc6adef5E1006bb305bB1975333e8fc4071295bf":
      "https://assets.coingecko.com/coins/images/11038/thumb/cartesi.png?1592288021",
    "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png",
    "0x65559aA14915a70190438eF90104769e5E890A00":
      "https://assets.coingecko.com/coins/images/19785/thumb/acatxTm8_400x400.jpg?1635850140",
    "0xD8737CA46aa6285dE7B8777a8e3db232911baD41":
      "https://assets.coingecko.com/coins/images/12423/thumb/stafi_logo.jpg?1599730991",
    "0xF1a0DA3367BC7aa04F8D94BA57B862ff37CeD174":
      "https://assets.coingecko.com/coins/images/9988/thumb/FOX.png?1574330622",
    "0x2E3D870790dC77A83DD1d18184Acc7439A53f475":
      "https://assets.coingecko.com/coins/images/13422/thumb/frax_logo.png?1608476506",
    "0x67CCEA5bb16181E7b4109c9c2143c24a1c2205Be":
      "https://assets.coingecko.com/coins/images/13423/thumb/frax_share.png?1608478989",
    "0x1EBA7a6a72c894026Cd654AC5CDCF83A46445B08":
      "https://assets.coingecko.com/coins/images/15810/thumb/gitcoin.png?1621992929",
    "0x589d35656641d6aB57A545F08cf473eCD9B6D5F7":
      "https://assets.coingecko.com/coins/images/14191/thumb/icon_gyen_200_200.png?1614843343",
    "0xFdb794692724153d1488CcdBE0C56c252596735F":
      "https://assets.coingecko.com/coins/images/13573/thumb/Lido_DAO.png?1609873644",
    "0x350a791Bfc2C21F9Ed5d10980Dad2e2638ffa7f6":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x514910771AF9Ca656af840dff83E8264EcF986CA/logo.png",
    "0xc40F949F8a4e094D1b49a23ea9241D289B7b2819":
      "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/optimism/assets/0xc40F949F8a4e094D1b49a23ea9241D289B7b2819/logo.png",
    "0x3390108E913824B8eaD638444cc52B9aBdF63798":
      "https://assets.coingecko.com/coins/images/14051/thumb/Mask_Network.jpg?1614050316",
    "0xab7bAdEF82E9Fe11f6f33f87BC9bC2AA27F2fCB5":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2/logo.png",
    "0x4200000000000000000000000000000000000042":
      "https://ethereum-optimism.github.io/data/OP/logo.png",
    "0xC1c167CC44f7923cd0062c4370Df962f9DDB16f5":
      "https://assets.coingecko.com/coins/images/29850/large/pepe-token.jpeg?1682922725",
    "0x9e1028F5F1D5eDE59748FFceE5532509976840E0":
      "https://assets.coingecko.com/coins/images/12381/thumb/60d18e06844a844ad75901a9_mark_only_03.png?1628674771",
    "0x7FB688CCf682d58f86D7e38e03f9D22e7705448B":
      "https://assets.coingecko.com/coins/images/14004/thumb/RAI-logo-coin.png?1613592334",
    "0xB548f63D4405466B36C0c0aC3318a22fDcec711a":
      "https://assets.coingecko.com/coins/images/12900/thumb/Rari_Logo_Transparent.png?1613978014",
    "0x8700dAec35aF8Ff88c16BdF0418774CB3D7599B4":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F/logo.png",
    "0xba1Cf949c382A32a09A17B2AdF3587fc7fA664f1":
      "https://assets.coingecko.com/coins/images/22876/thumb/SOL_wh_small.png?1644224316",
    "0xEf6301DA234fC7b0545c6E877D3359FE0B9E50a4":
      "https://assets.coingecko.com/coins/images/11969/thumb/UmfW5S6f_400x400.jpg?1596602238",
    "0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9":
      "https://assets.coingecko.com/coins/images/5013/thumb/sUSD.png?1616150765",
    "0x3eaEb77b03dBc0F6321AE1b72b2E9aDb0F60112B":
      "https://assets.coingecko.com/coins/images/12271/thumb/512x512_Logo_no_chop.png?1606986688",
    "0x747e42Eb0591547a0ab429B3627816208c734EA7":
      "https://assets.coingecko.com/coins/images/22228/thumb/nFPNiSbL_400x400.jpg?1641220340",
    "0xaf8cA653Fa2772d58f4368B0a71980e9E3cEB888":
      "https://assets.coingecko.com/coins/images/9644/thumb/Blk_icon_current.png?1584980686",
    "0xE7798f023fC62146e8Aa1b36Da45fb70855a77Ea":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828/logo.png",
    "0x6fd9d7AD17242c41f7131d257212c54A0e816691":
      "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/optimism/assets/0x6fd9d7AD17242c41f7131d257212c54A0e816691/logo.png",
    "0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
    "0x7F5c764cBc14f9669B88837ca1490cCa17c31607":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
    "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png",
    "0x68f180fcCe6836688e9084f035309E29Bf0A2095":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png",
    "0x4200000000000000000000000000000000000006":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
    "0x871f2F2ff935FD1eD867842FF2a7bfD051A5E527":
      "https://assets.coingecko.com/coins/images/12921/thumb/w2UiemF__400x400.jpg?1603670367",
    "0xD1917629B3E6A72E6772Aab5dBe58Eb7FA3C2F33":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xE41d2489571d322189246DaFA5ebDe1F4699F498/logo.png",
  },
  [ApertureSupportedChainId.POLYGON_MAINNET_CHAIN_ID]: {
    "0x9c2C5fd7b07E95EE044DDeba0E97a665F142394f":
      "https://assets.coingecko.com/coins/images/13469/thumb/1inch-token.png?1608803028",
    "0xD6DF932A45C0f255f85145f286eA0b292B21C90B":
      "https://assets.coingecko.com/coins/images/12645/thumb/AAVE.png?1601374110",
    "0xdDa7b23D2D72746663E7939743f929a3d85FC975":
      "https://assets.coingecko.com/coins/images/847/thumb/Ambire_AdEx_Symbol_color.png?1655432540",
    "0x6a6bD53d677F8632631662C48bD47b1D4D6524ee":
      "https://assets.coingecko.com/coins/images/18125/thumb/lpgblc4h_400x400.jpg?1630570955",
    "0xe2341718c6C0CbFa8e6686102DD8FbF4047a9e9B":
      "https://assets.coingecko.com/coins/images/14631/thumb/aioz_logo.png?1617413126",
    "0x95c300e7740D2A88a44124B424bFC1cB2F9c3b89":
      "https://assets.coingecko.com/coins/images/14113/thumb/Alchemix.png?1614409874",
    "0x82dCf1Df86AdA26b2dCd9ba6334CeDb8c2448e9e":
      "https://assets.coingecko.com/coins/images/11676/thumb/Monochram-aleph.png?1608483725",
    "0xbFc70507384047Aa74c29Cdc8c5Cb88D0f7213AC":
      "https://assets.coingecko.com/coins/images/22062/thumb/alethea-logo-transparent-colored.png?1642748848",
    "0x50858d870FAF55da2fD90FB6DF7c34b5648305C6":
      "https://assets.coingecko.com/coins/images/14375/thumb/alice_logo.jpg?1615782968",
    "0x3AE490db48d74B1bC626400135d4616377D0109f":
      "https://assets.coingecko.com/coins/images/12738/thumb/AlphaToken_256x256.png?1617160876",
    "0x0621d647cecbFb64b79E44302c1933cB4f27054d":
      "https://assets.coingecko.com/coins/images/12409/thumb/amp-200x200.png?1599625397",
    "0x101A023270368c0D50BFfb62780F4aFd4ea79C35":
      "https://assets.coingecko.com/coins/images/4324/thumb/U85xTl2.png?1608111978",
    "0x2b8504ab5eFc246d0eC5Ec7E74565683227497de":
      "https://assets.coingecko.com/coins/images/681/thumb/JelZ58cv_400x400.png?1601449653",
    "0xB7b31a6BC18e48888545CE79e83E06003bE70930":
      "https://assets.coingecko.com/coins/images/24383/small/apecoin.jpg?1647476455",
    "0x45C27821E80F8789b60Fd8B600C73815d34DDa6C":
      "https://assets.coingecko.com/coins/images/13256/thumb/api3.jpg?1606751424",
    "0xEE800B277A96B0f490a1A732e1D6395FAD960A26":
      "https://assets.coingecko.com/coins/images/8506/thumb/9u0a23XY_400x400.jpg?1559027357",
    "0x04bEa9FCE76943E90520489cCAb84E84C0198E29":
      "https://assets.coingecko.com/coins/images/1019/thumb/Airswap.png?1630903484",
    "0x0df0f72EE0e5c9B7ca761ECec42754992B2Da5BF":
      "https://assets.coingecko.com/coins/images/15985/thumb/ATA.jpg?1622535745",
    "0x5eB8D998371971D01954205c7AFE90A7AF6a95AC":
      "https://assets.coingecko.com/coins/images/12913/thumb/AudiusCoinLogo_2x.png?1603425727",
    "0x61BDD9C7d4dF4Bf47A4508c0c8245505F2Af5b7b":
      "https://assets.coingecko.com/coins/images/13029/thumb/axie_infinity_logo.png?1604471082",
    "0x1FcbE5937B0cc2adf69772D228fA4205aCF4D9b2":
      "https://assets.coingecko.com/coins/images/13287/thumb/badger_dao_logo.jpg?1607054976",
    "0x9a71012B13CA4d3D0Cdc72A177DF3ef03b0E76A3":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xba100000625a3754423978a60c9317c58a424e3D/logo.png",
    "0xA8b1E0764f85f53dfe21760e8AfE5446D82606ac":
      "https://assets.coingecko.com/coins/images/9545/thumb/band-protocol.png?1568730326",
    "0x3Cef98bb43d732E2F285eE605a8158cDE967D219":
      "https://assets.coingecko.com/coins/images/677/thumb/basic-attention-token.png?1547034427",
    "0x91c89A94567980f0e9723b487b0beD586eE96aa7":
      "https://assets.coingecko.com/coins/images/21061/thumb/biconomy_logo.jpg?1638269749",
    "0x438B28C5AA5F00a817b7Def7cE2Fb3d5d1970974":
      "https://assets.coingecko.com/coins/images/2848/thumb/ColorIcon_3x.png?1622516510",
    "0xc26D47d5c33aC71AC5CF9F776D63Ba292a4F7842":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x1F573D6Fb3F13d689FF844B4cE37794d79a7FF1C/logo.png",
    "0xa4B2B20b2C73c7046ED19AC6bfF5E5285c58F20a":
      "https://assets.coingecko.com/coins/images/20285/thumb/BOBA.png?1636811576",
    "0xA041544fe2BE56CCe31Ebb69102B965E06aacE80":
      "https://assets.coingecko.com/coins/images/12811/thumb/barnbridge.jpg?1602728853",
    "0xdAb529f40E671A1D4bF91361c21bf9f0C9712ab7":
      "https://assets.coingecko.com/coins/images/9576/thumb/BUSD.png?1568947766",
    "0x91a4635F620766145C099E15889Bd2766906A559":
      "https://assets.coingecko.com/coins/images/4379/thumb/Celr.png?1554705437",
    "0x594C984E3318e91313f881B021A0C4203fF5E59F":
      "https://assets.coingecko.com/coins/images/5000/thumb/Chromia.png?1559038018",
    "0xf1938Ce12400f9a761084E7A80d37e732a4dA056":
      "https://assets.coingecko.com/coins/images/8834/thumb/Chiliz.png?1561970540",
    "0x8505b9d2254A7Ae468c0E9dd10Ccea3A837aef5c":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xc00e94Cb662C3520282E6f5717214004A7f26888/logo.png",
    "0x93B0fF1C8828F6eB039D345Ff681eD735086d925":
      "https://assets.coingecko.com/coins/images/14168/thumb/covalent-cqt.png?1624545218",
    "0xAdA58DF0F643D959C2A47c9D4d4c1a4deFe3F11C":
      "https://assets.coingecko.com/coins/images/7310/thumb/oCw2s3GI_400x400.jpeg?1645172042",
    "0x172370d5Cd63279eFa6d502DAB29171933a610AF":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xD533a949740bb3306d119CC777fa900bA034cd52/logo.png",
    "0x2727Ab1c2D22170ABc9b595177B2D5C6E1Ab7B7B":
      "https://assets.coingecko.com/coins/images/11038/thumb/cartesi.png?1592288021",
    "0x8c208BC2A808a088a78398fed8f2640cab0b6EDb":
      "https://assets.coingecko.com/coins/images/14932/thumb/glossy_icon_-_C200px.png?1619073171",
    "0x276C9cbaa4BDf57d7109a41e67BD09699536FA3d":
      "https://assets.coingecko.com/coins/images/10687/thumb/CUBE_icon.png?1617026861",
    "0x66Dc5A08091d1968e08C16aA5b27BAC8398b02Be":
      "https://assets.coingecko.com/coins/images/788/thumb/civic.png?1547034556",
    "0x4257EA7637c355F81616050CbB6a9b709fd72683":
      "https://assets.coingecko.com/coins/images/15585/thumb/convex.png?1621256328",
    "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png",
    "0x26f5FB1e6C8a65b3A873fF0a213FA16EFF5a7828":
      "https://assets.coingecko.com/coins/images/13453/thumb/ddx_logo.png?1608741641",
    "0xff835562C761205659939B64583dd381a6AA4D92":
      "https://assets.coingecko.com/coins/images/11603/thumb/dext.png?1605790188",
    "0x993f2CafE9dbE525243f4A78BeBC69DAc8D36000":
      "https://assets.coingecko.com/coins/images/11955/thumb/image.png?1646041751",
    "0x85955046DF4668e1DD369D2DE9f3AEB98DD2A369":
      "https://assets.coingecko.com/coins/images/12465/thumb/defi_pulse_index_set.png?1600051053",
    "0x4C3bF0a3DE9524aF68327d1D2558a3B70d17D42a":
      "https://assets.coingecko.com/coins/images/17500/thumb/hjnIm9bV.jpg?1628009360",
    "0xE0339c80fFDE91F3e20494Df88d4206D86024cdF":
      "https://assets.coingecko.com/coins/images/14962/thumb/6GxcPRo3_400x400.jpg?1619157413",
    "0x7eC26842F195c852Fa843bB9f6D8B583a274a157":
      "https://assets.coingecko.com/coins/images/1102/thumb/enjin-coin-logo.png?1547035078",
    "0xbD7A5Cf51d22930B8B3Df6d834F9BCEf90EE7c4f":
      "https://assets.coingecko.com/coins/images/19785/thumb/acatxTm8_400x400.jpg?1635850140",
    "0x0E50BEA95Fe001A370A4F1C220C49AEdCB982DeC":
      "https://assets.coingecko.com/coins/images/14238/thumb/LOGO_HIGH_QUALITY.png?1647831402",
    "0x8a037dbcA8134FFc72C362e394e35E0Cad618F85":
      "https://assets.coingecko.com/coins/images/26045/thumb/euro-coin.png?1655394420",
    "0x176f5AB638cf4Ff3B6239Ba609C3fadAA46ef5B0":
      "https://assets.coingecko.com/coins/images/12304/thumb/Harvest.png?1613016180",
    "0x7583FEDDbceFA813dc18259940F76a02710A8905":
      "https://assets.coingecko.com/coins/images/5681/thumb/Fetch.jpg?1572098136",
    "0x7A7B94F18EF6AD056CDa648588181CDA84800f94":
      "https://assets.coingecko.com/coins/images/12423/thumb/stafi_logo.jpg?1599730991",
    "0x9ff62d1FC52A907B6DCbA8077c2DDCA6E6a9d3e1":
      "https://assets.coingecko.com/coins/images/25060/thumb/Forta_lgo_%281%29.png?1655353696",
    "0x5eCbA59DAcc1ADc5bDEA35f38A732823fc3dE977":
      "https://assets.coingecko.com/coins/images/14917/thumb/photo_2021-04-22_00.00.03.jpeg?1619020835",
    "0x65A05DB8322701724c197AF82C9CaE41195B0aA8":
      "https://assets.coingecko.com/coins/images/9988/thumb/FOX.png?1574330622",
    "0x104592a158490a9228070E0A8e5343B499e125D0":
      "https://assets.coingecko.com/coins/images/13422/thumb/frax_logo.png?1608476506",
    "0xC9c1c1c20B3658F8787CC2FD702267791f224Ce1":
      "https://assets.coingecko.com/coins/images/4001/thumb/Fantom.png?1558015016",
    "0x3e121107F6F22DA4911079845a470757aF4e1A1b":
      "https://assets.coingecko.com/coins/images/13423/thumb/frax_share.png?1608478989",
    "0x09E1943Dd2A4e82032773594f50CF54453000b97":
      "https://assets.coingecko.com/coins/images/12493/thumb/GALA-COINGECKO.png?1600233435",
    "0x385Eeac5cB85A38A9a07A70c73e0a3271CfB54A7":
      "https://assets.coingecko.com/coins/images/12467/thumb/ghst_200.png?1600750321",
    "0x0B220b82F3eA3B7F6d9A1D8ab58930C064A2b5Bf":
      "https://assets.coingecko.com/coins/images/542/thumb/Golem_Submark_Positive_RGB.png?1606392013",
    "0x5FFD62D3C3eE2E81C00A7b9079FB248e7dF024A8":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6810e776880C02933D47DB1b9fc05908e5386b96/logo.png",
    "0xF88fc6b493eda7650E4bcf7A290E8d108F677CfE":
      "https://assets.coingecko.com/coins/images/17139/thumb/10631.png?1635718182",
    "0x5fe2B58c013d7601147DcdD68C143A77499f5531":
      "https://assets.coingecko.com/coins/images/13397/thumb/Graph_Token.png?1608145566",
    "0xdb95f9188479575F3F718a245EcA1B3BF74567EC":
      "https://assets.coingecko.com/coins/images/15810/thumb/gitcoin.png?1621992929",
    "0xC8A94a3d3D2dabC3C1CaffFFDcA6A7543c3e3e65":
      "https://assets.coingecko.com/coins/images/5992/thumb/gemini-dollar-gusd.png?1536745278",
    "0x482bc619eE7662759CDc0685B4E78f464Da39C73":
      "https://assets.coingecko.com/coins/images/14191/thumb/icon_gyen_200_200.png?1614843343",
    "0x6cCBF3627b2C83AFEF05bf2F035E7f7B210Fe30D":
      "https://assets.coingecko.com/coins/images/14061/thumb/Shared_HOPR_logo_512px.png?1614073468",
    "0x9Cb74C8032b007466865f060ad2c46145d45553D":
      "https://assets.coingecko.com/coins/images/2565/thumb/logomark-purple-286x286.png?1638362736",
    "0xFA46dAf9909e116DBc40Fe1cC95fC0Bb1f452aBE":
      "https://assets.coingecko.com/coins/images/14468/large/ILV.JPG",
    "0x183070C90B34A63292cC908Ce1b263Cb56D49A7F":
      "https://assets.coingecko.com/coins/images/17233/thumb/imx.png?1636691817",
    "0xfBd8A3b908e764dBcD51e27992464B4432A1132b":
      "https://assets.coingecko.com/coins/images/12729/thumb/index.png?1634894321",
    "0x4E8dc2149EaC3f3dEf36b1c281EA466338249371":
      "https://assets.coingecko.com/coins/images/12882/thumb/Secondary_Symbol.png?1628233237",
    "0xF18Ac368001b0DdC80aA6a8374deb49e868EFDb8":
      "https://assets.coingecko.com/coins/images/14205/thumb/inverse_finance.jpg?1614921871",
    "0xf6372cDb9c1d3674E83842e3800F2A62aC9F3C66":
      "https://s2.coinmarketcap.com/static/img/coins/64x64/2777.png",
    "0xb87f5c1E81077FfcfE821dA240fd20C99c533aF1":
      "https://assets.coingecko.com/coins/images/13876/thumb/JASMY200x200.jpg?1612473259",
    "0x42f37A1296b2981F7C3cAcEd84c5096b2Eb0C72C":
      "https://assets.coingecko.com/coins/images/3373/thumb/IuNzUb5b_400x400.jpg?1589526336",
    "0x324b28d6565f784d596422B0F2E5aB6e9CFA1Dc7":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdd974D5C2e2928deA5F71b9825b8b646686BD200/logo.png",
    "0x53AEc293212E3B792563Bc16f1be26956adb12e9":
      "https://assets.coingecko.com/coins/images/12966/thumb/kp3r_logo.jpg?1607057458",
    "0xE8A51D0dD1b4525189ddA2187F90ddF0932b5482":
      "https://assets.coingecko.com/coins/images/9985/thumb/zRPSu_0o_400x400.jpg?1574327008",
    "0xC3C7d422809852031b44ab29EEC9F1EfF2A58756":
      "https://assets.coingecko.com/coins/images/13573/thumb/Lido_DAO.png?1609873644",
    "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x514910771AF9Ca656af840dff83E8264EcF986CA/logo.png",
    "0x465b67CB20A7E8bC4c51b4C7DA591C1945b41427":
      "https://assets.coingecko.com/coins/images/22572/thumb/loka_64pix.png?1642643271",
    "0x66EfB7cC647e0efab02eBA4316a2d2941193F6b3":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA4e8C3Ec456107eA67d3075bF9e3DF3A75823DB0/logo.png",
    "0x3962F4A0A0051DccE0be73A7e09cEf5756736712":
      "https://assets.coingecko.com/coins/images/7137/thumb/logo-circle-green.png?1619593365",
    "0x8Ab2Fec94d17ae69FB90E7c773f2C85Ed1802c01":
      "https://assets.coingecko.com/coins/images/14665/thumb/200-lqty-icon.png?1617631180",
    "0x84e1670F61347CDaeD56dcc736FB990fBB47ddC1":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xBBbbCA6A901c926F240b89EacB641d8Aec7AEafD/logo.png",
    "0x23001f892c0C82b79303EDC9B9033cD190BB21c7":
      "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/polygon/assets/0x23001f892c0C82b79303EDC9B9033cD190BB21c7/logo.png",
    "0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4":
      "https://assets.coingecko.com/coins/images/878/thumb/decentraland-mana.png?1550108745",
    "0x2B9E7ccDF0F4e5B24757c1E1a80e311E34Cb10c7":
      "https://assets.coingecko.com/coins/images/14051/thumb/Mask_Network.jpg?1614050316",
    "0x347ACCAFdA7F8c5BdeC57fa34a5b663CBd1aeca7":
      "https://assets.coingecko.com/coins/images/11335/thumb/2020-05-19-token-200.png?1589940590",
    "0x0000000000000000000000000000000000001010":
      "https://assets.coingecko.com/coins/images/4713/thumb/matic-token-icon.png?1624446912",
    "0xAa7DbD1598251f856C12f63557A4C4397c253Cea":
      "https://assets.coingecko.com/coins/images/14414/thumb/ENtxnThA_400x400.jpg?1615948522",
    "0x1B9D40715E757Bdb9bdEC3215B898E46d8a3b71a":
      "https://assets.coingecko.com/coins/images/15595/thumb/metis.jpeg?1660285312",
    "0x01288e04435bFcd4718FF203D6eD18146C17Cd4b":
      "https://assets.coingecko.com/coins/images/16786/thumb/mimlogopng.png?1624979612",
    "0x1C5cccA2CB59145A4B25F452660cbA6436DDce9b":
      "https://assets.coingecko.com/coins/images/13295/thumb/mirror_logo_transparent.png?1611554658",
    "0x6f7C932e7684666C9fd1d44527765433e01fF61d":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2/logo.png",
    "0xa9f37D84c856fDa3812ad0519Dad44FA0a3Fe207":
      "https://assets.coingecko.com/coins/images/605/thumb/melon.png?1547034295",
    "0x6968105460f67c3BF751bE7C15f92F5286Fd0CE5":
      "https://assets.coingecko.com/coins/images/13298/thumb/monavale_logo.jpg?1607232721",
    "0xA3c322Ad15218fBFAEd26bA7f616249f7705D945":
      "https://s2.coinmarketcap.com/static/img/coins/64x64/17704.png",
    "0x4985E0B13554fB521840e893574D3848C10Fcc6f":
      "https://assets.coingecko.com/coins/images/2843/thumb/ImcYCVfX_400x400.jpg?1628519767",
    "0x0Bf519071b02F22C17E7Ed5F4002ee1911f46729":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x1776e1F26f98b1A5dF9cD347953a26dd3Cb46671/logo.png",
    "0x282d8efCe846A88B159800bd4130ad77443Fa1A1":
      "https://assets.coingecko.com/coins/images/3687/thumb/ocean-protocol-logo.jpg?1547038686",
    "0xa63Beffd33AB3a2EfD92a39A7D2361CEE14cEbA8":
      "https://assets.coingecko.com/coins/images/3296/thumb/op.jpg?1547037878",
    "0x62414D03084EeB269E18C970a21f45D2967F0170":
      "https://assets.coingecko.com/coins/images/776/thumb/OMG_Network.jpg?1591167168",
    "0x0EE392bA5ef1354c9bd75a98044667d307C0e773":
      "https://assets.coingecko.com/coins/images/11841/thumb/orion_logo.png?1594943318",
    "0x9880e3dDA13c8e7D4804691A45160102d31F6060":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x4575f41308EC1483f3d399aa9a2826d74Da13Deb/logo.png",
    "0x553d3D295e0f695B9228246232eDF400ed3560B5":
      "https://assets.coingecko.com/coins/images/9519/thumb/paxg.PNG?1568542565",
    "0x263534a4Fe3cb249dF46810718B7B612a30ebbff":
      "https://assets.coingecko.com/coins/images/12381/thumb/60d18e06844a844ad75901a9_mark_only_03.png?1628674771",
    "0x8765f05ADce126d70bcdF1b0a48Db573316662eB":
      "https://assets.coingecko.com/coins/images/14316/thumb/54023228.png?1615366911",
    "0x7dc0cb65EC6019330a6841e9c274f2EE57A6CA6C":
      "https://assets.coingecko.com/coins/images/1241/thumb/pluton.png?1548331624",
    "0x8dc302e2141DA59c934d900886DbF1518Fd92cd4":
      "https://assets.coingecko.com/coins/images/12648/thumb/polkastarter.png?1609813702",
    "0xcB059C5573646047D6d88dDdb87B745C18161d3b":
      "https://assets.coingecko.com/coins/images/2784/thumb/inKkF01.png?1605007034",
    "0x73580A2416A57f1C4b6391DBA688A9e4f7DBECE0":
      "https://assets.coingecko.com/coins/images/8903/thumb/POND_200x200.png?1622515451",
    "0x0AaB8DC887D34f00D50E19aee48371a941390d14":
      "https://assets.coingecko.com/coins/images/1104/thumb/power-ledger.png?1547035082",
    "0x82FFdFD1d8699E8886a4e77CeFA9dd9710a7FefD":
      "https://assets.coingecko.com/coins/images/869/thumb/propy.png?1548332100",
    "0x9377Eeb7419486FD4D485671d50baa4BF77c2222":
      "https://assets.coingecko.com/coins/images/11973/thumb/DsNgK0O.png?1596590280",
    "0x36B77a184bE8ee56f5E81C56727B20647A42e28E":
      "https://assets.coingecko.com/coins/images/3370/thumb/5ZOu7brX_400x400.jpg?1612437252",
    "0x831753DD7087CaC61aB5644b308642cc1c33Dc13":
      "https://assets.coingecko.com/coins/images/13970/thumb/1_pOU6pBMEmiL-ZJVb0CYRjQ.png?1613386659",
    "0x2f81e176471CC57fDC76f7d332FB4511bF2bebDD":
      "https://assets.coingecko.com/coins/images/14013/thumb/radicle.png?1614402918",
    "0x00e5646f60AC6Fb446f621d146B6E1886f002905":
      "https://assets.coingecko.com/coins/images/14004/thumb/RAI-logo-coin.png?1613592334",
    "0x780053837cE2CeEaD2A90D9151aA21FC89eD49c2":
      "https://assets.coingecko.com/coins/images/11845/thumb/Rari.png?1594946953",
    "0xc3cFFDAf8F3fdF07da6D5e3A89B8723D5E385ff8":
      "https://assets.coingecko.com/coins/images/12629/thumb/200x200.png?1607952509",
    "0x19782D3Dc4701cEeeDcD90f0993f0A9126ed89d0":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x408e41876cCCDC0F92210600ef50372656052a38/logo.png",
    "0x6563c1244820CfBd6Ca8820FBdf0f2847363F733":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x221657776846890989a759BA2973e427DfF5C9bB/logo.png",
    "0xAdf2F2Ed91755eA3f4bcC9107a494879f633ae7C":
      "https://assets.coingecko.com/coins/images/1031/thumb/Request_icon_green.png?1643250951",
    "0x70c006878a5A50Ed185ac4C87d837633923De296":
      "https://assets.coingecko.com/coins/images/12373/thumb/REVV_TOKEN_Refined_2021_%281%29.png?1627652390",
    "0x3b9dB434F08003A89554CDB43b3e0b1f8734BdE7":
      "https://assets.coingecko.com/coins/images/12900/thumb/Rari_Logo_Transparent.png?1613978014",
    "0xbe662058e00849C3Eef2AC9664f37fEfdF2cdbFE":
      "https://assets.coingecko.com/coins/images/646/thumb/pL1VuXm.png?1604543202",
    "0x76b8D57e5ac6afAc5D415a054453d1DD2c3C0094":
      "https://assets.coingecko.com/coins/images/12843/thumb/image.png?1611212077",
    "0x61299774020dA444Af134c82fa83E3810b309991":
      "https://assets.coingecko.com/coins/images/11636/thumb/rndr.png?1638840934",
    "0xF92501c8213da1D6C74A76372CCc720Dc8818407":
      "https://assets.coingecko.com/coins/images/13005/thumb/keeper_dao_logo.jpg?1604316506",
    "0xBbba073C31bF03b8ACf7c28EF0738DeCF3695683":
      "https://assets.coingecko.com/coins/images/12129/thumb/sandbox_logo.jpg?1597397942",
    "0x6f8a06447Ff6FcF75d803135a7de15CE88C1d4ec":
      "https://assets.coingecko.com/coins/images/11939/thumb/shiba.png?1622619446",
    "0x0C7304fBAf2A320a1c50c46FE03752722F729946":
      "https://assets.coingecko.com/coins/images/10366/thumb/SLP.png?1578640057",
    "0x50B728D8D964fd00C2d0AAD81718b71311feF68a":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F/logo.png",
    "0xcdB3C70CD25FD15307D84C4F9D37d5C043B33Fb2":
      "https://assets.coingecko.com/coins/images/15861/thumb/abracadabra-3.png?1622544862",
    "0xd72357dAcA2cF11A5F155b9FF7880E595A3F5792":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xB64ef51C888972c908CFacf59B47C1AfBC0Ab8aC/logo.png",
    "0xB36e3391B22a970d31A9b620Ae1A414C6c256d2a":
      "https://assets.coingecko.com/coins/images/1230/thumb/stox-token.png?1547035256",
    "0x60Ea918FC64360269Da4efBDA11d8fC6514617C6":
      "https://assets.coingecko.com/coins/images/11969/thumb/UmfW5S6f_400x400.jpg?1596602238",
    "0xa1428174F516F527fafdD146b883bB4428682737":
      "https://assets.coingecko.com/coins/images/14040/thumb/6YPdWn6.png?1613975899",
    "0xF81b4Bec6Ca8f9fe7bE01CA734F55B2b6e03A7a0":
      "https://assets.coingecko.com/coins/images/5013/thumb/sUSD.png?1616150765",
    "0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a":
      "https://assets.coingecko.com/coins/images/12271/thumb/512x512_Logo_no_chop.png?1606986688",
    "0x6aBB753C1893194DE4a83c6e8B4EadFc105Fd5f5":
      "https://assets.coingecko.com/coins/images/9368/thumb/swipe.png?1566792311",
    "0xe1708AbDE4847B4929b70547E5197F1Ba1db2250":
      "https://assets.coingecko.com/coins/images/17495/thumb/tokemak-avatar-200px-black.png?1628131614",
    "0xA7b98d63a137bF402b4570799ac4caD0BB1c4B1c":
      "https://assets.coingecko.com/coins/images/1877/thumb/TRAC.jpg?1635134367",
    "0xE3322702BEdaaEd36CdDAb233360B939775ae5f1":
      "https://assets.coingecko.com/coins/images/9644/thumb/Blk_icon_current.png?1584980686",
    "0x8676815789211E799a6DC86d02748ADF9cF86836":
      "https://assets.coingecko.com/coins/images/14575/thumb/tribe.PNG?1617487954",
    "0x5b77bCA482bd3E7958b1103d123888EfCCDaF803":
      "https://assets.coingecko.com/coins/images/13180/thumb/truefi_glyph_color.png?1617610941",
    "0x5667dcC0ab74D1b1355C3b2061893399331B57e2":
      "https://assets.coingecko.com/coins/images/13330/thumb/virtua_original.png?1656043619",
    "0x3066818837c5e6eD6601bd5a91B0762877A6B731":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828/logo.png",
    "0xb33EaAd8d922B1083446DC23f610c2567fB5180f":
      "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/polygon/assets/0xb33EaAd8d922B1083446DC23f610c2567fB5180f/logo.png",
    "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
    "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
    "0xc2132D05D31c914a87C6611C10748AEb04B58e8F":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png",
    "0xd0258a3fD00f38aa8090dfee343f10A9D4d30D3F":
      "https://assets.coingecko.com/coins/images/21260/large/voxies.png",
    "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png",
    "0x90bb6fEB70A9a43CfAaA615F856BA309FD759A90":
      "https://assets.coingecko.com/coins/images/17106/thumb/WCFG.jpg?1626266462",
    "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
    "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270":
      "https://assets.coingecko.com/coins/images/4713/thumb/matic-token-icon.png?1624446912",
    "0x1B815d120B3eF02039Ee11dC2d33DE7aA4a8C603":
      "https://assets.coingecko.com/coins/images/12921/thumb/w2UiemF__400x400.jpg?1603670367",
    "0xd2507e7b5794179380673870d88B22F94da6abe0":
      "https://assets.coingecko.com/coins/images/4519/thumb/XYO_Network-logo.png?1547039819",
    "0xDA537104D6A5edd53c6fBba9A898708E465260b6":
      "https://assets.coingecko.com/coins/images/11849/thumb/yfi-192x192.png?1598325330",
    "0xb8cb8a7F4C2885C03e57E973C74827909Fdc2032":
      "https://assets.coingecko.com/coins/images/11902/thumb/YFII-logo.78631676.png?1598677348",
    "0x82617aA52dddf5Ed9Bb7B370ED777b3182A30fd1":
      "https://assets.coingecko.com/coins/images/17358/thumb/le1nzlO6_400x400.jpg?1632465691",
    "0x5559Edb74751A0edE9DeA4DC23aeE72cCA6bE3D5":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xE41d2489571d322189246DaFA5ebDe1F4699F498/logo.png",
  },
  [ApertureSupportedChainId.BASE_MAINNET_CHAIN_ID]: {
    "0xc5fecC3a29Fb57B5024eEc8a2239d4621e111CBE":
      "https://assets.coingecko.com/coins/images/13469/thumb/1inch-token.png?1608803028",
    "0x4158734D47Fc9692176B5085E0F52ee0Da5d47F1":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xba100000625a3754423978a60c9317c58a424e3D/logo.png",
    "0x2Ae3F1Ec7F1F5012CFEab0185bfc7aa3cf0DEc22":
      "https://ethereum-optimism.github.io/data/cbETH/logo.svg",
    "0x9e1028F5F1D5eDE59748FFceE5532509976840E0":
      "https://ethereum-optimism.github.io/data/COMP/logo.svg",
    "0x8Ee73c484A26e0A5df2Ee2a4960B789967dd0415":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xD533a949740bb3306d119CC777fa900bA034cd52/logo.png",
    "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb":
      "https://ethereum-optimism.github.io/data/DAI/logo.svg",
    "0xD08a2917653d4E460893203471f0000826fb4034":
      "https://assets.coingecko.com/coins/images/12304/thumb/Harvest.png?1613016180",
    "0x7D49a065D17d6d4a55dc13649901fdBB98B2AFBA":
      "https://assets.coingecko.com/coins/images/12271/thumb/512x512_Logo_no_chop.png?1606986688",
    "0x236aa50979D5f3De3Bd1Eeb40E81137F22ab794b":
      "https://raw.githubusercontent.com/uniswap/assets/master/blockchains/ethereum/assets/0x18084fbA666a33d37592fA2633fD49a74DD93a88/logo.png",
    "0xA81a52B4dda010896cDd386C7fBdc5CDc835ba23":
      "https://assets.coingecko.com/coins/images/1877/thumb/TRAC.jpg?1635134367",
    "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA":
      "https://ethereum-optimism.github.io/data/USDC/logo.png",
    "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913":
      "https://ethereum-optimism.github.io/data/USDC/logo.png",
    "0xfde4C96c8593536E31F229EA8f37b2ADa2699bb2":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png",
    "0x489fe42C267fe0366B16b0c39e7AEEf977E841eF":
      "https://assets.coingecko.com/coins/images/20825/thumb/photo_2021-11-25_02-05-11.jpg?1637811951",
    "0x4200000000000000000000000000000000000006":
      "https://ethereum-optimism.github.io/data/WETH/logo.png",
    "0x3bB4445D30AC020a84c1b5A8A2C6248ebC9779D0":
      "https://ethereum-optimism.github.io/data/ZRX/logo.png",
  },
  [ApertureSupportedChainId.BNB_MAINNET_CHAIN_ID]: {
    "0x111111111117dC0aa78b770fA6A738034120C302":
      "https://assets.coingecko.com/coins/images/13469/thumb/1inch-token.png?1608803028",
    "0xfb6115445Bff7b52FeB98650C87f44907E58f802":
      "https://assets.coingecko.com/coins/images/12645/thumb/AAVE.png?1601374110",
    "0xBc7d6B50616989655AfD682fb42743507003056D":
      "https://assets.coingecko.com/coins/images/12390/thumb/ACH_%281%29.png?1599691266",
    "0x6bfF4Fb161347ad7de4A625AE5aa3A1CA7077819":
      "https://assets.coingecko.com/coins/images/847/thumb/Ambire_AdEx_Symbol_color.png?1655432540",
    "0x33d08D8C7a168333a85285a68C0042b39fC3741D":
      "https://assets.coingecko.com/coins/images/14631/thumb/aioz_logo.png?1617413126",
    "0x82D2f8E02Afb160Dd5A480a617692e62de9038C4":
      "https://assets.coingecko.com/coins/images/11676/thumb/Monochram-aleph.png?1608483725",
    "0xAC51066d7bEC65Dc4589368da368b212745d63E8":
      "https://assets.coingecko.com/coins/images/14375/thumb/alice_logo.jpg?1615782968",
    "0xa1faa113cbE53436Df28FF0aEe54275c13B40975":
      "https://assets.coingecko.com/coins/images/12738/thumb/AlphaToken_256x256.png?1617160876",
    "0xf307910A4c7bbc79691fD374889b36d8531B08e3":
      "https://assets.coingecko.com/coins/images/4324/thumb/U85xTl2.png?1608111978",
    "0x6F769E65c14Ebd1f68817F5f1DcDb61Cfa2D6f7e":
      "https://assets.coingecko.com/coins/images/8506/thumb/9u0a23XY_400x400.jpg?1559027357",
    "0xA2120b9e674d3fC3875f415A7DF52e382F141225":
      "https://assets.coingecko.com/coins/images/15985/thumb/ATA.jpg?1622535745",
    "0x8b1f4432F943c465A973FeDC6d7aa50Fc96f1f65":
      "https://assets.coingecko.com/coins/images/27277/large/V-65_xQ1_400x400.jpeg",
    "0x715D400F88C167884bbCc41C5FeA407ed4D2f8A0":
      "https://assets.coingecko.com/coins/images/13029/thumb/axie_infinity_logo.png?1604471082",
    "0x935a544Bf5816E3A7C13DB2EFe3009Ffda0aCdA2":
      "https://assets.coingecko.com/coins/images/2848/thumb/ColorIcon_3x.png?1622516510",
    "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56":
      "https://assets.coingecko.com/coins/images/9576/thumb/BUSD.png?1568947766",
    "0xaEC945e04baF28b135Fa7c640f624f8D90F1C3a6":
      "https://assets.coingecko.com/coins/images/17117/thumb/logo.png?1626412904",
    "0xf9CeC8d50f6c8ad3Fb6dcCEC577e05aA32B224FE":
      "https://assets.coingecko.com/coins/images/5000/thumb/Chromia.png?1559038018",
    "0x09E889BB4D5b474f561db0491C38702F367A4e4d":
      "https://assets.coingecko.com/coins/images/15278/thumb/clover.png?1645084454",
    "0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xc00e94Cb662C3520282E6f5717214004A7f26888/logo.png",
    "0xd15CeE1DEaFBad6C0B3Fd7489677Cc102B141464":
      "https://assets.coingecko.com/coins/images/588/thumb/coval-logo.png?1599493950",
    "0x8dA443F84fEA710266C8eB6bC34B71702d033EF2":
      "https://assets.coingecko.com/coins/images/11038/thumb/cartesi.png?1592288021",
    "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png",
    "0x23CE9e926048273eF83be0A3A8Ba9Cb6D45cd978":
      "https://assets.coingecko.com/coins/images/19837/thumb/dar.png?1636014223",
    "0xe91a8D2c584Ca93C7405F15c22CdFE53C29896E3":
      "https://assets.coingecko.com/coins/images/11603/thumb/dext.png?1605790188",
    "0x99956D38059cf7bEDA96Ec91Aa7BB2477E0901DD":
      "https://assets.coingecko.com/coins/images/11955/thumb/image.png?1646041751",
    "0xEC583f25A049CC145dA9A256CDbE9B6201a705Ff":
      "https://assets.coingecko.com/coins/images/14578/thumb/KotgsCgS_400x400.jpg?1617094445",
    "0x961C8c0B1aaD0c0b10a51FeF6a867E3091BCef17":
      "https://assets.coingecko.com/coins/images/13480/thumb/DYP_Logo_Symbol-8.png?1655809066",
    "0x7bd6FaBD64813c48545C9c0e312A0099d9be2540":
      "https://assets.coingecko.com/coins/images/14962/thumb/6GxcPRo3_400x400.jpg?1619157413",
    "0x4B5C23cac08a567ecf0c1fFcA8372A45a5D33743":
      "https://assets.coingecko.com/coins/images/12304/thumb/Harvest.png?1613016180",
    "0x031b41e504677879370e9DBcF937283A8691Fa7f":
      "https://assets.coingecko.com/coins/images/5681/thumb/Fetch.jpg?1572098136",
    "0x90C97F71E18723b0Cf0dfa30ee176Ab653E89F40":
      "https://assets.coingecko.com/coins/images/13422/thumb/frax_logo.png?1608476506",
    "0xAD29AbB318791D579433D831ed122aFeAf29dcfe":
      "https://assets.coingecko.com/coins/images/4001/thumb/Fantom.png?1558015016",
    "0xe48A3d7d0Bc88d552f730B62c006bC925eadB9eE":
      "https://assets.coingecko.com/coins/images/13423/thumb/frax_share.png?1608478989",
    "0xe4Cc45Bb5DBDA06dB6183E8bf016569f40497Aa5":
      "https://assets.coingecko.com/coins/images/24530/thumb/GAL-Token-Icon.png?1651483533",
    "0x44Ec807ce2F4a6F2737A92e985f318d035883e47":
      "https://assets.coingecko.com/coins/images/26136/large/hashflow-icon-cmc.png",
    "0x5f4Bde007Dc06b867f86EBFE4802e34A1fFEEd63":
      "https://assets.coingecko.com/coins/images/18973/thumb/logosq200200Coingecko.png?1634090470",
    "0xa2B726B1145A4773F68593CF171187d8EBe4d495":
      "https://assets.coingecko.com/coins/images/12882/thumb/Secondary_Symbol.png?1628233237",
    "0x0231f91e02DebD20345Ae8AB7D71A41f8E140cE7":
      "https://assets.coingecko.com/coins/images/10351/thumb/logo512.png?1632480932",
    "0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x514910771AF9Ca656af840dff83E8264EcF986CA/logo.png",
    "0x2eD9a5C8C13b93955103B9a7C167B67Ef4d568a3":
      "https://assets.coingecko.com/coins/images/14051/thumb/Mask_Network.jpg?1614050316",
    "0xF218184Af829Cf2b0019F8E6F0b2423498a36983":
      "https://assets.coingecko.com/coins/images/11335/thumb/2020-05-19-token-200.png?1589940590",
    "0xCC42724C6683B7E57334c4E856f4c9965ED682bD":
      "https://assets.coingecko.com/coins/images/4713/thumb/matic-token-icon.png?1624446912",
    "0x949D48EcA67b17269629c7194F4b727d4Ef9E5d6":
      "https://assets.coingecko.com/coins/images/19304/thumb/Db4XqML.png?1634972154",
    "0xe552Fb52a4F19e44ef5A967632DBc320B0820639":
      "https://assets.coingecko.com/coins/images/15595/thumb/metis.jpeg?1660285312",
    "0xfE19F0B51438fd612f6FD59C1dbB3eA319f433Ba":
      "https://assets.coingecko.com/coins/images/16786/thumb/mimlogopng.png?1624979612",
    "0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9":
      "https://assets.coingecko.com/coins/images/13295/thumb/mirror_logo_transparent.png?1611554658",
    "0x9Fb9a33956351cf4fa040f65A13b835A3C8764E3":
      "https://assets.coingecko.com/coins/images/22087/thumb/1_Wyot-SDGZuxbjdkaOeT2-A.png?1640764238",
    "0x98f8669F6481EbB341B522fCD3663f79A3d1A6A7":
      "https://assets.coingecko.com/coins/images/11284/thumb/52954052.png?1589868539",
    "0x4e7f408be2d4E9D60F49A64B89Bb619c84C7c6F5":
      "https://assets.coingecko.com/coins/images/12381/thumb/60d18e06844a844ad75901a9_mark_only_03.png?1628674771",
    "0x7e624FA0E1c4AbFD309cC15719b7E2580887f570":
      "https://assets.coingecko.com/coins/images/12648/thumb/polkastarter.png?1609813702",
    "0xd21d29B38374528675C34936bf7d5Dd693D2a577":
      "https://assets.coingecko.com/coins/images/11973/thumb/DsNgK0O.png?1596590280",
    "0x4C882ec256823eE773B25b414d36F92ef58a7c0C":
      "https://assets.coingecko.com/coins/images/23931/thumb/PSTAKE_Dark.png?1645709930",
    "0x833F307aC507D47309fD8CDD1F835BeF8D702a93":
      "https://assets.coingecko.com/coins/images/12373/thumb/REVV_TOKEN_Refined_2021_%281%29.png?1627652390",
    "0xfA54fF1a158B5189Ebba6ae130CEd6bbd3aEA76e":
      "https://assets.coingecko.com/coins/images/22876/thumb/SOL_wh_small.png?1644224316",
    "0xB0D502E938ed5f4df2E681fE6E419ff29631d62b":
      "https://assets.coingecko.com/coins/images/24413/thumb/STG_LOGO.png?1647654518",
    "0x51BA0b044d96C3aBfcA52B64D733603CCC4F0d4D":
      "https://assets.coingecko.com/coins/images/14040/thumb/6YPdWn6.png?1613975899",
    "0x947950BcC74888a40Ffa2593C5798F11Fc9124C4":
      "https://assets.coingecko.com/coins/images/12271/thumb/512x512_Logo_no_chop.png?1606986688",
    "0xE64E30276C2F826FEbd3784958d6Da7B55DfbaD3":
      "https://assets.coingecko.com/coins/images/2346/thumb/SWFTCoin.jpg?1618392022",
    "0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A":
      "https://assets.coingecko.com/coins/images/9368/thumb/swipe.png?1566792311",
    "0xa4080f1778e69467E905B8d6F72f6e441f9e9484":
      "https://assets.coingecko.com/coins/images/18024/thumb/syn.png?1635002049",
    "0x3b198e26E473b8faB2085b37978e36c9DE5D7f68":
      "https://assets.coingecko.com/coins/images/604/thumb/time-32x32.png?1627130666",
    "0x2222227E22102Fe3322098e4CBfE18cFebD57c95":
      "https://assets.coingecko.com/coins/images/14676/thumb/kY-C4o7RThfWrDQsLCAG4q4clZhBDDfJQVhWUEKxXAzyQYMj4Jmq1zmFwpRqxhAJFPOa0AsW_PTSshoPuMnXNwq3rU7Imp15QimXTjlXMx0nC088mt1rIwRs75GnLLugWjSllxgzvQ9YrP4tBgclK4_rb17hjnusGj_c0u2fx0AvVokjSNB-v2poTj0xT9BZRCbzRE3-lF1.jpg?1617700061",
    "0x728C5baC3C3e370E372Fc4671f9ef6916b814d8B":
      "https://assets.coingecko.com/coins/images/13152/thumb/logo-2.png?1605748967",
    "0xBf5140A22578168FD562DCcF235E5D43A02ce9B1":
      "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/smartchain/assets/0xBf5140A22578168FD562DCcF235E5D43A02ce9B1/logo.png",
    "0x0D35A2B85c5A63188d566D104bEbf7C694334Ee4":
      "https://assets.coingecko.com/coins/images/12186/thumb/pawtocol.jpg?1597962008",
    "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
    "0x55d398326f99059fF775485246999027B3197955":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png",
    "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c":
      "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/smartchain/assets/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/logo.png",
    "0x2170Ed0880ac9A755fd29B2688956BD959F933F8":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
    "0x4691937a7508860F876c9c0a2a617E7d9E945D4B":
      "https://assets.coingecko.com/coins/images/12921/thumb/w2UiemF__400x400.jpg?1603670367",
    "0x7324c7C0d95CEBC73eEa7E85CbAac0dBdf88a05b":
      "https://assets.coingecko.com/coins/images/24210/thumb/Chain_icon_200x200.png?1646895054",
  },
  [ApertureSupportedChainId.AVALANCHE_MAINNET_CHAIN_ID]: {
    "0xd501281565bf7789224523144Fe5D98e8B28f267":
      "https://assets.coingecko.com/coins/images/13469/thumb/1inch-token.png?1608803028",
    "0x63a72806098Bd3D9520cC43356dD78afe5D386D9":
      "https://assets.coingecko.com/coins/images/12645/thumb/AAVE.png?1601374110",
    "0x2147EFFF675e4A4eE1C2f918d181cDBd7a8E208f":
      "https://assets.coingecko.com/coins/images/12738/thumb/AlphaToken_256x256.png?1617160876",
    "0x20CF1b6E9d856321ed4686877CF4538F2C84B4dE":
      "https://assets.coingecko.com/coins/images/4324/thumb/U85xTl2.png?1608111978",
    "0x44c784266cf024a60e8acF2427b9857Ace194C5d":
      "https://assets.coingecko.com/coins/images/27277/large/V-65_xQ1_400x400.jpeg",
    "0x98443B96EA4b0858FDF3219Cd13e98C7A4690588":
      "https://assets.coingecko.com/coins/images/677/thumb/basic-attention-token.png?1547034427",
    "0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39":
      "https://assets.coingecko.com/coins/images/9576/thumb/BUSD.png?1568947766",
    "0xc3048E19E76CB9a3Aa9d77D8C03c29Fc906e2437":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xc00e94Cb662C3520282E6f5717214004A7f26888/logo.png",
    "0x6b289CCeAA8639e3831095D75A3e43520faBf552":
      "https://assets.coingecko.com/coins/images/11038/thumb/cartesi.png?1592288021",
    "0xd586E7F844cEa2F87f50152665BCbc2C279D8d70":
      "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/avalanchec/assets/0xd586E7F844cEa2F87f50152665BCbc2C279D8d70/logo.png",
    "0x961C8c0B1aaD0c0b10a51FeF6a867E3091BCef17":
      "https://assets.coingecko.com/coins/images/13480/thumb/DYP_Logo_Symbol-8.png?1655809066",
    "0xD24C2Ad096400B6FBcd2ad8B24E7acBc21A1da64":
      "https://assets.coingecko.com/coins/images/13422/thumb/frax_logo.png?1608476506",
    "0x214DB107654fF987AD859F34125307783fC8e387":
      "https://assets.coingecko.com/coins/images/13423/thumb/frax_share.png?1608478989",
    "0x62edc0692BD897D2295872a9FFCac5425011c661":
      "https://assets.coingecko.com/coins/images/18323/large/arbit.png?1631532468",
    "0x8a0cAc13c7da965a312f08ea4229c37869e85cB9":
      "https://assets.coingecko.com/coins/images/13397/thumb/Graph_Token.png?1608145566",
    "0x5947BB275c521040051D82396192181b413227A3":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x514910771AF9Ca656af840dff83E8264EcF986CA/logo.png",
    "0x130966628846BFd36ff31a822705796e8cb8C18D":
      "https://assets.coingecko.com/coins/images/16786/thumb/mimlogopng.png?1624979612",
    "0x88128fd4b259552A9A1D457f435a6527AAb72d42":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2/logo.png",
    "0x9Fb9a33956351cf4fa040f65A13b835A3C8764E3":
      "https://assets.coingecko.com/coins/images/22087/thumb/1_Wyot-SDGZuxbjdkaOeT2-A.png?1640764238",
    "0x97Cd1CFE2ed5712660bb6c14053C0EcB031Bff7d":
      "https://assets.coingecko.com/coins/images/14004/thumb/RAI-logo-coin.png?1613592334",
    "0xBeC243C995409E6520D7C41E404da5dEba4b209B":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F/logo.png",
    "0xFE6B19286885a4F7F55AdAD09C3Cd1f906D2478F":
      "https://assets.coingecko.com/coins/images/22876/thumb/SOL_wh_small.png?1644224316",
    "0xCE1bFFBD5374Dac86a2893119683F4911a2F7814":
      "https://assets.coingecko.com/coins/images/15861/thumb/abracadabra-3.png?1622544862",
    "0x2F6F07CDcf3588944Bf4C42aC74ff24bF56e7590":
      "https://assets.coingecko.com/coins/images/24413/thumb/STG_LOGO.png?1647654518",
    "0x37B608519F91f70F2EeB0e5Ed9AF4061722e4F76":
      "https://assets.coingecko.com/coins/images/12271/thumb/512x512_Logo_no_chop.png?1606986688",
    "0x1f1E7c893855525b303f99bDF5c3c05Be09ca251":
      "https://assets.coingecko.com/coins/images/18024/thumb/syn.png?1635002049",
    "0x3Bd2B1c7ED8D396dbb98DED3aEbb41350a5b2339":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828/logo.png",
    "0x8eBAf22B6F053dFFeaf46f4Dd9eFA95D89ba8580":
      "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/avalanchec/assets/0x8eBAf22B6F053dFFeaf46f4Dd9eFA95D89ba8580/logo.png",
    "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E":
      "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/avalanchec/assets/0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E/logo.png",
    "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png",
    "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7":
      "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/avalanchec/assets/0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7/logo.png",
    "0x50b7545627a5162F82A992c33b87aDc75187B218":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png",
    "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
    "0xaBC9547B534519fF73921b1FBA6E672b5f58D083":
      "https://assets.coingecko.com/coins/images/12921/thumb/w2UiemF__400x400.jpg?1603670367",
    "0x9eAaC1B23d935365bD7b542Fe22cEEe2922f52dc":
      "https://assets.coingecko.com/coins/images/11849/thumb/yfi-192x192.png?1598325330",
    "0x596fA47043f99A4e0F122243B841E55375cdE0d2":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xE41d2489571d322189246DaFA5ebDe1F4699F498/logo.png",
  },
  [ApertureSupportedChainId.SCROLL_MAINNET_CHAIN_ID]: {
    "0x79379C0E09a41d7978f883a56246290eE9a8c4d3":
      "https://scroll-tech.github.io/token-list/data/AAVE/logo.svg",
    "0x6a28e90582c583fcd3347931c544819C31e9D0e0":
      "https://scroll-tech.github.io/token-list/data/BAL/logo.png",
    "0xB755039eDc7910C1F1BD985D48322E55A31AC0bF":
      "https://scroll-tech.github.io/token-list/data/CRV/logo.png",
    "0xcA77eB3fEFe3725Dc33bccB54eDEFc3D9f764f97":
      "https://scroll-tech.github.io/token-list/data/DAI/logo.svg",
    "0x608ef9A3BffE206B86c3108218003b3cfBf99c84":
      "https://scroll-tech.github.io/token-list/data/KNC/logo.svg",
    "0xeDEAbc3A1e7D21fE835FFA6f83a710c70BB1a051":
      "https://scroll-tech.github.io/token-list/data/LUSD/logo.svg",
    "0x53878B874283351D26d206FA512aEcE1Bef6C0dD":
      "https://scroll-tech.github.io/token-list/data/rETH/logo.svg",
    "0x434cdA25E8a2CA5D9c1C449a8Cb6bCbF719233E8":
      "https://scroll-tech.github.io/token-list/data/UNI/logo.png",
    "0x06eFdBFf2a14a7c8E15944D1F4A48F9F95F663A4":
      "https://scroll-tech.github.io/token-list/data/USDC/logo.svg",
    "0xf55BEC9cafDbE8730f096Aa55dad6D22d44099Df":
      "https://scroll-tech.github.io/token-list/data/USDT/logo.svg",
    "0x3C1BCa5a656e69edCD0D4E36BEbb3FcDAcA60Cf1":
      "https://scroll-tech.github.io/token-list/data/WBTC/logo.svg",
    "0x5300000000000000000000000000000000000004":
      "https://scroll-tech.github.io/token-list/data/WETH/logo.png",
    "0xf610A9dfB7C89644979b4A0f27063E9e7d7Cda32":
      "https://scroll-tech.github.io/token-list/data/wstETH/logo.svg",
  },
};

export const tokenIcons: TokenIconMap = Object.entries(tokensIcon).reduce(
  (acc, [chainId, obj]) => {
    acc[chainId] = Object.keys(obj).reduce((_acc, key) => {
      _acc[key.toLowerCase()] = obj[key];
      return _acc;
    }, {});
    return acc;
  },
  {}
);

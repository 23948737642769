import { ExchangeIcon2 } from "@aperture/assetkit";
import styled from "styled-components";
import { Input } from "../Input";
import { Body1, ButtonText } from "../Typography";

export const B = styled.span`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const TokenSwapContainer = styled.div`
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const SwapButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.transparent.transparent};
  border: none;
  background: none;
`;
export const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 16px;
  gap: ${({ theme }) => theme.spacing.xl};
  border-radius: ${({ theme }) => theme.radii.lg};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.global.line.Line2};
  }
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.global.line.Line2};
  }
`;

export const ValueInput = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const Value = styled.span`
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  overflow: hidden;
  margin-right: 10px;
  white-space: nowrap;
`;

export const Ticker = styled(ButtonText)`
  position: absolute;
  right: 40px;
  padding-left: 16px;
  padding-right: 0;
  border-left: 1px solid ${({ theme }) => theme.colors.global.line.Line2};
`;
export const StyledInput = styled(Input)`
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 136px 0px 26px;
  font-size: 24px;
  height: 28px;
  width: calc(100% - 28px);
  border: none;
  :focus {
    border: none;
  }
`;
export const GreyBox = styled.div`
  width: 26px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: ${({ theme }) => theme.radii.md};
  font-size: 24px;
`;
export const LeftGreyBox = styled(GreyBox)`
  left: -8px;
`;
export const RightGreyBox = styled(GreyBox)`
  right: 98px;
`;
export const TextBox = styled(Body1)`
  position: relative;
  width: 100%;
  display: block;
  margin-bottom: 12px;
  font-family: "Roboto Flex";
  line-height: 16px;
`;
export const SmallText = styled.div`
  position: absolute;
  right: 0;
  top: 3px;
  color: ${({ theme }) => theme.colors.global.text.T2};
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;
`;
export const MarketPriceWrapper = styled(Body1)`
  color: ${({ theme }) => theme.colors.global.text.T2};
  line-height: 16px;
`;
export const Grid = styled.div`
  display: grid;
  grid-template-columns: 24px 30px calc(100% - 54px);
`;

export const GreyBoxSM = styled.div`
  width: 20px;
  height: 18px;
  background: #d6d6e9;
  border-radius: 6px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
`;

export const Wrapper = styled.div`
  text-align: left;
`;
export const SwapButtonIcon = styled(ExchangeIcon2)`
  transform: rotate(-90deg);
  path {
    fill: ${({ theme }) => theme.colors.global.primary};
  }
`;

import { Flex, FlexColumn } from "@ui/common";
import { TextLink } from "@ui/components/Link";
import { Headline1 } from "@ui/components/Typography";
import styled, { keyframes } from "styled-components";

export const Content = styled(FlexColumn)<{ showIntro?: boolean }>`
  width: 100%;
  height: 100%;
  gap: 20px;
  align-items: ${({ showIntro }) => (showIntro ? "center" : "flex-end")};
`;

const slideIn = keyframes`
  0% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(0);
  }
`;

export const Title = styled(Headline1)`
  font-family: Dunbar Tall;
  margin-top: 70px;
  color: ${({ theme }) => theme.colors.global.text.T1};
  animation: ${slideIn} 0.2s ease-out forwards;
`;
export const SubTitle = styled.span<{ isDrawer: boolean }>`
  width: ${({ isDrawer }) => (isDrawer ? "100%" : "680px")};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: ${({ isDrawer }) => (isDrawer ? "left" : "center")};
  color: ${({ theme }) => theme.colors.global.text.T2};

  animation: ${slideIn} 0.2s ease-out forwards;
`;

export const PromptLink = styled(TextLink)`
  font-size: 14px;
  line-height: 16px;
  padding: 0 16px;
  height: 40px;
  align-items: center;
  display: flex;
  animation: ${slideIn} 0.2s ease-out forwards;
  border: 1px solid ${({ theme }) => theme.colors.global.text.T4};
`;

export const QuestionLink = styled(TextLink)`
  padding: 0 16px;
  height: 32px;
  align-items: center;
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
`;

export const Text = styled.div`
  white-space: nowrap;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  width: min-content;
  color: ${({ theme }) => theme.colors.global.text.T3};
`;

const FadeIn = keyframes`
  0% {
    transform: translateY(40%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const PopularQuestionsContainer = styled(Flex)`
  gap: 24px;
  align-items: center;

  animation: ${FadeIn} 0.3s ease-out forwards;
`;

export const QuestionListContainer = styled(Flex)<{
  isDrawer: boolean;
}>`
  scrollbar-width: thin;
  scrollbar-color: ${({ theme, isDrawer }) =>
      isDrawer
        ? theme.colors.global.background.BG3
        : theme.colors.global.background.BG2}
    transparent;
  overflow-x: auto;
  white-space: nowrap;
`;

export const ContentWrapper = styled.div`
  overflow-y: auto;
  height: 100%;
`;

export const InputWrapper = styled(FlexColumn)<{ isChatMode: boolean }>`
  box-sizing: border-box;
  width: ${({ isChatMode }) => (isChatMode ? "605px" : "100%")};
  transition: width 0.5s ease-in-out;
`;

export const IntentInput = styled.input`
  background: transparent;
  border: none;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  padding: 0;
  color: ${({ theme }) => theme.colors.global.text.T1};
  :focus-visible {
    outline: none;
  }
`;

export const IconButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IntentInputContainer = styled(Flex)<{
  bg: "grey" | "transparent";
}>`
  background: ${({ theme, bg }) =>
    bg === "grey"
      ? theme.colors.global.background.BG3
      : theme.colors.global.text.T5};
  height: 16px;
  padding: 12px 24px;
  border-radius: 100px;
  gap: 10px;
  :hover,
  :active,
  :focus {
    background: ${({ theme }) => theme.colors.global.background.BG4};
  }
`;

export const FirstLookWrapper = styled(FlexColumn)`
  gap: 40px;
  width: 100%;
`;

export const BlinkCursor = styled.span`
  animation: blink 1s infinite;

  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

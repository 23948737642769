import React from "react";
import styled from "styled-components";
import { compose, layout, space, typography } from "styled-system";

const StyledModalTitle1 = styled.span`
  font-weight: 500;
  font-size: 24px;
  ${compose(layout, space, typography)}
`;
export const ModalTitle1: React.FC<React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  return <StyledModalTitle1 {...props}>{children}</StyledModalTitle1>;
};

const StyledModalTitle2 = styled.span`
  font-family: "Dunbar Tall";
  font-weight: 500;
  font-size: 16px;
  ${compose(layout, space, typography)}
`;
export const ModalTitle2: React.FC<React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  return <StyledModalTitle2 {...props}>{children}</StyledModalTitle2>;
};

const StyledModalText1 = styled.span`
  font-weight: 400;
  font-size: 18px;
  ${compose(layout, space, typography)}
`;
export const ModalText1: React.FC<React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  return <StyledModalText1 {...props}>{children}</StyledModalText1>;
};

const StyledModalText2 = styled.span`
  font-weight: 400;
  font-size: 14px;
  ${compose(layout, space, typography)}
`;
export const ModalText2: React.FC<React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  return <StyledModalText2 {...props}>{children}</StyledModalText2>;
};

import { ChevronIcon } from "@aperture/assetkit";
import React from "react";
import styled from "styled-components";
import { Button } from "../Button";
import { HomeCardProps } from "./types";

const CardWrapper = styled.div`
  width: 382px;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.radii.xl};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const Title = styled.div`
  font-family: "Dunbar Tall";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  text-transform: capitalize;
`;
const Text = styled.div`
  color: ${({ theme }) => theme.colors.global.text.T3};
  font-family: "Roboto Flex";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;
const LinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;

  font-family: "Roboto Flex";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;
const Chevron = styled(ChevronIcon)`
  path {
    stroke: ${({ theme }) => theme.colors.global.textAlt.T1};
  }
`;
const StyledButton = styled(Button)`
  height: 32px;
  padding: 0;
`;

export const HomeCard: React.FC<React.PropsWithChildren<HomeCardProps>> = ({
  title,
  description,
  linkText = "Enter",
  onClick,
  ...props
}) => {
  return (
    <CardWrapper {...props}>
      <Title>{title}</Title>
      <Text>{description}</Text>
      <LinkWrapper>
        {linkText}
        <StyledButton
          variant="contained"
          color="secondary"
          width="32px"
          size="circle"
          onClick={onClick}
        >
          <Chevron />
        </StyledButton>
      </LinkWrapper>
    </CardWrapper>
  );
};

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { TokenIconProps } from "./types";

const ImgWrapper = styled.div`
  height: 100%;
`;
const Img = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.radii.circle};
  background: transparent;
`;
const Alt = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  background: ${({ theme }) => theme.colors.grey.darkGrey2};
  border-radius: ${({ theme }) => theme.radii.circle};
`;
const AltText = styled.span<{ size: number }>`
  margin: auto;
  font-size: ${({ size }) => size / 3 + "px"};
  font-weight: 500;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.grey.white} !important;
`;

export const TokenIcon: React.FC<TokenIconProps> = ({ src, alt }) => {
  const [imgBroke, setImgBroke] = useState<boolean>(false);
  const [height, setHeight] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) setHeight(ref.current.offsetHeight);
  });

  return (
    <>
      {src && !imgBroke && (
        <ImgWrapper>
          <Img src={src} alt={alt} onError={() => setImgBroke(true)} />
        </ImgWrapper>
      )}
      {(!src || imgBroke) && alt && (
        <Alt ref={ref}>
          <AltText size={height}>{alt.substring(0, 3).toUpperCase()}</AltText>
        </Alt>
      )}
    </>
  );
};

import LinearProgress from "@mui/material/LinearProgress";
import styled, { useTheme } from "styled-components";
import { LoaderProps } from "./types";

const BorderLinearProgress = styled(LinearProgress)<LoaderProps>`
  height: ${({ height }) => (height ? height : "28px")} !important;
  opacity: 0.1;
  border-radius: ${({ theme, radius }) => (radius ? radius : theme.radii.md)};
  width: ${({ size }) => (size ? size : "100px")};
`;

export const LinearLoader: React.FC<LoaderProps> = ({
  size,
  height,
  radius,
  color,
  ...props
}) => {
  const theme = useTheme();
  return (
    <BorderLinearProgress
      size={size}
      height={height}
      radius={radius}
      color={color ?? (theme.isDark ? "primary" : "inherit")}
      {...props}
    />
  );
};

import { PlusIcon } from "@aperture/assetkit";
import { Flex } from "@ui/common";
import { PropsWithChildren, useState } from "react";
import { useTheme } from "styled-components";
import { DrawerTitle as BackWrapper } from "../Drawer";
import { BtnSwitchTab } from "../Tabs";
import { Headline5 } from "../Typography";
import { HistoryList } from "./HistoryList";
import {
  Container,
  Content,
  DrawerContentWrapper,
  DrawerTitle,
  DrawerWrapper,
  MenuText,
  SideBar,
  StyledText,
} from "./style";
import { IIntentGptProps, ITab } from "./types";

const tabItems: ITab[] = ["Chat", "History"];

export const IntentGptDrawer: React.FC<IIntentGptProps & PropsWithChildren> = ({
  histories,
  onHistoryClick,
  onHistoryDelete,
  onClickNewChat,
  drawerOpen,
  onClickOpenDrawer,
  onClickBack,
  children,
  ...props
}) => {
  const [tab, setTab] = useState<ITab>("Chat");
  const theme = useTheme();
  const handleHistoryClick = (chatId: string) => {
    onHistoryClick(chatId);
    setTab("Chat");
  };

  const handleClickNewChat = () => {
    onClickNewChat();
    setTab("Chat");
  };

  return (
    <Container {...props} open={drawerOpen}>
      <SideBar open={drawerOpen} onClick={onClickOpenDrawer}>
        <MenuText>INTENTSGPT</MenuText>
      </SideBar>
      <DrawerWrapper open={drawerOpen}>
        <DrawerContentWrapper gap={"3xl"}>
          <BackWrapper onClick={onClickBack} isDrawer={true}>
            <Headline5 fontWeight={500} textAlign={"end"}>
              Back
            </Headline5>
          </BackWrapper>
          <Flex justifyContent="space-between" alignItems="center">
            <DrawerTitle>IntentsGPT</DrawerTitle>
            <BtnSwitchTab
              menu={tabItems}
              selected={tabItems.indexOf(tab)}
              onSelect={(value: number) => {
                setTab(tabItems[value]);
              }}
              onBtnClick={handleClickNewChat}
              btn={
                <Flex gap="md">
                  <PlusIcon
                    fill={theme.colors.global.primary}
                    width={14}
                    height={14}
                  />
                  <StyledText>New Chat</StyledText>
                </Flex>
              }
            />
          </Flex>
          <Content>
            {tab === "Chat" && children}
            {tab === "History" && (
              <HistoryList
                histories={histories}
                onClick={handleHistoryClick}
                onDelete={onHistoryDelete}
              />
            )}
          </Content>
        </DrawerContentWrapper>
      </DrawerWrapper>
    </Container>
  );
};

import { InfoIcon2 } from "@aperture/assetkit";
import React from "react";
import { StyledPopover } from ".";
import { IQuestionPopoverProps } from "./types";

export const InfoPopover: React.FC<IQuestionPopoverProps> = ({
  content,
  defaultPosition,
  defaultSize,
  iconSize = 14,
  ...props
}) => {
  return (
    !!content && (
      <StyledPopover {...{ content, defaultSize, defaultPosition }}>
        <InfoIcon2 height={iconSize} width={iconSize} {...props} />
      </StyledPopover>
    )
  );
};

import React from "react";
import styled from "styled-components";
import { Direction, TokenIconsProps } from "./types";

const TokenContainer = styled.div<{ size: number }>`
  display: flex;
  align-items: center;
`;
const IconWrapper = styled.div<{
  direction: Direction;
  size: number;
  overlap: number;
  overlapRight?: boolean;
}>`
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  margin-left: ${({ direction, overlap }) =>
    direction === Direction.Right ? `-${overlap}px` : "0"};
  z-index: ${({ direction, overlapRight }) =>
    direction === Direction.Left && overlapRight ? 1 : undefined};
`;

export const TokenIcons: React.FC<TokenIconsProps> = ({
  leftIcon,
  rightIcon,
  size,
  overlap = 12,
  overlapRight = true,
  ...props
}) => {
  return (
    <TokenContainer size={size} {...props}>
      <IconWrapper
        direction={Direction.Left}
        {...{ size, overlap, overlapRight }}
      >
        {leftIcon}
      </IconWrapper>
      <IconWrapper
        direction={Direction.Right}
        {...{ size, overlap, overlapRight }}
      >
        {rightIcon}
      </IconWrapper>
    </TokenContainer>
  );
};

import { MinusIcon, PlusIcon } from "@aperture/assetkit";
import React, { useCallback, useEffect, useState } from "react";
import {
  IGPlusMinus,
  PlusMinusMark,
  StyledContainer,
  StyledNumericalInput,
  Title,
} from "./style";
import { InputGroupPlusMinusProps } from "./types";

export const InputGroupPlusMinus: React.FC<InputGroupPlusMinusProps> = ({
  sm = false,
  value,
  placeholder,
  title,
  subTitle,
  disabled = false,
  warning = false,
  allowNegative = false,
  format,
  onChange = (value: string) => value,
  plusClick,
  minusClick,
}) => {
  //  for focus state, styled components doesn't let you select input parent container
  const [active, setActive] = useState(false);

  // animation if parent value updates local value
  const [pulsing, setPulsing] = useState<boolean>(!disabled);

  // let user type value and only update parent value on blur
  const [localValue, setLocalValue] = useState("");
  const [useLocalValue, setUseLocalValue] = useState(false);

  const handleOnFocus = () => {
    setUseLocalValue(true);
    setActive(true);
  };

  const handleOnBlur = useCallback(() => {
    setUseLocalValue(false);
    setActive(false);
    // trigger update on parent value
    if (!isNaN(parseFloat(localValue))) onChange(localValue);
  }, [localValue, onChange]);

  // for button clicks
  const handleDecrement = useCallback(() => {
    setUseLocalValue(false);
    minusClick();
  }, [minusClick]);

  const handleIncrement = useCallback(() => {
    setUseLocalValue(false);
    plusClick();
  }, [plusClick]);

  useEffect(() => {
    if (value !== undefined && localValue !== value && !useLocalValue) {
      const timeout = setTimeout(() => {
        setLocalValue(value); // reset local value to match parent
        !disabled && setPulsing(true); // trigger animation
        setTimeout(function () {
          setPulsing(false);
          clearTimeout(timeout);
        }, 1800);
      }, 0);
      return () => clearTimeout(timeout);
    }
  }, [localValue, useLocalValue, value, disabled]);

  return (
    <IGPlusMinus active={active} pulsing={pulsing} warning={warning}>
      <Title>{title}</Title>
      <StyledContainer>
        <PlusMinusMark sm={sm} disabled={disabled} onClick={handleDecrement}>
          <MinusIcon />
        </PlusMinusMark>
        <StyledNumericalInput
          sm={sm}
          placeholder={placeholder}
          value={localValue}
          fontSize="24px"
          disabled={disabled}
          prependSymbol={format?.prefix}
          appendSymbol={format?.suffix}
          allowNegative={allowNegative}
          onUserInput={(val: string) => {
            setLocalValue(val);
          }}
          //@ts-ignore
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
        />
        <PlusMinusMark sm={sm} disabled={disabled} onClick={handleIncrement}>
          <PlusIcon />
        </PlusMinusMark>
      </StyledContainer>
      <Title>{subTitle}</Title>
    </IGPlusMinus>
  );
};

import { AuthContent, Button } from "@aperture/uikit";
import styled from "styled-components";
import AuthorizationBtn from "../Button/AuthorizationBtn";
import { useAOStore } from "../GlobalStore";

const ButtonWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
  margin-top: 24px;
`;

const ButtonBox = styled(Button)`
  background: ${({ theme }) => theme.colors.global.background.BG3};
  color: ${({ theme }) => theme.colors.global.text.T1};
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  :hover {
    background: ${({ theme }) => theme.colors.global.background.BG3};
    opacity: 0.8;
  }
`;

export const AuthModal: React.FC<{
  onclose: () => void;
}> = ({ onclose }) => {
  const { ifAuthorized } = useAOStore();

  return (
    <>
      <AuthContent ifAuthorized={ifAuthorized} />
      <ButtonWrapper>
        <ButtonBox
          width="240px"
          variant="contained"
          size="lg"
          onClick={onclose}
        >
          Cancel
        </ButtonBox>
        <AuthorizationBtn onClick={onclose} />
      </ButtonWrapper>
    </>
  );
};

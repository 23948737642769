import { GoToIcon } from "@aperture/assetkit";
import React from "react";
import styled from "styled-components";
import { LinkProps } from "./types";

const GotoWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    fill: ${({ theme }) => theme.colors.global.textAlt.T1};
  }
`;

export const GoToLink: React.FC<LinkProps> = ({ link, ...props }) => {
  return (
    <GotoWrapper {...props}>
      <GoToIcon
        width={22.5}
        height={22.5}
        onClick={(event) => {
          event.stopPropagation();
          window.open(link, "_blank");
        }}
      />
    </GotoWrapper>
  );
};

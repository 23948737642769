import { ArrowRightIcon } from "@aperture/assetkit";
import { ITokenTickerIcon } from "@aperture/types";
import { Flex, FlexColumn } from "@ui/common";
import { CSSProperties, Fragment, HTMLAttributes } from "react";
import { TokenIcon } from "../TokenIcon";
import {
  B2,
  Connect,
  DetailsRow,
  IconConnect,
  Line,
  PercentageTag,
  PercentageTagWrapper,
  PureIconWrapper,
  RightArrow,
  S2,
  S3,
  StyledArrowIcon,
  TagWrapper,
  TokenTag,
} from "./style";
import { ITokenWithDetails, SwapRouteProps } from "./types";

export const tokens: Record<string, ITokenTickerIcon> = {
  WETH: {
    Icon: (
      <TokenIcon
        src="https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png"
        alt="WETH"
      />
    ),
    ticker: "WETH",
  },
  AVAX: {
    Icon: (
      <TokenIcon
        src="https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/avalanchec/assets/0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7/logo.png"
        alt="AVAX"
      />
    ),
    ticker: "AVAX",
  },
  USDC: {
    ticker: "USDC",
    Icon: (
      <TokenIcon
        src="https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png"
        alt="USDC"
      />
    ),
  },
  DAI: {
    Icon: (
      <TokenIcon
        src="https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png"
        alt="DAI"
      />
    ),
    ticker: "DAI",
  },
  USDT: {
    Icon: (
      <TokenIcon
        src="https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png"
        alt="USDT"
      />
    ),
    ticker: "USDT",
  },
};
export const getDetailHeight = (text: string) => {
  const styles: CSSProperties = {
    fontSize: "12px",
    lineHeight: "18px",
    wordBreak: "break-all",
    maxWidth: "78.88px",
    visibility: "hidden",
  };
  const detail = document.createElement("div");
  detail.textContent = text;
  Object.assign(detail.style, styles);

  document.body.appendChild(detail);
  const height = detail.getBoundingClientRect().height;
  document.body.removeChild(detail);
  return height;
};
export const getHeights = (routes: SwapRouteProps["routes"]) => {
  if (!routes) return { verticalLineHeight: 0, fromTokenMargin: 0 };
  const routeHeights = routes.map((route) => {
    // Details box that has the max. height per route
    const maxDetailsHeight = route.tokenDetails.reduce((max, tokenDetail) => {
      const totalHeight =
        tokenDetail.details?.reduce((acc, detail, index) => {
          const h = getDetailHeight(detail.title);
          return (
            acc +
            h +
            (tokenDetail.details && index !== tokenDetail.details.length - 1
              ? 4
              : 0)
          );
        }, 0) ?? 0;
      return Math.max(max, tokenDetail.details ? totalHeight : 0);
    }, 0);

    return (
      16 + // Token icon, ticker
      12 + // Padding top, bottom
      (maxDetailsHeight > 0 ? 8 : 0) + // gap
      maxDetailsHeight
    );
  });
  const total = routeHeights.reduce(
    (sum, height) => sum + height,
    (routes.length - 1) * 8
  );
  const lastRouteHeight =
    routeHeights.length > 0 ? routeHeights[routeHeights.length - 1] : 0;
  const verticalLineHeight = total - lastRouteHeight;

  const isEvenRoutes = routes.length % 2 === 0;
  const heightIndex = Math.floor(
    isEvenRoutes ? routes.length / 2 - 1 : (routes.length - 1) / 2 - 1
  );
  const fromTokenMargin =
    routes.length > 1
      ? 8 +
        routeHeights.reduce((sum, height, index) => {
          if (index < heightIndex) return sum + height;
          if (index === heightIndex)
            return sum + (isEvenRoutes ? height / 2 : height);
          return sum + 0;
        }, 0) +
        8 * ((routes.length - 1) / 2)
      : 8;

  return { verticalLineHeight, fromTokenMargin };
};

export const ConnectingLine = ({
  dark,
  withArrow,
  percentage,
  alignPercentage,
  marginTop,
  ...props
}: {
  dark: boolean;
  withArrow?: boolean;
  percentage?: string;
  alignPercentage?: boolean;
  marginTop?: number;
} & HTMLAttributes<HTMLDivElement>) => {
  return (
    <Connect {...props} marginTop={marginTop}>
      <Line />
      {percentage && (
        <PercentageTagWrapper dark={dark} isAligned={!!alignPercentage}>
          <PercentageTag dark={dark}>
            <S2 dark={dark}>{percentage}%</S2>
          </PercentageTag>
        </PercentageTagWrapper>
      )}
      {withArrow && (
        <RightArrow dark={dark}>
          <StyledArrowIcon />
        </RightArrow>
      )}
    </Connect>
  );
};

export const Route = ({
  dark,
  isMultiple = false,
  alignTag = false,
  tokenDetails,
  noConnectingLine = false,
  percentage,
}: {
  dark: boolean;
  isMultiple: boolean;
  alignTag: boolean;
  tokenDetails: ITokenWithDetails[];
  noConnectingLine?: boolean;
  percentage?: string;
}) => {
  const hasDetails = tokenDetails.some((tokenDetail) => !!tokenDetail.details);
  const tokenTags = tokenDetails.map((tokenDetail) => tokenDetail.token);
  return (
    <Flex alignItems="flex-start">
      {tokenTags.map((token, index) => {
        const withDetails = !!tokenDetails?.[index]?.details;
        return (
          token && (
            <Fragment key={`${index}-${token.ticker}`}>
              {noConnectingLine ? (
                <IconConnect>
                  <ArrowRightIcon width={24} height={24} />
                </IconConnect>
              ) : (
                <ConnectingLine
                  dark={dark}
                  withArrow={!isMultiple || (isMultiple && index !== 0)}
                  percentage={index === 0 && percentage ? percentage : ""}
                  alignPercentage={alignTag}
                  style={{ marginTop: hasDetails ? "8px" : undefined }}
                />
              )}
              <TagWrapper minW={hasDetails ? "132px" : undefined}>
                <TokenTag
                  dark={dark}
                  gap="md"
                  flexDirection="column"
                  tagWithoutDetails={hasDetails && !withDetails}
                >
                  <Flex
                    alignItems="center"
                    gap="sm"
                    style={{ width: withDetails ? "100%" : undefined }}
                  >
                    <PureIconWrapper>{token.Icon}</PureIconWrapper>
                    <S2 dark={dark}>{token.ticker}</S2>
                  </Flex>
                  {withDetails && (
                    <FlexColumn gap="sm" style={{ width: "100%" }}>
                      {tokenDetails[index].details?.map(
                        ({ title, percentage }, index) => (
                          <DetailsRow key={`${title}-${index}`}>
                            <B2>{title}</B2>
                            <S3 dark={dark}>{percentage}%</S3>
                          </DetailsRow>
                        )
                      )}
                    </FlexColumn>
                  )}
                </TokenTag>
              </TagWrapper>
            </Fragment>
          )
        );
      })}
    </Flex>
  );
};

import { Flex } from "@ui/common";
import styled from "styled-components";
import { Button } from "../Button";
import { Input } from "../Input";
import { Subtitle2, Subtitle3 } from "../Typography";

export const SlippageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${({ theme }) => theme.colors.global.text.T1};
  gap: ${({ theme }) => theme.spacing.lg};
`;

export const WarningWrapper = styled.div`
  display: flex;
  margin: 6px 8px 0 8px;
  width: 200px;
  svg {
    path {
      stroke: ${({ theme }) => theme.colors.errorWarning.orange};
    }
    stop {
      stopcolor: ${({ theme }) => theme.colors.errorWarning.orange};
    }
  }
`;

export const IconWrapper = styled.div`
  margin-top: -2px;
  height: 15px;
  width: 15px;
`;

export const WarningText = styled(Subtitle2)`
  margin-left: 4px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.errorWarning.orange};
`;

export const StyledInput = styled(Input)<{
  warning?: boolean;
  maxWidth?: number;
}>`
  height: 40px;
  width: 100%;
  padding: 16px;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`};
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.global.text.T1};
  border: 1px solid
    ${({ theme, warning }) =>
      warning
        ? theme.colors.errorWarning.orange
        : theme.colors.global.line.Line2};
  &:disabled {
    background-color: ${({ theme }) => theme.colors.global.background.BG4};
    color: ${({ theme }) => theme.colors.global.text.T3};
  }
`;

export const RightButton = styled.button<{ isPrimary: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  background: transparent;
  outline: none;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding-right: ${({ theme }) => theme.spacing.xl};
  color: ${({ theme, isPrimary }) =>
    isPrimary ? theme.colors.global.text.T1 : theme.colors.global.primary};
  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  height: 40px;
  width: 70px;
  box-sizing: border-box;
`;

export const S3 = styled(Subtitle3)<{ isSm?: boolean }>`
  box-sizing: border-box;
  font-weight: 400;
  text-align: right;
  padding-top: ${({ theme }) => theme.spacing.sm};
  padding-right: ${({ theme, isSm }) =>
    isSm ? theme.spacing.md : theme.spacing.xl};
  color: ${({ theme }) => theme.colors.grey.darkGrey2};
  width: ${({ isSm }) => (isSm ? "126px" : "180px")};
`;

export const Text = styled(Subtitle3)`
  line-height: 16px;
  color: ${({ theme }) => theme.colors.global.text.T2};
`;
export const Row = styled(Flex)`
  padding-top: ${({ theme }) => theme.spacing.lg};
`;

export const Checkbox = styled.input.attrs({ type: "checkbox" })`
  cursor: pointer;
  font-size: 14px;
  height: 16px;
  width: 16px;
  min-width: 16px;
  outline: none;
  box-shadow: none;
  appearance: none;
  position: relative;
  border-radius: ${({ theme }) => theme.radii.round};
  &:after {
    position: absolute;
    width: 18px;
    height: 18px;
    left: -2px;
    top: -2px;
    content: "";
    display: inline-block;
    visibility: visible;
    background: ${({ theme }) => theme.colors.global.background.BG2};
    border: 1px solid ${({ theme }) => theme.colors.global.line.Line2};
    border-radius: ${({ theme }) => theme.radii.round};
  }
  &:checked:after {
    content: "✔";
    text-align: center;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.grey.black};
    background-color: ${({ theme }) => theme.colors.global.success};
  }
  &:disabled:after {
    background: #d3d1e0;
    border-color: ${({ theme }) => theme.colors.grey.grey};
  }
`;

import { Flex, FlexColumn } from "@ui/common";
import _ from "lodash";
import { useTheme } from "styled-components";
import { FirstLookWrapper, PromptLink, SubTitle, Title } from "./styles";

export const FirstLook: React.FC<{
  isDrawer: boolean;
  presetQuestions: string[];
  onPromptSelected: (prompt: string) => void;
}> = ({ isDrawer, presetQuestions, onPromptSelected }) => {
  const theme = useTheme();
  const prompts = _.chunk(presetQuestions, 2);
  return (
    <FirstLookWrapper>
      <FlexColumn gap="3xl" alignItems="center">
        {!isDrawer && <Title fontWeight={500}>IntentsGPT</Title>}

        <SubTitle isDrawer={isDrawer}>
          You can ask me information about your Uniswap & PancakeSwap LP
          positions, intents with Aperture Finance, and anything about Defi you
          want to know about.
        </SubTitle>
      </FlexColumn>

      <FlexColumn gap={19} alignItems="center">
        {prompts.map((row, idx) => (
          <Flex
            gap="xl"
            justifyContent={isDrawer ? "flex-start" : "center"}
            flexWrap="wrap"
            key={idx}
          >
            {row.map((prompt) => (
              <PromptLink
                key={prompt}
                {...{
                  text: prompt,
                  withBorder: true,
                  textColor: theme.colors.global.text.T1,
                  iconColor: theme.colors.global.text.T1,
                  onLinkClick: () => onPromptSelected(prompt),
                  hover: {
                    type: "border",
                    color: theme.colors.global.text.T1,
                  },
                }}
              />
            ))}
          </Flex>
        ))}
      </FlexColumn>
    </FirstLookWrapper>
  );
};

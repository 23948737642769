import { ExchangeIcon } from "@aperture/assetkit";
import { Flex, T3Regular } from "@aperture/uikitv2";
import { FlexColumn } from "@ui/common/styles/global";
import { getStrategyText } from "@ui/components/StrategyInfoCard/getStrategyText";
import { formatMarketPrice, formatUSDAmount } from "@ui/utils";
import bigDecimal from "js-big-decimal";
import { useState } from "react";
import styled from "styled-components";
import { WhiteCard } from "../../Card";
import { Body1, Headline4, Subtitle2 } from "../../Typography";
import { IDeleteStrategyProps } from "../types";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing["3xl"]};
  width: 386px;
`;
const S2 = styled(Subtitle2)`
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const StyledSubtitle2 = styled(Subtitle2)`
  color: ${({ theme }) => theme.colors.global.text.T2};
`;
const StyledHeadline4 = styled(Headline4)`
  font-family: "Dunbar Tall";
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const StyledBody1 = styled(Body1)`
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const StyledWhiteCard = styled(WhiteCard)`
  background-color: ${({ theme }) => theme.colors.global.background.BG3};
`;
const SwapButton = styled.button`
  width: 20px;
  height: 20px;
  padding: 0;
  margin-top: -2px;
  cursor: pointer;
  border: none;
  background: none;
`;
const SwapButtonIcon = styled(ExchangeIcon)`
  transform: rotate(0);
  transition: transform ${({ theme }) => theme.transitions.default} ease;
  fill: ${({ theme }) => theme.colors.global.primary};
`;

export const DeleteStrategyContent: React.FC<IDeleteStrategyProps> = ({
  tokens,
  conditionSpotPrice,
  triggerInfo,
  revenueEarned,
  timeBuffer,
  frequency,
}) => {
  const isDualAction = triggerInfo.length > 1;

  const strategyText = triggerInfo.map(
    ({ triggerType, rebalanceType, triggerValue, rebalanceValue }, idx) => {
      const text = getStrategyText(
        triggerType,
        rebalanceType,
        tokens,
        triggerValue,
        rebalanceValue
      );
      return isDualAction ? { ...text, Trigger: text.Trigger[idx] } : text;
    }
  );

  const triggerData = isDualAction
    ? strategyText.reduce((acc, text, idx) => {
        acc = {
          ...acc,
          [`Trigger ${idx + 1}`]: text.Trigger,
          [`New Price Range${idx + 1}`]: text["New Price Range"],
        };
        return acc;
      }, {})
    : strategyText[0];

  const [swap, setSwap] = useState(false);
  const data = {
    "Condition Spot Price": !!Number(conditionSpotPrice) ? (
      <Flex gap="md">
        <T3Regular>
          {formatMarketPrice(
            swap
              ? bigDecimal.divide(1, conditionSpotPrice, 18)
              : conditionSpotPrice
          )}{" "}
          {tokens[swap ? 0 : 1].ticker} per {tokens[swap ? 1 : 0].ticker}
        </T3Regular>
        <SwapButton
          onClick={(event) => {
            event.stopPropagation();
            setSwap(!swap);
          }}
        >
          <SwapButtonIcon />
        </SwapButton>
      </Flex>
    ) : undefined,
    ...triggerData,
    "TWAP Duration": timeBuffer
      ? `${timeBuffer} ${
          bigDecimal.compareTo(timeBuffer, 1) > 0 ? "hrs" : "hr"
        }`
      : undefined,
    "Max Frequency": frequency ? `${frequency} times / day` : undefined,
  };
  return (
    <ContentWrapper>
      <FlexColumn gap="xl">
        <StyledHeadline4>Confirm Delete Strategy</StyledHeadline4>
        <StyledBody1>
          You have earned {formatUSDAmount(revenueEarned.total)} fees with this
          strategy in the past 30 days. Are you sure you want to delete this
          strategy?
        </StyledBody1>
      </FlexColumn>
      <StyledWhiteCard flexDirection="column">
        {data &&
          Object.entries(data).map(([label, value]) => {
            const isArray = Array.isArray(value);
            return (
              value && (
                <FlexColumn gap="md" key={label}>
                  <S2>
                    {isDualAction && label.includes("New Price Range")
                      ? label.slice(0, -1)
                      : label}
                  </S2>
                  {isArray ? (
                    value.map((v, idx) => (
                      <StyledSubtitle2 key={idx}>{v}</StyledSubtitle2>
                    ))
                  ) : (
                    <StyledSubtitle2>{value}</StyledSubtitle2>
                  )}
                </FlexColumn>
              )
            );
          })}
      </StyledWhiteCard>
    </ContentWrapper>
  );
};

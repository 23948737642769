import { ITokenTickerIcon } from "@aperture/types";
import { ReactNode } from "react";

export interface IDetails {
  title: string;
  percentage: string;
}
export interface ITokenWithDetails {
  token: ITokenTickerIcon;
  details?: IDetails[];
}

export interface SwapRouteProps {
  dark?: boolean;
  wBorder?: boolean;
  loading: boolean;
  priceImpact: string;
  api: { name: string; Icon: ReactNode; solver: E_Solver };
  fromToken: ITokenTickerIcon;
  routes?: {
    tokenDetails: ITokenWithDetails[];
    percentage?: string;
  }[];
  noConnectingLine?: boolean;
  maxWidth?: number;
  gasFeeUSD?: string;
  suggested?: boolean;
}

export enum E_Solver {
  SamePool = "SamePool",
  OKX = "OKX",
  PH = "PropellerHeads",
  OneInch = "1Inch",
}

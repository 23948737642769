import { DeleteIcon, EditIcon, ExchangeIcon } from "@aperture/assetkit";
import { Flex, FlexColumn } from "@ui/common/styles/global";
import styled from "styled-components";
import { Notification } from "../Notification";
import { EditBtn } from "../TriggerList/style";
import { Body1, Body2 } from "../Typography";

export const StrategyInfoBox = styled(FlexColumn)`
  gap: ${({ theme }) => theme.spacing.md};
`;
export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Title = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;
export const B1 = styled(Body1)`
  line-height: 16px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const B2 = styled(Body2)`
  line-height: 14px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const TitleButtons = styled(Flex)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
`;
export const StyledEditIcon = styled(EditIcon)`
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.colors.global.primary};
  }
`;
export const StyledDeleteIcon = styled(DeleteIcon)`
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.colors.global.primary};
  }
`;
export const StyledOutlineBtn = styled(EditBtn)`
  line-height: 14px;
  min-width: fit-content;
`;
export const ItemTitle = styled(Body1)`
  line-height: 16px;
  color: ${({ theme }) => theme.colors.global.text.T3};
`;
export const ItemValue = styled(Body1)`
  line-height: 16px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const InfoContent = styled(Flex)`
  width: 100%;
`;
export const Box = styled(FlexColumn)`
  width: 100%;
  gap: ${({ theme }) => theme.spacing.md};
`;
export const Item = styled(Flex)`
  width: 100%;
  gap: ${({ theme }) => theme.spacing.lg};
`;
export const ValueWrapper = styled(FlexColumn)`
  flex: 1 1 0%;
  align-items: end;
  gap: ${({ theme }) => theme.spacing.sm};
`;
export const StyledNotification = styled(Notification)`
  margin-top: ${({ theme }) => theme.spacing.xl};
`;
export const Wrapper = styled(FlexColumn)`
  align-items: flex-end;
  gap: ${({ theme }) => theme.spacing.md};
  margin-left: ${({ theme }) => theme.spacing.md};
`;
export const SwapButton = styled.button`
  width: 20px;
  height: 20px;
  padding: 0;
  margin-top: -2px;
  cursor: pointer;
  border: none;
  background: none;
`;
export const SwapButtonIcon = styled(ExchangeIcon)`
  transform: rotate(0);
  transition: transform ${({ theme }) => theme.transitions.default} ease;
  fill: ${({ theme }) => theme.colors.global.primary};
`;

import React from "react";
import styled from "styled-components";
import { NFTPositionCardProps } from "./types";

const StyledImg = styled.img`
  width: 100%;
  object-fit: contain;
`;

export const NFTPositionCard: React.FC<NFTPositionCardProps> = ({
  NFTStr,
  ...props
}) => {
  try {
    const uri = window.atob(
      NFTStr.replace("data:application/json;base64,", "")
    );
    const safeURL = new URL(JSON.parse(uri).image);
    return <StyledImg src={safeURL.href} {...props} />;
  } catch (error) {
    return <></>;
  }
};

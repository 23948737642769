import moment from "moment";

export const countDownDuration = (targetTime: number) => {
  const now = moment();
  const target = moment(targetTime);
  return moment.duration(target.diff(now));
};

export const countDownFormat = (targetTime: number) => {
  const duration = countDownDuration(targetTime);
  if (duration.asMilliseconds() <= 0) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

export const timestampFormat = (
  timestamp: number,
  showSecond?: boolean,
  hour12?: boolean
) => {
  const date = new Date(timestamp * 1e3);
  const dateFormat = date
    .toLocaleTimeString("en-US", {
      month: "short",
      year: "numeric",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: showSecond ? "2-digit" : undefined,
      hour12: hour12 ?? true,
    })
    .split(", ");
  const time = (dateFormat[2] ?? "-").split(":").join(":").toLowerCase();

  return { date: `${dateFormat[0]}, ${dateFormat[1]}`, time };
};

export function getDuration(
  timestamp1: number,
  timestamp2: number,
  capitalize?: boolean
) {
  // milliseconds
  timestamp1 *= 1000;
  timestamp2 *= 1000;
  const difference = Math.abs(timestamp1 - timestamp2);

  const minutesTotal = Math.ceil(difference / 60000);
  const hoursTotal = Math.floor(minutesTotal / 60);
  const days = Math.floor(hoursTotal / 24);
  const hours = hoursTotal % 24;
  const minutes = minutesTotal % 60;

  let formattedDuration = "";
  if (days > 0) {
    formattedDuration = `${days} ${capitalize ? "D" : "d"}ay${
      days > 1 ? "s" : ""
    }`;
    if (hours > 0) {
      formattedDuration += ` ${hours} ${capitalize ? "H" : "h"}our${
        hours > 1 ? "s" : ""
      }`;
    }
  } else if (hours > 0) {
    formattedDuration = `${hours} ${capitalize ? "H" : "h"}our${
      hours > 1 ? "s" : ""
    }`;
  } else {
    formattedDuration = `${minutes} ${capitalize ? "M" : "m"}inute${
      minutes > 1 ? "s" : ""
    }`;
  }

  return formattedDuration;
}

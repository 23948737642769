import React from "react";
import styled from "styled-components";
import { CardProps } from "./types";

const StyledCard = styled.div<{ selected: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 16px;
  gap: ${({ theme }) => theme.spacing.xl};
  ${({ theme, selected }) =>
    selected
      ? `
      background: ${theme.colors.transparent.white2};
      border: 1.5px solid ${theme.colors.primary.purple};
      `
      : `
      background: ${theme.colors.transparent.grey1};
      border: 1.5px solid ${theme.colors.transparent.transparent};
      `}
  border-radius: ${({ theme }) => theme.radii.md};
  transition: 0.5s;
  :hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.transparent.white2};
  }
`;

export const Card: React.FC<React.PropsWithChildren<CardProps>> = ({
  selected = false,
  children,
  ...props
}) => {
  return (
    <StyledCard selected={selected} {...props}>
      {children}
    </StyledCard>
  );
};

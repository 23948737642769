import { CopyIcon, LinkIcon2 } from "@aperture/assetkit";
import copy from "copy-to-clipboard";
import styled from "styled-components";
import { ITokenWarningContentProps } from "..";
import { Button } from "../../Button";
import { Body2, CommonLgText } from "../../Typography";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 420px;
  gap: ${({ theme }) => theme.spacing["3xl"]};
`;
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing["2xl"]};
`;
const TokenIconWrapper = styled.div`
  height: 80px;
  width: 80px;
`;
const WarningWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 38px;
  gap: ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.radii.md};
  background: ${({ theme }) => theme.colors.global.warning};
`;
const WarningText = styled(Body2)`
  font-size: 16px;
  line-height: 22px;
  padding: 0px 24px;
  color: ${({ theme }) => theme.colors.grey.black};
`;
const MainText = styled(CommonLgText)`
  color: ${({ theme }) => theme.colors.global.text.T2};
  text-align: center;
`;
const URLContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  height: 40px;
  gap: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.global.text.T1};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line2};
  border-radius: ${({ theme }) => theme.radii.round};
`;
const URLWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.lg};
`;
const URLRightArrow = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;
const URL = styled(CommonLgText)`
  margin-left: 24px;
  max-width: 304px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const StyledCopyIcon = styled(CopyIcon)`
  color: ${({ theme }) => theme.colors.global.text.T1};
  &:hover {
    color: ${({ theme }) => theme.colors.global.primaryHover};
    cursor: pointer;
  }
`;
const OpenLinkIcon = styled(LinkIcon2)`
  stroke: ${({ theme }) => theme.colors.global.text.T1};
  &:hover {
    cursor: pointer;
    stroke: ${({ theme }) => theme.colors.global.primaryHover};
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.xl};
`;
const ConfirmButton = styled(Button)`
  width: 100%;
  border-radius: ${({ theme }) => theme.radii.round};
`;
const CancelButton = styled(Button)`
  border-radius: ${({ theme }) => theme.radii.round};
  text-decoration: none;
`;

export const TokenWarningContent: React.FC<ITokenWarningContentProps> = ({
  amm,
  token,
  exploreURL,
  confirmClick,
  cancelClick,
  onClose,
}) => {
  const infoURL = `${exploreURL}/token/${token.address}`;
  return (
    <ContentWrapper>
      <InfoWrapper>
        <TokenIconWrapper>{token.Icon}</TokenIconWrapper>
        <WarningWrapper>
          <WarningText fontWeight={500}>Warning</WarningText>
        </WarningWrapper>
        <MainText>
          This token isn't traded on leading U.S. centralized exchanges or
          frequently swapped on{" "}
          {amm === "UNISWAP_V3" ? "Uniswap" : "PancakeSwap"}. Always conduct
          your own research before trading.
        </MainText>
      </InfoWrapper>
      <URLContainer>
        <URLWrapper>
          <URL>{infoURL}</URL>
          <StyledCopyIcon
            height={20}
            width={20}
            onClick={() => {
              copy(infoURL);
            }}
          />
        </URLWrapper>
        <URLRightArrow
          onClick={() => {
            window.open(infoURL);
          }}
        >
          <OpenLinkIcon height={20} width={20} />
        </URLRightArrow>
      </URLContainer>
      <ButtonWrapper>
        <ConfirmButton
          width="100%"
          variant="contained"
          color="secondary"
          size="lg"
          onClick={() => {
            confirmClick(token);
            onClose?.();
          }}
        >
          I understand
        </ConfirmButton>
        <CancelButton
          width="100%"
          variant="contained"
          color="default"
          size="lg"
          onClick={cancelClick}
        >
          Cancel
        </CancelButton>
      </ButtonWrapper>
    </ContentWrapper>
  );
};

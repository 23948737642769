import { NetErrorIcon, SwitchIcon } from "@aperture/assetkit";
import styled from "styled-components";
import {
  SubTitle,
  TextWrapper,
  Title,
  ContentWrapper as Wrapper,
} from "../style";
import { WrongNetworkProps } from "../types";

const ContentWrapper = styled(Wrapper).attrs({
  id: "wrong-network-modal-show",
})`
  width: 448px;
`;
const Capitalized = styled.span`
  text-transform: capitalize;
`;
const NetError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: ${({ theme }) => theme.radii.round};
  background: ${({ theme }) => theme.colors.global.error};
`;

export const WrongNetworkContent: React.FC<WrongNetworkProps> = ({
  networkName,
  content,
  isSwitchChain = false,
  isUnsupported = false,
}) => {
  return (
    <ContentWrapper>
      {isSwitchChain ? (
        <SwitchIcon />
      ) : (
        <NetError>
          <NetErrorIcon />
        </NetError>
      )}
      <TextWrapper>
        <Title>
          {isUnsupported
            ? "Network Not Supported"
            : isSwitchChain
            ? "Switch Network"
            : "Wrong Network"}
        </Title>
        {content ?? (
          <SubTitle>
            Open your wallet to switch to{" "}
            <Capitalized>{networkName.toLowerCase()}</Capitalized>
            <br />
            to continue.
          </SubTitle>
        )}
      </TextWrapper>
    </ContentWrapper>
  );
};

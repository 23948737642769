import { AmmEnum, OrderStatus } from "@aperture/uikit";
import {
  ApertureSupportedChainId,
  parsePrice,
  viem,
} from "@aperture_finance/uniswap-v3-automation-sdk";
import { FeeAmount } from "@aperture_finance/uniswap-v3-sdk";
import bigDecimal from "js-big-decimal";
import { Address, PublicClient } from "viem";
import { TriggerStatus } from "./triggerHelper";

export interface LOToken {
  ticker: string;
  address: Address; // provide weth address if native
  native: boolean;
  amount: string;
}

export async function getTransactionForLimitOrder(
  ammEnum: AmmEnum,
  chainId: ApertureSupportedChainId,
  address: Address,
  sellToken: LOToken,
  buyToken: LOToken,
  feeTier: FeeAmount,
  rangeWidth: number,
  client: PublicClient
) {
  const [tokenA, tokenB] = await Promise.all([
    viem.getToken(sellToken.address, chainId, client),
    viem.getToken(buyToken.address, chainId, client),
  ]);

  const outerLimitPrice = parsePrice(
    tokenA,
    tokenB,
    bigDecimal.divide(buyToken.amount, sellToken.amount, 18)
  );
  const inputCurrencyAmount = viem.getCurrencyAmount(
    sellToken.native ? viem.getNativeCurrency(chainId) : tokenA,
    bigDecimal.round(
      sellToken.amount,
      tokenA.decimals,
      bigDecimal.RoundingModes.DOWN
    )
  );

  const deadlineEpochSeconds = BigInt(
    Math.floor(new Date().getTime() / 1e3) + 1800
  );

  const tx = await viem.getCreatePositionTxForLimitOrder(
    address,
    outerLimitPrice, // return this for payload function
    inputCurrencyAmount, // return this for payload function
    feeTier,
    deadlineEpochSeconds,
    chainId,
    ammEnum,
    client,
    rangeWidth
  );

  return {
    tx,
    outerLimitPrice,
    inputCurrencyAmount,
  };
}

export function getOrderStatus(status: TriggerStatus, approvalStatus: boolean) {
  switch (status) {
    case TriggerStatus.CREATED:
    case TriggerStatus.STARTED:
      return approvalStatus ? OrderStatus.ACTIVE : OrderStatus.UNAUTHORIZED;
    case TriggerStatus.COMPLETED:
      return OrderStatus.FULFILLED;
    case TriggerStatus.DELETED:
      return OrderStatus.CANCELED;
    case TriggerStatus.INVALID:
      return OrderStatus.FAILED;
    default:
      OrderStatus.ACTIVE;
  }
}

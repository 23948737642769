import momentTimeZone from "moment-timezone";
import React from "react";
import DatePicker from "react-datepicker";
import { ArrowDown, Container } from "./style";
import { IDateSelectorProps } from "./types";

export const DateSelector: React.FC<IDateSelectorProps> = ({
  date,
  type = "date",
  expireDays = 0,
  onChange,
}) => {
  return type === "date" ? (
    <Container type={type}>
      <DatePicker
        dateFormat="E, MMMM d, yyyy"
        formatWeekDay={(nameOfDay: string) => nameOfDay.substr(0, 3)}
        showPopperArrow={false}
        selected={date}
        minDate={new Date()}
        maxDate={
          new Date(new Date().getTime() + expireDays * 24 * 60 * 60 * 1000)
        }
        onChange={(d: Date) => {
          onChange(momentTimeZone.tz(d, "GMT").format());
        }}
      />
      <ArrowDown />
    </Container>
  ) : (
    <Container type={type}>
      <DatePicker
        dateFormat="hh:mm aaa"
        timeFormat="hh:mm aaa"
        selected={date}
        showTimeSelect
        showTimeSelectOnly
        showPopperArrow={false}
        timeIntervals={15}
        timeCaption="Time"
        onChange={(d: Date) => {
          onChange(momentTimeZone.tz(d, "GMT").format());
        }}
      />
      <ArrowDown />
    </Container>
  );
};

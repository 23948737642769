import { Flex, FlexColumn } from "@uiv2/common";
import { T3Regular } from "@uiv2/components/Typography";
import styled, { keyframes } from "styled-components";
import { NumericalInput } from "./NumericalInput";

const pulse = (color: string) => keyframes`
  0% {
    box-shadow: 0 0 0 0 ${color};
  }
  70% {
    box-shadow: 0 0 0 2px ${color};
  }
  100% {
    box-shadow: 0 0 0 0 ${color};
  }
`;

export const IGPlusMinus = styled(FlexColumn)<{
  active: boolean;
  pulsing: boolean;
  warning?: boolean;
  error?: boolean;
}>`
  padding: 16px;
  gap: ${({ theme }) => theme.spacing.md};
  border: ${({ active, warning, theme }) =>
    warning
      ? "1px solid " + theme.colors.errorWarning.orange
      : active
      ? "1px solid " + theme.colors.global.line.Line2
      : "1px solid " + theme.colors.global.line.Line1};
  border-radius: 10px;
  animation: ${({ warning, pulsing, theme }) =>
      !warning && pulsing && pulse(theme.colors.global.primary)}
    0.8s linear;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const StyledContainer = styled(Flex)`
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
`;

export const StyledNumericalInput = styled(NumericalInput)<{ sm: boolean }>`
  height: ${({ sm }) => (sm ? "20px" : "24px")};
  padding: ${({ sm }) => (sm ? "0" : "8px 0px")};
  font-size: ${({ sm }) => (sm ? "16px" : "32px")};
  align-items: center;
  text-align: center;
  border-radius: 0;
  border: none !important;
  -webkit-appearance: textfield;
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.global.text.T3 : theme.colors.global.text.T1};

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  [type="number"] {
    -moz-appearance: textfield;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: gray;
  }
`;

export const PlusMinusMark = styled.button<{ sm: boolean; disabled: boolean }>`
  display: flex;
  width: ${({ sm }) => (sm ? "24px" : "36px")};
  height: ${({ sm }) => (sm ? "20px" : "24px")};
  border: none;
  border-radius: ${({ theme }) => theme.radii.md};
  background: ${({ theme }) => theme.colors.transparent.transparent};
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? "" : "pointer")};

  path {
    fill: ${({ disabled, theme }) =>
      disabled ? theme.colors.global.text.T3 : theme.colors.global.text.T1};
  }

  :hover {
    background: ${({ disabled, theme }) =>
      disabled
        ? theme.colors.transparent.transparent
        : theme.colors.global.background.BG2};
  }
  :active {
    background: ${({ disabled, theme }) =>
      disabled
        ? theme.colors.transparent.transparent
        : theme.colors.global.background.BG3};
  }
`;

export const Title = styled(T3Regular)`
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.global.text.T2};
`;

import { LinearLoader } from "@aperture/uikitv2";
import { Flex, FlexColumn } from "@ui/common";
import { formatFixed, formatUSDAmount } from "@ui/utils";
import React, { useEffect, useState } from "react";
import { RaindropLoader } from "../Loader/Spinner/Spinner.stories";
import { iconMap, notificationVariants } from "../Notification";
import { Popover } from "../Popover";
import { SwapRoute } from "../SwapRoute";
import { ChevronDown } from "../TopBar/AuthUser/style";
import { Subtitle3 } from "../Typography";
import {
  ApertureFee,
  ArrowBtn,
  B2,
  Card,
  CardTitle,
  Container,
  Content,
  FeeLabel,
  FeeValue,
  H5,
  HighlightText,
  IconWrapper,
  S2,
  Split,
  StyledButton,
  StyledCommonText,
  StyledNotification,
  StyledViewIcon,
  Tag,
  Text,
  Title,
  TitleRow,
} from "./style";
import { SwapPathProps } from "./types";

export const SwapPath: React.FC<SwapPathProps> = ({
  keepDropdown,
  priceImpact,
  api,
  fromToken,
  swapData,
  loading = false,
  showSwapRoutes = true,
  swapRoutes,
  maxSwapRouteWidth,
  errorMsg,
  onBtnClick = undefined,
  suggested,
  gasFeeUSD,
  feeUSD,
  feePercentage,
  atStake,
  ...props
}) => {
  const [expandable, setExpandable] = useState(true);
  const [isShow, setIsShow] = useState(!!keepDropdown);
  const [overflow, setOverflow] = useState(false);

  useEffect(() => {
    if (!!errorMsg) setIsShow(true);
  }, [errorMsg]);

  return (
    <Container {...props}>
      <Flex justifyContent="space-between" alignItems="center">
        <CardTitle>Swap Path</CardTitle>
        {!!onBtnClick && (
          <StyledButton
            color="secondary"
            size="xs"
            variant="outlined"
            disabled={loading || !!errorMsg}
            onClick={onBtnClick}
          >
            Change API
          </StyledButton>
        )}
      </Flex>
      <Title
        onClick={() => {
          if (!expandable || keepDropdown) return;
          setIsShow(!isShow);
          setExpandable(isShow);
          setTimeout(
            function () {
              setOverflow(!isShow);
              setExpandable(true);
            },
            isShow ? 0 : 300
          );
        }}
      >
        <FlexColumn style={{ width: "100%" }} gap="lg">
          <TitleRow isShow={!loading && !errorMsg && isShow}>
            <Flex gap="md" alignItems="center">
              <Text>Routing</Text>
              {!loading && !errorMsg && (
                <Tag gap="sm">
                  <IconWrapper>{api?.Icon}</IconWrapper>
                  <Subtitle3 fontWeight={400} fontSize={16} lineHeight={"20px"}>
                    {api?.name}
                  </Subtitle3>
                  {showSwapRoutes && (
                    <Popover
                      defaultPosition={{ bottom: 30, left: -122 }}
                      content={
                        <SwapRoute
                          noConnectingLine={false}
                          {...{
                            dark: true,
                            loading: false,
                            priceImpact,
                            api,
                            fromToken,
                            routes: swapRoutes,
                            maxWidth: maxSwapRouteWidth,
                            gasFeeUSD,
                          }}
                        />
                      }
                    >
                      <StyledViewIcon width={16} height={16} />
                    </Popover>
                  )}
                </Tag>
              )}
            </Flex>
            {loading ? (
              <RaindropLoader />
            ) : errorMsg ? (
              <S2>
                {atStake
                  ? "Unstake position to view swap info"
                  : "Swap info unavailable"}
              </S2>
            ) : (
              <Flex gap="sm" alignItems="center">
                {suggested && <HighlightText>Suggested</HighlightText>}
                <Text>Est. Price Impact</Text>
                <H5 priceImpact={Number(priceImpact ?? 0)}>
                  {formatFixed(priceImpact ?? "", 2)}%
                </H5>
                {!keepDropdown && (
                  <ArrowBtn>
                    <ChevronDown isShow={isShow} />
                  </ArrowBtn>
                )}
              </Flex>
            )}
          </TitleRow>
        </FlexColumn>
      </Title>
      {!loading && (swapData || typeof errorMsg === "string") && (
        <Content isShow={isShow} useOverflow={overflow} isError={!!errorMsg}>
          {errorMsg && typeof errorMsg === "string" ? (
            <StyledNotification
              variant="error"
              text={errorMsg}
              icon={iconMap[notificationVariants.WARNING]}
            />
          ) : (
            <>
              <Split isShow={isShow} />
              <Flex gap="lg">
                {swapData?.map((data, index) => (
                  <Card gap="md" key={`${data.to.ticker}-${index}`}>
                    <CardTitle>
                      Swap {fromToken?.ticker} to {data.to.ticker}
                    </CardTitle>
                    <FlexColumn gap="md">
                      <B2>Amount In</B2>
                      <StyledCommonText>
                        {data.amountIn} {fromToken?.ticker}
                      </StyledCommonText>
                    </FlexColumn>
                    <FlexColumn gap="md">
                      <B2>Expected Amount Out</B2>
                      <StyledCommonText>
                        {data.amountOut} {data.to.ticker}
                      </StyledCommonText>
                    </FlexColumn>
                    <FlexColumn gap="md">
                      <B2>Min. Amount Out After Slippage</B2>
                      <StyledCommonText>
                        {data.minAmountOut} {data.to.ticker}
                      </StyledCommonText>
                    </FlexColumn>
                  </Card>
                ))}
              </Flex>
            </>
          )}
        </Content>
      )}
      {feePercentage && (
        <ApertureFee justifyContent="space-between" alignItems="center">
          <FeeLabel>Aperture Fee</FeeLabel>
          {loading ? (
            <LinearLoader size="80px" height="16px" />
          ) : (
            <FeeValue>
              {feePercentage}% {feeUSD && `(${formatUSDAmount(feeUSD)})`}
            </FeeValue>
          )}
        </ApertureFee>
      )}
    </Container>
  );
};

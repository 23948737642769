import { CopyFileIcon } from "@aperture/assetkit";
import { ITokenTickerIconPair } from "@aperture/types";
import {
  QuestionPopover,
  formatTokenAmount,
  formatUSDAmount,
} from "@aperture/uikit";
import { FeeAmount } from "@aperture_finance/uniswap-v3-sdk";
import { useCopyText } from "@ui/utils/hooks";
import { useTheme } from "styled-components";
import { Flex, FlexColumn } from "../../common/styles/global";
import { IPriceRange } from "../../common/types/Position";
import { PositionInfoTitle } from "../PositionInfoTitle";
import { B2, Wrapper } from "../StrategyInfoCard/style";
import { SwapRate } from "./SwapRate";
import { strings } from "./strings";
import {
  Container,
  CopyWrapper,
  Label,
  PositionInfoItem,
  StyledInRangeIcon,
  Value,
} from "./style";

export interface IPastPositionCardProps {
  tokens: ITokenTickerIconPair;
  feeTier: FeeAmount;
  priceRange: IPriceRange;
  id: string;
  earned: {
    total: string;
    token0: { amount: string; usdAmount: string };
    token1: { amount: string; usdAmount: string };
  };
  liquidity: string;
  fees: string;
  duration: string;
}

export const PastPositionCard: React.FC<IPastPositionCardProps> = ({
  tokens,
  feeTier,
  priceRange,
  id,
  earned,
  liquidity,
  fees,
  duration,
}) => {
  const theme = useTheme();
  const { total, token0, token1 } = earned;
  const { hasCopied, handleCopyAddress } = useCopyText(id);

  return (
    <Container>
      <PositionInfoTitle
        titleSize="sm"
        forceLeft={true}
        tokens={tokens}
        feeTier={feeTier}
        padding={"0 8px"}
        fontSize="12px"
        lineHeight="20px"
        size={20}
      ></PositionInfoTitle>

      <FlexColumn gap="xl">
        <PositionInfoItem>
          <Label>{strings.positionRangeAtExitLabel}</Label>
          <SwapRate
            tokens={tokens}
            priceRange={priceRange}
            justifyContent="space-between"
          ></SwapRate>
        </PositionInfoItem>
      </FlexColumn>

      <PositionInfoItem>
        <Flex alignItems="center" justifyContent={"space-between"}>
          <Label>{strings.positionIdLabel}</Label>
          <Flex gap="md" alignItems={"center"}>
            <Value>{id}</Value>
            <CopyWrapper onClick={handleCopyAddress}>
              {hasCopied ? (
                <StyledInRangeIcon width={16} height={16} />
              ) : (
                <CopyFileIcon
                  fill={theme.colors.global.text.T1}
                  width={16}
                  height={16}
                />
              )}
            </CopyWrapper>
          </Flex>
        </Flex>
      </PositionInfoItem>

      <PositionInfoItem>
        <Flex alignItems="center" justifyContent={"space-between"}>
          <Label>{strings.positionLiquidityAtExitLabel}</Label>
          <Value>{formatUSDAmount(liquidity)}</Value>
        </Flex>
      </PositionInfoItem>

      <PositionInfoItem>
        <Flex alignItems="center" justifyContent={"space-between"}>
          <Label>{strings.totalDurationLabel}</Label>
          <Value>{duration}</Value>
        </Flex>
      </PositionInfoItem>
      <PositionInfoItem gap="md">
        <Flex justifyContent={"space-between"}>
          <Label>
            {strings.lifetimeFeeRevenueEarnedLabel}
            <QuestionPopover
              content={strings.lifetimeFeeRevenueTip}
              defaultPosition={{ left: -144, bottom: 66 }}
              style={{ marginTop: "2px" }}
            />
          </Label>
          <Wrapper>
            <Value>{formatUSDAmount(total)}</Value>
            <B2>{`${formatTokenAmount(token0.amount)} ${
              tokens[0].ticker
            } (~${formatUSDAmount(token0.usdAmount)})`}</B2>
            <B2>{`${formatTokenAmount(token1.amount)} ${
              tokens[1].ticker
            } (~${formatUSDAmount(token1.usdAmount)})`}</B2>
          </Wrapper>
        </Flex>
      </PositionInfoItem>

      <PositionInfoItem>
        <Flex alignItems="center" justifyContent={"space-between"}>
          <Label>
            {strings.lifetimeGasFeeSpentLabel}
            <QuestionPopover
              content={strings.lifetimeGasFeeSpentTip}
              style={{ marginTop: "2px" }}
            />
          </Label>
          <Value>{formatUSDAmount(fees)}</Value>
        </Flex>
      </PositionInfoItem>
    </Container>
  );
};

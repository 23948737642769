export const notificationVariants = {
  DEFAULT: "default",
  WARNING: "warning",
  ERROR: "error",
  SUCCESS: "success",
} as const;

export type NotificationVariant =
  (typeof notificationVariants)[keyof typeof notificationVariants];

export type INotificationProps = {
  variant: NotificationVariant;
  text: string;
  showClose?: boolean;
  button?: JSX.Element;
  onClose?: () => void;
  showIcon?: boolean;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
};

export type INotificationV2Props = INotificationProps & {
  title?: string;
  renderButton?: () => JSX.Element;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
};

export type IType = {
  variant: NotificationVariant;
};

import { ExchangeIcon } from "@aperture/assetkit";
import { ITokenTickerIconPair } from "@aperture/types";
import { Flex, IPriceRange } from "@ui/common";
import { useState } from "react";
import { T1Regular, T3Regular } from "../Typography";
import { Container, MinMaxWrapper, Row, Wrapper } from "./style";

export const SwapRate: React.FC<{
  tokens: ITokenTickerIconPair;
  priceRange: IPriceRange;
}> = ({ tokens, priceRange }) => {
  const [swap, setSwap] = useState(false);
  return (
    <Container>
      <Wrapper>
        <T3Regular>Price range</T3Regular>
        <Flex
          alignItems={"center"}
          onClick={(event) => {
            event.stopPropagation();
            setSwap(!swap);
          }}
        >
          <ExchangeIcon width={16} height={16} />
        </Flex>
      </Wrapper>
      <Row>
        <T1Regular>Min</T1Regular>
        <MinMaxWrapper>
          <div>{swap ? priceRange.swapMin : priceRange.min} </div>
          <div>
            {tokens[swap ? 0 : 1].ticker} per {tokens[swap ? 1 : 0].ticker}
          </div>
        </MinMaxWrapper>
      </Row>
      <Row>
        <T1Regular>Max</T1Regular>
        <MinMaxWrapper>
          <div>{swap ? priceRange.swapMax : priceRange.max} </div>
          <div>
            {tokens[swap ? 0 : 1].ticker} per {tokens[swap ? 1 : 0].ticker}
          </div>
        </MinMaxWrapper>
      </Row>
    </Container>
  );
};

import { useClickOutside } from "@uiv2/common/hooks";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { DefaultTheme, ThemeProvider } from "styled-components";
import { Container, DropdownContainer } from "./style";
import { IDropdownProps, IDropdownRef } from "./types";

const getElementOffsets = (
  el: HTMLElement,
  buttonHeight: number,
  buttonWidth: number
) => {
  const { top, left } = el.getBoundingClientRect();
  return {
    top,
    left: left + buttonWidth,
    bottom: window.innerHeight - top - buttonHeight,
    right: window.innerWidth - left,
  };
};

const calcPos = (buttonRef: HTMLElement, dropdownRef: HTMLElement) => {
  const buttonHeight = buttonRef.clientHeight || 0;
  const buttonWidth = buttonRef.clientWidth || 0;
  const { top, left, bottom, right } = getElementOffsets(
    buttonRef,
    buttonHeight,
    buttonWidth
  );
  const dropdownHeight = dropdownRef.clientHeight || 0;
  const dropdownWidth = dropdownRef.clientWidth || 0;
  if (left < dropdownWidth && right >= dropdownWidth) {
    if (bottom < dropdownHeight && top >= dropdownHeight) {
      return "topRight";
    } else if (bottom >= dropdownHeight) {
      return "bottomRight";
    }
  } else if (left >= dropdownWidth) {
    if (bottom < dropdownHeight && top >= dropdownHeight) {
      return "topLeft";
    }
  }
  return "bottomLeft";
};

export const Dropdown = forwardRef<IDropdownRef, IDropdownProps>(
  (
    {
      button,
      isOpen = false,
      dropdownAlwaysOpen = false,
      position,
      onDisplayChanged,
      disabled = false,
      insideRef,
      gap = 8,
      children,
      containerStyles,
      ...props
    },
    ref
  ) => {
    const [display, setDisplay] = useState(isOpen ? "show" : "hide");
    const [dropdownPos, setDropdownPos] = useState(position || "bottomLeft");
    const [buttonHeight, setButtonHeight] = useState(0);
    const buttonRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleClose = () => {
      !dropdownAlwaysOpen && setDisplay("hide");
    };

    useEffect(() => {
      setDisplay(isOpen ? "show" : "hide");
    }, [isOpen]);

    useEffect(() => {
      onDisplayChanged && onDisplayChanged(display === "show");
    }, [display]);

    useEffect(() => {
      if (!buttonRef?.current || !dropdownRef?.current) return;
      const buttonCurrent = buttonRef.current;
      const dropdownCurrent = dropdownRef.current;
      setButtonHeight(buttonCurrent.clientHeight || 0);
      if (position) return;
      const pos = calcPos(buttonCurrent, dropdownRef.current);
      setDropdownPos(pos);
      window.addEventListener("resize", () => {
        const pos = calcPos(buttonCurrent, dropdownCurrent);
        setDropdownPos(pos);
      });
      return () => {
        window.removeEventListener("resize", () => {
          const pos = calcPos(buttonCurrent, dropdownCurrent);
          setDropdownPos(pos);
        });
      };
    }, [buttonRef, dropdownRef, position]);

    useClickOutside([buttonRef, dropdownRef], handleClose);

    useImperativeHandle(ref, () => ({
      onClose: handleClose,
    }));

    return (
      <Container {...props}>
        <ThemeProvider
          theme={{ isDropdownOpen: display === "show" } as DefaultTheme}
        >
          <div
            ref={buttonRef}
            onClick={(event) => {
              if (disabled) return;
              const isShow =
                insideRef &&
                insideRef.current &&
                insideRef.current.contains(event.target as Node);
              setDisplay(
                isShow ? "show" : display === "show" ? "hide" : "show"
              );
            }}
          >
            {button}
          </div>
        </ThemeProvider>
        <DropdownContainer
          ref={dropdownRef}
          buttonHeight={buttonHeight}
          display={display}
          position={dropdownPos}
          gap={gap}
          style={{ ...containerStyles }}
        >
          {children}
        </DropdownContainer>
      </Container>
    );
  }
);

import { useEffect, useRef } from "react";
import { useTheme } from "styled-components";
import {
  D3PositionBreakdownBin,
  PositionBreakdown,
  PositionBreakdownChartProps,
} from "./../types";
import D3PositionBreakdownByToken from "./D3PositionBreakdownByToken";

let d3Chart: D3PositionBreakdownByToken | null = null;
export const PositionBreakdownByToken: React.FC<
  PositionBreakdownChartProps
> = ({
  isFullRange = false,
  token0,
  token1,
  token0PriceChart,
  token1PriceChart,
  priceRangeValue,
  priceAssumptionValue,
  positionBreakDown,
}) => {
  const theme = useTheme();
  const refElement = useRef<HTMLDivElement>(null);
  const processData = (ticks: PositionBreakdown[]): D3PositionBreakdownBin[] =>
    ticks.map((tick) => ({
      x: tick.price,
      y0: tick.token0Amount,
      y1: tick.token1Amount,
    }));

  useEffect(() => {
    if (!positionBreakDown || !token0 || !token1) return;
    if (d3Chart) d3Chart.destroy();

    const token0Symbol = token0?.ticker;
    const token1Symbol = token1?.ticker;
    const token0Decimal = token0?.decimals;
    const token1Decimal = token1?.decimals;

    d3Chart = new D3PositionBreakdownByToken(refElement.current, {
      width: 368,
      height: 180,
      minTick: 0,
      maxTick: priceAssumptionValue * 2,
      currentTick: priceAssumptionValue,
      token0Symbol,
      token1Symbol,
      token0Decimal,
      token1Decimal,
      data: processData(positionBreakDown),
      colors: {
        graph: theme.colors.global.primaryDisable,
        current: theme.colors.global.secondary,
        minMax: theme.colors.global.primary,
        axis: theme.colors.global.text.T2,
      },
    });
    // eslint-disable-next-line
  }, [
    refElement,
    token0,
    token1,
    token0PriceChart,
    token1PriceChart,
    positionBreakDown,
  ]);

  useEffect(() => {
    if (!d3Chart) return;
    if (!token0 || !token1) return;

    d3Chart.updateCurrentTick(priceAssumptionValue);
    // eslint-disable-next-line
  }, [priceAssumptionValue, token0, token1]);

  useEffect(() => {
    if (!d3Chart) return;
    if (!token0 || !token1) return;

    d3Chart.updateMinMaxTickRange(
      priceRangeValue[0],
      priceRangeValue[1],
      isFullRange
    );
    // eslint-disable-next-line
  }, [priceRangeValue, token0, token1, isFullRange]);

  return <div ref={refElement} />;
};

import { Flex } from "@uiv2/common";
import { T3Regular } from "@uiv2/components/Typography";
import styled from "styled-components";

const Container = styled(Flex)`
  width: 238px;
  padding: ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.radii.round};
  background-color: ${({ theme }) => theme.colors.global.background.BG2};
`;
const Value = styled(T3Regular)`
  width: 106px;
  text-align: center;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const Percentage = styled(Value)`
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.sm};
  border-radius: ${({ theme }) => theme.radii.round};
  background-color: ${({ theme }) => theme.colors.global.background.BG3};
`;

export type IValuePecentProps = {
  value: string;
  percentage: string;
};

export const ValuePercentBox: React.FC<IValuePecentProps> = ({
  value,
  percentage,
}) => {
  return (
    <Container gap={10} alignItems="center" justifyContent="center">
      <Value>${value}</Value>
      <Percentage>{percentage}%</Percentage>
    </Container>
  );
};

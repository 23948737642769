import Providers from "@/Providers";
import Layout from "@/components/layout";
import { config } from "@/config";
import { CloudFrontURL } from "@/config/url";
import { DatadogLogger } from "@/helper/datadogLogger";
import { isProdEnv } from "@/utils/helper";
import {
  ConsoleLogger,
  IOCKEY_LOGGER,
  ioc,
} from "@aperture_finance/uniswap-v3-automation-sdk";
import { datadogRum } from "@datadog/browser-rum";
import { MobileAlertContent } from "@ui/components";
import flagsmith from "flagsmith/isomorphic";
import { FlagsmithProvider } from "flagsmith/react";
import { IState } from "flagsmith/types";
import { NextPage } from "next";
import type { AppProps } from "next/app";
import Head from "next/head";
import Script from "next/script";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

const environmentID = config.flagsmithEnvID;

function MyApp(
  props: AppProps & {
    flagsmithState: IState;
  }
) {
  // Use useContext to defined & initialize Amplitude client with API key here.

  const [width, setWidth] = useState(540);
  useEffect(() => {
    registerServices();
    const updateWindowDimensions = () => {
      const windowWidth = window.innerWidth;
      setWidth(windowWidth);
    };
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  return (
    <>
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-CNRSMXD50K"
      ></Script>
      <Script id="google-analytics">{`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-CNRSMXD50K');
      `}</Script>
      <Head>
        <title>Aperture Finance V3</title>
        <meta
          name="description"
          content="Building AI Powered Intents, the future of DeFi User Experience. Pioneering Liquidity Intents for LPs on @uniswap V3 & more."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="manifest" href="/manifest.json" />
        <link
          rel="preload"
          as="image"
          href={`${CloudFrontURL}/images/nav-selector.png`}
        />
      </Head>
      <main>
        <FlagsmithProvider
          options={{
            environmentID,
            api: config.flagsmithAPI,
          }}
          serverState={props.flagsmithState}
          flagsmith={flagsmith}
        >
          <Providers>
            {width < 540 ? <MobileAlertContent /> : <App {...props} />}
          </Providers>
        </FlagsmithProvider>
      </main>
    </>
  );
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC<React.PropsWithChildren<unknown>>;
  /** render component without all layouts */
  pure?: true;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  return (
    <>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </>
  );
};

MyApp.getInitialProps = async () => {
  try {
    await flagsmith.init({
      // fetches flags on the server and passes them to the App
      environmentID,
      api: config.flagsmithAPI,
    });
    return { flagsmithState: flagsmith.getState() };
  } catch (error) {
    return { flagsmithState: {} };
  }
};

export default MyApp;

const registerServices = () => {
  if (isProdEnv()) {
    ioc.registerSingleton(
      IOCKEY_LOGGER,
      DatadogLogger,
      process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
      config.version
    );

    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID,
      clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN,
      site: "us3.datadoghq.com",
      service: "web-app",
      env: "production",
      version: config.version,
      sessionSampleRate: 10, // track 10% of sessions
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    });
  } else {
    ioc.registerSingleton(IOCKEY_LOGGER, ConsoleLogger);
  }
};

import { Flex, FlexColumn } from "@ui/common";
import styled from "styled-components";

export const Title = styled.div`
  font-family: Roboto Flex;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grey.white};
`;

export const Text = styled.div`
  font-family: Roboto Flex;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.global.primary};
`;

export const SuccessText = styled(Text)<{ maxW: number }>`
  max-width: ${({ maxW }) => `${maxW}px`};
  text-align: center;
`;

export const Box = styled.div<{ h: number; w: number }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ w }) => `${w}px`};
  height: ${({ h }) => `${h}px`};
`;

export const Container = styled(Flex)<{ size: number }>`
  position: relative;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  align-items: center;
  justify-content: center;
`;

export const Circle = styled.div<{
  size: number;
  d: number;
  progress: number;
}>`
  position: absolute;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: ${({ theme }) => theme.radii.circle};
  background: ${({ theme, progress }) => `conic-gradient(
    ${theme.colors.global.primary} 0deg,
    ${theme.colors.global.primary} ${progress}deg,
    ${theme.colors.global.background.BG2} ${progress}deg,
    ${theme.colors.global.background.BG2} 360deg
  )`};
  mask-image: ${({ size, d }) => `radial-gradient(
    circle,
    transparent 0,
    transparent ${(size - d * 2) / 2 - 0.5}px,
    black ${(size - d * 2) / 2}px,
    black ${size / 2}px,
    transparent ${size / 2 + 0.5}px
  )`};
`;

export const Spot = styled.div<{
  d: number;
  D: number;
  angle: number;
  isVisible: boolean;
}>`
  z-index: 3;
  position: absolute;
  width: ${({ d }) => `${d}px`};
  height: ${({ d }) => `${d}px`};
  border-radius: ${({ theme }) => theme.radii.circle};
  left: ${({ d, D, angle }) =>
    `calc(50% + ${getX(D / 2, angle)}px - ${d / 2}px)`};
  top: ${({ d, D, angle }) =>
    `calc(50% + ${getY(D / 2, angle)}px - ${d / 2}px)`};
  background-color: ${({ isVisible, theme }) =>
    isVisible
      ? theme.colors.global.background.BG3
      : theme.colors.transparent.transparent};
`;

export const Label = styled(FlexColumn)<{
  D: number;
  angle: number;
  w: number;
  h: number;
}>`
  position: absolute;
  z-index: 3;
  width: ${({ w }) => `${w}px`};
  min-width: max-content;
  left: ${({ w, D, angle }) =>
    `calc(50% + ${getX(D * 0.5, angle)}px - ${w / 2}px)`};
  top: ${({ h, D, angle }) =>
    `calc(50% + ${getY(D * 0.45, angle)}px - ${h / 2}px)`};
  text-align: ${({ angle }) =>
    angle === 0 || angle === 180 ? "center" : angle > 180 ? "right" : "left"};
`;

export const Marker = styled.div<{
  d: number;
  D: number;
  angle: number;
}>`
  z-index: 4;
  position: absolute;
  border-radius: ${({ theme }) => theme.radii.circle};
  background-color: ${({ theme }) => theme.colors.global.primary};
  width: ${({ d }) => `${d}px`};
  height: ${({ d }) => `${d}px`};
  left: ${({ d, D, angle }) =>
    `calc(50% + ${getX(D / 2, angle)}px - ${d / 2}px)`};
  top: ${({ d, D, angle }) =>
    `calc(50% + ${getY(D / 2, angle)}px - ${d / 2}px)`};
`;

const getRadian = (deg: number) => ((90 - deg) * Math.PI) / 180;
const getX = (radius: number, angle: number) =>
  radius * Math.cos(getRadian(angle));
const getY = (radius: number, angle: number) =>
  -radius * Math.sin(getRadian(angle));

import { IToken } from "@aperture/types";
import { TriggerItem } from "@aperture_finance/uniswap-v3-automation-sdk";
import bigDecimal from "js-big-decimal";
import React, { useState } from "react";
import { formatPrice, formatTokenAmount } from "../../utils/numberFormat";
import { SwapButtonIcon, SwapButtonWrapper } from "./style";
import { Projection } from "./types";

export const getRebalanceInfoItems = (
  trigger: TriggerItem,
  tokens: IToken[],
  getPrice: (ticker: number) => string,
  getPercentage: (ticker: number) => string,
  projectLP: (trigger: TriggerItem) => Projection
) => {
  if (trigger.action.type !== "Rebalance") {
    return [];
  }

  const priceLower = trigger.action.isCurrentTickOffset
    ? getPercentage(trigger.action.tickLower)
    : getPrice(trigger.action.tickLower);
  const priceUpper = trigger.action.isCurrentTickOffset
    ? getPercentage(trigger.action.tickUpper)
    : getPrice(trigger.action.tickUpper);
  const projectile = projectLP(trigger);

  if (trigger.action.isCurrentTickOffset) {
    return [
      {
        label: "New Price Range (from future spot price)",
        value: (
          <span>
            {bigDecimal.compareTo(priceUpper, 0) > 0 && "+"}
            {priceUpper}% / {priceLower}%
          </span>
        ),
      },
    ];
  }
  return [
    {
      label: "New Price Range",
      value: (
        <SwapComponent
          trigger={trigger}
          tokens={tokens}
          priceLower={priceLower}
          priceUpper={priceUpper}
          getPrice={getPrice}
          getPercentage={getPercentage}
          projectLP={projectLP}
        />
      ),
    },
    {
      label: "Projected Liquidity Position",
      value: (
        <>
          {tokens?.map((token, index) => (
            <span key={`${index}-${token.ticker}`}>{`${
              token.ticker
            } ${formatTokenAmount(projectile[index].amount || 0)} (${
              projectile[index].percent
            }%)`}</span>
          ))}
        </>
      ),
    },
  ];
};

interface SwapComponentProps {
  trigger: TriggerItem;
  tokens: IToken[];
  priceLower: string;
  priceUpper: string;
  getPrice: (ticker: number) => string;
  getPercentage: (ticker: number) => string;
  projectLP: (trigger: TriggerItem) => Projection;
}

const SwapComponent: React.FC<SwapComponentProps> = ({
  trigger,
  tokens,
  priceLower,
  priceUpper,
}) => {
  const [swap, setSwap] = useState<any>({});

  const swapTokenA = swap[trigger.taskId] ? tokens[0] : tokens[1];
  const swapTokenB = swap[trigger.taskId] ? tokens[1] : tokens[0];

  const price1 = swap[trigger.taskId]
    ? bigDecimal.compareTo(priceUpper, 0) > 0
      ? formatPrice(bigDecimal.divide(1, priceUpper, 18))
      : "∞"
    : bigDecimal.compareTo(priceLower, 0) > 0 &&
      bigDecimal.compareTo(bigDecimal.divide(1, priceLower, 18), 0) === 0
    ? "∞"
    : formatPrice(priceLower);
  const price2 = swap[trigger.taskId]
    ? bigDecimal.compareTo(priceLower, 0) > 0
      ? formatPrice(bigDecimal.divide(1, priceLower, 18))
      : "∞"
    : bigDecimal.compareTo(priceUpper, 0) > 0 &&
      bigDecimal.compareTo(bigDecimal.divide(1, priceUpper, 18), 0) === 0
    ? "∞"
    : formatPrice(priceUpper);

  return (
    <>
      <span>{`${price1} ${swapTokenA.ticker} per ${swapTokenB.ticker}`}</span>
      <SwapButtonWrapper
        onClick={(event) => {
          event.stopPropagation();
          setSwap({
            ...swap,
            [trigger.taskId]: !swap[trigger.taskId],
          });
        }}
      >
        <SwapButtonIcon />
      </SwapButtonWrapper>
      <span>{`${price2} ${swapTokenA.ticker} per ${swapTokenB.ticker}`}</span>
    </>
  );
};

export const data = [
  {
    activeLiquidity: 3387466200134645,
    price0: 5718528.36760096,
  },
  {
    activeLiquidity: 3387466200134645,
    price0: 1981322.37979625,
  },
  {
    activeLiquidity: 423291284543749060,
    price0: 498492.60996477,
  },
  {
    activeLiquidity: 423291284543749060,
    price0: 308466.35059438,
  },
  {
    activeLiquidity: 423291284543749060,
    price0: 296371.80468844,
  },
  {
    activeLiquidity: 1.1729045401790499e21,
    price0: 15986.75797942,
  },
  {
    activeLiquidity: 1.1729045401790499e21,
    price0: 10092.4109644,
  },
  {
    activeLiquidity: 1.1739293522233444e21,
    price0: 9316.50679916,
  },
  {
    activeLiquidity: 1.1739293522233444e21,
    price0: 8773.98200049,
  },
  {
    activeLiquidity: 1.225248834480074e21,
    price0: 8429.96610418,
  },
  {
    activeLiquidity: 6.695727947954369e21,
    price0: 7041.36293778,
  },
  {
    activeLiquidity: 6.701523380545889e21,
    price0: 6901.94151136,
  },
  {
    activeLiquidity: 6.712949741411074e21,
    price0: 6765.28067751,
  },
  {
    activeLiquidity: 6.712961543466028e21,
    price0: 6631.32577552,
  },
  {
    activeLiquidity: 6.713126755586741e21,
    price0: 6500.02322702,
  },
  {
    activeLiquidity: 6.713126755586741e21,
    price0: 6371.32051448,
  },
  {
    activeLiquidity: 6.741442411002412e21,
    price0: 6245.16616025,
  },
  {
    activeLiquidity: 6.742592056503028e21,
    price0: 6121.50970596,
  },
  {
    activeLiquidity: 6.742747929924031e21,
    price0: 6000.30169231,
  },
  {
    activeLiquidity: 6.743976497159239e21,
    price0: 5881.49363934,
  },
  {
    activeLiquidity: 6.744609912031435e21,
    price0: 5765.038027,
  },
  {
    activeLiquidity: 6.744857231478708e21,
    price0: 5650.88827613,
  },
  {
    activeLiquidity: 6.745115058817719e21,
    price0: 5538.9987299,
  },
  {
    activeLiquidity: 6.745149660905709e21,
    price0: 5429.32463545,
  },
  {
    activeLiquidity: 6.745531348369571e21,
    price0: 5321.82212609,
  },
  {
    activeLiquidity: 6.745531348369571e21,
    price0: 5216.44820366,
  },
  {
    activeLiquidity: 6.745808269966798e21,
    price0: 5113.16072142,
  },
  {
    activeLiquidity: 6.746081328982779e21,
    price0: 5011.91836712,
  },
  {
    activeLiquidity: 6.746286478931596e21,
    price0: 4912.68064653,
  },
  {
    activeLiquidity: 6.746452722788167e21,
    price0: 4815.40786719,
  },
  {
    activeLiquidity: 1.7767566512192125e22,
    price0: 4720.06112259,
  },
  {
    activeLiquidity: 2.64426514776639e22,
    price0: 4626.60227659,
  },
  {
    activeLiquidity: 2.644615439505947e22,
    price0: 4534.99394812,
  },
  {
    activeLiquidity: 2.6514885718633035e22,
    price0: 4445.1994963,
  },
  {
    activeLiquidity: 2.654973832676913e22,
    price0: 4357.18300575,
  },
  {
    activeLiquidity: 2.6565819165823855e22,
    price0: 4270.90927221,
  },
  {
    activeLiquidity: 1.8544946148936543e23,
    price0: 4186.3437885,
  },
  {
    activeLiquidity: 2.0401796570566438e23,
    price0: 4103.45273067,
  },
  {
    activeLiquidity: 2.0449083631555328e23,
    price0: 4022.20294451,
  },
  {
    activeLiquidity: 2.0449083631555328e23,
    price0: 3942.56193228,
  },
  {
    activeLiquidity: 2.0822593847094866e23,
    price0: 3864.49783968,
  },
  {
    activeLiquidity: 2.0822593847094866e23,
    price0: 3787.97944318,
  },
  {
    activeLiquidity: 2.0131055007671068e23,
    price0: 3712.97613745,
  },
  {
    activeLiquidity: 1.933620997934394e23,
    price0: 3639.45792316,
  },
  {
    activeLiquidity: 1.8914738081329277e23,
    price0: 3567.39539499,
  },
  {
    activeLiquidity: 1.9188617456630794e23,
    price0: 3496.75972986,
  },
  {
    activeLiquidity: 1.91928344674259e23,
    price0: 3427.52267537,
  },
  {
    activeLiquidity: 1.969543995373963e23,
    price0: 3359.65653856,
  },
  {
    activeLiquidity: 9.012218163722552e22,
    price0: 3293.13417477,
  },
  {
    activeLiquidity: 8.444637264598066e22,
    price0: 3227.92897685,
  },
  {
    activeLiquidity: 4.047606080404412e22,
    price0: 3164.01486443,
  },
  {
    activeLiquidity: 4.048924894875095e22,
    price0: 3101.3662736,
  },
  {
    activeLiquidity: 3.993670990578509e22,
    price0: 3039.95814657,
  },
  {
    activeLiquidity: 3.8539015299423806e22,
    price0: 2979.76592175,
  },
  {
    activeLiquidity: 3.854225991592934e22,
    price0: 2920.76552383,
  },
  {
    activeLiquidity: 3.819649592499707e22,
    price0: 2862.93335424,
  },
  {
    activeLiquidity: 3.815802820028794e22,
    price0: 2806.24628165,
  },
  {
    activeLiquidity: 2.8447487902913644e22,
    price0: 2750.68163274,
  },
  {
    activeLiquidity: 2.8447487902913644e22,
    price0: 2696.21718314,
  },
  {
    activeLiquidity: 2.8447487902913644e22,
    price0: 2642.83114851,
  },
  {
    activeLiquidity: 2.8447487902913644e22,
    price0: 2590.50217587,
  },
  {
    activeLiquidity: 3.770967640578692e22,
    price0: 2539.20933503,
  },
  {
    activeLiquidity: 3.7716012629178066e22,
    price0: 2488.93211021,
  },
  {
    activeLiquidity: 3.771588873766533e22,
    price0: 2439.65039186,
  },
  {
    activeLiquidity: 3.7714579131095837e22,
    price0: 2391.34446862,
  },
  {
    activeLiquidity: 3.7714579131095837e22,
    price0: 2343.9950194,
  },
  {
    activeLiquidity: 3.7714579131095837e22,
    price0: 2297.58310568,
  },
  {
    activeLiquidity: 3.772286623403364e22,
    price0: 2252.09016394,
  },
  {
    activeLiquidity: 2.7097394864714544e22,
    price0: 2207.49799821,
  },
  {
    activeLiquidity: 2.7097394693323614e22,
    price0: 2163.78877282,
  },
  {
    activeLiquidity: 2.7046072963959014e22,
    price0: 2120.94500523,
  },
  {
    activeLiquidity: 2.7046072963959014e22,
    price0: 2078.9495591,
  },
  {
    activeLiquidity: 2.6991716576237365e22,
    price0: 2037.78563735,
  },
  {
    activeLiquidity: 2.701404521371916e22,
    price0: 1997.43677551,
  },
  {
    activeLiquidity: 2.701404521371916e22,
    price0: 1957.88683513,
  },
  {
    activeLiquidity: 2.344122594505634e22,
    price0: 1919.11999727,
  },
  {
    activeLiquidity: 2.344122594505634e22,
    price0: 1881.12075623,
  },
  {
    activeLiquidity: 2.344122594505634e22,
    price0: 1843.87391334,
  },
  {
    activeLiquidity: 2.344122594505634e22,
    price0: 1807.36457084,
  },
  {
    activeLiquidity: 1.7970803795915514e22,
    price0: 1771.57812598,
  },
  {
    activeLiquidity: 8.634770202776297e21,
    price0: 1736.50026513,
  },
  {
    activeLiquidity: 8.623304762570807e21,
    price0: 1702.11695808,
  },
  {
    activeLiquidity: 8.133743945896893e21,
    price0: 1668.41445243,
  },
  {
    activeLiquidity: 8.133410144877449e21,
    price0: 1635.37926806,
  },
  {
    activeLiquidity: 7.758703385416507e21,
    price0: 1602.9981918,
  },
  {
    activeLiquidity: 7.758569554291504e21,
    price0: 1571.25827207,
  },
  {
    activeLiquidity: 7.729428783147262e21,
    price0: 1540.14681375,
  },
  {
    activeLiquidity: 7.729081838029977e21,
    price0: 1509.65137308,
  },
  {
    activeLiquidity: 7.71332163193849e21,
    price0: 1479.75975271,
  },
  {
    activeLiquidity: 7.712123314000403e21,
    price0: 1450.45999678,
  },
  {
    activeLiquidity: 7.71124631173621e21,
    price0: 1421.74038618,
  },
  {
    activeLiquidity: 7.73408420270442e21,
    price0: 1393.58943381,
  },
  {
    activeLiquidity: 7.734051514297034e21,
    price0: 1365.99588006,
  },
  {
    activeLiquidity: 7.733669826833174e21,
    price0: 1338.94868824,
  },
  {
    activeLiquidity: 6.530507017149755e21,
    price0: 1312.43704018,
  },
  {
    activeLiquidity: 7.462521481047438e21,
    price0: 1286.45033195,
  },
  {
    activeLiquidity: 7.462383610525321e21,
    price0: 1260.97816955,
  },
  {
    activeLiquidity: 7.462043443473568e21,
    price0: 1236.01036479,
  },
  {
    activeLiquidity: 7.462043443473568e21,
    price0: 1211.53693121,
  },
  {
    activeLiquidity: 7.461877199616997e21,
    price0: 1187.5480801,
  },
  {
    activeLiquidity: 7.458177883977393e21,
    price0: 1164.03421656,
  },
  {
    activeLiquidity: 7.456616568403855e21,
    price0: 1140.98593567,
  },
  {
    activeLiquidity: 7.454645799198025e21,
    price0: 1118.39401872,
  },
  {
    activeLiquidity: 7.453576721517632e21,
    price0: 1096.24942956,
  },
  {
    activeLiquidity: 7.438998232038319e21,
    price0: 1074.54331094,
  },
  {
    activeLiquidity: 7.438998232038319e21,
    price0: 1053.26698099,
  },
  {
    activeLiquidity: 7.438998232038319e21,
    price0: 1032.41192975,
  },
  {
    activeLiquidity: 7.438922291093815e21,
    price0: 1011.96981575,
  },
  {
    activeLiquidity: 7.438922291093815e21,
    price0: 991.93246269,
  },
  {
    activeLiquidity: 6.506633152706777e21,
    price0: 972.29185616,
  },
  {
    activeLiquidity: 6.506633152706777e21,
    price0: 953.04014045,
  },
  {
    activeLiquidity: 6.527768953046312e21,
    price0: 934.16961539,
  },
  {
    activeLiquidity: 6.527768953046312e21,
    price0: 915.67273326,
  },
  {
    activeLiquidity: 6.527271040704531e21,
    price0: 897.5420958,
  },
  {
    activeLiquidity: 6.527271039900647e21,
    price0: 879.77045126,
  },
  {
    activeLiquidity: 6.527271039900647e21,
    price0: 862.35069143,
  },
  {
    activeLiquidity: 6.527271039900647e21,
    price0: 845.27584888,
  },
  {
    activeLiquidity: 6.527755443475273e21,
    price0: 812.13373293,
  },
  {
    activeLiquidity: 6.374852370917367e21,
    price0: 796.05320357,
  },
  {
    activeLiquidity: 6.374367967342741e21,
    price0: 780.29107426,
  },
  {
    activeLiquidity: 6.361375688819992e21,
    price0: 764.84104057,
  },
  {
    activeLiquidity: 6.361375688819992e21,
    price0: 749.6969229,
  },
  {
    activeLiquidity: 6.388066471487985e21,
    price0: 734.85266402,
  },
  {
    activeLiquidity: 6.388066471487985e21,
    price0: 720.3023266,
  },
  {
    activeLiquidity: 6.388066471487985e21,
    price0: 706.0400909,
  },
  {
    activeLiquidity: 6.388066471487985e21,
    price0: 692.06025242,
  },
  {
    activeLiquidity: 6.361375688819992e21,
    price0: 678.35721958,
  },
  {
    activeLiquidity: 6.361375688819992e21,
    price0: 664.92551154,
  },
  {
    activeLiquidity: 5.188894439925487e21,
    price0: 651.75975598,
  },
  {
    activeLiquidity: 5.167758639585951e21,
    price0: 638.85468694,
  },
  {
    activeLiquidity: 5.167758639585951e21,
    price0: 613.80606389,
  },
  {
    activeLiquidity: 5.167758639585951e21,
    price0: 601.65249111,
  },
  {
    activeLiquidity: 5.14466292127873e21,
    price0: 589.73956327,
  },
  {
    activeLiquidity: 5.136376191390172e21,
    price0: 566.6166774,
  },
  {
    activeLiquidity: 5.136376191390172e21,
    price0: 555.39747082,
  },
  {
    activeLiquidity: 5.136376191390172e21,
    price0: 544.40040842,
  },
  {
    activeLiquidity: 5.136376191390172e21,
    price0: 523.05520912,
  },
  {
    activeLiquidity: 110321328252861280000,
    price0: 502.54692604,
  },
  {
    activeLiquidity: 110321328252861280000,
    price0: 482.84274484,
  },
  {
    activeLiquidity: 110321328252861280000,
    price0: 473.28229116,
  },
  {
    activeLiquidity: 108631103940022860000,
    price0: 454.72553647,
  },
  {
    activeLiquidity: 23304643779554170000,
    price0: 445.72181326,
  },
  {
    activeLiquidity: 1473918639542685700,
    price0: 403.30779107,
  },
  {
    activeLiquidity: 1473918639542685700,
    price0: 395.32215704,
  },
  {
    activeLiquidity: 1473918639542685700,
    price0: 330.20379331,
  },
  {
    activeLiquidity: 1473918639542685700,
    price0: 270.35070368,
  },
  {
    activeLiquidity: 1473918639542685700,
    price0: 264.99766605,
  },
  {
    activeLiquidity: 1473918639542685700,
    price0: 221.34664853,
  },
  {
    activeLiquidity: 1473918639542685700,
    price0: 216.96390817,
  },
  {
    activeLiquidity: 1473918639542685700,
    price0: 200.28373129,
  },
  {
    activeLiquidity: 1473918639542685700,
    price0: 196.318044,
  },
  {
    activeLiquidity: 1473918639542685700,
    price0: 170.67190293,
  },
  {
    activeLiquidity: 1473918639542685700,
    price0: 151.37330686,
  },
  {
    activeLiquidity: 1473918639542685700,
    price0: 123.93522078,
  },
  {
    activeLiquidity: 1473918639542685700,
    price0: 99.46143841,
  },
  {
    activeLiquidity: 1473918639542685700,
    price0: 75.17239038,
  },
  {
    activeLiquidity: 1473918639542685700,
    price0: 64.058198,
  },
  {
    activeLiquidity: 1473918639542685700,
    price0: 62.78982348,
  },
  {
    activeLiquidity: 1712557794919238100,
    price0: 2.01368223,
  },
  {
    activeLiquidity: 1473918639542685700,
    price0: 1,
  },
  {
    activeLiquidity: 1054014821199071200,
    price0: 0.20191267,
  },
  {
    activeLiquidity: 1054014821199071200,
    price0: 0.01005415,
  },
  {
    activeLiquidity: 1054014821199071200,
    price0: 0.00191184,
  },
  {
    activeLiquidity: 1054014821199071200,
    price0: 0.00046215,
  },
  {
    activeLiquidity: 29202776904398730,
    price0: 0.000001,
  },
];

import { FlexColumn } from "@ui/common";
import { Button, IpRestrictProps } from "@ui/components";
import styled from "styled-components";

const Container = styled(FlexColumn)`
  width: 328px;
`;
const Title = styled.div`
  font-family: Dunbar Tall;
  font-size: 20px;
  line-height: 25.84px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const Text = styled.div`
  font-family: Roboto Flex;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  white-space: pre-line;
  color: ${({ theme }) => theme.colors.global.text.T2};
`;
const List = styled.ul`
  margin: 0;
  padding-inline-start: 22px;
  color: ${({ theme }) => theme.colors.global.text.T2};
  li {
    line-height: 16px;
    height: 16px;
  }
`;
const StyledButton = styled(Button)`
  font-size: 14px;
  line-height: 16px;
  padding: 16px 40px;
`;

// ISO 3166-1 alpha-2
export const restrictedCountries = {
  Belarus: "BY",
  Cuba: "CU",
  "Hong Kong": "HK",
  Iran: "IR",
  "North Korea": "KP",
  Russia: "RU",
  Syria: "SY",
  Ukraine: "UA",
  "United Kingdom": "GB",
  "United States of America": "US",
};

export const IpRestrictContent = ({ list, handleClose }: IpRestrictProps) => {
  return (
    <Container gap={20}>
      <Title>You're Accessing Aperture From A Restricted District.</Title>
      <img
        src="https://media1.tenor.com/m/8kuu11MN_KcAAAAC/you-shall-not-pass-lord-of-the-rings.gif"
        alt="You Shall Not Pass Lord Of The Rings"
      />
      <FlexColumn gap="xl">
        <Text>{`Your IP address is from one of the restricted district below.`}</Text>
        <List>
          {list.map((country, idx) => (
            <li key={`${country}-${idx}`}>
              <Text>{country}</Text>
            </li>
          ))}
        </List>
      </FlexColumn>
      <StyledButton
        variant="contained"
        color="secondary"
        width="100%"
        size="circle"
        onClick={handleClose}
      >
        OK
      </StyledButton>
    </Container>
  );
};

import { RpcSettingsType } from "@/config/feature_flags";
import { SLIPSTREAM_NETWORKS } from "@/utils/helper";
import { AmmEnum } from "@aperture/uikit";
import {
  ApertureSupportedChainId,
  viem,
} from "@aperture_finance/uniswap-v3-automation-sdk";
import { Address } from "viem";
import { getPublicClient } from "../getPublicClient";

export const fetchPositions = async (
  chainId: ApertureSupportedChainId,
  walletAddress: Address,
  amm: AmmEnum,
  rpcSetting?: RpcSettingsType
) => {
  const publicClient = getPublicClient(chainId, rpcSetting);

  const positionMap = await viem.getAllPositions(
    walletAddress,
    chainId,
    amm,
    publicClient
  );
  if (amm === "SLIPSTREAM" && SLIPSTREAM_NETWORKS.includes(chainId)) {
    const stakePositionIds = await viem.getSlipStreamStakePositions(
      walletAddress,
      chainId,
      publicClient
    );
    const stakePositions = await viem.getPositionsState(
      chainId,
      amm,
      stakePositionIds as bigint[]
    );
    stakePositions.forEach((pos) =>
      positionMap.set(
        pos.tokenId.toString(),
        viem.PositionDetails.fromPositionStateStruct(chainId, pos)
      )
    );
  }

  return Object.fromEntries(positionMap);
};

import { CaretIcon, GridMoreIcon } from "@aperture/assetkit";
import { DropMenu } from "@ui/components/Menu/DropMenu";
import Link from "next/link";
import React, { CSSProperties } from "react";
import styled, { useTheme } from "styled-components";
import { Body1 } from "../../Typography";
import { NavLinkProps, NavTabsProps } from "./types";

const NavLinks = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  box-sizing: border-box;
`;
const NavLink = styled(Link)<{
  disabled?: boolean;
  highlight?: string;
}>`
  position: relative;
  display: flex !important;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 8px;
  border-radius: ${({ theme }) => theme.radii.round};
  color: ${({ theme }) => theme.colors.global.text.T1};
  text-decoration: none;
  cursor: pointer;

  font-family: "Roboto Flex";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;

  ${({ theme, disabled }) =>
    !disabled &&
    `
  :hover {
    opacity: 0.8;
    path,
    circle,
    line {
      fill: ${theme.colors.global.text.T1};
      transition: ${theme.transitions.default};
    }
    > .icon {
      transform: rotate(180deg);
      transition: ${theme.transitions.default};
    }
  }
  `}
  transition: ${({ theme }) => theme.transitions.default};
  ${({ highlight, theme }) =>
    !!highlight &&
    highlight === "true" &&
    `
   color: ${theme.colors.global.primary}; 
  `}
`;
const Button = styled(Body1)`
  margin: 0;
  display: flex;
  white-space: nowrap;
  font-family: "Roboto Flex";
  font-weight: 500;
  text-transform: uppercase;
`;
const ComingSoonText = styled.p`
  margin: 0;
  padding: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.primary.lightPurple};
  border: 1px solid ${({ theme }) => theme.colors.primary.lightPurple};
  border-radius: 4px;
  width: 101px;
  position: absolute;
  left: 40px;
  bottom: -10px;
  text-align: center;
`;
const ChevronDown = styled(CaretIcon)<{ fill?: CSSProperties["fill"] }>`
  width: 24px;
  height: 24px;
  fill: ${({ theme, fill }) => fill ?? theme.colors.global.text.T1};

  > path {
    fill: ${({ theme, fill }) => fill ?? theme.colors.global.text.T1};
  }
`;
const MenuWrapper = styled.div`
  padding: 10px 8px;
  :hover {
    opacity: 0.8;
  }
  cursor: pointer;
`;

export const NavTabs: React.FC<NavTabsProps> = ({
  moreMenuItem,
  onSelect,
  networkInfo,
  navLinks = [],
  bgImages,
}) => {
  const theme = useTheme();
  return (
    <NavLinks>
      {moreMenuItem && (
        <DropMenu
          key="NavMore"
          userMenu={
            <MenuWrapper>
              <GridMoreIcon
                width={24}
                height={24}
                fill={theme.colors.global.text.T1}
              />
            </MenuWrapper>
          }
          bgImg={bgImages?.More ?? ""}
          title="More"
          mainMenu={moreMenuItem}
        />
      )}
      {navLinks.map((nav: NavLinkProps, index: number) => {
        return nav.hasSubMenu && nav.menuItems ? (
          <DropMenu
            key={`${nav.text}-${index}`}
            userMenu={
              <NavLink
                href={nav.disabled ? {} : `/${nav.link}`}
                key={index}
                disabled={nav.disabled}
                highlight={nav.highlight}
              >
                <Button>{nav.text}</Button>
                <br />
                {nav.disabled && <ComingSoonText>Coming soon!</ComingSoonText>}
                <ChevronDown />
              </NavLink>
            }
            bgImg={bgImages?.Selector ?? ""}
            title="Select Dex"
            mainMenu={nav.menuItems}
            onSelect={onSelect}
            internalMenuLabel="Select Chain"
            networkInfo={networkInfo}
          ></DropMenu>
        ) : (
          <NavLink
            href={nav.disabled ? {} : `/${nav.link}`}
            key={index}
            disabled={nav.disabled}
            highlight={nav.highlight}
          >
            <Button>{nav.text}</Button>
            <br />
            {nav.disabled && <ComingSoonText>Coming soon!</ComingSoonText>}
          </NavLink>
        );
      })}
    </NavLinks>
  );
};

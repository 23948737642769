import bigDecimal from "js-big-decimal";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { IInputRef, InputProps } from "./types";

export const BaseInput = styled.input<{
  align: "left" | "right";
  width?: number;
  error?: boolean;
  warning?: boolean;
}>`
  width: ${({ width }) => (width ? width - 32 + "px" : "calc(100% - 32px)")};
  height: 20px;
  padding: 8px 16px;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  text-align: ${({ align }) => align};
  border-radius: ${({ theme }) => theme.radii.round};
  background-color: transparent;
  color: ${({ theme }) => theme.colors.global.text.T1};
  outline: none;
  border: none;
  &:disabled {
    color: ${({ theme }) => theme.colors.global.text.T3};
  }
`;

const formatNumber = (
  value: string,
  type: "number" | "integer",
  round?: number
) => {
  // format to a valid number >= 0 without prefix '-'
  let formatValue = value
    .replace(/[^0-9.]/g, "") // Remove all chars
    .replace(/^0+(?!\.|$)/, ""); // Truncate leading `0`s

  if (type === "integer") {
    formatValue = formatValue.replace(/\./g, ""); // Remove all dots
    formatValue = formatValue === "" ? "0" : formatValue;
    formatValue = (
      Math.round(parseFloat(formatValue) / (round ?? 1)) * (round ?? 1)
    ).toString();
    if (round) {
      formatValue =
        bigDecimal.compareTo(formatValue, 0) > 0
          ? formatValue
          : round.toString();
    }
    return formatValue;
  } else {
    // Keep only one `.`
    // formatValue = formatValue.replace(/(?<=\..*)\./g, "");

    // Older version of Safari doesn't support lookbehind yet
    // https://caniuse.com/js-regexp-lookbehind
    // Compromised solution for backward compatibility
    const firstDotIdx = formatValue.indexOf(".") + 1;
    formatValue =
      formatValue.substring(0, firstDotIdx) +
      formatValue.substring(firstDotIdx).replace(/\./g, "");
    return formatValue === "." ? "0." : formatValue;
  }
};

export const Input = forwardRef<IInputRef, InputProps>(
  (
    {
      defaultValue = "",
      type = "text",
      align = "right",
      width,
      onBlur = (value: string) => value,
      onChange = (value: string) => value,
      onError = () => false,
      onWarning = () => false,
      format = {
        prefix: "",
        suffix: "",
        round: 1,
      },
      ...props
    },
    ref
  ) => {
    const formatValue = useCallback(
      (text: string) => {
        return text === ""
          ? ""
          : (format.prefix ?? "") + text + (format.suffix ?? "");
      },
      [format.prefix, format.suffix]
    );

    const [value, setValue] = useState(
      formatValue(
        type === "number" || type === "integer"
          ? formatNumber(defaultValue || "", type)
          : defaultValue || ""
      )
    );
    const handleChange = (event: any) => {
      let value = event.target.value;
      setValue(
        type === "number" || type === "integer"
          ? formatValue(formatNumber(value, type))
          : formatValue(value)
      );
      onChange(
        type === "number" || type === "integer"
          ? formatNumber(value, type)
          : value
      );
    };
    const handleBlur = (event: any) => {
      let value = event.target.value;
      value =
        type === "number" || type === "integer"
          ? formatNumber(value, type, format.round)
          : value;
      setValue(formatValue(value));
      onBlur(value);
    };
    const handleSetValue = (newValue: string) => {
      newValue =
        type === "number" || type === "integer"
          ? formatNumber(newValue, type)
          : newValue;
      setValue(formatValue(newValue));
      onChange(newValue);
    };
    useImperativeHandle(ref, () => ({
      setValue: handleSetValue,
    }));
    useEffect(() => {
      setValue(formatValue(defaultValue ?? ""));
    }, [defaultValue, formatValue]);

    const baseInput = useRef<HTMLInputElement>(null);
    const handleKeyDown = (event: any) => {
      if (format && format.prefix && event.key === "Delete") {
        const input = baseInput.current as HTMLInputElement;
        const position = format.prefix.length;
        if (input && input.selectionStart !== null) {
          input.selectionStart < position &&
            input.setSelectionRange(position, position);
        }
      }
      if (format && format.suffix && event.key === "Backspace") {
        const input = baseInput.current as HTMLInputElement;
        if (input && input.selectionStart !== null) {
          const position = input.value.length - format.suffix.length;
          input.selectionStart > position &&
            input.setSelectionRange(position, position);
        }
      }
    };

    return (
      <BaseInput
        ref={baseInput}
        align={align}
        type="text"
        width={width}
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        error={onError(value)}
        warning={onWarning(value)}
        {...props}
      />
    );
  }
);

import styled from "styled-components";
import {
  color,
  compose,
  layout,
  space,
  typography,
  variant,
} from "styled-system";
import { styleVariants } from "./theme";
import { TagProps } from "./types";

const StyledTag = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  border-radius: ${({ theme }) => theme.radii.sm};
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  ${variant({ variants: styleVariants })}
  transition: ${({ theme }) => `all ${theme.transitions.default} ease-in-out`};
  grid-gap: ${({ theme }) => theme.spacing.sm};
  gap: ${({ theme }) => theme.spacing.sm};
  ${compose(space, layout, color, typography)}
`;

export const Tag: React.FC<React.PropsWithChildren<TagProps>> = ({
  children,
  ...props
}) => {
  return <StyledTag {...props}>{children}</StyledTag>;
};

import { SwapPathProps } from "@aperture/uikit";
import { getLogger } from "@aperture_finance/uniswap-v3-automation-sdk";
import { E_Solver } from "@aperture_finance/uniswap-v3-automation-sdk/dist/viem";
import { useMemo, useState } from "react";

export const useSolverRouting = () => {
  const [swapInfo, setSwapInfo] = useState<SwapPathProps[]>(undefined);
  const [selectedSolver, setSelectedSolver] = useState<E_Solver>(undefined);

  const selectedSolverIndex = useMemo(
    () => findSolverIndex(swapInfo, selectedSolver),
    [selectedSolver, swapInfo]
  );

  const setSelectedSolverByIdx = (
    idx: number,
    _swapInfo: SwapPathProps[] = swapInfo,
    bySuggested: boolean = false
  ) => {
    const solver = _swapInfo?.[idx]?.api?.solver;
    setSelectedSolver(solver);

    getLogger().info("Solver.Selected", {
      solver,
      allSolvers: _swapInfo?.map((s) => s.api?.solver),
      bySuggested,
    });
  };

  const resetSolver = () => {
    _setSwapInfo(undefined);
  };

  const _setSwapInfo = (data: SwapPathProps[]) => {
    setSwapInfo(data);
    if (data) {
      if (findSolverIndex(data, selectedSolver) === -1) {
        setSelectedSolverByIdx(getSuggestedSolver(data), data, true);
      }
    } else {
      // reset the selected solver
      setSelectedSolver(undefined);
    }
  };

  const noNeedSwap = !!(
    swapInfo?.[selectedSolverIndex]?.swapRoutes?.length === 0
  );

  return {
    noNeedSwap,

    swapInfo,
    setSwapInfo: _setSwapInfo,

    selectedSolver: selectedSolverIndex,
    setSelectedSolver: setSelectedSolverByIdx,

    suggestedSolver: useMemo(() => getSuggestedSolver(swapInfo), [swapInfo]),
    resetSolver,
  };
};

const findSolverIndex = (data: SwapPathProps[], solver: E_Solver) => {
  return data?.findIndex((s) => s.api?.solver === solver) ?? -1;
};

export const getSuggestedSolver = (data: SwapPathProps[]) => {
  return data?.reduce((acc, cur, idx) => {
    if (idx === 0 || cur.liquidity > data[acc].liquidity) {
      return idx;
    }
    return acc;
  }, 0);
};

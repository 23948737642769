import styled, { DefaultTheme } from "styled-components";
import { variant } from "styled-system";
import { IDrawContainer } from "./types";

export const DRAWER_WIDTH = "57.29%";

export const DrawerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
  z-index: ${({ theme }) => theme.zIndices.tab};
`;

const getDirectionsStyle = (
  isOpen: boolean,
  theme: DefaultTheme,
  width?: string,
  height?: string
) => {
  const absoluteHorizontalPos = isOpen ? 0 : `calc(-1 * ${width} - 20px)`;
  const absoluteVerticalPos = isOpen ? 0 : `calc(-1 * ${height} - 20px)`;
  return {
    left: {
      width: width,
      height: "100%",
      top: 0,
      left: absoluteHorizontalPos,
      background: theme.colors.global.background.BG2,
    },
    right: {
      width: width,
      height: "100%",
      top: 0,
      right: absoluteHorizontalPos,
      background: theme.colors.global.background.BG2,
    },
    top: {
      width: "100%",
      height: height,
      top: absoluteVerticalPos,
      left: 0,
      background: theme.colors.global.background.BG2,
    },
    bottom: {
      width: "100%",
      height: height,
      bottom: absoluteVerticalPos,
      left: 0,
      background: theme.colors.global.background.BG2,
    },
  };
};

export const DrawerContainer = styled.div<IDrawContainer>`
  max-width: ${DRAWER_WIDTH};
  position: absolute;
  transition: all ${({ theme }) => theme.transitions.default} ease;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadows.xLarge};
  pointer-events: all;
  padding: 22px 40px;
  ${({ width, height, isOpen, theme }) =>
    variant({
      prop: "direction",
      variants: getDirectionsStyle(isOpen ?? false, theme, width, height),
    })}
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 36px 28px 10px 28px;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.grey.black};
`;

export const ContentContainer = styled.div`
  height: calc(100% - 104px);
  width: calc(100% - 56px);
  margin: 0 auto;
  overflow: hidden scroll;
`;

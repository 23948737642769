import React from "react";
import { QuestionPopover } from "../Popover";
import {
  B,
  GreyBoxSM,
  Grid,
  LeftGreyBox,
  MarketPriceWrapper,
  RightGreyBox,
  StyledInput,
  SwapButton,
  SwapButtonIcon,
  TextBox,
  Ticker,
  TokenSwapContainer,
  ValueContainer,
  ValueInput,
  Wrapper,
} from "./style";
import { ITokenSwapProps } from "./types";

export const TokenSwap: React.FC<ITokenSwapProps> = ({
  onPlusClick,
  onMinusClick,
  onCloseToMarketClick,
  sellTicker,
  buyTicker,
  disabled,
  action,
  setAction,
  marketPrice,
  ...props
}) => {
  const PopoverContent = (
    <Wrapper>
      By default, the limit price is set within a one-tick range order. Your
      entered price will be auto-adjusted to the average execution price, and
      the order will fully execute only when the market price surpasses the
      entire range.
      <br />
      <br />
      <Grid>
        <GreyBoxSM> - </GreyBoxSM>
        <GreyBoxSM> + </GreyBoxSM>
        <div>
          Use the plus or minus buttons to choose a higher or lower average
          price corresponding to different ranges.
        </div>
      </Grid>
    </Wrapper>
  );
  return (
    <TokenSwapContainer>
      <TextBox>
        {action === "sell" ? "Buy" : "Sell"}{" "}
        {action === "sell" ? buyTicker : sellTicker} at Price
        <QuestionPopover
          //@ts-ignore
          style={{ marginLeft: "4px" }}
          content={PopoverContent}
        />
      </TextBox>

      <ValueContainer>
        <ValueInput>
          <LeftGreyBox onClick={onMinusClick}> - </LeftGreyBox>
          <RightGreyBox onClick={onPlusClick}> + </RightGreyBox>
          <StyledInput
            disabled={disabled}
            align="left"
            type="number"
            {...props}
          />
          <Ticker>{action === "sell" ? sellTicker : buyTicker}</Ticker>
          <SwapButton
            onClick={() => setAction(action === "sell" ? "buy" : "sell")}
            style={{
              marginBottom: "2px",
              position: "absolute",
              right: "-12px",
            }}
          >
            <SwapButtonIcon />
          </SwapButton>
        </ValueInput>
        <MarketPriceWrapper>
          Market Price:
          <div style={{ float: "right" }}>
            {!(sellTicker === "-" || sellTicker === "-") ? (
              <>
                <B>{marketPrice[action === "sell" ? "buy" : "sell"]}</B>{" "}
                {action === "sell" ? sellTicker : buyTicker} per{" "}
                {action === "sell" ? buyTicker : sellTicker}
              </>
            ) : (
              <> - </>
            )}
          </div>
        </MarketPriceWrapper>
      </ValueContainer>
    </TokenSwapContainer>
  );
};

import { QuestionMarkIcon } from "@aperture/assetkit";
import React from "react";
import styled from "styled-components";
import { Popover } from "../Popover";
import { IInfoCardProps } from "./types";

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  gap: ${({ theme }) => theme.spacing["5xl"]};
`;
const Title = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const Content = styled.div`
  text-align: right;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const PopoverContainer = styled.span`
  margin-left: ${({ theme }) => theme.spacing.sm};
  svg {
    fill: ${({ theme }) => theme.colors.global.text.T2};
  }
`;

export const InfoCard: React.FC<IInfoCardProps> = ({
  title = "",
  content = "",
  hint = "",
  ...props
}) => {
  return (
    <InfoContainer {...props}>
      <Title>
        <span>{title}</span>
        {hint && (
          <PopoverContainer>
            <Popover content={hint}>
              <QuestionMarkIcon height={12} width={12} />
            </Popover>
          </PopoverContainer>
        )}
      </Title>
      <Content>{content}</Content>
    </InfoContainer>
  );
};

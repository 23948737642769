import { StarIcon } from "@aperture/assetkit";
import { Flex, FlexColumn } from "@uiv2/common";
import { TokenIcons } from "@uiv2/components/TokenIcons";
import { T2, T3Regular } from "@uiv2/components/Typography";
import styled, { useTheme } from "styled-components";
import { ITimeframe } from "../TimeFrame";

const Wrapper = styled.div<{ w: number }>`
  min-width: ${({ w }) => `${w}px`};
  height: 20px;
  padding-block: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
`;
const StyledT2 = styled(T2)`
  color: ${({ theme }) => theme.colors.global.text.T2};
`;
const StyledT3 = styled(T3Regular)`
  color: ${({ theme }) => theme.colors.global.text.T2};
`;
const IconWrapper = styled.div`
  height: 20px;
  width: 20px;
  svg {
    height: 20px;
    width: 20px;
  }
`;
const FeeAPYLink = styled.a<{ url?: boolean }>`
  text-decoration: none;
  :hover {
    text-decoration: ${({ url }) => (url ? "underline" : "none")};
    color: ${({ theme }) => theme.colors.global.success};
  }
`;
const FeeAPY = styled(T2)`
  color: ${({ theme }) => theme.colors.global.success};
`;
const EmptyStateWrapper = styled(FlexColumn)`
  padding: ${({ theme }) => theme.spacing.md};
  padding-top: ${({ theme }) => theme.spacing["3xl"]};
`;
const Pool = styled.a<{ url?: boolean }>`
  display: flex;
  gap: 6px;
  text-decoration: none;
  :hover {
    text-decoration: ${({ url }) => (url ? "underline" : "none")};
    color: ${({ theme }) => theme.colors.global.text.T2};
  }
`;
const Tag = styled.div`
  padding: 2px 4px;
  border-radius: ${({ theme }) => theme.radii.sm};
  background-color: ${({ theme }) => theme.colors.global.background.BG3};
`;

type IIconWTicker = { ticker: string; Icon: JSX.Element };

export enum Risk {
  SAFE = "SAFE",
  LOW_RISK = "LOW RISK",
  HIGH_RISK = "HIGH RISK",
}
export interface IPoolRow {
  poolId: string;
  token0: IIconWTicker;
  token1: IIconWTicker;
  feeTier: string;

  dexIcon: JSX.Element;
  chainIcon: JSX.Element;

  feeAPY: string;
  risk: Risk;

  priceVolatility: string;
  totalValueLockedUSD: string;

  fee24h: string;
  volume24h: string;
  volumePerTVL: string;

  isFavorite?: boolean;
  onSelect?: () => void;
  url?: string;
  calcUrl?: string;
}

export const getPoolTableHeaders = (t: ITimeframe) => [
  "selector",
  "POOL",
  "DEX/CHAIN",
  "FEE APY",
  "RISK",
  `PRICE VOLATILITY (${t}D)`,
  "TVL",
  `FEES (${t}D)`,
  `VOLUME (${t}D)`,
  "24H AVG VOLUME / TVL",
];

export const poolTableColumns = {
  selector: 49,
  POOL: 262,
  "DEX/CHAIN": 111,
  "FEE APY": 130,
  RISK: 124,
  "PRICE VOLATILITY": 177,
  TVL: 113,
  FEES: 140,
  VOLUME: 160,
  "24H AVG VOLUME / TVL": 175,
} as const;

const FavoriteButton = styled(StarIcon)`
  cursor: pointer;
`;

export const PoolRow = ({
  poolId,
  token0,
  token1,
  feeTier,
  dexIcon,
  chainIcon,
  feeAPY,
  risk,
  priceVolatility,
  totalValueLockedUSD,
  fee24h,
  volume24h,
  volumePerTVL,
  isFavorite,
  onSelect,
  url,
  calcUrl,
}: IPoolRow) => {
  const theme = useTheme();
  const items: Record<keyof typeof poolTableColumns, JSX.Element> = {
    selector: (
      <FavoriteButton
        onClick={onSelect}
        fill={
          isFavorite
            ? theme.colors.global.primary
            : theme.colors.transparent.transparent
        }
        stroke={
          isFavorite ? theme.colors.global.primary : theme.colors.global.text.T4
        }
      />
    ),
    POOL: (
      <Flex alignItems="center" gap="md">
        <Pool href={url} url={!!url} target="_blank" rel="noreferrer">
          <TokenIcons
            leftIcon={token0.Icon}
            rightIcon={token1.Icon}
            size={20}
            overlap={8}
            overlapRight
          />
          <StyledT2>
            {token0.ticker}/{token1.ticker}
          </StyledT2>
        </Pool>
        <Tag>
          <StyledT2>{feeTier}</StyledT2>
        </Tag>
      </Flex>
    ),
    "DEX/CHAIN": (
      <Flex gap="sm">
        <IconWrapper>{dexIcon}</IconWrapper>
        <StyledT2>/</StyledT2>
        <IconWrapper>{chainIcon}</IconWrapper>
      </Flex>
    ),
    "FEE APY": (
      <FeeAPYLink
        href={calcUrl}
        target="_blank"
        rel="noreferrer"
        url={!!calcUrl}
      >
        <FeeAPY>{feeAPY}</FeeAPY>
      </FeeAPYLink>
    ),
    RISK: <StyledT2>{risk}</StyledT2>,
    "PRICE VOLATILITY": <StyledT2>{priceVolatility}</StyledT2>,
    TVL: <StyledT2>{totalValueLockedUSD}</StyledT2>,
    FEES: <StyledT2>{fee24h}</StyledT2>,
    VOLUME: <StyledT2>{volume24h}</StyledT2>,
    "24H AVG VOLUME / TVL": <StyledT2>{volumePerTVL}</StyledT2>,
  };
  return (
    <Flex alignItems="center">
      {Object.entries(items).map(([key, item]) => (
        <Wrapper
          key={`${poolId}-${key}`}
          w={poolTableColumns[key as keyof typeof poolTableColumns]}
        >
          {item}
        </Wrapper>
      ))}
    </Flex>
  );
};

export const PoolsEmptyState = (
  <EmptyStateWrapper gap="sm" alignItems="center" justifyContent="center">
    <StyledT2>No Pools.</StyledT2>
    <StyledT3>Likely a subgraph issue. Please check back later!</StyledT3>
  </EmptyStateWrapper>
);

export const FavoritePoolsEmptyState = (
  <StyledT3>
    You don’t have favorite pools yet. Click the star icon to add pools to your
    favorite collection.
  </StyledT3>
);

import { IPriceRange, TokenInfo as TokenInfoType } from "@aperture/types";
import { formatMarketPrice } from "@uiv2/utils/numberFormat";
import bigDecimal from "js-big-decimal";
import { ReactNode, useState } from "react";
import { TokenPriceGroup } from "../TokenPriceGroup";
import { PureBox, StyledTitle, StyledValue } from "./style";

export interface ITokenInfo extends TokenInfoType {
  Icon: ReactNode;
  amount: string;
  collectableAmount: string;
  price: string;
}

export interface IPositionPriceRange {
  tokens: [string, string];
  priceRange: IPriceRange;
  marketPrice: string;
}
export const PositionPriceRange: React.FC<IPositionPriceRange> = ({
  tokens,
  priceRange,
  marketPrice,
}) => {
  const [selected, setSelected] = useState();
  const { min, max, swapMax, swapMin } = priceRange;
  const swap = selected === tokens[1];
  return (
    <TokenPriceGroup
      buyToken={{
        ticker: tokens[0],
        min,
        max,
      }}
      sellToken={{
        ticker: tokens[1],
        min: swapMin,
        max: swapMax,
      }}
      title={
        <StyledTitle>
          Market Price:
          <StyledValue>
            {formatMarketPrice(
              swap ? bigDecimal.divide(1, marketPrice, 18) : marketPrice
            )}
            <PureBox>
              {tokens[swap ? 0 : 1]} per {tokens[swap ? 1 : 0]}
            </PureBox>
          </StyledValue>
        </StyledTitle>
      }
      setSelected={(value) => setSelected(value)}
    />
  );
};

import React from "react";
import styled from "styled-components";
import { compose, layout, space, typography } from "styled-system";
import { UITypographyProps } from "./types";

const StyledSubtitle0 = styled.div<UITypographyProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  ${compose(layout, space, typography)}
`;
export const Subtitle0: React.FC<
  React.PropsWithChildren<UITypographyProps>
> = ({ children, ...props }) => (
  <StyledSubtitle0 {...props}>{children}</StyledSubtitle0>
);

const StyledSubtitle1 = styled.div<UITypographyProps>`
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  ${compose(layout, space, typography)}
`;
export const Subtitle1: React.FC<
  React.PropsWithChildren<UITypographyProps>
> = ({ children, ...props }) => (
  <StyledSubtitle1 {...props}>{children}</StyledSubtitle1>
);

const StyledSubtitle2 = styled.div<UITypographyProps>`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  ${compose(layout, space, typography)}
`;
export const Subtitle2: React.FC<
  React.PropsWithChildren<UITypographyProps>
> = ({ children, ...props }) => (
  <StyledSubtitle2 {...props}>{children}</StyledSubtitle2>
);

const StyledSubtitle3 = styled.div<UITypographyProps>`
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  ${compose(layout, space, typography)}
`;
export const Subtitle3: React.FC<
  React.PropsWithChildren<UITypographyProps>
> = ({ children, ...props }) => (
  <StyledSubtitle3 {...props}>{children}</StyledSubtitle3>
);

import { Flex } from "@uiv2/common";
import { defaultTheme } from "@uiv2/theme";
import { CSSProperties } from "react";
import styled from "styled-components";
import { Edge, TabSize, TabType } from "./types";

const wrapper_padding = 6;
const switch_wrapper_padding = 1;
const tab_item_py = 4;
const tab_item_px = 16;

const switch_item_heights: Record<TabSize, number> = {
  sm: 12,
  md: 14,
  lg: 16,
  xl: 16,
};

export const tabTextStyles = {
  sm: {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "14px",
  },
  md: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16px",
  },
  lg: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
  },
  xl: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",
  },
};

const getBorderRadius = (type?: TabType, edge?: Edge) => {
  const round = defaultTheme.radii.round;

  switch (type) {
    case "pill": {
      return round;
    }
    case "underlined": {
      return "0px";
    }
    case "filled": {
      return edge === "left"
        ? `${round} 0px 0px ${round}`
        : edge === "right"
        ? `0px ${round} ${round} 0px `
        : "0px";
    }
  }
};
const getItemPadding = (type: TabType) => {
  if (type === "filled") {
    return `${tab_item_py + 6}px ${tab_item_px}px`;
  } else if (type === "switch") {
    return "0px";
  } else {
    return `${tab_item_py}px ${tab_item_px}px`;
  }
};
const getTabWidth = (text: string, size: TabSize, type: TabType) => {
  const styles: CSSProperties = {
    ...tabTextStyles[size],
    padding: getItemPadding(type),
    width: "fit-content",
    visibility: "hidden",
  };
  const label = document.createElement("span");
  label.textContent = text;
  Object.assign(label.style, styles);

  document.body.appendChild(label);
  const width = label.getBoundingClientRect().width;
  document.body.removeChild(label);
  return width;
};

export const pillTypeTabs: TabType[] = ["filled", "pill"];

export const getTabWidths = (size: TabSize, type: TabType, tabs: string[]) => {
  const isPillType = pillTypeTabs.includes(type);
  if (!isPillType) {
    return tabs.map(() => 100 / tabs.length);
  } else {
    return tabs.map((item) => getTabWidth(item, size, type));
  }
};
export const Wrapper = styled(Flex)<{
  type: TabType;
  size: TabSize;
  isOn: boolean;
}>`
  display: ${({ type }) => type === "filled" && "contents"};
  padding: ${({ type }) =>
    type === "switch" ? switch_wrapper_padding : wrapper_padding}px;
  height: ${({ type, size }) =>
    type === "switch"
      ? `${switch_item_heights[size]}px`
      : `calc(${tabTextStyles[size].lineHeight} + ${tab_item_py * 2}px})`};
  width: ${({ type }) => (type === "underlined" ? undefined : "fit-content")};
  border-radius: ${({ theme, type }) =>
    type === "underlined" ? 0 : theme.radii.round};
  background: ${({ theme, type, isOn }) =>
    type === "underlined"
      ? theme.colors.transparent.transparent
      : type === "switch"
      ? isOn
        ? theme.colors.global.success
        : theme.colors.transparent.transparent
      : theme.colors.global.background.BG3};
  border: ${({ theme, type, isOn }) =>
    type === "switch"
      ? `1px solid ${
          isOn ? theme.colors.global.success : theme.colors.transparent.grey2
        }`
      : undefined};
`;
export const TabBar = styled(Flex)<{ type: TabType; isOn?: boolean }>`
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :hover,
  :focus {
    .tabSlider {
      box-shadow: ${({ theme }) => theme.shadows.small};
    }
  }
  border-radius: ${({ theme, type }) =>
    type === "underlined" ? 0 : theme.radii.round};
  background: ${({ theme, type, isOn }) =>
    type === "underlined"
      ? theme.colors.transparent.transparent
      : type === "switch"
      ? isOn
        ? theme.colors.global.success
        : theme.colors.transparent.transparent
      : theme.colors.global.background.BG3};
  width: ${({ type }) => (type === "underlined" ? "100%" : "fit-content")};
  height: fit-content;
`;
export const TabSlider = styled.div.attrs({
  className: "tabSlider",
})<{
  type: TabType;
  size: TabSize;
  height?: string;
  width: CSSProperties["width"];
  edge?: Edge;
  isOn?: boolean;
}>`
  position: absolute;
  height: ${({ height, type, size }) =>
    type === "underlined"
      ? "3px"
      : type === "switch"
      ? `${switch_item_heights[size]}px`
      : height ?? "100%"};
  width: ${({ width }) => width};
  background: ${({ theme, type, isOn }) =>
    type === "underlined"
      ? theme.colors.global.text.T2
      : type === "switch"
      ? isOn
        ? theme.colors.global.background.BG1
        : theme.colors.grey.mediumGrey
      : theme.colors.global.text.T1};
  border-radius: ${({ theme, type }) =>
    type === "underlined" ? 0 : theme.radii.round};
  bottom: ${({ type }) => (type === "underlined" ? "-0.5px" : undefined)};
  transition: ${({ theme }) => theme.transitions.default};
  border-radius: ${({ edge, type }) => getBorderRadius(type, edge)};
`;

export const TabItem = styled(Flex)<{
  isSelected: boolean;
  type: TabType;
  size: TabSize;
  width?: CSSProperties["width"];
}>`
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: ${({ width, type, size }) =>
    type === "switch"
      ? `${switch_item_heights[size]}px`
      : width ?? "fit-content"};
  height: ${({ type, size }) =>
    type === "switch"
      ? `${switch_item_heights[size]}px`
      : `calc(100% - ${tab_item_py * 2}px)`};
  padding: ${({ type }) => getItemPadding(type)};
  z-index: ${({ theme }) => theme.zIndices.tab};
  color: ${({ theme, isSelected, type }) =>
    type === "underlined"
      ? isSelected
        ? theme.colors.global.text.T1
        : theme.colors.global.text.T3
      : isSelected
      ? theme.colors.global.background.BG3
      : theme.colors.global.text.T1};
  border-bottom: ${({ theme, type, isSelected }) =>
    type === "underlined"
      ? `1px solid ${
          isSelected
            ? theme.colors.transparent.transparent
            : theme.colors.global.line.Line1
        }`
      : "none"};
  transition: ${({ theme }) => theme.transitions.default};
  :hover {
    color: ${({ theme, type, isSelected }) =>
      type === "underlined" && !isSelected
        ? theme.colors.global.text.T2
        : undefined};
    border-color: ${({ theme, type, isSelected }) =>
      type === "underlined" && !isSelected
        ? theme.colors.global.text.T3
        : undefined};
  }
`;

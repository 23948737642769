import { ChevronIcon } from "@aperture/assetkit";
import Link from "next/link";
import styled from "styled-components";

export const DropdownBox = styled.div<{
  isOpen: boolean;
  bgImg: string;
}>`
  width: 318px;
  height: 250px;
  flex-shrink: 0;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.global.text.T1};
  background: ${({ theme }) => theme.colors.global.background.BG3};
  position: relative;
  z-index: 1;

  ::before {
    content: "";
    position: absolute;
    border-radius: 10px;
    background: url(${({ bgImg }) => bgImg});
    filter: blur(0px);
    background-repeat: no-repeat;
    background-size: cover;
    width: 174px;
    height: 206px;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
`;

export const Title = styled.div`
  font-family: "Dunbar Tall";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-transform: uppercase;
  padding: 8px;
  height: 100%;
`;

export const MenuWrapper = styled.div<{
  hasItems: boolean;
  height?: number;
  minHeight?: number;
}>`
  box-sizing: border-box;
  display: inline-flex;
  ${({ height }) => height && `height:${height}px;`}
  ${({ minHeight }) => minHeight && `min-height:${minHeight}px;`}
  padding: 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 8px;
  background: ${({ theme, hasItems }) =>
    hasItems
      ? theme.colors.transparent.transparent
      : `${theme.colors.global.background.BG1}1A`};
`;

export const MenuItem = styled.div<{
  gap: number;
  hasItems: boolean;
  isSelected: boolean;
}>`
  box-sizing: border-box;
  display: flex;
  width: 180px;
  height: 32px;
  padding: 8px;
  align-items: center;
  gap: ${({ gap }) => `${gap}px`};
  font-family: "Roboto Flex";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.global.text.T1};
  :hover {
    cursor: pointer;
    border-radius: 5px;
    background: ${({ theme, hasItems }) =>
      hasItems
        ? theme.colors.global.background.BG4
        : theme.colors.global.background.BG3};
  }
  transition: all ${({ theme }) => theme.transitions.default} ease-in-out;

  ${({ isSelected, theme, hasItems }) =>
    isSelected &&
    `
    border-radius: 5px;
    background: 
      ${
        hasItems
          ? theme.colors.global.background.BG4
          : theme.colors.global.background.BG3
      };
    .hover-chevron {
      display: block;
      > svg,
      path {
        stroke: ${theme.colors.global.text.T1};
      }
    }
  `}
`;

export const Caret = styled(ChevronIcon)`
  display: block;
  margin-left: auto;
  margin-right: 0;

  path {
    stroke: ${({ theme }) => theme.colors.global.text.T1};
  }
`;

export const MenuBox = styled.div<{ isOpen: boolean }>`
  box-sizing: border-box;
  width: max-content;
  text-align: center;
  min-width: fit-content;
  overflow: hidden;
  cursor: auto;
  background: ${({ theme }) => theme.colors.global.background.BG3};
  color: ${({ theme }) => theme.colors.global.text.T1};
  border-radius: 0 10px 10px 0;
  z-index: ${({ theme }) => theme.zIndices.dropdown};

  ${({ isOpen }) =>
    !isOpen &&
    `
    pointer-events: none;
    visibility: hidden;
  `}
`;

export const NavLink = styled(Link)`
  display: flex;
  gap: 10px;
  box-sizing: border-box;
  align-items: center;
  color: ${({ theme }) => theme.colors.global.text.T1};
  text-decoration: none;
`;

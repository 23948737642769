import { DeleteIcon } from "@aperture/assetkit";
import { Flex, FlexColumn } from "@ui/common";
import styled, { keyframes } from "styled-components";
import { compose, display, layout, space } from "styled-system";
import { DRAWER_WIDTH } from "../Drawer/style";
import { Body2, ButtonText1, Headline1 } from "../Typography";

export const Container = styled(Flex)<{ open: boolean }>`
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${({ theme }) => theme.zIndices.tab};
  background-color: ${({ theme, open }) =>
    open
      ? theme.colors.global.background.BG2
      : theme.colors.transparent.transparent};
  width: ${({ open }) => (open ? DRAWER_WIDTH : "40px")};
  transition: width ${({ theme }) => theme.transitions.default} !important;
`;

export const DrawerWrapper = styled.div<{ open: boolean }>`
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  padding: 22px 180px 22px 40px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.global.text.T1};
  background-color: ${({ theme }) => theme.colors.global.background.BG2};

  ${compose(layout, space)}
`;

export const DrawerContentWrapper = styled(FlexColumn)`
  height: calc(100% - 40px);
  width: 605px;
`;

export const SideBar = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  height: 129px;
  width: 28px;
  padding: 24px 6px;
  top: 448px;
  border-radius: 10px 0px 0px 10px;
  gap: 10px;
  color: ${({ theme }) => theme.colors.global.textAlt.T1};
  background-color: ${({ theme }) => theme.colors.global.primary};
  transition: ${({ theme }) =>
    `${theme.transitions.default} ease background-color`};

  &:hover {
    background-color: ${({ theme }) => theme.colors.global.primaryHover};
    opacity: 0.8;
  }

  ${display}
`;

export const MenuText = styled.div`
  position: relative;
  font-family: Roboto Flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  text-wrap: nowrap;
  transform: rotate(-0.25turn);
  color: ${({ theme }) => theme.colors.global.textAlt.T1};
`;

export const DrawerTitle = styled(Headline1)`
  font-family: Dunbar Tall;
  font-weight: 500;
  text-align: left;
`;

export const MessageListWrapper = styled(FlexColumn)`
  overflow-y: auto;
  height: 100%;
  scrollbar-color: ${({ theme }) => theme.colors.grey.black} transparent;
  gap: ${({ theme }) => theme.spacing["2xl"]};
  width: 605px;
`;

export const ChatBubble = styled.div<{ isUser: boolean }>`
  border-radius: ${({ theme }) => theme.radii.md};
  background-color: ${({ theme, isUser }) =>
    isUser ? theme.colors.global.primary : theme.colors.global.text.T5};

  color: ${({ theme, isUser }) =>
    isUser ? theme.colors.global.textAlt.T1 : theme.colors.global.text.T1};
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  padding: 0 16px;
  margin: 0 12px;
  max-width: 70%;
`;

const slideIn = keyframes`
  from {
    transform: translateY(40%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const MessageWrapper = styled(Flex)`
  animation: ${slideIn} 0.5s;
`;
export const AvatarWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

export const HistoryDate = styled(Body2)`
  font-weight: 400;
  line-height: 14px;
  padding-top: 14px;
  color: ${({ theme }) => theme.colors.global.text.T3};
`;

export const HistoryWrapper = styled(Flex)`
  height: 16px;
  padding: 8px 12px;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.radii.md};
  &:hover {
    background: ${({ theme }) => theme.colors.global.text.T5};
    svg {
      display: block;
    }
  }
`;

export const HistoryTitle = styled.div`
  width: calc(100% - 8px);
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const HistoryDelete = styled(DeleteIcon)`
  display: none;
  width: 16px;
  height: 16px;
`;

export const StyledText = styled(ButtonText1)`
  line-height: 16px;
`;

export const Content = styled(FlexColumn)`
  height: calc(100% - 120px);
`;

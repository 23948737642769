import { LinkIcon2 } from "@aperture/assetkit";
import { Flex, FlexColumn } from "@ui/common";
import styled, { useTheme } from "styled-components";
import { Button } from "../Button";

const Container = styled(FlexColumn)`
  box-sizing: border-box;
  width: 532px;
  height: fit-content;
  border-radius: 15px;
  margin-top: 20px;
  padding: ${({ theme }) => theme.spacing.xl};
  background: ${({ theme }) => theme.colors.global.background.Gradient1};
`;
const Text1 = styled.div`
  font-family: "Roboto Flex";
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.global.text.T2};
`;
const Title = styled.div`
  font-family: "Roboto Flex";
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  white-space: pre;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const StyledButton = styled(Button)`
  padding: 12px 40px;
  line-height: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.global.textAlt.T1};

  &:disabled {
    background: ${({ theme }) => `${theme.colors.global.text.T3} !important`};
    color: ${({ theme }) => `${theme.colors.global.textAlt.T1} !important`};
  }
`;

export const TokenAllocation: React.FC = () => {
  const theme = useTheme();
  return (
    <Container gap="xl">
      <Title>{`INTENTional Campaign\nToken Allocation`}</Title>
      <StyledButton
        variant="contained"
        color="primary"
        size="lg"
        disabled={true}
      >
        <Flex gap={10} alignItems="center">
          Claim
          <LinkIcon2
            width={16}
            height={16}
            stroke={theme.colors.global.textAlt.T1}
          />
        </Flex>
      </StyledButton>
      <Text1>INTENTional Airdrop Campaign is over.</Text1>
    </Container>
  );
};

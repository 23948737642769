import { Flex, FlexColumn } from "@uiv2/common";
import styled from "styled-components";

export const Container = styled(FlexColumn)`
  width: 368px;
  padding: 16px;
  gap: ${({ theme }) => theme.spacing["3xl"]};
  border-radius: ${({ theme }) => theme.radii.xl};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
`;
export const HeaderContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
export const InputContainer = styled(FlexColumn)`
  width: 368px;
  gap: ${({ theme }) => theme.spacing.lg};
`;
export const MinMaxContainer = styled.div`
  width: 178px;
`;

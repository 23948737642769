import { CaretIcon } from "@aperture/assetkit";
import { Flex } from "@ui/common/styles/global";
import styled from "styled-components";
import { compose, layout } from "styled-system";
import { ISelectStyleProps } from "./type";

export const SelectListContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const SelectList = styled.div`
  width: 100%;
  position: absolute;
  border: 1px solid ${({ theme }) => theme.colors.grey.grey};
  border-radius: ${({ theme }) => theme.radii.md};
  background: ${({ theme }) => theme.colors.grey.lightGrey1};
  transition: opacity ${({ theme }) => theme.transitions.default};
  box-sizing: border-box;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  color: ${({ theme }) => theme.colors.grey.black};
  top: 8px;
`;

export const SelectListItem = styled(Flex)`
  box-sizing: border-box;
  align-items: center;
  padding: 8px;
  border-radius: ${({ theme }) => theme.radii.md};
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.transparent.grey2};
  }
  transition: all ${({ theme }) => theme.transitions.default};
`;

export const SelectButton = styled(Flex)<{ disabled?: boolean }>`
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.grey.mediumGrey : theme.colors.grey.black};

  transition: all ${({ theme }) => theme.transitions.default};
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
`;

export const ArrowDown = styled(CaretIcon)`
  width: 11px;
  height: 11px;
  transform: rotate(180deg);
`;

export const Container = styled.div<ISelectStyleProps>`
  height: 40px;
  box-sizing: border-box;
  position: relative;
  border: ${({ bordered, theme }) =>
    bordered ? `1px solid ${theme.colors.grey.grey}` : "none"};
  border-radius: ${({ theme }) => theme.radii.md};
  ${compose(layout)}
`;

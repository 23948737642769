import { isManta } from "@/utils/networkHelper";
import {
  Button,
  Button3,
  SupportedChainId,
  WrongNetworkContent,
} from "@aperture/uikit";
import { useRouter } from "next/router";
import styled from "styled-components";
import { useNetwork } from "../NetworkContext/NetworkContext";

const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 36px;
  gap: ${({ theme }) => theme.spacing.md};
`;
const SubTitle = styled(Button3)`
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey.mediumGrey};
`;

export const WrongNetworkModal: React.FC<{
  onclose: () => void;
  switchToChain?: SupportedChainId; // Switch Network Modal
}> = ({ onclose, switchToChain }) => {
  const { network, ammEnum } = useNetwork();
  const { pathname } = useRouter();

  const unsupportedOnManta =
    (pathname.startsWith("/limit-orders") ||
      pathname.startsWith("/recurring-rebalance")) &&
    isManta(network);

  const unsupportedSlipstream =
    ammEnum === "SLIPSTREAM" &&
    (pathname.startsWith("/limit-orders") || pathname.startsWith("/swap"));

  const title = unsupportedOnManta
    ? "Scheduled automations are currently not supported on Manta Network due to lack of OpenZeppelin support."
    : "Feature not supported for Aerodrome and Velodrome yet.";

  const isUnsupported = unsupportedOnManta || unsupportedSlipstream;

  return (
    <>
      <WrongNetworkContent
        isSwitchChain={!!switchToChain}
        networkName={switchToChain ?? network}
        content={isUnsupported ? <SubTitle>{title}</SubTitle> : undefined}
        isUnsupported={isUnsupported}
      />
      <ButtonWrapper>
        <Button
          width="448px"
          variant="contained"
          color="secondary"
          size="lg"
          onClick={onclose}
        >
          Switch Network
        </Button>
      </ButtonWrapper>
    </>
  );
};

import { useNetwork } from "@/components/NetworkContext/NetworkContext";
import useSWR from "swr/immutable";
import { Address, PublicClient } from "viem";
import { useAccount } from "wagmi";

export function useIsWalletContract() {
  const { isConnected, address } = useAccount();
  const { isChainSupported, publicClient } = useNetwork();
  const showFetch = isConnected && isChainSupported;

  return useSWR(showFetch ? `isWalletContract-${address}` : null, () =>
    isWalletContract(address, publicClient)
  );
}

async function isWalletContract(address: Address, publicClient: PublicClient) {
  if (!address) {
    return undefined;
  }
  const bytecode = await publicClient.getBytecode({
    address,
  });
  return bytecode !== undefined; // it is a contract address if it has bytecode
}

import { ConnectModal } from "@/components/layout/hooks";
import { useEventCallback } from "@/hooks/useEventCallback";
import { ChatView } from "@aperture/uikit";
import { E_ModalKey, useModal } from "@aperture/uikitv2";
import { Message, experimental_useAssistant as useAssistant } from "ai/react";
import { FormEvent, useEffect, useMemo, useRef } from "react";
import { useAccount } from "wagmi";

const presetQuestions = [
  "What will Aperture’s Intent Based Infrastructure do?",
  "How does a Concentrated Liquidity Market Maker work?",
  "Tell me more about Aperture Solver Network.",
  "How will AI benefit Defi in general?",
];

export const ChatViewWrapper: React.FC<{
  chatId: string;
  isDrawer: boolean;
  showIntro?: boolean;
  threadId?: string;
  initMessages?: Message[];
  onMessagesUpdate?: (
    chatId: string,
    messages: Message[],
    threadId: string
  ) => void;
}> = ({
  chatId,
  showIntro,
  isDrawer,
  threadId: _threadId,
  onMessagesUpdate = () => {},
  initMessages = [],
}) => {
  const initMessagesRef = useRef<Message[]>(initMessages);
  const handleUpdateMessages = useEventCallback(onMessagesUpdate);
  const { address } = useAccount();
  const { setModalState } = useModal();

  const { status, messages, input, setInput, submitMessage, threadId } =
    useAssistant({
      api: "/api/assistant",
      ...(_threadId && {
        threadId: _threadId,
      }),
    });

  const messageList = useMemo(
    () => [...initMessagesRef.current].concat(messages),
    [messages]
  );

  useEffect(() => {
    if (messages.length > 0) {
      handleUpdateMessages(chatId, messageList, threadId);
    }
  }, [chatId, handleUpdateMessages, messageList, messages, threadId]);

  const handleSubmit = useEventCallback((e: FormEvent<HTMLFormElement>) => {
    if (!address) {
      setModalState(E_ModalKey.CONNECT_MODAL, true);
      return;
    }
    submitMessage(e);
  });

  return (
    <>
      <ChatView
        showIntro={showIntro}
        address={address}
        isDrawer={isDrawer}
        messages={messageList}
        input={input}
        setInput={setInput}
        onSubmit={handleSubmit}
        presetQuestions={presetQuestions}
        isLoading={status !== "awaiting_message"}
      />
      <ConnectModal />
    </>
  );
};

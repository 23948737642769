import styled from "styled-components";
import { CommonLgText, T1Regular } from "../Typography";

export const StrategyDetailBox = styled.div<{ selected: Boolean; sm: Boolean }>`
  position: relative;
  display: flex;
  flex-direction: ${({ sm }) => (sm ? "column" : "row")};
  gap: ${({ theme, sm }) => (sm ? theme.spacing.lg : theme.spacing["3xl"])};
  padding: 16px;
  border-radius: ${({ theme }) => theme.radii.md};
  background-color: ${({ theme, selected }) =>
    selected
      ? theme.colors.global.background.BG3
      : theme.colors.global.background.BG1};
  border: 1px solid ${({ theme, selected }) => theme.colors.global.line.Line1};
  transition: all ${({ theme }) => theme.transitions.default} ease;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.global.background.BG3};
  }
`;
export const Box = styled.div<{ minWidth: number; drawerOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
  min-width: ${({ minWidth }) => `${minWidth}px`};
  min-height: 140px;
  width: 100%;
`;
export const Wrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
  align-items: center;
  flex-wrap: wrap;
  svg {
    fill: ${({ theme }) => theme.colors.global.primary};
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
`;
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const S2 = styled(T1Regular)`
  vertical-align: middle;
`;
export const S3 = styled(CommonLgText)<{ value?: boolean }>`
  color: ${({ value, theme }) =>
    value ? theme.colors.global.text.T1 : theme.colors.global.text.T3};
`;
export const DividerHorizontal = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.global.line.Line1};
`;
export const HR = styled.hr`
  width: 100%;
  margin: 0;
  border-bottom: none;
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
`;
export const Divider = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
`;
export const MinMaxWrapper = styled(T1Regular)`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
`;

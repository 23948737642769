import { createContext, useContext, useState } from "react";

const Context = createContext({
  width: "100vw",
  height: "100vh",
  updateWidth: (newWidth: string) => {},
  updateHeight: (newHeight: string) => {},
});

export const ResizeProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [width, setWidth] = useState("100vw");
  const [height, setHeight] = useState("100vh");

  const updateWidth = (newWidth: string) => {
    setWidth(newWidth);
  };

  const updateHeight = (newHeight: string) => {
    setHeight(newHeight);
  };

  return (
    <Context.Provider value={{ width, height, updateWidth, updateHeight }}>
      {children}
    </Context.Provider>
  );
};

export const useResize = () => {
  return useContext(Context);
};

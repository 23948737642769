export enum OpenPositionQueryKey {
  TOKEN_A = "tokenA",
  TOKEN_B = "tokenB",
  FEE_TIER = "feeTier",
  TICK_LOWER = "tickLower",
  TICK_UPPER = "tickUpper",
  ZAP_IN = "zapIn",
  CALC_NETWORK = "calc_network",
  CALC_DEX = "calc_dex",
}

export enum CommonQueryKey {
  CHAIN_ID = "chainId",
  AMM = "amm",
  STRATEGY_ID = "strategyId",
  PID = "pid",
  VAULT_ADDRESS = "address",
}

export const replaceBrowserHistory = (
  key: string,
  value?: string | number | null
) => {
  const url = new URL(window.location.href);
  const newUrl = new URL(window.location.href);
  if (!value) {
    newUrl.searchParams.delete(key);
  } else {
    newUrl.searchParams.set(key, String(value));
  }
  if (newUrl.toString() !== url.toString()) {
    window.history.replaceState({}, "", newUrl);
  }
};

export const getBrowserHistory = (key: string) => {
  const url = new URL(window.location.href);
  return url.searchParams.get(key);
};

export const CampaignIntentCodeQueryKey = "intentCode";

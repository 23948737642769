import { useEventCallback } from "@/hooks/useEventCallback";
import { IPRestriction } from "@/hooks/useUserIPRestriction";
import { getNetworkId } from "@/utils/networkHelper";
import {
  BasicInfo,
  ConnectModal as ConnectModalContent,
  ConnectionType,
  IpRestrictContent,
  SupportedChainId,
  restrictedCountries,
} from "@aperture/uikit";
import { E_ModalKey, Modal, useModal } from "@aperture/uikitv2";
import { useRouter } from "next/router";
import { useRef } from "react";
import { useConnect } from "wagmi";
import { useConnectStore, useSolverRoutingStore } from "../GlobalStore";
import { OrderRoutingModal as OrderRoutingContent } from "../Modal/OrderRoutingModal";
import { useNetwork } from "../NetworkContext/NetworkContext";
import { useSnackbarV2 } from "../SnackbarContext/SnackbarContextV2";

export const ConnectModal = () => {
  const { addSnackbar } = useSnackbarV2();
  const { amm, network, setNetwork } = useNetwork();
  const { connectType } = useConnectStore();
  const { connect, connectors } = useConnect();
  const { state, setModalState } = useModal();
  const { isOpen } = state.CONNECT_MODAL;

  return (
    <Modal
      isModalOpen={isOpen}
      onClose={() => setModalState(E_ModalKey.CONNECT_MODAL, false)}
    >
      <ConnectModalContent
        {...{
          defaultAMM: amm,
          defaultChain: network,
          currentConnectType: connectType,
          chainSelect: (amm: BasicInfo, chain: SupportedChainId) =>
            setNetwork(amm, chain),
          walletSelect: (chain: SupportedChainId, walletId: ConnectionType) => {
            const connector = connectors.find(
              (connector) => connector.id === walletId
            );
            if (connector) {
              connect(
                { chainId: getNetworkId(chain), connector },
                {
                  onSuccess: () =>
                    setModalState(E_ModalKey.CONNECT_MODAL, false),
                  onError: (error, { chainId }) => {
                    console.log(
                      `Failed to connect chain ${chainId}`,
                      error.message
                    );
                    addSnackbar({
                      info: "Your connect network request has failed",
                      status: "failed",
                    });
                  },
                }
              );
            }
          },
        }}
      />
    </Modal>
  );
};

export const useToggleIPRestrictModal = () => {
  const { setModalState } = useModal();

  const timerRef = useRef(null);
  const toggleIPRestrictionModal = useEventCallback(
    (userIpInfo: IPRestriction) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      if (!!userIpInfo && userIpInfo?.isRestricted) {
        // open modal after 500ms to avoid flickering
        timerRef.current = setTimeout(() => {
          setModalState(E_ModalKey.IP_RESTRICT_MODAL, true);
        }, 500);
      } else {
        setModalState(E_ModalKey.IP_RESTRICT_MODAL, false);
      }
    }
  );

  return {
    toggleIPRestrictionModal,
  };
};
export const IPRestrictModal = () => {
  const { state, setModalState } = useModal();
  const { isOpen } = state.IP_RESTRICT_MODAL;
  const router = useRouter();

  return (
    <Modal
      closeIcon={false}
      disableBackgroundOnClose={true}
      isModalOpen={isOpen}
      onClose={() => setModalState(E_ModalKey.IP_RESTRICT_MODAL, false)}
    >
      <IpRestrictContent
        {...{
          list: Object.keys(restrictedCountries),
          handleClose: () => {
            router.push("/");
            setModalState(E_ModalKey.IP_RESTRICT_MODAL, false);
          },
        }}
      />
    </Modal>
  );
};

export const OrderRoutingModal = () => {
  const { state, setModalState } = useModal();
  const { isOpen } = state.ORDER_ROUTING_MODAL;
  const { setSelectedSolver } = useSolverRoutingStore();

  const toggleModal = (open: boolean) => {
    setModalState(E_ModalKey.ORDER_ROUTING_MODAL, open);
  };

  return (
    <Modal isModalOpen={isOpen} onClose={() => toggleModal(false)}>
      <OrderRoutingContent
        onSelect={(idx: number) => {
          setSelectedSolver(idx);
          toggleModal(false);
        }}
        onClose={() => toggleModal(false)}
      />
    </Modal>
  );
};

import usePrevious from "@ui/hooks/usePrevious";
import { BrushBehavior, brushX, D3BrushEvent, ScaleLinear, select } from "d3";
import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  brushHandleAccentPath,
  brushHandleLinePath,
  brushHandlePath,
  OffScreenHandle,
} from "./svg";
import { BrushProps } from "./types";

const Handle = styled.path<{ color: string }>`
  cursor: ew-resize;
  pointer-events: none;
  stroke-width: 2;
  stroke: ${({ color }) => color};
  fill: ${({ color }) => color};
`;
const HandleLine = styled(Handle)`
  stroke-width: 2;
  stroke-dasharray: 4 4;
`;
const HandleAccent = styled.path`
  cursor: ew-resize;
  pointer-events: none;
  stroke-width: 1.5;
  stroke: ${({ theme }) => theme.colors.global.background.BG1};
  fill: ${({ theme }) => theme.colors.global.background.BG1};
`;
const LabelGroup = styled.g<{ visible: boolean }>`
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  transition: opacity 300ms;
`;
const TooltipBackground = styled.rect`
  fill: ${({ theme }) => theme.colors.grey.lightGrey1};
  stroke: ${({ theme }) => theme.colors.grey.grey};
`;
const Tooltip = styled.text`
  text-anchor: middle;
  font-size: 14px;
  fill: ${({ theme }) => theme.colors.primary.greyPurple};
`;

// flips the handles draggers when close to the container edges
const FLIP_HANDLE_THRESHOLD_PX = 20;

// margin to prevent tick snapping from putting the brush off screen
const BRUSH_EXTENT_MARGIN_PX = 2;

/**
 * Returns true if every element in `a` maps to the
 * same pixel coordinate as elements in `b`
 */
const compare = (
  a: [number, number],
  b: [number, number],
  xScale: ScaleLinear<number, number>
): boolean => {
  // normalize pixels to 1 decimals
  const aNorm = a.map((x) => Math.round(xScale(x) * 10));
  const bNorm = b.map((x) => Math.round(xScale(x) * 10));
  return aNorm.every((v, i) => v === bNorm[i]);
};

export const Brush = ({
  id,
  xScale,
  interactive,
  brushLabelValue,
  brushExtent,
  setBrushExtent,
  innerWidth,
  innerHeight,
  westHandleColor,
  eastHandleColor,
  brushMinValue,
  brushBoundary,
}: BrushProps) => {
  const brushRef = useRef<SVGGElement | null>(null);
  const brushBehavior = useRef<BrushBehavior<SVGGElement> | null>(null);

  // only used to drag the handles on brush for performance
  const [localBrushExtent, setLocalBrushExtent] = useState<
    [number, number] | null
  >(brushExtent);
  const [showLabels, setShowLabels] = useState(false);
  const [hovering, setHovering] = useState(false);

  const previousBrushExtent = usePrevious(brushExtent);

  const brushed = useCallback(
    (event: D3BrushEvent<unknown>) => {
      const { type, selection, mode, sourceEvent } = event;

      // if (!selection) {
      //   setLocalBrushExtent(null);
      //   return;
      // }

      // if selection is null, custom the scaled area based on layerX
      const scaled = (
        selection
          ? (selection as [number, number])
          : [sourceEvent?.layerX - 20, sourceEvent?.layerX + 20]
      ).map(xScale.invert) as [number, number];

      if (brushBoundary !== undefined) {
        if (scaled[0] > brushBoundary) {
          // avoid moving left handle beyond the right side of boundry
          scaled[0] = brushBoundary;
          select(brushRef.current)
            .selectAll(".selection")
            .attr("x", xScale(brushBoundary))
            .attr("width", xScale(scaled[1]) - xScale(1));
          select(brushRef.current)
            .selectAll(".handle--w")
            .attr("x", xScale(brushBoundary));
        } else if (scaled[1] < brushBoundary) {
          // avoid moving right handle beyond the left side of boundry
          scaled[1] = brushBoundary;
          select(brushRef.current)
            .selectAll(".selection")
            .attr("x", xScale(scaled[0]))
            .attr("width", xScale(brushBoundary) - xScale(scaled[0]));
          select(brushRef.current)
            .selectAll(".handle--e")
            .attr("x", xScale(scaled[1]));
        }
      }

      // avoid infinite render loop by checking for change
      if (type === "end" && !compare(brushExtent, scaled, xScale)) {
        setBrushExtent(scaled, mode);
      }

      setLocalBrushExtent(scaled);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [xScale, brushExtent, setBrushExtent]
  );

  // keep local and external brush extent in sync
  // i.e. snap to ticks on brush end
  useEffect(() => {
    setLocalBrushExtent(brushExtent);
  }, [brushExtent]);

  // initialize the brush
  useEffect(() => {
    if (!brushRef.current) return;

    brushBehavior.current = brushX<SVGGElement>()
      .extent([
        [
          Math.max(
            0 + BRUSH_EXTENT_MARGIN_PX,
            brushMinValue === undefined ? 0 : xScale(brushMinValue)
          ),
          0,
        ],
        [innerWidth - BRUSH_EXTENT_MARGIN_PX, innerHeight],
      ])
      .handleSize(30)
      .filter(() => interactive)
      .on("brush end", brushed);

    brushBehavior.current(select(brushRef.current));

    if (
      previousBrushExtent &&
      compare(brushExtent, previousBrushExtent, xScale)
    ) {
      select(brushRef.current)
        .transition()
        .call(brushBehavior.current.move as any, brushExtent.map(xScale));
    }

    // brush linear gradient
    select(brushRef.current)
      .selectAll(".selection")
      .attr("stroke", "none")
      .attr("fill-opacity", "0.1")
      .attr("fill", `url(#${id}-gradient-selection)`);

    // hide overlay to avoid clicking function outside selected range
    select(brushRef.current).selectAll(".overlay").attr("display", "none");
  }, [
    brushExtent,
    brushed,
    id,
    innerHeight,
    innerWidth,
    interactive,
    previousBrushExtent,
    xScale,
    brushMinValue,
  ]);

  // respond to xScale changes only
  useEffect(() => {
    if (!brushRef.current || !brushBehavior.current) return;

    brushBehavior.current.move(
      select(brushRef.current) as any,
      brushExtent.map(xScale) as any
    );
  }, [brushExtent, xScale]);

  // show labels when local brush changes
  useEffect(() => {
    setShowLabels(true);
    const timeout = setTimeout(() => setShowLabels(false), 1500);
    return () => clearTimeout(timeout);
  }, [localBrushExtent]);

  // variables to help render the SVGs
  const flipWestHandle =
    localBrushExtent && xScale(localBrushExtent[0]) > FLIP_HANDLE_THRESHOLD_PX;
  const flipEastHandle =
    localBrushExtent &&
    xScale(localBrushExtent[1]) > innerWidth - FLIP_HANDLE_THRESHOLD_PX;

  const showWestArrow =
    localBrushExtent &&
    (xScale(localBrushExtent[0]) < 0 || xScale(localBrushExtent[1]) < 0);
  const showEastArrow =
    localBrushExtent &&
    (xScale(localBrushExtent[0]) > innerWidth ||
      xScale(localBrushExtent[1]) > innerWidth);

  const westHandleInView =
    localBrushExtent &&
    xScale(localBrushExtent[0]) >= 0 &&
    xScale(localBrushExtent[0]) <= innerWidth;
  const eastHandleInView =
    localBrushExtent &&
    xScale(localBrushExtent[1]) >= 0 &&
    xScale(localBrushExtent[1]) <= innerWidth;

  return (
    <>
      <defs>
        <linearGradient
          id={`${id}-gradient-selection`}
          x1="0%"
          y1="100%"
          x2="100%"
          y2="100%"
        >
          <stop stopColor={westHandleColor} />
          <stop stopColor={eastHandleColor} offset="1" />
        </linearGradient>

        {/* clips at exactly the svg area */}
        <clipPath id={`${id}-brush-clip`}>
          <rect x="0" y="0" width={innerWidth} height={innerHeight} />
        </clipPath>
      </defs>

      {/* will host the d3 brush */}
      <g
        ref={brushRef}
        clipPath={`url(#${id}-brush-clip)`}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      />

      {/* custom brush handles */}
      {localBrushExtent && (
        <>
          {/* west handle */}
          {westHandleInView ? (
            <g
              transform={`translate(${Math.max(
                0,
                xScale(localBrushExtent[0])
              )}, 0), scale(${flipWestHandle ? "-1" : "1"}, 1)`}
            >
              <g>
                <Handle
                  color={westHandleColor}
                  d={brushHandlePath(innerHeight)}
                />
                <HandleAccent
                  d={brushHandleAccentPath(innerHeight)}
                  transform={`scale(${flipWestHandle ? "1" : "-1"}, 1)`}
                />
              </g>

              <LabelGroup
                transform={`translate(50,0), scale(${
                  flipWestHandle ? "1" : "-1"
                }, 1)`}
                visible={showLabels || hovering}
              >
                <TooltipBackground
                  y={innerHeight / 2 - 14}
                  x="-30"
                  height="30"
                  width="60"
                  rx="8"
                />
                <Tooltip
                  x="1"
                  y={innerHeight / 2 + 2}
                  transform="scale(-1, 1)"
                  dominantBaseline="middle"
                >
                  {brushLabelValue("w", localBrushExtent[0])}
                </Tooltip>
              </LabelGroup>
            </g>
          ) : null}

          {/* east handle */}
          {eastHandleInView ? (
            <g
              transform={`translate(${xScale(localBrushExtent[1])}, 0), scale(${
                flipEastHandle ? "-1" : "1"
              }, 1)`}
            >
              <g>
                <Handle
                  color={eastHandleColor}
                  d={brushHandlePath(innerHeight)}
                />
                <HandleAccent
                  d={brushHandleAccentPath(innerHeight)}
                  transform={`scale(${flipEastHandle ? "-1" : "1"}, 1)`}
                />
              </g>

              <LabelGroup
                transform={`translate(50,0), scale(${
                  flipEastHandle ? "-1" : "1"
                }, 1)`}
                visible={showLabels || hovering}
              >
                <TooltipBackground
                  y={innerHeight / 2 - 14}
                  x="-30"
                  height="30"
                  width="60"
                  rx="8"
                />
                <Tooltip
                  x="1"
                  y={innerHeight / 2 + 2}
                  dominantBaseline="middle"
                >
                  {brushLabelValue("e", localBrushExtent[1])}
                </Tooltip>
              </LabelGroup>
            </g>
          ) : null}

          {showWestArrow && <OffScreenHandle color={westHandleColor} />}

          {showEastArrow && (
            <g transform={`translate(${innerWidth}, 0) scale(-1, 1)`}>
              <OffScreenHandle color={eastHandleColor} />
            </g>
          )}
        </>
      )}
    </>
  );
};

export const CurrentBrush = ({
  xScale,
  brushExtent,
  innerWidth,
  innerHeight,
  westHandleColor,
  eastHandleColor,
}: {
  xScale: ScaleLinear<number, number>;
  brushExtent: [number, number];
  innerWidth: number;
  innerHeight: number;
  westHandleColor: string;
  eastHandleColor: string;
}) => {
  const flipWestHandle = xScale(brushExtent[0]) > FLIP_HANDLE_THRESHOLD_PX;
  const flipEastHandle =
    xScale(brushExtent[1]) > innerWidth - FLIP_HANDLE_THRESHOLD_PX;

  const westHandleInView =
    xScale(brushExtent[0]) >= 0 && xScale(brushExtent[0]) <= innerWidth;
  const eastHandleInView =
    xScale(brushExtent[1]) >= 0 && xScale(brushExtent[1]) <= innerWidth;

  return (
    <>
      {westHandleInView ? (
        <g
          transform={`translate(${Math.max(
            0,
            xScale(brushExtent[0])
          )}, 0), scale(${flipWestHandle ? "-1" : "1"}, 1)`}
        >
          <g>
            <HandleLine
              color={westHandleColor}
              d={brushHandleLinePath(innerHeight)}
            />
            {/* <Handle
              color={westHandleColor}
              d={brushHandleCirclePath(innerHeight)}
            />
            <HandleAccent
              d={brushHandleSmallAccentPath(innerHeight)}
              transform={`scale(${flipWestHandle ? "1" : "-1"}, 1)`}
            /> */}
          </g>
        </g>
      ) : null}

      {eastHandleInView ? (
        <g
          transform={`translate(${xScale(brushExtent[1])}, 0), scale(${
            flipEastHandle ? "-1" : "1"
          }, 1)`}
        >
          <g>
            <HandleLine
              color={westHandleColor}
              d={brushHandleLinePath(innerHeight)}
            />
            {/* <Handle
              color={eastHandleColor}
              d={brushHandleCirclePath(innerHeight)}
            />
            <HandleAccent
              d={brushHandleSmallAccentPath(innerHeight)}
              transform={`scale(${flipEastHandle ? "-1" : "1"}, 1)`}
            /> */}
          </g>
        </g>
      ) : null}
    </>
  );
};

import { Flex, FlexColumn } from "@ui/common";
import { formatTokenAmount } from "@ui/utils";
import React from "react";
import styled from "styled-components";
import { Body1, Body2, Subtitle0 } from "../Typography";
import { StakeStatsProps } from "./types";

const Title = styled(Body1)`
  line-height: 16px;
  font-family: "Roboto Flex";
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const Container = styled(FlexColumn)`
  width: 278px;
  padding: 16px;
  gap: ${({ theme }) => theme.spacing.xl};
  border-radius: ${({ theme }) => theme.radii.xl};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
`;
const InfoTitle = styled(Body1)`
  line-height: 16px;
  font-family: "Roboto Flex";
  color: ${({ theme }) => theme.colors.global.text.T2};
`;
const InfoValueWrapper = styled(Flex)`
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
`;
const InfoValue = styled(Subtitle0)`
  font-size: 20px;
  font-family: "Roboto Flex";
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const InfoUnit = styled(Body2)`
  line-height: 14px;
  font-family: "Roboto Flex";
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const HR = styled.hr`
  width: 100%;
  margin: 0;
  border-bottom: none;
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line2};
`;

export const StakeStats: React.FC<StakeStatsProps> = ({
  token,
  averageAmount,
  totalAmount,
  percentage,
}) => {
  return (
    <FlexColumn gap="md">
      <Title>Pool Stats</Title>
      <Container>
        <FlexColumn gap="md">
          <InfoTitle>Average Staked Amount</InfoTitle>
          <InfoValueWrapper>
            <Flex gap="sm">
              <token.Icon width={20} height={20} />
              <InfoValue>{formatTokenAmount(averageAmount)}</InfoValue>
            </Flex>
            <InfoUnit>{token.ticker}</InfoUnit>
          </InfoValueWrapper>
        </FlexColumn>
        <HR />
        <FlexColumn gap="md">
          <InfoTitle>Total Staked Token</InfoTitle>
          <InfoValueWrapper>
            <Flex gap="sm">
              <token.Icon width={20} height={20} />
              <InfoValue>{formatTokenAmount(totalAmount)}</InfoValue>
            </Flex>
            <InfoUnit>{token.ticker}</InfoUnit>
          </InfoValueWrapper>
        </FlexColumn>
        <HR />
        <FlexColumn gap="md">
          <InfoTitle>% Of Liquid Supply</InfoTitle>
          <InfoValue>{percentage}</InfoValue>
        </FlexColumn>
        {token?.price && (
          <>
            <HR />
            <FlexColumn gap="md">
              <InfoTitle>{token.ticker} Token Price</InfoTitle>
              <InfoValue>{`${token.price} USD`}</InfoValue>
            </FlexColumn>
          </>
        )}
      </Container>
    </FlexColumn>
  );
};

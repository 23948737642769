import {
  AerodromeIcon,
  ApertureLogo2,
  BinanceIcon,
  BybitIcon,
  CoinbaseIcon,
  HaloIcon,
  MetamaskIcon,
  NetworkIcons,
  OkxIcon,
  PancakeIcon,
  RabbyIcon,
  SafeIcon,
  UniswapIcon,
  VelodromeIcon,
  WalletConnectIcon,
} from "@aperture/assetkit";
import { FeeAmount } from "@aperture_finance/uniswap-v3-sdk";

export enum ConnectionType {
  METAMASK = "io.metamask",
  OKX_WALLET = "com.okex.wallet",
  COINBASE_WALLET = "coinbaseWalletSDK",
  WALLET_CONNECT = "walletConnect",
  RABBY = "io.rabby",
  HALO_WALLET = "haloWallet",
  BYBIT_WALLET = "com.bybit",
  BINANCE_WALLET = "BinanceW3WSDK",
  SAFE = "safe",
  NONE = "none",
}

export const connectionName = {
  [ConnectionType.METAMASK]: "MetaMask",
  [ConnectionType.OKX_WALLET]: "OKX Wallet",
  [ConnectionType.COINBASE_WALLET]: "Coinbase Wallet",
  [ConnectionType.WALLET_CONNECT]: "WalletConnect",
  [ConnectionType.RABBY]: "Rabby",
  [ConnectionType.HALO_WALLET]: "Halo Wallet",
  [ConnectionType.BYBIT_WALLET]: "Bybit Wallet",
  [ConnectionType.BINANCE_WALLET]: "Binance Web3 Wallet",
  [ConnectionType.SAFE]: "Safe Wallet",
  [ConnectionType.NONE]: "None",
};

export enum WalletClient {
  METAMASK = "METAMASK",
  WALLETCONNECT = "WALLETCONNECT",
  OKX = "OKX",
  COINBASE = "COINBASE",
  RABBY = "RABBY",
  HALO = "HALO",
  BYBIT = "BYBIT",
  BINANCE = "BINANCE",
  SAFE = "SAFE",
}

export const walletClientName = {
  [ConnectionType.METAMASK]: WalletClient.METAMASK,
  [ConnectionType.OKX_WALLET]: WalletClient.OKX,
  [ConnectionType.COINBASE_WALLET]: WalletClient.COINBASE,
  [ConnectionType.WALLET_CONNECT]: WalletClient.WALLETCONNECT,
  [ConnectionType.RABBY]: WalletClient.RABBY,
  [ConnectionType.HALO_WALLET]: WalletClient.HALO,
  [ConnectionType.BYBIT_WALLET]: WalletClient.BYBIT,
  [ConnectionType.BINANCE_WALLET]: WalletClient.BINANCE,
  [ConnectionType.SAFE]: WalletClient.SAFE,
  [ConnectionType.NONE]: "None",
};

export enum WalletConnectSubtype {
  HALO = "HALO",
  SAFE = "SAFE",
  METAMASK = "METAMASK",
  BYBIT = "BYBIT",
  BINANCE = "BINANCE",
  UNKNOWN = "UNKNOWN",
}

export enum SupportedChainId {
  ETHEREUM = "ETHEREUM",
  ARBITRUM = "ARBITRUM",
  MANTA = "MANTA PACIFIC",
  OPTIMISM = "OPTIMISM",
  POLYGON = "POLYGON",
  BASE = "BASE",
  BNB = "BNB",
  AVALANCHE = "AVALANCHE",
  SCROLL = "SCROLL",
}
export interface BasicInfo {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  name: string;
}

export interface NetworkInfo extends BasicInfo {
  id: SupportedChainId;
  amm: BasicInfo[];
}

export interface ConnectInfo extends BasicInfo {
  id: ConnectionType;
}

export type AmmEnum = "UNISWAP_V3" | "PANCAKESWAP_V3" | "SLIPSTREAM";
export enum E_AMM {
  Uniswap = "Uniswap",
  PancakeSwap = "PancakeSwap",
  ApertureSwap = "ApertureSwap",
  Aerodrome = "Aerodrome",
  Velodrome = "Velodrome",
}

export const AmmInfo: Record<E_AMM, BasicInfo> = {
  [E_AMM.Uniswap]: { name: E_AMM.Uniswap, Icon: UniswapIcon },
  [E_AMM.PancakeSwap]: { name: E_AMM.PancakeSwap, Icon: PancakeIcon },
  [E_AMM.ApertureSwap]: { name: E_AMM.ApertureSwap, Icon: ApertureLogo2 },
  [E_AMM.Aerodrome]: { name: E_AMM.Aerodrome, Icon: AerodromeIcon },
  [E_AMM.Velodrome]: { name: E_AMM.Velodrome, Icon: VelodromeIcon },
};

export const ChainList: NetworkInfo[] = [
  {
    id: SupportedChainId.ETHEREUM,
    name: "Ethereum",
    Icon: NetworkIcons.EthereumIcon,
    amm: [AmmInfo.Uniswap, AmmInfo.PancakeSwap],
  },
  {
    id: SupportedChainId.ARBITRUM,
    name: "Arbitrum",
    Icon: NetworkIcons.ArbitrumIcon,
    amm: [AmmInfo.Uniswap],
  },
  {
    id: SupportedChainId.OPTIMISM,
    name: "Optimism",
    Icon: NetworkIcons.OptimismIcon,
    amm: [AmmInfo.Uniswap, AmmInfo.Velodrome],
  },
  {
    id: SupportedChainId.POLYGON,
    name: "Polygon",
    Icon: NetworkIcons.PolygonIcon,
    amm: [AmmInfo.Uniswap],
  },
  {
    id: SupportedChainId.BASE,
    name: "Base",
    Icon: NetworkIcons.BaseIcon,
    amm: [AmmInfo.Uniswap, AmmInfo.Aerodrome],
  },
  {
    id: SupportedChainId.BNB,
    name: "BNB Chain",
    Icon: NetworkIcons.BscIcon,
    amm: [AmmInfo.Uniswap, AmmInfo.PancakeSwap],
  },
  {
    id: SupportedChainId.AVALANCHE,
    name: "Avalanche",
    Icon: NetworkIcons.AvalancheIcon,
    amm: [AmmInfo.Uniswap],
  },
  {
    id: SupportedChainId.SCROLL,
    name: "Scroll",
    Icon: NetworkIcons.ScrollIcon,
    amm: [AmmInfo.Uniswap],
  },
  {
    id: SupportedChainId.MANTA,
    name: "Manta Pacific",
    Icon: NetworkIcons.MantaIcon,
    amm: [AmmInfo.ApertureSwap],
  },
];

export const WalletList: ConnectInfo[] = [
  {
    id: ConnectionType.METAMASK,
    name: "MetaMask",
    Icon: MetamaskIcon,
  },
  {
    id: ConnectionType.OKX_WALLET,
    name: "OKX Wallet",
    Icon: OkxIcon,
  },
  {
    id: ConnectionType.WALLET_CONNECT,
    name: "WalletConnect",
    Icon: WalletConnectIcon,
  },
  {
    id: ConnectionType.COINBASE_WALLET,
    name: "Coinbase Wallet",
    Icon: CoinbaseIcon,
  },
  {
    id: ConnectionType.RABBY,
    name: "Rabby Wallet",
    Icon: RabbyIcon,
  },
  {
    id: ConnectionType.HALO_WALLET,
    name: "Halo Wallet",
    Icon: HaloIcon,
  },
  {
    id: ConnectionType.BYBIT_WALLET,
    name: "Bybit Wallet",
    Icon: BybitIcon,
  },
  {
    id: ConnectionType.BINANCE_WALLET,
    name: "Binance Web3 Wallet",
    Icon: BinanceIcon,
  },
  {
    id: ConnectionType.SAFE,
    name: "Safe Wallet",
    Icon: SafeIcon,
  },
];

export const SupportedFeeAmount: Record<AmmEnum, FeeAmount[]> = {
  UNISWAP_V3: [
    FeeAmount.LOWEST,
    FeeAmount.LOW,
    FeeAmount.MEDIUM,
    FeeAmount.HIGH,
  ],
  PANCAKESWAP_V3: [
    FeeAmount.LOWEST,
    FeeAmount.LOW,
    FeeAmount.PCS_V3_MEDIUM,
    FeeAmount.HIGH,
  ],
  SLIPSTREAM: [],
};

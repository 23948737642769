import { ApertureSupportedChainId } from "@aperture_finance/uniswap-v3-automation-sdk";
import { useSessionStorageState } from "ahooks";
import { useFlags } from "flagsmith/react";
import { IFlagsmithFeature } from "flagsmith/types";
import { useSearchParams } from "next/navigation";
import { useMemo, useRef } from "react";

export const enum E_FLAGS {
  TEST_FLAG = "test_flag",
  RPC_SETTING = "rpcs", // long term setting
  OKX_QUOTE_SETTING = "okx_quote_tokens", // long term setting
  MMAS = "mmas",
  REMOVE_LP_ZAP_OUT = "remove_lp_zap_out",
}

export const useIsTestFlagEnabled = () => {
  const flag = useFlags([E_FLAGS.TEST_FLAG]).test_flag.enabled;
  const search = useSearchParams();
  return (
    search.get("test_flag") !== "0" && (flag || search.get("test_flag") === "1")
  );
};

export type RpcSettingsType = Partial<
  Record<ApertureSupportedChainId, string[]>
>;

export type OkxQuoteTokensType = Partial<
  Record<ApertureSupportedChainId, string[]>
>;

export function useRpcSettings(): RpcSettingsType {
  const flag = useFlags([E_FLAGS.RPC_SETTING]).rpcs;
  const flagRef = useRef(flag); // we only need rpc config from serverState, useRef will only consume once

  return useMemo(() => {
    if (!flagRef.current.enabled) {
      return undefined;
    }
    try {
      return JSON.parse(flagRef.current.value as string) as RpcSettingsType;
    } catch (e) {
      console.error(e);
      return {};
    }
  }, []);
}

export function useOkxQuoteTokens(): RpcSettingsType {
  const flag = useFlags([E_FLAGS.OKX_QUOTE_SETTING]).okx_quote_tokens;
  const flagRef = useRef(flag); // we only need okx quote tokens from serverState, useRef will only consume once

  return useMemo(() => {
    if (!flagRef.current.enabled) {
      return undefined;
    }
    try {
      return JSON.parse(flagRef.current.value as string) as OkxQuoteTokensType;
    } catch (e) {
      console.error(e);
      return {};
    }
  }, []);
}

export const useIsMMaSEnabled = () => {
  const search = useSearchParams();
  const flagStore = useFlagStore(E_FLAGS.MMAS);
  return (
    search.get(E_FLAGS.MMAS) !== "0" &&
    (flagStore.enabled || search.get(E_FLAGS.MMAS) === "1")
  );
};

export const useIsZapOutEnabled = () => {
  const search = useSearchParams();
  const flagStore = useFlagStore(E_FLAGS.REMOVE_LP_ZAP_OUT);
  return (
    search.get(E_FLAGS.REMOVE_LP_ZAP_OUT) !== "0" &&
    (flagStore.enabled || search.get(E_FLAGS.REMOVE_LP_ZAP_OUT) === "1")
  );
};

const useFlagStore = (flag: E_FLAGS) => {
  const f = useFlags([flag])[flag];

  const [flagStore, setFlagStore] = useSessionStorageState<IFlagsmithFeature>(
    "flag_" + flag,
    {
      defaultValue: f,
    }
  );

  if (!flagStore.enabled && f.enabled) {
    setFlagStore(f);
  }

  return flagStore;
};

import styled from "styled-components";
import { LayoutProps, SpaceProps, compose, layout, space } from "styled-system";
import { Button3 } from "../Typography";

type ContentUIProps = LayoutProps & SpaceProps;

export const ContentWrapper = styled.div<ContentUIProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 456px;
  padding-top: 50px;
  gap: ${({ theme }) => theme.spacing["6xl"]};
  ${compose(layout, space)}
`;
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.xl};
`;

export const Title = styled.span`
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const SubTitle = styled(Button3)`
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey.mediumGrey};
`;

import { CSSProperties } from "react";

export const axisWidth = 427;
export const spotWidth = 9;

export const normalizeValue = (
  value: number,
  minSpot: number,
  maxSpot: number
) => {
  const percentage = ((value - minSpot) / (maxSpot - minSpot)) * 100;
  const spotWidthPercentage = (spotWidth / axisWidth) * 100;
  const halfSpotWidthPercentage = spotWidthPercentage / 2;
  return (
    (percentage * (100 - spotWidthPercentage)) / 100 + halfSpotWidthPercentage
  );
};

export const getPosition = (
  value: number,
  minSpot: number,
  maxSpot: number
) => {
  return (normalizeValue(value, minSpot, maxSpot) * axisWidth) / 100;
};

export const getEdge = (marker: number, minSpot: number, maxSpot: number) => {
  const distanceFromMin = normalizeValue(marker, minSpot, maxSpot);
  const distanceFromMax = 100 - distanceFromMin;
  return distanceFromMin < 20 ? "L" : distanceFromMax < 20 ? "R" : undefined;
};

const customRound = (num: number) => {
  if (num === 0) return 10;
  let magnitude = Math.floor(Math.log10(Math.abs(num)));
  let lowerThreshold = Math.pow(10, magnitude);
  return parseFloat((5 * lowerThreshold).toFixed(12));
};

export const generateSpots = (below: number, above: number) => {
  const maxMarker = Math.max(Math.abs(below), Math.abs(above));
  const space =
    maxMarker < 1 ? customRound(maxMarker) : maxMarker < 50 ? 10 : 30;
  const range =
    maxMarker < 1 ? space * 2 : Math.ceil((maxMarker + space) / 10) * 10;
  const spots = [-range, -range / 2, 0, range / 2, range];
  const roundTo = range / 2 < 100 ? (range / 2 < 50 ? 10 : 50) : 100;
  const formattedSpots =
    maxMarker < 1
      ? spots
      : spots.map((spot) =>
          spot !== 0
            ? Math.round(Math.abs(spot) / roundTo) *
              roundTo *
              (spot / Math.abs(spot))
            : 0
        );
  return formattedSpots;
};

export const parseMarker = (
  value: string | undefined,
  isRatio: boolean,
  isNegative: boolean = false
) => {
  if (!value) return undefined;
  const parsed = Math.abs(parseFloat(value));
  if (isNaN(parsed)) return undefined;
  if (isRatio && (parsed < 0 || parsed > 100)) return undefined;
  return isNegative ? parsed * -1 : parsed;
};

export const getLabelWidth = (text: string) => {
  const styles: CSSProperties = {
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "14px",
    padding: "4px",
    visibility: "hidden",
  };
  const label = document.createElement("span");
  label.textContent = text;
  Object.assign(label.style, styles);

  document.body.appendChild(label);
  const width = label.getBoundingClientRect().width;
  document.body.removeChild(label);
  return width;
};

export const getEdgePosition = (
  edge: "L" | "R" | undefined,
  pos: number,
  width: number,
  isBelow: Boolean
) => {
  const offset = 9;
  let belowRightEdge, aboveLeftEdge;
  switch (edge) {
    case "R":
      belowRightEdge = pos + offset;
      aboveLeftEdge = pos - width + offset;
      return isBelow ? belowRightEdge : aboveLeftEdge;
    case "L":
      belowRightEdge = pos + width - offset;
      aboveLeftEdge = pos - offset;
      return isBelow ? belowRightEdge : aboveLeftEdge;
    default:
      belowRightEdge = pos + offset;
      aboveLeftEdge = pos - offset;
      return isBelow ? belowRightEdge : aboveLeftEdge;
  }
};

export const checkOverlap = (
  belowMarker: number,
  aboveMarker: number,
  minSpot: number,
  maxSpot: number
) => {
  const belowPos = getPosition(belowMarker, minSpot, maxSpot);
  const abovePos = getPosition(aboveMarker, minSpot, maxSpot);
  const belowlabelWidth = getLabelWidth(`${belowMarker}%`);
  const abovelabelWidth = getLabelWidth(`${aboveMarker}%`);

  const bEdge = getEdge(belowMarker, minSpot, maxSpot);
  const aEdge = getEdge(aboveMarker, minSpot, maxSpot);

  const belowRightEdge = getEdgePosition(
    bEdge,
    belowPos,
    belowlabelWidth,
    true
  );
  const aboveLeftEdge = getEdgePosition(
    aEdge,
    abovePos,
    abovelabelWidth,
    false
  );
  const difference = belowRightEdge - aboveLeftEdge;
  return { difference, aEdge };
};

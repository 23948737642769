import { ApertureLogo } from "@aperture/assetkit";
import Link from "next/link";
import React from "react";
import styled from "styled-components";
import { LogoTag } from "../../Typography";
import { NavLogoProps } from "./types";

const LogoLink = styled(Link)`
  display: flex;
`;
const TagWrapper = styled.div`
  margin-left: 96px;
  margin-top: -8px;
`;
const Tag = styled(LogoTag)`
  background: transparent;
  padding: 4px 8px;
  line-height: 11px;
  background: ${({ theme }) => theme.colors.transparent.transparent};
  border-radius: ${({ theme }) => theme.radii.sm};
  color: ${({ theme }) => theme.colors.grey.lightGrey3};
  outline: 1px solid ${({ theme }) => theme.colors.grey.lightGrey3};
`;

export const NavLogo: React.FC<NavLogoProps> = ({ tag }) => {
  return (
    <>
      <LogoLink href="/">
        <ApertureLogo width={132} height={32} />
      </LogoLink>
      {tag && (
        <TagWrapper>
          <Tag>{tag}</Tag>
        </TagWrapper>
      )}
    </>
  );
};

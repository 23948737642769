import { variant } from "styled-system";
import { ThemedProps, colors, sizes, variants } from "./types";

export const iconSizeVariants = (props: ThemedProps) =>
  variant({
    prop: "size",
    variants: {
      [sizes.xl]: {
        height: "22px",
      },
      [sizes.lg]: {
        height: "20px",
      },
      [sizes.md]: {
        height: "20px",
      },
      [sizes.sm]: {
        height: "18px",
      },
      [sizes.xs]: {
        height: "16px",
      },
    },
  });

export const buttonVariants = (props: ThemedProps) =>
  variant({
    prop: "variant",
    variants: {
      [variants.contained]: {
        color: () => {
          if (props.color === colors.default)
            return props.theme.colors.global.text.T1;
          return props.theme.colors.global.textAlt.T1;
        },
        background: () => {
          switch (props.color) {
            case colors.primary:
              return props.theme.isDropdownOpen
                ? props.theme.colors.primary.darkPurple
                : props.theme.colors.global.primary;
            case colors.secondary:
              return props.theme.isDropdownOpen
                ? props.theme.colors.grey.lightGrey3
                : props.theme.colors.global.primary;
            case colors.gradient:
              return props.theme.isDropdownOpen
                ? props.theme.gradients.primary.dark
                : props.theme.gradients.primary.medium;
            case colors.black:
              return props.theme.colors.grey.darkGrey2;
            case colors.default:
              return props.theme.colors.global.background.BG3;
          }
        },
        opacity:
          props.theme.isDropdownOpen && props.color === colors.black
            ? "0.7"
            : "unset",
        "&:hover": {
          background: () => {
            switch (props.color) {
              case colors.primary:
              case colors.secondary:
                return props.theme.colors.global.primaryHover;
              case colors.gradient:
                return props.theme.gradients.primary.light;
              case colors.default:
                return `${props.theme.colors.global.background.BG1}CC`;
            }
          },
          opacity: props.color === colors.black ? "0.7" : "unset",
        },
        "&:active": {
          background: () => {
            switch (props.color) {
              case colors.primary:
              case colors.secondary:
                return props.theme.colors.global.primaryHover;
              case colors.gradient:
                return props.theme.gradients.primary.dark;
              case colors.default:
                return `${props.theme.colors.global.background.BG3}CC`;
            }
          },
          opacity: props.color === colors.black ? "0.7" : "unset",
        },
      },
      [variants.outlined]: {
        border: "1.5px solid",
        borderColor: () => {
          if (props.color === colors.secondary)
            return props.theme.colors.global.primary;
          return props.theme.colors.primary.darkPurple;
        },
        color: () => {
          if (props.color === colors.secondary)
            return props.theme.colors.global.primary;
          return props.theme.colors.primary.darkPurple;
        },
        background: props.theme.isDropdownOpen
          ? props.theme.colors.transparent.grey1
          : props.theme.colors.transparent.transparent,
        "&:hover": {
          borderColor: () => {
            if (props.color === colors.secondary)
              return props.theme.colors.global.primary;
            return props.theme.colors.primary.darkPurple;
          },
          color: () => {
            if (props.color === colors.secondary)
              return props.theme.colors.global.textAlt.T1;
            return props.theme.colors.primary.darkPurple;
          },
          background: props.theme.colors.global.primaryHover,
        },
        "&:active": {
          background: props.theme.colors.global.primaryHover,
          opacity: "0.7",
        },
      },
      [variants.text]: {
        textDecoration: "underline",
        boxShadow: "none !important",
        padding: "0 !important",
        minWidth: 0,
        background: props.theme.colors.transparent.transparent,
        color: props.theme.isDropdownOpen
          ? props.theme.colors.primary.darkPurple
          : props.theme.colors.primary.greyPurple,
        "&:hover": {
          color: props.theme.colors.primary.purple,
        },
        "&:active": {
          color: props.theme.colors.primary.darkPurple,
        },
      },
    },
  });

export const buttonSizeVariants = (props: ThemedProps) =>
  variant({
    prop: "size",
    variants: {
      [sizes.xl]: {
        lineHeight: "18px",
        padding: "15px 24px",
        borderRadius: "24px",
        fontSize: "18px",
        paddingInline: "24px",
        height: "48px",
      },
      [sizes.lg]: {
        lineHeight: "16px",
        padding: "8px 16px",
        borderRadius: "24px",
        fontSize: "16px",
        paddingInline: "16px",
        height: "40px",
      },
      [sizes.md]: {
        lineHeight: "24px",
        padding: "6px 12px",
        borderRadius: "28px",
        fontSize: "16px",
        paddingInline: "12px",
        height: "36px",
      },
      [sizes.sm]: {
        lineHeight: "21px",
        padding: "8px 8px",
        borderRadius: "30px",
        fontSize: "14px",
        paddingInline: "8px",
        height: "32px",
      },
      [sizes.xs]: {
        lineHeight: "16px",
        padding: "6px 8px",
        borderRadius: "24px",
        fontSize: "14px",
        paddingInline: "8px",
        height: "28px",
      },
      [sizes["2xs"]]: {
        lineHeight: "14px",
        padding: "4px 6px",
        borderRadius: "16px",
        fontSize: "14px",
        paddingInline: "6px",
        height: "22px",
      },
      [sizes.circle]: {
        lineHeight: "24px",
        padding: "15px 15px",
        borderRadius: "100px",
        fontSize: "16px",
        height: "40px",
      },
    },
  });

export const disabledButtonVariants = (props: ThemedProps) =>
  variant({
    prop: "variant",
    variants: {
      [variants.contained]: {
        background: () => {
          switch (props.color) {
            case colors.primary:
              return `${props.theme.colors.transparent.purple1} !important`;
            default:
              return `${props.theme.colors.transparent.grey2} !important`;
          }
        },
        color: () => {
          switch (props.color) {
            case colors.primary:
              return `${props.theme.colors.global.textAlt.T3} !important`;
            default:
              return `${props.theme.colors.global.textAlt.T3} !important`;
          }
        },
        opacity: "1 !important",
        cursor: "no-drop",
        border: "none !important",
      },
      [variants.outlined]: {
        background: () => {
          switch (props.color) {
            case colors.secondary:
              return `${props.theme.colors.global.line.Line1} !important`;
            default:
              return `${props.theme.colors.transparent.grey2} !important`;
          }
        },
        color: () => {
          switch (props.color) {
            case colors.secondary:
              return `${props.theme.colors.global.textAlt.T3} !important`;
            default:
              return `${props.theme.colors.grey.mediumGrey} !important`;
          }
        },
        opacity: "1 !important",
        cursor: "no-drop",
        border: "none !important",
      },
      [variants.text]: {
        background: `${props.theme.colors.transparent.transparent} !important`,
        color: `${props.theme.colors.grey.mediumGrey} !important`,
        opacity: "1 !important",
        cursor: "no-drop",
        border: "none !important",
      },
    },
  });

export const lockedButtonVariants = (props: ThemedProps) =>
  variant({
    prop: "variant",
    variants: {
      [variants.contained]: {
        background: () => {
          switch (props.color) {
            case colors.primary:
              return `${props.theme.colors.transparent.purple1} !important`;
            default:
              return `${props.theme.colors.transparent.grey2} !important`;
          }
        },
        color: () => {
          switch (props.color) {
            case colors.primary:
              return `${props.theme.colors.global.textAlt.T3} !important`;
            default:
              return `${props.theme.colors.grey.mediumGrey} !important`;
          }
        },
        opacity: "1 !important",
        cursor: "no-drop",
        border: "none !important",
      },
      [variants.outlined]: {
        background: () => {
          switch (props.color) {
            case colors.secondary:
              return `${props.theme.colors.transparent.transparent} !important`;
            default:
              return `${props.theme.colors.transparent.grey2} !important`;
          }
        },
        color: () => {
          switch (props.color) {
            case colors.secondary:
              return `${props.theme.colors.global.textAlt.T3} !important`;
            default:
              return `${props.theme.colors.grey.mediumGrey} !important`;
          }
        },
        border: () => {
          switch (props.color) {
            case colors.secondary:
              return `1.5px solid ${props.theme.colors.global.line.Line1} !important`;
            default:
              return "none !important";
          }
        },
        opacity: "1 !important",
        cursor: "no-drop",
      },
      [variants.text]: {
        background: `${props.theme.colors.transparent.transparent} !important`,
        color: `${props.theme.colors.grey.mediumGrey} !important`,
        opacity: "1 !important",
        cursor: "no-drop",
        border: "none !important",
      },
    },
  });

const { version } = require("../package.json");

interface IConfig {
  automanAPI: string;
  flagsmithAPI: string;
  flagsmithEnvID: string;
  strategyEndpoint: string;
  version: string;
}

type ConfigKey = "dev" | "test" | "prod";

const prodConfig: IConfig = {
  version,
  automanAPI: "https://api.aperture.finance/",
  flagsmithEnvID: "ULPkgwgiUyySSAoUq6TSSF",
  flagsmithAPI:
    process.env.NEXT_PUBLIC_FLAGSMITH_API || //override only when we want to switch production to direct API for immediate feature change
    "https://flagsmith-api.aperture.finance/api/v1/", // by default use cloudflare cached proxy, which should cache flags for 5 mins
  strategyEndpoint:
    "https://ekhwswkwtuw44p3cxiz53e3hfy0cyfef.lambda-url.us-west-2.on.aws/",
};

const testConfig = {
  ...prodConfig,
  automanAPI: "https://test-api.hyperfocal.tech/",
  flagsmithEnvID: "RzUa5ANhunXtTN5UTpNMqp",
  flagsmithAPI: "https://edge.api.flagsmith.com/api/v1/", // direct api, no cache
  strategyEndpoint:
    "https://lenkky3wawsv7yo7lbnvoxwnne0fnspm.lambda-url.us-west-2.on.aws/",
};

const configs: Record<"dev" | "test" | "prod", IConfig> = {
  dev: {
    ...testConfig,
    automanAPI: "https://api.hyperfocal.tech/",
    strategyEndpoint:
      "https://yw2x3caa5vwhtafg6jxro6zzyq0mlmuj.lambda-url.us-west-2.on.aws/",
  },
  test: testConfig,
  prod: prodConfig,
};

export const config =
  configs[process.env.NEXT_PUBLIC_ENV as ConfigKey] ?? configs.prod;

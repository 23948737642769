import bigDecimal from "js-big-decimal";
import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "styled-components";
import {
  formatTokenAmount,
  formatUSDAmount,
  limitToScale,
} from "../../../utils";
import { LinearLoader } from "../../Loader";
import { IInputRef } from "../types";
import {
  BalanceName,
  BalanceValue,
  BalanceWrapper,
  InputButton,
  Max,
  StakeContainer,
  StakeWrapper,
  StyledInput,
  Text,
  TokenInfoWrapper,
  USDValue,
  ValueContainer,
} from "./style";
import { IStakeInputProps } from "./types";

export const StakeInput: React.FC<IStakeInputProps> = ({
  title,
  token,
  value,
  stakeCap,
  showMax = true,
  showBalance = true,
  onValueChange,
  loading = false,
  isError = false,
  errorMsg = undefined,
  disabled = false,
  decimalScale,
  ...props
}) => {
  const theme = useTheme();
  const [convertedValue, setConvertedValue] = useState<string>("0.00");
  const inputRef = useRef<IInputRef>(null);
  const convertValue = (value: string, tokenPrice: string) =>
    tokenPrice && tokenPrice !== "-"
      ? bigDecimal.multiply(value, tokenPrice)
      : "";

  useEffect(() => {
    if (!token || (!value && value !== "")) return;
    setConvertedValue(convertValue(value, token?.price ?? ""));
  }, [value, token]);

  return (
    <StakeContainer>
      {!!title && <Text>{title}</Text>}
      <StakeWrapper isError={isError || !!errorMsg}>
        <InputButton>
          <StyledInput
            ref={inputRef}
            align="left"
            placeholder="0"
            defaultValue="0"
            //@ts-ignore
            value={
              token ? (value ? limitToScale(value, decimalScale) : "") : ""
            }
            disabled={!token || loading || disabled}
            type="number"
            onChange={(value: string) =>
              onValueChange(limitToScale(value, decimalScale))
            }
            onBlur={() => {
              !!errorMsg && inputRef?.current?.setValue(stakeCap || "0");
            }}
            {...props}
          />
          <TokenInfoWrapper gap="md" alignItems="center">
            <token.Icon width={20} height={20} />
            {token.ticker}
          </TokenInfoWrapper>
        </InputButton>
        <ValueContainer>
          {loading ? (
            <LinearLoader size="80px" height="14px" radius="8px" />
          ) : (
            <USDValue>
              {token && value && bigDecimal.compareTo(value, 0) > 0
                ? convertedValue
                  ? `~${formatUSDAmount(convertedValue)}`
                  : "-"
                : "-"}
            </USDValue>
          )}
          <BalanceWrapper>
            {showBalance && (
              <>
                <BalanceName>Balance</BalanceName>
                <BalanceValue>
                  {formatTokenAmount(token?.amount || "0")}
                </BalanceValue>
              </>
            )}
            {showMax &&
              token &&
              bigDecimal.compareTo(token?.amount, "0") > 0 && (
                <Max
                  disabled={loading || disabled}
                  onClick={() =>
                    !loading &&
                    !disabled &&
                    inputRef?.current?.setValue(token?.amount || "0")
                  }
                >
                  Max
                </Max>
              )}
          </BalanceWrapper>
        </ValueContainer>
      </StakeWrapper>
      {!!errorMsg && <Text color={theme.colors.global.error}>{errorMsg}</Text>}
    </StakeContainer>
  );
};

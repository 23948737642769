import { SearchIcon } from "@aperture/assetkit";
import React from "react";
import styled from "styled-components";
import { Input } from "./Input";
import { SearchInputProps } from "./types";

const Container = styled.div<{ width?: number }>`
  position: relative;
  height: 42px;
  width: ${({ width }) => width && width + "px"};
`;
const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
  fill: ${({ theme }) => theme.colors.global.text.T1};
`;

const StyledInput = styled(Input)<{ width?: number }>`
  width: ${({ width }) => (width ? width - 70 + "px" : "calc(100% - 70px)")};
  height: 24px;
  padding-left: 50px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.global.text.T1};
  background: ${({ theme }) => theme.colors.global.background.BG1};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
  transition: border-color 0.3s ease !important;

  :focus {
    border-color: ${({ theme }) => theme.colors.global.text.T1};
  }
`;

export const SearchInput: React.FC<SearchInputProps> = ({ ...props }) => {
  return (
    <Container width={props.width}>
      <StyledSearchIcon height={16} width={16} />
      <StyledInput type="text" align="left" {...props} />
    </Container>
  );
};

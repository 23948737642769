import { TriggerType } from "@ui/common/types/Strategy";
import { formatTokenAmount } from "@ui/utils";
import React, { useMemo } from "react";
import { getRebalanceRange } from "../TriggerDetail";
import { IEditTriggerDetailProps } from "../type";
import { PureEditTriggerDetail } from "./PureEditTriggerDetail";

export const EditTriggerDetail: React.FC<IEditTriggerDetailProps> = ({
  type,
  triggerIdx,
  onEdit,
  onDelete,
  onAddTrigger,
  tokens,
  trigger,
  disabled = false,
}) => {
  const {
    triggerType,
    baseToken,
    timeBufferChecked,
    aboveChecked,
    belowChecked,
    below,
    above,
    aboveAction,
    belowAction,
  } = trigger;

  const token2 = tokens[1 - baseToken];

  const aboveValue = useMemo(() => {
    if (!aboveChecked) {
      return "";
    }

    if (triggerType === TriggerType.PRICE) {
      return `${formatTokenAmount(above, token2.decimals)} ${token2.ticker}`;
    }
    return `${above}%`;
  }, [above, aboveChecked, token2.decimals, token2.ticker, triggerType]);

  const belowValue = useMemo(() => {
    if (!belowChecked) {
      return "";
    }

    const prefix = triggerType === TriggerType.RATIO ? "" : "-";
    if (triggerType === TriggerType.PRICE) {
      return `${prefix}${formatTokenAmount(below, token2.decimals)} ${
        token2.ticker
      }`;
    }
    return `${prefix}${below}%`;
  }, [below, belowChecked, token2.decimals, token2.ticker, triggerType]);

  const belowRange = useMemo(() => {
    if (!belowAction) return undefined;
    return getRebalanceRange(belowAction, tokens);
  }, [belowAction, tokens]);
  const aboveRange = useMemo(() => {
    if (!aboveAction) return undefined;
    return getRebalanceRange(aboveAction, tokens);
  }, [aboveAction, tokens]);

  return (
    <PureEditTriggerDetail
      type={type}
      triggerIdx={triggerIdx}
      token={tokens[baseToken]}
      above={aboveValue}
      below={belowValue}
      aboveRange={aboveRange}
      belowRange={belowRange}
      triggerType={triggerType}
      onClickEdit={onEdit}
      onClickDelete={onDelete}
      onAddTrigger={onAddTrigger}
      disabled={disabled}
    />
  );
};

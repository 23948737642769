import styled from "styled-components";
import { Headline5 } from "../Typography";

export const SliderContainer = styled.div`
  height: 66px;
  position: relative;
`;

export const Input = styled.input.attrs({ type: "range" })<{ value: number }>`
  width: 100%;
  height: 11px;
  -webkit-appearance: none;
  background: ${({ theme }) => theme.colors.transparent.grey2};
  border-radius: ${({ theme }) => theme.radii.md};
  outline: none;
  position: absolute;
  left: 0;
  bottom: 6px;
  margin: 0;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 19px;
    height: 19px;
    background: ${({ theme }) => theme.colors.global.primary};
    box-shadow: ${({ theme }) => theme.shadows.small};
    border-radius: ${({ theme }) => theme.radii.round};
    cursor: pointer;
    position: relative;
    z-index: 1;
    transform: ${({ value }) =>
      `translate(${value < 15 ? "-4px" : value > 85 ? "4px" : "0"}, 0)`};
    :hover {
      background: ${({ theme }) => theme.colors.global.primaryHover};
      box-shadow: ${({ theme }) => theme.shadows.mediumPurple};
    }
    :active {
      background: ${({ theme }) => theme.colors.global.primaryHover};
      box-shadow: ${({ theme }) => theme.shadows.mediumPurple};
    }
  }
`;

export const ValidLine = styled.div<{ value: number }>`
  background: ${({ theme }) => theme.colors.global.primary};
  border-radius: ${({ theme }) => theme.radii.md};
  position: absolute;
  left: 0;
  bottom: 6px;
  width: ${({ value }) => value}%;
  height: 11px;
`;

export const Tag = styled(Headline5)<{ value: number }>`
  color: ${({ theme }) => theme.colors.global.text.T1};
  position: absolute;
  font-size: 14px;
  min-width: 48px;
  top: 0;
  left: ${({ value }) =>
    `calc(${value}% - ${value < 5 ? "8px" : value > 95 ? "42px" : "24px"})`};
  background: ${({ theme }) => theme.colors.global.background.BG3};
  border-radius: ${({ theme }) => theme.radii.sm};
  text-align: center;
  padding: 8px 0;
`;

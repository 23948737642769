import React from "react";
import { IPastPositionCardProps, PastPositionCard } from "../PastPositionCard";
import { Headline5 } from "../Typography";
import {
  Container,
  Line,
  PastPositionStatus,
  StatusText,
  StyledFlex,
} from "./style";

export interface IPastPositionListProps {
  positions: Array<{
    time: string;
    date: string;
    status: string;
    position: IPastPositionCardProps;
  }>;
}

export const PastPositionList: React.FC<IPastPositionListProps> = ({
  positions,
}) => {
  return (
    <Container>
      <Headline5 lineHeight={"20px"} marginBottom="12px">
        Past Positions
      </Headline5>
      <StyledFlex flexDirection="column" gap="lg">
        <Line />
        {positions.map(({ position, time, date, status }, index) => {
          return (
            <StyledFlex gap="xl" key={`${index}-${position.id}`}>
              <PastPositionStatus isLast={index === positions.length - 1}>
                <StatusText color="first">{time}</StatusText>
                <StatusText color="second">{date}</StatusText>
                <StatusText color="third">{status}</StatusText>
              </PastPositionStatus>

              <PastPositionCard {...position} />
            </StyledFlex>
          );
        })}
      </StyledFlex>
    </Container>
  );
};

import React, { useRef, useState } from "react";
import styled from "styled-components";
import { useIsomorphicEffect } from "../../contexts/MatchBreakpoints/Provider";
import { InfoPopover } from "../Popover";
import { Headline4 } from "../Typography";
import { Input } from "./Input";
import { InputWTickerProps } from "./types";

const Container = styled.div<{ width?: number }>`
  position: relative;
  width: ${({ width }) => width && width + "px"};
`;
const StyledInput = styled(Input)<{ width?: number; right: number }>`
  width: ${({ width, right }) =>
    width ? width - right - 16 + "px" : "calc(100% - " + (right + 16) + "px)"};
  padding-right: ${({ right }) => right && right + "px"};
`;
const Ticker = styled.div`
  position: absolute;
  right: 16px;
  top: 10px;
`;
const H4 = styled(Headline4)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
  height: 18px;
`;

const InputWithTickerComponent: React.FC<InputWTickerProps> = ({
  ticker,
  popover,
  ...props
}) => {
  const tickerRef = useRef<HTMLDivElement>(null);
  const [right, setRight] = useState(0);
  useIsomorphicEffect(() => {
    function updatePosition() {
      if (tickerRef.current) {
        setRight(tickerRef.current.offsetWidth + 24);
      }
    }
    window.addEventListener("resize", updatePosition);
    updatePosition();
    return () => window.removeEventListener("resize", updatePosition);
  }, [popover, ticker]);
  return (
    <Container width={props.width}>
      <StyledInput right={right} {...props} />
      <Ticker ref={tickerRef}>
        <H4>
          {!!ticker && <span>{ticker}</span>}
          {
            <InfoPopover
              content={popover}
              defaultPosition={{ right: -16, bottom: 30 }}
              defaultSize={{ width: props.width }}
            />
          }
        </H4>
      </Ticker>
    </Container>
  );
};

export const InputWithTicker = React.memo(InputWithTickerComponent);

import { CaretIcon, WarningIcon } from "@aperture/assetkit";
import { Flex } from "@ui/common";
import styled from "styled-components";
import { Input } from "../Input";
import { Body1, Headline5, Subtitle2 } from "../Typography";

export const Container = styled.div`
  margin-top: 16px;
  padding: 16px;
  border-radius: ${({ theme }) => theme.radii.lg};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};
`;

export const Title = styled(Flex)`
  cursor: pointer;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const StyledText = styled(Body1)`
  font-family: "Roboto Flex";
  line-height: 16px;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

export const ArrowBtn = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 8px;
  margin: -12px -14px -12px auto;
  border-radius: ${({ theme }) => theme.radii.md};
`;

export const ArrowDown = styled(CaretIcon)<{ isShow: boolean }>`
  width: 24px;
  height: 24px;
  transition: transform ${({ theme }) => theme.transitions.default};
  transform: ${({ isShow }) => (isShow ? "rotate(180deg)" : "")};
  path {
    fill: ${({ theme }) => theme.colors.global.text.T1};
  }
`;

export const Content = styled.div<{
  isShow: boolean;
  useOverflow: boolean;
  gasCeilingError: boolean;
  expireDayError: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
  overflow: ${({ useOverflow }) => (useOverflow ? "initial" : "hidden")};
  padding-top: ${({ isShow }) => (isShow ? "12px" : "0")};
  height: ${({ isShow, gasCeilingError, expireDayError }) =>
    isShow
      ? gasCeilingError && expireDayError
        ? "305px"
        : gasCeilingError || expireDayError
        ? "290px"
        : "275px"
      : "0"};
  transition: height ${({ theme }) => theme.transitions.default},
    padding-top ${({ theme }) => theme.transitions.default};
`;

export const SelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
`;

export const Split = styled.hr`
  width: 100%;
  margin: 0;
`;

export const ButtonContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 48px;
  width: 186.5px;
  padding: 0 16px 0 12px;
  > svg {
    width: 20px;
    height: 20px;
  }

  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.global.text.T3 : theme.colors.global.text.T1};
  background: ${({ disabled, theme }) => theme.colors.global.background.BG1};
  border-radius: ${({ theme }) => theme.radii.md};
  border: 1px solid
    ${({ theme: { colors, isDropdownOpen } }) =>
      isDropdownOpen ? colors.global.line.Line2 : colors.global.line.Line1};
  &:hover {
    border-color: ${({ disabled, theme }) =>
      disabled
        ? theme.colors.global.line.Line1
        : theme.colors.global.line.Line2};
  }
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
`;

export const DropdownArrow = styled(ArrowDown)`
  margin-left: auto;
`;

export const DropdownContent = styled.div`
  width: 186.5px;
  padding: 8px;
  box-sizing: border-box;
  line-height: 1;
`;

export const DropdownList = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  max-height: 140px;
`;

export const DropdownItem = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: ${({ theme }) => theme.radii.md};
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.transparent.grey2};
  }
  transition: all ${({ theme }) => theme.transitions.default};
`;

export const FeeTierText = styled(Headline5)`
  margin-left: auto;
`;

export const TitleWithQuestion = styled(Subtitle2)`
  display: flex;
  align-items: center;
  width: 186.5px;
  gap: ${({ theme }) => theme.spacing.sm};
`;

export const Revenue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  width: 186.5px;
  height: 48px;
  background: ${({ theme }) => theme.colors.global.background.BG1};
  color: ${({ theme }) => theme.colors.global.text.T1};
  border-radius: ${({ theme }) => theme.radii.md};
  gap: ${({ theme }) => theme.spacing.md};
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line2};
  box-sizing: border-box;

  > svg {
    height: 16px;
    width: 16px;
  }
  div:last-child {
    margin-left: auto;
  }
`;

export const IconWrapper = styled.div`
  height: 16px;
  width: 16px;
`;

export const StyledInput = styled(Input)`
  padding: 8px;
  height: 48px;
  width: 186.5px;
  border-radius: ${({ theme }) => theme.radii.md};
  font-size: 14px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.global.line.Line1};

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.global.line.Line2};
  }
`;

export const ExpireWarning = styled.div`
  color: ${({ theme }) => theme.colors.global.error};
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  order: 2;
  margin-top: -8px;
`;

export const StyledWarningIcon = styled(WarningIcon)`
  width: 15px;
  height: 15px;
  path {
    stroke: ${({ theme }) => theme.colors.global.error};
  }
  margin-right: ${({ theme }) => theme.spacing.sm};
`;

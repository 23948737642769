import React, { useState } from "react";
import styled from "styled-components";
import { variant } from "styled-system";
import {
  IActivityTab,
  IActivityTabActiveLine,
  IActivityTabItem,
  IActivityTabsProps,
} from "./types";

const TabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;
const TabItem = styled.div<IActivityTabItem>`
  flex: 1;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.global.background.BG4};
  ${variant({
    variants: {
      inactive: {
        color: "global.text.T3",
        "&:hover": {
          color: "global.text.T2",
          borderColor: "global.text.T3",
        },
      },
      active: {
        color: "global.text.T1",
      },
    },
  })}
`;
const TabActiveLine = styled.div<IActivityTabActiveLine>`
  height: 3px;
  width: ${(props) => `${100 / props.totalLen}%`};
  position: absolute;
  bottom: 0;
  left: ${(props) => `${(props.index / props.totalLen) * 100}%` || 0};
  transition: left ${({ theme }) => theme.transitions.default};
  background: ${({ theme }) => theme.colors.global.text.T2};
`;

export const ActivityTab: React.FC<IActivityTabsProps> = ({
  tabsList,
  defaultTabName,
  defaultTabIndex,
  onChange,
}) => {
  const activeIndex = (() => {
    const index = tabsList.findIndex((tab) => tab === defaultTabName);
    return index > -1
      ? index
      : defaultTabIndex && defaultTabIndex < tabsList.length
      ? defaultTabIndex
      : 0;
  })();
  const [activeTab, setActiveTab] = useState(activeIndex);
  const handleClick = (name: IActivityTab, index: number) => {
    setActiveTab(index);
    onChange && onChange(name, index);
  };

  return (
    <TabContainer>
      {tabsList?.map((tab: IActivityTab, index: number) => (
        <TabItem
          key={tab}
          variant={activeTab === index ? "active" : "inactive"}
          onClick={() => {
            handleClick(tab, index);
          }}
        >
          {tab}
        </TabItem>
      ))}
      <TabActiveLine index={activeTab} totalLen={tabsList.length} />
    </TabContainer>
  );
};

import { useState } from "react";
import { Activity } from "../../Activity";
import { LinearLoaderGroup } from "../../Loader";
import { ActivityTab } from "../../Tabs";
import { PortfolioToken } from "./PortfolioToken";
import {
  ActivitiesContent,
  LoaderWrapper,
  TabContentsContainer,
  TabTitle,
  TokensContent,
} from "./style";
import { ITabContents } from "./types";
export const TabContents: React.FC<ITabContents> = ({
  portfolioBalances,
  activities,
  explorerURL,
}) => {
  const [activeTab, setActiveTab] = useState("Activities");

  return (
    <TabContentsContainer>
      <ActivityTab
        defaultTabName={"Activities"}
        tabsList={["Tokens", "Activities", "History"]}
        onChange={(tabName) => {
          setActiveTab(tabName);
        }}
      />
      {activeTab === "Activities" && (
        <>
          <TabTitle>
            <span>Recent Activities</span>
          </TabTitle>
          <ActivitiesContent>
            {activities &&
              Object.values(activities).map((item, index) => (
                <Activity key={index} explorerURL={explorerURL} data={item} />
              ))}
          </ActivitiesContent>
        </>
      )}
      {activeTab === "Tokens" &&
        (portfolioBalances ? (
          portfolioBalances.tokenBalances.length ? (
            <TokensContent>
              {portfolioBalances.tokenBalances.map((item) => (
                <PortfolioToken key={item.id} token={item} />
              ))}
            </TokensContent>
          ) : (
            <TabTitle>
              <span>No Tokens Yet</span>
            </TabTitle>
          )
        ) : (
          <LoaderWrapper>
            <LinearLoaderGroup size="100%" height="51px" />
          </LoaderWrapper>
        ))}
      {activeTab === "History" && (
        <>
          <TabTitle>
            <span>Coming Soon</span>
          </TabTitle>
        </>
      )}
    </TabContentsContainer>
  );
};

import { Flex } from "@ui/common";
import { formatUSDAmount } from "@ui/utils";
import bigDecimal from "js-big-decimal";
import React, { CSSProperties } from "react";
import styled from "styled-components";
import { LinearLoader } from "../Loader";
import { ApertureFeePopover, QuestionPopover } from "../Popover";
import { IFeeInfoCardProps } from "./types";

const InfoContainer = styled.div<{ size: "sm" | "md" | "lg" | "xl" }>`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  ${({ size }) =>
    size === "sm"
      ? "font-size: 12px; line-height: 12px;"
      : size === "md"
      ? "font-size: 14px; line-height: 16px;"
      : size === "lg"
      ? "font-size: 16px; line-height: 16px;"
      : "font-size: 16px; line-height: 20px;"}
  gap: ${({ theme }) => theme.spacing["5xl"]};
`;
const Title = styled.span<{
  titleFontWeight?: CSSProperties["fontWeight"];
  normalLineHight?: boolean;
  titleColor?: "light" | "dark";
}>`
  ${({ titleFontWeight }) =>
    titleFontWeight && `font-weight:${titleFontWeight};`}
  ${({ normalLineHight }) => normalLineHight && "line-height: normal;"}
  color: ${({ theme, titleColor }) =>
    titleColor === "light"
      ? theme.colors.global.text.T2
      : theme.colors.global.text.T1};
`;
const FeeActivated = styled.span<{ lightMode?: boolean }>`
  text-align: right;
  max-width: 400px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.global.text.T1};
`;
const FeeCharged = styled.span<{ lightMode?: boolean }>`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.global.text.T3};
  text-decoration-line: line-through;
`;
const NoFeeCharged = styled.span`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.global.success};
`;

export const FeeInfoCard: React.FC<IFeeInfoCardProps> = ({
  styles = {
    size: "md",
    titleFontWeight: undefined,
    titleColor: "dark",
    normalLineHight: false,
    lightMode: false,
  },
  amm,
  isApertureFee = true,
  feeInfo,
  hint = "",
  isLoading = false,
  ...props
}) => {
  return (
    <InfoContainer size={styles.size} {...props}>
      <Flex gap={"sm"} alignItems={"center"}>
        <Title
          titleColor={styles.titleColor}
          titleFontWeight={styles.titleFontWeight}
          normalLineHight={styles.normalLineHight}
        >
          {isApertureFee
            ? "Aperture Fee"
            : amm === "UNISWAP_V3"
            ? "Uniswap Fee"
            : "PancakeSwap Fee"}
        </Title>
        {isApertureFee && (
          <ApertureFeePopover
            hint={hint}
            defaultPosition={{
              left: -82,
              bottom: 24,
            }}
          />
        )}
        {!isApertureFee && (
          <QuestionPopover
            defaultPosition={{
              left:
                styles.size === "sm" ? -82 : styles.size === "md" ? -90 : -100,
              bottom: 24,
            }}
            content={`${
              amm === "UNISWAP_V3" ? "Uniswap" : "PancakeSwap"
            } charges a ${
              feeInfo.fee * 100
            }% fee on every swap. By using ApertureSwap, you don't have to pay this fee on your swap.`}
          />
        )}
      </Flex>
      <Flex gap={"md2"} alignItems={"center"}>
        {isLoading ? (
          <LinearLoader
            size="80px"
            height={
              styles.size === "sm"
                ? "12px"
                : styles.size === "md"
                ? "14px"
                : "16px"
            }
          />
        ) : feeInfo.active ? (
          <FeeActivated>
            {typeof feeInfo.feeUSD === "string"
              ? feeInfo.feeUSD
              : feeInfo.amount !== "-"
              ? formatUSDAmount(
                  bigDecimal.multiply(feeInfo.amount, feeInfo.fee)
                )
              : "-"}
          </FeeActivated>
        ) : (
          <>
            <FeeCharged lightMode={styles.lightMode}>{`${feeInfo.fee * 100}% (${
              feeInfo.amount !== "-"
                ? formatUSDAmount(
                    bigDecimal.multiply(feeInfo.amount, feeInfo.fee)
                  )
                : "-"
            })`}</FeeCharged>
            <NoFeeCharged>$0.00</NoFeeCharged>
          </>
        )}
      </Flex>
    </InfoContainer>
  );
};

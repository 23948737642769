import { variants } from "./types";

export const styleVariants = {
  [variants.PRIMARY]: {
    backgroundColor: "grey.lightGrey2",
    color: "primary.purple",
  },
  [variants.INFO]: {
    backgroundColor: "errorWarning.lightOrange",
    color: "primary.purple",
  },
  [variants.ERROR]: {
    backgroundColor: "errorWarning.orange",
    color: "grey.white",
  },
  [variants.TEXT]: {
    backgroundColor: "transparent.white1",
    color: "grey.black",
  },
  [variants.LIGHT_GRAY]: {
    backgroundColor: "grey.lightGrey3",
    color: "grey.mediumGrey",
  },
  [variants.GRAY]: {
    backgroundColor: "global.background.BG3",
    color: "global.text.T1",
  },
  [variants.WHITE_GRAY]: {
    backgroundColor: "grey.white",
    color: "grey.darkGrey2",
  },
  [variants.DARK_GRAY]: {
    backgroundColor: "transparent.grey2",
    color: "grey.darkGrey2",
  },
  [variants.SUCCESS]: {
    backgroundColor: "positive.green",
    color: "grey.white",
  },
  [variants.RISE]: {
    backgroundColor: "positive.green",
    color: "global.textAlt.T1",
  },
  [variants.AUTO]: {
    backgroundColor: "global.primary",
    color: "global.black",
  },
  [variants.LIGHT_FAILED]: {
    color: "global.error",
  },
  [variants.FAILED]: {
    backgroundColor: "errorWarning.red",
    color: "grey.white",
  },
  [variants.CLOSED]: {
    backgroundColor: "grey.mediumGrey",
    color: "grey.white",
    padding: "6px 8px",
    lineHeight: "12px",
  },
};

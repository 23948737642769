import React from "react";
import styled from "styled-components";
import { Input } from "./Input";
import { InputGroupRightProps } from "./types";

const StyledContainer = styled.div<{ width?: number }>`
  position: relative;
  display: flex;
  width: ${({ width }) => width && width + "px"};
  gap: ${({ theme }) => theme.spacing.md};
`;

const StyledInput = styled(Input)<{ width?: number }>`
  width: 100%;
`;

export const InputGroupRight: React.FC<InputGroupRightProps> = ({
  ...props
}) => {
  return (
    <StyledContainer width={props.width}>
      <StyledInput {...props} />
      {props.rightNode}
    </StyledContainer>
  );
};

import { IDefaultPopoverPosition } from "@aperture/types";
import { Flex } from "@uiv2/common";
import {
  LimitOrderIconBtn,
  PrescheduledPIconBtn,
  RebalancedIconBtn,
  RecurringRebalanceIconBtn,
} from "../IconButton";

export const Status: React.FC<{
  rebalanced?: Boolean;
  recurringRebalanced?: Boolean;
  prescheduledPosition?: Boolean;
  limitOrder?: Boolean;
  position?: IDefaultPopoverPosition;
}> = ({
  rebalanced = undefined,
  recurringRebalanced = undefined,
  prescheduledPosition = undefined,
  limitOrder = undefined,
  position,
}) => {
  return (
    <Flex gap="md">
      {recurringRebalanced !== undefined && (
        <RecurringRebalanceIconBtn
          warning={!recurringRebalanced}
          position={position}
        />
      )}
      {rebalanced !== undefined && (
        <RebalancedIconBtn warning={!rebalanced} position={position} />
      )}
      {prescheduledPosition !== undefined && (
        <PrescheduledPIconBtn
          warning={!prescheduledPosition}
          position={position}
        />
      )}
      {limitOrder !== undefined && (
        <LimitOrderIconBtn warning={!limitOrder} position={position} />
      )}
    </Flex>
  );
};

import {
  Breakpoints,
  Colors,
  Gradients,
  MediaQueries,
  Radii,
  Shadows,
  Spacing,
  Transitions,
  ZIndices,
} from "./types";

export interface ApertureTheme {
  siteWidth: number;
  isDark: boolean;
  colors: Colors;
  gradients: Gradients;
  breakpoints: Breakpoints;
  mediaQueries: MediaQueries;
  spacing: Spacing;
  shadows: Shadows;
  radii: Radii;
  transitions: Transitions;
  zIndices: ZIndices;
  isDropdownOpen?: boolean;
}

export * from "./base";
export * from "./colors";
export * from "./theme";
export * from "./types";

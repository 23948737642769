import styled from "styled-components";
import { ButtonText } from "../../Typography";
import { StyledBar, StyledItem } from "../SharedTab";
import { SwitchTabProps } from "./types";

const TabBar = styled(StyledBar)<Partial<SwitchTabProps>>`
  width: 60px;
  border-radius: ${({ theme }) => theme.radii.round};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
`;

const TabSlider = styled.div<{
  height: string;
  selectedIndex: number;
  length: number;
  disabled: Boolean;
  sliderColor: string;
  marginTop: number;
}>`
  position: absolute;
  width: ${({ height }) => height};
  height: ${({ height }) => height};
  top: 50%;
  left: ${({ selectedIndex, length }) => `${(100 / length) * selectedIndex}%`};
  transition: ${({ theme }) => theme.transitions.default};
  transform: translateY(-50%);
  background: ${({ theme, sliderColor, disabled }) => {
    if (disabled) {
      return theme.colors.global.text.T3;
    }
    if (sliderColor === "primary") {
      return theme.colors.global.primary;
    } else {
      return theme.colors.global.text.T3;
    }
  }};
  border-radius: ${({ theme }) => theme.radii.round};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.global.text.T1 : theme.colors.grey.white};
`;
const TabItems = styled(StyledItem)<{ disabled: Boolean; selected: Boolean }>`
  z-index: ${({ theme }) => theme.zIndices.tab};
  color: ${({ theme, disabled, selected }) =>
    disabled
      ? theme.colors.global.text.T1
      : selected
      ? theme.colors.global.textAlt.T1
      : theme.colors.global.text.T1};
`;

export const BaseTab: React.FC<SwitchTabProps> = ({
  leftValue,
  rightValue,
  selected = 0,
  height = "28px",
  marginTop = 8,
  onSelect,
  sliderColor = "default",
  ...props
}) => {
  return (
    <TabBar
      {...props}
      onClick={() => {
        if (!props.disabled) onSelect(1 - selected ? rightValue : leftValue);
      }}
    >
      <TabSlider
        disabled={props.disabled ?? false}
        height={height}
        marginTop={marginTop}
        sliderColor={sliderColor}
        selectedIndex={selected}
        length={2}
      />
      {[leftValue, rightValue].map((item, index) => (
        <TabItems
          key={`${index}-${item}`}
          itemWidth={100 / 2}
          className={`${index === selected ? "itemSelected" : ""}`}
          disabled={props.disabled ?? false}
          selected={selected === index}
        >
          <ButtonText lineHeight={"24px"}>{item}</ButtonText>
        </TabItems>
      ))}
    </TabBar>
  );
};

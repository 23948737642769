/*
 * Generates an SVG path for the east brush handle.
 * Apply `scale(-1, 1)` to generate west brush handle.
 *
 * https://medium.com/@dennismphil/one-side-rounded-rectangle-using-svg-fb31cf318d90
 */
export const brushHandlePath = (height: number) =>
  [
    // handle
    `M 0 0`, // move to origin
    `v ${height}`, // vertical line
    "m 0.5 0", // move 1px to the right
    `V 0`, // second vertical line
    `M -13.5 ${height / 2 + 1}`, // move to offset vertical middle

    // circle
    "a 5,5 0 1,1 28,0", // draw circle
    "5,5 0 1,1 -28,0",
    `z`, // close path
  ].join(" ");

export const brushHandleCirclePath = (height: number) =>
  `M -7.5 ${height / 2 + 1} a 5,5 0 1,1 16,0 5,5 0 1,1 -16,0 z`;

export const brushHandleLinePath = (height: number) =>
  `M 0 0 v ${height} m 0.5 0 V 0 M -7.5 ${height / 2 + 1} z`;

export const brushHandleSmallAccentPath = (height: number) =>
  `m 0 ${height / 2 - 1.5} l 2.5 2.5 l -2.5 2.5 z`;

export const brushHandleAccentPath = (height: number) =>
  [
    `m -0.5 ${height / 2 - 3}`, // move to offset vertical middle
    "l 4 4", // diagonal line
    "l -4 4", // diagonal line
    "z", // close path
  ].join(" ");

export const OffScreenHandle = ({
  color,
  size = 10,
  margin = 10,
}: {
  color: string;
  size?: number;
  margin?: number;
}) => (
  <polygon
    points={`0 0, ${size} ${size}, 0 ${size}`}
    transform={` translate(${size + margin}, ${margin}) rotate(45) `}
    fill={color}
    stroke={color}
    strokeWidth="4"
    strokeLinejoin="round"
  />
);

import { ApertureAPIDocURL } from "@/config/url";
import { getAutomanAPI } from "@/utils/helper";
import { Flex, FlexColumn, HomeCard, TotalCard } from "@aperture/uikit";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled(FlexColumn)`
  width: 100%;
  max-width: 840px;
  min-width: 376px;
  height: calc(100vh - 189px);
  min-height: 480px;
  align-items: center;
  justify-content: center;
`;
const Homepage = () => {
  const router = useRouter();
  const [txCount, setTxCount] = useState<number>();
  const [tveUsd, setTveUsd] = useState<number>();
  const [userCount, setUserCount] = useState<number>();

  useEffect(() => {
    fetch(`${getAutomanAPI()}getMetricsSinceInception`)
      .then((response) => response.json())
      .then((responseJson) => {
        setTxCount(responseJson.txEnabledCount);
        setTveUsd(responseJson.tveUsd);
        setUserCount(responseJson.walletCount);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <Container>
      <Flex gap="lg" style={{ margin: "auto 0" }}>
        <FlexColumn gap="lg">
          <HomeCard
            title="Aperture’s Intent API: Custom-Defined Workflow Enhancement"
            description="Utilize the Aperture API for a streamlined, intent-driven
              workflow. Tailor your strategy, simplify transactions, and drive
              success with declarative, user-focused solutions."
            linkText="Read More"
            onClick={() => window.open(ApertureAPIDocURL, "_black")}
          />
          <HomeCard
            title="Advanced Liquidity Management with Intent-driven UniV3 Tools"
            description="Streamline and elevate your Uniswap and PancakeSwap experience.
              Effortlessly initiate and manage LP positions, optimize
              performance through smart rebalancing, strategic fee reinvestment,
              and effective recurring tactics, all tailored to your investment
              objectives."
            linkText="Enter"
            onClick={() => router.push("/liquidity-management")}
          />
        </FlexColumn>
        <TotalCard
          bgImg="/bg-total.png"
          totalVolume={tveUsd}
          totalUsers={userCount}
          totalTransactions={txCount}
        />
      </Flex>
    </Container>
  );
};

export default Homepage;

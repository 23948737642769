import bigDecimal from "js-big-decimal";
import styled from "styled-components";
import { formatMarketPrice } from "../../utils/numberFormat";
import { InputGroupPlusMinus } from "../Input";
import { LiquidityChartRangeInput } from "../LiquidityChartRangeInput/LiquidityChartRangeInput";
import { Notification, notificationVariants } from "../Notification";
import { Subtitle2 } from "../Typography";
import { LiquiditySelectorProps } from "./types";

const TitleWrapper = styled(Subtitle2)<{ isLoading: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.global.text.T3};
`;

const ExchangeRateWrapper = styled.div`
  font-weight: 500;
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.global.text.T1};
`;

const StyledTextBox = styled.div`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.global.text.T3};
`;

const LiquiditySelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: ${({ theme }) => theme.spacing["3xl"]};
`;

const InputsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacing.lg};
`;

const StyledNotification = styled(Notification)`
  margin-top: -8px;
`;

export function LiquiditySelector({
  currencyA,
  currencyB,
  feeAmount,
  ticksAtLimit,
  price,
  priceLower,
  priceUpper,
  warningMsg,
  getDecrementLower,
  getIncrementLower,
  getDecrementUpper,
  getIncrementUpper,
  onLeftRangeInput,
  onRightRangeInput,
  validate,
  chartHeight,
  interactive,
  dataState,
  currentRange,
}: LiquiditySelectorProps) {
  return (
    <LiquiditySelectorWrapper>
      {currencyA && currencyB && (
        <TitleWrapper
          fontWeight={400}
          fontSize={16}
          lineHeight={"20px"}
          isLoading={dataState.isLoading}
          // height={40}
        >
          Market Price
          <ExchangeRateWrapper>
            {price !== undefined &&
              formatMarketPrice(bigDecimal.multiply(price, "1.0"))}
            <StyledTextBox>
              {currencyB} per {currencyA}
            </StyledTextBox>
          </ExchangeRateWrapper>
        </TitleWrapper>
      )}
      <LiquidityChartRangeInput
        currencyA={currencyA}
        currencyB={currencyB}
        feeAmount={feeAmount}
        ticksAtLimit={ticksAtLimit}
        price={price}
        priceLower={priceLower}
        priceUpper={priceUpper}
        onLeftRangeInput={onLeftRangeInput}
        onRightRangeInput={onRightRangeInput}
        interactive={interactive}
        chartHeight={chartHeight}
        dataState={dataState}
        currentRange={currentRange}
      />
      <InputsWrapper>
        <InputGroupPlusMinus
          placeholder="0.00"
          value={priceLower}
          onChange={onLeftRangeInput}
          defaultValue={priceLower ?? "0"}
          title={"Min"}
          subTitle={`${currencyB ?? ""} per ${currencyA ?? ""}`}
          minusClick={getDecrementLower}
          plusClick={getIncrementLower}
          disabled={!interactive}
          warning={
            !!warningMsg &&
            !!priceLower &&
            !!price &&
            (bigDecimal.compareTo(priceLower, price) >= 0 ||
              bigDecimal.compareTo(priceLower, priceUpper) >= 0)
          }
        />
        <InputGroupPlusMinus
          placeholder="0.00"
          value={priceUpper}
          onChange={onRightRangeInput}
          defaultValue={priceUpper ?? "0"}
          title={"Max"}
          subTitle={`${currencyB ?? ""} per ${currencyA ?? ""}`}
          minusClick={getDecrementUpper}
          plusClick={getIncrementUpper}
          disabled={!interactive}
          warning={
            !!warningMsg &&
            !!priceUpper &&
            !!price &&
            (bigDecimal.compareTo(parseFloat(priceUpper), price) <= 0 ||
              bigDecimal.compareTo(priceLower, priceUpper) >= 0)
          }
        />
      </InputsWrapper>
      {!!priceLower &&
        !!priceUpper &&
        !!price &&
        (bigDecimal.compareTo(priceLower, price) >= 0 ||
          bigDecimal.compareTo(parseFloat(priceUpper), price) <= 0) &&
        warningMsg && (
          <StyledNotification
            variant={notificationVariants.WARNING}
            text={warningMsg}
          />
        )}
    </LiquiditySelectorWrapper>
  );
}

import { CopyFileIcon, EtherScanIcon, InRangeIcon } from "@aperture/assetkit";
import { useDrawer } from "@ui/components/Drawer";
import { WalletText } from "@ui/components/Typography";
import { useCopyText } from "@ui/utils/hooks";
import React from "react";
import {
  ConnectionType,
  connectionName,
  formatUSDAmount,
  getWalletIcon,
  shortenAddress,
} from "../../../utils";
import { Dropdown } from "../../Dropdown";
import { LinearLoader, Spinner } from "../../Loader";
import { Avatar } from "../Avatar";
import { TabContents } from "./TabContents";
import {
  Amount,
  ButtonGroup,
  ButtonTextBox,
  Capitalized,
  ChevronDown,
  ConnectStatus,
  ContentContainer,
  IconWrapper,
  SpinnerWrapper,
  StyledBox,
  StyledButton,
  StyledExitIcon,
  UserAddress,
  UserInfo,
} from "./style";
import { AuthStatus, AuthUserProps } from "./types";

export const AuthUser: React.FC<AuthUserProps> = ({
  portfolioBalances,
  activities,
  authUser,
  authStatus,
  authKeepOpen,
  connectType = ConnectionType.METAMASK,
  explorerURL,
  url,
  onClick,
  onAuthClick,
  onDisconnect,
}) => {
  const { drawerOpen } = useDrawer();
  const WalletIcon = getWalletIcon(connectType);
  const { hasCopied, handleCopyAddress } = useCopyText(authUser.address);

  const DropdownBtn = (
    <StyledBox drawerOpen={drawerOpen} onClick={onClick}>
      <IconWrapper>
        <Avatar text={authUser.address} />
        <WalletIcon
          width={16}
          height={16}
          style={{ position: "absolute", right: "0", bottom: "0" }}
        />
      </IconWrapper>
      <WalletText>{shortenAddress(authUser.address, 4, 4)}</WalletText>
      <ChevronDown colorAlt={true} />
    </StyledBox>
  );

  return (
    <Dropdown button={DropdownBtn} dropdownAlwaysOpen={authKeepOpen}>
      <ContentContainer>
        <ConnectStatus>
          <Capitalized>
            Connected with {connectionName[connectType]}
          </Capitalized>
          {connectType !== ConnectionType.SAFE && (
            <StyledExitIcon onClick={onDisconnect} />
          )}
        </ConnectStatus>
        <UserInfo>
          <UserAddress>
            <WalletIcon width={25} height={25} />
            {shortenAddress(authUser.address, 6, 4)}
          </UserAddress>
        </UserInfo>
        <Amount>
          {portfolioBalances ? (
            `${formatUSDAmount(portfolioBalances.totalBalance)}`
          ) : (
            <LinearLoader size="150px" height="24px" />
          )}
        </Amount>
        <ButtonGroup>
          <span onClick={handleCopyAddress}>
            {hasCopied ? (
              <InRangeIcon width="20" height="20" />
            ) : (
              <CopyFileIcon />
            )}
          </span>
          {url && (
            <span
              onClick={() => {
                window.open(`${url}/address/${authUser.address}`, "_blank");
              }}
            >
              <EtherScanIcon />
            </span>
          )}
          <div style={{ marginLeft: "auto" }}>
            <StyledButton
              onClick={onAuthClick}
              disabled={authStatus === AuthStatus.LOADING}
            >
              <ButtonTextBox lineHeight={"32px"} height={"32px"}>
                {authStatus === AuthStatus.LOADING ? (
                  <SpinnerWrapper>
                    <Spinner size={20} />
                  </SpinnerWrapper>
                ) : authStatus === AuthStatus.AUTHORIZED ? (
                  "Revoke Operator Authorization"
                ) : (
                  "Authorize Operator"
                )}
              </ButtonTextBox>
            </StyledButton>
          </div>
        </ButtonGroup>
      </ContentContainer>
      <TabContents
        portfolioBalances={portfolioBalances}
        activities={activities}
        explorerURL={explorerURL}
      />
    </Dropdown>
  );
};

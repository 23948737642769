import { Flex, FlexColumn } from "@uiv2/common";
import { Input } from "@uiv2/components/Input";
import { T3Regular, T4 } from "@uiv2/components/Typography";
import styled from "styled-components";

const StyledT3 = styled(T3Regular)`
  color: ${({ theme }) => theme.colors.global.text.T2};
`;
const InputText = styled(T4)`
  color: ${({ theme }) => theme.colors.global.text.T3};
`;
const InputWrapper = styled(Flex)`
  align-items: center;
  padding: ${({ theme }) => theme.spacing.sm};
  border-radius: ${({ theme }) => theme.radii.sm};
  background-color: ${({ theme }) => theme.colors.global.background.BG3};
`;
const StyledInput = styled(Input)`
  font-family: "Roboto Flex";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.global.text.T3};
`;

interface PriceInputProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
}

const PriceInput: React.FC<PriceInputProps> = ({ label, value, onChange }) => (
  <FlexColumn gap="md">
    <StyledT3>{label} Price (USD)</StyledT3>
    <InputWrapper>
      <InputText>$</InputText>
      <StyledInput
        align="left"
        type="number"
        placeholder="0.00"
        defaultValue={value}
        onChange={onChange}
      />
    </InputWrapper>
  </FlexColumn>
);

export default PriceInput;

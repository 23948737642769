import React from "react";
import styled from "styled-components";
import { LayoutProps, layout } from "styled-system";

const StyledMainCard = styled.div<LayoutProps>`
  width: 100%;
  max-width: 1080px;
  background-color: ${({ theme }) => theme.colors.global.background.BG1};
  border-radius: ${({ theme }) => theme.radii.md};
  padding: 24px;
  ${layout}
`;

export const MainCard: React.FC<React.PropsWithChildren<LayoutProps>> = ({
  children,
  ...props
}) => {
  return <StyledMainCard {...props}>{children}</StyledMainCard>;
};

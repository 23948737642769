import { IDefaultPopoverPosition } from "@aperture/types";
import { MouseEventHandler } from "react";

export const variants = {
  PRIMARY: "primary",
  ERROR: "error",
  RECURRING: "recurring",
} as const;
export type Variant = (typeof variants)[keyof typeof variants];

export interface IconButtonProps {
  variant: Variant;
  Icon: any;
  content?: string;
  onHover?: MouseEventHandler;
  onClick?: MouseEventHandler;
  position?: IDefaultPopoverPosition;
}

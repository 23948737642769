import { ConnectModal } from "@/components/layout/hooks";
import {
  IntentGptDrawer as PureIntentGptDrawer,
  useDrawer,
} from "@aperture/uikit";
import { E_ModalKey, useModal } from "@aperture/uikitv2";
import { usePathname } from "next/navigation";
import { useEffect } from "react";
import { useAccount } from "wagmi";
import { ChatViewWrapper } from "../ChatViewWrapper";
import { useChatStore } from "../hooks";

export const IntentGptDrawer: React.FC = () => {
  const {
    showIntro,
    chatList,
    updateChat,
    deleteChat,
    newChat,
    currentChatId,
    currentThreadId,
    setCurrentChatId,
    currentMessages,
  } = useChatStore();

  const { intentDrawerOpen, hideIntentDrawer, showIntentDrawer } = useDrawer();
  const { setModalState } = useModal();

  const pathname = usePathname();
  const { address } = useAccount();

  useEffect(() => {
    hideIntentDrawer();
  }, [hideIntentDrawer, pathname]);

  const onClickOpenDrawer = () => {
    if (!address) {
      setModalState(E_ModalKey.CONNECT_MODAL, true);
      return;
    }

    showIntentDrawer();
  };

  if (pathname.startsWith("/intents-gpt")) {
    return null;
  }

  return (
    <>
      <PureIntentGptDrawer
        histories={chatList}
        onHistoryClick={setCurrentChatId}
        onHistoryDelete={deleteChat}
        onClickNewChat={newChat}
        onClickOpenDrawer={onClickOpenDrawer}
        onClickBack={hideIntentDrawer}
        drawerOpen={intentDrawerOpen}
      >
        <ChatViewWrapper
          showIntro={showIntro}
          isDrawer={true}
          key={currentChatId}
          chatId={currentChatId}
          initMessages={currentMessages}
          threadId={currentThreadId}
          onMessagesUpdate={updateChat}
        />
      </PureIntentGptDrawer>
      <ConnectModal />
    </>
  );
};

export enum TriggerType {
  PRICE = "price",
  PRICE_PERCENTAGE = "pricePercentage",
  RATIO = "ratio",
}
export enum RebalanceActionTab {
  PERCENTAGES = "Percentages",
  TOKEN_TERMS = "Token Terms",
  RATIO = "Ratio",
}
export type TriggerInfo = {
  triggerType: TriggerType;
  rebalanceType: RebalanceActionTab;
  triggerValue: TriggerValue;
  rebalanceValue: PriceBasedRebalanceValue | RatioBasedRebalanceValue;
};
export type TriggerValue = {
  above: string;
  below: string;
  baseToken: 0 | 1;
};
export type PriceBasedRebalanceValue = {
  above: string;
  below: string;
  baseToken: 0 | 1;
};
export type RatioBasedRebalanceValue = {
  percentageToken0: string;
  percentageToken1: string;
  tickWidth: number;
  baseToken: 0 | 1;
};

import { useOkxQuoteTokens, useRpcSettings } from "@/config/feature_flags";
import { useOnLogout } from "@/hooks/globalState/useOnLogout";
import { useEventCallback } from "@/hooks/useEventCallback";
import { AmmEnum } from "@aperture/uikit";
import { ApertureSupportedChainId } from "@aperture_finance/uniswap-v3-automation-sdk";
import { Token } from "@uniswap/sdk-core";
import { useLocalStorageState } from "ahooks";
import { keyBy } from "lodash";
import { useEffect, useReducer, useRef } from "react";
import { Address } from "viem";
import {
  initAllTokenMapAction,
  updateActivePositionMapAction,
  updatePositionMapAction,
  updateTokenMapAction,
  updateTokenPriceMapAction,
} from "./actions";
import { reducer } from "./reducer";
import { AllChainState, IPositionMap, IRawTokenMap } from "./types";

const initialState: AllChainState = {
  rawAllTokenMap: undefined,
  rawAllTokenPriceMap: {},
  rawAllPositionMap: undefined,
  activePositionMap: {},
};

export const useAllChainState = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [_, setAllChainsWarningTokens] = useLocalStorageState<
    Record<number, Token[]>
  >(`all-chains-warning-tokens`);

  const okxQuoteTokens = useOkxQuoteTokens();
  const rpcSetting = useRpcSettings();
  const initialized = useRef<{ [key: string]: boolean }>({});

  const checkInitialized = (key: string) => {
    if (!initialized.current[key]) {
      initialized.current[key] = true;
      return false;
    }
    return true;
  };

  const updateTokenMap = useEventCallback(
    async ({
      chainId,
      tokens,
    }: {
      chainId: ApertureSupportedChainId;
      tokens: Token[];
    }) => {
      setAllChainsWarningTokens((prev) => ({
        ...prev,
        [chainId]: [...(prev?.[chainId] ?? []), ...tokens],
      }));

      const tokenMap = keyBy(tokens, (token) => token.address);
      updateTokenMapAction(dispatch, chainId, tokenMap);

      updateTokenPriceMap({
        chainId,
        tokenMap,
      });

      return tokenMap;
    }
  );

  const updateTokenPriceMap = useEventCallback(
    async ({
      chainId,
      tokenMap,
      positionMap,
      isInitialization = false,
    }: {
      chainId: ApertureSupportedChainId;
      tokenMap?: IRawTokenMap;
      positionMap?: IPositionMap;
      isInitialization?: boolean;
    }) => {
      if (!tokenMap) {
        tokenMap = state.rawAllTokenMap?.[chainId] ?? {};
      }

      const initKey = `token-price-${chainId}`;
      if (isInitialization && checkInitialized(initKey)) {
        return null;
      }

      const tokenPriceMap = await updateTokenPriceMapAction(
        dispatch,
        chainId,
        Object.values(tokenMap),
        okxQuoteTokens?.[chainId]
      );

      if (positionMap) {
        updateActivePositionMapAction(
          dispatch,
          chainId,
          positionMap,
          tokenMap,
          tokenPriceMap
        );
      }

      return tokenPriceMap;
    }
  );

  const updatePositionMap = useEventCallback(
    async ({
      chainId,
      amm,
      walletAddress,
      isInitialization = false,
    }: {
      chainId: ApertureSupportedChainId;
      amm: AmmEnum;
      walletAddress: Address;
      isInitialization?: boolean;
    }) => {
      if (!chainId || !amm || !walletAddress) return null;

      const initKey = `position-map-${walletAddress}-${amm}-${chainId}`;
      if (isInitialization && checkInitialized(initKey)) {
        return null;
      }

      const positionMap = await updatePositionMapAction(
        dispatch,
        chainId,
        amm,
        walletAddress,
        rpcSetting
      );

      const tokenMap = state.rawAllTokenMap?.[chainId];

      updateActivePositionMapAction(dispatch, chainId, positionMap, tokenMap);

      updateTokenPriceMap({
        chainId,
        tokenMap,
        positionMap,
      });

      return positionMap;
    }
  );

  useEffect(() => {
    // initialize for raw token map
    initAllTokenMapAction(dispatch);
  }, []);

  useOnLogout(() => {
    // clear position map
    dispatch({
      type: "clearPostionMap",
    });

    for (const key in initialized.current) {
      if (key.startsWith("position-map-")) {
        delete initialized.current[key];
      }
    }
  });

  return {
    state,
    updateTokenPriceMap,
    updatePositionMap,
    updateTokenMap,
    dispatch,
  };
};

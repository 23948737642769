import { Flex } from "@ui/common";
import styled from "styled-components";

export interface INoteCardProps {
  text: string;
  className?: string;
}
const Container = styled(Flex)`
  width: fit-content;
`;
const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.global.warning};
`;

export const NoteCard = ({ text, className, ...props }: INoteCardProps) => {
  return (
    <Container className={className} gap="sm" {...props}>
      <Text>{text}</Text>
    </Container>
  );
};
